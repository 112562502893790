import { useState } from 'react'
import { reverse } from 'named-urls'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button, Box } from '@material-ui/core'

import { MenuButton, DialogLogs, ConfirmationDialog } from 'components'
import {
  DialogReopen,
  InactivateDataProcessDialog,
  ReactivateDataProcessDialog,
  DeleteDataProcessDialog,
  DialogApproveRevision,
  DialogReproveRevision,
} from '../'

import { useDataProcess, usePermissions, useSnackbar } from 'hooks'
import { useDataProcessesCache } from 'hooks/caches'

import { routes } from 'Routes'
import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'

const DataProcessMenuItems = ({
  dataProcess,
  origin,
  setLoading,
  onEvent,
  ...rest
}) => {
  const history = useHistory()
  const { permitted } = usePermissions()
  const snackbar = useSnackbar()
  const { data, logs, reloadOptions } = useDataProcess()
  const dataProcessesCache = useDataProcessesCache()

  // Dialogs States
  const [dialogConfirm, setDialogConfirm] = useState(false)
  const [dialogReopen, setDialogReopen] = useState(false)
  const [dialogInactivateDataProcess, setDialogInactivateDataProcess] =
    useState(false)
  const [dialogReactivateDataProcess, setDialogReactivateDataProcess] =
    useState(false)
  const [dialogDeleteDataProcess, setDialogDeleteDataProcess] = useState(false)
  const [dialogLogs, setDialogLogs] = useState(false)

  const [dialogApproveRevision, setDialogApproveRevision] = useState(false)
  const [dialogReproveRevision, setDialogReproveRevision] = useState(false)

  const handleEdit = () => {
    history.push(
      reverse(routes.dataProcess.edit, {
        dataProcessId: data?.id,
      }),
    )
  }

  // Envia o processo para revisão
  const sendToReview = async () => {
    setLoading(true)
    setDialogConfirm(false)
    try {
      await service.dponet.dataProcesses.changeStatus({
        dataProcessId: dataProcess.id,
        statusId: constants.dataProcess.WAITING_REVIEW_STATUS,
      })
      snackbar.open({
        message: 'Processo enviado para a revisão!',
        variant: 'success',
      })
      onEvent()
      setLoading(false)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
      setLoading(false)
    }
  }

  const initializeRevision = async () => {
    setLoading(true)
    try {
      await service.dponet.dataProcesses.changeStatus({
        dataProcessId: dataProcess.id,
        statusId: constants.dataProcess.REVIEWING_STATUS,
      })
      snackbar.open({
        message: 'Revisão do processo iniciada com sucesso!',
        variant: 'success',
      })
      setLoading(false)
      onEvent()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
      setLoading(false)
    }
  }

  const allowEdition = () => {
    return (
      dataProcess?.company?.kind === constants.companies.KINDS.TEMPLATE &&
      permitted(constants.permissions.DATA_PROCESSES.CREATE) &&
      !constants.dataProcess.NOT_EDITABLE_STATUS.includes(dataProcess.statusId)
    )
  }

  const allowSendToRevision = () => {
    return (
      permitted(constants.permissions.DATA_PROCESSES.CREATE) &&
      (dataProcess.statusId === constants.dataProcess.PENDING_STATUS ||
        dataProcess.statusId === constants.dataProcess.DISAPPROVED_STATUS)
    )
  }

  const allowReopen = () => {
    return (
      permitted(constants.permissions.DATA_PROCESSES.CREATE) &&
      dataProcess.statusId === constants.dataProcess.APPROVED_STATUS
    )
  }

  const allowShowProcessHistory = () => {
    return !!logs?.length
  }

  const allowInactivation = () => {
    return (
      permitted(constants.permissions.DATA_PROCESSES.INACTIVE_AND_REACTIVE) &&
      dataProcess.statusId === constants.dataProcess.APPROVED_STATUS
    )
  }

  const allowReactivate = () => {
    return (
      permitted(constants.permissions.DATA_PROCESSES.INACTIVE_AND_REACTIVE) &&
      dataProcess.statusId === constants.dataProcess.INACTIVE_STATUS
    )
  }

  const allowExclusion = () => {
    return permitted(constants.permissions.DATA_PROCESSES.DESTROY)
  }

  const allowReproveOrApprove = () => {
    if (dataProcess.statusId !== constants.dataProcess.AUTO_REVIEW_STATUS) {
      return (
        permitted(constants.permissions.DATA_PROCESSES.REVIEW_DATA_PROCESS) &&
        dataProcess.statusId === constants.dataProcess.REVIEWING_STATUS
      )
    }
    return false
  }

  const allowJustApprove = () => {
    return (
      permitted(constants.permissions.DATA_PROCESSES.REVIEW) &&
      [
        constants.dataProcess.REVIEWING_STATUS,
        constants.dataProcess.AUTO_REVIEW_STATUS,
      ].includes(dataProcess.statusId)
    )
  }

  const allowInitializeRevision = () => {
    return (
      permitted(constants.permissions.DATA_PROCESSES.REVIEW) &&
      dataProcess.statusId === constants.dataProcess.WAITING_REVIEW_STATUS
    )
  }
  const items = [
    {
      label: 'EDITAR',
      action: () => handleEdit(),
      visible: allowEdition(),
    },
    {
      label: 'ENVIAR PARA REVISÃO',
      action: () => setDialogConfirm(true),
      visible: allowSendToRevision(),
    },
    {
      label: 'REABRIR',
      action: () => setDialogReopen(true),
      visible: allowReopen(),
    },
    {
      label: 'HISTÓRICO DE ALTERAÇÕES',
      action: () => setDialogLogs(true),
      visible: allowShowProcessHistory(),
    },
    {
      label: 'INATIVAR',
      action: () => setDialogInactivateDataProcess(true),
      visible: allowInactivation(),
    },
    {
      label: 'REATIVAR',
      action: () => setDialogReactivateDataProcess(true),
      visible: allowReactivate(),
    },
    {
      label: 'EXCLUIR',
      action: () => setDialogDeleteDataProcess(true),
      visible: allowExclusion(),
    },
    {
      label: 'INICIAR REVISÃO',
      action: () => initializeRevision(),
      visible: allowInitializeRevision(),
    },
    {
      label: 'APROVAR REVISÃO',
      action: () => setDialogApproveRevision(true),
      visible:
        dataProcess.statusId === constants.dataProcess.AUTO_REVIEW_STATUS
          ? allowJustApprove()
          : allowReproveOrApprove(),
    },
    {
      label: 'REPROVAR REVISÃO',
      action: () => setDialogReproveRevision(true),
      visible: allowReproveOrApprove(),
    },
  ]

  const visibleItems = items.filter((item) => item.visible)

  const eventOnDelete = () => {
    dataProcessesCache.useUpdateCache()
    history.push(routes.dataProcess.all)
  }

  return (
    <>
      {visibleItems?.length ? (
        <MenuButton iconButton={false} {...rest}>
          {visibleItems.map((item) => (
            <Button
              key={item.label}
              fullWidth
              onClick={() => {
                item.action()
              }}
            >
              {item.label}
            </Button>
          ))}
        </MenuButton>
      ) : (
        <Box py={2}></Box>
      )}
      <ConfirmationDialog
        message="Deseja enviar esse processo para revisão?"
        cancelButtonText="Cancelar"
        buttonText="Enviar para revisão"
        open={dialogConfirm}
        setOpen={setDialogConfirm}
        actionAcceptButton={sendToReview}
      />
      <DialogReopen
        open={dialogReopen}
        setOpen={setDialogReopen}
        dataProcess={dataProcess}
        setLoading={setLoading}
        refresh={onEvent}
      />
      <DialogLogs open={dialogLogs} setOpen={setDialogLogs} logs={logs} />
      <InactivateDataProcessDialog
        dataProcessId={dataProcess?.id}
        open={dialogInactivateDataProcess}
        setOpen={setDialogInactivateDataProcess}
        action={onEvent}
      />
      <ReactivateDataProcessDialog
        dataProcessId={dataProcess?.id}
        open={dialogReactivateDataProcess}
        setOpen={setDialogReactivateDataProcess}
        action={onEvent}
      />
      <DeleteDataProcessDialog
        dataProcessId={dataProcess?.id}
        open={dialogDeleteDataProcess}
        setOpen={setDialogDeleteDataProcess}
        action={eventOnDelete}
        redirectTo={routes.dataProcess.all}
        setLoading={setLoading}
      />
      <DialogApproveRevision
        data={dataProcess}
        reloadOptions={reloadOptions}
        open={dialogApproveRevision}
        setOpen={setDialogApproveRevision}
        setLoading={setLoading}
        onEvent={onEvent}
      />
      <DialogReproveRevision
        dataProcess={dataProcess}
        open={dialogReproveRevision}
        setOpen={setDialogReproveRevision}
        setLoading={setLoading}
        onEvent={onEvent}
      />
    </>
  )
}

DataProcessMenuItems.propTypes = {
  dataProcess: PropTypes.object.isRequired,
  origin: PropTypes.string,
  setLoading: PropTypes.func.isRequired,
  onEvent: PropTypes.func.isRequired,
}

DataProcessMenuItems.defaultProps = {
  setLoading: () => {},
  onEvent: () => {},
}

export default DataProcessMenuItems
