import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { routes } from 'Routes'
import { reverse } from 'named-urls'

import useSnackbar from 'hooks/useSnackbar'
import { useHistory } from 'react-router-dom'
import { ConfirmationDialog, Permitted, ButtonLogs } from 'components'

import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'

const ActionButtons = ({
  preRegistration,
  refresh,
  isList,
  setLoading,
  typeClient,
}) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openConvertDialog, setOpenConvertDialog] = useState(false)

  const { STATUS_TRIALS } = constants.preRegistration

  const snackbar = useSnackbar()
  const history = useHistory()

  const isClient = typeClient === constants.preRegistration.CLIENT_TYPE

  const handleDelete = async () => {
    try {
      await service.dponet.preRegistrations.destroy({
        preRegistrationToken: preRegistration?.token,
      })

      snackbar.open({
        message: `${
          isClient ? 'cliente' : 'sublicenciamento'
        } excluido com sucesso!`,
        variant: 'success',
      })
      if (isClient) return history.push(routes.clients.all)

      history.push(routes.sublicensings.all)
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
      })
    }
    setOpenDeleteDialog(false)
    refresh()
  }

  const goToEdit = () => {
    history.push(
      reverse(routes.preRegistrations.edit, {
        preRegistrationToken: preRegistration.token,
      }),
    )
  }

  const HandleCreateUserOmie = async () => {
    setLoading(true)
    try {
      await service.dponet.preRegistrations.createUserOmie(
        preRegistration.token,
      )
      snackbar.open({
        message: 'Cliente criado no OMIE com sucesso',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Erro ao criar cliente no OMIE',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const HandleConvertTrialToReal = async () => {
    try {
      await service.dponet.preRegistrations.convertTrialToReal(
        preRegistration.token,
      )
      snackbar.open({
        message: 'Empresa convertida com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Erro ao converter a empresa',
        variant: 'error',
      })
    }
    setOpenConvertDialog(false)
    refresh()
  }

  const handleCreateCompany = async () => {
    setLoading(true)
    try {
      await service.dponet.preRegistrations.generateCompany(
        preRegistration.token,
      )

      snackbar.open({
        message: 'Empresa gerada com sucesso',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Erro ao gerar empresa',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleOpenDeleteDialog = () => setOpenDeleteDialog(true)

  return (
    <>
      <Permitted tag={constants.permissions.PRE_REGISTRATIONS.CREATE}>
        <Button fullWidth size="small" onClick={goToEdit}>
          Editar
        </Button>
      </Permitted>
      {isList ? (
        <Button
          fullWidth
          size="small"
          onClick={() => {
            history.push(
              reverse(routes.preRegistrations.show, {
                preRegistrationToken: preRegistration?.token,
              }),
            )
          }}
        >
          Visualizar
        </Button>
      ) : (
        <>
          <ButtonLogs
            entityId={preRegistration?.token}
            routes="pre_registrations"
          />
          <Button
            fullWidth
            size="small"
            onClick={HandleCreateUserOmie}
            disabled={preRegistration?.codeClientOmie}
          >
            Criar cliente no OMIE
          </Button>
          <Button
            fullWidth
            size="small"
            onClick={setOpenConvertDialog}
            disabled={!STATUS_TRIALS.includes(preRegistration?.status)}
          >
            Converter para real
          </Button>
          <Button
            fullWidth
            size="small"
            onClick={handleCreateCompany}
            disabled={preRegistration?.isRelatedCompany}
          >
            Gerar empresa
          </Button>
        </>
      )}
      <Permitted tag={constants.permissions.PRE_REGISTRATIONS.DESTROY}>
        <Button fullWidth size="small" onClick={handleOpenDeleteDialog}>
          Excluir
        </Button>
      </Permitted>
      {openDeleteDialog && (
        <ConfirmationDialog
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          message={constants.preRegistration.DESTROY_DIALOG}
          cancelButtonText="Cancelar"
          buttonText="Excluir"
          actionAcceptButton={handleDelete}
        />
      )}
      {openConvertDialog && (
        <ConfirmationDialog
          open={openConvertDialog}
          setOpen={setOpenConvertDialog}
          message="Tem certeza que deseja converter esta empresa para real?"
          cancelButtonText="Cancelar"
          buttonText="Confirmar"
          actionAcceptButton={HandleConvertTrialToReal}
        />
      )}
    </>
  )
}

ActionButtons.propTypes = {
  preRegistration: PropTypes.object,
  refresh: PropTypes.func,
  isList: PropTypes.bool,
  logs: PropTypes.array,
  setLoading: PropTypes.func,
  typeClient: PropTypes.string,
}

ActionButtons.defaultProps = {
  refresh: () => {},
  isList: false,
}

export default ActionButtons
