import * as yup from 'yup'

export const schemaUser = yup.object().shape({
  title: yup.string().required(),
  link: yup.string().url().required(),
  documentCategoryId: yup.string().required(),
  otherDocumentCategory: yup.string().when('documentCategoryId', {
    is: 'another',
    then: yup.string().required(),
  }),
})

export default schemaUser
