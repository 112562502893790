import { useState } from 'react'

import PropTypes from 'prop-types'

import {
  Card,
  CardHeader,
  Divider,
  Box,
  Typography,
  Collapse,
  IconButton,
} from '@material-ui/core'

import clsx from 'clsx'

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import useStyles from './styles.js'

const SpecificRiskRow = ({ specificRisk, classes }) => {
  const [expanded, setExpanded] = useState()

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>{specificRisk.id}</TableCell>
        <TableCell overFlowSize="275px">{specificRisk.description}</TableCell>
        <TableCell disableTooltip>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          disableTooltip
          noOverFlow
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box mx={2.5} mb={2.5}>
              <Typography gutterBottom className={classes.expandedText}>
                {specificRisk.description}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const SpecificRisks = ({ rmc }) => {
  const classes = useStyles()

  return (
    <Card>
      <CardHeader title="Riscos específicos para a empresa" />
      <Divider />
      <Table
        aria-label="collapsible table"
        emptyMessage="Nenhum risco específico encontrado"
        size="small"
      >
        <TableHead>
          <TableRow height="60px">
            <TableCell width="10%">ID</TableCell>
            <TableCell width="80%">Risco</TableCell>
            <TableCell width="10%"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rmc?.specificRisks?.map((specificRisk) => (
            <SpecificRiskRow
              specificRisk={specificRisk}
              key={specificRisk.id}
              classes={classes}
            />
          ))}
        </TableBody>
      </Table>
    </Card>
  )
}

SpecificRisks.propTypes = {
  rmc: PropTypes.object,
}

export default SpecificRisks
