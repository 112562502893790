const styles = (theme) => ({
  root: {
    marginTop: -theme.spacing(2),
    paddingTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: -theme.spacing(2),
    },
  },
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  whiteTypography: {
    color: theme.palette.common.white,
  },
  menuNavigation: {
    color: theme.palette.text.white,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
})

export default styles
