import { Button, Box } from '@material-ui/core'
import { MenuButton, LabelIconStart } from 'components'

import {
  // Eye as EyeIcon,
  Repeat as RepeatIcon,
} from 'react-feather'

const ResponsibleFormMenuItems = ({
  setChangeResponsibleDialog,
  editionMode,
}) => {
  const cardActionsItems = [
    // DESCOMENTAR NO MOMENTO EM QUE FOR ADICIONADO A TELA DE EXIBIÇÃO DE USUÁRIO
    // ADICIONAR REDIRECIONAMENTO NA "action"

    // {
    //   icon: <EyeIcon size={20} />,
    //   label: 'Informações sobre responsável',
    //   action: () => {>> função de redirecionamento vai aqui <<},
    //   visible: true,
    // },
    {
      icon: <RepeatIcon size={20} />,
      label: 'Alterar responsável',
      action: () => setChangeResponsibleDialog(true),
      visible: true,
    },
  ]

  const visibleItems = cardActionsItems.filter((item) => item.visible)

  return (
    <>
      {visibleItems?.length > 0 && editionMode ? (
        <Box display="flex" alignItems="center">
          <MenuButton>
            {visibleItems.map((item) => (
              <Button
                key={item.label}
                color="secondary"
                fullWidth
                onClick={item.action}
              >
                <LabelIconStart icon={item.icon} label={item.label} />
              </Button>
            ))}
          </MenuButton>
        </Box>
      ) : (
        <Box py={2}></Box>
      )}
    </>
  )
}

export default ResponsibleFormMenuItems
