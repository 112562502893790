import PropTypes from 'prop-types'
import { TableRow, TableCell } from 'components/Table'
import { MenuButton, Permitted } from 'components'
import { Chip, Button } from '@material-ui/core'
import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from './styles'
import clsx from 'clsx'

import { reverse } from 'named-urls'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

const CompanySizeRow = ({
  companySize,
  setSelectedSize,
  setOpenSize,
  setIsDestroy,
}) => {
  const classes = useStyles()
  const history = useHistory()

  const handleChange = (companySize, isDestroy) => {
    setSelectedSize(companySize)
    setOpenSize(true)
    setIsDestroy(isDestroy)
  }

  return (
    <TableRow key={companySize?.id}>
      <TableCell disableTooltip>{companySize?.id}</TableCell>
      <TableCell>{companySize?.name}</TableCell>
      <TableCell align="center" disableTooltip>
        <Chip
          className={clsx(classes.root, {
            [classes.active]: companySize?.active,
            [classes.inactive]: !companySize?.active,
          })}
          size="small"
          label={companySize.active ? 'Ativo' : 'Inativo'}
        />
      </TableCell>
      <TableCell align="center">
        {helpers.formatters.date(companySize?.inactivateAt) || '-'}
      </TableCell>
      <TableCell disableTooltip align="right">
        <Permitted
          someTags={[
            constants.permissions.COMPANY_SIZES.DESTROY,
            constants.permissions.COMPANY_SIZES.ACTIVATE_OR_INACTIVATE,
            constants.permissions.COMPANY_SIZES.EDIT,
          ]}
        >
          <MenuButton>
            <Permitted tag={constants.permissions.COMPANY_SIZES.EDIT}>
              <Button
                fullWidth
                size="small"
                onClick={() =>
                  history.push(
                    reverse(routes.companySizes.edit, {
                      sizeId: companySize?.id,
                    }),
                  )
                }
              >
                Editar
              </Button>
            </Permitted>
            <Permitted tag={constants.permissions.COMPANY_SIZES.DESTROY}>
              <Button
                fullWidth
                size="small"
                onClick={() => handleChange(companySize, true)}
              >
                Excluir
              </Button>
            </Permitted>
            <Permitted
              tag={constants.permissions.COMPANY_SIZES.ACTIVATE_OR_INACTIVATE}
            >
              <Button
                fullWidth
                size="small"
                onClick={() => handleChange(companySize, false)}
              >
                {companySize?.active ? 'Inativar' : 'Ativar'}
              </Button>
            </Permitted>
          </MenuButton>
        </Permitted>
      </TableCell>
    </TableRow>
  )
}

CompanySizeRow.propTypes = {
  companySize: PropTypes.object,
}

CompanySizeRow.depaultProps = {
  companySize: {},
}

export default CompanySizeRow
