import { useLocation } from 'react-router-dom'

import { useFetch } from 'hooks'

import { Container, ContentHeader, LoadingFeedback, Page } from 'components'
import { TabsForm } from './components'

import * as service from 'service'

const ContractNew = () => {
  const location = useLocation()
  const preRegistrationToken = location?.state?.preRegistrationToken

  const { response, isLoading } = useFetch(
    service.dponet.preRegistrations.get,
    { preRegistrationToken },
    [],
    !!preRegistrationToken,
  )

  const loading = preRegistrationToken ? isLoading : false

  return (
    <Page title="Novo contrato">
      <Container maxWidth={false}>
        <ContentHeader
          menu="Contrato"
          title="Novo contrato"
          subtitle="Novo contrato"
        />
        <LoadingFeedback open={loading} />
        {!loading && (
          <TabsForm
            mode="new"
            preRegistration={response?.data?.preRegistration}
          />
        )}
      </Container>
    </Page>
  )
}

export default ContractNew
