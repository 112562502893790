import { jsPDF } from 'jspdf'

import moment from 'moment'

import templates from '../templates'

import '../fonts/Raleway-Bold-normal'
import '../fonts/Raleway-Regular-normal'
import '../fonts/Raleway-Thin-normal'
import '../fonts/Roboto-Bold-normal'
import '../fonts/Roboto-Regular-normal'

import pages from './pages'

moment.locale('pt-br')
var dateNow = moment().format('MMM YYYY')
dateNow = dateNow.charAt(0).toUpperCase() + dateNow.slice(1)

const incidentReportPdf = async (company, incident) => {
  const ReportRmcPdf = async (company) => {
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      userUnit: 300,
    })

    try {
      templates.firstCover(
        pdf,
        company?.name,
        company?.cnpj,
        dateNow,
        'Relatório de Incidente Simplificado',
      )

      pages.disclaimer(pdf, dateNow)

      pages.incident(pdf, `Incidente | ${dateNow}`, incident)

      pages.treatment(pdf, `Incidente | ${dateNow}`, incident)

      templates.lastCover(pdf)

      pdf.save(
        `Relatorio_de_Incidente_simplificado${company?.name
          ?.split(' ')
          ?.join('_')
          ?.toLowerCase()}_${dateNow}.pdf`,
      )
    } catch (e) {}
  }
  return ReportRmcPdf(company)
}

export default incidentReportPdf
