import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/styles'
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Tab,
  TablePagination,
  Tabs,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { Plus as PlusIcon } from 'react-feather'

import {
  Container,
  ContentHeader,
  FilterButton,
  LoadingFeedback,
  Page,
  Permitted,
} from 'components'
import { DepartmentTables, DepartmentFilter } from './components'

import { useFilter, usePagination } from 'hooks'
import { useDepartmentsCache } from 'hooks/caches'

import constants from 'constants/index'
import { routes } from 'Routes'

const DepartmentsMain = () => {
  const filter = useFilter()
  const history = useHistory()
  const theme = useTheme()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)
  const departmentsCache = useDepartmentsCache()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const [tab, setTab] = useState(constants.department.ALL_TAB)

  const departmentsResponse = departmentsCache.useList({
    ...{ ...filter.filters },
    ...(tab !== constants.subgroup.ALL_TAB && {
      status: tab === constants.subgroup.ACTIVE_TAB,
    }),
    perPage,
    page,
  })

  const departments = departmentsResponse.data?.departments

  const refresh = () => departmentsCache.useUpdateCache()

  const handleChangeTab = (_, value) => {
    setTab(value)
    if (value !== constants.department.ALL_TAB) {
      return filter.setFilters({
        ...filter.filters,
        active: value === constants.department.ACTIVE_TAB,
      })
    }

    const filters = filter.filters
    delete filters.active
    filter.setFilters({ ...filters })
  }

  return (
    <Page title="Departamentos">
      <LoadingFeedback open={departmentsResponse.isFetching} />
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Listagem"
          menu="Departamentos"
          subtitle="Exibição dos departamentos"
        >
          <Box ml={1}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
        </ContentHeader>
        <Box
          mt={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              {constants.department.TABS.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  onClick={(event) => handleChangeTab(event, tab.value)}
                />
              ))}
            </Tabs>
          </Box>
        </Box>
        <Card>
          <Box width="100%" display="flex" alignItems="center">
            <Grid container>
              <Grid item xs={6}>
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  ml={2}
                >
                  <Typography variant="h5">Departamentos</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box p={2} display="flex" justifyContent="flex-end">
                  <Permitted tag={constants.permissions.DEPARTMENT.CREATE}>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<PlusIcon />}
                      onClick={() => history.push(routes.departments.new)}
                    >
                      Criar
                    </Button>
                  </Permitted>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <DepartmentTables departments={departments ?? []} refresh={refresh} />
          <Box px={2} display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              count={departmentsResponse.data?.meta?.totalCount ?? 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Card>
      </Container>

      <DepartmentFilter filter={filter} />
    </Page>
  )
}

export default DepartmentsMain
