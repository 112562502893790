import { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Card,
  Container,
  ContentHeader,
  LoadingFeedback,
  Page,
} from 'components'
import { TechnicalFaqsForm } from './components'

import { useFetch } from 'hooks'

import * as service from 'service'

const TechnicalFaqsEdit = ({ match }) => {
  const [loading, setLoading] = useState(false)

  const { technicalFaqId } = match?.params

  const { response, isLoading, refresh, error } = useFetch(
    service.dponet.technicalFaqs.get,
    {
      technicalFaqId,
    },
    [technicalFaqId],
  )

  const technicalFaq = response?.data?.technicalFaq

  return (
    <Page title="Editar FAQ - Apoio Técnico">
      <LoadingFeedback open={loading || isLoading} />
      <Container spacingXl={40} spacingLg={25} spacingMd={10} spacingSm={10}>
        <ContentHeader menu="Apoio Técnico" title="Editar" subtitle="FAQs" />
        <Card title="Editar FAQ do apoio técnico">
          {((!isLoading && technicalFaq) || error) && (
            <TechnicalFaqsForm
              setLoading={setLoading}
              refresh={refresh}
              formData={technicalFaq}
              isEdit
            />
          )}
        </Card>
      </Container>
    </Page>
  )
}

TechnicalFaqsEdit.propTypes = {
  match: PropTypes.object,
}

export default TechnicalFaqsEdit
