import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  LoadingFeedback,
  Page,
  Permitted,
} from 'components'

import { ProfilesTable } from 'components/Profiles'
import { Plus as PlusIcon } from 'react-feather'

import { useHistory } from 'react-router-dom'
import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import constants from 'constants/index'
import * as service from 'service'
import { routes } from 'Routes'

const CollaboratorProfilesMain = () => {
  const filter = useFilter()
  const theme = useTheme()
  const history = useHistory()

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = useFetch(
    service.dponet.collaboratorProfiles.get,
    {
      ...{ ...filter.filters },
      status: true,
      perPage,
      page,
      idNot: 1,
    },
    [page, perPage, filter.filters],
  )

  return (
    <Page title="Listagem Perfis de Colaborador">
      <LoadingFeedback open={isLoading} />
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Listagem"
          menu="Perfis de colaborador"
          subtitle="Perfis de colaborador"
        >
          <Box ml={1}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
        </ContentHeader>
        <Card>
          <Box width="100%">
            <Grid container>
              <Grid item xs={6}>
                <Box
                  width="100%"
                  p={2}
                  height="100%"
                  display="flex"
                  alignItems="center"
                >
                  <Typography variant="h5">Perfis de colaborador</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box p={2} display="flex" justifyContent="flex-end">
                  <Permitted
                    tag={constants.permissions.COLLABORATOR_PROFILES.CREATE}
                  >
                    <Button
                      onClick={() =>
                        history.push(routes.collaboratorProfiles.new)
                      }
                      variant="outlined"
                      color="primary"
                      startIcon={<PlusIcon />}
                    >
                      Criar
                    </Button>
                  </Permitted>
                </Box>
              </Grid>
            </Grid>
            <Divider />
          </Box>
          <ProfilesTable
            profiles={response?.data?.profiles || []}
            isCollaborator
            refresh={refresh}
          />
          <Box px={2} display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Card>
      </Container>
      <Filters filter={filter}>
        <input textfieldinput="true" label="Identificador" name="id" />
        <input textfieldinput="true" label="Nome" name="name" />
      </Filters>
    </Page>
  )
}

export default CollaboratorProfilesMain
