import * as yup from 'yup'
import constants from 'constants/index'

const schema = yup.object().shape({
  kind: yup.string().required(),
  type: yup.string().required(),
  fantasyName: yup.string().required().max(60),
  document: yup.string().required(),
  phone: yup.string().required(),
  email: yup.string().required('Digite um E-mail valido'),
  segmentId: yup.string().required(),
  typeTrial: yup.string().when('type', {
    is: constants.companies.TYPE_TRIAL,
    then: yup.string().required(),
  }),
  name: yup.string().when('kind', {
    is: constants.companies.KIND_COMPANY,
    then: yup.string().required().max(60),
  }),
  postalCode: yup.string().when('type', {
    is: constants.companies.TYPE_REAL,
    then: yup.string().required(),
  }),
  country: yup.string().when('type', {
    is: constants.companies.TYPE_REAL,
    then: yup.string().required(),
  }),
  state: yup.string().when('type', {
    is: constants.companies.TYPE_REAL,
    then: yup.string().required(),
  }),
  city: yup.string().when('type', {
    is: constants.companies.TYPE_REAL,
    then: yup.string().required(),
  }),
  neighborhood: yup.string().when('type', {
    is: constants.companies.TYPE_REAL,
    then: yup.string().required(),
  }),
  street: yup.string().when('type', {
    is: constants.companies.TYPE_REAL,
    then: yup.string().required(),
  }),
  number: yup.string().when('type', {
    is: constants.companies.TYPE_REAL,
    then: yup.string().required(),
  }),
  complement: yup.string(),
  sellerId: yup.string().required(),
  endDate: yup.date().when('type', {
    is: constants.companies.TYPE_TRIAL,
    then: yup.date().required().typeError('Confirme uma data'),
  }),
  responsibleName: yup.string().required(),
  responsibleEmail: yup.string().email().required(),
  responsiblePhone: yup.string().required(),
  planId: yup.number().required(),
})

export default schema
