import api from 'service/api'

const dponetAPI = api.create('dponet')
const dponetAPINotTreat = api.create('dponet', false)

const get = async ({ ...params }) => {
  return await dponetAPI.get('/user_collaborators', { params })
}

const changeStatus = async ({ userId, ...data }) => {
  return await dponetAPINotTreat.put(
    `/user_collaborators/${userId}/change_status`,
    data,
  )
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('/user_collaborators', data)
}

const edit = async ({ userId = '', ...data }) => {
  return await dponetAPI.put(`/user_collaborators/${userId}`, data)
}

const exportArchive = async ({ ...params }) => {
  return await dponetAPI.get('/user_collaborators/export', { params })
}

const userCollaborators = {
  get,
  changeStatus,
  create,
  edit,
  exportArchive,
}

export default userCollaborators
