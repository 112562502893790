import PropTypes from 'prop-types'

import { Box, Typography, Grid, TableContainer } from '@material-ui/core'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import useStyles from './styles'

import helpers from 'helpers'
import constants from 'constants/index'

const DataTreatmentAgent = ({ dataTreatmentAgents, isLoading }) => {
  const classes = useStyles()

  return (
    <>
      <Box mt={3} mb={2}>
        <Typography variant="h5">Agentes de tratamentos</Typography>
      </Box>
      <Grid container className={classes.dataContainer}>
        <Grid className={classes.dataTable} item xs={12}>
          {!isLoading && (
            <TableContainer>
              <Table
                size="small"
                emptyMessage="Nenhum agente de tratamento foi encontrado"
                noWrap
              >
                <TableHead>
                  <TableRow>
                    <TableCell width="45%">Nome</TableCell>
                    <TableCell width="35%">CPF/CNPJ</TableCell>
                    <TableCell width="20%">Tipo</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataTreatmentAgents?.map((dataTreatmentAgent) => (
                    <TableRow key={dataTreatmentAgent?.id}>
                      <TableCell>{dataTreatmentAgent?.name}</TableCell>
                      <TableCell>
                        {helpers.formatters.cnpjAndCpfMask(
                          dataTreatmentAgent?.document,
                        ) || '-'}
                      </TableCell>
                      <TableCell>
                        {
                          constants.dataTreatmentAgent.TRANSLATED_TYPE[
                            dataTreatmentAgent?.agentType
                          ]
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </>
  )
}

DataTreatmentAgent.propTypes = {
  dataTreatmentAgents: PropTypes.arrayOf(PropTypes.object),
}

export default DataTreatmentAgent
