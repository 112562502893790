import * as yup from 'yup'

const schemaDepartmentUser = yup.object().shape({
  responsible: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(),
      }),
    )
    .required()
    .typeError('Preencha esse campo'),
})

export default schemaDepartmentUser
