import { useState } from 'react'
import { Button, Typography, makeStyles, Fade } from '@material-ui/core'
import styles from './styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(styles)

const IconButton = ({
  Icon,
  text,
  onClick,
  buttonDisabled = false,
  noHover = false,
  ...rest
}) => {
  const [isHovered, setHovered] = useState(false)

  const classes = useStyles()

  const handleMouseHover = () => {
    setHovered(!isHovered)
  }

  return (
    <Button
      onMouseEnter={handleMouseHover}
      onMouseLeave={handleMouseHover}
      className={classes.buttonIB}
      classes={{
        label: classes.buttonLabelIB,
      }}
      onClick={onClick}
      disableRipple
      variant="outlined"
      disabled={buttonDisabled}
    >
      {!isHovered && !noHover ? (
        <Icon {...rest} className={classes.iconMarginIB} />
      ) : (
        <>
          {!noHover ? (
            <>
              <Fade in={isHovered}>
                <Icon {...rest} className={classes.iconMarginIB} />
              </Fade>
              <Fade in={isHovered}>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  className={classes.buttonTitleIB}
                >
                  {text.toUpperCase()}
                </Typography>
              </Fade>
            </>
          ) : (
            <>
              <Icon {...rest} className={classes.iconMarginIB} />
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.buttonTitleIB}
              >
                {text.toUpperCase()}
              </Typography>
            </>
          )}
        </>
      )}
    </Button>
  )
}

IconButton.propTypes = {
  Icon: PropTypes.any,
  text: PropTypes.string,
  buttonDisabled: PropTypes.bool,
}

export default IconButton
