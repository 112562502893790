import { useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Button,
  Typography,
} from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'

import {
  Documents,
  Revision,
  GeneralInformation,
  DataLifeCycle,
  DataTreatment,
  DataCollected,
  NecessityProportionality,
  DataTreatmentAgent,
} from './components/New'
import { Page, ContentHeader, LoadingFeedback } from 'components'
import Stepper from './components/New/Stepper'

import useDataProcess from 'hooks/useDataProcess'

import useStyles from './styles'
import helpers from 'helpers'

const DataProcessesNew = () => {
  const classes = useStyles()
  const steps = helpers.dataProcesses
    .getTextsSteps()
    .map((textStep) => textStep.step)

  const {
    loadRelationOptions,
    lastActiveStep,
    activeStep,
    backStep,
    setToNextStep,
    companyId,
    setDepartments,
    setDataProcessSources,
    setStorageTimes,
    setDiscardModes,
    setInternalAllocationModes,
    setRecoveries,
    setShareProcesses,
    setProtections,
    setDataCollectedOptions,
    setTitularCategories,
    setStorageLocations,
    setData,
    setLawsOptions,
  } = useDataProcess()

  const [relationOptionsResponse, departmentsResponse] =
    loadRelationOptions(companyId)

  const relationOptions = relationOptionsResponse.data?.options

  const loading =
    relationOptionsResponse.isLoading || departmentsResponse.isLoading

  useEffect(() => {
    if (
      !relationOptionsResponse.isFetching &&
      relationOptionsResponse.isFetched
    ) {
      setDataProcessSources(relationOptions.dataProcessSources)
      setStorageLocations(relationOptions.storageLocations)
      setStorageTimes(relationOptions.storageTimes)
      setDiscardModes(relationOptions.discardModes)
      setInternalAllocationModes(relationOptions.internalAllocationModes)
      setRecoveries(relationOptions.recoveries)
      setShareProcesses(relationOptions.shareProcesses)
      setProtections(relationOptions.protections)
      setDataCollectedOptions(
        helpers.dataCollected.mountLabelList(
          relationOptions.dataCollectedOptions,
        ),
      )
      setTitularCategories(relationOptions.titularCategories)
      setLawsOptions(relationOptions.laws)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relationOptionsResponse.isFetching])

  useEffect(() => {
    if (!departmentsResponse.isFetching && departmentsResponse.isFetched) {
      setDepartments(departmentsResponse.data?.departments)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentsResponse.isFetching])

  useEffect(() => {
    setData({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page className={classes.root} title="Novo processo">
      <LoadingFeedback open={loading && companyId ? true : false} />
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader menu="Processos" title="Novo" />
        <Box display="flex" width="100%" height="auto" mt={3}>
          <Paper className={classes.fullCardWidth}>
            <Box
              display="flex"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              p={2}
              fontWeight={500}
            >
              <Typography variant="subtitle1">
                <Box fontWeight="bold">
                  {helpers.dataProcesses.getTextsSteps()[activeStep].title}
                </Box>
              </Typography>
              <Typography variant="subtitle1">
                Etapa {activeStep + 1} de 8
              </Typography>
            </Box>
            <Divider />
            <Grid container>
              <Grid item lg={9} md={8} xs={12}>
                <Box maxHeight="50%" width="100%">
                  <PerfectScrollbar options={{ suppressScrollX: true }}>
                    {activeStep === 0 && (
                      <GeneralInformation
                        description={
                          helpers.dataProcesses.getTextsSteps()[activeStep]
                            .description
                        }
                      />
                    )}
                    {activeStep === 1 && (
                      <DataCollected
                        description={
                          helpers.dataProcesses.getTextsSteps()[activeStep]
                            .description
                        }
                      />
                    )}
                    {activeStep === 2 && (
                      <DataLifeCycle
                        description={
                          helpers.dataProcesses.getTextsSteps()[activeStep]
                            .description
                        }
                      />
                    )}
                    {activeStep === 3 && (
                      <DataTreatment
                        description={
                          helpers.dataProcesses.getTextsSteps()[activeStep]
                            .description
                        }
                      />
                    )}
                    {activeStep === 4 && <DataTreatmentAgent />}
                    {activeStep === 5 && (
                      <NecessityProportionality
                        description={
                          helpers.dataProcesses.getTextsSteps()[activeStep]
                            .description
                        }
                      />
                    )}
                    {activeStep === 6 && (
                      <Documents
                        description={
                          helpers.dataProcesses.getTextsSteps()[activeStep]
                            .description
                        }
                      />
                    )}
                    {activeStep === 7 && (
                      <Revision
                        description={
                          helpers.dataProcesses.getTextsSteps()[activeStep]
                            .description
                        }
                      />
                    )}
                  </PerfectScrollbar>
                </Box>
              </Grid>

              <Grid item lg={3} md={4} xs={12}>
                <Stepper lastActiveStep={lastActiveStep} steps={steps}>
                  <Box p={1}>
                    <Grid
                      container
                      display="flex"
                      direction="row"
                      className={classes.gridButton}
                    >
                      <Button
                        variant="contained"
                        onClick={backStep}
                        disabled={activeStep === 0}
                      >
                        VOLTAR
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<CheckIcon />}
                        onClick={() => setToNextStep(true)}
                      >
                        {activeStep === steps.length - 1 ? 'SALVAR' : 'PRÓXIMO'}
                      </Button>
                    </Grid>
                  </Box>
                </Stepper>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </Page>
  )
}

export default DataProcessesNew
