import Main from './UsersMain'
import New from './UsersNew'
import Edit from './UsersEdit'

const Users = {
  Main,
  New,
  Edit,
}

export default Users
