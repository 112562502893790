/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { Box } from '@material-ui/core'

import {
  Page,
  Container,
  ContentHeader,
  LoadingBox,
  LoadingFeedback,
} from 'components'
import { Edit, View } from './components'

import useDataProcess from 'hooks/useDataProcess'
import { useDataProcessesCache } from 'hooks/caches'
import { RmcProvider } from 'providers'

import constants from 'constants/index'
import useStyles from './styles'
import helpers from 'helpers'
import * as service from 'service'
const { StatusHeader, CardBar } = View
const { Tabs } = Edit

const DataProcessesEdit = ({ match }) => {
  const classes = useStyles()

  const { dataProcessId } = match.params

  const {
    loadRelationOptions,
    setData,
    setNonComplianceReports,
    setLegalFrameworks,
    setDepartments,
    setDataProcessSources,
    setStorageTimes,
    setDiscardModes,
    setInternalAllocationModes,
    setRecoveries,
    setShareProcesses,
    setStorageLocations,
    setProtections,
    setDataCollectedOptions,
    setTitularCategories,
    clearLoadedDataProcess,
    setLawsOptions,
  } = useDataProcess()

  const dataProcessCache = useDataProcessesCache()

  const [dataProcessResponse, legalFrameworkResponse] =
    dataProcessCache.useOptions(dataProcessId, { withoutLogs: true })

  const dataProcess = dataProcessResponse.data?.dataProcess
  const legalFrameworks = legalFrameworkResponse.data?.legalFrameworks

  const [relationOptionsResponse, departmentsResponse] = loadRelationOptions(
    dataProcess?.company?.id,
  )

  const relationOptions = relationOptionsResponse.data?.options
  const departments = departmentsResponse.data?.departments

  useEffect(() => {
    if (!dataProcessResponse.isLoading) setData(dataProcess)
  }, [dataProcessResponse.isFetching])

  useEffect(() => {
    if (
      !relationOptionsResponse.isFetching &&
      relationOptionsResponse.isFetched
    ) {
      setDataProcessSources(relationOptions.dataProcessSources)
      setStorageLocations(relationOptions.storageLocations)
      setStorageTimes(relationOptions.storageTimes)
      setDiscardModes(relationOptions.discardModes)
      setInternalAllocationModes(relationOptions.internalAllocationModes)
      setRecoveries(relationOptions.recoveries)
      setShareProcesses(relationOptions.shareProcesses)
      setProtections(relationOptions.protections)
      setDataCollectedOptions(
        helpers.dataCollected.mountLabelList(
          relationOptions.dataCollectedOptions,
        ),
      )
      setTitularCategories(relationOptions.titularCategories)
      setLawsOptions(relationOptions.laws)
    }
  }, [relationOptionsResponse.isFetching])

  useEffect(() => {
    if (!departmentsResponse.isFetching && departmentsResponse.isFetched) {
      setDepartments(departments)
    }
  }, [departmentsResponse.isFetching])

  useEffect(() => {
    if (
      !legalFrameworkResponse.isFetching &&
      legalFrameworkResponse.isFetched
    ) {
      setLegalFrameworks(legalFrameworks)
    }
  }, [legalFrameworkResponse.isFetching])

  useEffect(() => {
    const loadNonComplianceReports = () => {
      if (!dataProcess) return

      if (dataProcess.nonComplianceReport) {
        service.dponet.nonComplianceReports
          .get({
            entityType: constants.nonComplianceReports.ENTITY_DATA_PROCESS,
            entityId: dataProcess.id,
          })
          .then((responseNonComplianceReports) => {
            setNonComplianceReports(
              responseNonComplianceReports.data.nonComplianceReports,
            )
          })
      }
    }

    loadNonComplianceReports()
  }, [dataProcess])

  const loading =
    dataProcessResponse.isFetching ||
    legalFrameworkResponse.isFetching ||
    relationOptionsResponse.isFetching ||
    departmentsResponse.isFetching

  const firstLoading =
    dataProcessResponse.isLoading ||
    legalFrameworkResponse.isLoading ||
    relationOptionsResponse.isLoading ||
    departmentsResponse.isLoading

  useEffect(() => {
    return () => {
      clearLoadedDataProcess()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Page className={classes.root} title="Editar processo">
      <Container
        maxWidth={false}
        spacingXl={40}
        spacingLg={25}
        spacingMd={20}
        spacingSm={5}
        spacingXs={5}
        className={classes.container}
      >
        <ContentHeader
          menu="Data Mapping"
          title="Editar"
          subtitle="Processamento de dados"
        />
        {firstLoading ? (
          <LoadingBox open={loading} />
        ) : (
          <>
            <LoadingFeedback open={loading} />
            <StatusHeader
              name={dataProcess?.name}
              id={dataProcess?.id}
              companyName={dataProcess?.company?.name}
              statusId={dataProcess?.statusId}
            />
            <Box mt={4} mb={3}>
              <CardBar
                departmentName={dataProcess?.department?.name}
                statusId={dataProcess?.statusId}
                fragility={dataProcess?.fragility}
              />
            </Box>
            <Box my={2} mb={6}>
              <RmcProvider>
                <Tabs />
              </RmcProvider>
            </Box>
          </>
        )}
      </Container>
    </Page>
  )
}

export default DataProcessesEdit
