import PropTypes from 'prop-types'

import {
  Box,
  Card,
  Grid,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core'

import styles from './styles'
import { LoadingTextComponent } from 'components'

const useStyles = makeStyles(styles)

const TopCards = ({ cardsList, loading }) => {
  const classes = useStyles()

  return (
    <Box my={4}>
      <Grid container spacing={2}>
        {cardsList.map((card, index) => (
          <Grid key={index} item md={4} xs={12}>
            <Card>
              <Box display="flex" alignItems="center" width="100%" py={2}>
                <Box px={2}>
                  <Box
                    id={`card-${index}`}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className={classes.icon}
                  >
                    {card.icon}
                  </Box>
                </Box>
                <Box
                  className={classes.textBox}
                  textOverflow="ellipsis"
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  pr={2}
                >
                  <Tooltip title={card.title}>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      gutterBottom
                      noWrap
                    >
                      {card.title}
                    </Typography>
                  </Tooltip>
                  <LoadingTextComponent loading={loading}>
                    <Tooltip title={card?.description || '-'}>
                      <Typography
                        color="textPrimary"
                        variant="subtitle1"
                        className={classes.descriptionTypography}
                        noWrap
                      >
                        {card?.description || '-'}
                      </Typography>
                    </Tooltip>
                  </LoadingTextComponent>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

TopCards.prototype = {
  cardsList: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  loading: PropTypes.bool,
}

export default TopCards
