import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'
import { reverse } from 'named-urls'
import { Tabs, Tab, Box, Divider, Button } from '@material-ui/core'

import GeneralInformation from '../GeneralInformation'
import DataCollecteds from '../DataCollecteds'
import DataTreatments from '../DataTreatments'
import DataTreatmentAgents from '../DataTreatmentAgents'
import LifeCycles from '../LifeCycles'
import DocumentedInformation from '../DocumentedInformation'
import NecessityProportionality from '../NecessityProportionality'
import DataCollectedsView from '../../View/DataCollecteds'
import DataTreatmentsView from '../../View/DataTreatments'
import LifeCyclesView from '../../View/LifeCycles'
import DocumentedInformationView from '../../View/DocumentedInformation'
import NecessityProportionalityView from '../../View/NecessityProportionality'

import RmcProvider from 'providers/RmcProvider'
import DocumentsProvider from 'providers/DocumentsProvider'
import useDataProcess from 'hooks/useDataProcess'
import useRmc from 'hooks/useRmc'

import useStyles from './styles'
import constants from 'constants/index'
import { routes } from 'Routes'

const TabsDataProcess = () => {
  const location = useLocation()
  const history = useHistory()
  const search = qs.parse(location.search)
  const classes = useStyles()
  const { submitting, setSubmitting, nonComplianceReport, data } =
    useDataProcess()

  const { setData } = useRmc()

  useEffect(() => {
    if (nonComplianceReport)
      setData({ nonComplianceReport: nonComplianceReport })

    // eslint-disable-next-line
  }, [])

  const onSubmit = () => {
    setSubmitting(submitting + 1)
  }

  const [currentTab, setCurrentTab] = useState(
    search.tab || constants.dataProcess.TAB_VIEW_VALUE.GENERAL,
  )

  const replaceLocation = (origin) => {
    history.replace(`${location.pathname}?tab=${origin}`)
  }

  const handleTabsChange = (_, value) => {
    setCurrentTab(value)
    replaceLocation(value)
  }

  return (
    <>
      <Box width="100%" display="flex" justifyContent="center">
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
          textColor="secondary"
        >
          {constants.dataProcess.TABS_EDIT.map((tab) => (
            <Tab
              className={classes.root}
              key={tab.value}
              label={tab.label}
              value={tab.value}
              onClick={(e) => handleTabsChange(e, tab.value)}
            />
          ))}
        </Tabs>
      </Box>
      <Divider />
      <RmcProvider>
        <Box mb={3}>
          {currentTab === constants.dataProcess.TAB_VIEW_VALUE.GENERAL && (
            <GeneralInformation />
          )}
          {data?.company?.kind === constants.companies.KINDS.TEMPLATE ? (
            <>
              {currentTab ===
                constants.dataProcess.TAB_VIEW_VALUE.DATA_COLLECTS && (
                <DataCollecteds />
              )}
              {currentTab ===
                constants.dataProcess.TAB_VIEW_VALUE.LIFE_CYCLES && (
                <LifeCycles />
              )}
              {currentTab ===
                constants.dataProcess.TAB_VIEW_VALUE.DATA_TREATMENTS && (
                <DataTreatments />
              )}
              {currentTab ===
                constants.dataProcess.TAB_VIEW_VALUE.DATA_TREATMENT_AGENTS && (
                <DataTreatmentAgents
                  companyId={data?.company?.id}
                  dataProcessId={data?.id}
                />
              )}
              {currentTab ===
                constants.dataProcess.TAB_VIEW_VALUE.DOCUMENTS && (
                <DocumentsProvider documents={data.documents}>
                  <DocumentedInformation />
                </DocumentsProvider>
              )}
              {currentTab ===
                constants.dataProcess.TAB_VIEW_VALUE
                  .NECESSITY_PROPORTIONALITY && <NecessityProportionality />}
            </>
          ) : (
            <>
              {currentTab ===
                constants.dataProcess.TAB_VIEW_VALUE.DATA_COLLECTS && (
                <DataCollectedsView />
              )}
              {currentTab ===
                constants.dataProcess.TAB_VIEW_VALUE.LIFE_CYCLES && (
                <LifeCyclesView />
              )}
              {currentTab ===
                constants.dataProcess.TAB_VIEW_VALUE.DATA_TREATMENTS && (
                <DataTreatmentsView />
              )}
              {currentTab ===
                constants.dataProcess.TAB_VIEW_VALUE.DOCUMENTS && (
                <DocumentedInformationView />
              )}
              {currentTab ===
                constants.dataProcess.TAB_VIEW_VALUE
                  .NECESSITY_PROPORTIONALITY && (
                <NecessityProportionalityView />
              )}
            </>
          )}
        </Box>
        <Box display="flex">
          <Box mr={2}>
            <Button
              variant="contained"
              onClick={() =>
                history.push(
                  reverse(routes.dataProcess.view, { dataProcessId: data.id }),
                )
              }
            >
              Cancelar
            </Button>
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              onClick={onSubmit}
              id="submit-button-data-process"
            >
              Salvar a edição
            </Button>
          </Box>
        </Box>
      </RmcProvider>
    </>
  )
}

export default TabsDataProcess
