import { useState } from 'react'

import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'

import { isEmpty } from 'lodash'

import constants from 'constants/index'

import { ConfirmationDialog, Permitted, ButtonLogs } from 'components'

import * as service from 'service'

import useSnackbar from 'hooks/useSnackbar'
import { useHistory } from 'react-router-dom'

import { reverse } from 'named-urls'

import { routes } from 'Routes'

import helpers from 'helpers'
import { getBaseURL } from 'service/env'

const ActionButtons = ({ plan, refresh, isEdit }) => {
  const [openActivateDialog, setOpenActivateDialog] = useState(false)
  const [openInactivateDialog, setOpenInactivateDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const snackbar = useSnackbar()
  const history = useHistory()

  const handleActiveOrInactive = async () => {
    try {
      await service.dponet.plans.changeStatus({ planId: plan?.id })

      snackbar.open({
        message: `Segmento e preço ${
          plan?.status ? 'inativado' : 'ativado'
        } com sucesso!`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: `Falha ao ${
          plan?.status ? 'inativar' : 'ativar'
        } segmento e preço.`,
        variant: 'error',
      })
    }
    setOpenActivateDialog(false)
    setOpenInactivateDialog(false)
    refresh()
  }

  const handleDelete = async () => {
    try {
      await service.dponet.plans.destroy({ planId: plan?.id })

      snackbar.open({
        message: 'Segmento e preço excluido com sucesso!',
        variant: 'success',
      })

      history.push(routes.integrationPlans.all)
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
      })
    }
    setOpenDeleteDialog(false)
    refresh()
  }

  const redirectToSubscription = (segmentName) => {
    window.open(
      `${getBaseURL('website')}/subscription?segment=${segmentName}`,
      '_blank',
    )
  }

  return (
    <>
      {!isEdit && <ButtonLogs entityId={plan?.id} routes="plans" />}
      <Permitted tag={constants.permissions.PLANS.CREATE}>
        {isEdit && (
          <Button
            fullWidth
            size="small"
            onClick={() =>
              history.push(
                reverse(routes.integrationPlans.edit, {
                  planId: plan?.id,
                }),
              )
            }
          >
            Editar
          </Button>
        )}
        {plan?.status ? (
          <Button
            fullWidth
            size="small"
            onClick={() => {
              setOpenInactivateDialog(true)
            }}
          >
            Inativar
          </Button>
        ) : (
          <Button
            fullWidth
            size="small"
            onClick={() => {
              setOpenActivateDialog(true)
            }}
          >
            Ativar
          </Button>
        )}
      </Permitted>
      <Permitted tag={constants.permissions.PLANS.DESTROY}>
        <Button
          fullWidth
          size="small"
          onClick={() => {
            setOpenDeleteDialog(true)
          }}
        >
          Excluir
        </Button>
      </Permitted>
      {!isEmpty(plan?.segment) && (
        <Button
          fullWidth
          size="small"
          onClick={() => {
            redirectToSubscription(plan?.segment?.name)
          }}
        >
          Acessar
        </Button>
      )}
      <ConfirmationDialog
        open={openActivateDialog}
        setOpen={setOpenActivateDialog}
        message={constants.plan.ACTIVATE_DIALOG}
        cancelButtonText="Cancelar"
        buttonText="Ativar"
        actionAcceptButton={handleActiveOrInactive}
      />
      <ConfirmationDialog
        open={openInactivateDialog}
        setOpen={setOpenInactivateDialog}
        message={constants.plan.INACTIVATE_DIALOG}
        cancelButtonText="Cancelar"
        buttonText="Inativar"
        actionAcceptButton={handleActiveOrInactive}
      />
      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        message={constants.plan.DESTROY_DIALOG}
        cancelButtonText="Cancelar"
        buttonText="Excluir"
        actionAcceptButton={handleDelete}
      />
    </>
  )
}

ActionButtons.propTypes = {
  plan: PropTypes.object,
  refresh: PropTypes.func,
  isEdit: PropTypes.bool,
}

ActionButtons.defaultProps = {
  refresh: () => {},
  isEdit: false,
}

export default ActionButtons
