import permissions from '../../permissions'

const EXPORT_RIPD_LIST = [
  {
    name: 'Permitir/Negar acesso ao menu',
    tag: permissions.EXPORT_RIPD.EXPORT_RIPD_ADMIN,
  },
]

export default EXPORT_RIPD_LIST
