import { useState } from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { ChangeStatusOrDestroy } from '../'
import { Box } from '@material-ui/core'

import EconomicActivityRow from '../EconomicActivityRow'
import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'

const EconomicAtivitiesTable = ({ activities, refresh }) => {
  const [selectedActivity, setSelectedActivity] = useState({})
  const [openActivity, setOpenActivity] = useState(false)
  const [isDestroy, setIsDestroy] = useState(null)

  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhuma atividade econômica encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="25%" align="left">
                Nome
              </TableCell>
              <TableCell width="10%" align="center">
                Sessão
              </TableCell>
              <TableCell width="10%" align="center">
                Divisão
              </TableCell>
              <TableCell width="10%" align="left">
                Grupo
              </TableCell>
              <TableCell width="10%" align="left">
                Identificador CNAE
              </TableCell>
              <TableCell width="5%" align="center">
                Status
              </TableCell>
              <TableCell width="20%" align="center">
                Inativo em
              </TableCell>
              <TableCell width="5%" align="left">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activities?.map((activity) => (
              <EconomicActivityRow
                activity={activity}
                key={activity.id}
                setSelectedActivity={setSelectedActivity}
                setOpenActivity={setOpenActivity}
                setIsDestroy={setIsDestroy}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      {selectedActivity?.id && (
        <ChangeStatusOrDestroy
          activity={selectedActivity}
          refresh={refresh}
          setOpenActivity={setOpenActivity}
          openActivity={openActivity}
          isDestroy={isDestroy}
        />
      )}
    </Box>
  )
}

EconomicAtivitiesTable.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func,
}

EconomicAtivitiesTable.defaultProps = {
  refresh: () => {},
}

export default EconomicAtivitiesTable
