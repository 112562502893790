import { useEffect, useState, useRef } from 'react'

import { Box, Grid, TablePagination, useMediaQuery } from '@material-ui/core'
import {
  Container,
  ContentHeader,
  FilterButton,
  LoadingFeedback,
  NoData,
  Page,
} from 'components'
import { LegalFrameworksFilters, RowLegalFramework } from './components'

import { useFetch, useFilter, usePagination } from 'hooks'

import * as service from 'service'
import helpers from 'helpers'

import theme from 'theme'
import { isEmpty } from 'lodash'

const LegalFrameworksMain = () => {
  const filter = useFilter()
  const ref = useRef()

  const [loading, setLoading] = useState(false)

  const [legalFrameworks, setLegalFrameworks] = useState([])

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(4)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  useEffect(() => {
    const loadLegalFrameworks = async () => {
      const response = await service.dponet.legalFrameworks.get()
      const orderedLegalFrameworks = helpers.dataProcesses.orderLegalFrameworks(
        response?.data?.legalFrameworks,
      )
      setLegalFrameworks(orderedLegalFrameworks)
    }

    loadLegalFrameworks()
  }, [])

  const { response, isLoading } = useFetch(
    service.dponet.dataProcesses.getWithLegalFrameworks,
    {
      ...{ ...filter.filters },
      perPage,
      page,
    },
    [perPage, page, filter.filters],
  )

  useEffect(() => {
    const timer = setTimeout(() => {
      if (ref.current) {
        ref.current.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
        })
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [page, perPage])

  return (
    <Page title="Enquadramento Legal" ref={ref}>
      <LoadingFeedback open={isLoading || loading} />
      <Container
        maxWidth={false}
        spacingXl={15}
        spacingLg={10}
        spacingMd={5}
        spacingSm={5}
      >
        <ContentHeader subtitle="Enquadramento Legal">
          <Box pl={1}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
        </ContentHeader>

        <Grid container spacing={4}>
          {response?.data?.dataProcesses?.map((dataProcess) => (
            <Grid item xs={12} key={dataProcess?.id}>
              <RowLegalFramework
                data={dataProcess}
                legalFrameworks={legalFrameworks}
                setLoading={setLoading}
              />
            </Grid>
          ))}
          {isEmpty(response?.data?.dataProcesses) && (
            <NoData message="Nenhum processamento de dados foi encontrado." />
          )}
        </Grid>
        <TablePagination
          component="div"
          count={response?.data?.meta?.totalCount || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          rowsPerPage={perPage}
          rowsPerPageOptions={[2, 4, 6]}
          labelRowsPerPage={isDesktop ? 'Por página' : ''}
          nextIconButtonProps={{ size: 'small' }}
          backIconButtonProps={{ size: 'small' }}
        />
      </Container>
      <LegalFrameworksFilters filter={filter} />
    </Page>
  )
}

export default LegalFrameworksMain
