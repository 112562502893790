import permissions from '../../permissions'

const ECONOMIC_ACTIVITY_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.ECONOMIC_ACTIVITIES.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.CREATE,
    tag: permissions.ECONOMIC_ACTIVITIES.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.EDIT,
    tag: permissions.ECONOMIC_ACTIVITIES.EDIT,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.DESTROY,
    tag: permissions.ECONOMIC_ACTIVITIES.DESTROY,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.ACTIVATE_OR_INACTIVATE,
    tag: permissions.ECONOMIC_ACTIVITIES.ACTIVATE_OR_INACTIVATE,
  },
]

export default ECONOMIC_ACTIVITY_LIST
