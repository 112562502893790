import { useState } from 'react'

import { Page, Container, ContentHeader } from 'components'
import { DepartmentForm } from './components'

import { useDepartmentsCache } from 'hooks/caches'

import { routes } from 'Routes'

const DepartmentsNew = () => {
  const [loading, setLoading] = useState(false)

  const departmentsCache = useDepartmentsCache()
  const navigation = [{ text: 'Listagem', route: routes.departments.all }]

  const refresh = () => departmentsCache.useUpdateCache()

  return (
    <Page title="Novo departamento">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Novo"
          menu="Departamento"
          subtitle="Departamento"
          menuNavigations={navigation}
        />
        <DepartmentForm
          refresh={refresh}
          setLoading={setLoading}
          loading={loading}
        />
      </Container>
    </Page>
  )
}
export default DepartmentsNew
