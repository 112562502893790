import { Controller } from 'react-hook-form'

import { Label, Select as SelectComponent } from 'components'
import { Grid, TextField, Box } from '@material-ui/core'

import helpers from 'helpers'

import useStyles from '../styles'

const StorageTimeVolumetry = ({ setValue, register, errors, control }) => {
  const classes = useStyles()

  const volumetryOptions = helpers.dataProcesses.selectVolumetryOptions()

  return (
    <Box className={classes.box}>
      <Grid container spacing={2}>
        <Grid item lg={6} xs={12}>
          <Label
            title="Tempo de armazenamento na organização"
            description="Por quanto tempo os dados ficam armazenados na organização?"
            item
            xs={12}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Controller
                  as={
                    <TextField
                      type="number"
                      variant="outlined"
                      error={!!errors?.value}
                      helperText={errors?.value?.message}
                      fullWidth
                    />
                  }
                  register={register}
                  control={control}
                  name="value"
                  mode="onBlur"
                  onBlur={([event]) => {
                    return setValue(event.target.name, event.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={8}>
                <Controller
                  as={
                    <SelectComponent
                      items={volumetryOptions}
                      error={!!errors?.volumetry}
                      helperText={errors?.volumetry?.message}
                    />
                  }
                  register={register}
                  control={control}
                  name="volumetry"
                  mode="onBlur"
                  onBlur={([event]) => {
                    return setValue(event.target.name, event.target.value)
                  }}
                />
              </Grid>
            </Grid>
          </Label>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Label
            title="Justificativa do tempo de armazenamento"
            description="Por que os dados ficam armazenados na organização por este tempo?"
            item
            xs={12}
          >
            <Controller
              as={
                <TextField
                  variant="outlined"
                  error={!!errors?.deadlineJustification}
                  helperText={errors?.deadlineJustification?.message}
                  fullWidth
                />
              }
              register={register}
              control={control}
              name="deadlineJustification"
              mode="onBlur"
              onBlur={([event]) => {
                return setValue(event.target.name, event.target.value)
              }}
            />
          </Label>
        </Grid>
      </Grid>
    </Box>
  )
}
export default StorageTimeVolumetry
