import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1, 2),
  },
  children: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      justifyContent: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
}))
