import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip } from '@material-ui/core'
import { Upload } from 'react-feather'

import { FormAccess } from './components'

const AccessButton = ({ paramsFilter }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Tooltip title="Acessos">
        <Button
          onClick={() => setOpen(true)}
          startIcon={<Upload size="18" />}
          variant="contained"
          color="primary"
        >
          Acessos
        </Button>
      </Tooltip>
      <FormAccess open={open} setOpen={setOpen} paramsFilter={paramsFilter} />
    </>
  )
}

AccessButton.propTypes = {
  paramsFilter: PropTypes.object,
}

export default AccessButton
