import { Box, Typography } from '@material-ui/core'
import { Card } from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'

import useSTyles from '../../styles'

const Feedback = ({ data, loading }) => {
  const classes = useSTyles()

  return (
    <Card>
      <Box m={6} style={{ height: '400px' }}>
        <Box mb={2}>
          <Typography variant="h4">Respostas:</Typography>
        </Box>
        <PerfectScrollbar
          options={{
            wheelPropagation: false,
            useBothWheelAxes: false,
            swipeEasing: true,
          }}
        >
          <Box mb={1}>
            {loading && <Typography>Carregando...</Typography>}
            <Typography className={classes.preLine}>
              {data.replaceAll('data:', '')}
            </Typography>
          </Box>
        </PerfectScrollbar>
      </Box>
    </Card>
  )
}

export default Feedback
