import Main from './PreRegistrationsMain'
import Show from './PreRegistrationsShow'
import New from './PreRegistrationsNew'
import Edit from './PreRegistrationsEdit'

const PreRegistrations = {
  Main,
  Show,
  New,
  Edit,
}

export default PreRegistrations
