import * as yup from 'yup'

const schemaUserCompany = (isEdit, phoneRequired) =>
  yup.object().shape({
    ...(!isEdit && {
      companyId: yup.object().required('Selecione uma empresa').nullable(),
    }),
    ...(!isEdit && {
      isResponsable: yup
        .boolean()
        .typeError('Selecione uma das opções')
        .required(),
    }),
    ...(phoneRequired && { phone: yup.string().required() }),
    profileId: yup.string().required(),
  })

export default schemaUserCompany
