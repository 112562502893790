import { Box, Grid, Typography } from '@material-ui/core'
import { isEmpty } from 'lodash'

import { NoData } from 'components'
import { DropdownDetails } from '../../'

import useDataProcess from 'hooks/useDataProcess'

import { Server as ServerIcon } from 'react-feather'

import helpers from 'helpers'

const LifeCycles = () => {
  const { data } = useDataProcess()

  const objectToMountBody = (lifeCycle) => {
    const valueVolumetry = !!lifeCycle?.value && !!lifeCycle?.volumetry
    return [
      {
        title: 'Onde os dados são armazenados',
        data: lifeCycle?.storageLocation?.name,
      },
      {
        title: 'Ambiente de alocação',
        data: lifeCycle?.internalAllocationMode?.name,
      },
      {
        title: 'Tempo de Armazenamento',
        data: helpers.dataProcesses.storageModeType(
          lifeCycle?.storageType,
          lifeCycle?.value,
          lifeCycle?.volumetry,
          valueVolumetry,
        ),
      },
      {
        title: 'Justificativa de retenção',
        data: lifeCycle?.retentionFinality,
      },
      {
        title: 'Como os dados são dispostos',
        data: helpers.dataProcesses.discardMode(lifeCycle),
      },
      {
        title: 'Forma de proteção',
        data: lifeCycle?.protection?.name,
      },
      {
        title: 'Forma de recuperação',
        data: lifeCycle?.recovery?.name,
      },
    ]
  }
  return (
    <Box>
      {!isEmpty(data?.lifeCycles) ? (
        <>
          <Box my={2}>
            <Typography variant="h4">
              Todos salvaguarda e ciclo de vida
            </Typography>
          </Box>
          <Grid container spacing={2}>
            {data?.lifeCycles?.map((lifeCycle) => (
              <Grid item xs={12} md={6}>
                <DropdownDetails
                  iconTitle={<ServerIcon />}
                  title={lifeCycle?.storageLocation?.name}
                  objectContent={objectToMountBody(lifeCycle)}
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <NoData message="Nenhum salvaguarda e ciclo de vida encontrado" />
      )}
    </Box>
  )
}
export default LifeCycles
