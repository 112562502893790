import { useState, useEffect } from 'react'
import { reverse } from 'named-urls'
import { useHistory } from 'react-router-dom'

import DataProcessContext from 'contexts/DataProcessContext'
import { useSnackbar, useTransferList } from 'hooks'
import { useCompaniesCache, useDataProcessesCache } from 'hooks/caches'

import { routes } from 'Routes'
import helpers from 'helpers'
import * as service from 'service'
import { isEmpty } from 'lodash'

const DataProcessProvider = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0)
  const [code, setCode] = useState('')
  const [companyId, setCompanyId] = useState(0)
  const [counter] = useState(0)
  const [data, setData] = useState({})
  const [dataCollectedOptions, setDataCollectedOptions] = useState([])
  const [dataCollecteds, setDataCollecteds] = useState([])
  const [dataProcessSources, setDataProcessSources] = useState([])
  const [departments, setDepartments] = useState([])
  const [discardModes, setDiscardModes] = useState([])
  const [editLegalFramework, setEditLegalFramework] = useState()
  const [internalAllocationModes, setInternalAllocationModes] = useState([])
  const [lastActiveStep, setLastActiveStep] = useState(0)
  const [legalFrameworks, setLegalFrameworks] = useState([])
  const [logs, setLogs] = useState([])
  const [necDataCollecteds, setNecDataCollecteds] = useState([])
  const [necDataLifeCycles, setNecDataLifeCycles] = useState([])
  const [necDataTreatments, setNecDataTreatments] = useState([])
  const [nonComplianceReports, setNonComplianceReports] = useState([])
  const [protections, setProtections] = useState([])
  const [recoveries, setRecoveries] = useState([])
  const [shareProcesses, setShareProcesses] = useState([])
  const [sourceOptions, setSourceOptions] = useState([])
  const [storageLocations, setStorageLocations] = useState([])
  const [storageTimes, setStorageTimes] = useState([])
  const [submitting, setSubmitting] = useState(0)
  const [titularCategories, setTitularCategories] = useState([])
  const [toNextStep, setToNextStep] = useState(false)
  const [validForms, setValidForms] = useState({
    info: false,
    legalFramework: false,
  })
  const [lawsOptions, setLawsOption] = useState([])
  const [laws, setLaws] = useState([])
  const [volumetryType, setVolumetryType] = useState('')
  const [submitUpdate, setSubmitUpdate] = useState(false)

  const history = useHistory()
  const snackbar = useSnackbar()
  const { right: rightLegalFrameworkList } = useTransferList()
  const companiesCache = useCompaniesCache()
  const dataProcessCache = useDataProcessesCache()

  const formatNecessityProportionality = (
    dataCollectedsForm,
    dataTreatmentsForm,
    lifeCyclesForm,
  ) => {
    let dataFormatted = {}

    dataCollectedsForm.map((dataCollectedForm) => {
      dataCollectedForm.collectedFields.map((collectedField) => {
        dataFormatted[
          `collectedField-${dataCollectedForm.id}-${collectedField.id}`
        ] = helpers.formatters.convertBoolToBinary(collectedField.necessary)

        return dataFormatted
      })
      return dataCollectedForm
    })

    dataTreatmentsForm.map((dataTreatmentForm) => {
      dataFormatted[`dataTreatment-${dataTreatmentForm.id}`] =
        helpers.formatters.convertBoolToBinary(dataTreatmentForm.necessary)

      return dataFormatted
    })

    lifeCyclesForm.map((lifeCycleForm) => {
      dataFormatted[`dataLifeCycle-${lifeCycleForm.id}`] =
        helpers.formatters.convertBoolToBinary(lifeCycleForm.necessary)
      return dataFormatted
    })
    return dataFormatted
  }

  const submitDataCollecteds = (dataFormatted) => {
    data.dataCollecteds.map(async (dataCollected) => {
      let dataCollecteds =
        helpers.dataCollected.mountNecessityAndProportionalityParams(
          dataFormatted,
          dataCollected,
        )

      if (dataCollecteds.collectedFields.length > 0) {
        await service.dponet.dataCollecteds.put({
          dataProcessId: data.id,
          dataCollectedId: dataCollected.id,
          dataCollecteds,
        })
      }
    })
  }

  const submitDataTreatments = (dataFormatted) => {
    data.dataTreatments.map(async (dataTreat) => {
      let dataTreatment =
        helpers.dataTreatment.mountNecessityAndProportionalityParams(
          dataFormatted,
          dataTreat.id,
        )

      if (dataTreatment !== {}) {
        await service.dponet.dataTreatments.put({
          dataProcessId: data.id,
          dataTreatmentId: dataTreat.id,
          dataTreatment,
        })
      }
    })
  }

  const submitDataLifeCycles = (dataFormatted) => {
    data.lifeCycles.map(async (dataLife) => {
      let dataLifeCycle =
        helpers.dataLifeCycle.mountNecessityAndProportionalityParams(
          dataFormatted,
          dataLife.id,
        )

      if (dataLifeCycle !== {}) {
        await service.dponet.dataLifeCycles.put({
          dataProcessId: data.id,
          dataLifeCycleId: dataLife.id,
          lifeCycle: {
            retentionFinality: dataLife.retentionFinality,
            storageLocationId: dataLife.storageLocation.id,
            discardModeId: dataLife.discardMode.id,
            internalAllocationModeId: dataLife.internalAllocationMode.id,
            protectionId: dataLife.protection.id,
            recoveryId: dataLife.recovery.id,
            value: dataLife.value,
            volumetry: dataLife.volumetry,
            ...dataLifeCycle,
          },
        })
      }
    })
  }

  const submitNecessityProportionality = async (
    dataCollectedsForm,
    dataTreatmentsForm,
    lifeCyclesForm,
  ) => {
    try {
      let dataFormatted = formatNecessityProportionality(
        dataCollectedsForm,
        dataTreatmentsForm,
        lifeCyclesForm,
      )
      submitDataCollecteds(dataFormatted)
      submitDataTreatments(dataFormatted)
      submitDataLifeCycles(dataFormatted)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
  }

  const submitGeneralData = async () => {
    try {
      let legalFrameworkIds = []
      Object.keys(rightLegalFrameworkList).map((key) => {
        rightLegalFrameworkList[key].items.map((item) => {
          if (
            legalFrameworkIds.filter(
              (legalFrameworksId) => legalFrameworksId === item.id,
            ).length === 0
          )
            legalFrameworkIds.push({ id: item.id })
          return item
        })
        return key
      })

      await service.dponet.dataProcesses.put({
        dataProcessId: data.id,
        dataProcess: {
          ...data,
          legalFrameworks: legalFrameworkIds || [],
          storageMode: volumetryType,
          laws: laws?.map((law) => ({
            id: law,
          })),
        },
      })
    } catch (error) {
      throw error
    }
  }

  const handleSubmitEdit = async () => {
    try {
      await submitGeneralData()

      submitNecessityProportionality(
        necDataCollecteds,
        necDataTreatments,
        necDataLifeCycles,
      )

      reloadEntireDataProcess()
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    const onSubmit = () => {
      handleSubmitEdit()
        .then(() => {
          setSubmitting(0)
          history.push(
            reverse(routes.dataProcess.view, { dataProcessId: data.id }),
          )
          snackbar.open({
            variant: 'success',
            message: 'Processamento de dados editado com sucesso.',
          })
        })
        .catch((error) => {
          snackbar.open({
            variant: 'error',
            message:
              helpers.formatters.errorMessage(error?.response?.data?.error) ||
              `Verifique se os campos ${
                isEmpty(laws) && 'do tempo de armazenamento'
              } estão devidamente preenchidos!`,
          })
        })
    }

    if (submitting >= 1 && submitUpdate) {
      onSubmit()
    }
    // eslint-disable-next-line
  }, [submitting, submitUpdate])

  const loadRelationOptions = (companyId) => {
    return companiesCache.useOptions(companyId, {
      enabled: !!companyId && companyId !== 0,
    })
  }

  const reloadDataProcess = () => {
    dataProcessCache.useUpdateCache(data?.id)
  }

  const reloadOptions = () => {
    companiesCache.useUpdateOptionsCache(companyId)
  }

  const reloadEntireDataProcess = () => {
    reloadDataProcess()
    reloadOptions()
  }

  const payload = (dataProcess = null) => {
    return helpers.dataProcesses.formatPayloadEdit(dataProcess || data)
  }

  const loadLogs = async (dataProcessId) => {
    const responseLogs = await service.dponet.dataProcesses.logs({
      dataProcessId,
    })
    setLogs(responseLogs?.data?.dponetAudits)
  }

  const clearLoadedDataProcess = () => {
    setData({})
  }

  return (
    <DataProcessContext.Provider
      value={{
        activeStep,
        code,
        companyId,
        counter,
        data,
        dataCollectedOptions,
        dataCollecteds,
        dataProcessSources,
        departments,
        discardModes,
        editLegalFramework,
        internalAllocationModes,
        lastActiveStep,
        legalFrameworks,
        loadLogs,
        loadRelationOptions,
        logs,
        necDataCollecteds,
        necDataLifeCycles,
        necDataTreatments,
        nonComplianceReports,
        payload,
        protections,
        recoveries,
        reloadDataProcess,
        reloadEntireDataProcess,
        reloadOptions,
        setActiveStep,
        setCode,
        setCompanyId,
        setData,
        setDataCollectedOptions,
        setDataCollecteds,
        setDataProcessSources,
        setDepartments,
        setDiscardModes,
        setEditLegalFramework,
        setInternalAllocationModes,
        setLastActiveStep,
        setLegalFrameworks,
        setLogs,
        setNecDataCollecteds,
        setNecDataLifeCycles,
        setNecDataTreatments,
        setNonComplianceReports,
        setProtections,
        setRecoveries,
        setShareProcesses,
        setSourceOptions,
        setStorageTimes,
        setSubmitting,
        setTitularCategories,
        setToNextStep,
        setValidForms,
        shareProcesses,
        sourceOptions,
        storageLocations,
        setStorageLocations,
        storageTimes,
        submitting,
        titularCategories,
        toNextStep,
        validForms,
        clearLoadedDataProcess,
        lawsOptions,
        setLawsOption,
        laws,
        setLaws,
        volumetryType,
        setVolumetryType,
        submitUpdate,
        setSubmitUpdate,
      }}
    >
      {children}
    </DataProcessContext.Provider>
  )
}

export default DataProcessProvider
