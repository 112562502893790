/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField, CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'

import { BaseDialog, LoadingFeedback } from 'components'

import { useSnackbar, useCompany } from 'hooks'

import * as service from 'service'
import helpers from 'helpers'

const SelectSecondaryEconomicActivityDialog = ({ open, setOpen, company }) => {
  const snackbar = useSnackbar()
  const { isLoading, setIsLoading, loadSecondaryEconomicActivities } =
    useCompany()

  const [value, setValue] = useState('')
  const [presentValue, setPresentValue] = useState(true)

  const [economicActivities, setEconomicActivities] = useState([])
  const [economicActivitiesInputValues, setEconomicActivitiesInputValues] =
    useState('')
  const [isLoadingEconomicActiviesInput, setIsLoadingEconomicActivities] =
    useState(false)

  const { instructionsText } = helpers.companies.typeAndSearch
  const economicActivitiesOptionsValues = isLoadingEconomicActiviesInput
    ? []
    : economicActivities

  const loadEconomicActivities = async () => {
    setIsLoadingEconomicActivities(true)

    const listSecondaryEconomicActivities =
      company?.secondaryEconomicActivities?.map((obj) => obj.id)

    const responsibleCandidatesResponse =
      await service.dponet.economicActivities.get({
        active: true,
        perPage: 1000 * 1000,
        name: economicActivitiesInputValues,
        listSecondaryEconomicActivities,
      })

    setEconomicActivities(
      responsibleCandidatesResponse?.data?.economicActivities,
    )
    setIsLoadingEconomicActivities(false)
  }

  const handleTypeEconomicActivities = (e) => {
    setEconomicActivitiesInputValues(e.target.value)
  }

  const addSecondaryEconomicActivity = async () => {
    setPresentValue(!!value)
    if (!value) return

    setIsLoading(true)
    if (company && value) {
      try {
        await service.dponet.companies.addSecondaryEconomicActivity({
          companyId: company.id,
          economicActivityId: value.id,
        })
        loadSecondaryEconomicActivities(company?.id)
        setValue(null)
        setOpen(false)

        snackbar.open({
          message: 'Atividade econômica secundária adicionada com sucesso!',
          variant: 'success',
        })
      } catch (error) {
        snackbar.open({
          message: helpers.formatters.errorMessage(
            error?.response?.data?.error,
          ),
          variant: 'error',
        })
      }
    }
    setTimeout(() => {
      setIsLoading(false)
    }, 200)
  }

  const listAvailableSecondaryEconomicActivity =
    economicActivitiesOptionsValues?.filter(
      (item) => item.id !== company?.mainEconomicActivity?.id,
    )

  useEffect(() => {
    const getResponsibleCandidates = setTimeout(() => {
      if (economicActivitiesInputValues.length < 3) return

      loadEconomicActivities()
    }, 1000)

    return () => clearTimeout(getResponsibleCandidates)
  }, [economicActivitiesInputValues])

  useEffect(() => {
    if (!open && economicActivitiesInputValues)
      setEconomicActivitiesInputValues('')
  }, [open])

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <BaseDialog
        open={open}
        setOpen={setOpen}
        title="Selecione uma atividade econômica secundária"
        closeButtonText="Cancelar"
        actionButtonText="Adicionar"
        actionButton={addSecondaryEconomicActivity}
        dialogSize="sm"
        formRef="add-secondary-economic-activity"
      >
        <Autocomplete
          options={listAvailableSecondaryEconomicActivity}
          getOptionLabel={(option) => option.name || ''}
          getOptionSelected={(option, value) =>
            !value || option.id === value.id || value.id === 0
          }
          noOptionsText={instructionsText(economicActivitiesInputValues)}
          loading={isLoadingEconomicActiviesInput}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={handleTypeEconomicActivities}
              helperText={
                !presentValue
                  ? 'Preencha esse campo'
                  : 'Seleciona uma atividade econômica para adicioná-la à lista de atividades econômicas secundárias'
              }
              error={!presentValue}
              label="Selecione a atividade econômica secundária"
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoadingEconomicActiviesInput ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue)
          }}
        />
      </BaseDialog>
    </>
  )
}

SelectSecondaryEconomicActivityDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  company: PropTypes.object,
}

export default SelectSecondaryEconomicActivityDialog
