import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  boxTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  boxTitleTwo: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: theme.spacing(97),
  },
  muiDialogTitleOverride: {
    padding: '20px 0px 10px 23px',
    [theme.breakpoints.up('md')]: {
      overflow: 'hidden',
    },
  },
  new: {
    backgroundColor: colors.lightBlue[800],
    borderRadius: '44px',
    color: theme.palette.white,
    textTransform: 'uppercase',
    fontWeight: 600,
    height: '26px',
    marginRight: theme.spacing(1),
    fontSize: '12px',
  },
  improvement: {
    backgroundColor: colors.lightGreen[500],
    borderRadius: '44px',
    color: theme.palette.white,
    textTransform: 'uppercase',
    fontWeight: 600,
    height: '26px',
    marginRight: theme.spacing(1),
    fontSize: '12px',
  },
  actionsContainer: {
    display: 'flex',
    gap: theme.spacing(1),
    paddingInline: theme.spacing(2),
    width: '100%',
  },
}))

export default styles
