const saveText = (
  havePaymentOrders,
  methodPayment,
  payInBackoffice,
  sendEmails,
) => {
  if (havePaymentOrders) {
    return 'Salvar'
  }

  if (methodPayment === 'card') {
    if (payInBackoffice) {
      return 'Salvar e pagar'
    }

    if (sendEmails) {
      return 'Salvar e enviar e-mails'
    }

    return 'Salvar'
  } else {
    return 'Salvar e gerar cobrança'
  }
}

export default saveText
