import PropTypes from 'prop-types'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import { ConfirmationDialog as ConfirmationRemoveDialog } from 'components'

const UnlinkUserModal = ({
  availableUsersRefresh = () => {},
  department,
  open = false,
  refresh = () => {},
  setLoading = () => {},
  setOpen = () => {},
  user = null,
}) => {
  const snackbar = useSnackbar()

  const message = `Tem certeza que deseja desvincular o usuário ${user?.name} desse departamento`

  const handleRemoveUser = async () => {
    try {
      setLoading(true)
      await service.dponet.departments.unlinkUser({
        departmentId: department?.id,
        userId: user.id,
      })

      snackbar.open({
        message: 'Usuário desvinculado do departamento com sucesso!',
        variant: 'success',
      })
      setOpen(false)
      refresh()
      availableUsersRefresh()
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ??
          'Erro ao desvincular usuário do departamento',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <ConfirmationRemoveDialog
      open={open}
      setOpen={setOpen}
      message={message}
      cancelButtonText="Cancelar"
      buttonText="Confirmar"
      actionAcceptButton={handleRemoveUser}
    />
  )
}

UnlinkUserModal.propTypes = {
  availableUsersRefresh: PropTypes.func.isRequired,
  department: PropTypes.object,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
  setOpen: PropTypes.func,
  user: PropTypes.object,
}

export default UnlinkUserModal
