import { BaseDialog, LoadingFeedback } from 'components'
import { useSnackbar, useCompany } from 'hooks'
import helpers from 'helpers'
import * as service from 'service'
import { Typography, Box } from '@material-ui/core'

const RemoveSecondaryEconomicActivityConfirmationDialog = ({
  open,
  setOpen,
  economicActivityId,
  companyId,
}) => {
  const snackbar = useSnackbar()
  const { isLoading, setIsLoading, loadSecondaryEconomicActivities } =
    useCompany()

  const handleDeleteSecondaryEconomicActivity = async (economicActivityId) => {
    setIsLoading(true)
    if (companyId && economicActivityId && !isLoading) {
      try {
        await service.dponet.companies.removeSecondaryEconomicActivity({
          companyId: companyId,
          economicActivityId: economicActivityId,
        })
        loadSecondaryEconomicActivities(companyId)
        setOpen(false)

        snackbar.open({
          message: 'Atividade econômica secundária removida com sucesso!',
          variant: 'success',
        })
      } catch (error) {
        snackbar.open({
          message: helpers.formatters.errorMessage(
            error?.response?.data?.error,
          ),
          variant: 'error',
        })
      }
    }
    setIsLoading(false)
  }

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <BaseDialog
        open={open}
        setOpen={setOpen}
        title="Remover atividade econômica secundária"
        closeButtonText="Cancelar"
        actionButtonText="Remover"
        actionButton={() =>
          handleDeleteSecondaryEconomicActivity(economicActivityId)
        }
        dialogSize="sm"
      >
        <Box mb={1}>
          <Typography variant="body1">
            Realmente deseja excluir esta atividade econômica?
          </Typography>
        </Box>
      </BaseDialog>
    </>
  )
}

export default RemoveSecondaryEconomicActivityConfirmationDialog
