const WAITING_ACTION_STATUS = 'WAITING_ACTION'
const WAITING_UPDATE_STATUS = 'WAITING_UPDATE'
const CONFIRMED_STATUS = 'CONFIRMED'
const UPDATED_STATUS = 'UPDATED'
const AUTOMATICALLY_APPROVED_STATUS = 'AUTOMATICALLY_APPROVED'

const WAITING_ACTION_LABEL = 'AGUARDANDO ESCOLHA'
const CONFIRMED_LABEL = 'PROCESSO CONFIRMADO COMO CORRETO'
const UPDATED_LABEL = 'PROCESSO ATUALIZADO'
const AUTOMATICALLY_APPROVED_LABEL = 'APROVADO AUTOMATICAMENTE'

const auditProcess = {
  WAITING_ACTION_STATUS,
  WAITING_UPDATE_STATUS,
  CONFIRMED_STATUS,
  UPDATED_STATUS,
  AUTOMATICALLY_APPROVED_STATUS,
  WAITING_ACTION_LABEL,
  CONFIRMED_LABEL,
  UPDATED_LABEL,
  AUTOMATICALLY_APPROVED_LABEL,
}

export default auditProcess
