import {
  Button,
  Box,
  Card,
  Divider,
  Grid,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { Plus as PlusIcon } from 'react-feather'
import { useHistory } from 'react-router-dom'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  LoadingFeedback,
  Page,
  Permitted,
} from 'components'
import ContractsTable from './components/ContractsTable'
import * as service from 'service'
import { routes } from 'Routes'
import constants from 'constants/index'

const ContractsMain = () => {
  const history = useHistory()
  const filter = useFilter()

  const theme = useTheme()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = useFetch(
    service.dponet.paymentContract.lists,
    {
      ...filter.filters,
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  const { response: responseSeller, isLoading: loadSeller } = useFetch(
    service.dponet.sellers.index,
    {
      kindNotIn: [constants.seller.PARTNER_KIND],
    },
  )

  const handleNew = () => history.push(routes.contracts.new)

  return (
    <>
      <Page title="Contratos">
        <LoadingFeedback open={isLoading || loadSeller} />
        <Container maxWidth={false}>
          <ContentHeader
            title="Listagem"
            menu="Contratos"
            subtitle="Listagem dos Contratos"
          >
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </ContentHeader>
          <Card>
            <Box width="100%">
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    width="100%"
                    p={2}
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5">Contratos</Typography>
                    <Permitted
                      tag={constants.permissions.PAYMENT_CONTRACTS.MANAGE}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<PlusIcon />}
                        onClick={handleNew}
                      >
                        Criar
                      </Button>
                    </Permitted>
                  </Box>
                </Grid>
              </Grid>
              <Divider />
            </Box>
            <ContractsTable
              paymentContracts={response?.data?.paymentContracts || []}
              refresh={refresh}
            />
            <Box px={2} display="flex" justifyContent="flex-end">
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            </Box>
          </Card>
        </Container>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <input
            textfieldinput="true"
            label="Cliente/Subclicenciamento"
            placeholder="ID, Nome ou CNPJ/CPF"
            name="identifierOrDocumentCont"
          />
          <input
            textfieldinput="true"
            label="Nº do contrato"
            name="contractIdentifier"
          />
          <select
            textfieldinput="true"
            label="Método de pagamento"
            name="methodPayment"
          >
            <option value=""></option>
            <option value="billet">Boleto</option>
            <option value="card">Cartão</option>
            <option value="pix">Pix</option>
          </select>
          <select textfieldinput="true" label="Status" name="kind">
            <option value=""></option>
            <option value="initial">Em implementação</option>
            <option value="recurrence">Recorrência</option>
            <option value="manual">Manual</option>
            <option value="canceled">Cancelado</option>
            <option value="duplicated">Duplicado (temporário)</option>
            <option value="suspended">Suspenso</option>
          </select>
          <select textfieldinput="true" label="Gateway" name="gatewayEq">
            <option value=""></option>
            <option value="superfin">Superfin</option>
            <option value="omie">OMIE</option>
          </select>
          <input
            datepickerinput="true"
            label="Data de faturamento"
            name="nextDateAdvanceEq"
          />
          <input
            textfieldinput="true"
            type="number"
            label="Dia de faturamento"
            name="daysAdvanceEq"
          />
          <select
            textfieldinput="true"
            label="Tipo de vencimento"
            name="typeBillingDayEq"
          >
            <option value=""></option>
            <option value="fixed">Dia fixo</option>
            <option value="dynamic">Nº de dias após faturar</option>
          </select>
          <input
            textfieldinput="true"
            type="number"
            label="Dia de vencimento fixo"
            name="billingDayEq"
          />
          <input
            textfieldinput="true"
            type="number"
            label="Dia de vencimento dinâmico"
            name="billingDayDynamicEq"
          />
          <select
            autocompleteselectinput="true"
            compareByName
            name="sellerId"
            label="Executivo de vendas"
            optionvalues={responseSeller?.data?.sellers || []}
          />
        </Filters>
      </Page>
    </>
  )
}

export default ContractsMain
