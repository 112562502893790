import api from 'service/api'

const dponetAPI = api.create('dponet')

const index = async ({ ...params }) => {
  return await dponetAPI.get('/billing_emails', { params })
}

const create = async (data) => {
  return await dponetAPI.post('/billing_emails', { billingEmail: data })
}

const destroy = async (billingEmailId) => {
  return await dponetAPI.delete(`/billing_emails/${billingEmailId}`)
}

const billingEmails = {
  index,
  create,
  destroy,
}

export default billingEmails
