import separateTypeAgents from './separateTypeAgents'
import typeControllerIsPresent from './typeControllerIsPresent'
import agentTypeOfCompanySelf from './agentTypeOfCompanySelf'

const dataTreatmentAgent = {
  separateTypeAgents,
  typeControllerIsPresent,
  agentTypeOfCompanySelf,
}

export default dataTreatmentAgent
