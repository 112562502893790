import {
  Card,
  CardHeader,
  Divider,
  Box,
  Grid,
  Typography,
} from '@material-ui/core'

import PropTypes from 'prop-types'

import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from 'components/Table'

import { Label } from 'components'

import useDataProcess from 'hooks/useDataProcess'

import useStyles from '../../styles'
import helpers from 'helpers'

const TableLegalFrameworkRow = ({ legalFramework }) => {
  return (
    <TableRow key={legalFramework.id}>
      <TableCell>{legalFramework.id}</TableCell>
      <TableCell>{legalFramework.name}</TableCell>
      <TableCell>{legalFramework.legalTemplate}</TableCell>
    </TableRow>
  )
}

TableLegalFrameworkRow.propTypes = {
  legalFramework: PropTypes.object.isRequired,
}

const LegalFramework = () => {
  const { data } = useDataProcess()

  const classes = useStyles()

  return (
    <Card>
      <CardHeader title="Detalhes do enquadramento legal" />
      <Divider />
      <Box className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Label title="Legítimo interesse">
              {data?.lia ? (
                helpers.formatters
                  .multipleParagraphs(data?.lia, '\n\n')
                  .map((paragraph, index) => (
                    <Typography paragraph key={index}>
                      {paragraph}
                    </Typography>
                  ))
              ) : (
                <Typography>Sem legítimo interesse.</Typography>
              )}
            </Label>
          </Grid>
          <Grid item xs={12}>
            <Label title="Enquadramentos legais">
              {data?.legalFrameworks?.length > 0 ? (
                <Card>
                  <PerfectScrollbar>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell width="10%">Identificador</TableCell>
                          <TableCell width="30%">Nome</TableCell>
                          <TableCell width="60%">Base legal</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data?.legalFrameworks?.map((legalFramework) => (
                          <TableLegalFrameworkRow
                            legalFramework={legalFramework}
                            key={legalFramework.id}
                          />
                        ))}
                      </TableBody>
                    </Table>
                  </PerfectScrollbar>
                </Card>
              ) : (
                <Typography>Sem enquadramentos legais</Typography>
              )}
            </Label>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

export default LegalFramework
