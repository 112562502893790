import { Page, Container, ContentHeader } from 'components'
import { CompanySizeForm } from './components'

import { routes } from 'Routes'

const CompanySizesNew = () => {
  const navigationsRoutes = [
    {
      text: 'Listagem',
      route: routes.companySizes.all,
    },
  ]
  return (
    <Page title="Nova atividade econômica">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Novo tamanho de empresa"
          menu="Tamanho de empresa"
          subtitle="Novo tamanho de empresa"
          menuNavigations={navigationsRoutes}
        />
        <CompanySizeForm />
      </Container>
    </Page>
  )
}
export default CompanySizesNew
