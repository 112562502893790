import { Layers as LayersIcon } from 'react-feather'

const mountMenuItems = (questionnaireItems, countBadgeResponse) => [
  {
    items: [
      {
        title: 'Processos',
        icon: LayersIcon,
        href: '/data-processes/resume',
        items: [
          {
            title: 'Resumo',
            href: '/data-processes/resume',
          },
          {
            title: 'Todos os processos',
            href: '/data-processes',
            permission: 'list_data_processes',
          },
        ],
      },
    ],
  },
]

export default mountMenuItems
