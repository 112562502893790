import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import { Label } from 'components'
import useStyles from '../../styles'
import PropTypes from 'prop-types'

const ResponsibleCard = ({ responsibleUser }) => {
  const classes = useStyles()

  return (
    <Card>
      <CardHeader title="Dados do responsável" />
      <Divider />
      <Box className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Label title="Nome">
              <Typography>{responsibleUser?.name}</Typography>
            </Label>
          </Grid>
          <Grid item xs={12} md={6}>
            <Label title="Email">
              <Typography>{responsibleUser?.email}</Typography>
            </Label>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

ResponsibleCard.propTypes = {
  responsibleUser: PropTypes.object,
}

export default ResponsibleCard
