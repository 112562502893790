import constants from 'constants/index'

const origin = (finality) => {
  switch (finality) {
    case constants.nonComplianceReports.FRAGILITY_FINALITY:
      return 'Risco'
    case constants.nonComplianceReports.NECESSARY_FINALITY:
      return 'Necessidade e proporcionalidade'
    case constants.nonComplianceReports.FRAGILITY_AND_NECESSARY_FINALITY:
      return 'Risco e necessidade e proporcionalidade'
    case constants.nonComplianceReports.QUESTION_FINALITY:
      return 'Questão'
    case constants.nonComplianceReports.INCIDENT_FINALITY:
      return 'Incidente'
    default:
      return '-'
  }
}

export default origin
