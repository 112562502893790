import { useState, useEffect } from 'react'
import { Box, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { isEmpty } from 'lodash'

import useFetch from 'hooks/useFetch'

import {
  Container,
  ContentHeader,
  Page,
  MenuButton,
  LoadingFeedback,
} from 'components'
import {
  BasicInfoCard,
  AddressCard,
  ResponsibleCard,
  PlanCard,
  CouponCard,
  MembershipCard,
  PaymentContractTable,
  CompanyData,
} from './components/index'
import ActionButtons from './components/ActionButtons'

import * as service from 'service'
import constants from 'constants/index'

const PreRegistrationsShow = ({ match }) => {
  const [logs, setLogs] = useState()
  const [loading, setLoading] = useState(true)
  const { preRegistrationToken } = match.params

  const { response, isLoading, refresh } = useFetch(
    service.dponet.preRegistrations.get,
    { preRegistrationToken: preRegistrationToken },
  )

  const preRegistration = response?.data?.preRegistration

  const title =
    preRegistration?.typeClient === constants.preRegistration.CLIENT_TYPE
      ? 'Cliente'
      : 'Sublicenciamento'

  useEffect(() => {
    const getLogs = async () => {
      const logsData = await service.dponet.preRegistrations.logs({
        preRegistrationToken: preRegistrationToken,
      })
      setLogs(logsData.data.dponetAudits)
    }
    setLoading(true)
    getLogs().finally(() => setLoading(false))
  }, [preRegistrationToken])

  return (
    <>
      <LoadingFeedback open={isLoading || loading} />
      {(!isLoading || !loading) && (
        <Page title={title}>
          <Container maxWidth={false}>
            <ContentHeader
              title="Listagem"
              menu={title}
              subtitle={`Exibição do ${title}`}
            >
              <MenuButton>
                <ActionButtons
                  setLoading={setLoading}
                  preRegistration={preRegistration}
                  refresh={refresh}
                  logs={logs}
                  typeClient={preRegistration?.typeClient}
                />
              </MenuButton>
            </ContentHeader>
            <Box mt={4} mb={4}>
              <Alert
                variant="filled"
                severity={
                  preRegistration?.codeClientOmie ? 'success' : 'warning'
                }
              >
                <Typography>
                  {preRegistration?.codeClientOmie
                    ? `Esse ${title} tem vínculo com o omie`
                    : `Esse ${title} não tem vínculo com o omie`}
                </Typography>
              </Alert>
            </Box>
            <Box mb={4}>
              <BasicInfoCard preRegistration={preRegistration} />
            </Box>
            <Box mb={4}>
              <AddressCard address={preRegistration?.address} />
            </Box>
            <Box mb={4}>
              <ResponsibleCard
                responsibleUser={preRegistration?.responsibleUser}
              />
            </Box>
            <Box mt={4} mb={4}>
              <CompanyData company={preRegistration?.company} />
            </Box>
            <Box mb={4}>
              <PlanCard plan={preRegistration?.plan} />
            </Box>
            {!isEmpty(preRegistration?.membershipRegistration) && (
              <Box mb={4}>
                <MembershipCard
                  membershipRegistration={
                    preRegistration?.membershipRegistration
                  }
                />
              </Box>
            )}
            {preRegistration?.discountCoupon && (
              <Box mb={4}>
                <CouponCard coupon={preRegistration?.discountCoupon} />
              </Box>
            )}
            <PaymentContractTable preRegistration={preRegistration} />
          </Container>
        </Page>
      )}
    </>
  )
}

export default PreRegistrationsShow
