import statusLabel from './statusLabel'
import statusColor from './statusColor'
import formatQuestionnairesToReport from './formatQuestionnairesToReport'

const questionnaires = {
  statusLabel,
  statusColor,
  formatQuestionnairesToReport,
}

export default questionnaires
