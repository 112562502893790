import PropTypes from 'prop-types'

import {
  Page,
  Container,
  ContentHeader,
  MenuButton,
  Permitted,
} from 'components'
import { CouponsForm, ActionButtons, InfoUserByCoupon } from './components'

import { useFetch } from 'hooks'

import * as service from 'service'
import constants from 'constants/index'

const CouponEdit = ({ match }) => {
  const {
    params: { couponId },
  } = match

  const { response, isLoading } = useFetch(
    service.dponet.coupons.show,
    {
      couponId,
    },
    [],
  )

  return (
    <Page title="Edição do cupom">
      <Container maxWidth={false}>
        <ContentHeader
          title="Edição do cupom"
          menu="Cupom"
          subtitle="Edição do cupom"
        >
          <Permitted
            someTags={[
              constants.permissions.COUPONS.SHOW_LOGS,
              constants.permissions.COUPONS.CREATE,
              constants.permissions.COUPONS.DESTROY,
            ]}
          >
            <MenuButton>
              <ActionButtons couponId={couponId} />
            </MenuButton>
          </Permitted>
        </ContentHeader>
        {!isLoading && (
          <>
            <InfoUserByCoupon
              createdAt={response?.data?.discountCoupon?.createdAt}
              user={response?.data?.discountCoupon?.user}
            />
            <CouponsForm isEdit coupon={response?.data?.discountCoupon} />
          </>
        )}
      </Container>
    </Page>
  )
}

CouponEdit.propTypes = {
  match: PropTypes.object,
}

export default CouponEdit
