import constants from 'constants/index'

const mountLabelsList = (data) => {
  return data.map((val) => ({
    id: val.id,
    label: val.name,
    group:
      constants.dataCollected.dataCollectedLabelOptions[
        (val.dataType && val.dataType.toString()) || '0'
      ],
  }))
}

export default mountLabelsList
