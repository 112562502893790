import { useState } from 'react'

import { Button } from '@material-ui/core'
import { ConfirmationDialog, Permitted } from 'components'

import PropTypes from 'prop-types'
import * as service from 'service'
import useSnackbar from 'hooks/useSnackbar'

import constants from 'constants/index'
import helpers from 'helpers'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

const RemoveCoupon = ({ couponId, refresh, isEdit }) => {
  const history = useHistory()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const snackbar = useSnackbar()

  const deleteMessage = `Ao excluir este cupom nenhum cliente poderá utilizar o mesmo posteriormente,
                         os clientes que já utilizaram o cupom não serão afetados, 
                         tem certeza que deseja realizar está exclusão?`

  const handleDelete = async () => {
    try {
      await service.dponet.coupons.destroy({ couponId })

      snackbar.open({
        message: 'Cupom de desconto excluido com sucesso!',
        variant: 'success',
      })
      if (isEdit) {
        history.push(routes.coupons.all)
      } else {
        refresh()
      }
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Erro ao excluir o cupom de desconto ',
      })
    }
    setOpenDeleteDialog(false)
  }

  return (
    <>
      <Permitted tag={constants.permissions.COUPONS.DESTROY}>
        <Button
          fullWidth
          size="small"
          onClick={() => setOpenDeleteDialog(true)}
        >
          Excluir
        </Button>
      </Permitted>
      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        message={deleteMessage}
        cancelButtonText="Cancelar"
        buttonText="Excluir"
        actionAcceptButton={handleDelete}
      />
    </>
  )
}

RemoveCoupon.propTypes = {
  couponId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isEdit: PropTypes.bool,
  refresh: PropTypes.func,
}

RemoveCoupon.defaultProps = {
  isEdit: false,
  refresh: () => {},
}

export default RemoveCoupon
