import constants from 'constants/index'

const statusLabel = (status) => {
  switch (status) {
    case constants.privacyPolicy.DRAFT_STATUS_VALUE:
      return constants.privacyPolicy.DRAFT_STATUS_LABEL
    case constants.privacyPolicy.ACTIVE_STATUS_VALUE:
      return constants.privacyPolicy.ACTIVE_STATUS_LABEL
    case constants.privacyPolicy.INACTIVE_STATUS_VALUE:
      return constants.privacyPolicy.INACTIVE_STATUS_LABEL
    default:
      return ''
  }
}

export default statusLabel
