import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  tableHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    borderBottom: `1px solid ${theme.palette.secondary.superLight}`,
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.secondary.superLight}`,
    width: '100%',
  },
}))

export default styles
