import constants from 'constants/index'

const filterUnnecessaryDataTreatments = (rmc) => {
  if (!rmc?.unnecessaryDatas) {
    return []
  }
  return rmc.unnecessaryDatas.filter(
    (unnecessaryData) =>
      unnecessaryData.dataType ===
      constants.dataProcess.UNNECESSARY_DATA_TYPE.DATA_TREATMENT,
  )
}

export default filterUnnecessaryDataTreatments
