import { useContext } from 'react'

import FilterTagsContext from 'contexts/FilterTagsContext'

const useFilterTags = () => {
  const context = useContext(FilterTagsContext)

  if (context === undefined) {
    throw new Error(
      'useFilterTags must be used within a FilterTagsContext.Provider',
    )
  }

  return {
    companies: context.companies,
    departments: context.departments,
    titularCategories: context.titularCategories,
    templateCompanies: context.templateCompanies,
    legalFrameworks: context.legalFrameworks,
    defineDepartments: context.defineDepartments,
    defineCompanies: context.defineCompanies,
    defineTitularCategories: context.defineTitularCategories,
    defineTemplateCompanies: context.defineTemplateCompanies,
    defineLegalFrameworks: context.defineLegalFrameworks,
  }
}

export default useFilterTags
