import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default styles
