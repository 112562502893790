import PropTypes from 'prop-types'

import { Box, Button, Card, Grid, Typography } from '@material-ui/core'
import { Plus as PlusIcon } from 'react-feather'

import useStyles from './styles'

const FormCard = ({
  title,
  padding,
  addButton,
  buttonText,
  children,
  ...rest
}) => {
  const classes = useStyles()
  const gridValue = addButton ? 8 : 12
  const styleClass = addButton ? classes.tableHeader : classes.borderBottom

  return (
    <Card>
      <Grid container>
        <Box className={styleClass} p={2}>
          <Grid item xs={gridValue}>
            <Typography variant="body1">{title}</Typography>
          </Grid>
          {addButton && (
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<PlusIcon />}
                  {...rest}
                >
                  {buttonText}
                </Button>
              </Box>
            </Grid>
          )}
        </Box>
      </Grid>
      <Box p={padding}>{children}</Box>
    </Card>
  )
}

FormCard.propTypes = {
  title: PropTypes.string.isRequired,
  padding: PropTypes.number,
  addButton: PropTypes.bool,
  buttonText: PropTypes.string,
  children: PropTypes.node.isRequired,
}

FormCard.defaultProps = {
  padding: 2,
  addButton: false,
}

export default FormCard
