import { useState } from 'react'

import SelectCountryContext from 'contexts/SelectCountryContext'

import * as service from 'service'
import { isEmpty } from 'lodash'

const SelectCountryProvider = ({ children }) => {
  const [countries, setCountries] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState({})
  const [selectedCountries, setSelectedCountries] = useState([])

  const [manySelectedCountry, setManySelectedCountry] = useState([])
  const [manySelectedCountries, setManySelectedCountries] = useState({})

  const loadCountries = async () => {
    setLoading(true)

    const response = await service.dponet.internationalTransfer.countryList()

    setCountries(response?.data)

    setLoading(false)
  }

  const addCountry = (country) => {
    const countryExists = selectedCountries.find(
      (selectedCountry) => selectedCountry?.country === country?.name,
    )

    if (country?.id && !countryExists) {
      let newCountry = { id: country.id, country: country.name }
      setSelectedCountries([...selectedCountries, newCountry])
    }
  }

  const removeCountry = (country, handleRemoveCountry) => {
    let newCountriesList = selectedCountries.filter(
      (selectedCountry) => selectedCountry.id !== country.id,
    )
    setSelectedCountries(newCountriesList)
    handleRemoveCountry(country)
  }

  const sortNewNamesToObject = (object) => {
    let keys = Object.keys(object)
    let newObject = {}

    keys.map((key, index) => {
      newObject[`${key.split('-')[0]}-${index}`] = object[key]
      return newObject
    })

    return newObject
  }

  const addCountryToManyFields = (country, fieldName) => {
    const countryExists = manySelectedCountries[fieldName]?.find(
      (selectedCountry) => selectedCountry?.country === country?.name,
    )
    if (country?.id && !countryExists) {
      let newCountry = { id: country.id, country: country.name }

      let newManySelectedCountries = Object.assign({}, manySelectedCountries)

      if (!isEmpty(Object.keys(manySelectedCountries))) {
        if (!isEmpty(newManySelectedCountries[fieldName])) {
          delete newManySelectedCountries[fieldName]

          setManySelectedCountries({
            ...newManySelectedCountries,
            [fieldName]: [...manySelectedCountries[fieldName], newCountry],
          })
        } else {
          setManySelectedCountries({
            ...newManySelectedCountries,
            [fieldName]: [newCountry],
          })
        }
      } else {
        setManySelectedCountries({
          [fieldName]: [newCountry],
        })
      }
    }
  }

  const removeCountryToManyFields = (
    country,
    fieldName,
    handleRemoveCountry,
  ) => {
    let newManySelectedCountries = Object.assign({}, manySelectedCountries)

    let newCountries = newManySelectedCountries[fieldName].filter(
      (countryInArray) => countryInArray !== country,
    )

    if (newManySelectedCountries[fieldName]) {
      setManySelectedCountries({
        ...newManySelectedCountries,
        [fieldName]: newCountries,
      })
    } else {
      setManySelectedCountries({ [fieldName]: newCountries })

      handleRemoveCountry(country)
    }
  }

  return (
    <SelectCountryContext.Provider
      value={{
        countries,
        setCountries,
        loading,
        setLoading,
        loadCountries,
        selectedCountries,
        setSelectedCountries,
        selectedCountry,
        setSelectedCountry,
        addCountry,
        removeCountry,
        manySelectedCountry,
        setManySelectedCountry,
        manySelectedCountries,
        setManySelectedCountries,
        addCountryToManyFields,
        removeCountryToManyFields,
        sortNewNamesToObject,
      }}
    >
      {children}
    </SelectCountryContext.Provider>
  )
}

export default SelectCountryProvider
