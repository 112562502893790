import constants from 'constants/index'

const mountSelectedList = (data) => {
  if (!data) return

  return data.map((val) => ({
    id: val.dataCollectedOption && val.dataCollectedOption.id,
    label: val.dataCollectedOption && val.dataCollectedOption.name,
    group:
      constants.dataCollected.dataCollectedLabelOptions[
        val?.dataCollectedOption?.dataType?.toString() || '0'
      ],
  }))
}

export default mountSelectedList
