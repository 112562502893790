import { useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { CardHeader, Grid, Paper } from '@material-ui/core'
import {
  Avatar,
  DataProcessInfo,
  Header,
  LegalFrameworksDetails,
} from './components'

import constants from 'constants/index'

import useStyles from './styles'

const RowLegalFramework = ({ data, legalFrameworks, setLoading }) => {
  const classes = useStyles()
  const { WAITING_REVIEW_STATUS, REVIEWING_STATUS } = constants.dataProcess
  const headerRef = useRef(null)

  const [dataProcess, setDataProcess] = useState(data || {})

  const changeDataProcessStatus = (statusId) => {
    if (!!headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    setDataProcess({
      ...dataProcess,
      statusId,
    })
  }

  const showDetails = [WAITING_REVIEW_STATUS, REVIEWING_STATUS].includes(
    dataProcess?.statusId,
  )

  return (
    <>
      <Paper>
        <CardHeader
          ref={headerRef}
          disableTypography
          className={classes.cardHeader}
          classes={{ avatar: classes.cardPlayPause }}
          title={
            <Header
              status={dataProcess?.statusId}
              updatedAt={dataProcess?.updatedAt}
            />
          }
          avatar={
            <Avatar
              setLoading={setLoading}
              dataProcessId={dataProcess?.id}
              status={dataProcess?.statusId}
              changeDataProcessStatus={changeDataProcessStatus}
            />
          }
        />
      </Paper>
      <Grid container spacing={1} className={classes.containerContent}>
        <Grid item xs={12} md={showDetails ? 7 : 12} lg={showDetails ? 8 : 12}>
          <DataProcessInfo
            showDetails={showDetails}
            changeDataProcessStatus={changeDataProcessStatus}
            dataProcess={dataProcess}
            setLoading={setLoading}
          />
        </Grid>
        {showDetails && (
          <Grid item xs={12} md={5} lg={4}>
            <LegalFrameworksDetails
              changeDataProcessStatus={changeDataProcessStatus}
              dataProcess={dataProcess}
              legalFrameworks={legalFrameworks}
              setLoading={setLoading}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

RowLegalFramework.propTypes = {
  data: PropTypes.object,
  legalFrameworks: PropTypes.object,
  setLoading: PropTypes.func,
}

RowLegalFramework.defaultProps = {
  data: {},
  legalFrameworks: [],
  setLoading: () => {},
}

export default RowLegalFramework
