import {
  WIDTH as WIDTHPdf,
  HEIGHT as HEIGHTPdf,
  fontSize,
  PADDING,
} from '../constants'

const numberPage = (pdf, color, skippedPages = 3) => {
  const WIDTH = WIDTHPdf(pdf)
  const HEIGHT = HEIGHTPdf(pdf)
  const PAGE_NUMBER =
    pdf.internal.getCurrentPageInfo().pageNumber - skippedPages

  pdf.setFont('Raleway-Bold')
  pdf.setFontSize(fontSize.pageNumber)
  pdf.setTextColor(color)
  pdf.text(PAGE_NUMBER.toString(), WIDTH - PADDING, HEIGHT - PADDING / 2)
}

export default numberPage
