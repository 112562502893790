import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  cardHeader: {
    padding: theme.spacing(1),
  },
  cardPlayPause: {
    marginRight: 0,
  },
  containerContent: {
    marginTop: -5,
  },
}))

export default styles
