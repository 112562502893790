import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'

import { useSnackbar } from 'hooks'

import useStyles from './styles'

const CheckoutLink = ({ sendEmails, setSendEmails, shouldSave, url }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()

  const handleCopy = () => {
    window.navigator.clipboard.writeText(url)
    snackbar.open({
      message: 'Link copiado com sucesso!',
      variant: 'success',
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={10} xl={11}>
        <TextField
          type="url"
          value={url}
          fullWidth
          {...(shouldSave && {
            helperText:
              'É necessário salvar o contrato para o link poder ser acessado',
          })}
        />
      </Grid>
      <Grid item xs={12} sm={2} xl={1}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          className={classes.btn}
          onClick={handleCopy}
        >
          Copiar link
        </Button>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel component={Typography} variant="h5" gutterBottom>
            Deseja enviar este link para os emails cadastrados?
          </FormLabel>
          <RadioGroup
            value={sendEmails?.toString() ?? 'true'}
            onChange={(_, value) => setSendEmails(value === 'true')}
          >
            <FormControlLabel
              value="true"
              control={<Radio color="primary" className={classes.radio} />}
              label="SIM"
            />
            <FormControlLabel
              value="false"
              control={<Radio color="primary" className={classes.radio} />}
              label="NÃO"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default CheckoutLink
