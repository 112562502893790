import { useState } from 'react'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { Box } from '@material-ui/core'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { PaymentOrdersTableRow } from '..'
import { LoadingFeedback } from 'components'

const PaymentOrdersTable = ({
  paymentOrders,
  refresh = () => {},
  isLoading,
}) => {
  const [loading, setLoading] = useState(false)

  return (
    <>
      <LoadingFeedback open={isLoading || loading} />
      <Box width="100%">
        <PerfectScrollbar
          options={{ wheelPropagation: true, useBothWheelAxes: true }}
        >
          <Table emptyMessage="Nenhuma ordem de pagamento encontrado" noWrap>
            <TableHead>
              <TableRow>
                <TableCell width="5%">ID</TableCell>
                <TableCell width="10%" overFlowSize="100%">
                  Nº contrato
                </TableCell>
                <TableCell width="10%" align="left">
                  Método
                </TableCell>
                <TableCell width="10%" align="left">
                  Organização
                </TableCell>
                <TableCell width="10%" align="left">
                  Valor
                </TableCell>
                <TableCell width="10%" align="left">
                  Vencimento
                </TableCell>
                <TableCell width="10%" align="center">
                  Pagamento
                </TableCell>
                <TableCell width="10%" align="left">
                  Referência
                </TableCell>
                <TableCell width="10%" align="left">
                  Nº nota fiscal
                </TableCell>
                <TableCell width="10%" align="left">
                  Status
                </TableCell>
                <TableCell width="5%" align="left">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentOrders?.map((paymentOrder) => (
                <PaymentOrdersTableRow
                  refresh={refresh}
                  paymentOrder={paymentOrder}
                  key={paymentOrder?.id}
                  setLoading={setLoading}
                />
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Box>
    </>
  )
}

PaymentOrdersTable.propTypes = {
  paymentOrders: PropTypes.arrayOf(Object),
  refresh: PropTypes.func,
  isRelatedCompany: PropTypes.bool,
}

export default PaymentOrdersTable
