import * as yup from 'yup'

const schemaQuestion = () =>
  yup.object().shape({
    theme: yup.string().required(),
    question: yup.string().required(),
    affirmative: yup.string().required(),
    recomendations: yup.string(),
    reason: yup.string().required(),
    securityPolicy: yup.string(),
  })

export default schemaQuestion
