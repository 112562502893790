import { useState } from 'react'

import RmcsListContext from 'contexts/RmcsListContext'

import * as service from 'service'

const RmcsListProvider = ({ children }) => {
  const [loadingProvider, setLoadingProvider] = useState(false)
  const [departments, setDepartments] = useState({})
  const [users, setUsers] = useState({})
  const unlimitedPerPage = 10000000

  const loadUsers = async (companyId) => {
    setLoadingProvider(true)

    if (!companyId) {
      setUsers({})
      setLoadingProvider(false)
      return
    }

    let responseUsers = await service.dponet.user.get({
      perPage: unlimitedPerPage,
      requestOrigin: 'RMC',
      companyId: companyId,
    })

    setUsers(responseUsers?.data?.users)
    setLoadingProvider(false)
  }

  const loadDepartments = async (companyId) => {
    setLoadingProvider(true)

    if (!companyId) {
      setDepartments({})
      setLoadingProvider(false)
      return
    }

    let responseDepartments = await service.dponet.departments.get({
      perPage: unlimitedPerPage,
      companyId: companyId,
    })

    setDepartments(responseDepartments?.data?.departments)
    setLoadingProvider(false)
  }

  return (
    <RmcsListContext.Provider
      value={{
        loadingProvider,
        departments,
        users,
        loadUsers,
        loadDepartments,
      }}
    >
      {children}
    </RmcsListContext.Provider>
  )
}

export default RmcsListProvider
