const input = (value = '') => {
  value = value.replace(/[^0-9,.]/g, '').replace('.', ',')

  if (value.indexOf(',') !== value.lastIndexOf(','))
    value = value.substring(0, value.lastIndexOf(','))

  return value
}

const convert = (value = '') => {
  return parseFloat(value.replace(/,/g, '.'))
}

const float = {
  input,
  convert,
}

export default float
