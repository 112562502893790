import PropTypes from 'prop-types'

import { ConfirmationDialog } from 'components'

const DialogRemoveDataLifeCycle = ({
  dataProcessId,
  dataLifeCycleId,
  open,
  setOpen,
  handleRemove,
}) => {
  return (
    <ConfirmationDialog
      open={open}
      setOpen={setOpen}
      message="Tem certeza que deseja remover este salvaguarda e ciclo de vida?"
      cancelButtonText="Cancelar"
      buttonText="Continuar"
      actionAcceptButton={() => handleRemove(dataProcessId, dataLifeCycleId)}
    />
  )
}

DialogRemoveDataLifeCycle.propTypes = {
  dataProcessId: PropTypes.number.isRequired,
  dataLifeCycleId: PropTypes.number,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
}

DialogRemoveDataLifeCycle.defaultProps = {
  setOpen: () => {},
  handleRemove: () => {},
}

export default DialogRemoveDataLifeCycle
