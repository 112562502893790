import PropTypes from 'prop-types'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  Card,
} from '@material-ui/core'

import PerfectScrollbar from 'react-perfect-scrollbar'

import useStyles from './styles'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

const TableRowCollectedFields = ({ collectedField }) => {
  return (
    <TableRow>
      <TableCell overFlowSize="600px">
        {collectedField.dataCollectedOption.name}
      </TableCell>
      <TableCell>{collectedField.dataCollectedOption.dataTypeName}</TableCell>
    </TableRow>
  )
}

const TableCollectedFields = ({ collectedFields }) => {
  return (
    <Table emptyMessage="Nenhum dado tratado encontrado" noWrap>
      <TableHead>
        <TableRow>
          <TableCell width="75%">Dado</TableCell>
          <TableCell width="25%">Tipo</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {collectedFields.map((collectedField) => (
          <TableRowCollectedFields
            collectedField={collectedField}
            key={collectedField.id}
          />
        ))}
      </TableBody>
    </Table>
  )
}

const DialogCollectedFields = ({ open, setOpen, collectedFields }) => {
  const classes = useStyles()

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>
        <Box>
          <Typography variant="h6">Visualizar dados tratados</Typography>
        </Box>
      </DialogTitle>
      <Box mx={1}>
        <DialogContent dividers>
          <Card>
            <PerfectScrollbar>
              <TableCollectedFields collectedFields={collectedFields} />
            </PerfectScrollbar>
          </Card>
        </DialogContent>
      </Box>
      <DialogActions p={2}>
        <Box pr={3} width="100%" display="flex" justifyContent="flex-end">
          <Button
            type="button"
            variant="contained"
            className={classes.closeButton}
            onClick={() => setOpen(false)}
          >
            FECHAR
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

DialogCollectedFields.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  collectedField: PropTypes.array,
}

DialogCollectedFields.defaultProps = {
  setOpen: () => {},
}

export default DialogCollectedFields
