import { Typography, Box, Card } from '@material-ui/core'

import PropTypes from 'prop-types'

import { DocumentsTable, TableAvailableMeasures } from './components'

import constants from 'constants/index'

const FragilityContent = ({ rmc }) => {
  return (
    <>
      <Box mb={2}>
        <Typography variant="h4">Medidas Disponíveis</Typography>
      </Box>
      <Typography>
        Medidas de segurança administrativas e técnicas dos questionários de
        Governança e cultura e Infraestrutura e tecnologia ou implementadas nos
        RMCs desses questionários. Essas medidas devem ser as que, na prática,
        se aplicam ao processo que gerou o RMC. As medidas de segurança são como
        um guarda-chuva e podem proteger vários processos ao mesmo tempo. Um
        código de conduta de proteção de dados de um departamento é uma medida
        de segurança administrativa aplicável a todos os processos do
        departamento.
      </Typography>
      <Box mt={2}>
        <Card>
          <TableAvailableMeasures rmc={rmc} />
        </Card>
      </Box>
      <Box mt={5} mb={2}>
        <Typography variant="h4">Outras medidas</Typography>
      </Box>
      <Typography>
        Outras medidas de segurança administrativas e técnicas que são
        praticadas na rotina da empresa e se aplicam ao processo que gerou o
        RMC.
      </Typography>
      <Box mt={2}>
        <Card>
          <TableAvailableMeasures
            rmc={rmc}
            type={constants.nonComplianceReports.ANOTHER_FINALITY}
          />
        </Card>
      </Box>
      <Box mt={5} mb={2}>
        <Typography variant="h4">Documentos</Typography>
      </Box>
      <Card>
        <DocumentsTable documents={rmc.documents} />
      </Card>
    </>
  )
}

FragilityContent.propTypes = {
  rmc: PropTypes.object,
}

export default FragilityContent
