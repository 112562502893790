import { Button } from '@material-ui/core'
import { Trash as TrashIcon } from 'react-feather'
import useStyles from './styles'

const RemoveButton = ({ ...rest }) => {
  const classes = useStyles()

  return (
    <Button
      className={classes.removeButton}
      startIcon={<TrashIcon size={18} />}
      {...rest}
    >
      Remover
    </Button>
  )
}

export default RemoveButton
