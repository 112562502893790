import React, { useState } from 'react'

import PropTypes from 'prop-types'

import { ConfirmationDialog, LoadingFeedback } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import * as service from 'service'

const ConfirmationLoss = ({ paymentOrderId, open, setOpen, refresh }) => {
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()

  const handleLoss = async () => {
    setLoading(true)

    try {
      await service.dponet.paymentOrders.paymentLoss({
        paymentOrderId: paymentOrderId,
      })

      snackbar.open({
        message: `Pagamento atualizado com sucesso!`,
        variant: 'success',
      })

      setOpen(false)
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu um erro ao tentar atualizar o pagamento',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <ConfirmationDialog
        open={open}
        setOpen={setOpen}
        message="Tem certeza de que deseja confirmar a perda deste pagamento?"
        cancelButtonText="Cancelar"
        buttonText="Confirmar"
        actionAcceptButton={handleLoss}
      />
    </>
  )
}

ConfirmationLoss.propTypes = {
  consentFormId: PropTypes.number,
  receiptId: PropTypes.number,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
}

export default ConfirmationLoss
