import PropTypes from 'prop-types'
import { Dialog, DialogContent, Box, Typography } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import useStyles from './styles'

const InfoDialog = ({
  open = false,
  setOpen = () => {},
  text = '',
  title = '',
  typeObject = false,
}) => {
  const classes = useStyles()

  const handleClose = () =>
    setOpen(typeObject ? { open: false, text: '', title: '' } : false)

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <Box className={classes.context}>
        <Box display="flex" justifyContent="space-between" px={2} mt={2}>
          <Box ml={1} className={classes.text}>
            <Typography variant="h5" color="white">
              {title}
            </Typography>
          </Box>
          <CloseIcon className={classes.icon} onClick={handleClose} />
        </Box>
        <DialogContent>
          <Box my={2} className={classes.text}>
            <Typography variant="body1">{text}</Typography>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  )
}

InfoDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
  typeObject: PropTypes.bool,
}

export default InfoDialog
