const noDataMessage = (dataCollecteds) => {
  const MESSAGE_DEFAULT =
    'Nenhum dado tratado, forma de proteção e/ou compartilhamento foi avaliado como desnecessário e/ou desproporcional até o momento.'

  const MESSAGE_ALL_NECESSARY =
    'Todos os dados tratados, formas de proteção e compartilhamentos foram avaliados e considerados necessários e proporcionais para a atividade de  tratamento de dados pessoais.'

  const arrayNecessary = []
  dataCollecteds.forEach((dataCollected) => {
    dataCollected.collectedFields.forEach((collectedField) => {
      arrayNecessary.push(collectedField?.necessary)
    })
  })

  if (arrayNecessary.every((item) => item === true)) {
    return MESSAGE_ALL_NECESSARY
  }

  return MESSAGE_DEFAULT
}

export default noDataMessage
