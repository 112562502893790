import api from 'service/api'

const API = api.create('dponet')
const base = '/financial'

const report = async () => await API.get(`${base}/report`)
const stats = async (params) => await API.get(`${base}/stats`, { params })
const orders = async (params) => await API.get(`${base}/orders`, { params })

const financial = {
  report,
  stats,
  orders,
}

export default financial
