const KNOWLEDGE_BASE_ID = 1
const INFORMATION_SECURITY_ID = 2

const ALL_STATUS = null
const ACTIVE_STATUS = 'ACTIVE'
const INACTIVE_STATUS = 'INACTIVE'
const DRAFT_STATUS = 'DRAFT'

const ALL_STATUSES = [ACTIVE_STATUS, INACTIVE_STATUS, DRAFT_STATUS]

const TABS = [
  {
    value: ALL_STATUS,
    label: 'TODOS',
  },
  {
    value: ACTIVE_STATUS,
    label: 'PUBLICADOS',
  },
  {
    value: DRAFT_STATUS,
    label: 'RASCUNHOS',
  },
  {
    value: INACTIVE_STATUS,
    label: 'INATIVOS',
  },
]

const SUBCATEGORIES_TABS = [
  {
    value: ALL_STATUS,
    label: 'TODOS',
  },
  {
    value: ACTIVE_STATUS,
    label: 'ATIVOS',
  },
  {
    value: INACTIVE_STATUS,
    label: 'INATIVOS',
  },
]

const STATUSES_LABELS = {
  [ACTIVE_STATUS]: 'PUBLICADO',
  [DRAFT_STATUS]: 'RASCUNHO',
  [INACTIVE_STATUS]: 'INATIVO',
}

const STATUSES_LABELS_IS_FAQ = {
  [ACTIVE_STATUS]: 'ATIVO',
  [DRAFT_STATUS]: 'RASCUNHO',
  [INACTIVE_STATUS]: 'INATIVO',
}

const STATUSES_LABELS_BAR = {
  [ACTIVE_STATUS]: 'ativado(a)',
  [DRAFT_STATUS]: 'salvo(a)',
  [INACTIVE_STATUS]: 'inativado(a)',
}

const STATUSES_LABELS_BAR_IS_FAQ = {
  [ACTIVE_STATUS]: 'publicado(a)',
  [DRAFT_STATUS]: 'salvo(a)',
  [INACTIVE_STATUS]: 'inativado(a)',
}

const technicalFaqs = {
  ACTIVE_STATUS,
  ALL_STATUS,
  ALL_STATUSES,
  DRAFT_STATUS,
  INACTIVE_STATUS,
  INFORMATION_SECURITY_ID,
  KNOWLEDGE_BASE_ID,
  STATUSES_LABELS_BAR_IS_FAQ,
  STATUSES_LABELS_BAR,
  STATUSES_LABELS_IS_FAQ,
  STATUSES_LABELS,
  SUBCATEGORIES_TABS,
  TABS,
}

export default technicalFaqs
