import PropTypes from 'prop-types'
import { Page, Container, ContentHeader } from 'components'

import { useFetch } from 'hooks'
import * as service from 'service'
import { routes } from 'Routes'

import { EconomicActivityForm } from './components'

const EconomicActivitiesEdit = ({ match }) => {
  const {
    params: { activityId },
  } = match

  const { response, isLoading } = useFetch(
    service.dponet.economicActivities.show,
    {
      activityId,
    },
    [],
  )

  const navigationsRoutes = [
    {
      text: 'Listagem',
      route: routes.economicActivities.all,
    },
  ]

  return (
    <Page title="Edição de atividade econômica">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Edição"
          menu="Atividade econômica"
          subtitle="Edição de atividade econômica"
          menuNavigations={navigationsRoutes}
        />
        {!isLoading && (
          <EconomicActivityForm
            isEdit
            activity={response?.data?.economicActivity}
          />
        )}
      </Container>
    </Page>
  )
}

EconomicActivitiesEdit.propTypes = {
  match: PropTypes.object,
}

export default EconomicActivitiesEdit
