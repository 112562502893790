import { useState } from 'react'
import { Button, Box, Grid } from '@material-ui/core'
import { isNil, isEmpty } from 'lodash'
import { DatePicker } from '@material-ui/pickers'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'

import { useSnackbar, useFetch } from 'hooks'

import { Select } from 'components'

import constants from 'constants/index'

import { Container, ContentHeader, LoadingFeedback, Page } from 'components'
import { ContractTable, Feedback } from './components'
import * as service from 'service'

import helpers from 'helpers'
import { getBaseURL } from 'service/env'
import useStyles from './styles'

const ContractInvoicesMain = () => {
  const classes = useStyles()
  const [loadingMessage, setLoadingMessage] = useState(false)
  const [contractIds, setContractIds] = useState([])
  const [queryData, setQueryData] = useState({})
  const [dataResponse, setDataResponse] = useState('')
  const snackbar = useSnackbar()

  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      nextDateAdvance: null,
      gateway: 'superfin',
    },
  })

  const onSubmit = (data) => {
    setQueryData({
      gatewayEq: data.gateway,
      nextDateAdvanceEq: moment(data.nextDateAdvance).format('YYYY-MM-DD'),
      kind: 'recurrence',
    })
  }

  const condition = !isNil(queryData?.nextDateAdvanceEq)

  const { response, isLoading } = useFetch(
    service.dponet.paymentContract.lists,
    queryData,
    [queryData?.nextDateAdvanceEq, queryData?.gatewayEq],
    condition,
  )

  const inLoading = condition ? isLoading : false

  const handleInvoices = async () => {
    try {
      setLoadingMessage(true)
      const ids = contractIds.map((item) => {
        return { id: item }
      })

      const url = getBaseURL('dponet')
      const uri = `${url}/payment_contract_invoice`

      const responseStream = await fetch(uri, {
        method: 'POST',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          Authorization: service.dponet.auth.getToken() || '',
        },
        body: JSON.stringify({ payment_contract_ids: ids }),
      })

      if (!responseStream.ok || !responseStream.body) {
        throw responseStream.statusText
      }

      const reader = responseStream.body.getReader()
      const decoder = new TextDecoder()
      const loopRunner = true

      const dataRegex = /data: "/g
      const nRegex = /"\n\n/g

      while (loopRunner) {
        const { value, done } = await reader.read()

        if (done) break

        const decodedChunk = decoder.decode(value, { stream: true })

        const decodedChunkFormatted = decodedChunk
          .replaceAll(dataRegex, '')
          .replaceAll(nRegex, '')

        setDataResponse((resData) => resData + decodedChunkFormatted)
      }

      setQueryData({})
      setContractIds([])
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error || 'Falha no faturamento!',
        ),
        variant: 'error',
      })
    } finally {
      setLoadingMessage(false)
    }
  }

  return (
    <>
      <Page title="Faturamentos">
        <LoadingFeedback open={inLoading} />
        <Container maxWidth={false}>
          <ContentHeader
            title="Faturamentos"
            menu="Vendas"
            subtitle="Faturamentos"
          />
          <Grid container spacing={4}>
            <Grid xs={12} sm={6} item>
              <Box className={classes.box}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container spacing={2}>
                    <Grid item xs="12" sm="5">
                      <Controller
                        as={
                          <DatePicker
                            label="Data de faturamento"
                            color="primary"
                            format="DD/MM/YYYY"
                            variant="outlined"
                            fullWidth
                            onChange={(newValue) => ({ value: newValue })}
                          />
                        }
                        control={control}
                        name="nextDateAdvance"
                        mode="onChange"
                      />
                    </Grid>
                    <Grid item xs="12" sm="5">
                      <Controller
                        as={
                          <Select
                            items={constants.paymentOrder.GATEWAYES}
                            label="Intermediador de pagamento"
                          />
                        }
                        control={control}
                        name="gateway"
                        mode="onChange"
                      />
                    </Grid>
                    <Grid item xs="12" sm="2">
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        disabled={isNil(watch('nextDateAdvance'))}
                      >
                        Buscar
                      </Button>
                    </Grid>
                  </Grid>
                </form>
                <Box mt={6}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={isEmpty(contractIds)}
                    fullWidth
                    onClick={handleInvoices}
                  >
                    Faturar contratos selecionados
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid xs={12} sm={6} item>
              <Feedback data={dataResponse} loading={loadingMessage} />
            </Grid>
            {condition && (
              <Grid xs={12} item>
                <ContractTable
                  paymentContracts={response?.data?.paymentContracts || []}
                  setContractIds={setContractIds}
                />
              </Grid>
            )}
          </Grid>
        </Container>
      </Page>
    </>
  )
}

export default ContractInvoicesMain
