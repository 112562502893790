import { useState } from 'react'
import PropTypes from 'prop-types'

import { Page, Container, ContentHeader, LoadingFeedback } from 'components'
import { DepartmentForm } from './components'

import { useFetch } from 'hooks'

import { routes } from 'Routes'
import * as service from 'service'

const DepartmentEdit = ({ match }) => {
  const {
    params: { departmentId },
  } = match

  const [loading, setLoading] = useState(false)

  const navigation = [{ text: 'Listagem', route: routes.departments.all }]

  const { response, isLoading, refresh } = useFetch(
    service.dponet.departments.get,
    {
      departmentId,
    },
    [departmentId],
  )

  const department = response?.data?.department

  const {
    response: usersResponse,
    isLoading: userIsLoading,
    refresh: availableUsersRefresh,
  } = useFetch(
    service.dponet.departments.availableUsers,
    {
      departmentId,
      companyId: department?.company?.id,
    },
    [departmentId],
    !isLoading,
  )

  const availableUsers = usersResponse?.data?.users

  return (
    <Page title="Edição de departamento">
      <LoadingFeedback open={loading || isLoading || userIsLoading} />
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Edição"
          menu="Departamento"
          subtitle="Edição de departamento"
          menuNavigations={navigation}
        />
        {!isLoading && (
          <DepartmentForm
            availableUsers={availableUsers}
            availableUsersRefresh={availableUsersRefresh}
            department={department}
            isEdit
            refresh={refresh}
            setLoading={setLoading}
            loading={loading}
          />
        )}
      </Container>
    </Page>
  )
}

DepartmentEdit.propTypes = {
  match: PropTypes.object,
}

export default DepartmentEdit
