import constants from 'constants/index'

const statusColor = (statusId, classes) => {
  switch (statusId) {
    case constants.dataProcess.PENDING_STATUS:
      return classes.pending
    case constants.dataProcess.WAITING_REVIEW_STATUS:
      return classes.waitingReview
    case constants.dataProcess.DISAPPROVED_STATUS:
      return classes.reviewDisapproved
    case constants.dataProcess.APPROVED_STATUS:
      return classes.reviewApproved
    case constants.dataProcess.REVIEWING_STATUS:
      return classes.reviewing
    case constants.dataProcess.INACTIVE_STATUS:
      return classes.inactive
    case constants.dataProcess.AUTO_REVIEW_STATUS:
      return classes.autoReview
    default:
      return ''
  }
}

export default statusColor
