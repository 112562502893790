import React from 'react'

import { Box, Paper, Radio } from '@material-ui/core'
import clsx from 'clsx'
import useStyles from './styles'
import PropTypes from 'prop-types'

const CardRadio = ({
  children,
  alignCard,
  alignX,
  checked,
  action,
  noBorder,
  isRealAndIsEdit,
}) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      justifyContent={alignCard}
      alignItems={alignX}
      className={clsx(classes.root, {
        [classes.checked]: checked && !isRealAndIsEdit,
        [classes.noBorder]: noBorder,
      })}
      component={Paper}
      variant="outlined"
      onClick={action}
      py={checked ? 0 : 0.2}
      px={1}
    >
      <Box className={classes.content}>{children}</Box>
      <Box>
        <Radio
          checked={checked}
          color={!isRealAndIsEdit ? 'primary' : 'default'}
          size="small"
        />
      </Box>
    </Box>
  )
}

CardRadio.propTypes = {
  children: PropTypes.object.isRequired,
  alignCard: PropTypes.string,
  alignX: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  action: PropTypes.func.isRequired,
  noBorder: PropTypes.bool,
  isEdit: PropTypes.bool,
}

export default CardRadio
