import { Grid, Box, Typography } from '@material-ui/core'
import { isEmpty } from 'lodash'

import { Database as DatabaseIcon } from 'react-feather'

import { NoData } from 'components'
import { TableCollectedFields } from './components'
import { DropdownDetails } from '../../'

import useDataProcess from 'hooks/useDataProcess'

import rangeAgeContent from 'helpers/dataCollected/rangeAgeContent'

const DataCollecteds = () => {
  const { data } = useDataProcess()
  const dataCollecteds = data.dataCollecteds

  const dataCollectedSubtitles = (dataCollected) => {
    return [
      `Faixa Etária: ${rangeAgeContent(dataCollected?.titularAgeRange)}`,
      `Quantidade aproximada de titulares:  ${
        dataCollected?.holderNumber || 'Não definido'
      }`,
    ]
  }

  return (
    <Box>
      {!isEmpty(dataCollecteds) ? (
        <>
          <Box my={2}>
            <Typography variant="h4">Todos dados tratados</Typography>
          </Box>
          <Grid container spacing={2}>
            {dataCollecteds.map((dataCollected) => (
              <Grid item xs={12} md={6}>
                <DropdownDetails
                  title={dataCollected.titularCategory.name}
                  subtitles={dataCollectedSubtitles(dataCollected) || []}
                  iconTitle={<DatabaseIcon />}
                >
                  <TableCollectedFields
                    collectedFields={dataCollected.collectedFields}
                  />
                </DropdownDetails>
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <NoData message="Nenhum dado tratado encontrado" />
      )}
    </Box>
  )
}

export default DataCollecteds
