import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const SkeletonInputs = ({ grids }) => {
  return (
    <Grid container spacing={2}>
      {grids?.map((grid, index) => (
        <Grid key={index} item xs={grid}>
          <Skeleton variant="rect" width="100%" height={45} />
        </Grid>
      ))}
    </Grid>
  )
}

export default SkeletonInputs
