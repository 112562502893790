import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'
import { Calendar as CalendarIcon } from 'react-feather'

import helpers from 'helpers'

import useStyles from './styles'

const Header = ({ status, updatedAt }) => {
  const classes = useStyles()

  return (
    <Box width="100%" display="flex" justifyContent="space-between">
      <Box
        display="flex"
        alignItems="center"
        className={classes.cardHeaderStatus}
      >
        <Box
          className={clsx(
            classes.statusBox,
            helpers.dataProcesses.statusColor(status, classes),
          )}
          mr={1}
        >
          {helpers.dataProcesses.statusIcon(status, 22)}
        </Box>
        <Typography>
          {helpers.dataProcesses.statusLabel(status)?.toUpperCase()}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <Box display="flex" flexDirection="column" pr={0.5} textAlign="end">
          <Typography variant="body1">Última atualização</Typography>
          <Typography variant="body2">{`feita ${helpers.formatters.dateFromNow(
            updatedAt,
          )}`}</Typography>
        </Box>
        <CalendarIcon />
      </Box>
    </Box>
  )
}

Header.propTypes = {
  status: PropTypes.number,
  updatedAt: PropTypes.string,
}

export default Header
