import constants from 'constants/index'

const statusLabel = (statusId) => {
  switch (statusId) {
    case constants.dataProcess.PENDING_STATUS:
      return constants.dataProcess.PENDING_LABEL
    case constants.dataProcess.WAITING_REVIEW_STATUS:
      return constants.dataProcess.WAITING_REVIEW_LABEL
    case constants.dataProcess.DISAPPROVED_STATUS:
      return constants.dataProcess.DISAPPROVED_LABEL
    case constants.dataProcess.APPROVED_STATUS:
      return constants.dataProcess.APPROVED_LABEL
    case constants.dataProcess.REVIEWING_STATUS:
      return constants.dataProcess.REVIEWING_LABEL
    case constants.dataProcess.INACTIVE_STATUS:
      return constants.dataProcess.INACTIVE_LABEL
    case constants.dataProcess.AUTO_REVIEW_STATUS:
      return constants.dataProcess.AUTO_REVIEW_LABEL
    default:
      return '-'
  }
}

export default statusLabel
