import { Container, ContentHeader, Page } from 'components'
import { CopyForm } from './components'

const DataProcessesMain = () => {
  return (
    <Page title="Copiar Questionários">
      <Container
        maxWidth={false}
        spacingXl={20}
        spacingLg={15}
        spacingMd={10}
        spacingSm={5}
        spacingXs={5}
      >
        <ContentHeader
          menu="Questionários"
          title="Copiar"
          subtitle="Copiar questionários"
        />
        <CopyForm />
      </Container>
    </Page>
  )
}

export default DataProcessesMain
