import { Container, ContentHeader, Page, LoadingFeedback } from 'components'
import { FormLastUpdate } from './components'
import { useFetch } from 'hooks'
import * as service from 'service'

const LastUpdatesNew = ({ match }) => {
  const {
    params: { lastUpdateId },
  } = match

  const { response, isLoading } = useFetch(
    service.dponet.lastUpdates.get,
    { lastUpdateId },
    [],
  )

  return (
    <Page title="Editar atualização">
      <LoadingFeedback open={isLoading} />
      <Container spacingXl={40} spacingLg={25} spacingMd={10} spacingSm={10}>
        <ContentHeader
          menu="Atualizações"
          title="Últimas Atualizações"
          subtitle="Últimas Atualizações"
        />
        {!isLoading && (
          <FormLastUpdate
            isEdit
            editionPermitted
            lastUpdate={response?.data?.lastUpdate}
          />
        )}
      </Container>
    </Page>
  )
}

export default LastUpdatesNew
