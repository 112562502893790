import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async () => {
  return await dponetAPI.get('/legal_frameworks')
}

const legalFrameworks = { get }

export default legalFrameworks
