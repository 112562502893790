import axios from 'axios'
import api from 'service/api'
import * as service from 'service'
import { getBaseURL } from '../../env'

const dponetAPI = api.create('dponet')
const dponetAPIWithoutErrorValidation = api.create('dponet', false)
const baseURL = getBaseURL('dponet')

const get = async ({ companyId = '', ...params }) => {
  return await dponetAPI.get(`/companies/${companyId}`, { params })
}

const listWithCompanyAudits = async ({ ...params }) => {
  return await dponetAPI.get(`/companies/list_with_audits`, { params })
}

const create = async (data) => {
  return await dponetAPI.post('/companies', data)
}

const put = async ({ companyId, ...data }) => {
  return await dponetAPI.put(`/companies/${companyId}`, data)
}

const changeResponsable = async ({ companyId, ...data }) => {
  return await dponetAPI.post(
    `/companies/${companyId}/change_responsable`,
    data['params'],
  )
}

const responsableUser = async ({ companyId }) => {
  return await dponetAPI.get(`/companies/${companyId}/responsable_user`)
}

const addLogo = async ({ companyId = '', logo }) => {
  var formData = new FormData()

  formData.append('logo', logo)

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/companies/${companyId}/add_logo`,
    data: formData,
    ...config,
  })
}

const secondaryEconomicActivities = async ({
  companyId,
  unscoped,
  ...data
}) => {
  return await dponetAPI.get(
    `/companies/${companyId}/secondary_economic_activities`,
    data,
  )
}

const addSecondaryEconomicActivity = async ({
  companyId,
  economicActivityId,
}) => {
  return await dponetAPI.post(
    `/companies/${companyId}/add_secondary_economic_activity/${economicActivityId}`,
  )
}

const removeSecondaryEconomicActivity = async ({
  companyId,
  economicActivityId,
}) => {
  return await dponetAPI.delete(
    `/companies/${companyId}/remove_secondary_economic_activity/${economicActivityId}`,
  )
}

const companiesListCsv = async ({ ...params }) => {
  return await dponetAPI.get(`/companies/list_steps`, { params })
}

const changeStatus = async ({ companyId }) => {
  return await dponetAPI.put(`/companies/${companyId}/change_status`)
}

const destroy = async ({ companyId, description = '' }) => {
  return await dponetAPI.delete(
    `/companies/${companyId}?description=${description}`,
  )
}

const logs = async ({ companyId }) => {
  return await dponetAPI.get(`/companies/${companyId}/logs`)
}

const exportDataProcesses = async ({ ...params }) => {
  return await dponetAPI.get('/companies/export_processes', { params })
}

const importDataProcesses = async ({ companyId, fileToImport }) => {
  var formData = new FormData()

  formData.append('file_to_import', fileToImport)

  const config = {
    headers: {
      'Content-Type': fileToImport.type,
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/companies/${companyId}/import_data_processes`,
    data: formData,
    ...config,
  })
}

const exportRipd = async ({ companyId }) => {
  return await dponetAPI.post(`/ripds/export/${companyId}`)
}

const verifyDocument = async (document) => {
  return await dponetAPIWithoutErrorValidation.post(
    `/companies/verify_documents/${document}`,
  )
}

const consultQrCode = async ({ companyId }) => {
  return await dponetAPI.get(`/companies/${companyId}/consult_qrcode`)
}

const updateDemoPartner = async ({ companyId, demoPartner }) => {
  return await dponetAPI.put(`/companies/${companyId}/update_demo_partner`, {
    demoPartner,
  })
}

const changeStatusCompanyInTrial = async ({ companyId, statusId }) => {
  return await dponetAPI.patch(
    `/company_free_trials/${companyId}/change_status/${statusId}`,
  )
}

const upgradeSupplierToClient = async (companyId) => {
  return await dponetAPI.put(
    `/companies/${companyId}/upgrade_supplier_to_client`,
  )
}

const companies = {
  get,
  create,
  put,
  addLogo,
  changeResponsable,
  responsableUser,
  secondaryEconomicActivities,
  addSecondaryEconomicActivity,
  removeSecondaryEconomicActivity,
  companiesListCsv,
  changeStatus,
  destroy,
  logs,
  exportDataProcesses,
  importDataProcesses,
  listWithCompanyAudits,
  exportRipd,
  verifyDocument,
  consultQrCode,
  updateDemoPartner,
  changeStatusCompanyInTrial,
  upgradeSupplierToClient,
}

export default companies
