import { colors } from '@material-ui/core'

import palette from '../palette'

const MuiTableCell = {
  head: {
    color: palette.titleTableCell,
    fontWeight: 700,
    borderColor: colors.grey[300],
  },
  root: {
    borderBottom: `1px solid ${colors.grey[300]}`,
    padding: '16px',
    fontSize: '0.875rem',
  },
}

export default MuiTableCell
