import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

import { Box, Button } from '@material-ui/core'

import {
  PasswordValidator,
  PasswordInputField,
  LoadingFeedback,
} from 'components'
import { routes } from 'Routes'
import * as service from 'service'
import schemas from '../../schemas'

export const Form = ({ defaultValues }) => {
  const [loading, setLoading] = useState(false)
  const [passwordInput, setPasswordInput] = useState('')
  const [justSubmitted, setJustSubmitted] = useState(false)

  const history = useHistory()
  const auth = useAuth()
  const snackbar = useSnackbar()

  const { handleSubmit, errors, control } = useForm({
    validationSchema: schemas.newPassword,
    defaultValues: defaultValues,
  })

  const onSubmit = async (data) => {
    setLoading(true)

    try {
      const response = await service.dponet.user.newPassword(data)

      const login = {
        email: response?.data?.email,
        password: data.password,
      }

      await service.dponet.auth.login(login)

      snackbar.open({
        message: 'Senha alterada com sucesso',
        variant: 'success',
      })

      setTimeout(auth.loadDataWithAnimation, 1000)
    } catch (error) {
      history.push(routes.root, {
        error:
          error?.response?.data?.error?.message || 'E-mail ou senha inválidos!',
      })
    }
  }

  const handleSubmitForm = (event) => {
    setJustSubmitted(true)
    handleSubmit(onSubmit)(event)
  }

  const handleChangePasswordInput = (e) => {
    setPasswordInput(e.target.value)
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <Box mb={3}>
        <PasswordValidator
          passwordInput={passwordInput}
          justSubmitted={justSubmitted}
        />
      </Box>
      <form onSubmit={handleSubmitForm}>
        <Controller
          as={
            <PasswordInputField
              label="Nova senha"
              color="primary"
              variant="outlined"
              error={!!errors.password}
              fullWidth
            />
          }
          control={control}
          name="password"
          mode="onChange"
          onChange={([event]) => {
            handleChangePasswordInput(event)
            return event.target.value
          }}
        />
        <Controller
          as={
            <PasswordInputField
              label="Confirme a senha"
              variant="outlined"
              margin="normal"
              error={!!errors.passwordConfirm}
              helperText={errors?.passwordConfirm?.message}
              fullWidth
            />
          }
          control={control}
          name="passwordConfirm"
          mode="onChange"
        />
        <Controller as={<></>} control={control} name="resetPasswordToken" />
        <Box
          mt={2}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Button
            color="primary"
            type="submit"
            variant="contained"
            disabled={loading}
          >
            {loading ? 'Carregando...' : 'Cadastrar nova senha'}
          </Button>
        </Box>
      </form>
    </>
  )
}

export default Form
