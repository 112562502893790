const ALL_TAB = 0
const INDICATED_STATUS = 'INDICATED'
const APPROVED_STATUS = 'APPROVED'
const REFUSED_STATUS = 'REFUSED'
const INACTIVE_STATUS = 'INACTIVE'

const GENERAL_INFORMATION_VALUE = 'general_information'
const GENERAL_INFORMATION_LABEL = 'Informações Gerais'

const STATUSES_LABELS = {
  [INDICATED_STATUS]: 'Pendente',
  [APPROVED_STATUS]: 'Aprovado',
  [REFUSED_STATUS]: 'Negado',
  [INACTIVE_STATUS]: 'Inativo',
}

const TABS = [
  {
    value: ALL_TAB,
    label: 'Todos',
  },
  {
    value: INDICATED_STATUS,
    label: 'Pendentes',
  },
  {
    value: APPROVED_STATUS,
    label: 'Aprovados',
  },
  {
    value: REFUSED_STATUS,
    label: 'Negados',
  },
  {
    value: INACTIVE_STATUS,
    label: 'Inativos',
  },
]

const companySuppliers = {
  TABS,
  INACTIVE_STATUS,
  ALL_TAB,
  INDICATED_STATUS,
  APPROVED_STATUS,
  REFUSED_STATUS,
  STATUSES_LABELS,
  GENERAL_INFORMATION_VALUE,
  GENERAL_INFORMATION_LABEL,
}

export default companySuppliers
