import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ planId = '', ...params }) => {
  return await dponetAPI.get(`/plans/${planId}`, { params })
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('/plans', data)
}

const edit = async ({ planId = '', ...data }) => {
  return await dponetAPI.put(`/plans/${planId}`, data)
}

const changeStatus = async ({ planId }) => {
  return await dponetAPI.put(`/plans/${planId}/change_status`)
}

const destroy = async ({ planId }) => {
  return await dponetAPI.delete(`/plans/${planId}`)
}

const logs = async ({ planId }) => {
  return await dponetAPI.get(`/plans/${planId}/logs`)
}

const plans = {
  get,
  create,
  edit,
  changeStatus,
  destroy,
  logs,
}

export default plans
