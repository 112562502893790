const styles = (theme) => ({
  root: {
    color: theme.palette.white,
    borderRadius: '4px',
  },
  pending: {
    background: `linear-gradient(90deg, ${theme.palette.azure} 0%,${theme.palette.spanishBlue} 100%)`,
  },
  waitingReview: {
    background: `linear-gradient(90deg, ${theme.palette.yellowOrange} 0%,${theme.palette.rawSienna} 100%)`,
  },
  reviewDisapproved: {
    background: `linear-gradient(90deg, ${theme.palette.darkRed} 0%,${theme.palette.crimsonRed} 100%)`,
  },
  reviewApproved: {
    background: `linear-gradient(90deg, ${theme.palette.greenPantone} 0%,${theme.palette.forestGreenWeb} 100%)`,
  },
  reviewing: {
    background: `linear-gradient(90deg, ${theme.palette.blueViolet} 0%,${theme.palette.russianViolet} 100%)`,
  },
  inactive: {
    background: `linear-gradient(90deg, ${theme.palette.spanishGray} 0%,${theme.palette.battleshipGrey} 100%)`,
  },
  autoReview: {
    background: `linear-gradient(90deg, ${theme.palette.teal} 0%,${theme.palette.deepJungleGreen} 100%)`,
  },
})
export default styles
