import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'
import clsx from 'clsx'

import { Box, Typography } from '@material-ui/core'
import { Filters } from 'components'

import { Eye as EyeIcon } from 'react-feather'

import useStyles from './styles'
import helpers from 'helpers'
import constants from 'constants/index'

const LegalFrameworksFilters = ({ filter }) => {
  const classes = useStyles()
  const { nameWithDocumentByArrayObject } = helpers.companies.nameWithDocument

  const { WAITING_REVIEW_STATUS, REVIEWING_STATUS } = constants.dataProcess
  const [isLoadingCompaniesInput, setIsLoadingCompaniesInput] = useState(false)
  const [companyInputValue, setCompanyInputValue] = useState('')

  const [companies, setCompanies] = useState([])

  const { loadCompanies, mountCompanyParams, instructionsText } =
    helpers.companies.typeAndSearch

  const handleTypeCompaniesInput = (e) => {
    setCompanyInputValue(e.target.value)
  }

  useEffect(() => {
    const getCompanies = setTimeout(() => {
      const hasCompanyValueToFetch =
        companyInputValue.length < 3 && isEmpty(filter.filters?.companyId)

      if (hasCompanyValueToFetch || !filter.drawerOpen) return

      loadCompanies(
        mountCompanyParams(companyInputValue, filter.filters),
        setIsLoadingCompaniesInput,
        setCompanies,
      )
    }, 1000)

    return () => clearTimeout(getCompanies)
    //eslint-disable-next-line
  }, [companyInputValue])

  const companiesOptionsValues = nameWithDocumentByArrayObject(
    isLoadingCompaniesInput ? [] : companies,
  )

  return (
    <Filters filter={filter}>
      {[WAITING_REVIEW_STATUS, REVIEWING_STATUS].map((status) => (
        <input key={status} cardselect="true" name="statusId" value={status}>
          <Box display="flex" alignItems="center" height="inherit" p={1}>
            <Box
              className={clsx(
                classes.statusBox,
                helpers.dataProcesses.statusColor(status, classes),
              )}
              mr={1}
            >
              <EyeIcon size={18} />
            </Box>
            <Typography>
              {helpers.dataProcesses.statusLabel(status)?.toUpperCase()}
            </Typography>
          </Box>
        </input>
      ))}
      <input textfieldinput="true" label="Identificador" name="id" />
      <input textfieldinput="true" label="Nome" name="name" />
      <input datepickerinput="true" label="Data de criação" name="createdAt" />
      <select
        autocompleteselectinput="true"
        label="Empresa"
        name="companyId"
        loading={isLoadingCompaniesInput}
        onChangeTypeSearch={handleTypeCompaniesInput}
        optionvalues={companiesOptionsValues}
        noOptionsText={instructionsText(companyInputValue)}
      />
      <select
        multipleselectinput="true"
        label="Compartilhamento"
        name="shareTypes"
        optionvalues={constants.dataProcess.SHARE_TYPE_OPTIONS}
      />
    </Filters>
  )
}

LegalFrameworksFilters.propTypes = {
  filter: PropTypes.object,
}

export default LegalFrameworksFilters
