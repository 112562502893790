import permissions from '../../permissions'

const DATA_PROCESS_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.VISUALIZE,
    tag: permissions.DATA_PROCESSES.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE,
    tag: permissions.DATA_PROCESSES.CREATE,
  },
  {
    name: 'Reabrir',
    tag: permissions.DATA_PROCESSES.REOPEN,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.ACTIVATE_OR_INACTIVATE,
    tag: permissions.DATA_PROCESSES.INACTIVE_AND_REACTIVE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.DESTROY,
    tag: permissions.DATA_PROCESSES.DESTROY,
  },
  {
    name: 'Listar e gerenciar processos sugeridos',
    tag: permissions.DATA_PROCESSES.LIST_AND_MANAGE_SUGGESTED,
  },
]

export default DATA_PROCESS_LIST
