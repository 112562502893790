import api from 'service/api'
import humps from 'humps'

import * as service from 'service'
import { AUTH_TOKEN } from 'service/constants'

const dponetAPI = api.create('dponet', false)

const login = async (data) => {
  const loginResponse = await dponetAPI.post('/authentication', data)
  const unauthorizedMessage =
    'Apenas usuários colaboradores podem acessar a plataforma.'

  if (loginResponse.data.authToken) {
    const token = loginResponse.data.authToken

    setToken(token)
    return loginResponse
  }
  throw unauthorizedMessage
}

const forgotPassword = async (data) => {
  const response = await dponetAPI.post('/users/password', {
    api_user: data,
  })

  return response
}

const setToken = (token) => {
  localStorage.setItem(AUTH_TOKEN, token)
}

const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN)
}

const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN)
}

const logout = () => {
  service.history.push('/')
  removeToken()
}

const getUserIdFromToken = () => {
  const token = getToken()
  const payload = humps.camelizeKeys(JSON.parse(atob(token.split('.')[1])))

  return payload.userId
}

const isAuthenticated = () => {
  const token = getToken()
  return !!token
}

const auth = {
  login,
  forgotPassword,
  logout,
  getToken,
  setToken,
  removeToken,
  isAuthenticated,
  getUserIdFromToken,
}

export default auth
