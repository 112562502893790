import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  paper: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  boxProcessInfo: {
    border: `solid 1px ${theme.colors.dataProcess.borders.secondary}`,
    borderRadius: '4px',
  },
  boxProcessName: {
    backgroundColor: theme.colors.dataProcess.borders.secondary,
  },
  buttonReprove: {
    backgroundColor: theme.colors.dataProcess.buttons.secondary,
    textShadow: '1px 4px 4px rgba(0, 0, 0, 0.25)',
    maxHeight: 30,
  },
}))

export default styles
