import Main from './GroupsMain'
import New from './GroupsNew'
import Edit from './GroupsEdit'

const Groups = {
  Main,
  New,
  Edit,
}

export default Groups
