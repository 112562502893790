import * as yup from 'yup'

const schemaProfile = () =>
  yup.object().shape({
    name: yup.string().when('originProfileId', {
      is: false,
      then: yup.string().required(),
    }),
    description: yup.string().required(),
    companyId: yup.object().when('createDefaultProfile', {
      is: false,
      then: yup.object().required(),
    }),
  })

export default schemaProfile
