import { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton } from 'components'

import constants from 'constants/index'
import useStyles from '../../../TableRowUsers/styles'

import { DialogCertificate } from './components'
import {
  UserCompaniesForm,
  UserCompanyRemoveLinkingDialog,
} from '../../components'
import UserChangeStatusDialog from '../../../UserChangeStatusDialog'

import { Chip, Button } from '@material-ui/core'
import clsx from 'clsx'

const UserCompanies = ({ user, userCompanies, refresh }) => {
  const [linkedCompany, setLinkedCompany] = useState(false)
  const [linkedCompanyStatus, setLinkedCompanyStatus] = useState(false)
  const [removeLinkingCompany, setRemoveLinkingCompany] = useState(false)
  const [currentUserCompany, setCurrentUserCompany] = useState({})

  const classes = useStyles()

  const handleEdit = (userCompany) => {
    setCurrentUserCompany(userCompany)
    setLinkedCompany(true)
  }

  const handleChangeStatus = (userCompany) => {
    setCurrentUserCompany(userCompany)
    setLinkedCompanyStatus(true)
  }

  const handleRemoveLinking = (userCompany) => {
    setCurrentUserCompany(userCompany)
    setRemoveLinkingCompany(true)
  }

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width="10%">ID</TableCell>
            <TableCell width="35%">Empresa</TableCell>
            <TableCell width="30%">Perfil</TableCell>
            <TableCell width="15%">Status</TableCell>
            <TableCell width="10%">Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userCompanies?.map((userCompany) => (
            <TableRow key={userCompany?.id} hover>
              <TableCell disableTooltip>{userCompany?.id}</TableCell>
              <TableCell>{userCompany?.company?.name}</TableCell>
              <TableCell>{userCompany?.profile?.name}</TableCell>
              <TableCell disableTooltip>
                <Chip
                  className={clsx(classes.root, {
                    [classes.active]:
                      userCompany?.status === constants.userCompanies.ACTIVE,
                    [classes.inactive]:
                      userCompany?.status === constants.userCompanies.INACTIVE,
                  })}
                  align="center"
                  size="small"
                  label={
                    userCompany?.status === constants.userCompanies.ACTIVE
                      ? 'Ativo'
                      : 'Inativo'
                  }
                />
              </TableCell>
              <TableCell disableTooltip>
                <MenuButton>
                  <Button
                    onClick={() => handleEdit(userCompany)}
                    fullWidth
                    size="small"
                  >
                    Editar
                  </Button>
                  <Button
                    onClick={() => handleChangeStatus(userCompany)}
                    fullWidth
                    size="small"
                  >
                    {userCompany?.status === constants.userCompanies.ACTIVE
                      ? 'Inativar'
                      : 'Ativar'}
                  </Button>
                  <DialogCertificate
                    userCompany={userCompany}
                    refresh={refresh}
                  >
                    Certificado
                  </DialogCertificate>
                  <Button
                    onClick={() => handleRemoveLinking(userCompany)}
                    fullWidth
                    size="small"
                  >
                    Excluir
                  </Button>
                </MenuButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {currentUserCompany?.id && (
        <>
          <UserCompaniesForm
            setOpen={setLinkedCompany}
            refresh={refresh}
            open={linkedCompany}
            currentUserCompany={currentUserCompany}
            isEdit
          />
          <UserChangeStatusDialog
            user={user}
            userCompany={currentUserCompany}
            isCollaborator={false}
            open={linkedCompanyStatus}
            setOpen={setLinkedCompanyStatus}
            refresh={refresh}
          />
          <UserCompanyRemoveLinkingDialog
            currentUserCompany={currentUserCompany}
            open={removeLinkingCompany}
            setOpen={setRemoveLinkingCompany}
            refresh={refresh}
          />
        </>
      )}
    </>
  )
}

UserCompanies.propTypes = {
  userCompanies: PropTypes.arrayOf(Object),
}

export default UserCompanies
