import Main from './SubgroupsMain'
import New from './SubgroupNew'
import Edit from './SubgroupEdit'

const Subgroups = {
  Main,
  New,
  Edit,
}

export default Subgroups
