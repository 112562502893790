import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  rootBar: {
    display: 'flex',
    borderRadius: '4px',
    position: 'fixed',
    zIndex: 9999,
    top: '50%',
    height: '240px',
    marginTop: '-120px',
    right: theme.spacing(2),
    alignItems: 'center',
  },
  icons: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#F2FBFF',
    boxShadow:
      '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
  },
  icon: {
    borderRadius: '4px',
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },
  reviewingIcon: {
    '&:hover': {
      backgroundColor: theme.palette.blueViolet,
      opacity: 0.8,
    },
    color: theme.palette.white,
    backgroundColor: theme.palette.blueViolet,
  },
  acceptIcon: {
    '&:hover': {
      backgroundColor: theme.palette.greenPantone,
      opacity: 0.8,
    },
    color: theme.palette.white,
    backgroundColor: theme.palette.greenPantone,
  },
  reproveIcon: {
    '&:hover': {
      backgroundColor: theme.palette.darkRed,
      opacity: 0.8,
    },
    color: theme.palette.white,
    backgroundColor: theme.palette.darkRed,
  },
}))

export default styles
