import permissions from '../../permissions'

const GROUP_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.GROUPS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.CREATE,
    tag: permissions.GROUPS.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.EDIT,
    tag: permissions.GROUPS.EDIT,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.DESTROY,
    tag: permissions.GROUPS.DESTROY,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.ACTIVATE_OR_INACTIVATE,
    tag: permissions.GROUPS.ACTIVATE_OR_INACTIVATE,
  },
]

export default GROUP_LIST
