import New from './TechnicalFaqsNew'
import Edit from './TechnicalFaqsEdit'
import KnowledgeBaseMain from './KnowledgeBase/KnowledgeBaseMain'
import SubcategoryMain from './Subcategories/SubcategoryMain'
import SubcategoryNew from './Subcategories/SubcategoryNew'
import SubcategoryEdit from './Subcategories/SubcategoryEdit'

const TechnicalFaqs = {
  New,
  Edit,
  KnowledgeBaseMain,
  SubcategoryMain,
  SubcategoryNew,
  SubcategoryEdit,
}

export default TechnicalFaqs
