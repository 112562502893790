import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  boxTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeIcon: {
    cursor: 'pointer',
    marginRight: '22px',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
}))

export default styles
