const DRAFT_STATUS_LABEL = 'RASCUNHO'
const ACTIVE_STATUS_LABEL = 'ATIVO'
const INACTIVE_STATUS_LABEL = 'INATIVO'

const DRAFT_STATUS_VALUE = '1'
const ACTIVE_STATUS_VALUE = '2'
const INACTIVE_STATUS_VALUE = '3'

const privacyPolicy = {
  ACTIVE_STATUS_LABEL,
  INACTIVE_STATUS_LABEL,
  DRAFT_STATUS_LABEL,
  ACTIVE_STATUS_VALUE,
  INACTIVE_STATUS_VALUE,
  DRAFT_STATUS_VALUE,
}

export default privacyPolicy
