import permissions from '../../permissions'

const PRIVACY_POLICY_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.VISUALIZE,
    tag: permissions.PRIVACY_POLICY.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE,
    tag: permissions.PRIVACY_POLICY.CREATE,
  },
]

export default PRIVACY_POLICY_LIST
