import Main from './DepartmentsMain'
import New from './DepartmentsNew'
import Edit from './DepartmentsEdit'

const Departments = {
  Main,
  New,
  Edit,
}

export default Departments
