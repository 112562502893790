import permissions from '../../permissions'

const PAYMENT_CONTRACT_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE,
    tag: permissions.PAYMENT_CONTRACTS.MANAGE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.PAYMENT_CONTRACTS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.SHOW,
    tag: permissions.PAYMENT_CONTRACTS.SHOW,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.INVOICE,
    tag: permissions.PAYMENT_CONTRACTS.INVOICE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.DESTROY,
    tag: permissions.PAYMENT_CONTRACTS.DESTROY,
  },
]

export default PAYMENT_CONTRACT_LIST
