import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Tab,
  TablePagination,
  Tabs,
  Typography,
  useMediaQuery,
} from '@material-ui/core'

import {
  Container,
  ContentHeader,
  FilterButton,
  LoadingFeedback,
  Page,
} from 'components'
import { TechnicalFaqsFilter, TechnicalFaqsTable } from '../components'

import { useFetch, useFilter, usePagination } from 'hooks'

import constants from 'constants/index'
import * as service from 'service'
import { routes } from 'Routes'
import theme from 'theme'

const KnowledgeBaseMain = () => {
  const [selectedTab, setSelectedTab] = useState(
    constants.technicalFaqs.ALL_STATUS,
  )

  const filter = useFilter()
  const history = useHistory()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()

  const {
    response: faqsResponse,
    isLoading: faqsIsLoading,
    refresh: faqsRefresh,
  } = useFetch(
    service.dponet.technicalFaqs.get,
    {
      categoryIds: [
        constants.technicalFaqs.KNOWLEDGE_BASE_ID,
        constants.technicalFaqs.INFORMATION_SECURITY_ID,
      ],
      status: selectedTab,
      ...filter.filters,
      page,
      perPage,
    },
    [filter.filters, page, perPage, selectedTab],
  )

  const { response: subcategoriesResponse } = useFetch(
    service.dponet.technicalFaqs.getSubcategory,
    { categoryId: constants.technicalFaqs.KNOWLEDGE_BASE_ID },
    [filter.drawerOpen],
    !!filter.drawerOpen,
  )

  const technicalFaqs = faqsResponse?.data?.technicalFaqs

  const subcategoriesFaqs =
    subcategoriesResponse?.data?.knowledgeBaseSubcategories

  const subcategoryFormattingFaqs =
    subcategoriesFaqs &&
    subcategoriesFaqs.map((subcategory) => {
      return { id: subcategory?.id, name: subcategory?.title }
    })

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const handleTabChange = (_, clickedTab) => {
    setSelectedTab(clickedTab)
  }

  return (
    <Page title="Base de Conhecimento">
      <LoadingFeedback open={faqsIsLoading} />
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Apoio Técnico"
          title="Listagem"
          subtitle="Base de Conhecimento"
        >
          <FilterButton setDrawerOpen={filter.setDrawerOpen} />
        </ContentHeader>
        <Box
          mt={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              {constants.technicalFaqs.TABS.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
        </Box>
        <Card>
          <Box width="100%" display="flex" alignItems="center">
            <Grid container>
              <Grid item xs={6}>
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  ml={2}
                >
                  <Typography variant="h5">Base de Conhecimento</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box p={2} display="flex" justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<PlusCircleIcon width={15} height={15} />}
                    onClick={() => history.push(routes.technicalFaqs.new)}
                  >
                    Criar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <TechnicalFaqsTable
            technicalFaqs={technicalFaqs}
            refresh={faqsRefresh}
            faqName="Base de conhecimento"
          />
          <Box px={2} display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              count={faqsResponse?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Card>
      </Container>
      <TechnicalFaqsFilter
        filter={filter}
        subcategory={subcategoryFormattingFaqs}
      />
    </Page>
  )
}

export default KnowledgeBaseMain
