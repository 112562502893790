import { useState } from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

import InputMask from 'react-input-mask'
import TextField from '@material-ui/core/TextField'
import helpers from 'helpers'

const PhoneInput = ({
  fieldName,
  control,
  errors,
  label,
  phoneNumber,
  editionPermitted,
  editionMode,
  ...rest
}) => {
  const [mask, setMask] = useState(
    helpers.formatters.phoneMaskBuilder(phoneNumber),
  )

  const handleChange = (e) => {
    const value = e[0]?.target?.value
    if (!value) return
    setMask(helpers.formatters.phoneMaskBuilder(value))

    return value
  }

  if (editionMode) {
    return (
      <TextField
        fullWidth
        error={!!errors?.[fieldName]}
        helperText={errors?.[fieldName]?.message}
        label={label}
        disabled
        defaultValue={helpers.formatters.phoneDynamicMask(phoneNumber)}
      />
    )
  }

  return (
    <Controller
      as={
        <InputMask disabled={!editionPermitted} {...rest}>
          {(inputProps) => (
            <TextField
              fullWidth
              error={!!errors?.[fieldName]}
              placeholder="(99) 9999-9999"
              helperText={errors?.[fieldName]?.message}
              label={label}
              {...inputProps}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        </InputMask>
      }
      name={fieldName}
      mask={mask}
      onChange={handleChange}
      control={control}
      mode="onBlur"
    />
  )
}

PhoneInput.propTypes = {
  fieldName: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  editionPermitted: PropTypes.bool,
}

export default PhoneInput
