import PropTypes from 'prop-types'

import {
  Box,
  Typography,
  Card,
  CardHeader,
  Divider,
  Chip,
} from '@material-ui/core'
import constants from 'constants/index'

const VolumetryShow = ({ dataProcess }) => {
  const { storageMode, value, volumetry, deadlineJustification, laws } =
    dataProcess

  const storageTime = () => {
    switch (storageMode) {
      case constants.dataProcess.STORY_MODE_DEFINED_TYPE:
        return `${value} ${volumetry}`
      case constants.dataProcess.STORY_MODE_UNDEFINED_TYPE:
        return 'Não definido'
      default:
        return 'Permanente'
    }
  }

  return (
    <>
      <Box mb={2}>
        <Card>
          <CardHeader title="Tempo de armazenamento da organização" />
          <Divider />
          <Box m={2}>
            <Typography>{storageTime()}</Typography>
          </Box>
        </Card>
      </Box>

      {storageMode === constants.dataProcess.STORY_MODE_PERMANENT_TYPE && (
        <Box mb={2}>
          <Card>
            <CardHeader title="Motivo (lei que obriga fazer o armazenamento permanente)" />
            <Divider />
            <Box m={2}>
              {laws.map((law) => (
                <>
                  <Chip label={law?.name} />{' '}
                </>
              ))}
            </Box>
          </Card>
        </Box>
      )}

      {storageMode === constants.dataProcess.STORY_MODE_DEFINED_TYPE && (
        <Card>
          <CardHeader title="Justificativa do tempo de armazenamento" />
          <Divider />
          <Box m={2}>
            <Typography>{deadlineJustification}</Typography>
          </Box>
        </Card>
      )}
    </>
  )
}

VolumetryShow.propTypes = {
  dataProcess: PropTypes.object,
}

export default VolumetryShow
