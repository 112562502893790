import { useState } from 'react'

import { Button } from '@material-ui/core'
import { ConfirmationDialog, Permitted } from 'components'

import constants from 'constants/index'
import helpers from 'helpers'

import PropTypes from 'prop-types'
import * as service from 'service'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

import useSnackbar from 'hooks/useSnackbar'

const RemoveSubgroup = ({ subgroupId, refresh, isEdit }) => {
  const history = useHistory()
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const snackbar = useSnackbar()

  const deleteMessage = 'Deseja realmente excluir este subgrupo?'

  const handleDelete = async () => {
    try {
      await service.dponet.subgroups.destroy({ subgroupId })

      if (isEdit) {
        history.push(routes.subgroups.all)
      } else {
        refresh()
      }

      snackbar.open({
        message: 'Subgrupo excluido com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Erro ao excluir o subgrupo!',
      })
    }
    setOpenDeleteDialog(false)
  }

  return (
    <>
      <Permitted tag={constants.permissions.SUBGROUPS.DESTROY}>
        <Button
          fullWidth
          size="small"
          onClick={() => setOpenDeleteDialog(true)}
        >
          Excluir
        </Button>
      </Permitted>
      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        message={deleteMessage}
        cancelButtonText="Cancelar"
        buttonText="Excluir"
        actionAcceptButton={handleDelete}
      />
    </>
  )
}

RemoveSubgroup.propTypes = {
  subgroupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isEdit: PropTypes.bool,
  refresh: PropTypes.func,
}

RemoveSubgroup.propTypes = {
  isEdit: false,
  refresh: () => {},
}

export default RemoveSubgroup
