import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  iconBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2),
  },
  iconStyle: {
    borderRadius: theme.spacing(4),
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: theme.palette.white,
  },

  iconStyleEliminated: {
    backgroundColor: theme.palette.error.main,
  },
  iconStyleNotEliminated: {
    backgroundColor: theme.palette.success.main,
  },
  labelText: {
    fontWeight: '700 !important',
  },
}))

export default styles
