/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import Filters from 'components/Filters'

import { useFilterTags, useDataProcess } from 'hooks'
import { useDataProcessesCache } from 'hooks/caches'

import constants from 'constants/index'
import helpers from 'helpers'

const DataProcessFilters = ({ filter }) => {
  const [selectedCompanyId, setSelectedCompanyId] = useState(
    filter.filters.companyId,
  )
  const [eventSources, setEventSources] = useState()
  const [anotherSource, setAnotherSource] = useState(
    isNaN(parseInt(filter.filters.sourceDescription))
      ? ''
      : filter.filters.sourceDescription,
  )
  const [dataSources, setDataSources] = useState([])

  const [companies, setCompanies] = useState([])
  const [companyInputValue, setCompanyInputValue] = useState('')
  const [isLoadingCompaniesInput, setIsLoadingCompaniesInput] = useState(false)
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false)
  const [originCompanies, setOriginCompanies] = useState([])
  const [originCompaniesInputValue, setOriginCompaniesInputValue] = useState('')
  const [isLoadingOriginCompaniesInput, setIsLoadingOriginCompaniesInput] =
    useState(false)
  const [isLoadingOriginCompanies, setIsLoadingOriginCompanies] =
    useState(false)

  const {
    loadCompanies,
    mountCompanyParams,
    mountOriginCompaniesParams,
    instructionsText,
  } = helpers.companies.typeAndSearch
  const { nameWithDocumentByArrayObject } = helpers.companies.nameWithDocument

  const dataProcessCache = useDataProcessesCache()
  const { loadRelationOptions } = useDataProcess()
  const {
    defineDepartments,
    defineLegalFrameworks,
    defineTemplateCompanies,
    defineTitularCategories,
    defineCompanies,
  } = useFilterTags()

  const [relationOptionsResponse, departmentsResponse] =
    loadRelationOptions(selectedCompanyId)

  const legalFrameworkResponse = dataProcessCache.useListLegalFramework()

  const departments = departmentsResponse.data?.departments || []
  const relationOptions = relationOptionsResponse.relationOptions || {}
  const legalFrameworks = legalFrameworkResponse?.data?.legalFrameworks || []

  const companiesOptionsValues = nameWithDocumentByArrayObject(
    isLoadingCompaniesInput ? [] : companies,
  )

  const changeDataSources = (sourceDescription) => {
    const optionsFormatted = helpers.dataProcesses.mountDataProcessSources(
      relationOptions.dataProcessSources || [],
      departments,
      sourceDescription,
    )
    setDataSources(optionsFormatted)
  }

  const handleAnotherSource = (e) => {
    setAnotherSource(e.target.value)
  }

  const handleChangeSource = (event) => {
    const value = parseInt(event.target.value)
    setEventSources(value)

    changeDataSources(value)
  }

  const handleTypeOriginCompaniesInput = (e) => {
    setOriginCompaniesInputValue(e.target.value)
  }

  const handleTypeCompaniesInput = (e) => {
    setCompanyInputValue(e.target.value)
  }

  useEffect(() => {
    const filterSourceDescription = filter.filters.sourceDescription
    const numberSource = parseInt(filterSourceDescription)
    if (filter.filters.anotherSourceDescription) {
      filter.filters.sourceDescription = filter.filters.anotherSourceDescription
      delete filter.filters['anotherSourceDescription']
    }
    filter.setFilters(filter.filters)
    if (filterSourceDescription) {
      if (isNaN(numberSource)) {
        setEventSources(constants.dataProcess.ANOTHER_SOURCE)
      } else {
        changeDataSources(numberSource)
        setEventSources(numberSource)
      }
    } else {
      setEventSources()
    }
  }, [filter.filters, filter.reload])

  useEffect(() => {
    const fetchedRelationOptions =
      !departmentsResponse.isFetching && departmentsResponse.isFetched

    if (fetchedRelationOptions) {
      defineDepartments(departments)
    }
  }, [departmentsResponse.isFetching])

  useEffect(() => {
    const fetchedRelationOptions =
      !relationOptionsResponse.isFetching && relationOptionsResponse.isFetched

    if (fetchedRelationOptions) {
      defineLegalFrameworks(relationOptions.legalFrameworks)
      defineTitularCategories(relationOptions.titularCategories)
    }
  }, [relationOptionsResponse.isFetching])

  useEffect(() => {
    const getCompanies = setTimeout(() => {
      const hasCompanyValueToFetch =
        companyInputValue.length < 3 && isEmpty(filter.filters?.companyId)

      if (hasCompanyValueToFetch || !filter.drawerOpen) return

      loadCompanies(
        mountCompanyParams(companyInputValue, filter.filters),
        setIsLoadingCompaniesInput,
        setCompanies,
      )
    }, 1000)

    return () => clearTimeout(getCompanies)
  }, [companyInputValue])

  useEffect(() => {
    const getTemplateCompanies = setTimeout(() => {
      const hasOriginCompaniesValueToFetch =
        originCompaniesInputValue.length < 3 &&
        isEmpty(filter.filters?.originCompanies)

      if (hasOriginCompaniesValueToFetch || !filter.drawerOpen) return

      loadCompanies(
        mountOriginCompaniesParams(originCompaniesInputValue, filter.filters),
        setIsLoadingOriginCompaniesInput,
        setOriginCompanies,
      )
    }, 1000)

    return () => clearTimeout(getTemplateCompanies)
  }, [originCompaniesInputValue, filter.filters?.originCompanies])

  useEffect(() => {
    if (filter.drawerOpen) {
      if (filter.filters?.companyId) {
        loadCompanies(
          mountCompanyParams(companyInputValue, filter.filters),
          setIsLoadingCompanies,
          setCompanies,
        ).then((companies) => {
          defineCompanies(companies)
        })
      }

      if (filter.filters?.originCompanies) {
        loadCompanies(
          mountOriginCompaniesParams(originCompaniesInputValue, filter.filters),
          setIsLoadingOriginCompanies,
          setOriginCompanies,
        ).then((originCompanies) => {
          defineTemplateCompanies(originCompanies)
        })
      }
    }
  }, [filter.drawerOpen])

  useEffect(() => {
    if (!filter.drawerOpen) {
      if (originCompaniesInputValue) setOriginCompaniesInputValue('')
      if (companyInputValue) setCompanyInputValue('')
    }
  }, [filter.drawerOpen])

  return (
    <Filters
      filter={filter}
      isLoading={isLoadingCompanies || isLoadingOriginCompanies}
    >
      <input textfieldinput="true" label="Identificador" name="id" />
      <input textfieldinput="true" label="Nome" name="name" />
      <input datepickerinput="true" label="Data de criação" name="createdAt" />
      <select textfieldinput="true" label="Documentos anexados" name="document">
        <option value=""></option>
        <option value={true}>Com documento</option>
        <option value={false}>Sem documento</option>
      </select>
      <select
        autocompleteselectinput="true"
        label="Empresa"
        name="companyId"
        loading={isLoadingCompaniesInput}
        onChangeTypeSearch={handleTypeCompaniesInput}
        onChange={(companyId) => setSelectedCompanyId(companyId)}
        optionvalues={companiesOptionsValues}
        noOptionsText={instructionsText(companyInputValue)}
      />
      {!isEmpty(departments) && (
        <select
          autocompleteselectinput="true"
          label="Departamento"
          name="departmentId"
          optionvalues={departments}
        />
      )}
      <select textfieldinput="true" label="Tipo da empresa" name="companyKind">
        <option value="" />
        {constants.dataProcess.COMPANY_KIND_FILTER_OPTIONS.map(
          (companyKind) => (
            <option key={companyKind.id} value={companyKind.id}>
              {companyKind.name}
            </option>
          ),
        )}
      </select>
      <select
        multipleselectinput="true"
        label="Template de origem"
        name="originCompanies"
        loading={isLoadingOriginCompaniesInput}
        onChangeTypeSearch={handleTypeOriginCompaniesInput}
        optionvalues={originCompanies}
        noOptionsText={instructionsText(originCompaniesInputValue)}
      />
      <select
        textfieldinput="true"
        label="Origem dos dados"
        value={eventSources}
        name={constants.dataProcess.SOURCE_DESCRIPTION_FIELD}
        onChange={handleChangeSource}
        willclearfield={constants.dataProcess.DATA_PROCESS_SOURCES_FIELD}
      >
        <option value=""></option>
        <option value={constants.dataProcess.TITULAR_DATA_SOURCE}>
          O próprio titular
        </option>
        {!isEmpty(departments) && (
          <option value={constants.dataProcess.DEPARTMENT_SOURCE}>
            Outro departamento
          </option>
        )}
        {relationOptions.dataProcessSources && (
          <>
            <option value={constants.dataProcess.THIRD_PARTY_SOURCE}>
              Um terceiro
            </option>
            <option value={constants.dataProcess.PUBLIC_SOURCE}>
              Dados públicos
            </option>
          </>
        )}
        <option value={constants.dataProcess.ANOTHER_SOURCE}>Outro</option>
      </select>
      {constants.dataProcess.SOURCES_WITH_ENTITY.includes(eventSources) && (
        <select
          multipleselectinput="true"
          name={constants.dataProcess.DATA_PROCESS_SOURCES_FIELD}
          label={helpers.dataProcesses.getSourceFilterLabel(eventSources)}
          optionvalues={dataSources?.map((source) => ({
            id: source.entityId,
            name: source.entityName,
          }))}
        />
      )}
      {constants.dataProcess.ANOTHER_SOURCE === eventSources && (
        <input
          textfieldinput="true"
          name="anotherSourceDescription"
          label="Outra origem"
          onChange={handleAnotherSource}
          value={anotherSource}
          required
        />
      )}
      <select
        multipleselectinput="true"
        label="Risco"
        name="fragilityId"
        optionvalues={constants.dataProcess.FRAGILITY_FILTER_OPTIONS}
      />
      {selectedCompanyId && relationOptions.titularCategories && (
        <select
          multipleselectinput="true"
          label="Titular de dados"
          name="titularCategories"
          optionvalues={relationOptions.titularCategories}
        />
      )}
      {selectedCompanyId && relationOptions.dataCollectedOptions && (
        <select
          multipleselectinput="true"
          compareByName="true"
          label="Dado tratado"
          name="dataCollectedOptionNames"
          optionvalues={helpers.formatters.formatByNameInFilter(
            relationOptions.dataCollectedOptions,
          )}
        />
      )}
      {selectedCompanyId && relationOptions.shareProcesses && (
        <select
          multipleselectinput="true"
          compareByName="true"
          label="Com quem compartilha"
          name="shareProcessNames"
          optionvalues={helpers.formatters.formatByNameInFilter(
            relationOptions.shareProcesses,
          )}
        />
      )}
      <select
        textfieldinput="true"
        label="Transferência internacional"
        name="internationalTransfers"
      >
        <option value=""></option>
        <option value={true}>Sim</option>
        <option value={false}>Não</option>
      </select>
      {legalFrameworks.length > 0 && (
        <select
          multipleselectinput="true"
          label="Enquadramento legal"
          name="legalFrameworks"
          optionvalues={helpers.dataProcesses.formatLegalFrameworksFilterOptions(
            legalFrameworks,
          )}
        />
      )}
      <select
        multipleselectinput="true"
        label="Tipo de dados"
        name="dataTypes"
        optionvalues={constants.dataProcess.DATA_TYPE_OPTIONS}
      />
      <select
        multipleselectinput="true"
        label="Compartilhamento"
        name="shareTypes"
        optionvalues={constants.dataProcess.SHARE_TYPE_OPTIONS}
      />

      <select
        textfieldinput="true"
        label="Tempo de armazenamento"
        name="storageMode"
      >
        <option value=""></option>
        <option value={constants.dataProcess.STORY_MODE_DEFINED_TYPE}>
          Definido
        </option>
        <option value={constants.dataProcess.STORY_MODE_UNDEFINED_TYPE}>
          Não definido
        </option>
        <option value={constants.dataProcess.STORY_MODE_PERMANENT_TYPE}>
          Permanente
        </option>
      </select>
    </Filters>
  )
}

export default DataProcessFilters
