import { routes } from 'Routes'
import { reverse } from 'named-urls'
import { useHistory } from 'react-router-dom'

import { Button } from '@material-ui/core'

import { Permitted } from 'components'
import constants from 'constants/index'

import PropTypes from 'prop-types'

const ActionButtons = ({ churn }) => {
  const history = useHistory()

  const handleEdit = () => {
    history.push(
      reverse(routes.churns.edit, {
        churnId: churn?.id,
      }),
    )
  }

  const handleView = () => {
    history.push(
      reverse(routes.churns.show, {
        churnId: churn?.id,
      }),
    )
  }

  return (
    <>
      <Permitted tag={constants.permissions.CHURNS.MANAGE}>
        <Button
          fullWidth
          size="small"
          onClick={handleEdit}
          disabled={!constants.churn.STATUSES_INITIAL.includes(churn?.status)}
        >
          Editar
        </Button>
      </Permitted>
      <Permitted tag={constants.permissions.CHURNS.SHOW}>
        <Button fullWidth size="small" onClick={handleView}>
          Visualizar
        </Button>
      </Permitted>
    </>
  )
}

ActionButtons.propTypes = {
  churn: PropTypes.object,
}

export default ActionButtons
