import permissions from '../../permissions'

const REPORT_LIST = [
  {
    name: 'Exibir relatório de Ativação',
    tag: permissions.REPORTS.ACTIVATION,
  },
  {
    name: 'Exibir relatório de Selo',
    tag: permissions.REPORTS.SEAL,
  },
  {
    name: 'Exibir relatório de Consulta de Empresa',
    tag: permissions.REPORTS.COMPANY_QUERY,
  },
  {
    name: 'Exibir relatório de Ticket',
    tag: permissions.REPORTS.TICKET,
  },
]

export default REPORT_LIST
