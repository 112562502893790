import PropTypes from 'prop-types'

import { BaseDialog } from 'components'

import { Typography, Box, TextField } from '@material-ui/core'

import { useSnackbar } from 'hooks'

import { Controller, useForm } from 'react-hook-form'

import * as service from 'service'

import constants from 'constants/index'

import helpers from 'helpers'

import schema from './schema'

const DialogReproveRevision = ({
  dataProcess,
  open,
  setOpen,
  setLoading,
  onEvent,
}) => {
  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: {
      observation: '',
    },
  })

  const snackbar = useSnackbar()

  const handleReproveRevision = async (data) => {
    setLoading(true)

    try {
      await service.dponet.dataProcesses.changeStatus({
        dataProcessId: dataProcess.id,
        statusId: constants.dataProcess.DISAPPROVED_STATUS,
        observation: data.observation,
      })
      snackbar.open({
        message: 'Processamento de dados reprovado com sucesso!',
        variant: 'success',
      })
      onEvent()
      setLoading(false)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
      setLoading(false)
    }
  }

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title="Reprovar processamento de dados"
      closeButtonText="cancelar"
      actionButtonText="Reprovar"
      formRef="id-form-observation"
      noMargin
    >
      <Typography>
        Deseja realmente reprovar este processamento de dados?
      </Typography>
      <Box mt={2}>
        <form
          onSubmit={handleSubmit(handleReproveRevision)}
          id="id-form-observation"
        >
          <Controller
            as={
              <TextField
                multiline
                rows={4}
                label="Observação"
                color="primary"
                variant="outlined"
                error={!!errors.observation}
                helperText={errors.observation?.message}
              />
            }
            fullWidth
            name="observation"
            control={control}
          />
        </form>
      </Box>
    </BaseDialog>
  )
}

DialogReproveRevision.propTypes = {
  dataProcess: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  onEvent: PropTypes.func,
  setLoading: PropTypes.func,
}

DialogReproveRevision.defaultProps = {
  dataProcess: {},
  setOpen: () => {},
  setLoading: () => {},
  onEvent: () => {},
}

export default DialogReproveRevision
