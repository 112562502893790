import { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box } from '@material-ui/core'

import { CompanySizeRow, ChangeStatusOrDestroy } from '../'
import PerfectScrollbar from 'react-perfect-scrollbar'

const CompanySizesTable = ({ companySizes, refresh }) => {
  const [selectedSize, setSelectedSize] = useState({})
  const [openSize, setOpenSize] = useState(false)
  const [isDestroy, setIsDestroy] = useState(null)
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhum tamanho de empresas encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="10%">ID</TableCell>
              <TableCell width="50%" align="left">
                Nome
              </TableCell>
              <TableCell width="15%" align="center">
                Status
              </TableCell>
              <TableCell width="15%" align="center">
                Inativo em
              </TableCell>
              <TableCell width="10%" align="left">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companySizes?.map((companySize) => (
              <CompanySizeRow
                companySize={companySize}
                key={companySize.id}
                setSelectedSize={setSelectedSize}
                setOpenSize={setOpenSize}
                setIsDestroy={setIsDestroy}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      {selectedSize?.id && (
        <ChangeStatusOrDestroy
          companySize={selectedSize}
          refresh={refresh}
          setOpenSize={setOpenSize}
          openSize={openSize}
          isDestroy={isDestroy}
        />
      )}
    </Box>
  )
}

CompanySizesTable.propTypes = {
  companySizes: PropTypes.arrayOf(PropTypes.object),
}

export default CompanySizesTable
