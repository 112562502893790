import PropTypes from 'prop-types'
import { useState } from 'react'

import {
  Box,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core'
import { Card } from 'components'

import { useForm, Controller } from 'react-hook-form'

import { LoadingFeedback, BackButton } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import useStyles from './styles'

import * as service from 'service'

import helpers from 'helpers'
import schema from './schema'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

const CompanySizeForm = ({ isEdit, size }) => {
  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()

  const checkedEdit = isEdit ? size?.active : true
  const [checked, setChecked] = useState(checkedEdit)
  const labelChecked = checked ? 'Ativo' : 'Inativo'

  const { control, handleSubmit, errors, register } = useForm({
    validationSchema: schema(),
    defaultValues: {
      name: size?.name || '',
      active: size?.active || checkedEdit,
    },
  })

  const onSubmit = async (companySize) => {
    setLoading(true)
    try {
      if (isEdit) {
        await service.dponet.companySizes.edit({
          sizeId: size?.id,
          companySize,
        })
      } else {
        await service.dponet.companySizes.create(companySize)
      }
      snackbar.open({
        message: `Tamanho de empresa ${
          isEdit ? 'editada' : 'criada'
        } com sucesso!`,
        variant: 'success',
      })
      setLoading(false)
      history.push(routes.companySizes.all)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || `Erro ao ${isEdit ? 'editar' : 'criar'} tamanho de empresa!`,
        variant: 'error',
      })
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          title={`${isEdit ? 'Edição de' : 'Novo'} tamanho`}
          paddingCard={5}
        >
          <Grid container className={classes.gapGrid}>
            <Grid item xs={12}>
              <Typography variant="body1">Status</Typography>
              <Box display="flex" alignItems="center">
                <FormControlLabel
                  className={classes.labelSwitch}
                  control={
                    <Switch
                      checked={checked}
                      onChange={(event) => {
                        setChecked(event.target.checked)
                      }}
                    />
                  }
                  inputRef={register}
                  name="active"
                />
                <Typography variant="body1">{labelChecked}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Nome da atividade"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    fullWidth
                  />
                }
                control={control}
                name="name"
                mode="onBlur"
              />
            </Grid>
          </Grid>
        </Card>
        <Box display="flex" justifyContent="flex-start">
          <Box pr={2.5}>
            <BackButton onClick={() => history.push(routes.companySizes.all)}>
              Voltar
            </BackButton>
          </Box>
          <Box>
            <Button color="primary" variant="contained" type="submit">
              Salvar
            </Button>
          </Box>
        </Box>
      </form>
    </>
  )
}
CompanySizeForm.propTypes = {
  isEdit: PropTypes.bool,
  size: PropTypes.object,
}

CompanySizeForm.defaultProps = {
  isEdit: false,
  size: null,
}

export default CompanySizeForm
