import { useState, useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  Box,
  Typography,
  Card as CardMaterial,
  CircularProgress,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { Card } from 'components'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { useCompany } from 'hooks'

import helpers from 'helpers'
import * as service from 'service'

const EconomicActivityForm = ({
  control,
  errors,
  disableCertFields,
  editionPermitted,
  company,
}) => {
  const [selectedOption, setSelectedOption] = useState()

  const [economicActivities, setEconomicActivities] = useState([])
  const [economicActivitiesInputValues, setEconomicActivitiesInputValues] =
    useState(company?.mainEconomicActivity?.name || '')
  const [isLoadingEconomicActiviesInput, setIsLoadingEconomicActivities] =
    useState(false)

  const { isLoading } = useCompany()

  const { instructionsText } = helpers.companies.typeAndSearch
  const economicActivitiesOptionsValues = isLoadingEconomicActiviesInput
    ? []
    : economicActivities

  const loadEconomicActivities = async () => {
    setIsLoadingEconomicActivities(true)
    const listSecondaryEconomicActivities =
      company?.secondaryEconomicActivities?.map((obj) => obj.id)

    const responsibleCandidatesResponse =
      await service.dponet.economicActivities.get({
        active: true,
        perPage: 1000 * 1000,
        name: economicActivitiesInputValues,
        listSecondaryEconomicActivities,
      })

    setEconomicActivities(
      responsibleCandidatesResponse?.data?.economicActivities,
    )
    setIsLoadingEconomicActivities(false)
  }

  const handleTypeEconomicActivities = (e) => {
    setEconomicActivitiesInputValues(e.target.value)
  }

  useEffect(() => {
    const getResponsibleCandidates = setTimeout(() => {
      if (economicActivitiesInputValues.length < 3) return

      loadEconomicActivities()
    }, 1000)

    return () => clearTimeout(getResponsibleCandidates)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [economicActivitiesInputValues])

  useEffect(() => {
    setSelectedOption(company?.mainEconomicActivity)
    //eslint-disable-next-line
  }, [])

  return (
    <Card title="Selecione a atividade econômica da empresa">
      {!isLoading && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="economicActivityId"
              onChange={([, data]) => {
                setSelectedOption(data)
                return data
              }}
              mode="onChange"
              as={
                <Autocomplete
                  options={economicActivitiesOptionsValues || []}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) =>
                    option.id === value.id || value.id === 0
                  }
                  disabled={!editionPermitted}
                  noOptionsText={instructionsText(
                    economicActivitiesInputValues,
                  )}
                  loading={isLoadingEconomicActiviesInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={handleTypeEconomicActivities}
                      error={!!errors?.economicActivityId}
                      helperText={
                        errors?.economicActivityId
                          ? 'Selecione uma atividade econômica'
                          : ''
                      }
                      label="Atividade econômica da empresa"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingEconomicActiviesInput ? (
                              <CircularProgress color="primary" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              }
            />
          </Grid>
          {selectedOption && (
            <Box width="100%" my={2}>
              <Box ml={0.5} mb={2}>
                <Typography variant="body2" gutterBottom>
                  Dados da atividade econômica segundo o CNAE(Classificação
                  Nacional de Atividades Econômicas)
                </Typography>
              </Box>
              <CardMaterial>
                <PerfectScrollbar
                  options={{ wheelPropagation: false, useBothWheelAxes: true }}
                >
                  <Table size="medium" noWrap>
                    <TableHead>
                      <TableRow>
                        <TableCell width="25%">Sessão</TableCell>
                        <TableCell width="25%">Divisão</TableCell>
                        <TableCell width="25%">Grupo</TableCell>
                        <TableCell width="25%">Identificador CNAE</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={selectedOption.id} hover>
                        <TableCell>{selectedOption.session}</TableCell>
                        <TableCell>{selectedOption.division}</TableCell>
                        <TableCell>{selectedOption.group}</TableCell>
                        <TableCell>{selectedOption.classCnae}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </PerfectScrollbar>
              </CardMaterial>
              {!disableCertFields && (
                <Box mt={2} ml={0.5}>
                  <Typography variant="body2">
                    Realize a criação da empresa para adicionar atividades
                    econômicas secundárias.
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Grid>
      )}
    </Card>
  )
}

EconomicActivityForm.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  editionPermitted: PropTypes.bool,
  hasEconomicActivity: PropTypes.bool,
}

EconomicActivityForm.defaultProps = {
  hasEconomicActivity: false,
}
export default EconomicActivityForm
