import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'

import { Box, Grid } from '@material-ui/core'

import { Card } from 'components'

import * as service from 'service'

import helpers from 'helpers'

import { TransferList } from 'components'

import { useTransferList } from 'hooks'

const Segment = ({ isEdit, coupon, setSegmentToSubmit }) => {
  const { right } = useTransferList()

  const [isLoading, setIsLoading] = useState(true)
  const [segmentsRigth, setSegmentsRigth] = useState([])
  const [segmentsLeft, setSegmentsLeft] = useState([])

  useEffect(() => {
    const responseSegments = async () => {
      const response = await service.dponet.segments.get({
        active: true,
        perPage: 10000000,
      })

      const segmentLeftFiltered = (segmentsForAdd) => {
        const segmentsAdded = helpers.discountCoupon.toConvertArrayIsEdit(
          coupon?.discountCouponSegments || [],
        )
        const segmentsAddedAndFormated = segmentsAdded.map(
          (segment) => segment.id,
        )
        return segmentsForAdd.filter(
          (segment) => !segmentsAddedAndFormated.includes(segment.id),
        )
      }

      setSegmentsLeft(segmentLeftFiltered(response?.data.segments))
      setIsLoading(false)
    }

    responseSegments()

    if (isEdit) {
      setSegmentsRigth(
        helpers.discountCoupon.toConvertArrayIsEdit(
          coupon?.discountCouponSegments,
        ),
      )
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    setSegmentToSubmit(right)
    //eslint-disable-next-line
  }, [right])

  return (
    <Card title="Segmento(s)" paddingCard={5}>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {!isLoading && (
              <TransferList
                leftItens={segmentsLeft || []}
                rightItens={segmentsRigth || []}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

Segment.propTypes = {
  isEdit: PropTypes.bool,
  coupon: PropTypes.object,
  setSegmentToSubmit: PropTypes.func.isRequired,
}

Segment.defaultProps = {
  isEdit: false,
}

export default Segment
