import chipStatusColor from './chipStatusColor'
import holderAffectedInformedStatus from './holderAffectedInformedStatus'
import optionImpactIncidentHolderStatus from './optionImpactIncidentHolderStatus'
import dataProtectedNotPossibleIdentifyHolderStatus from './dataProtectedNotPossibleIdentifyHolderStatus'

const incidents = {
  chipStatusColor,
  holderAffectedInformedStatus,
  optionImpactIncidentHolderStatus,
  dataProtectedNotPossibleIdentifyHolderStatus,
}

export default incidents
