import { useState } from 'react'

import { Container, ContentHeader, LoadingFeedback, Page } from 'components'
import { ChurnStepper } from './components'

import { useFetch } from 'hooks'

import * as service from 'service'

const ChurnsShow = ({ match }) => {
  const {
    params: { churnId },
  } = match

  const [loading, setLoading] = useState(false)

  const { response, isLoading } = useFetch(
    service.dponet.churns.show,
    { churnId },
    [],
  )

  return (
    <Page title="Visualização de churn">
      {isLoading ? (
        <LoadingFeedback open={isLoading || loading} />
      ) : (
        <Container maxWidth={false}>
          <ContentHeader
            menu="Visualização de churn"
            title="Listagem"
            subtitle="Visualização de churn"
          />
          <ChurnStepper
            churn={response?.data?.churn}
            setLoading={setLoading}
            show
          />
        </Container>
      )}
    </Page>
  )
}

export default ChurnsShow
