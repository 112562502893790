import { isEmpty } from 'lodash'

import palette from 'theme/palette'

import { oppenedQuestions as questionsFormatted } from '../../helpers'

import components from '../../components'
import { GAP, marginTable } from '../../constants'

const openQuestions = async (pdf, date, questionnaireFormatted, subtitle) => {
  pdf.addPage('a4', 'p')
  components.lineWithDate(pdf, date)

  components.subtitle(pdf, subtitle, GAP * 1.5)

  questionnaireFormatted.forEach((questionnaireFormatted, index) => {
    let oppenedQuestions = questionsFormatted(subtitle, questionnaireFormatted)

    let tableHeight = pdf.lastAutoTable.finalY + 10
    if (index === 0) {
      tableHeight = GAP * 2
    }
    if (isEmpty(oppenedQuestions)) {
      components.table(
        pdf,
        [[questionnaireFormatted?.name]],
        [['Nenhuma medida encontrada']],
        tableHeight + marginTable + GAP * 0.1,
        1,
      )
    } else {
      const measuresAdoptedTable = []
      ;(oppenedQuestions || []).forEach((addoptedQuestion) => {
        measuresAdoptedTable.push([
          `${addoptedQuestion?.theme}`,
          `${addoptedQuestion?.affirmative}`,
        ])
      })
      components.table(
        pdf,
        [[questionnaireFormatted?.name, 'Resposta']],
        measuresAdoptedTable,
        tableHeight + marginTable + GAP * 0.1,
        0.4,
      )
    }
  })

  components.lineWithDate(pdf, date)
  components.numberPage(pdf, palette.black)
}

export default openQuestions
