import PropTypes from 'prop-types'

import { Button } from '@material-ui/core'

const ButtonComponent = ({
  type = 'submit',
  variant = 'contained',
  color = 'primary',
  form,
  title,
  onClick = () => {},
}) => {
  return (
    <Button
      type={type}
      variant={variant}
      color={color}
      style={{ padding: '10px 30px' }}
      form={form}
      onClick={onClick}
    >
      {title}
    </Button>
  )
}

ButtonComponent.propTypes = {
  type: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  form: PropTypes.string,
}

export default ButtonComponent
