import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  chip: {
    borderRadius: theme.spacing(0.5),
    fontWeight: 700,
    height: theme.spacing(3),
    color: theme.palette.white,
  },
  chipBlack: { backgroundColor: theme.palette.black },
  chipNotPaid: { backgroundColor: theme.palette.azure },
  chipWaitingPayment: { backgroundColor: theme.palette.yellowOrange },
  chipWaitingConfirm: { backgroundColor: theme.palette.yellowOrange },
  chipPaid: { backgroundColor: theme.palette.greenPantone },
  chipFailure: { backgroundColor: theme.palette.darkRed },
  chipCanceled: { backgroundColor: theme.palette.spanishGray },
  chipIntegration: { backgroundColor: theme.palette.blueViolet },
  chipLoss: { backgroundColor: theme.palette.cgRed },
}))

export default styles
