import PropTypes from 'prop-types'

import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { Card } from 'components'
import { CouponUsesTypes } from './components'

import helpers from 'helpers'
import constants from 'constants/index'

const CouponUses = ({ formMethods }) => {
  const { errors, control, watch } = formMethods

  return (
    <Card title="Destinatário do cupom" paddingCard={3} fullHeight>
      <Grid container>
        <Grid item xs={6}>
          <Box mb={1}>
            <Controller
              control={control}
              name="couponUses"
              mode="onChange"
              as={
                <RadioGroup>
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label={constants.discountCoupon.MULTI_USES_LABEL}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label={constants.discountCoupon.ONE_USES_LABEL}
                  />
                </RadioGroup>
              }
            />
          </Box>
          {watch('couponUses') === 'false' && (
            <Box mt={2}>
              <Controller
                as={
                  <TextField
                    label="CPF/CNPJ do Destinatário"
                    type="text"
                    color="primary"
                    InputProps={{
                      maxLength: 18,
                    }}
                    variant="outlined"
                    error={!!errors.document}
                    helperText={errors?.document?.message}
                    fullWidth
                  />
                }
                onChange={([event]) => {
                  return helpers.formatters.cnpjAndCpfMask(event.target.value)
                }}
                control={control}
                name="document"
                mode="onBlur"
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={6}>
          <CouponUsesTypes />
        </Grid>
      </Grid>
    </Card>
  )
}

CouponUses.propTypes = {
  formMethods: PropTypes.object.isRequired,
}

export default CouponUses
