import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import useStyles from './styles'
import clsx from 'clsx'

import {
  Box,
  Button,
  Container,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'

import { DialogLogs, LoadingFeedback, MenuButton, Page } from 'components'
import {
  GeneralInformation,
  Header,
  QuestionnaireDetails,
  SupplierAvatar,
} from './components'
import { routes } from 'Routes'

import helpers from 'helpers'
import constants from 'constants/index'
import * as service from 'service'

const SuppliersShow = ({ match }) => {
  const history = useHistory()

  const { companySupplierId } = match.params

  const [loading, setLoading] = useState(false)
  const [questionnaires, setQuestionnaires] = useState([])
  const [questionnaire, setQuestionnaire] = useState([])
  const [logs, setLogs] = useState([])
  const [openLogsDialog, setOpenLogsDialog] = useState(false)
  const [currentTab, setCurrentTab] = useState(
    constants.companySuppliers.GENERAL_INFORMATION_VALUE,
  )
  const [companySupplier, setCompanySupplier] = useState({})
  const classes = useStyles()

  const loadCompany = async () => {
    const responseSuppliers = await service.dponet.companySuppliers.get({
      companySupplierId,
    })
    const { companySupplier } = responseSuppliers.data
    setCompanySupplier(companySupplier)
  }

  useEffect(() => {
    const loadData = async () => {
      await loadCompany()

      const responseQuestionnaires =
        await service.dponet.companySuppliers.supplierQuestionnaires({
          companySupplierId,
        })

      const { questionnaires } = responseQuestionnaires.data

      setQuestionnaires(questionnaires)
      setLoading(false)
    }

    const loadLogs = async () => {
      const response = await service.dponet.companySuppliers.logs({
        companySupplierId,
      })

      setLogs(response?.data?.dponetAudits)
    }

    loadData()
    loadLogs()
    //eslint-disable-next-line
  }, [])

  const tabs =
    helpers.companySuppliers.mountTabsWithQuestionnaires(questionnaires)

  const handleBack = () => {
    history.push(routes.suppliers.all)
  }

  const handleTabsChange = (e, value) => {
    setCurrentTab(value)
    if (value !== constants.companySuppliers.GENERAL_INFORMATION_VALUE) {
      setQuestionnaire(
        questionnaires.find((questionnaire) => questionnaire.id === value),
      )
    } else {
      setQuestionnaire({})
    }
  }

  const handleLogs = () => {
    setOpenLogsDialog(true)
  }

  return (
    <Page title="Fornecedores">
      <LoadingFeedback open={loading} />
      <Container
        maxWidth={false}
        className={clsx(classes.blueContainer, classes.containerSpacing)}
      >
        <Header>
          <MenuButton iconButton={false}>
            <Button
              color="secondary"
              onClick={handleLogs}
              fullWidth
              size="small"
            >
              Histórico
            </Button>
          </MenuButton>
          <SupplierAvatar company={companySupplier} />
        </Header>
      </Container>
      <Container
        maxWidth={false}
        className={clsx(classes.whiteContainer, classes.containerSpacing)}
      >
        <Box
          pt={14}
          pb={4}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h3">
            <b>{companySupplier?.supplier?.name}</b>
          </Typography>
        </Box>
        <Tabs
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
          textColor="primary"
          indicatorColor="primary"
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              onClick={(e) => handleTabsChange(e, tab.value)}
            />
          ))}
        </Tabs>
      </Container>
      <Container maxWidth={false} className={classes.containerSpacing}>
        {currentTab === constants.companySuppliers.GENERAL_INFORMATION_VALUE ? (
          <GeneralInformation
            company={{
              supplierResponsibleEmail:
                companySupplier?.supplierResponsibleEmail,
              companyName: companySupplier?.company?.name,
              ...companySupplier?.supplier,
            }}
          />
        ) : (
          <QuestionnaireDetails questionnaire={questionnaire} />
        )}
        <Box mt={4}>
          <Button variant="contained" onClick={handleBack}>
            Voltar
          </Button>
        </Box>
      </Container>
      <DialogLogs
        open={openLogsDialog}
        setOpen={setOpenLogsDialog}
        logs={logs}
      />
    </Page>
  )
}

export default SuppliersShow
