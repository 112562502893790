import { useQuery, useQueryClient } from '@tanstack/react-query'

import constants from 'constants/index'

const useDepartmentsCache = () => {
  const queryClient = useQueryClient()

  const useList = (params, options) => {
    const query = constants.queryKeys.departments.listCache(params, options)

    return useQuery(query)
  }

  const useView = (id, options) => {
    const query = constants.queryKeys.departments.viewCache(id, options)

    return useQuery(query)
  }

  const useAvailableUsersCache = (id, companyId, options) => {
    const query = constants.queryKeys.departments.availableUsersCache(
      id,
      companyId,
      options,
    )

    return useQuery(query)
  }

  const useUpdateCache = (id) => {
    constants.queryKeys.departments
      .updateCache(id)
      .map((query) => queryClient.invalidateQueries(query))
  }

  return { useList, useView, useUpdateCache, useAvailableUsersCache }
}

export default useDepartmentsCache
