const styles = (theme) => ({
  dataCollectedContainer: {
    padding: theme.spacing(1),
  },
  dataCollectedTable: {
    marginBottom: theme.spacing(2),
  },
  documentsCollectedTable: {
    marginBottom: theme.spacing(3),
  },
  button: {
    padding: '0px',
  },
  buttonLabel: {
    textTransform: 'capitalize',
  },
  buttonTitle: {
    paddingLeft: '5px',
  },
  textTableRight: {
    textAlign: 'center',
  },
  actionHeader: {
    paddingRight: '70px',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperModal: {
    width: 500,
    margin: theme.spacing(1),
    padding: theme.spacing(2, 2, 3),
    outline: 'none',
  },
  tableContainerModal: {
    marginTop: theme.spacing(2),
    maxHeight: 400,
  },
})

export default styles
