import { useState } from 'react'
import { useTheme } from '@material-ui/styles'
import { Menu as MenuIcon, Grid as GridButton } from 'react-feather'
import { Skeleton } from '@material-ui/lab'
import {
  Box,
  TablePagination,
  useMediaQuery,
  IconButton,
  Divider,
} from '@material-ui/core'

import { Main, DataProcessStatusCards, DataProcessFilters } from './components'
import { Container, Page, LoadingFeedback } from 'components'
import { ContentHeaderMain, TabsMain } from './components/Main/components'

import { useFilter, usePagination } from 'hooks'
import { useDataProcessesCache, useCompaniesCache } from 'hooks/caches'

import constants from 'constants/index'

const DataProcessesMain = () => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const filter = useFilter({
    statusId: constants.dataProcess.WAITING_REVIEW_STATUS,
  })
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(12)

  const companiesCache = useCompaniesCache()
  const dataProcessesCache = useDataProcessesCache()

  const [currentTab, setCurrentTab] = useState(
    !!filter.filters?.auditStatus
      ? constants.dataProcess.AUDIT_PROCESSES_VALUE
      : constants.dataProcess.ALL_PROCESSES_VALUE,
  )
  const [listingType, setListingType] = useState('cards')
  const [actionLoading, setActionLoading] = useState(false)

  const onGoingCompaniesToAuditResponse =
    companiesCache.useListWithCompanyAudits({})
  const dataProcessesResponse = dataProcessesCache.useList({
    ...filter.filters,
    perPage,
    page,
    auditStatus:
      currentTab === constants.dataProcess.AUDIT_PROCESSES_VALUE
        ? [
            constants.auditProcess.WAITING_ACTION_STATUS,
            constants.auditProcess.WAITING_UPDATE_STATUS,
          ]
        : '',
    minimal: true,
  })
  const processesStatsResponse = dataProcessesCache.useProcessesStats({
    ...filter.filters,
    page: undefined,
    perPage: undefined,
    statusId: undefined,
    withoutDepartments: true,
    auditStatus:
      currentTab === constants.dataProcess.AUDIT_PROCESSES_VALUE
        ? [
            constants.auditProcess.WAITING_ACTION_STATUS,
            constants.auditProcess.WAITING_UPDATE_STATUS,
          ]
        : '',
  })

  const onGoingCompaniesToAudit =
    onGoingCompaniesToAuditResponse.data?.listWithAudits || false
  const dataProcesses = dataProcessesResponse.data?.dataProcesses || []
  const processesStats = processesStatsResponse.data?.stats || {}

  return (
    <Page title="Listagem Processos">
      <LoadingFeedback
        open={dataProcessesResponse.isFetching || actionLoading}
      />
      <Container>
        <ContentHeaderMain filter={filter} />
        <DataProcessStatusCards
          filter={filter}
          processesStats={processesStats}
        />
        <Box
          mt={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <TabsMain
            filter={filter}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            hasOnGoingCompanyToAudit={onGoingCompaniesToAudit}
          />

          <Box display="flex" alignItems="center">
            <Box>
              <IconButton
                color="secondary"
                component="span"
                onClick={() => {
                  setListingType('table')
                }}
              >
                <MenuIcon size="16px" />
              </IconButton>
            </Box>
            <IconButton
              color="secondary"
              component="span"
              onClick={() => {
                setListingType('cards')
              }}
            >
              <GridButton size="16px" />
            </IconButton>
          </Box>
        </Box>
        <Divider />
        <Box my={2}>
          <Main
            dataProcesses={dataProcesses}
            isLoading={dataProcessesResponse.isFetching}
            listingType={listingType}
            setLoading={setActionLoading}
          />
        </Box>

        {dataProcessesResponse.data?.meta ? (
          <TablePagination
            component="div"
            count={dataProcessesResponse.data?.meta?.totalCount || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[4, 12, 36, 100]}
            labelRowsPerPage={isDesktop ? 'Por página' : ''}
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        ) : (
          <Box py="11px">
            <Skeleton variant="rect" width={200} height={30} />
          </Box>
        )}

        <DataProcessFilters filter={filter} />
      </Container>
    </Page>
  )
}

export default DataProcessesMain
