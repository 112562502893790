import { Grid } from '@material-ui/core'

import { Page, Container, ContentHeader, LoadingFeedback } from 'components'
import { QuestionsReply } from './components'

import { routes } from 'Routes'
import * as service from 'service'
import useFetch from 'hooks/useFetch'

const QuestionnairesCreate = ({ match }) => {
  const {
    params: { questionnaireId },
  } = match

  const menuNavigations = [
    { route: routes.questionnaires.all, text: 'Listagem' },
  ]

  const { response, isLoading, refresh } = useFetch(
    service.dponet.questionnaires.show,
    questionnaireId,
  )

  const questionnaire = response?.data?.questionnaire

  return (
    <Page title="Visualização de questionário">
      <LoadingFeedback open={isLoading} />
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Questionários por empresa"
          menuNavigations={menuNavigations}
          title="Visualização questionários por empresa"
          subtitle={questionnaire?.name}
        />
        {!isLoading && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <QuestionsReply questionnaire={questionnaire} refresh={refresh} />
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  )
}

export default QuestionnairesCreate
