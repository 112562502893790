import PropTypes from 'prop-types'

import { Typography, Grid } from '@material-ui/core'

import useStyles from './styles'

const DescriptionPaper = ({ description, children, isDocument }) => {
  const classes = useStyles()
  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item xs={12} sm={6} lg={7}>
        <Typography variant="caption">{description}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={5} className={classes.children}>
        {children}
      </Grid>
      {isDocument && (
        <Grid item xs={12} sm={12} lg={12}>
          <Typography variant="caption">
            Arquivos suportados: jpg, jpeg, png, pdf, docx.
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

DescriptionPaper.propTypes = {
  description: PropTypes.string.isRequired,
  children: PropTypes.node,
  isDocument: PropTypes.bool,
}

DescriptionPaper.defaultProps = {
  isDocument: false,
}

export default DescriptionPaper
