import permissions from '../../permissions'

const DOCUMENT_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.DOCUMENTS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE,
    tag: permissions.DOCUMENTS.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.DESTROY,
    tag: permissions.DOCUMENTS.DESTROY,
  },
  {
    name: permissions.PERMISSIONS_PRIVATE_DOCUMENTS_LABEL.LIST_PRIVATE_DOCUMENT,
    tag: permissions.DOCUMENTS.LIST_PRIVATE_DOCUMENTS,
  },
]

export default DOCUMENT_LIST
