import { makeStyles, Grid, Typography, Box, Button } from '@material-ui/core'

import styles from './styles'
import * as service from 'service'

import { routes } from 'Routes'
import { useAuth } from 'hooks'

const useStyles = makeStyles(styles)

const NoPermissionsFound = () => {
  const classes = useStyles()
  const auth = useAuth()

  const handleLogout = () => {
    auth.loadOut()
    service.dponet.auth.logout()
    service.history.push(routes.root)
  }

  return (
    <Box className={classes.root}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item lg={6} xs={12}>
          <Box className={classes.content}>
            <Typography variant="h1">403: Não autorizado</Typography>
            <Typography variant="subtitle2">
              Seu perfil está sem nenhuma permissão de gerenciamento, contacte
              seu gestor para entender o que aconteceu.
            </Typography>
            <Box mt={3}>
              <Button
                color="primary"
                variant="contained"
                onClick={handleLogout}
              >
                Sair
              </Button>
            </Box>
            <img
              alt="Não permitido"
              className={classes.image}
              src="/images/forbidden.svg"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default NoPermissionsFound
