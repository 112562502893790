import { makeStyles } from '@material-ui/core'
import polygonLightBlue from 'images/polygon_white_blue.svg'

const styles = makeStyles((theme) => ({
  boxIcon: {
    color: theme.palette.primary.main,
    backgroundImage: `url(${polygonLightBlue})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: 32,
    height: 36,
  },
}))

export default styles
