import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  gapGrid: {
    gap: theme.spacing(2.5),
  },
  infoBox: {
    border: '2px solid grey',
    backgroundColor: colors.grey[200],
    borderRadius: '4px',
  },
}))

export default styles
