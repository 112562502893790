import { Box, Card, CardHeader, Divider } from '@material-ui/core'

import PropTypes from 'prop-types'

import { GeneralInformation, SpecificRisks, ActionPlans } from '../'

import DocumentTable from '../ActionPlans/components/FragilityContent/components/DocumentsTable'

const QuestionContent = ({ rmc }) => {
  return (
    <>
      <Box mb={5}>
        <GeneralInformation rmc={rmc} />
      </Box>
      <Box mb={5}>
        <SpecificRisks rmc={rmc} />
      </Box>
      <Box mb={5}>
        <ActionPlans rmc={rmc} />
      </Box>
      <Box mb={5}>
        <Card>
          <CardHeader title="Documentos" />
          <Divider />
          <DocumentTable documents={rmc.documents} />
        </Card>
      </Box>
    </>
  )
}

QuestionContent.propsTypes = {
  rmc: PropTypes.object,
}

export default QuestionContent
