import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ ...params }) => {
  return await dponetAPI.get(`/user_companies`, { params })
}

const changeStatus = async ({ userCompanyId, ...data }) => {
  return await dponetAPI.put(
    `/user_companies/${userCompanyId}/change_status`,
    data,
  )
}

const consultCertificate = async ({ userCompanyId, certType }) => {
  return await dponetAPI.get(
    `/user_companies/${userCompanyId}/certification/${certType}`,
  )
}

const vinculateUserCompany = async ({ userId, companyId, ...params }) => {
  return await dponetAPI.post(
    `/user_companies/user/${userId}/company/${companyId}`,
    { ...params },
  )
}

const put = async ({ id, ...params }) => {
  return await dponetAPI.put(`/user_companies/${id}`, { ...params })
}

const deleteVinculate = async ({ userCompanyId }) => {
  return await dponetAPI.delete(`user_companies/${userCompanyId}`)
}

const userCompanies = {
  get,
  changeStatus,
  vinculateUserCompany,
  put,
  deleteVinculate,
  consultCertificate,
}

export default userCompanies
