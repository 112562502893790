import helpers from 'helpers'

import constants from 'constants/index'

const mountNecessityAndProportionalityParams = (data, dataCollected) => {
  let dataCollecteds = { collectedFields: [] }

  Object.keys(data).map((key) => {
    let splitKey = key.split('-')
    let keyType = splitKey[0]
    let idRelation = splitKey[1]
    let keyId = splitKey[2]

    if (
      keyType === constants.unnecessaryData.COLLECTED_FIELD_TYPE &&
      +idRelation === dataCollected.id
    ) {
      return dataCollecteds.collectedFields.push({
        id: keyId,
        necessary: helpers.formatters.convertBinaryToBool(data[key]),
      })
    }

    return key
  })

  return dataCollecteds
}

export default mountNecessityAndProportionalityParams
