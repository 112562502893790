import { useState } from 'react'

import { IconButton, Box } from '@material-ui/core'

import useDataProcess from 'hooks/useDataProcess'

import {
  Eye as EyeIcon,
  // Download as DownloadIcon,
  Feather as FeatherIcon,
  ThumbsUp as ThumbsUpIcon,
  ThumbsDown as ThumbsDownIcon,
} from 'react-feather'

import { History as HistoryIcon } from '@material-ui/icons'

import { DialogLogs } from 'components'

import { DialogApproveRevision, DialogReproveRevision } from '../'

import useSnackbar from 'hooks/useSnackbar'
import useStyles from './styles'
import clsx from 'clsx'
import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'

const ShortcutBar = ({
  handleTabsChange,
  executeScroll,
  onEvent,
  loading,
  setLoading,
}) => {
  const { data, logs, reloadEntireDataProcess, reloadOptions } =
    useDataProcess()

  const classes = useStyles()

  const snackbar = useSnackbar()

  const [dialogLogs, setDialogLogs] = useState(false)
  const [dialogApproveRevision, setDialogApproveRevision] = useState(false)
  const [dialogReproveRevision, setDialogReproveRevision] = useState(false)

  const goToLegalFramework = () => {
    handleTabsChange(constants.dataProcess.TAB_VIEW_VALUE.GENERAL)

    setTimeout(() => {
      executeScroll()
    }, 500)
  }

  const initializeRevision = async () => {
    setLoading(true)
    try {
      await service.dponet.dataProcesses.changeStatus({
        dataProcessId: data.id,
        statusId: constants.dataProcess.REVIEWING_STATUS,
      })
      snackbar.open({
        message: 'Revisão do processo iniciada com sucesso!',
        variant: 'success',
      })
      reloadEntireDataProcess()
      setLoading(false)
      onEvent()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
      setLoading(false)
    }
  }

  return (
    <>
      <Box className={classes.rootBar}>
        <Box className={classes.icons}>
          <IconButton
            aria-label="Legal framework shortcut"
            size="medium"
            color="primary"
            onClick={goToLegalFramework}
          >
            <FeatherIcon />
          </IconButton>
          {(helpers.dataProcesses.statusesCheck.autoReview(data) ||
            helpers.dataProcesses.statusesCheck.reviewing(data)) && (
            <IconButton
              className={clsx(classes.icon, classes.acceptIcon)}
              aria-label="Accept shortcut"
              size="medium"
              variant="raised"
              onClick={() => setDialogApproveRevision(true)}
            >
              <ThumbsUpIcon />
            </IconButton>
          )}
          {helpers.dataProcesses.statusesCheck.reviewing(data) && (
            <IconButton
              className={clsx(classes.icon, classes.reproveIcon)}
              aria-label="Refuse shortcut"
              size="medium"
              onClick={() => setDialogReproveRevision(true)}
            >
              <ThumbsDownIcon />
            </IconButton>
          )}
          {helpers.dataProcesses.statusesCheck.waitingReview(data) && (
            <IconButton
              className={clsx(classes.icon, classes.reviewingIcon)}
              aria-label="Revision shortcut"
              size="medium"
              onClick={initializeRevision}
            >
              <EyeIcon />
            </IconButton>
          )}

          {/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- */}
          {/* TODO adicionar funcionalidade quando a issue de API for implementado. */}

          {/* <IconButton
            aria-label="Download shortcut"
            size="medium"
            color="primary"
            onClick={() => {}}
          >
            <DownloadIcon />
          </IconButton> */}
          {/* =-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=- */}

          <IconButton
            aria-label="History shortcut"
            size="medium"
            color="primary"
            onClick={() => setDialogLogs(true)}
          >
            <HistoryIcon />
          </IconButton>
        </Box>
      </Box>
      <DialogApproveRevision
        data={data}
        reloadOptions={reloadOptions}
        open={dialogApproveRevision}
        setOpen={setDialogApproveRevision}
        onEvent={onEvent}
        setLoading={setLoading}
      />
      <DialogReproveRevision
        dataProcess={data}
        open={dialogReproveRevision}
        setOpen={setDialogReproveRevision}
        onEvent={onEvent}
        setLoading={setLoading}
      />
      <DialogLogs open={dialogLogs} setOpen={setDialogLogs} logs={logs} />
    </>
  )
}

export default ShortcutBar
