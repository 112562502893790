import axios from 'axios'

const get = async (cep) => {
  if (process.env.NODE_ENV !== 'production') {
    return { data: { error: 'true' } }
  }

  return await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
}

export default { get }
