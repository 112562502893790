import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  radioGroup: {
    gap: '7px',
    paddingBottom: '15px',
  },
  cardMaterial: {
    padding: '0px 10px',
    width: '182px',
  },
  selected: {
    padding: '0px 10px',
    width: '182px',
    borderWidth: '.15em',
    borderColor: theme.palette.brightBlue,
    backgroundColor: theme.palette.background.default,
  },
}))

export default styles
