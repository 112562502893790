import { useState } from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box } from '@material-ui/core'
import { InactivateProfileDialog } from '../components'

import ProfilesTableRow from '../ProfilesTableRow'
import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'

const ProfilesTable = ({ profiles, isCollaborator, refresh }) => {
  const [openInactivate, setOpenInactivate] = useState(false)
  const [profileInactivate, setProfileInactivate] = useState({})

  const handleInactivateProfile = (profile) => {
    setProfileInactivate(profile)
    setOpenInactivate(true)
  }

  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhum perfil encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width={isCollaborator ? '25%' : '5%'}>ID</TableCell>
              <TableCell width={isCollaborator ? '30%' : '25%'} align="left">
                Nome
              </TableCell>
              <TableCell width={isCollaborator ? '55%' : '25%'} align="left">
                Descrição
              </TableCell>
              {!isCollaborator && (
                <>
                  <TableCell width="20%" align="left">
                    Empresa
                  </TableCell>
                  <TableCell width="20%" align="left">
                    Categoria de perfil
                  </TableCell>
                </>
              )}
              <TableCell width="5%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profiles.map((profile) => (
              <ProfilesTableRow
                profile={profile}
                key={profile.id}
                isCollaborator={isCollaborator}
                handleInactivateProfile={handleInactivateProfile}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <InactivateProfileDialog
        profile={profileInactivate}
        open={openInactivate}
        setOpen={setOpenInactivate}
        isCollaborator={isCollaborator}
        refresh={refresh}
      />
    </Box>
  )
}

ProfilesTable.propTypes = {
  profiles: PropTypes.array,
  isCollaborator: PropTypes.bool,
  refresh: PropTypes.func,
}

ProfilesTable.defaultProps = {
  isCollaborator: false,
  refresh: () => {},
}

export default ProfilesTable
