import Main from './ChurnsMain'
import Edit from './ChurnsEdit'
import Show from './ChurnsShow'

const Churns = {
  Main,
  Edit,
  Show,
}

export default Churns
