const percentage = (value) => {
  if (parseFloat(matchNumber(value)) >= 10000) {
    return '100.00'
  }

  const cleanNum = matchNumber(value)
  return cleanNum.match(/.{1,2}(?=(.{2})*$)/g)?.join('.')
}

const matchNumber = (number) => number.match(/\d+/g)?.join('') || ''

export default percentage
