import PropTypes from 'prop-types'

import { useState, useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { useFormContext, Controller } from 'react-hook-form'

import {
  Box,
  TextField,
  MenuItem,
  Typography,
  Checkbox,
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'

import helpers from 'helpers'
import { isEmpty } from 'lodash'

import { useDataProcess } from 'hooks'

const TableNecPropDataCollected = ({ dataCollecteds, editType }) => {
  const { control, errors, setValue } = useFormContext()

  const [checked, setChecked] = useState(false)

  const { setNecDataCollecteds } = useDataProcess()

  const handleChangeCollectedField = (value, collectedField) => {
    const indexMapped = dataCollecteds.map((dataCollected, index) => {
      if (dataCollected.collectedFields.indexOf(collectedField) !== -1) {
        return {
          dataCollectedIndex: index,
          collectedFieldIndex:
            dataCollected.collectedFields.indexOf(collectedField),
        }
      }
      return {}
    })

    const { dataCollectedIndex, collectedFieldIndex } = indexMapped.filter(
      (object) => !isEmpty(object),
    )[0]

    dataCollecteds[dataCollectedIndex].collectedFields[
      collectedFieldIndex
    ].necessary = helpers.formatters.convertBinaryToBool(value)

    setNecDataCollecteds(dataCollecteds)
  }

  useEffect(() => {
    dataCollecteds.map((dataCollected) => {
      dataCollected.collectedFields.map((collectedField) => {
        const collectedFieldName = `collectedField-${dataCollected.id}-${collectedField.id}`

        if (checked) {
          setValue(collectedFieldName, 1)
          return collectedField
        } else {
          setValue(
            collectedFieldName,
            helpers.formatters.convertBoolToBinary(collectedField.necessary),
          )
          return collectedField
        }
      })
      return dataCollected
    })
    //eslint-disable-next-line
  }, [checked])

  return (
    <>
      {!editType && (
        <Box
          mb={2}
          justifyContent="space-between"
          display="flex"
          alignItems="center"
        >
          <Box>
            <Typography variant="h6">Dados tratados</Typography>
          </Box>
          <Box padding="checkbox">
            <Box
              justifyContent="space-between"
              display="flex"
              alignItems="center"
            >
              <Box fontWeight="fontWeightBold">
                <Typography>
                  Deseja selecionar <u>todos</u> com SIM?
                </Typography>
              </Box>
              <Checkbox
                type="checkbox"
                onChange={() => {
                  setChecked(!checked)
                }}
              />
            </Box>
          </Box>
        </Box>
      )}

      <PerfectScrollbar>
        <Box width="100%">
          <Table
            size="small"
            emptyMessage="Nenhum dado tratado encontrado"
            noWrap
          >
            <TableHead>
              <TableRow>
                <TableCell width="20%">Dado</TableCell>
                <TableCell width="10%">Tipo</TableCell>
                <TableCell width="20%">Categoria do Titular</TableCell>
                <TableCell width="15%">Faixa Etária</TableCell>
                <TableCell width="10%">
                  Quantidade aproximada de titulares
                </TableCell>
                <TableCell width="25%">
                  Considera este dado necessário para o tratamento?
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataCollecteds?.map((dataCollected) => {
                return dataCollected.collectedFields.map((collectedField) => (
                  <TableRow key={collectedField.dataCollectedOption.id}>
                    <TableCell>
                      {collectedField?.dataCollectedOption?.name}
                    </TableCell>
                    <TableCell>
                      {helpers.dataCollected.dataTypeLabel(
                        collectedField?.dataCollectedOption?.dataType,
                      )}
                    </TableCell>
                    <TableCell>
                      {dataCollected?.titularCategory?.name}
                    </TableCell>
                    <TableCell>
                      {helpers.dataCollected.rangeAgeContent(
                        dataCollected?.titularAgeRange,
                      )}
                    </TableCell>
                    <TableCell>
                      {dataCollected?.holderNumber || 'Não definido'}
                    </TableCell>
                    <TableCell disableTooltip>
                      <Box my={1}>
                        <Controller
                          as={
                            <TextField
                              label="Necessário?"
                              select
                              color="primary"
                              variant="outlined"
                              error={
                                !!errors[
                                  `collectedField-${dataCollected.id}-${collectedField.id}`
                                ]
                              }
                              helperText={
                                errors &&
                                errors[
                                  `collectedField-${dataCollected.id}-${collectedField.id}`
                                ] &&
                                'Preencha este campo'
                              }
                              fullWidth
                            >
                              <MenuItem key="sim" value={1}>
                                Sim
                              </MenuItem>
                              <MenuItem key="não" value={0}>
                                Não
                              </MenuItem>
                            </TextField>
                          }
                          defaultValue={helpers.formatters.convertBoolToBinary(
                            collectedField.necessary,
                          )}
                          name={`collectedField-${dataCollected.id}-${collectedField.id}`}
                          rules={{ required: true }}
                          control={control}
                          onChange={([event]) => {
                            handleChangeCollectedField(
                              event.target.value,
                              collectedField,
                            )

                            return event.target.value
                          }}
                          mode="onChange"
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </>
  )
}

TableNecPropDataCollected.propTypes = {
  dataCollecteds: PropTypes.arrayOf(Object).isRequired,
  editType: PropTypes.bool,
}

TableNecPropDataCollected.defaultValue = {
  editType: false,
}

export default TableNecPropDataCollected
