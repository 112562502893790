import PropTypes from 'prop-types'

import { Permitted, ButtonLogs } from 'components'
import RemoveCoupon from '../RemoveCoupon'

import constants from 'constants/index'

const ActionButtons = ({ couponId }) => {
  return (
    <>
      <Permitted
        someTags={[
          constants.permissions.COUPONS.CREATE,
          constants.permissions.COUPONS.SHOW_LOGS,
        ]}
      >
        <ButtonLogs routes="discount_coupons" entityId={couponId} />
      </Permitted>
      <Permitted tag={constants.permissions.COUPONS.DESTROY}>
        <RemoveCoupon couponId={couponId} isEdit />
      </Permitted>
    </>
  )
}

ActionButtons.propTypes = {
  couponId: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
}

export default ActionButtons
