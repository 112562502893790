import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import PreRegistrationsTableRow from '../PreRegistrationsTableRow'

const PreRegistrationsTable = ({
  paymentContractId,
  preRegistrationPrincipalId,
  preRegistrations,
  refresh,
}) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: true, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhum registro encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="20%" align="left">
                Nome
              </TableCell>
              <TableCell width="15%" align="left">
                CNPJ/CPF
              </TableCell>
              <TableCell width="20%" align="left">
                Categoria
              </TableCell>
              <TableCell width="20%" align="left">
                Responsável
              </TableCell>
              <TableCell width="20%" align="left">
                Valor recomendado
              </TableCell>
              <TableCell width="15%" align="left">
                Status
              </TableCell>
              <TableCell width="5%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {preRegistrations?.map((preRegistration) => (
              <PreRegistrationsTableRow
                preRegistration={preRegistration}
                preRegistrationPrincipalId={preRegistrationPrincipalId}
                key={preRegistration?.id}
                paymentContractId={paymentContractId}
                refresh={refresh}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

PreRegistrationsTable.propTypes = {
  preRegistrations: PropTypes.object,
  refresh: PropTypes.func,
}

export default PreRegistrationsTable
