import { useLocation } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { Container, ContentHeader, Page } from 'components'
import { Form } from './components'

import { useFetch } from 'hooks'

import * as service from 'service'
import constants from 'constants/index'

const PreRegistrationsNew = () => {
  const location = useLocation()
  const typeDefault = location?.state?.type
  const isSupplier = location?.state?.isSupplier
  const companyId = location?.state?.companyId
  const typeClient = location?.state?.typeClient
  const parentContractId = location?.state?.parentContractId
  const createContract = location?.state?.createContract

  const { response, isLoading } = useFetch(
    service.dponet.companies.get,
    {
      companyId,
    },
    [],
    !!companyId,
  )
  const companySupplier = response?.data?.company
  const companySupplierIsJuridic = isSupplier && isEmpty(companySupplier?.cpf)

  const isClient = typeClient === constants.preRegistration.CLIENT_TYPE

  return (
    <Page title="Criar contratante">
      <Container maxWidth={false}>
        <ContentHeader
          menu={isClient ? 'Cliente' : 'Sublicenciamento'}
          title={`Criar ${isClient ? 'Cliente' : 'Sublicenciamento'}`}
          subtitle={`Criar ${isClient ? 'Cliente' : 'Sublicenciamento'}`}
        />
        {(!isLoading || !companyId) && (
          <Form
            typeDefault={typeDefault}
            isSupplier={isSupplier}
            companySupplier={companySupplier}
            typeClient={typeClient}
            companySupplierIsJuridic={companySupplierIsJuridic}
            parentContractId={parentContractId}
            createContract={createContract}
          />
        )}
      </Container>
    </Page>
  )
}

export default PreRegistrationsNew
