import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, TextField, Grid } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useForm, Controller } from 'react-hook-form'

import useSnackbar from 'hooks/useSnackbar'

import {
  Card,
  LoadingBox,
  ButtonComponent,
  Select as SelectComponent,
  CompanyTypeAndSeachInput,
} from 'components'

import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'
import schema from './schema'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

const PlansForm = ({ plan, isEdit }) => {
  const [loading, setLoading] = useState(false)
  const [companyId, setCompanyId] = useState(null)
  const [segments, setSegments] = useState([])

  useEffect(() => {
    const getSegments = async () => {
      const response = await service.dponet.segments.get({
        active: true,
        perPage: 100000,
      })
      setSegments(response?.data?.segments)
    }
    getSegments()
  }, [])

  const history = useHistory()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors, watch } = useForm({
    validationSchema: schema(),
    defaultValues: {
      segmentId: plan?.segment
        ? { id: plan?.segment?.id, name: plan?.segment?.name }
        : { id: 0, name: '' },
      name: plan?.name || '',
      implementationPrice: helpers.formatters.currencyInput(
        plan?.implementationPrice.toString() || '',
      ),
      code: plan?.code || '',
      price: helpers.formatters.currencyInput(plan?.price.toString() || ''),
      companyId: plan?.companyTemplate || companyId,
      implementationSplit: plan?.implementationSplit || 1,
      typeService:
        plan?.typeService || constants.paymentContract.DIGITAL_SALES_LABEL,
      vertical: plan?.vertical || constants.paymentContract.SERVICES_LABEL,
    },
  })

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      data.implementationPrice = helpers.formatters.regularizeCurrency(
        data.implementationPrice,
      )
      data.price = helpers.formatters.regularizeCurrency(data.price)
      data.templateCompanyId = data.companyId?.id
      data.segmentId = data?.segmentId?.id
      delete data.companyId

      if (isEdit) {
        await service.dponet.plans.edit({ planId: plan.id, plan: data })
      } else {
        await service.dponet.plans.create({ plan: data })
      }

      snackbar.open({
        message: `Segmento e preço ${
          isEdit ? 'editado' : 'criado'
        } com sucesso`,
        variant: 'success',
      })
      history.push(routes.plans.all)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
      setLoading(false)
    }
  }

  const handleReturn = () => history.push(routes.plans.all)

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card title="Plano">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="segmentId"
                  onChange={([, data]) => data}
                  mode="onChange"
                  as={
                    <Autocomplete
                      options={segments || []}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) =>
                        option.id === value.id || value.id === 0
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors?.segmentId}
                          helperText={
                            errors?.segmentId ? 'Selecione um segmento' : ''
                          }
                          label="Segmento"
                          variant="outlined"
                        />
                      )}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <CompanyTypeAndSeachInput
                  setCompanyId={setCompanyId}
                  control={control}
                  errors={errors}
                  kind={constants.companies.KINDS.TEMPLATE}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      label="Nome do plano"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="name"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  as={
                    <TextField
                      label="Código do plano"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="code"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  as={
                    <TextField
                      label="Mensalidade"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.price}
                      helperText={errors?.price?.message}
                      fullWidth
                    />
                  }
                  onChange={([event]) => {
                    return helpers.formatters.currencyInput(event.target.value)
                  }}
                  control={control}
                  name="price"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  as={
                    <TextField
                      label="Implementação"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.implementationPrice}
                      helperText={errors?.implementationPrice?.message}
                      fullWidth
                    />
                  }
                  onChange={([event]) => {
                    return helpers.formatters.currencyInput(event.target.value)
                  }}
                  control={control}
                  name="implementationPrice"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="implementationSplit"
                  mode="onBlur"
                  as={
                    <SelectComponent
                      label="Parcelas permitidas na implementação"
                      items={constants.plan.SPLITS}
                      error={!!errors.implementationSplit}
                      helperText={errors?.implementationSplit?.message}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="typeService"
                  mode="onBlur"
                  as={
                    <SelectComponent
                      label="Tipo de serviço"
                      items={constants.paymentContract.TYPE_SERVICES_OPTIONS}
                      error={!!errors?.typeService}
                      helperText={errors?.typeService?.message}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  control={control}
                  name="vertical"
                  mode="onBlur"
                  as={
                    <SelectComponent
                      label="Vertical"
                      items={
                        constants.paymentContract.VERTICAL_OPTIONS[
                          watch('typeService')
                        ]
                      }
                      error={!!errors?.vertical}
                      helperText={errors?.vertical?.message}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Card>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Box pr={2}>
              <ButtonComponent
                onClick={handleReturn}
                title="Voltar"
                variant="outlined"
                type="button"
              />
            </Box>
            <ButtonComponent title="Salvar" />
          </Box>
        </form>
      )}
    </>
  )
}

PlansForm.propTypes = {
  plan: PropTypes.object,
  isEdit: PropTypes.bool,
}

PlansForm.defaultProps = {
  isEdit: false,
}

export default PlansForm
