import autoTable from 'jspdf-autotable'
import palette from 'theme/palette'
import { WIDTH as WIDTHPdf, PADDING } from '../constants/index'

const table = (
  pdf,
  head,
  body,
  startY = 10,
  cellWidth = 0.65,
  isBold = false,
  margin = PADDING,
) => {
  const WIDTH = WIDTHPdf(pdf) - margin * 2

  autoTable(pdf, {
    head: head,
    body: body,
    startY: startY,
    tableWidth: WIDTH,
    margin: margin,
    columnStyles: {
      0: {
        font: 'Roboto-Bold',
        cellWidth: WIDTH * cellWidth,
        halign: 'left',
        fontStyle: 'bold',
        textColor: palette.BlueDownDark,
      },
    },
    headStyles: {
      textColor: palette.white,
      font: 'Roboto-Bold',
      fillColor: palette.BlueDownDark,
      cellPadding: 6,
    },
    styles: {
      cellWidth: 'auto',
      textColor: palette.BlueDownDark,
    },
    bodyStyles: {
      font: 'Roboto-Regular',
    },
    theme: 'grid',
  })
}

export default table
