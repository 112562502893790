import api from 'service/api'

const dponetAPI = api.create('dponet')

const create = async (data) => {
  return await dponetAPI.post('/integration_partner_plans', data)
}

const update = async (integrationPartnerPlanId, integrationPartnerPlan) => {
  return await dponetAPI.put(
    `/integration_partner_plans/${integrationPartnerPlanId}`,
    { integrationPartnerPlan },
  )
}

const destroy = async (integrationPartnerPlanId) => {
  return await dponetAPI.delete(
    `/integration_partner_plans/${integrationPartnerPlanId}`,
  )
}

const integrationPartnerPlans = {
  create,
  update,
  destroy,
}

export default integrationPartnerPlans
