import Main from './PlansMain'
import New from './PlansNew'
import Edit from './PlansEdit'

const Plans = {
  Main,
  New,
  Edit,
}

export default Plans
