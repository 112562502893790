import * as yup from 'yup'

const schema = yup.object().shape({
  originCompany: yup.object().required(),
  companies: yup.array().required(),
  sourceIds: yup.array().required(),
  sourceType: yup.string().required(),
})

export default schema
