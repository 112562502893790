import helpers from 'helpers'
import constants from 'constants/index'

const defaultValues = (
  isSupplier,
  companySupplier,
  preRegistration,
  companySupplierIsJuridic,
) => {
  const defaultFantasyName = () => {
    if (isSupplier) return companySupplier?.name
    return preRegistration?.fantasyName || preRegistration?.name || ''
  }

  const defaultPhone = () => {
    if (isSupplier) return helpers.formatters.phone(companySupplier?.phone)
    return preRegistration?.phone || ''
  }

  const defaultEmail = () => {
    if (isSupplier) return companySupplier?.email
    return preRegistration?.email || ''
  }

  const defaultResponsible = () => {
    if (isSupplier) return companySupplier?.responsableUser
    return preRegistration?.responsibleUser || ''
  }

  const defaultKind = () => {
    if (isSupplier && !companySupplierIsJuridic) {
      return constants.companies.KIND_PERSONAL
    }
    return preRegistration?.kind || constants.companies.KIND_COMPANY
  }

  return {
    defaultFantasyName,
    defaultPhone,
    defaultEmail,
    defaultResponsible,
    defaultKind,
  }
}

export default defaultValues
