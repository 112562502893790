import helpers from 'helpers'
import components from '../../components'

import { GAP, marginTable } from '../../constants'

import palette from 'theme/palette'

const timmingCommunication = async (pdf, date, { incident }) => {
  const incidentReporting = incident?.incidentReporting
  const communicationIncidentHolder = incident?.communicationIncidentHolder

  pdf.addPage('a4', 'p')

  components.subtitle(pdf, 'Tempestividade e Comunicação', GAP)

  components.lineWithDate(pdf, date)

  components.paragraph(
    pdf,
    'Sobre o Incidente',
    GAP * 1.5,
    palette.BlueDownDark,
    'Roboto-Bold',
  )

  const incidentData = [
    [
      'Quando ocorreu',
      helpers.formatters.date(incidentReporting?.occurred) || 'N/A',
    ],
    [
      'Quando tomou ciência',
      helpers.formatters.date(incidentReporting?.incidentAware) || 'N/A',
    ],
    [
      'Quando comunicou à ANPD',
      helpers.formatters.date(incidentReporting?.anpdNotificationDate) || 'N/A',
    ],
    [
      'Quando comunicou aos titulares',
      helpers.formatters.date(incidentReporting?.informedHolder) || 'N/A',
    ],
    [
      'Justifique, se cabível, a não realização da comunicação completa à ANPD e aos titulares de dados afetados no prazo sugerido de 2 (dois) dias úteis após a ciência do incidente',
      incidentReporting?.justificationNotNotifyingAnpd || 'N/A',
    ],
    [
      'Se cabível, informe quando e a quais outras autoridades o incidente foi comunicado:',
      incidentReporting?.informedOtherAuthority || 'N/A',
    ],
  ]

  components.table(pdf, [], incidentData, GAP * 1.6, 0.4)

  let tableHeight = pdf.previousAutoTable.finalY + 10

  components.paragraph(
    pdf,
    'Da Comunicação do Incidente aos Titulares dos Dados',
    tableHeight + marginTable,
    palette.BlueDownDark,
    'Roboto-Bold',
  )

  const controllerDataTable = [
    [
      'Os titulares dos dados afetados foram comunicados sobre o incidente?',
      helpers.incidents.holderAffectedInformedStatus(
        communicationIncidentHolder?.holderAffectedInformed,
      ) || 'N/A',
    ],
    [
      'Se cabível, quando os titulares serão comunicados sobre o incidente?',
      communicationIncidentHolder?.holderWillCommunicateIn || 'N/A',
    ],
    [
      'De que forma a ocorrência do incidente foi comunicada aos titulares?',
      incident?.dataOptionIncidents?.howWasIncidentReportedOwner
        ? incident?.dataOptionIncidents?.howWasIncidentReportedOwner
            .map((dataType) => dataType.name + '\n')
            .join('')
        : 'N/A',
    ],
    [
      'Descreva como ocorreu a comunicação:',
      communicationIncidentHolder?.holderCommunicationOccurred || 'N/A',
    ],
    [
      'Quantos titulares foram comunicados individualmente sobre o incidente??',
      communicationIncidentHolder?.holderCommunicatedIndividually || 'N/A',
    ],
    [
      'Justifique, se cabível, o que motivou a não realização da comunicação individual aos titulares:',
      communicationIncidentHolder?.holderNotCommunicatedIndividually || 'N/A',
    ],
    [
      'O comunicado aos titulares atendeu os requisitos acima?',
      helpers.formatters.convertBoolToString(
        communicationIncidentHolder?.metAboveRequirement,
      ) || 'N/A',
    ],
  ]

  components.table(
    pdf,
    [],
    controllerDataTable,
    tableHeight + marginTable + GAP * 0.1,
    0.4,
  )

  components.numberPage(pdf, palette.black, 2)
}

export default timmingCommunication
