import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  cardHeader: {
    padding: theme.spacing(1),
  },
  statusBox: {
    width: 34,
    height: 34,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  pending: {
    backgroundColor: theme.colors.dataProcess.backgroundStatuses.pending,
  },
  waitingReview: {
    backgroundColor: theme.colors.dataProcess.backgroundStatuses.waitingReview,
  },
  reviewDisapproved: {
    backgroundColor: theme.colors.dataProcess.backgroundStatuses.reproved,
  },
  reviewApproved: {
    backgroundColor: theme.colors.dataProcess.backgroundStatuses.approved,
  },
  reviewing: {
    backgroundColor: theme.colors.dataProcess.backgroundStatuses.reviewing,
  },
  inactive: {
    backgroundColor: theme.colors.dataProcess.backgroundStatuses.inactive,
  },
  autoReview: {
    backgroundColor: theme.colors.dataProcess.backgroundStatuses.autoReview,
  },
  cardHeaderStatus: {
    width: 'fit-content',
    border: `solid 1px ${theme.colors.dataProcess.borders.primary}`,
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    padding: theme.spacing(1),
  },
  cardPlayPause: {
    marginRight: 0,
  },
  boxProcessInfo: {
    border: `solid 1px ${theme.colors.dataProcess.borders.secondary}`,
    borderRadius: '4px',
  },
  boxProcessName: {
    backgroundColor: theme.colors.dataProcess.borders.secondary,
  },
  containerContent: {
    marginTop: -5,
  },
  cardIaMessage: {
    backgroundColor: theme.colors.dataProcess.buttons.primary,
    color: theme.palette.common.white,
  },
}))

export default styles
