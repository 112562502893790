import toConvertArrayIsEdit from './toConvertArrayIsEdit'
import toConvertIdForSegmentId from './toConvertIdForSegmentId'
import discountType from './discountType'
import discountValue from './discountValue'
import discountFormatter from './discountFormatter'
import discountFormatterToSubmit from './discountFormatterToSubmit'

const discontCoupon = {
  toConvertArrayIsEdit,
  toConvertIdForSegmentId,
  discountType,
  discountValue,
  discountFormatter,
  discountFormatterToSubmit,
}

export default discontCoupon
