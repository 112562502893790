import { isEmpty, isArray } from 'lodash'

const filterObjects = (value, list) => {
  const getObject = (itemId) => {
    if (isArray(itemId)) {
      return list?.filter(
        (object) => Number(object.id[0]) === Number(itemId[0]),
      )
    }
    return list?.filter((object) => Number(object.id) === Number(itemId))
  }

  const getObjects = (value) => {
    if (!isEmpty(value) && isArray(value)) {
      var response = ''
      var counter = value.length

      value?.map((item) => {
        if (counter === 1) {
          return !!getObject(item) && (response += getObject(item)[0]?.name)
        }

        counter -= 1
        return (
          !!getObject(item) && (response += getObject(item)[0]?.name + ', ')
        )
      })
    }

    return response
  }

  if (!isArray(value) && !isEmpty(getObject(value))) {
    return getObject(value)[0]?.name
  }

  if (!isEmpty(getObjects(value))) {
    return getObjects(value)
  }
}

export default filterObjects
