const ALL_TAB = 0
const ACTIVE_TAB = 1
const INACTIVE_TAB = 2

const ACTIVE = true
const INACTIVE = false

const TABS = [
  {
    value: ALL_TAB,
    label: 'Todos',
  },
  {
    value: ACTIVE_TAB,
    label: 'Ativos',
  },
  {
    value: INACTIVE_TAB,
    label: 'Inativos',
  },
]

const department = {
  TABS,
  ALL_TAB,
  ACTIVE_TAB,
  INACTIVE_TAB,
  INACTIVE,
  ACTIVE,
}

export default department
