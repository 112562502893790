import constants from 'constants/companies'

const blockUpdate = (status, setChecked, isEditChecked) => {
  if (status === constants.STATUSES.EVALUATION) {
    setChecked(true)
  } else {
    setChecked(isEditChecked)
  }
}

export default blockUpdate
