import { useState } from 'react'

import {
  Card,
  Container,
  ContentHeader,
  LoadingFeedback,
  Page,
} from 'components'
import { SubcategoryForm } from './components'

const TechnicalFaqsSubcategory = () => {
  const [loading, setLoading] = useState(false)

  return (
    <Page title="Nova Base de Conhecimento - FAQ">
      <LoadingFeedback open={loading} />
      <Container spacingXl={40} spacingLg={25} spacingMd={10} spacingSm={10}>
        <ContentHeader
          menu="Apoio Técnico"
          title="Nova"
          subtitle="Subcategoria"
        />
        <Card title="Adicionar nova subcategoria">
          <SubcategoryForm setLoading={setLoading} />
        </Card>
      </Container>
    </Page>
  )
}

export default TechnicalFaqsSubcategory
