import { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Divider, Paper, Typography } from '@material-ui/core'
import { Clipboard as ClipboardIcon } from 'react-feather'
import {
  DataCollecteds,
  DataTreatments,
  GreyChip,
  LifeCycles,
  TitleSection,
} from './components'
import { DialogReproveRevision } from 'views/DataProcesses/components'

import constants from 'constants/index'
import useStyles from './styles'

const DataProcessInfo = ({
  dataProcess,
  changeDataProcessStatus,
  setLoading,
  showDetails,
}) => {
  const classes = useStyles()
  const [dialogReproveRevision, setDialogReproveRevision] = useState(false)

  return (
    <Paper square className={classes.paper}>
      <Box m={1}>
        <Box display="flex" width="100%" className={classes.boxProcessInfo}>
          <Box
            width="50%"
            className={classes.boxProcessName}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={1.5}
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="h5">#{dataProcess?.id}</Typography>
              <Typography variant="h3">{dataProcess?.company?.name}</Typography>
            </Box>
            <ClipboardIcon size={27} />
          </Box>
          <Box
            p={2}
            width="50%"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="end"
          >
            <Typography variant="h5">
              {dataProcess?.company?.document || '-'}
            </Typography>
            <Typography variant="h5">
              Departamento - {dataProcess?.department?.name || '-'}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box m={showDetails ? 4 : 3} textAlign={showDetails ? 'left' : 'center'}>
        <Typography variant="h4">{dataProcess?.name}</Typography>
      </Box>
      {showDetails && (
        <>
          <Divider />
          <Box m={2}>
            <TitleSection title="Por que os dados são tratados?" />
            <GreyChip text={dataProcess?.finality} />
          </Box>
          <Divider />
          <DataCollecteds dataCollecteds={dataProcess?.dataCollecteds} />
          <Divider />
          <LifeCycles lifeCycles={dataProcess?.lifeCycles} />
          <Divider />
          <DataTreatments dataTreatments={dataProcess?.dataTreatments} />
          <Divider />
          {dataProcess?.statusId === constants.dataProcess.REVIEWING_STATUS && (
            <>
              <Box my={1.5} mx={2} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.buttonReprove}
                  onClick={() => setDialogReproveRevision(true)}
                >
                  Reprovar processo
                </Button>
              </Box>
              <DialogReproveRevision
                dataProcess={dataProcess}
                open={dialogReproveRevision}
                setOpen={setDialogReproveRevision}
                setLoading={setLoading}
                onEvent={() =>
                  changeDataProcessStatus(
                    constants.dataProcess.DISAPPROVED_STATUS,
                  )
                }
              />
            </>
          )}
        </>
      )}
    </Paper>
  )
}

DataProcessInfo.propTypes = {
  dataProcess: PropTypes.object,
  changeDataProcessStatus: PropTypes.func,
  setLoading: PropTypes.func,
  showDetails: PropTypes.bool,
}

DataProcessInfo.defaultProps = {
  dataProcess: {},
  changeDataProcessStatus: () => {},
  setLoading: () => {},
  showDetails: true,
}

export default DataProcessInfo
