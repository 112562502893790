import PropTypes from 'prop-types'

import { Box, Grid, Typography } from '@material-ui/core'
import { Card, Label, Permitted } from 'components'
import constants from 'constants/index'
import helpers from 'helpers'
import DemoPartnerMenuItems from './components/DemoPartnerMenuItems'

const DemoPartner = ({ refresh, demoPartner, companyId }) => {
  const demoFormatted = helpers.formatters.benefits(demoPartner)
  return (
    <Card
      title="Dados do benefício"
      actionButton={
        <Permitted tag={constants.permissions.COMPANIES.UPDATE_DEMO_PARTNER}>
          <DemoPartnerMenuItems
            refresh={refresh}
            companyId={companyId}
            editionMode={true}
            demoPartner={demoPartner}
          />
        </Permitted>
      }
    >
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Label titleVariant="body1" title="Formulário utilizado">
              <Typography variant="body2">{demoFormatted.partner}</Typography>
            </Label>
          </Grid>
          <Grid item xs={6}>
            <Label titleVariant="body1" title="Tipo">
              <Typography variant="body2">
                {demoFormatted.typePartner}
              </Typography>
            </Label>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Label titleVariant="body1" title="Data final do acesso gratuito">
              <Typography variant="body2">
                {helpers.formatters.date(demoPartner?.demoEndDate)}
              </Typography>
            </Label>
          </Grid>
          <Grid item xs={6}>
            <Label titleVariant="body1" title="mensalidade">
              <Typography variant="body2">{demoFormatted.price}</Typography>
            </Label>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

DemoPartner.propTypes = {
  demoPartner: PropTypes.object,
}

export default DemoPartner
