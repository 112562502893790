import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box } from '@material-ui/core'

import DepartmentTableRow from '../DepartmentTableRow'
import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'

const DepartmentTable = ({ departments, refresh }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhum departamento encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="30%" align="left">
                Nome
              </TableCell>
              <TableCell width="30%" align="left">
                Empresa
              </TableCell>
              <TableCell width="20%" align="left">
                Status
              </TableCell>
              <TableCell width="10%" align="center">
                Criado em
              </TableCell>
              <TableCell width="5%" align="left">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {departments.map((department) => (
              <DepartmentTableRow
                department={department}
                refresh={refresh}
                key={department.id}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

DepartmentTable.propTypes = {
  departments: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func,
}

DepartmentTable.defaultProps = {
  refresh: () => {},
}

export default DepartmentTable
