import PropTypes from 'prop-types'
import {
  File as FileIcon,
  Check as CheckIcon,
  Loader as LoaderIcon,
  Moon as MoonIcon,
} from 'react-feather'

import { InformativeCard } from 'components'
import palette from 'theme/palette'

const QuestionnaireInformativeCards = ({ questionnaire }) => {
  const questionInfoCards = [
    {
      backgoundIconColor: palette.secondary.dark,
      icon: <FileIcon color={palette.white} size="18px" />,
      label: 'TODAS AS QUESTÕES',
      value: questionnaire?.totalQuestions || 0,
    },
    {
      backgoundIconColor: palette.greenPantone,
      icon: <CheckIcon color={palette.white} size="18px" />,
      label: 'RESPONDIDAS',
      value: questionnaire?.answeredQuestions?.length || 0,
    },
    {
      backgoundIconColor: palette.azure,
      icon: <LoaderIcon color={palette.white} size="18px" />,
      label: 'PENDENTES',
      value: questionnaire?.pendingQuestions?.length || 0,
    },
    {
      backgoundIconColor: palette.spanishGray,
      icon: <MoonIcon color={palette.white} size="18px" />,
      label: 'INATIVAS',
      value: questionnaire?.inactiveQuestions?.length || 0,
    },
  ]

  return (
    <>
      {questionInfoCards.map((questionInfoCard) => (
        <InformativeCard
          key={questionInfoCard.label}
          backgoundIconColor={questionInfoCard.backgoundIconColor}
          icon={questionInfoCard.icon}
          label={questionInfoCard.label}
          value={questionInfoCard.value}
        />
      ))}
    </>
  )
}

QuestionnaireInformativeCards.propTypes = {
  questionnaire: PropTypes.object.isRequired,
}

export default QuestionnaireInformativeCards
