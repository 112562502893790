import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, TextField } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'

import {
  Select as SelectComponent,
  DialogLayout,
  LoadingBox,
  CompanyTypeAndSeachInput,
} from 'components'

import { useSnackbar } from 'hooks'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'
import schema from './schema'

const UserCompaniesForm = ({
  userId,
  setOpen,
  open,
  refresh,
  isEdit,
  currentUserCompany,
}) => {
  const [companyId, setCompanyId] = useState()
  const [profileTypeResponsible, setProfileTypeResponsible] = useState(false)
  const [profiles, setProfiles] = useState([])
  const [isLoadingProfile, setIsLoadingProfile] = useState(false)

  const snackbar = useSnackbar()
  const { control, handleSubmit, errors, setValue } = useForm({
    validationSchema: schema(isEdit, profileTypeResponsible),
    defaultValues: {
      companyId: currentUserCompany?.company || null,
      profileId: currentUserCompany?.profile?.id || '',
      isResponsable: currentUserCompany?.responsable || '',
      phone: helpers.formatters.phoneDynamicMask(
        currentUserCompany?.user?.phone || '',
      ),
    },
  })

  useEffect(() => {
    const loadProfiles = async () => {
      if (!companyId && !currentUserCompany?.id) return

      setIsLoadingProfile(true)

      const response = await service.dponet.userProfiles.get({
        perPage: 100000,
        companyId: companyId || currentUserCompany?.company?.id,
        listDefaults: true,
        status: true,
      })

      setProfiles(response?.data?.profiles)
      setIsLoadingProfile(false)
    }

    loadProfiles()
  }, [companyId, currentUserCompany])

  const onSubmit = async (data) => {
    try {
      if (isEdit) {
        await service.dponet.userCompanies.put({
          id: currentUserCompany?.id,
          profileId: data.profileId,
        })
      } else {
        await service.dponet.userCompanies.vinculateUserCompany({
          userId,
          companyId: data.companyId.id,
          userCompany: {
            profileId: data.profileId,
            isResponsable: data.isResponsable,
          },
        })
      }
      snackbar.open({
        message: isEdit
          ? `Perfil do usuário na empresa alterado!`
          : `Usuário vinculado a empresa ${data.companyId.name}`,
        variant: 'success',
      })
      refresh()
      if (!isEdit) {
        setOpen(false)
      }
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
  }

  const handleChangeCompany = () => {
    setValue('profileId', '')
  }

  return (
    <>
      <form>
        <DialogLayout
          title={
            isEdit
              ? 'Editar vínculo de empresa e usuário'
              : 'Vincular empresa ao usuário'
          }
          open={open}
          setOpen={setOpen}
          confirmTextButton="Salvar"
          handleSubmit={handleSubmit(onSubmit)}
        >
          <Box width="500px">
            {!isEdit && (
              <Box pb={2}>
                <CompanyTypeAndSeachInput
                  setCompanyId={setCompanyId}
                  handleChangeCompany={handleChangeCompany}
                  control={control}
                  errors={errors}
                />
              </Box>
            )}
            <Box pb={1}>
              {!isLoadingProfile ? (
                <Controller
                  control={control}
                  name="profileId"
                  mode="onChange"
                  onChange={([event]) => {
                    setProfileTypeResponsible(
                      event.target.value ===
                        constants.profile.PROFILE_ID_RESPONSIBLE,
                    )
                    return event.target.value
                  }}
                  as={
                    <SelectComponent
                      disabled={!companyId && !isEdit}
                      label="Perfil de usuário"
                      items={profiles || []}
                      error={!!errors.profileId}
                      loading={isLoadingProfile}
                      helperText={errors?.profileId?.message}
                    />
                  }
                />
              ) : (
                <LoadingBox />
              )}
            </Box>
            {!isEdit && (
              <Box>
                <Controller
                  control={control}
                  name="isResponsable"
                  mode="onBlur"
                  as={
                    <SelectComponent
                      label="O usuário é o representante da empresa?"
                      items={[
                        { id: 'true', name: 'Sim' },
                        { id: 'false', name: 'Não' },
                      ]}
                      error={!!errors.isResponsable}
                      helperText={errors?.isResponsable?.message}
                    />
                  }
                />
              </Box>
            )}
            {profileTypeResponsible && (
              <Box py={1}>
                <Controller
                  as={
                    <TextField
                      label="Telefone do usuário"
                      type="text"
                      color="primary"
                      variant="outlined"
                      placeholder="(99) 9999-9999"
                      error={!!errors.phone}
                      helperText={errors?.phone?.message}
                      fullWidth
                    />
                  }
                  onChange={([event]) => {
                    return helpers.formatters.phoneDynamicMask(
                      event.target.value,
                    )
                  }}
                  control={control}
                  name="phone"
                  mode="onBlur"
                />
              </Box>
            )}
          </Box>
        </DialogLayout>
      </form>
    </>
  )
}

UserCompaniesForm.propTypes = {
  userId: PropTypes.number,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
  isEdit: PropTypes.bool,
  currentUserCompany: PropTypes.object,
}

UserCompaniesForm.defaultProps = {
  refresh: () => {},
  isEdit: false,
}

export default UserCompaniesForm
