import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { reverse } from 'named-urls'
import { useHistory } from 'react-router-dom'

import { TableRow, TableCell } from 'components/Table'

import { routes } from 'Routes'
import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from './styles'

const PaymentContractRow = ({ paymentContract, parentId }) => {
  const history = useHistory()
  const classes = useStyles()

  const handleShowContract = () => {
    history.push(
      reverse(routes.contracts.show, {
        contractId: paymentContract?.id,
      }),
    )
  }

  return (
    <>
      <TableRow key={paymentContract?.id}>
        <TableCell>#{paymentContract?.id}</TableCell>
        <TableCell pointer disableTooltip>
          <Typography
            variant="body2"
            onClick={handleShowContract}
            className={classes.link}
          >
            {paymentContract?.contractIdentifier}
          </Typography>
        </TableCell>
        <TableCell>
          {paymentContract?.id === parentId ? 'Não' : 'Sim'}
        </TableCell>
        <TableCell>
          {
            constants.preRegistration.KIND_PAYMENT_FEATURES[
              paymentContract?.kind
            ]
          }
        </TableCell>
        <TableCell>
          R$ {helpers.formatters.currencyInput(paymentContract?.price)}
        </TableCell>
        <TableCell>
          {helpers.formatters.date(paymentContract?.nextDateAdvance) || '-'}
        </TableCell>
        <TableCell>
          {helpers.formatters.date(paymentContract?.nextBillingDate) || '-'}
        </TableCell>
      </TableRow>
    </>
  )
}

PaymentContractRow.propTypes = {
  paymentContract: PropTypes.object,
}

export default PaymentContractRow
