import PropTypes from 'prop-types'
import * as service from 'service'
import { Typography, Box } from '@material-ui/core'
import DOMPurify from 'dompurify'

import {
  Page,
  Container,
  ContentHeader,
  LoadingFeedback,
  Card,
} from 'components'
import PrivacyPoliciesInfoCards from './components/PrivacyPoliciesInfoCards'

import useFetch from 'hooks/useFetch'

import useStyles from './styles'
import helpers from 'helpers'

const PrivacyPoliciesShow = ({ match }) => {
  const classes = useStyles()

  const { privacyPolicyId } = match.params
  const { response, isLoading } = useFetch(
    service.dponet.privacyPolicies.show,
    {
      privacyPolicyId,
    },
  )

  const privacyPolicy = response?.data?.privacyPolicy
  const sanitizedPrivacyPolicy = DOMPurify.sanitize(privacyPolicy?.content)

  return (
    <Page title="Política de privacidade">
      <LoadingFeedback open={isLoading} />
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Políticas de privacidade"
          title="Visualizar política de privacidade"
          subtitle="Política de privacidade"
        />
        <Box mb={5}>
          <PrivacyPoliciesInfoCards privacyPolicy={privacyPolicy} />
        </Box>
        <Box mb={3}>
          <Card title="Informações gerais">
            <Typography variant="body1" className={classes.title}>
              Título
            </Typography>
            <Box mt={1} mb={2}>
              <Typography variant="body1">{privacyPolicy?.title}</Typography>
            </Box>
            <Typography variant="body1" className={classes.title}>
              Data de atualização
            </Typography>
            <Box mt={1} mb={1}>
              <Typography variant="body1">
                {helpers.formatters.date(privacyPolicy?.updatedAt)}
              </Typography>
            </Box>
          </Card>
        </Box>
        <Card title="Descrição da Política de Privacidade">
          <Box ml={2}>
            <Typography
              dangerouslySetInnerHTML={{ __html: sanitizedPrivacyPolicy }}
              variant="body1"
            />
          </Box>
        </Card>
      </Container>
    </Page>
  )
}

PrivacyPoliciesShow.propTypes = {
  match: PropTypes.object,
}

export default PrivacyPoliciesShow
