import PropTypes from 'prop-types'
import { Grid, Card, Box, Tooltip, Typography } from '@material-ui/core'

import useStyles from './styles'

const InformativeCard = ({
  handleClick,
  backgoundIconColor,
  icon,
  label,
  value,
}) => {
  const classes = useStyles(backgoundIconColor)()

  return (
    <Grid item lg={3} sm={6} xs={12}>
      <Card>
        <Box
          display="flex"
          alignItems="center"
          width="100%"
          onClick={handleClick}
          className={classes.card}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.iconBox}
          >
            {icon}
          </Box>

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box display="flex" flexDirection="column" width="100%">
              <Tooltip title={label} placement="bottom-start">
                <Typography
                  color="textSecondary"
                  className={classes.titleTypography}
                  noWrap
                >
                  {label}
                </Typography>
              </Tooltip>
              <Typography
                color="textPrimary"
                className={classes.valueTypography}
              >
                {value}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    </Grid>
  )
}

InformativeCard.propTypes = {
  handleClick: PropTypes.func,
  backgoundIconColor: PropTypes.string,
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.number,
}

InformativeCard.defaultProps = {
  handleClick: () => {},
  backgoundIconColor: 'transparent',
}

export default InformativeCard
