import { BasePermissionsForm } from '../index'

import { Box, Typography } from '@material-ui/core'

import constants from 'constants/index'
import PropTypes from 'prop-types'

const CompanySegmentationForm = ({
  register,
  permissions,
  handleChange,
  activeProfile,
  isEdit,
}) => {
  const collaboratorProfileLists =
    constants.permissionsLists.collaboratorProfileLists

  return (
    <>
      <Box mt={2} mb={3}>
        <Typography variant="h5">Segmentação de Empresas</Typography>
      </Box>
      <BasePermissionsForm
        title="Grupos"
        permissionsList={collaboratorProfileLists.groupList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Subgrupos"
        permissionsList={collaboratorProfileLists.subgroupList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Segmentos"
        permissionsList={collaboratorProfileLists.segmentList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Atividades Econômicas"
        permissionsList={collaboratorProfileLists.economicActivityList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Tamanho da Empresa"
        permissionsList={collaboratorProfileLists.companySizeList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
    </>
  )
}

CompanySegmentationForm.propTypes = {
  register: PropTypes.func,
  permissions: PropTypes.object,
  handleChange: PropTypes.func,
  activeProfile: PropTypes.bool,
  isEdit: PropTypes.bool,
}

export default CompanySegmentationForm
