import PropTypes from 'prop-types'

import { Box, Typography, makeStyles } from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const NoData = ({ message }) => {
  const classes = useStyles()

  return (
    <Box display="grid" justifyContent="center" width="100%" mt={4}>
      <Typography variant="h4" color="secondary" align="center">
        {message}
      </Typography>
      <Box mt={3}>
        <img
          alt="Nenhum dado encontrado"
          className={classes.image}
          src="/images/no_data.svg"
        />
      </Box>
    </Box>
  )
}

NoData.propTypes = {
  message: PropTypes.string.isRequired,
}

export default NoData
