import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${colors.grey[300]}`,
    borderRadius: '4px',
    paddingBottom: '0px',
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(0),
  },
}))

export default styles
