import constants from 'constants/platformContract'

const chipStatusLabel = (status) => {
  switch (status) {
    case constants.GENERATING_STATUS:
      return constants.GENERATING_STATUS_LABEL
    case constants.AWAITING_PAYMENT_ORDER_STATUS:
      return constants.AWAITING_PAYMENT_STATUS_LABEL
    case constants.FAILED_STATUS:
      return constants.FAILED_STATUS_LABEL
    case constants.AWAITING_SELLER_STATUS:
      return constants.AWAITING_SELLER_LABEL
    case constants.AWAITING_CLIENT_STATUS:
      return constants.AWAITING_CLIENT_LABEL
    case constants.AWAITING_CEO_STATUS:
      return constants.AWAITING_CEO_LABEL
    case constants.AWAITING_FINANCIAL_STATUS:
      return constants.AWAITING_FINANCIAL_LABEL
    case constants.FINALIZED_STATUS:
      return constants.FINALIZED_STATUS_LABEL
    case constants.CANCELED_STATUS:
      return constants.CANCELED_LABEL
    default:
      return null
  }
}

export default chipStatusLabel
