import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ lastUpdateId = '', ...params }) => {
  return await dponetAPI.get(`/last_updates/${lastUpdateId}`, { params })
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('/last_updates', data)
}

const put = async ({ lastUpdateId = '', ...data }) => {
  return await dponetAPI.put(`/last_updates/${lastUpdateId}`, data)
}

const destroy = async ({ lastUpdateId = '' }) => {
  return await dponetAPI.delete(`/last_updates/${lastUpdateId}`)
}

const lastUpdates = {
  get,
  create,
  put,
  destroy,
}

export default lastUpdates
