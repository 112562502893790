import PropTypes from 'prop-types'
import { Page, Container, ContentHeader } from 'components'

import { useFetch } from 'hooks'
import * as service from 'service'

import { DocumentationLinksForm } from './components'

const DocumentationLinksEdit = ({ match }) => {
  const {
    params: { documentationLinkId },
  } = match

  const { response, isLoading } = useFetch(
    service.dponet.documentationLinks.show,
    {
      documentationLinkId,
    },
    [],
  )

  return (
    <Page title="Edição de link de ajuda">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Edição"
          menu="Grupo"
          subtitle="Edição de link de ajuda"
        />
        {!isLoading && (
          <DocumentationLinksForm
            isEdit
            documentationLink={response?.data?.documentationLink}
          />
        )}
      </Container>
    </Page>
  )
}

DocumentationLinksEdit.propTypes = {
  match: PropTypes.object,
}

export default DocumentationLinksEdit
