/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import Filters from 'components/Filters'

import helpers from 'helpers'

const IncidentFilter = ({ filter }) => {
  const [companies, setCompanies] = useState([])
  const [companyInputValue, setCompanyInputValue] = useState('')
  const [isLoadingCompaniesInput, setIsLoadingCompaniesInput] = useState(false)
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false)
  const [originCompanies, setOriginCompanies] = useState([])
  const [originCompaniesInputValue, setOriginCompaniesInputValue] = useState('')
  const [isLoadingOriginCompaniesInput, setIsLoadingOriginCompaniesInput] =
    useState(false)
  const [isLoadingOriginCompanies, setIsLoadingOriginCompanies] =
    useState(false)

  const {
    loadCompanies,
    mountCompanyParams,
    mountOriginCompaniesParams,
    instructionsText,
  } = helpers.companies.typeAndSearch
  const { nameWithDocumentByArrayObject } = helpers.companies.nameWithDocument

  const companiesOptionsValues = nameWithDocumentByArrayObject(
    isLoadingCompaniesInput ? [] : companies,
  )

  const handleTypeOriginCompaniesInput = (e) => {
    setOriginCompaniesInputValue(e.target.value)
  }

  const handleTypeCompaniesInput = (e) => {
    setCompanyInputValue(e.target.value)
  }

  useEffect(() => {
    const getCompanies = setTimeout(() => {
      const hasCompanyValueToFetch =
        companyInputValue.length < 3 && isEmpty(filter.filters?.companyId)

      if (hasCompanyValueToFetch || !filter.drawerOpen) return

      loadCompanies(
        mountCompanyParams(companyInputValue, filter.filters),
        setIsLoadingCompaniesInput,
        setCompanies,
      )
    }, 1000)

    return () => clearTimeout(getCompanies)
  }, [companyInputValue])

  useEffect(() => {
    const getTemplateCompanies = setTimeout(() => {
      const hasOriginCompaniesValueToFetch =
        originCompaniesInputValue.length < 3 &&
        isEmpty(filter.filters?.originCompanies)

      if (hasOriginCompaniesValueToFetch || !filter.drawerOpen) return

      loadCompanies(
        mountOriginCompaniesParams(originCompaniesInputValue, filter.filters),
        setIsLoadingOriginCompaniesInput,
        setOriginCompanies,
      )
    }, 1000)

    return () => clearTimeout(getTemplateCompanies)
  }, [originCompaniesInputValue, filter.filters?.originCompanies])

  useEffect(() => {
    if (filter.drawerOpen) {
      if (filter.filters?.companyId) {
        loadCompanies(
          mountCompanyParams(companyInputValue, filter.filters),
          setIsLoadingCompanies,
          setCompanies,
        )
      }

      if (filter.filters?.originCompanies) {
        loadCompanies(
          mountOriginCompaniesParams(originCompaniesInputValue, filter.filters),
          setIsLoadingOriginCompanies,
          setOriginCompanies,
        )
      }
    }
  }, [filter.drawerOpen])

  useEffect(() => {
    if (!filter.drawerOpen) {
      if (originCompaniesInputValue) setOriginCompaniesInputValue('')
      if (companyInputValue) setCompanyInputValue('')
    }
  }, [filter.drawerOpen])

  return (
    <Filters
      filter={filter}
      isLoading={isLoadingCompanies || isLoadingOriginCompanies}
    >
      <input textfieldinput="true" label="Identificador" name="id" />
      <select
        autocompleteselectinput="true"
        label="Empresa"
        name="companyId"
        loading={isLoadingCompaniesInput}
        onChangeTypeSearch={handleTypeCompaniesInput}
        optionvalues={companiesOptionsValues}
        noOptionsText={instructionsText(companyInputValue)}
      />
      <select
        multipleselectinput="true"
        label="Template de origem"
        name="originCompanies"
        loading={isLoadingOriginCompaniesInput}
        onChangeTypeSearch={handleTypeOriginCompaniesInput}
        optionvalues={originCompanies}
        noOptionsText={instructionsText(originCompaniesInputValue)}
      />
      <input
        datepickerinput="true"
        label="Data do incidente"
        name="incidentDate"
      />
      <input
        datepickerinput="true"
        label="Data de criação incial"
        name="createdAtGt"
        data-target="createdAtLt"
        data-min
      />
      <input
        datepickerinput="true"
        label="Data de criação final"
        name="createdAtLt"
        data-target="createdAtGt"
        data-max
      />
    </Filters>
  )
}

export default IncidentFilter
