import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  fullHeight: {
    height: '100%',
    minHeight: '100%',
  },
}))

export default styles
