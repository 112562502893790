import PropTypes from 'prop-types'

import { Box, Typography, Grid, makeStyles } from '@material-ui/core'

import { TableDocuments } from '../../TableDocuments'

import useDataProcess from 'hooks/useDataProcess'

import styles from './styles'

import { TableDataCollecteds } from '../../TableDataCollecteds'
import { LoadingBox } from 'components'

const useStyles = makeStyles(styles)

const DataCollected = ({ dataCollecteds, isLoading, dataProcessId }) => {
  const classes = useStyles()
  const { loadRelationOptions, companyId } = useDataProcess()

  const [relationOptionsResponse] = loadRelationOptions(companyId)

  const options = relationOptionsResponse.data?.options
  const dataCollectedOptions = options?.dataCollectedOptions

  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <>
          <Box mt={3} mb={2}>
            <Typography variant="h5">Dados Tratados</Typography>
          </Box>
          <Grid container className={classes.dataCollectedContainer}>
            <Grid className={classes.dataCollectedTable} item xs={12}>
              <TableDataCollecteds
                dataCollecteds={dataCollecteds}
                dataProcessId={dataProcessId}
                classes={classes}
                dataCollectedOptions={dataCollectedOptions?.data}
              />
            </Grid>
          </Grid>
          <Box mt={3} mb={2}>
            <Typography variant="h5">Documentos Anexados</Typography>
          </Box>
          <TableDocuments classes={classes} dataProcessId={dataProcessId} />
        </>
      )}
    </>
  )
}

DataCollected.propTypes = {
  dataCollecteds: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  dataProcessId: PropTypes.number.isRequired,
}

export default DataCollected
