import PropTypes from 'prop-types'

import { Grid, Box, Typography } from '@material-ui/core'

import { TypeAndValue, CouponUses } from './components'

const DiscountCoupon = ({ formMethods }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Box mb={2}>
          <Typography variant="h4">Desconto & Destinatário</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TypeAndValue formMethods={formMethods} />
      </Grid>
      <Grid item xs={12}>
        <CouponUses formMethods={formMethods} />
      </Grid>
    </Grid>
  )
}

DiscountCoupon.propTypes = {
  formMethods: PropTypes.object.isRequired,
}

export default DiscountCoupon
