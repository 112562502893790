import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid, Typography } from '@material-ui/core'

import { LoadingFeedback } from 'components'

import helpers from 'helpers'

const ListInviteAdvisor = ({
  setDisabled,
  invite,
  loading,
  inviteTypeText,
}) => {
  useEffect(() => {
    setDisabled(true)
    //eslint-disable-next-line
  }, [invite])

  return (
    <>
      <LoadingFeedback open={loading} />
      {invite ? (
        <Grid container spacing={3}>
          {invite?.name && (
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" color="textSecondary">
                Nome
              </Typography>
              <Typography color="textPrimary" variant="body2">
                {invite?.name}
              </Typography>
            </Grid>
          )}
          {invite?.fantasyDpo && (
            <Grid item xs={12} md={6}>
              <Typography variant="subtitle2" color="textSecondary">
                DPO Fantasia
              </Typography>
              <Typography color="textPrimary" variant="body2">
                {invite?.fantasyDpo}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="textSecondary">
              Email
            </Typography>
            <Typography color="textPrimary" variant="body2">
              {invite?.email}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" color="textSecondary">
              Status
            </Typography>
            <Typography color="textPrimary" variant="body2">
              {helpers.inviteAdvisor.statusLabel(invite?.status)}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Box m={3}>
          <Typography color="textPrimary" variant="body1">
            Não existe nenhum {inviteTypeText} vinculado à empresa.
          </Typography>
        </Box>
      )}
    </>
  )
}

ListInviteAdvisor.propTypes = {
  setDisabled: PropTypes.func,
  invite: PropTypes.object,
  loading: PropTypes.bool,
  inviteTypeText: PropTypes.string,
}

ListInviteAdvisor.defaultProps = {
  setDisabled: () => {},
  invite: {},
}

export default ListInviteAdvisor
