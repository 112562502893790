import { Container, ContentHeader, Page } from 'components'
import { FormLastUpdate } from './components'

const LastUpdatesNew = () => {
  return (
    <Page title="Nova atualização">
      <Container spacingXl={40} spacingLg={25} spacingMd={10} spacingSm={10}>
        <ContentHeader
          menu="Atualizações"
          title="Últimas Atualizações"
          subtitle="Últimas Atualizações"
        />
        <FormLastUpdate editionPermitted />
      </Container>
    </Page>
  )
}

export default LastUpdatesNew
