import palette from 'theme/palette'

const MuiFormLabel = {
  root: {
    color: palette.text.primary,
    fontSize: '12px',
    fontWeight: 400,
    '&$focused': {
      fontSize: '12px',
      color: palette.secondary.dark,
    },
  },
}

export default MuiFormLabel
