import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  chipActive: {
    backgroundColor: colors.green[800],
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipOnboarding: {
    backgroundColor: colors.blue[800],
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipEvaluation: {
    backgroundColor: colors.lightBlue[800],
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipMaintenance: {
    backgroundColor: colors.yellow[800],
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipSuspended: {
    backgroundColor: colors.red[800],
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipInactive: {
    backgroundColor: colors.grey[800],
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipTrial: {
    backgroundColor: colors.pink[900],
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipTrialCanceled: {
    backgroundColor: theme.palette.black,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipTest: {
    backgroundColor: colors.orange[500],
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
}))

export default styles
