import Main from './CompanySizesMain'
import New from './CompanySizesNew'
import Edit from './CompanySizesEdit'

const CompanySizes = {
  Main,
  New,
  Edit,
}

export default CompanySizes
