import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { Plus as PlusIcon } from 'react-feather'
import { useHistory } from 'react-router-dom'

import {
  Container,
  ContentHeader,
  FilterButton,
  LoadingFeedback,
  Page,
  Filters,
  Permitted,
} from 'components'
import { SalesTable } from './components'

import { useFetch, useFilter, usePagination } from 'hooks'

import constants from 'constants/index'
import * as service from 'service'
import { routes } from 'Routes'

const Main = () => {
  const filter = useFilter()
  const history = useHistory()

  const theme = useTheme()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading } = useFetch(
    service.dponet.sellers.index,
    {
      ...{ ...filter.filters },
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  return (
    <Page title="Vendedor & Parceiro">
      <LoadingFeedback open={isLoading} />
      <Container maxWidth={false}>
        <ContentHeader
          title="Listagem"
          menu="Vendedor & Parceiro"
          subtitle="Vendedor & Parceiro"
        >
          <Box ml={1}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
        </ContentHeader>
        <Card>
          <Box width="100%">
            <Grid container>
              <Grid item xs={6}>
                <Box
                  width="100%"
                  p={2}
                  height="100%"
                  display="flex"
                  alignItems="center"
                >
                  <Typography variant="h5">Vendedor & Parceiro</Typography>
                </Box>
              </Grid>
              <Permitted tag={constants.permissions.SELLERS.CREATE}>
                <Grid item xs={6}>
                  <Box p={2} display="flex" justifyContent="flex-end">
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<PlusIcon />}
                      onClick={() => history.push(routes.sellers.new)}
                    >
                      Criar
                    </Button>
                  </Box>
                </Grid>
              </Permitted>
            </Grid>
            <Divider />
          </Box>
          <SalesTable sales={response?.data?.sellers ?? []} />
          <Box px={2} display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Card>
      </Container>
      <Filters filter={filter}>
        <input textfieldinput="true" label="Identificador" name="id" />
        <input textfieldinput="true" label="Nome" name="name" />
        <input textfieldinput="true" label="E-mail" name="email" />
        <select textfieldinput="true" label="Tipo" name="kindIn">
          <option value=""></option>
          {constants.seller.OPTIONS.map((type) => (
            <option key={type?.id} value={type?.id}>
              {type?.name}
            </option>
          ))}
        </select>
      </Filters>
    </Page>
  )
}

export default Main
