import { Page, Container, ContentHeader } from 'components'
import { Form } from './components'

const New = () => {
  return (
    <Page title="Novo Vendedor & Parceiro">
      <Container maxWidth={false}>
        <ContentHeader
          title="Novo"
          menu="Vendedor & Parceiro"
          subtitle="Vendedor & Parceiro"
        />
        <Form />
      </Container>
    </Page>
  )
}
export default New
