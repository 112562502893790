import * as yup from 'yup'

const schema = yup.object().shape({
  reasonExclusion: yup.string().required(),
  anotherReasonExclusion: yup.string().when('reasonExclusion', {
    is: 'another',
    then: yup.string().required(),
  }),
})

export default schema
