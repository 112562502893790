import { useState } from 'react'

import PropTypes from 'prop-types'

import { BaseDialog } from 'components'

import { Typography, Box, TextField, CircularProgress } from '@material-ui/core'

import { useAuth, useSnackbar } from 'hooks'

import { Controller, useForm } from 'react-hook-form'

import * as service from 'service'

import helpers from 'helpers'

import schema from './schema'

const ExportDataProcessesDialog = ({ open, setOpen, companyId }) => {
  const { user } = useAuth()

  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: {
      receiverEmail: user.email || '',
    },
  })

  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()

  const handleExport = async (data) => {
    setLoading(true)

    try {
      await service.dponet.companies.exportDataProcesses({
        companyId,
        receiverEmail: data.receiverEmail,
      })
      snackbar.open({
        message: 'Processamento de dados reprovado com sucesso!',
        variant: 'success',
      })
      setOpen(false)
      setLoading(false)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
      setOpen(false)
      setLoading(false)
    }
  }

  return (
    <>
      <BaseDialog
        open={open}
        setOpen={setOpen}
        title="Exportar processamentos de dados"
        closeButtonText="cancelar"
        actionButtonText="Exportar"
        formRef="id-form-receiver-email"
        noMargin
      >
        <Typography>
          Tem certeza que deseja exportar um relatório de processos da empresa?
        </Typography>
        {loading ? (
          <Box
            mt={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box mt={2}>
            <form
              onSubmit={handleSubmit(handleExport)}
              id="id-form-receiver-email"
            >
              <Controller
                as={
                  <TextField
                    label="Email"
                    color="primary"
                    variant="outlined"
                    error={!!errors.receiverEmail}
                    helperText={errors.receiverEmail?.message}
                  />
                }
                fullWidth
                name="receiverEmail"
                control={control}
              />
            </form>
          </Box>
        )}
      </BaseDialog>
    </>
  )
}

ExportDataProcessesDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  companyId: PropTypes.number.isRequired,
}

ExportDataProcessesDialog.defaultProps = {
  setOpen: () => {},
}

export default ExportDataProcessesDialog
