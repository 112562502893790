import { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  TextField,
  Grid,
  Switch,
  FormControlLabel,
  Typography,
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'

import { Card, BackButton, Select as SelectComponent, Label } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import helpers from 'helpers'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'
import useStyles from './styles'
import schema from './schema'

const DocumentationLinksForm = ({ documentationLink, isEdit }) => {
  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()
  const checkedEdit = isEdit ? documentationLink?.status : true
  const [checked, setChecked] = useState(checkedEdit)
  const labelChecked = checked ? 'Ativo' : 'Inativo'

  const routesArray = []
  Object.values(routes).forEach((itens) => {
    if (typeof itens === 'string') {
      routesArray.push(itens)
    } else {
      routesArray.push(
        ...Object.values(itens).filter((item) => item?.name !== 'toString'),
      )
    }
  })

  const { control, handleSubmit, errors, register, reset } = useForm({
    validationSchema: schema(),
    defaultValues: {
      url: documentationLink?.url,
      pageUri: documentationLink?.pageUri,
      status: documentationLink?.status,
    },
  })

  const onSubmit = async (data) => {
    try {
      if (isEdit) {
        await service.dponet.documentationLinks.update({
          documentationLinkId: documentationLink?.id,
          documentationLink: data,
        })
      } else {
        await service.dponet.documentationLinks.create({
          documentationLink: data,
        })
      }

      snackbar.open({
        message: `Links de ajuda ${
          isEdit ? 'atualizado' : 'criado'
        } com sucesso`,
        variant: 'success',
      })

      history.push(routes.documentationLinks.all)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
      reset(data)
    }
  }

  return (
    <>
      <Box className={classes.infoBox} p={2} mb={2}>
        <Box mb={2}>
          <Typography>Página em que o link aparecerá</Typography>
        </Box>
        <Box mb={2}>
          <Typography>
            Os links de ajuda cadastrados serão exibidos no portal do cliente.
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography>
            Para incluir uma nova documentação, preencha o "Página onde o link
            aparecerá" com a URI da página desejada.
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography>Ex:</Typography>
        </Box>
        <Box mb={2}>
          <Typography>Listagem de processos</Typography>
        </Box>
        <Box mb={2}>
          <Typography>
            URL: link de redirecionamento
            https://dponet.sandbox.immunizesystem.com/data-processes
          </Typography>
          <Typography>
            URI: select com rotas disponíveis /data-processes
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography>Visualizar processos</Typography>
        </Box>
        <Box mb={2}>
          <Typography>
            URL: link de redirecionamento
            https://dponet.sandbox.immunizesystem.com/data-processes/show/4000
          </Typography>
          <Typography>
            URI: select com rotas disponíveis /data-processes/show/:id
          </Typography>
        </Box>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card title="Preencha os dados do link de ajuda">
          <Grid container className={classes.gapGrid}>
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Link da Documentação(URL)"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.url}
                    helperText={errors?.url?.message}
                    fullWidth
                  />
                }
                control={control}
                name="url"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="pageUri"
                mode="onBlur"
                as={
                  <SelectComponent
                    label="Página em que o link aparecerá(URI)"
                    items={routesArray.map(function (route) {
                      return { id: route, name: route }
                    })}
                    error={!!errors.pageUri}
                    helperText={errors?.pageUri?.message}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Label
                padBuTitle={0}
                titleVariant="body1"
                title="Status "
                marginTopChildren={0}
              >
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checked}
                        onChange={(event) => {
                          setChecked(event.target.checked)
                        }}
                      />
                    }
                    inputRef={register}
                    name="status"
                  />
                  <Typography variant="body1">{labelChecked}</Typography>
                </Box>
              </Label>
            </Grid>
          </Grid>
        </Card>
        <Box mt={2} display="flex" justifyContent="flex-start">
          <Box pr={2}>
            <BackButton
              onClick={() => history.push(routes.documentationLinks.all)}
            >
              Voltar
            </BackButton>
          </Box>
          <Box>
            <Button color="primary" variant="contained" type="submit">
              Salvar
            </Button>
          </Box>
        </Box>
      </form>
    </>
  )
}

DocumentationLinksForm.propTypes = {
  documentationLink: PropTypes.object,
}

export default DocumentationLinksForm
