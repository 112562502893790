const PENDING_STATUS = 1
const ANSWERED_STATUS = 2
const INACTIVE_STATUS = 3

const PENDING_LABEL = 'PENDENTE'
const ANSWERED_LABEL = 'RESPONDIDO'
const INACTIVE_LABEL = 'INATIVO'

const ALL_VALUE = 'all'
const PENDING_VALUE = 'pending'
const ANSWERED_VALUE = 'answered'
const INACTIVE_VALUE = 'inactive'

const AFFIRMATIVE_ANSWER = 'Sim'
const NEGATIVE_ANSWER = 'Não'
const NOT_APPLICABLE_ANSWER = 'Não se aplica'

const TABS = [
  {
    value: ALL_VALUE,
    label: 'Todas',
  },
  {
    value: PENDING_VALUE,
    label: 'Pendentes',
  },
  {
    value: ANSWERED_VALUE,
    label: 'Respondidas',
  },
  {
    value: INACTIVE_VALUE,
    label: 'Inativas',
  },
]

const profile = {
  PENDING_STATUS,
  ANSWERED_STATUS,
  INACTIVE_STATUS,
  PENDING_LABEL,
  ANSWERED_LABEL,
  INACTIVE_LABEL,
  TABS,
  ALL_VALUE,
  PENDING_VALUE,
  ANSWERED_VALUE,
  INACTIVE_VALUE,
  AFFIRMATIVE_ANSWER,
  NEGATIVE_ANSWER,
  NOT_APPLICABLE_ANSWER,
}

export default profile
