import palette from 'theme/palette'
import components from '../components'

import { ratio } from '../helpers'

import {
  WIDTH as WIDTHPdf,
  HEIGHT as HEIGHTPdf,
  fontSize,
  PADDING,
} from '../constants'

const chapterCover = (pdf, title, subtitle) => {
  const WIDTH = WIDTHPdf(pdf)
  const HEIGHT = HEIGHTPdf(pdf)

  pdf.addPage()
  pdf.setFont('Raleway-Bold')
  pdf.setFillColor(palette.BlueDownDark)
  pdf.rect(0, 0, WIDTH, HEIGHT, 'F')
  pdf.setFontSize(fontSize.title)
  pdf.setTextColor(palette.white)
  var splited_title = pdf.splitTextToSize(title, ratio(1500))
  pdf.text(splited_title, PADDING, ratio(290))
  pdf.setFontSize(fontSize.subtitle)
  pdf.setFont('Raleway-Regular')
  var splited_subtitle = pdf.splitTextToSize(subtitle, ratio(1000))
  pdf.text(splited_subtitle, PADDING, HEIGHT - ratio(280))

  components.numberPage(pdf, palette.white)
}

export default chapterCover
