import { Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import { ChevronsRight as ChevronsRightIcon } from 'react-feather'

const TopicSection = ({ text }) => {
  return (
    <Box my={0.5} display="flex">
      <Box mr={0.5}>
        <ChevronsRightIcon size={18} />
      </Box>
      <Typography variant="body1">{text}</Typography>
    </Box>
  )
}

TopicSection.propTypes = {
  text: PropTypes.string,
}

export default TopicSection
