import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    nameEllipsis: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '18rem',
    },
  },
  headerWhite: {
    backgroundColor: theme.palette.white,
    paddingTop: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },
  paperMargin: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(5),
  },
  boxHeight: {
    height: '75vh !important',
  },
  iconMargin: {
    marginRight: theme.spacing(1),
  },
  editIconButton: {
    marginRight: theme.spacing(1),
  },
  paddingContentHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  paddingDescription: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  fullCardWidth: {
    width: '100%',
  },
  disableButtonHover: {
    backgroundColor: 'transparent !important',
  },
  gridButton: {
    justifyContent: 'space-between',
  },
}))

export default styles
