import origin from './origin'
import status from './status'
import finalityCheck from './finalityCheck'
import filterUnnecessaryLifeCycles from './filterUnnecessaryLifeCycles'
import filterUnnecessaryDataTreatments from './filterUnnecessaryDataTreatments'
import filterUnnecessaryCollectedFields from './filterUnnecessaryCollectedFields'
import getContinuedText from './getContinuedText'
import getFinalizedText from './getFinalizedText'
import getFragilityActionText from './getFragilityActionText'
import eliminateNotEliminateTexts from './eliminateNotEliminateTexts'
import eliminateNotEliminateSubtitleMessages from './eliminateNotEliminateSubtitleMessages'
import checkHighOrSevereFragility from './checkHighOrSevereFragility'
import getOriginName from './getOriginName'
import getResponsibleNames from './getResponsibleNames'
import statusChipColor from './statusChipColor'
import statusesCheck from './statusesCheck'
import chosenAction from './chosenAction'

const nonComplianceReports = {
  origin,
  status,
  finalityCheck,
  filterUnnecessaryLifeCycles,
  filterUnnecessaryDataTreatments,
  filterUnnecessaryCollectedFields,
  getContinuedText,
  getFinalizedText,
  getFragilityActionText,
  eliminateNotEliminateTexts,
  eliminateNotEliminateSubtitleMessages,
  checkHighOrSevereFragility,
  getOriginName,
  getResponsibleNames,
  statusChipColor,
  statusesCheck,
  chosenAction,
}

export default nonComplianceReports
