import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
  },
  failure: {
    color: theme.palette.error.main,
  },
  stepper: {
    border: 'none',
  },
}))

export default styles
