import { IconButton, TableContainer } from '@material-ui/core'
import { Visibility as VisibilityIcon } from '@material-ui/icons'
import { reverse } from 'named-urls'
import { useHistory } from 'react-router-dom'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { Card } from 'components'

import helpers from 'helpers'
import theme from 'theme'
import { routes } from 'Routes'

const ContextList = ({ isLoading, orders, title }) => {
  const history = useHistory()

  const handleRedirect = (contractId) =>
    history.push(reverse(routes.contracts.show, { contractId }), {
      tab: 'payment',
    })

  return (
    <Card
      fromTable
      title={title}
      paddingTitle={1}
      backgroundColor={theme.palette.iceGray}
      color="secondary"
    >
      <TableContainer>
        <Table
          size="small"
          emptyMessage="Nenhum dados foi encontrado"
          noWrap
          isLoading={isLoading}
        >
          <TableHead>
            <TableRow>
              <TableCell variant="body1" color="secondary" disableTooltip>
                ID
              </TableCell>
              <TableCell variant="body1" color="secondary" disableTooltip>
                Nº contrato
              </TableCell>
              <TableCell variant="body1" color="secondary" disableTooltip>
                Organização
              </TableCell>
              <TableCell variant="body1" color="secondary" disableTooltip>
                Valor
              </TableCell>
              <TableCell variant="body1" color="secondary" disableTooltip>
                Faturamento
              </TableCell>
              <TableCell variant="body1" color="secondary" disableTooltip>
                Vencimento
              </TableCell>
              <TableCell variant="body1" color="secondary" disableTooltip>
                Referência
              </TableCell>
              <TableCell
                align="right"
                variant="body1"
                color="secondary"
                disableTooltip
              >
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders?.map((order) => (
              <TableRow key={order?.id}>
                <TableCell variant="body1" color="secondary" disableTooltip>
                  #{order?.id || order?.entityId}
                </TableCell>
                <TableCell variant="body1" color="secondary">
                  {order?.contractIdentifier}
                </TableCell>
                <TableCell variant="body1" color="secondary">
                  {order?.organization}
                </TableCell>
                <TableCell variant="body1" color="secondary" disableTooltip>
                  R$ {helpers.formatters.currencyInput(order?.price)}
                </TableCell>
                <TableCell variant="body1" color="secondary" disableTooltip>
                  {helpers.formatters.date(
                    order?.dateInvoice ||
                      order?.createdAt ||
                      order?.nextDateAdvance,
                  ) || '-'}
                </TableCell>
                <TableCell variant="body1" color="secondary" disableTooltip>
                  {helpers.formatters.date(
                    order?.expirationDate || order?.nextBillingDate,
                  ) || '-'}
                </TableCell>
                <TableCell variant="body1" color="secondary" disableTooltip>
                  {helpers.formatters.date(
                    order?.orderReferenceDate || order?.nextDateAdvance,
                    'MM/Y',
                  ) || '-'}
                </TableCell>
                <TableCell align="right" disableTooltip>
                  <IconButton
                    onClick={() => handleRedirect(order?.entityId || order?.id)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default ContextList
