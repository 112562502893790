import * as yup from 'yup'

export const schema = yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  groupId: yup.object().nullable(),
  companySizeId: yup.object().nullable(),
  subgroupId: yup.object().nullable(),
  segmentId: yup.object().nullable(),
  companyTemplateId: yup.object(),
  statusId: yup.number().required(),
  economicActivityId: yup.object().shape({
    id: yup.number().required(),
    name: yup.string().required(),
  }),
})

export default schema
