import constants from 'constants/index'
import helpers from 'helpers'

const discountValue = (discountType, discountValue) => {
  if (discountType === constants.discountCoupon.PERCENTAGE_TYPE)
    return `${discountValue}%`

  return `${helpers.formatters.floatToMoney(Number(discountValue), true)}`
}

export default discountValue
