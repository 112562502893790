import PropTypes from 'prop-types'

import { useEffect } from 'react'

import { Box, Typography } from '@material-ui/core'

import {
  TableNecPropDataCollectedShow,
  TableNecPropDataTreatmentShow,
  TableNecPropDataLifeCycleShow,
} from './components'

import { RmcActionContent, Permitted } from 'components'

import constants from 'constants/index'

import useRmc from 'hooks/useRmc'
import FinalizedAlert from 'components/RmcActionContent/components/FinalizedAlert'
import helpers from 'helpers'

const ShowNecessityProportionality = ({ data }) => {
  const { loadData, loading, data: rmc } = useRmc()

  useEffect(() => {
    if (
      data.nonComplianceReport?.status ===
      constants.nonComplianceReports.FINALIZED_STATUS
    ) {
      loadData(data.nonComplianceReport.id)
    }
    //eslint-disable-next-line
  }, [])

  const dataLifeCycles = data.lifeCycles?.filter(
    (dataLifeCycle) => dataLifeCycle.necessary === false,
  )

  const dataTreatments = data.dataTreatments?.filter(
    (dataTreatment) => dataTreatment.necessary === false,
  )

  const collectedFields = helpers.dataCollected.mountUnnecessaryFields(
    data.dataCollecteds,
  )

  const showStep =
    dataLifeCycles.length > 0 ||
    dataTreatments.length > 0 ||
    collectedFields.length > 0

  const showAlert =
    data?.nonComplianceReport &&
    data.nonComplianceReport.status !==
      constants.nonComplianceReports.FINALIZED_STATUS &&
    data.nonComplianceReportFinality ===
      constants.nonComplianceReports.NECESSARY_FINALITY

  return (
    <>
      {showStep ? (
        <>
          <Box my={2}>
            <Typography variant="h5">
              Análise da Necessidade e Proporcionalidade
            </Typography>
          </Box>
          <Box my={2}>
            <Typography variant="body1">
              Os seguintes itens foram indicados como
              desnecessários/desproporcionais:
            </Typography>
          </Box>

          <TableNecPropDataCollectedShow collectedFields={collectedFields} />
          <TableNecPropDataTreatmentShow dataTreatments={dataTreatments} />
          <TableNecPropDataLifeCycleShow dataLifeCycles={dataLifeCycles} />
          {showAlert && (
            <Box mt={2}>
              <FinalizedAlert
                nonComplianceReportId={data.nonComplianceReport.id}
              />
            </Box>
          )}
        </>
      ) : (
        <>
          {data.nonComplianceReportFinality !==
            constants.nonComplianceReports.FRAGILITY_FINALITY && (
            <Box>
              {!loading && rmc?.dataProcessVersion === data?.version && (
                <Permitted
                  tag={constants.permissions.NON_COMPLIANCE_REPORT.EDIT}
                >
                  <Box my={2}>
                    <Typography variant="h5">
                      Resultado da reavaliação de necessidade e
                      proporcionalidade
                    </Typography>
                  </Box>
                  <RmcActionContent showHeader={false} />
                </Permitted>
              )}
            </Box>
          )}
        </>
      )}
    </>
  )
}

ShowNecessityProportionality.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ShowNecessityProportionality
