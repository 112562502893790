import { useState } from 'react'

import { IconButton, Collapse, Box, Typography } from '@material-ui/core'
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'

import clsx from 'clsx'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import PropTypes from 'prop-types'

import constants from 'constants/index'

import useStyles from './styles'

const AvailableMeasureRow = ({ availableMeasure, classes }) => {
  const [expanded, setExpanded] = useState()

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell>{availableMeasure.id}</TableCell>
        <TableCell overFlowSize="275px">
          {availableMeasure.description}
        </TableCell>
        <TableCell disableTooltip>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          disableTooltip
          noOverFlow
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <Box mx={2.5} mb={2.5}>
              <Typography gutterBottom className={classes.expandedText}>
                {availableMeasure.description}
              </Typography>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const TableAvailableMeasures = ({ rmc, type }) => {
  const classes = useStyles()

  const filter =
    type === constants.nonComplianceReports.ANOTHER_FINALITY
      ? (securityMeasure) =>
          securityMeasure.measureType ===
          constants.nonComplianceReports.ANOTHER_FINALITY
      : (securityMeasure) =>
          securityMeasure.measureType !==
          constants.nonComplianceReports.ANOTHER_FINALITY

  const filteredSecurityMeasures = rmc?.securityMeasures?.filter(filter)

  return (
    <Table size="small" emptyMessage="Nenhuma medida encontrada" noWrap>
      <TableHead>
        <TableRow>
          <TableCell width="10%">ID</TableCell>
          <TableCell width="80%">Descrição</TableCell>
          <TableCell width="10%"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {filteredSecurityMeasures.map((availableMeasure) => (
          <AvailableMeasureRow
            availableMeasure={availableMeasure}
            classes={classes}
            key={availableMeasure.id}
          />
        ))}
      </TableBody>
    </Table>
  )
}

TableAvailableMeasures.propTypes = {
  rmc: PropTypes.object,
  type: PropTypes.string,
}

TableAvailableMeasures.defaultProps = {
  type: '',
}

export default TableAvailableMeasures
