import { useState } from 'react'

import { isEmpty } from 'lodash'

import {
  Card,
  CardHeader,
  Divider,
  Grid,
  Button,
  Box,
  Typography,
} from '@material-ui/core'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { LoadingFeedback, MenuButton } from 'components'
import { DialogAdd, DialogEdition, DialogExclusion } from './components'

import { PlusCircle as PlusCircleIcon } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { useFetch } from 'hooks'
import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'

const DataTreatments = ({ dataProcessId, companyId }) => {
  const [openCreate, setOpenCreate] = useState(false)
  const [dialogRemove, setDialogRemove] = useState(false)
  const [dialogEdit, setDialogEdit] = useState(false)
  const [handleDataAgent, setHangleDataAgent] = useState(null)
  const [type, setType] = useState(null)
  const [document, setDocument] = useState(null)

  const snackbar = useSnackbar()

  const { response, isLoading, refresh } = useFetch(
    service.dponet.dataTreatmentAgents.index,
    { dataProcessId, companyId, perPage: 100_000 },
    [],
  )

  const isCompanySelfPresent = () => {
    const agent = helpers.dataTreatmentAgent.agentTypeOfCompanySelf(
      response?.data?.dataTreatmentAgents,
    )
    if (isEmpty(agent)) return false

    return true
  }

  const handleRemove = (dataTreatmentAgent) => {
    if (dataTreatmentAgent?.companySelf) {
      snackbar.open({
        message:
          'Não é possível remover essa organização; no entanto, você pode optar por editá-lo.',
        variant: 'error',
      })
    } else {
      setHangleDataAgent(dataTreatmentAgent)
      setDialogRemove(true)
    }
  }

  const handleEdit = (dataTreatmentAgent) => {
    setHangleDataAgent(dataTreatmentAgent)
    setDialogEdit(true)
  }

  const handleChange = async (actionExclusion) => {
    try {
      if (actionExclusion) {
        await service.dponet.dataTreatmentAgents.destroy({
          dataProcessId,
          dataTreatmentAgentId: handleDataAgent?.id,
        })
      } else {
        await service.dponet.dataTreatmentAgents.changeType({
          dataProcessId,
          dataTreatmentAgentId: handleDataAgent?.id,
          agentType: type === null ? handleDataAgent?.agentType : type,
          document: document === null ? handleDataAgent?.document : document,
        })
      }

      snackbar.open({
        message: `Agente de tratamento ${
          actionExclusion ? 'excluido' : 'editado'
        } com sucesso`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: `Falha ao ${
          actionExclusion ? 'excluir' : 'editar'
        } o agente de tratamento`,
        variant: 'error',
      })
    } finally {
      setHangleDataAgent(null)
      setDialogRemove(false)
      setDialogEdit(false)
      refresh()
    }
  }

  return (
    <Card>
      <LoadingFeedback open={isLoading} />
      <Grid container>
        <Grid item xs={6}>
          <CardHeader title="Agentes de tratamentos" />
        </Grid>
        <Grid item xs={6}>
          <Box m={1} display="flex" justifyContent="flex-end">
            <Button
              onClick={() => setOpenCreate(true)}
              variant="contained"
              size="small"
            >
              <Box pl={1} pt={1}>
                <PlusCircleIcon size="18px" />
              </Box>
              <Box px={1}>
                <Typography variant="h6">ADICIONAR</Typography>
              </Box>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <PerfectScrollbar>
        <Table
          emptyMessage="Nenhum agente de tratamento encontrado"
          size="small"
          noWrap
        >
          <TableHead>
            <TableRow>
              <TableCell width="40%" disableTooltip>
                Organização
              </TableCell>
              <TableCell width="30%" disableTooltip>
                CPF/CNPJ
              </TableCell>
              <TableCell width="20%" disableTooltip>
                Tipo
              </TableCell>
              <TableCell width="10%" disableTooltip>
                Ação
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {response?.data?.dataTreatmentAgents?.map(
              (dataTreatmentAgent, index) => (
                <TableRow key={index}>
                  <TableCell>{dataTreatmentAgent?.name}</TableCell>
                  <TableCell disableTooltip>
                    {helpers.formatters.cnpjAndCpfMask(
                      dataTreatmentAgent?.document,
                    ) || '-'}
                  </TableCell>
                  <TableCell>
                    {
                      constants.dataTreatmentAgent.TRANSLATED_TYPE[
                        dataTreatmentAgent?.agentType
                      ]
                    }
                  </TableCell>
                  <TableCell align="right" disableTooltip>
                    <MenuButton>
                      <Button
                        color="secondary"
                        fullWidth
                        onClick={() => handleEdit(dataTreatmentAgent)}
                      >
                        Editar
                      </Button>
                      <Button
                        color="secondary"
                        fullWidth
                        onClick={() => handleRemove(dataTreatmentAgent)}
                      >
                        Excluir
                      </Button>
                    </MenuButton>
                  </TableCell>
                </TableRow>
              ),
            )}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      {!isLoading && openCreate && (
        <DialogAdd
          open={openCreate}
          setOpen={setOpenCreate}
          isCompanySelfPresent={isCompanySelfPresent}
          refresh={refresh}
          dataProcessId={dataProcessId}
          companyId={companyId}
        />
      )}
      {!isLoading && dialogEdit && (
        <DialogEdition
          open={dialogEdit}
          setOpen={setDialogEdit}
          handleChange={handleChange}
          agent={handleDataAgent}
          setType={setType}
          type={type}
          setDocument={setDocument}
          document={document}
        />
      )}
      {!isLoading && dialogRemove && (
        <DialogExclusion
          open={dialogRemove}
          setOpen={setDialogRemove}
          handleRemove={handleChange}
        />
      )}
    </Card>
  )
}

export default DataTreatments
