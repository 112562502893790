import lineWithDate from './lineWithDate'
import numberAndTitle from './numberAndTitle'
import numberPage from './numberPage'
import paragraph from './paragraph'
import subtitle from './subtitle'
import table from './table'
import rectangle from './rectangle'

const components = {
  lineWithDate,
  numberAndTitle,
  numberPage,
  paragraph,
  subtitle,
  table,
  rectangle,
}

export default components
