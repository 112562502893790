import PropTypes from 'prop-types'
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core'

import { Controller } from 'react-hook-form'

import { Label, CompanyTypeAndSeachInput } from 'components'

import constants from 'constants/index'

const CompanyCompany = ({ control, companyTemplateName, watch, errors }) => {
  return (
    <Grid item xs={12}>
      <Label title="Empresa modelo" description={companyTemplateName}>
        {!companyTemplateName && (
          <Controller
            as={
              <RadioGroup>
                <Box>
                  <FormControlLabel
                    value={constants.preRegistration.COPY_COMPANY_PLAN_TYPE}
                    control={<Radio color="primary" />}
                    label="Empresa modelo vinculada ao plano (padrão)"
                  />
                </Box>
                <Box>
                  <FormControlLabel
                    value={
                      constants.preRegistration.COPY_COMPANY_EXISTING_COMPANY
                    }
                    control={<Radio color="primary" />}
                    label="Definir uma empresa existente como modelo"
                  />
                </Box>
              </RadioGroup>
            }
            control={control}
            name="typeTemplate"
            mode="onBlur"
          />
        )}
        {watch('typeTemplate') ===
          constants.preRegistration.COPY_COMPANY_EXISTING_COMPANY && (
          <CompanyTypeAndSeachInput
            control={control}
            errors={errors}
            fieldName="companyTemplate"
          />
        )}
      </Label>
    </Grid>
  )
}

CompanyCompany.propTypes = {
  control: PropTypes.object.isRequired,
  companyTemplateName: PropTypes.string,
  watch: PropTypes.func,
  errors: PropTypes.object,
}

export default CompanyCompany
