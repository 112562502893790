const ALL_TAB = 0
const ACTIVE_TAB = 1
const INACTIVE_TAB = 2

const STATUSES = {
  active: 'active',
  inactive: 'inactive',
}

const STATUSES_LABEL = {
  active: 'Ativo',
  inactive: 'Inativo',
}

const PLATFORMS = {
  easy: 'easy',
  dponet: 'dponet',
}

const COOKIE_PLANS = {
  free: 'free',
  limited: 'limited',
  unlimited: 'unlimited',
  custom: 'custom',
}

const PLATFORM_LABELS = {
  easy: 'DPOeasy',
  dponet: 'DPOnet',
}

const COOKIE_LABELS = {
  free: 'Gratuíto',
  limited: 'Limitado',
  unlimited: 'Full',
  custom: 'Personalizado',
}

const SELECT_PLATFORM_INTEGRATION_PARTNER = [
  { id: PLATFORMS.dponet, name: PLATFORM_LABELS.dponet },
  { id: PLATFORMS.easy, name: PLATFORM_LABELS.easy },
]

const SELECT_COOKIE_PLAN_INTEGRATION_PARTNER = [
  { id: COOKIE_PLANS.free, name: COOKIE_LABELS.free },
  { id: COOKIE_PLANS.limited, name: COOKIE_LABELS.limited },
  { id: COOKIE_PLANS.unlimited, name: COOKIE_LABELS.unlimited },
  { id: COOKIE_PLANS.custom, name: COOKIE_LABELS.custom },
]

const TABS = [
  {
    value: ALL_TAB,
    label: 'TODAS',
  },
  {
    value: ACTIVE_TAB,
    label: 'ATIVOS',
  },
  {
    value: INACTIVE_TAB,
    label: 'INATIVOS',
  },
]

const integrationPartner = {
  ALL_TAB,
  ACTIVE_TAB,
  INACTIVE_TAB,
  STATUSES,
  TABS,
  PLATFORMS,
  STATUSES_LABEL,
  COOKIE_LABELS,
  PLATFORM_LABELS,
  SELECT_PLATFORM_INTEGRATION_PARTNER,
  SELECT_COOKIE_PLAN_INTEGRATION_PARTNER,
  COOKIE_PLANS,
}

export default integrationPartner
