import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ ...params }) => {
  return await dponetAPI.get('/groups', { params })
}

const show = async ({ groupId = '' }) => {
  return await dponetAPI.get(`/groups/${groupId}`)
}

const post = async (data) => {
  return await dponetAPI.post('/groups', data)
}

const put = async ({ groupId = '', ...data }) => {
  return await dponetAPI.put(`/groups/${groupId}`, data)
}

const destroy = async ({ groupId }) => {
  return await dponetAPI.delete(`/groups/${groupId}`)
}

const groups = {
  get,
  show,
  post,
  put,
  destroy,
}

export default groups
