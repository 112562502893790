import * as yup from 'yup'

const schemaDepartment = (isEdit) => {
  return yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    ...(!isEdit && {
      companyId: yup.object().typeError('Selecione uma empresa').required(),
    }),
  })
}

export default schemaDepartment
