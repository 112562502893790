import { Link as RouterLink } from 'react-router-dom'
import { useLocation, matchPath } from 'react-router'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, Divider, Hidden, List, Typography } from '@material-ui/core'

import NavItem from '../NavItem'

import { Permitted } from 'components'

import useAuth from 'hooks/useAuth'

import Logo from 'images/dponet-logo-blue.png'

const renderNavItems = ({ items, ...rest }) => {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        [],
      )}
    </List>
  )
}

const reduceChildRoutes = ({
  acc,
  pathname,
  item,
  menuDisabled,
  depth = 0,
}) => {
  const key = (item.id || item.title) + depth

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    })

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        somePermissions={item.somePermissions}
        permission={item.permission}
        info={item.info}
        open={Boolean(open)}
        title={item.title}
        show={item.show}
        menuDisabled={menuDisabled}
        main={item.main}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
          menuDisabled: menuDisabled,
        })}
      </NavItem>,
    )
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        somePermissions={item.somePermissions}
        permission={item.permission}
        info={item.info}
        show={item.show}
        title={item.title}
        menuDisabled={menuDisabled}
        main={item.main}
      />,
    )
  }

  return acc
}

const Content = () => {
  const auth = useAuth()
  const location = useLocation()

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box mt={4} display="flex" justifyContent="center">
            <RouterLink to={auth.homePage}>
              <img src={Logo} alt="logo" width="75px" />
            </RouterLink>
          </Box>
        </Hidden>
        <Box my={4} textAlign="center">
          <Typography variant="h5" color="textPrimary" underline="none">
            {auth?.user?.name}
          </Typography>
          <Box mt={1}>
            <Typography variant="body2" color="textSecondary">
              {auth?.user?.profile?.name}
            </Typography>
          </Box>
        </Box>
        <Divider />

        <Box
          flex="1"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="80vh"
        >
          <Box p={2}>
            {auth.menuItems &&
              auth.menuItems.map((item) => (
                <Permitted
                  tag={item.permission}
                  someTags={item.somePermissions}
                  key={item?.items[0]?.title}
                  show={item.show}
                >
                  {renderNavItems({
                    items: item.items,
                    pathname: location.pathname,
                    menuDisabled: item.menuDisabled,
                  })}
                </Permitted>
              ))}
          </Box>
        </Box>
      </PerfectScrollbar>
    </Box>
  )
}

export default Content
