import PropTypes from 'prop-types'

import { useState } from 'react'

import { TableContainer, Button } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { Permitted } from 'components'

import DataTreatmentModal from '../DialogDataTreatment'
import DialogRemoveDataTreatment from '../DialogRemoveDataTreatment'

import * as service from 'service'
import helpers from 'helpers'
import useSnackbar from 'hooks/useSnackbar'

import constants from 'constants/index'

const TableDataTreatments = ({
  dataProcessId,
  dataTreatments,
  edit,
  classes,
  loadData,
}) => {
  const snackbar = useSnackbar()
  const [dialogRemoveDataTreatments, setDialogRemoveDataTreatments] =
    useState(false)
  const [removeDataTreatment, setRemoveDataTreatment] = useState()

  const handleRemove = (dataTreatmentId) => {
    setRemoveDataTreatment(dataTreatmentId)
    setDialogRemoveDataTreatments(true)
  }

  const deleteDataTreatment = async (dataProcessId, dataTreatmentId) => {
    try {
      await service.dponet.dataTreatments.destroy({
        dataProcessId,
        dataTreatmentId,
      })

      setRemoveDataTreatment(null)
      setDialogRemoveDataTreatments(false)

      loadData()

      snackbar.open({
        message: 'Dado compartilhado removido com sucesso',
        variant: 'success',
      })
    } catch (error) {
      setRemoveDataTreatment(null)
      setDialogRemoveDataTreatments(true)
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
  }

  return (
    <>
      <TableContainer>
        <Table
          size="small"
          emptyMessage="Nenhum compartilhamento de dados foi encontrado"
          noWrap
        >
          <TableHead>
            <TableRow>
              <TableCell width={edit && '20%'}>
                Com quem o dado é compartilhado?
              </TableCell>
              <TableCell width={edit && '25%'}>
                Porquê o dado é compartilhado?
              </TableCell>
              <TableCell width={edit && '25%'}>
                Como o dado é compartilhado
              </TableCell>
              <TableCell width={edit && '20%'}>
                Tipo de compartilhamento
              </TableCell>
              {edit ? (
                <TableCell width={edit && '20%'} align="right">
                  Ações
                </TableCell>
              ) : (
                <></>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataTreatments?.map((dataTreatment) => (
              <TableRow key={dataTreatment.id}>
                <TableCell>{dataTreatment.shareProcess?.name}</TableCell>
                <TableCell>{dataTreatment.description}</TableCell>
                <TableCell>{dataTreatment.controllMechanismView}</TableCell>
                <TableCell>{dataTreatment.shareType?.name}</TableCell>
                {edit && (
                  <TableCell align="right" disableTooltip>
                    <DataTreatmentModal
                      dataTreatment={dataTreatment}
                      classes={classes}
                      mode="edit"
                    >
                      <Permitted
                        tag={constants.permissions.DATA_PROCESSES.CREATE}
                      >
                        <Button
                          color="secondary"
                          fullWidth
                          onClick={() => handleRemove(dataTreatment.id)}
                        >
                          Excluir
                        </Button>
                      </Permitted>
                    </DataTreatmentModal>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DialogRemoveDataTreatment
        open={dialogRemoveDataTreatments}
        setOpen={setDialogRemoveDataTreatments}
        handleRemove={deleteDataTreatment}
        dataProcessId={dataProcessId}
        dataTreatmentId={removeDataTreatment}
      />
    </>
  )
}

TableDataTreatments.propTypes = {
  classes: PropTypes.object.isRequired,
  edit: PropTypes.bool,
  dataTreatments: PropTypes.arrayOf(Object).isRequired,
  dataProcessId: PropTypes.number,
  loadData: PropTypes.func.isRequired,
}

TableDataTreatments.defaultProps = {
  loadData: () => {},
}

export default TableDataTreatments
