import * as yup from 'yup'

const schemaCoupon = () =>
  yup.object().shape({
    name: yup.string().required(),
    code: yup.string().required(),
    status: yup.boolean(),
    discountType: yup.string().required(),
    discountValue: yup.string().required(),
    couponUses: yup.string().required(),
    document: yup.string().when(
      'couponUses',
      {
        is: 'false',
        then: yup.string().required(),
      },
      {
        is: 'true',
        then: yup.string().nullable(),
      },
    ),
    startDate: yup.string().nullable(),
    expirationDate: yup.string().nullable(),
    sellerId: yup.string().required(),
  })

export default schemaCoupon
