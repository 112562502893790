import constants from 'constants/index'

const getFinalizedText = (finalized, fragilityFinality, isRisk, rmc) => {
  const inactivated =
    rmc.chosenAction === constants.nonComplianceReports.FINALIZED_CHOSEN_ACTION

  if (!fragilityFinality && !isRisk) {
    if (finalized) {
      return ''
    }
    return 'Os itens indicados como desnecessários/desproporcionais estão aguardando reanálise.'
  }

  if (inactivated) {
    return 'O processo foi inativado devido ao risco calculado. Em vez de optar por continuar com o processo e abordar o risco por meio de medidas de segurança, a empresa optou por deixar de realizar a atividade de tratamento de dados pessoais.'
  }

  if (finalized) {
    return `Medidas de segurança administrativas e técnicas aplicadas. ${mountMeasuresText(
      rmc?.securityMeasures,
    )}`
  }

  return ''
}

const mountMeasuresText = (measures) => {
  let text = []
  const availableMeasures = measures.filter(
    (measure) =>
      measure.measureType === constants.nonComplianceReports.QUESTION_FINALITY,
  )

  const anotherMeasures = measures.filter(
    (measure) =>
      measure.measureType === constants.nonComplianceReports.ANOTHER_FINALITY,
  )

  if (!!availableMeasures.length) {
    text.push(
      'Medidas dos questionários e implementadas em RMCs de Governança e Cultura e Infraestrutura e Tecnologia: ',
    )
    text = mountItems(availableMeasures, text)
  }

  if (!!anotherMeasures.length) {
    text.push('Outras medidas administrativas e técnicas: ')
    text = mountItems(anotherMeasures, text)
  }

  return text.join('')
}

const mountItems = (items, text) => {
  for (let item of items) {
    const descriptionArray = item.description.split(' ')
    text.push(
      `${item.id} - ${descriptionArray.slice(0, 3).join(' ')}${
        descriptionArray.length > 3 ? '...' : ''
      }; `,
    )
  }
  return text
}
export default getFinalizedText
