import { makeStyles } from '@material-ui/core'

const styles = (spacingXl, spacingLg, spacingMd, spacingSm, spacingXs) => {
  return makeStyles((theme) => ({
    container: {
      [theme.breakpoints.only('xl')]: {
        paddingLeft: theme.spacing(spacingXl),
        paddingRight: theme.spacing(spacingXl),
      },
      [theme.breakpoints.only('lg')]: {
        paddingLeft: theme.spacing(spacingLg),
        paddingRight: theme.spacing(spacingLg),
      },
      [theme.breakpoints.only('md')]: {
        paddingLeft: theme.spacing(spacingMd),
        paddingRight: theme.spacing(spacingMd),
      },
      [theme.breakpoints.only('sm')]: {
        paddingLeft: theme.spacing(spacingSm),
        paddingRight: theme.spacing(spacingSm),
      },
      [theme.breakpoints.only('xs')]: {
        paddingLeft: theme.spacing(spacingXs),
        paddingRight: theme.spacing(spacingXs),
      },
    },
  }))
}

export default styles
