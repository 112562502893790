const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  text: {
    width: 'auto',
    marginRight: theme.spacing(1),
    paddingTop: theme.spacing(0.75),
  },
  tagsGroup: {
    display: 'flex',
  },
  tag: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.white,
    padding: theme.spacing(0.75, 1.25),
    borderRadius: '25px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: '200px',
    fontSize: '12px',
  },
  tagText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: '700',
    paddingRight: theme.spacing(1),
  },
  tagIconButton: {
    padding: '0px',
  },
  tagIcon: {
    width: '18px',
    height: '18px',
  },
})

export default styles
