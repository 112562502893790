import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  active: {
    backgroundColor: theme.palette.greenPantone,
    borderRadius: '4px',
    color: theme.palette.white,
    textTransform: 'uppercase',
    fontWeight: 700,
    height: '21px',
  },
  inactive: {
    backgroundColor: theme.palette.spanishGray,
    borderRadius: '4px',
    color: theme.palette.white,
    textTransform: 'uppercase',
    fontWeight: 700,
    height: '21px',
  },
  draft: {
    backgroundColor: theme.palette.azure,
    borderRadius: '4px',
    color: theme.palette.white,
    textTransform: 'uppercase',
    fontWeight: 700,
    height: '21px',
  },
}))
export default styles
