import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  chipActive: {
    backgroundColor: colors.green[800],
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipInactive: {
    backgroundColor: colors.grey[800],
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
}))

export default styles
