import { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import { useForm, Controller } from 'react-hook-form'
import useDataProcess from 'hooks/useDataProcess'
import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'

import { makeStyles, Grid, Typography, Button, Box } from '@material-ui/core'

import { PlusCircle } from 'react-feather'

import DocumentsTable from './components/DocumentsTable'

import { DescriptionPaper } from '../../'

import styles from '../styles'
import * as service from 'service'
import { ConfirmationDialog, LoadingBox } from 'components'
import helpers from 'helpers'

const useStyles = makeStyles(styles)

const Documents = ({ description }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const {
    data: dataProcess,
    toNextStep,
    setToNextStep,
    nextStep,
  } = useDataProcess()

  const [dialogConfirmAddDocument, setDialogConfirmAddDocument] =
    useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!dialogConfirmAddDocument) {
      document.getElementById('documents').value = ''
    }
  }, [dialogConfirmAddDocument])

  const { response, isLoading, refresh } = useFetch(
    service.dponet.dataProcesses.get,
    {
      dataProcessId: dataProcess.id,
    },
  )

  const { control } = useForm()

  useEffect(() => {
    if (toNextStep) {
      if (!isLoading) {
        nextStep()
        setToNextStep(false)
      }
    }
    // eslint-disable-next-line
  }, [nextStep])

  const onChangeCreate = async () => {
    const documentInput = document.getElementById('documents')
    const files = documentInput.files

    if (files.length === 0) return

    try {
      setLoading(true)
      setDialogConfirmAddDocument(false)
      await service.dponet.documents.addDocument({
        dataProcessId: dataProcess.id,
        files: files,
      })
      setLoading(false)
      documentInput.value = ''
      refresh()
      snackbar.open({
        message: 'Documento(s) adicionado(s) com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
  }

  return (
    <>
      <DescriptionPaper description={description} isDocument>
        <form id="documents-form">
          <Controller
            as={
              <Button
                variant="outlined"
                startIcon={<PlusCircle size={20} />}
                htmlFor="documents"
                component="label"
              >
                <Box mr={1}>
                  <Typography>Selecionar documento(s)</Typography>
                </Box>
                <input
                  type="file"
                  multiple
                  id="documents"
                  style={{ display: 'none' }}
                />
              </Button>
            }
            control={control}
            onChange={() => setDialogConfirmAddDocument(true)}
            name="documents"
          />
        </form>
      </DescriptionPaper>
      <Grid container className={classes.root}>
        <Grid xs={12} item>
          {isLoading || loading ? (
            <LoadingBox />
          ) : (
            <>
              <DocumentsTable
                dataProcessId={dataProcess.id}
                onEvent={refresh}
                documents={response?.data?.dataProcess?.documents}
              />
              <ConfirmationDialog
                open={dialogConfirmAddDocument}
                setOpen={setDialogConfirmAddDocument}
                message="Você confirma que não existe nenhum dado pessoal no
                  modelo/template selecionado? Lembre-se de que a existência de
                  qualquer informação no documento é de responsabilidade do
                  usuário."
                cancelButtonText="Cancelar"
                buttonText="Continuar"
                actionAcceptButton={onChangeCreate}
              />
            </>
          )}
        </Grid>
      </Grid>
    </>
  )
}

Documents.propTypes = {
  description: PropTypes.string.isRequired,
}

export default Documents
