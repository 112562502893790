import { useState } from 'react'

import PropTypes from 'prop-types'

import { BaseDialog, LoadingFeedback } from 'components'

import { Typography } from '@material-ui/core'

import { useSnackbar } from 'hooks'

import * as service from 'service'

const ExportRipdDialog = ({ open, setOpen, companyId }) => {
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)

  const handleExport = async () => {
    setLoading(true)

    try {
      await service.dponet.companies.exportRipd({
        companyId,
      })
      snackbar.open({
        message: 'RIPD exportado com sucesso, verifique seu e-mail!',
        variant: 'success',
      })
      setOpen(false)
      setLoading(false)
    } catch (error) {
      snackbar.open({
        message: 'Ocorreu uma falha na exportação.',
        variant: 'error',
      })
      setOpen(false)
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <BaseDialog
        open={open}
        setOpen={setOpen}
        title="Exportar RIPD"
        closeButtonText="cancelar"
        actionButtonText="Exportar"
        actionButton={handleExport}
        noMargin
      >
        <Typography>Tem certeza que deseja exportar o RIPD?</Typography>
      </BaseDialog>
    </>
  )
}

ExportRipdDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  companyId: PropTypes.number.isRequired,
}

ExportRipdDialog.defaultProps = {
  setOpen: () => {},
}

export default ExportRipdDialog
