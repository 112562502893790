import PropTypes from 'prop-types'
import {
  Page,
  Container,
  ContentHeader,
  Permitted,
  MenuButton,
} from 'components'

import { RemoveSubgroup } from './components'

import { useFetch } from 'hooks'
import * as service from 'service'

import constants from 'constants/index'

import { SubgroupsForm } from './components'

const SubgroupEdit = ({ match }) => {
  const {
    params: { subgroupId },
  } = match

  const { response, isLoading, refresh } = useFetch(
    service.dponet.subgroups.show,
    {
      subgroupId,
    },
    [],
  )

  return (
    <Page title="Edição de subgrupo">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Edição"
          menu="Subgrupo"
          subtitle="Edição de subgrupo"
        >
          <Permitted someTags={[constants.permissions.SUBGROUPS.DESTROY]}>
            <MenuButton>
              <RemoveSubgroup
                isEdit
                subgroupId={response?.data?.subgroup?.id}
                refresh={refresh}
              />
            </MenuButton>
          </Permitted>
        </ContentHeader>
        {!isLoading && (
          <SubgroupsForm isEdit subgroup={response?.data?.subgroup} />
        )}
      </Container>
    </Page>
  )
}

SubgroupEdit.propTypes = {
  match: PropTypes.object,
}

export default SubgroupEdit
