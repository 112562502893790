import { useState } from 'react'
import { TextField, Grid } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'

import useSnackbar from 'hooks/useSnackbar'

import { BaseDialog, LoadingFeedback } from 'components'

import * as service from 'service'
import helpers from 'helpers'

const Linked = ({ open, setOpen, paymentContractId, refresh }) => {
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      contractIdentifier: '',
    },
  })

  const onSubmit = async ({ contractIdentifier }) => {
    setLoading(true)
    try {
      await service.dponet.paymentContract.linked({
        paymentContractId,
        contractIdentifier,
      })

      snackbar.open({
        message: 'Contrato vinculado com sucesso',
        variant: 'success',
      })
      refresh()
      setOpen(false)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Erro ao vincular contrato',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <BaseDialog
        open={open}
        setOpen={setOpen}
        title="Vincular contrato do omie"
        closeButtonText="cancelar"
        actionButtonText="Confirmar"
        formRef="linked-omie-contract"
        noMargin
      >
        <form onSubmit={handleSubmit(onSubmit)} id="linked-omie-contract">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Identificador do contrato"
                    placeholder="2024/0000"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.contractIdentifier}
                    helperText={errors?.contractIdentifier?.message}
                    fullWidth
                    required
                  />
                }
                control={control}
                name="contractIdentifier"
                mode="onBlur"
              />
            </Grid>
          </Grid>
        </form>
      </BaseDialog>
    </>
  )
}

export default Linked
