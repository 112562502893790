import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import {
  Card,
  CardHeader,
  Divider,
  Box,
  Grid,
  TextField,
} from '@material-ui/core'

import { Label, Select as SelectComponent } from 'components'
import ContextDataProcessSource from '../../../../ContextDataProcessSource'

import useDataProcess from 'hooks/useDataProcess'

import useStyles from '../../styles'

import { useForm, Controller } from 'react-hook-form'

import helpers from 'helpers'

import * as service from 'service'

import VolumetryForm from '../../../../VolumetryForm'

import schema from './schema'

const Infos = () => {
  const {
    setCode,
    payload: formatDataProcess,
    data,
    setData,
    submitting,
    departments,
    setValidForms,
    validForms,
    setVolumetryType,
    volumetryType,
    setLaws,
    laws,
    setSubmitUpdate,
  } = useDataProcess()

  useEffect(() => {
    if (data?.id) {
      setLaws(data?.laws?.map((law) => law.id))
      setVolumetryType(data?.storageMode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id])

  const payload = formatDataProcess()

  const {
    control,
    triggerValidation,
    errors,
    getValues,
    setValue,
    watch,
    register,
  } = useForm({
    reValidateMode: 'onBlur',
    validationSchema: schema(volumetryType),
    defaultValues: {
      departmentId: payload?.departmentId ?? '',
      code: payload?.code ?? '',
      version: payload?.version ?? '',
      name: payload?.name ?? '',
      finality: payload?.finality ?? '',
      value: payload?.value ?? '',
      volumetry: payload?.volumetry ?? '',
      deadlineJustification: payload?.deadlineJustification ?? '',
      sourceDescription: '',
      sourceDescriptionAnother: '',
      dataProcessDataSources: payload?.dataProcessDataSources
        ? helpers.dataProcesses.covertDataSourcesToOptions(
            payload?.dataProcessDataSources,
          )
        : [],
    },
  })

  const [selectDepartmentOpen] = useState(false)
  const [allSources, setAllSources] = useState([])

  const classes = useStyles()

  const setCodeByDepartment = async (departmentId) => {
    const response = await service.dponet.departments.getCode({ departmentId })
    setCode(response.data.code)
    setValue('code', response.data.code)
  }

  const handleChangeValue = (name, value) => {
    data[name] = value
    formComplete()
    setData(data)
    setValue(name, value)
    return value
  }

  const formComplete = () => {
    const emptyFields = [
      'departmentId',
      'code',
      'version',
      'name',
      'finality',
      'value',
      'volumetry',
      'deadlineJustification',
    ].filter((field) => payload[field] === '')
    if (isEmpty(emptyFields) && !isEmpty(payload['dataProcessDataSources'])) {
      return setValidForms({ ...validForms, info: true })
    }

    return setValidForms({ ...validForms, info: false })
  }

  useEffect(() => {
    if (submitting >= 1) {
      triggerValidation()
    }

    triggerValidation().then((valid) => {
      setSubmitUpdate(valid)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitting])

  useEffect(() => {
    formComplete()

    setAllSources(
      helpers.dataProcesses.covertDataSourcesToOptions(
        payload?.dataProcessDataSources || [],
      ),
    )
    // eslint-disable-next-line
  }, [])

  const watchSourceDescription = watch('sourceDescription')

  return (
    <Card>
      <CardHeader title="Detalhes do processo" />
      <Divider />
      <Box className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Label
              title="Departamentos"
              description="Selecione o departamento em que os dados serão armazenados."
              item
            >
              <Controller
                as={
                  <SelectComponent
                    items={departments}
                    open={selectDepartmentOpen}
                    error={!!errors.departmentId}
                    helperText={errors?.departmentId?.message}
                  />
                }
                onChange={([event]) => {
                  handleChangeValue(event.target.name, event.target.value)
                  setCodeByDepartment(event.target.value)
                  return event.target.value
                }}
                control={control}
                name="departmentId"
                mode="onChange"
              />
            </Label>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label
              title="Código"
              description="Identificador único do registro em sua empresa."
              item
            >
              <Controller
                as={
                  <TextField
                    color="primary"
                    variant="outlined"
                    error={!!errors.code}
                    helperText={errors?.code?.message}
                    fullWidth
                  />
                }
                onBlur={([event]) => {
                  return handleChangeValue(
                    event.target.name,
                    event.target.value,
                  )
                }}
                control={control}
                name="code"
                mode="onBlur"
              />
            </Label>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label
              title="Versão"
              description="Versão do novo processamento de dados."
              item
            >
              <Controller
                as={
                  <TextField
                    disabled
                    color="primary"
                    variant="outlined"
                    error={!!errors.version}
                    helperText={errors?.version?.message}
                    fullWidth
                  />
                }
                control={control}
                name="version"
                mode="onChange"
              />
            </Label>
          </Grid>
          <Grid item xs={12}>
            <Label
              title="Nome do registro"
              description="Descreva qual o nome do registro que vamos mapear, ou seja, aqui é onde damos início ao mapeamento."
              item
            >
              <Controller
                as={
                  <TextField
                    color="primary"
                    variant="outlined"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    fullWidth
                  />
                }
                onBlur={([event]) => {
                  return handleChangeValue(
                    event.target.name,
                    event.target.value,
                  )
                }}
                control={control}
                name="name"
                mode="onBlur"
              />
            </Label>
          </Grid>
          <Grid item xs={12}>
            <Label
              description="Para que? Por qual motivo a empresa trata esses dados? O objetivo é descobrir a finalidade."
              title="Por que o dado é tratado?"
              item
            >
              <Controller
                as={
                  <TextField
                    color="primary"
                    variant="outlined"
                    multiline
                    rows={3}
                    error={!!errors.finality}
                    helperText={errors?.finality?.message}
                    fullWidth
                  />
                }
                onBlur={([event]) => {
                  return handleChangeValue(
                    event.target.name,
                    event.target.value,
                  )
                }}
                control={control}
                name="finality"
                mode="onBlur"
              />
            </Label>
          </Grid>
          <ContextDataProcessSource
            errors={errors}
            control={control}
            classes={classes}
            setValue={handleChangeValue}
            getValues={getValues}
            allSources={allSources}
            setAllSources={setAllSources}
            watchSourceDescription={watchSourceDescription}
            isEdit
          />
          <Grid item xs={12}>
            <VolumetryForm
              control={control}
              setValue={handleChangeValue}
              enable={data?.id}
              register={register}
              errors={errors}
              volumetryType={volumetryType}
              setVolumetryType={setVolumetryType}
              laws={laws}
              setLaws={setLaws}
              data={data}
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

export default Infos
