import { useState } from 'react'
import {
  Card,
  CardHeader,
  Divider,
  Grid,
  Button,
  Box,
  Typography,
} from '@material-ui/core'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { LoadingFeedback } from 'components'
import { DataTreatmentRow } from './components'
import DialogDataTreatment from '../../DialogDataTreatment'

import { useDataProcess, useSelectCountry } from 'hooks'

const DataTreatments = () => {
  const { data } = useDataProcess()

  const { setManySelectedCountries, setSelectedCountries } = useSelectCountry()
  const [openDataTreatmentModal, setOpenDataTreatmentModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleCreate = () => {
    setManySelectedCountries({})
    setSelectedCountries([])
    setOpenDataTreatmentModal(true)
  }

  return (
    <Card>
      <LoadingFeedback open={loading} />
      <Grid container>
        <Grid item xs={6}>
          <CardHeader title="Com quem os dados são compartilhados?" />
        </Grid>
        <Grid item xs={6}>
          <Box m={1} display="flex" justifyContent="flex-end">
            <Button onClick={handleCreate} variant="contained" size="small">
              <Box pl={1} pt={1}>
                <PlusCircleIcon size="18px" />
              </Box>
              <Box px={1}>
                <Typography variant="h6">ADICIONAR</Typography>
              </Box>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <PerfectScrollbar>
        <Table
          emptyMessage="Nenhum dado compartilhado encontrado"
          size="small"
          noWrap
        >
          <TableHead>
            <TableRow>
              <TableCell width="20%">
                Com quem o dado é compartilhado?
              </TableCell>
              <TableCell width="25%">Porquê o dado é compartilhado?</TableCell>
              <TableCell width="25%">Como o dado é compartilhado</TableCell>
              <TableCell width="20%">Tipo de compartilhamento</TableCell>
              <TableCell width="10%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.dataTreatments.map((dataTreatment) => (
              <DataTreatmentRow
                dataTreatment={dataTreatment}
                setLoading={setLoading}
                key={dataTreatment.id}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <DialogDataTreatment
        dataProcessId={data.id}
        open={openDataTreatmentModal}
        setLoading={setLoading}
        setOpen={setOpenDataTreatmentModal}
      />
    </Card>
  )
}

export default DataTreatments
