import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'

import { X as CloseIcon } from 'react-feather'
import constants from 'constants/index'
import { Select } from 'components'
import { routes } from 'Routes'

import useStyles from './styles'

const SelectCompanyType = ({
  open,
  onClose,
  items,
  testOrTemplate = false,
  typeClient,
}) => {
  const history = useHistory()

  const classes = useStyles()
  const [selectItem, setSelectItem] = useState('')

  const handleChange = (e) => {
    const item = e.target.value
    setSelectItem(item)
  }

  const handleClose = () => {
    setSelectItem('')
    onClose()
  }

  const { KIND_COMPANY, KIND_PERSONAL, KIND_TEMPLATE } = constants.companies

  const moveForwardCreateCompany = (type) => {
    if (
      type === KIND_COMPANY ||
      type === KIND_PERSONAL ||
      type === KIND_TEMPLATE
    ) {
      return history.push(routes.companies.new, { type })
    }

    history.push(routes.preRegistrations.new, { type, typeClient })
  }

  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
      <Box className={classes.boxTitle}>
        <DialogTitle>
          <Typography variant="h5">Selecione o tipo de empresa</Typography>
        </DialogTitle>
        <CloseIcon
          size={24}
          className={classes.closeIcon}
          onClick={handleClose}
        />
      </Box>
      <Divider />
      <DialogContent>
        {testOrTemplate && (
          <Typography className={classes.atention}>
            Atenção: Empresas reais e trial (gratuidade) devem ser criadas na
            tela de clientes presente no modulo de vendas.
          </Typography>
        )}

        <Box my={2}>
          <Select
            items={items}
            onChange={handleChange}
            label="Selecione o tipo de empresa"
          />
          {selectItem && (
            <Box className={classes.dialogActions}>
              <Button
                type="button"
                color="primary"
                variant="contained"
                onClick={() => moveForwardCreateCompany(selectItem)}
              >
                AVANÇAR
              </Button>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

SelectCompanyType.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  testOrTemplate: PropTypes.func,
}

export default SelectCompanyType
