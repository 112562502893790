import { useState } from 'react'
import { Box, Button, Divider, Paper, Typography } from '@material-ui/core'
import { Check as CheckIcon } from 'react-feather'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import { LegalFrameworksChecklist } from './components'
import { DialogApproveRevision } from 'views/DataProcesses/components'

import constants from 'constants/index'
import * as service from 'service'
import useStyles from './styles'

const LegalFrameworksDetails = ({
  changeDataProcessStatus,
  dataProcess,
  legalFrameworks,
  setLoading,
}) => {
  const classes = useStyles()
  const [dialogApproveRevision, setDialogApproveRevision] = useState(false)
  const [selectedLegalFrameworks, setSelectedLegalFrameworks] = useState([])

  const updateLegalFrameworks = async () => {
    await service.dponet.dataProcesses.put({
      dataProcessId: dataProcess?.id,
      dataProcess: {
        legalFrameworks: selectedLegalFrameworks?.map((id) => ({ id })),
      },
    })
    changeDataProcessStatus(constants.dataProcess.APPROVED_STATUS)
  }

  const activePersonalDataType = () =>
    dataProcess.dataCollecteds.some((dataCollected) =>
      dataCollected.collectedFields.some((collectedField) =>
        constants.dataCollected.dataCollectedPersonal.includes(
          collectedField.dataCollectedOption.dataType,
        ),
      ),
    )
  const activePersonalSensitiveDataType = () =>
    dataProcess.dataCollecteds.some((dataCollected) =>
      dataCollected.collectedFields.some((collectedField) =>
        constants.dataCollected.dataCollectedPersonalSensitive.includes(
          collectedField.dataCollectedOption.dataType,
        ),
      ),
    )
  const activeMinorDataType = () =>
    dataProcess.dataCollecteds.some((dataCollected) => {
      return dataCollected.titularAgeRange?.some(
        (ageRange) => ageRange.id === constants.dataCollected.CHILD_ID,
      )
    })

  return (
    <Paper square className={classes.paper}>
      <Box m={2}>
        <Typography variant="h4">Detalhes do enquadramento legal</Typography>
      </Box>
      <Divider />
      <Box m={2}>
        <Box
          p={1}
          mb={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.cardIaMessage}
        >
          <CheckIcon size={24} />
          <Box ml={1}>
            <Typography variant="h5">
              SUGERIDO POR INTELIGÊNCIA ARTIFICIAL
            </Typography>
          </Box>
        </Box>
        <LegalFrameworksChecklist
          activePersonalDataType={activePersonalDataType()}
          activePersonalSensitiveDataType={activePersonalSensitiveDataType()}
          activeMinorDataType={activeMinorDataType()}
          predictions={dataProcess?.predictions}
          selectedLegalFrameworks={selectedLegalFrameworks}
          setSelectedLegalFrameworks={setSelectedLegalFrameworks}
          legalFrameworks={legalFrameworks}
        />
      </Box>
      <Divider />
      {dataProcess?.statusId === constants.dataProcess.REVIEWING_STATUS && (
        <Box my={1.5} mx={2} display="flex" justifyContent="flex-end">
          <Button
            variant="contained"
            color="secondary"
            className={classes.buttonAprove}
            onClick={() => setDialogApproveRevision(true)}
            disabled={isEmpty(selectedLegalFrameworks)}
          >
            Aprovar processo
          </Button>
        </Box>
      )}
      <DialogApproveRevision
        data={dataProcess}
        open={dialogApproveRevision}
        setOpen={setDialogApproveRevision}
        onEvent={updateLegalFrameworks}
        setLoading={setLoading}
      />
    </Paper>
  )
}

LegalFrameworksDetails.propTypes = {
  changeDataProcessStatus: PropTypes.func,
  dataProcess: PropTypes.object,
  legalFrameworks: PropTypes.object,
  setLoading: PropTypes.func,
}

LegalFrameworksDetails.defaultProps = {
  changeDataProcessStatus: () => {},
  dataProcess: {},
  legalFrameworks: {},
  setLoading: () => {},
}

export default LegalFrameworksDetails
