import PropTypes from 'prop-types'
import { TableRow, TableCell } from 'components/Table'
import { MenuButton, Permitted } from 'components'
import { Chip } from '@material-ui/core'
import helpers from 'helpers'
import useStyles from './styles'
import clsx from 'clsx'
import { ActionButtons } from '..'
import constants from 'constants/index'

const PlansTableRow = ({ plan, refresh }) => {
  const classes = useStyles()

  return (
    <>
      <TableRow key={plan.id}>
        <TableCell>{plan.id}</TableCell>
        <TableCell>{plan.code}</TableCell>
        <TableCell>{plan.name}</TableCell>
        <TableCell>
          {constants.integrationPartner.PLATFORM_LABELS[plan?.platform]}
        </TableCell>
        <TableCell>R$ {helpers.formatters.currencyInput(plan.price)}</TableCell>
        <TableCell disableTooltip>
          <Chip
            className={clsx(classes.root, {
              [classes.active]: plan.status,
              [classes.inactive]: !plan.status,
            })}
            align="center"
            size="small"
            label={plan.status ? 'Ativo' : 'Inativo'}
          />
        </TableCell>
        <TableCell disableTooltip align="right">
          <Permitted
            someTags={[
              constants.permissions.PLANS.CREATE,
              constants.permissions.PLANS.DESTROY,
            ]}
          >
            <MenuButton>
              <ActionButtons isEdit plan={plan} refresh={refresh} />
            </MenuButton>
          </Permitted>
        </TableCell>
      </TableRow>
    </>
  )
}

PlansTableRow.propTypes = {
  plan: PropTypes.object,
  refresh: PropTypes.func,
}

PlansTableRow.defaultProps = {
  refresh: () => {},
}

export default PlansTableRow
