import PropTypes from 'prop-types'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

const TableRowCollectedFields = ({ collectedField }) => {
  return (
    <TableRow>
      <TableCell overFlowSize="600px">
        {collectedField.dataCollectedOption.name}
      </TableCell>
      <TableCell>{collectedField.dataCollectedOption.dataTypeName}</TableCell>
    </TableRow>
  )
}

const TableCollectedFields = ({ collectedFields }) => {
  return (
    <Table emptyMessage="Nenhum dado tratado encontrado" noWrap>
      <TableHead>
        <TableRow>
          <TableCell width="75%">Dado</TableCell>
          <TableCell width="25%">Tipo</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {collectedFields.map((collectedField) => (
          <TableRowCollectedFields
            collectedField={collectedField}
            key={collectedField.id}
          />
        ))}
      </TableBody>
    </Table>
  )
}

TableCollectedFields.propTypes = {
  collectedFields: PropTypes.array,
}

TableRowCollectedFields.propTypes = {
  collectedField: PropTypes.object,
}

export default TableCollectedFields
