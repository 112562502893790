import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import useSnackbar from 'hooks/useSnackbar'
import useDataProcess from 'hooks/useDataProcess'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'

import Form from './components/Form'

import * as service from 'service'

import helpers from 'helpers'

import constants from 'constants/index'

const DialogDataLifeCycle = ({
  dataProcessId,
  open,
  setLoading,
  setOpen,
  refresh,
  dataProcess,
  mode,
  dataLifeCycle,
  ...props
}) => {
  const snackbar = useSnackbar()
  const { reloadOptions, reloadDataProcess } = useDataProcess()
  const edit = mode === 'edit'

  const { STORY_MODE_DEFINED_TYPE } = constants.dataProcess

  const valueDataProcess = dataProcess?.value
  const volumetryDataProcess = dataProcess?.volumetry
  const storageMode = dataProcess?.storageMode

  const onSubmit = async (data, watch, setError) => {
    if (storageMode === STORY_MODE_DEFINED_TYPE) {
      let formattedStorageTimeDataProcess =
        helpers.dataProcesses.storageTimeValidation(
          valueDataProcess,
          volumetryDataProcess,
        )

      let formattedStorageTimeLifeCycle =
        helpers.dataProcesses.storageTimeValidation(
          watch('value'),
          watch('volumetry'),
        )

      if (formattedStorageTimeDataProcess < formattedStorageTimeLifeCycle) {
        setError('value', {
          type: {
            invalid:
              'Insira uma data menor do que a informada nas informações gerais do processo',
          },
        })

        setError('volumetry', {
          type: {
            invalid:
              'Insira uma data menor do que a informada nas informações gerais do processo',
          },
        })
        return
      }
    }

    try {
      setLoading(true)
      setOpen(false)
      if (edit) {
        await service.dponet.dataLifeCycles.put({
          dataProcessId: dataProcessId,
          dataLifeCycleId: dataLifeCycle.id,
          lifeCycle: { ...data },
        })
      } else {
        await service.dponet.dataLifeCycles.create({
          dataProcessId,
          lifeCycle: { ...data },
        })
      }
      refresh()
      setLoading(false)
      snackbar.open({
        message: `Salvaguarda e ciclo de vida ${
          edit ? 'editado' : 'criado'
        } com sucesso!`,
        variant: 'success',
      })
      reloadDataProcess()
      reloadOptions()
      setOpen(false)
    } catch (error) {
      setOpen(false)
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      maxWidth="md"
      fullWidth
      {...props}
    >
      <DialogTitle>
        <Box>
          <Typography variant="h4">
            {edit ? 'Editar' : 'Adicionar'} salvaguarda e ciclo de vida
          </Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Form
          onSubmit={onSubmit}
          dataProcessId={dataProcessId}
          dataLifeCycle={dataLifeCycle}
          dataProcess={dataProcess}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Voltar
          </Button>
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          form="data-life-cycle-form"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogDataLifeCycle.propTypes = {
  dataProcessId: PropTypes.number,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
  onEvent: PropTypes.func,
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
  refresh: PropTypes.func,
  dataProcess: PropTypes.object.isRequired,
}

DialogDataLifeCycle.defaultProps = {
  setLoading: () => {},
  setOpen: () => {},
  refresh: () => {},
  dataLifeCycle: {},
  dataProcess: {},
}

export default DialogDataLifeCycle
