import { useState } from 'react'
import useSnackbar from 'hooks/useSnackbar'
import PropTypes from 'prop-types'

import { ConfirmationDialog, LoadingFeedback } from 'components'

import helpers from 'helpers'

import * as service from 'service'

const UserCompanyRemoveLinkingDialog = ({
  currentUserCompany,
  open,
  setOpen,
  refresh,
}) => {
  const snackbar = useSnackbar()

  const [isLoading, setIsloading] = useState(false)

  const noticeText = () => {
    if (!currentUserCompany?.id) return

    return `Você tem certeza que deseja desvincular o usuário 
            ${currentUserCompany?.user?.name} 
            da empresa ${currentUserCompany?.company?.name}? 
            Após confirmar, esse usuário não terá mais acesso à empresa.`
  }

  const onSubmit = async (currentUserCompany) => {
    try {
      setIsloading(true)
      await service.dponet.userCompanies.deleteVinculate({
        userCompanyId: currentUserCompany?.id,
      })

      setIsloading(false)
      snackbar.open({
        message: 'Usuário foi desvinculado com sucesso!',
        variant: 'success',
      })
      setOpen(false)
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Falha ao desvincular usuário!',
        variant: 'error',
      })
      setIsloading(false)
      setOpen(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <ConfirmationDialog
        open={open}
        setOpen={setOpen}
        message={noticeText()}
        buttonText="Confirmar"
        actionAcceptButton={() => onSubmit(currentUserCompany)}
      />
    </>
  )
}

UserCompanyRemoveLinkingDialog.propType = {
  currentUserCompany: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
}
UserCompanyRemoveLinkingDialog.defaultProps = {
  setOpen: () => {},
  refresh: () => {},
}

export default UserCompanyRemoveLinkingDialog
