import { Fragment, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { reverse } from 'named-urls'
import { Button, Chip, TableContainer } from '@material-ui/core'
import clsx from 'clsx'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { ConfirmationDialog, MenuButton } from 'components'

import { useSnackbar } from 'hooks'

import helpers from 'helpers'
import * as service from 'service'
import { routes } from 'Routes'
import useStyles from './styles'
import constants from 'constants/index'

const SubcategoryTable = ({ subcategories, refresh }) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openActivationDialog, setOpenActivationDialog] = useState(false)
  const [openInactivationDialog, setOpenInactivationDialog] = useState(false)

  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()

  const handleDelete = async (subCategoryId) => {
    try {
      await service.dponet.technicalFaqs.destroySubcategory({
        subCategoryId,
      })
      snackbar.open({
        message: `Subcategoria excluída com sucesso!`,
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setOpenDeleteDialog(false)
    }
  }

  const handleChangeStatus = async (subCategoryId, status) => {
    try {
      await service.dponet.technicalFaqs.updateSubcategory({
        subCategoryId,
        status,
      })
      snackbar.open({
        message: `Subcategoria ${helpers.technicalFaqs
          .snackbarStatusTranslation(status, false)
          .toLowerCase()} com sucesso!`,
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setOpenActivationDialog(false)
      setOpenInactivationDialog(false)
    }
  }

  return (
    <TableContainer>
      <PerfectScrollbar>
        <Table emptyMessage="Nenhuma subcategoria encontrada">
          <TableHead>
            <TableRow>
              <TableCell width="5%" align="left">
                ID
              </TableCell>
              <TableCell width="30%" align="left">
                Título
              </TableCell>
              <TableCell width="15%" align="left">
                Descrição
              </TableCell>
              <TableCell width="15%" align="left">
                Criado por
              </TableCell>
              <TableCell width="20%" align="center">
                Última atualização
              </TableCell>
              <TableCell width="10%" align="center">
                Status
              </TableCell>
              <TableCell width="5%" align="left">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subcategories?.map((subcategory) => (
              <TableRow key={subcategory?.id}>
                <TableCell>{subcategory?.id}</TableCell>
                <TableCell overFlowSize="300px">{subcategory?.title}</TableCell>
                <TableCell overFlowSize="200px">
                  {subcategory?.description}
                </TableCell>
                <TableCell overFlowSize="150px">
                  {subcategory?.author?.name}
                </TableCell>
                <TableCell align="center">
                  {helpers.formatters.dateTime(subcategory?.updatedAt)}
                </TableCell>
                <TableCell align="center" disableTooltip>
                  <Chip
                    align="center"
                    size="small"
                    label={helpers.technicalFaqs.statusTranslation(
                      subcategory?.status,
                    )}
                    className={clsx(classes.root, classes.chip, {
                      [classes.active]:
                        subcategory?.status ===
                        constants.technicalFaqs.ACTIVE_STATUS,
                      [classes.inactive]:
                        subcategory?.status ===
                        constants.technicalFaqs.INACTIVE_STATUS,
                    })}
                  />
                </TableCell>
                <TableCell disableTooltip>
                  <MenuButton>
                    <Button
                      size="small"
                      onClick={() =>
                        history.push(
                          reverse(routes.technicalFaqs.editSubcategory, {
                            subCategoryId: subcategory?.id,
                          }),
                        )
                      }
                    >
                      Editar
                    </Button>
                    {subcategory?.status ===
                      constants.technicalFaqs.INACTIVE_STATUS && (
                      <Fragment>
                        <Button
                          size="small"
                          onClick={() => setOpenActivationDialog(true)}
                        >
                          Ativar
                        </Button>
                        <ConfirmationDialog
                          open={openActivationDialog}
                          setOpen={setOpenActivationDialog}
                          message="Deseja realmente ativar a Subcategoria?"
                          cancelButtonText="Cancelar"
                          buttonText="Ativar"
                          actionAcceptButton={() =>
                            handleChangeStatus(
                              subcategory?.id,
                              constants.technicalFaqs.ACTIVE_STATUS,
                            )
                          }
                        />
                      </Fragment>
                    )}
                    {subcategory?.status ===
                      constants.technicalFaqs.ACTIVE_STATUS && (
                      <Fragment>
                        <Button
                          size="small"
                          onClick={() => setOpenInactivationDialog(true)}
                        >
                          Inativar
                        </Button>
                        <ConfirmationDialog
                          open={openInactivationDialog}
                          setOpen={setOpenInactivationDialog}
                          message="Deseja realmente inativar a Subcategoria?"
                          subMessage="Lembre-se de que você pode reativá-la posteriormente, caso necessário."
                          cancelButtonText="Cancelar"
                          buttonText="Inativar"
                          actionAcceptButton={() =>
                            handleChangeStatus(
                              subcategory?.id,
                              constants.technicalFaqs.INACTIVE_STATUS,
                            )
                          }
                        />
                      </Fragment>
                    )}
                    <Button
                      size="small"
                      onClick={() => setOpenDeleteDialog(true)}
                    >
                      Excluir
                    </Button>
                    <ConfirmationDialog
                      open={openDeleteDialog}
                      setOpen={setOpenDeleteDialog}
                      message="Deseja realmente excluir a Subcategoria?"
                      alert={
                        <div>
                          Esta ação irá excluir todas as FAQs vinculadas a esta
                          subcategoria
                          <br />
                          VOCÊ TEM CERTEZA QUE DESEJA FAZER ISSO?
                          <br />
                          Lembre-se de que esta ação é irreversível!
                        </div>
                      }
                      cancelButtonText="Cancelar"
                      buttonText="Excluir"
                      actionAcceptButton={() => handleDelete(subcategory?.id)}
                    />
                  </MenuButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </TableContainer>
  )
}

SubcategoryTable.propTypes = {
  subcategories: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func,
}

export default SubcategoryTable
