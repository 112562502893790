import { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import { useCompany } from 'hooks'
import PropTypes from 'prop-types'

import { Grid, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Card } from 'components'

const SegmentationForm = ({
  control,
  errors,
  group,
  subgroup,
  setValue,
  setLoading,
  editionPermitted,
}) => {
  const {
    companySizes,
    groups,
    segments,
    subgroups,
    loadSubgroups,
    loadSegments,
    isLoading,
  } = useCompany()

  useEffect(() => {
    if (group || subgroup) {
      setLoading(true)

      if (group) {
        loadSubgroups(group?.id)
      }

      if (subgroup) {
        loadSegments(subgroup?.id)
      }
      setTimeout(() => {
        setLoading(false)
      }, 200)
    }

    // eslint-disable-next-line
  }, [group, subgroup])

  const handleChangeGroup = (data) => {
    setLoading(true)
    loadSubgroups(data?.id)

    setValue('subgroupId', { id: 0, name: '' })
    setValue('segmentId', { id: 0, name: '' })
    setTimeout(() => {
      setLoading(false)
    }, 200)

    return data
  }

  const handleChangeSubgroup = (data) => {
    setLoading(true)
    loadSegments(data?.id)
    setValue('segmentId', { id: 0, name: '' })
    setTimeout(() => {
      setLoading(false)
    }, 200)

    return data
  }

  return (
    <Card title="Segmentação da empresa">
      {!isLoading && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="groupId"
              onChange={([, data]) => handleChangeGroup(data)}
              mode="onChange"
              as={
                <Autocomplete
                  options={groups || []}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) =>
                    option.id === value.id || value.id === 0
                  }
                  disabled={!editionPermitted}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecione o grupo a qual a empresa pertence"
                      variant="outlined"
                      error={!!errors.groupId}
                      helperText={errors?.groupId?.message}
                    />
                  )}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="companySizeId"
              onChange={([, data]) => data}
              mode="onChange"
              as={
                <Autocomplete
                  options={companySizes || []}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) =>
                    option.id === value.id || value.id === 0
                  }
                  disabled={!editionPermitted}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Selecione o tamanho da empresa"
                      variant="outlined"
                      error={!!errors.companySizeId}
                      helperText={errors?.companySizeId?.message}
                    />
                  )}
                />
              }
            />
          </Grid>
          {subgroups && (
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="subgroupId"
                onChange={([, data]) => handleChangeSubgroup(data)}
                mode="onChange"
                as={
                  <Autocomplete
                    options={subgroups || []}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.id === value.id || value.id === 0
                    }
                    disabled={!editionPermitted}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione o subgrupo ao qual a empresa pertence"
                        variant="outlined"
                        error={!!errors.subgroupId}
                        helperText={errors?.subgroupId?.message}
                      />
                    )}
                  />
                }
              />
            </Grid>
          )}
          {segments && (
            <Grid item xs={12} md={6}>
              <Controller
                control={control}
                name="segmentId"
                onChange={([, data]) => data}
                mode="onChange"
                as={
                  <Autocomplete
                    options={segments || []}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.id === value.id || value.id === 0
                    }
                    disabled={!editionPermitted}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Selecione o segmento ao qual a empresa pertence"
                        variant="outlined"
                        error={!!errors.segmentId}
                        helperText={errors?.segmentId?.message}
                      />
                    )}
                  />
                }
              />
            </Grid>
          )}
        </Grid>
      )}
    </Card>
  )
}

SegmentationForm.prototype = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  group: PropTypes.object,
  subgroup: PropTypes.object,
  setValue: PropTypes.func,
  setLoading: PropTypes.func,
  editionPermitted: PropTypes.bool,
}

SegmentationForm.defaultProps = {
  setValue: () => {},
  setLoading: () => {},
}

export default SegmentationForm
