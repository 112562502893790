import { orderBy } from 'lodash'

const separateCompaniesByKinds = (companies, kinds) => {
  const filteredCompanies = companies.filter((company) =>
    kinds.includes(company?.kind),
  )

  return orderBy(
    filteredCompanies.map((company) => ({
      id: company?.id,
      name: `${company?.name} - ${company?.document}`,
    })),
    ['name'],
    ['asc'],
  )
}

export default separateCompaniesByKinds
