import { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { routes } from 'Routes'

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { BackButton, Card, LoadingFeedback } from 'components'

import { useSnackbar } from 'hooks'

import helpers from 'helpers'
import constants from 'constants/index'
import * as service from 'service'
import schema from './schema'

const SupplierForm = () => {
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [loadingCompanies, setLoadingCompanies] = useState(false)
  const [companies, setCompanies] = useState(false)
  const [companiesInput, setCompaniesInput] = useState('')
  const { instructionsText, loadCompanies } = helpers.companies.typeAndSearch
  const history = useHistory()

  useEffect(() => {
    const getCompanies = setTimeout(async () => {
      if (companiesInput.length < 3) return
      loadCompanies(
        {
          active: true,
          perPage: 1000 * 1000,
          name: companiesInput,
          kinds: [
            constants.companies.KINDS.COMPANY,
            constants.companies.KINDS.PERSONAL,
          ],
        },
        setLoadingCompanies,
        setCompanies,
      )
    }, 1000)

    return () => clearTimeout(getCompanies)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesInput])

  const { errors, control, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: {
      company: null,
      name: '',
      document: '',
      email: '',
    },
  })

  const onSubmit = async (supplier) => {
    try {
      const companyId = supplier?.company?.id
      setLoading(true)
      if (supplier.document.length > 14) {
        supplier.cnpj = supplier.document
      } else {
        supplier.cpf = supplier.document
      }
      delete supplier.document
      delete supplier?.company

      await service.dponet.companySuppliers.create({
        companySupplier: {
          companyId,
          supplier,
        },
      })
      snackbar.open({
        message: 'Novo fornecedor criado com sucesso.',
        variant: 'success',
      })
      history.push(routes.suppliers.all)
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || 'Ocorreu um erro ao criar um novo fornecedor.',
        variant: 'error',
      })
    }
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <Card title="Dados básicos">
        <Box py={1}>
          <form id="supplierForm" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="company"
                  onChange={([, data]) => data}
                  mode="onBlur"
                  as={
                    <Autocomplete
                      options={companies || []}
                      getOptionLabel={(option) => option?.name || ''}
                      getOptionSelected={(option, value) =>
                        option?.id === value?.id || null
                      }
                      noOptionsText={instructionsText(companiesInput)}
                      loading={loadingCompanies}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => setCompaniesInput(e.target.value)}
                          error={!!errors.company}
                          helperText={errors?.company?.message}
                          label="Empresa contratante"
                          variant="outlined"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {loadingCompanies ? (
                                  <CircularProgress color="primary" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      label="Nome do fornecedor"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="name"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      label="CNPJ/CPF"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.document}
                      helperText={errors?.document?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  onChange={([e]) => {
                    return helpers.formatters.documentFormatter(e.target.value)
                  }}
                  name="document"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      label="E-mail de um responsável do fornecedor"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.email}
                      helperText={errors?.email?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="email"
                  mode="onBlur"
                />
              </Grid>
            </Grid>
          </form>
        </Box>
      </Card>
      <Box mt={3} display="flex" justifyContent="flex-start">
        <Box pr={4}>
          <BackButton
            disabled={loading}
            onClick={() => history.push(routes.suppliers.all)}
          >
            Voltar
          </BackButton>
        </Box>
        <Box>
          <Button
            disabled={loading}
            form="supplierForm"
            color="primary"
            variant="contained"
            type="submit"
          >
            Salvar
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default SupplierForm
