import PropTypes from 'prop-types'
import { Box, Grid, Typography, Tooltip, makeStyles } from '@material-ui/core'

import clsx from 'clsx'

import styles from './styles'

const useStyles = makeStyles(styles)

const Label = ({
  children,
  ellipsis,
  title,
  description,
  className,
  titleVariant,
  marginTopChildren,
  padBuTitle,
  tooltip = true,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Grid
      {...rest}
      className={clsx(
        {
          [classes.ellipsis]: ellipsis,
        },
        classes.root,
      )}
    >
      <Box pb={padBuTitle}>
        <Typography variant={titleVariant} color="textPrimary">
          {title}
        </Typography>
      </Box>
      {tooltip ? (
        <Tooltip noWrap title={description} placement="bottom-start">
          <Typography variant="caption" color="textSecondary">
            {description}
          </Typography>
        </Tooltip>
      ) : (
        <Typography variant="caption" color="textSecondary">
          {description}
        </Typography>
      )}

      <Box mt={marginTopChildren}>{children}</Box>
    </Grid>
  )
}

Label.propTypes = {
  tooltip: PropTypes.bool,
  children: PropTypes.element,
  ellipsis: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  titleVariant: PropTypes.string,
  marginTopChildren: PropTypes.number,
  padBuTitle: PropTypes.number,
}

Label.defaultProps = {
  title: '',
  description: '',
  className: '',
  titleVariant: 'subtitle2',
  marginTopChildren: 0,
  padBuTitle: 0,
}

export default Label
