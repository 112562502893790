const selectVolumetryOptions = () => {
  return [
    { id: 'minutos', name: 'Minutos' },
    { id: 'horas', name: 'Horas' },
    { id: 'dias', name: 'Dias' },
    { id: 'meses', name: 'Meses' },
    { id: 'anos', name: 'Anos' },
  ]
}

export default selectVolumetryOptions
