import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box } from '@material-ui/core'

import LastUpdatesTableRow from '../LastUpdatesTableRow'
import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'

const LastUpdatesTable = ({ lastUpdates, refresh }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhuma atualização encontrada" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="30%" align="left">
                Título
              </TableCell>
              <TableCell width="30%" align="left">
                Subtítulo
              </TableCell>
              <TableCell width="10%" align="left">
                Publicado por
              </TableCell>
              <TableCell width="10%" align="left">
                Tipo
              </TableCell>
              <TableCell width="10%" align="center">
                Última atualização
              </TableCell>
              <TableCell width="5%" align="left">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {lastUpdates?.map((lastUpdate) => (
              <LastUpdatesTableRow
                lastUpdate={lastUpdate}
                refresh={refresh}
                key={lastUpdate.id}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

LastUpdatesTable.propTypes = {
  lastUpdates: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func,
}

LastUpdatesTable.defaultProps = {
  refresh: () => {},
}

export default LastUpdatesTable
