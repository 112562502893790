import PropTypes from 'prop-types'
import { TableRow, TableCell } from 'components/Table'
import { MenuButton, Permitted } from 'components'
import { Chip, Button } from '@material-ui/core'
import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from './styles'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { routes } from 'Routes'
import RemoveSegment from '../RemoveSegment'

const SegmentsTableRow = ({ segment, refresh }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <TableRow key={segment.id}>
        <TableCell align="left">{segment?.id}</TableCell>
        <TableCell align="center">{segment?.name}</TableCell>
        <TableCell align="left">{segment?.subgroup?.name}</TableCell>
        <TableCell disableTooltip align="center">
          <Chip
            className={clsx(classes.root, {
              [classes.active]: segment.active,
              [classes.inactive]: !segment.active,
            })}
            align="center"
            size="small"
            label={segment.active ? 'Ativo' : 'Inativo'}
          />
        </TableCell>
        <TableCell align="center">
          {segment?.inactivateAt
            ? helpers.formatters.date(segment?.inactivateAt)
            : ' - '}
        </TableCell>
        <TableCell disableTooltip align="right">
          <Permitted
            someTags={[
              constants.permissions.SEGMENTS.EDIT,
              constants.permissions.SEGMENTS.DESTROY,
            ]}
          >
            <MenuButton>
              <Permitted tag={constants.permissions.SEGMENTS.EDIT}>
                <Button
                  fullWidth
                  size="small"
                  onClick={() =>
                    history.push(
                      reverse(routes.segments.edit, {
                        segmentId: segment?.id,
                      }),
                    )
                  }
                >
                  Editar
                </Button>
              </Permitted>
              <Permitted tag={constants.permissions.SEGMENTS.DESTROY}>
                <RemoveSegment segmentId={segment?.id} refresh={refresh} />
              </Permitted>
            </MenuButton>
          </Permitted>
        </TableCell>
      </TableRow>
    </>
  )
}

SegmentsTableRow.propTypes = {
  segment: PropTypes.object,
  refresh: PropTypes.func,
}

SegmentsTableRow.defaultProps = {
  refresh: () => {},
}

export default SegmentsTableRow
