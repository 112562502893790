const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
  floatingButton: {
    margin: 0,
    top: 'auto',
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  contentContainerMarginReduce: {
    marginTop: '-4px !important',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-8px !important',
    },
  },
})

export default styles
