import { useState, useEffect } from 'react'

import { Filters } from 'components'

import * as service from 'service'

const SubgroupFilter = ({ filter }) => {
  const [groups, setGroups] = useState([])
  const [isLoadingGroups, setIsLoadingGroups] = useState(false)

  const { drawerOpen } = filter

  useEffect(() => {
    const getGroups = async () => {
      setIsLoadingGroups(true)

      const groupsResponse = await service.dponet.groups.get({
        perPage: 1000 * 1000,
        order: 'name ASC',
      })

      setGroups(groupsResponse?.data?.groups)
      setIsLoadingGroups(false)
    }

    if (drawerOpen && groups.length === 0) getGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen])

  return (
    <Filters filter={filter} isLoading={isLoadingGroups}>
      <input textfieldinput="true" label="Identificador" name="id" />
      <input textfieldinput="true" label="Nome do subgrupo" name="name" />
      <select
        autocompleteselectinput="true"
        name="groupId"
        label="Grupo"
        optionvalues={groups}
      />
    </Filters>
  )
}

export default SubgroupFilter
