import constants from 'constants/index'

const checkHighOrSevereFragility = (fragility) => {
  return [
    constants.nonComplianceReports.SEVERE_FRAGILITY_STATUS,
    constants.nonComplianceReports.HIGH_FRAGILITY_STATUS,
  ].includes(fragility)
}

export default checkHighOrSevereFragility
