import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ ...params }) => {
  return await dponetAPI.get('/subgroups', { params })
}

const show = async ({ subgroupId = '' }) => {
  return await dponetAPI.get(`/subgroups/${subgroupId}`)
}

const post = async (data) => {
  return await dponetAPI.post('/subgroups', data)
}

const put = async ({ subgroupId = '', ...data }) => {
  return await dponetAPI.put(`/subgroups/${subgroupId}`, data)
}

const destroy = async ({ subgroupId }) => {
  return await dponetAPI.delete(`/subgroups/${subgroupId}`)
}

const subgroups = {
  get,
  show,
  post,
  put,
  destroy,
}

export default subgroups
