import { useState } from 'react'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import {
  Card,
  CardHeader,
  Divider,
  Button,
  Box,
  Grid,
  Typography,
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { DataCollectedRow } from './components'
import { DialogDataCollected } from '../../DialogDataCollected'

import useDataProcess from 'hooks/useDataProcess'
import { LoadingFeedback } from 'components'

const DataCollecteds = () => {
  const [dialogDataCollected, setDialogDataCollected] = useState(false)
  const [loading, setLoading] = useState(false)
  const { data: dataProcess } = useDataProcess()

  return (
    <Card>
      <LoadingFeedback open={loading} />
      <Grid container>
        <Grid item xs={6}>
          <CardHeader title="Dados Tratados" />
        </Grid>
        <Grid item xs={6}>
          <Box m={1} display="flex" justifyContent="flex-end">
            <Button
              type="button"
              variant="contained"
              size="small"
              onClick={() => setDialogDataCollected(true)}
            >
              <Box pl={1} pt={1}>
                <PlusCircleIcon size="18px" />
              </Box>
              <Box px={1}>
                <Typography variant="h6">ADICIONAR</Typography>
              </Box>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <PerfectScrollbar>
        <Table
          emptyMessage="Nenhum dado tratado encontrado"
          size="small"
          noWrap
        >
          <TableHead>
            <TableRow>
              <TableCell width="35%">Categoria do titular</TableCell>
              <TableCell width="35%">Faixa Etária</TableCell>
              <TableCell width="20%">
                Quantidade aproximada de titulares
              </TableCell>
              <TableCell width="10%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataProcess?.dataCollecteds.map((dataCollected, index) => (
              <DataCollectedRow
                dataCollected={dataCollected}
                setLoading={setLoading}
                key={dataCollected.id}
                index={index}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <DialogDataCollected
        dataProcessId={dataProcess?.id}
        open={dialogDataCollected}
        setOpen={setDialogDataCollected}
        setLoading={setLoading}
        dataCollected={{}}
      />
    </Card>
  )
}

DataCollecteds.propTypes = {
  refresh: PropTypes.func,
}

export default DataCollecteds
