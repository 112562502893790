import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons'

import useStyles from './styles'

const AccordionCheckboxList = ({
  title,
  items,
  selectedItems,
  handleSelectAll,
  handleSelectSingle,
  allLabel,
  getLabel,
  getItemId,
}) => {
  const classes = useStyles()

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(event) => handleSelectAll(event.target.checked)}
                checked={selectedItems.length === items?.length}
              />
            }
            label={allLabel}
          />
          {items?.map((item) => {
            const itemId = getItemId(item)
            const itemLabel = getLabel(item)

            return (
              <FormControlLabel
                key={item?.id}
                control={
                  <Checkbox
                    onChange={(event) =>
                      handleSelectSingle(event.target.checked, itemId)
                    }
                    checked={selectedItems.includes(itemId)}
                  />
                }
                label={itemLabel}
              />
            )
          })}
        </>
      </AccordionDetails>
    </Accordion>
  )
}

export default AccordionCheckboxList
