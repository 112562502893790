import Main from './CompaniesMain'
import New from './CompaniesNew'
import Edit from './CompaniesEdit'

const Companies = {
  Main,
  New,
  Edit,
}

export default Companies
