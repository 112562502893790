import { colors, makeStyles } from '@material-ui/core'
import palette from 'theme/palette'

const styles = makeStyles((theme) => ({
  borderBottom: {
    borderBottom: `1px solid ${palette.secondary.superLight}`,
  },
  blueContainer: {
    backgroundColor: theme.palette.primary.main,
  },
  whiteContainer: {
    backgroundColor: theme.palette.common.white,
  },
  containerSpacing: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(12),
    },
  },
  buttonOptions: {
    backgroundColor: colors.green[500],
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: colors.green[500],
    },
  },
}))

export default styles
