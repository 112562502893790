import constants from 'constants/index'

const statusName = (status) => {
  if (status) {
    return constants.documentationLinks.ACTIVE_STATUS_LABEL
  }
  return constants.documentationLinks.INACTIVE_STATUS_LABEL
}

export default statusName
