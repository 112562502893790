import constants from 'constants/index'

const StatusColor = (status, classes) => {
  switch (status) {
    case constants.question.ANSWERED_STATUS:
      return classes.answered
    case constants.question.INACTIVE_STATUS:
      return classes.inactive
    default:
      return classes.pending
  }
}

export default StatusColor
