export const onlyNumbers = (value) => {
  return value.replace(/\D/g, '')
}

export const onlyLetters = (value) => {
  return value.replace(/[^a-zA-Z0-9 ]/g, '')
}

export const removeSpaces = (value) => {
  return value.replace(/\s+/g, '')
}

const regex = {
  onlyNumbers,
  onlyLetters,
  removeSpaces,
}

export default regex
