import { useFetch } from 'hooks'

import { ShowPayment } from './components'
import {
  Container,
  ContentHeader,
  Page,
  LoadingFeedback,
  MenuButton,
  ButtonLogs,
} from 'components'

import * as service from 'service'

const PaymentOrdersShow = ({ match }) => {
  const {
    params: { paymentOrderId },
  } = match

  const { response, isLoading } = useFetch(
    service.dponet.paymentOrders.show,
    { paymentOrderId },
    [],
  )

  return (
    <Page title="Exibir ordem de pagamento">
      <LoadingFeedback open={isLoading} />
      <Container maxWidth={false}>
        <ContentHeader
          menu="Ordem de pagamento"
          title="Exibir ordem de pagamento"
          subtitle="Exibir ordem de pagamento"
        >
          <MenuButton>
            <ButtonLogs routes="payment_orders" entityId={paymentOrderId} />
          </MenuButton>
        </ContentHeader>
        {!isLoading && (
          <ShowPayment paymentOrder={response?.data?.paymentOrder} />
        )}
      </Container>
    </Page>
  )
}

export default PaymentOrdersShow
