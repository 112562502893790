import { Chip } from '@material-ui/core'
import clsx from 'clsx'

import paymentHelper from 'helpers/paymentOrders'

import useStyles from './styles'

const ChipPaymentOrder = ({ paymentOrder }) => {
  const classes = useStyles()

  const classColor = clsx(
    classes.chip,
    paymentHelper.chipStatusColor(paymentOrder?.status, classes),
  )

  return (
    <Chip
      size="small"
      className={classColor}
      label={paymentHelper.chipStatusLabel(paymentOrder?.status)}
    />
  )
}

export default ChipPaymentOrder
