import chipStatusColor from './chipStatusColor'
import chipStatusLabel from './chipStatusLabel'
import mountReal from './mountReal'
import mountTrial from './mountTrial'
import defaultValues from './defaultValues'
import billingDayText from './billingDayText'
import typeCompany from './typeCompany'
import tableGrid from './tableGrid'

const preRegistrations = {
  chipStatusColor,
  chipStatusLabel,
  defaultValues,
  mountReal,
  mountTrial,
  billingDayText,
  typeCompany,
  tableGrid,
}

export default preRegistrations
