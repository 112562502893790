import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  mainBox: {
    backgroundColor: theme.palette.background.default,
    height: '100% !important',
  },
}))

export default styles
