import constants from 'constants/companies'

const statusLabel = (status, isEasy) => {
  if (isEasy) {
    switch (status) {
      case constants.STATUSES.ACTIVE:
        return constants.STATUSES_LABELS[constants.STATUSES.ACTIVE]
      case constants.STATUSES.INACTIVE:
        return 'Em implementação'
      default:
        return constants.STATUSES_LABELS[status]
    }
  }

  return constants.STATUSES_LABELS[status]
}

export default statusLabel
