import { isUndefined } from 'lodash'

import * as service from 'service'

const listCache = (params, options = {}) => {
  const keys = isUndefined(params)
    ? ['companies', 'list']
    : ['companies', 'list', params]

  const queryFn = () =>
    service.dponet.companies.get(params).then((response) => response.data)

  return { queryKey: keys, queryFn: queryFn, exact: false, ...options }
}

const viewCache = (id) => {
  const keys = ['companies', 'view', parseInt(id)]
  const queryFn = () =>
    service.dponet.companies
      .get({ companyId: id })
      .then((response) => response.data)

  return { queryKey: keys, queryFn: queryFn, exact: true }
}

const logsCache = (id, options = {}) => {
  const keys = ['companies', 'logs', parseInt(id)]
  const queryFn = () =>
    service.dponet.companies
      .logs({ companyId: id })
      .then((response) => response.data)

  return {
    queryKey: keys,
    exact: true,
    queryFn: queryFn,
    ...options,
  }
}
const listWithCompanyAuditsCache = (params) => {
  const keys = ['companies', 'list', 'withCompanyAudits', params]
  const queryFn = () =>
    service.dponet.companies
      .listWithCompanyAudits(params)
      .then((response) => response.data)

  return { queryKey: keys, queryFn: queryFn, exact: false }
}

const updateCache = (id) => {
  if (isUndefined(id)) return [listCache()]

  return [listCache(), viewCache(id), logsCache(id)]
}

const companies = {
  listCache,
  logsCache,
  updateCache,
  viewCache,
  listWithCompanyAuditsCache,
}

export default companies
