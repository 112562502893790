import { Card } from 'components'
import { Table, TableBody, TableCell, TableRow } from 'components/Table'
import { Switch, Typography } from '@material-ui/core'

import PropTypes from 'prop-types'

const BasePermissionsForm = ({
  title,
  permissionsList,
  register,
  noOverFlow,
  permissions,
  handleChange,
  activeProfile,
  isEdit,
}) => {
  const handleSwitchOnChange = (event, permission) => {
    if (activeProfile || !isEdit) {
      handleChange(event.target.checked, permission.tag)
    }
  }

  return (
    <Card dropdown title={title}>
      <Table>
        <TableBody>
          {permissionsList?.map((permission) => (
            <TableRow key={permission.tag}>
              <TableCell width="90%" align="left" noOverFlow={noOverFlow}>
                {permission.name}
                {permission?.description && (
                  <Typography variant="body2">
                    {permission?.description}
                  </Typography>
                )}
              </TableCell>
              <TableCell width="10%" align="left" disableTooltip>
                <Switch
                  color="primary"
                  name={`permissions[${permission.tag}]`}
                  inputRef={register}
                  checked={permissions[permission.tag] ?? true}
                  onChange={(event) => handleSwitchOnChange(event, permission)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  )
}

BasePermissionsForm.propTypes = {
  title: PropTypes.string,
  permissionsList: PropTypes.array,
  register: PropTypes.func,
  noOverFlow: PropTypes.bool,
  permissions: PropTypes.object,
  handleChange: PropTypes.func,
  activeProfile: PropTypes.bool,
}

BasePermissionsForm.defaultProps = {
  permissions: {},
  handleChange: () => {},
}

export default BasePermissionsForm
