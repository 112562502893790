const AWAITING_RESPONSE_STATUS_NUMBER = 0
const PARTIALLY_ANSWERED_STATUS_NUMBER = 1
const ANSWERED_STATUS_NUMBER = 2

const AWAITING_RESPONSE_STATUS = 'awaiting_response'
const PARTIALLY_ANSWERED_STATUS = 'partially_answered'
const ANSWERED_STATUS = 'answered'

const AWAITING_RESPONSE_LABEL = 'Aguardando Resposta'
const PARTIALLY_ANSWERED_LABEL = 'Parcialmente respondido'
const ANSWERED_LABEL = 'Respondido'

const profile = {
  AWAITING_RESPONSE_STATUS_NUMBER,
  PARTIALLY_ANSWERED_STATUS_NUMBER,
  ANSWERED_STATUS_NUMBER,
  AWAITING_RESPONSE_STATUS,
  PARTIALLY_ANSWERED_STATUS,
  ANSWERED_STATUS,
  AWAITING_RESPONSE_LABEL,
  PARTIALLY_ANSWERED_LABEL,
  ANSWERED_LABEL,
}

export default profile
