import PropTypes from 'prop-types'

import {
  Box,
  TextField,
  MenuItem,
  Typography,
  Tooltip,
  CircularProgress,
} from '@material-ui/core'

import useStyles from './styles'

const Select = ({
  items,
  anotherOption = false,
  otherValue = null,
  loading,
  label,
  disabled,
  startIcon = null,
  actionStartIcon = () => {},
  ...rest
}) => {
  const classes = useStyles()
  return (
    <TextField
      select
      fullWidth
      label={label}
      variant="outlined"
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <>
            {loading ? (
              <Box mr={3}>
                <CircularProgress color="primary" size={20} />
              </Box>
            ) : null}
          </>
        ),
        startAdornment: (
          <>
            {startIcon && (
              <Box
                mr={3}
                onClick={actionStartIcon}
                className={classes.boxStartIcon}
              >
                {startIcon}
              </Box>
            )}
          </>
        ),
      }}
      {...rest}
    >
      {items.map((item) => (
        <MenuItem key={item?.id} value={item?.id}>
          <Box width="100%">
            <Tooltip title={item?.name || ''} placement="top">
              <Typography>{item?.name}</Typography>
            </Tooltip>
          </Box>
        </MenuItem>
      ))}
      {anotherOption && (
        <MenuItem key="another" value={otherValue ?? 'another'}>
          Outro
        </MenuItem>
      )}
    </TextField>
  )
}

Select.propTypes = {
  anotherOption: PropTypes.bool,
  items: PropTypes.array,
  loading: PropTypes.bool,
  label: PropTypes.string,
  otherValue: PropTypes.string,
  startIcon: PropTypes.node,
  actionStartIcon: PropTypes.func,
}

export default Select
