import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ ...params }) => {
  return await dponetAPI.get(`/discount_coupons`, { params })
}

const create = async (data) => {
  return await dponetAPI.post(`/discount_coupons`, data)
}

const show = async ({ couponId = '' }) => {
  return await dponetAPI.get(`/discount_coupons/${couponId}`)
}

const update = async ({ couponId = '', ...data }) => {
  return await dponetAPI.put(`/discount_coupons/${couponId}`, data)
}

const logs = async ({ couponId = '', ...data }) => {
  return await dponetAPI.get(`/discount_coupons/${couponId}/logs`, data)
}

const destroy = async ({ couponId = '' }) => {
  return await dponetAPI.delete(`/discount_coupons/${couponId}`)
}

const coupons = {
  get,
  create,
  show,
  update,
  logs,
  destroy,
}

export default coupons
