const styles = (theme) => ({
  menuBox: {
    marginBottom: theme.spacing(1),
  },
  h4: {
    fontSize: '24px',
    fontWeight: 600,
  },
  title: {
    color: theme.palette.text.titleTable,
  },
  menu: {
    color: theme.palette.text.subtitle2,
  },
  filters: {
    paddingTop: '0px !important',
  },
  menuNavigation: {
    color: theme.palette.text.subtitle2,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
})

export default styles
