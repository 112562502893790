import PropTypes from 'prop-types'
import { TableRow, TableCell } from 'components/Table'
import { MenuButton, Permitted } from 'components'
import RemoveCoupon from '../RemoveCoupon'
import { Chip, Button } from '@material-ui/core'
import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from './styles'
import clsx from 'clsx'

import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { routes } from 'Routes'

const CouponsTableRow = ({ coupon, refresh }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <TableRow key={coupon.id}>
        <TableCell align="center">{coupon?.id}</TableCell>
        <TableCell align="center">{coupon?.name}</TableCell>
        <TableCell align="center">{coupon?.code}</TableCell>
        <TableCell align="center">
          {helpers.discountCoupon.discountType(coupon?.discountType)}
        </TableCell>
        <TableCell align="center">
          {helpers.discountCoupon.discountValue(
            coupon?.discountType,
            coupon?.discountValue,
          )}
        </TableCell>
        <TableCell align="center">{coupon?.seller?.name || ''}</TableCell>
        <TableCell align="center" disableTooltip>
          <Chip
            className={clsx(classes.root, {
              [classes.active]: coupon.status,
              [classes.inactive]: !coupon.status,
            })}
            align="center"
            size="small"
            label={coupon.status ? 'Ativo' : 'Inativo'}
          />
        </TableCell>
        <TableCell align="center">
          {helpers.formatters.date(coupon?.createdAt)}
        </TableCell>
        <TableCell disableTooltip align="right">
          <Permitted
            someTags={[
              constants.permissions.COUPONS.CREATE,
              constants.permissions.COUPONS.DESTROY,
            ]}
          >
            <MenuButton>
              <Permitted tag={constants.permissions.COUPONS.CREATE}>
                <Button
                  fullWidth
                  size="small"
                  onClick={() =>
                    history.push(
                      reverse(routes.coupons.edit, {
                        couponId: coupon?.id,
                      }),
                    )
                  }
                >
                  Editar
                </Button>
              </Permitted>
              <Permitted tag={constants.permissions.COUPONS.DESTROY}>
                <RemoveCoupon refresh={refresh} couponId={coupon?.id} />
              </Permitted>
            </MenuButton>
          </Permitted>
        </TableCell>
      </TableRow>
    </>
  )
}

CouponsTableRow.propTypes = {
  coupon: PropTypes.object,
  refresh: PropTypes.func,
}

CouponsTableRow.defaultProps = {
  refresh: () => {},
}

export default CouponsTableRow
