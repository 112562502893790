import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, TextField, Grid } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { Autocomplete } from '@material-ui/lab'

import {
  BackButton,
  Card,
  LoadingFeedback,
  Select as SelectComponent,
} from 'components'

import { useCompany, useSnackbar } from 'hooks'

import * as service from 'service'

import helpers from 'helpers'
import schema from './schema'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

const SegmentsForm = ({ segment, isEdit }) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const snackbar = useSnackbar()
  const group = segment?.subgroup?.group
  const subgroup = segment?.subgroup
  const { groups, subgroups, loadSubgroups } = useCompany()
  const statusItems = [
    { id: 'true', name: 'Ativo' },
    { id: 'false', name: 'Inativo' },
  ]

  const { control, handleSubmit, errors, reset, setValue } = useForm({
    validationSchema: schema(),
    defaultValues: {
      name: segment?.name || '',
      group: {
        id: segment?.subgroup?.group?.id,
        name: segment?.subgroup?.group?.name,
      } || { id: 0, name: '' },
      subgroup: {
        id: segment?.subgroup?.id,
        name: segment?.subgroup?.name,
      } || { id: 0, name: '' },
      active: segment ? segment?.active?.toString() : '',
    },
  })

  useEffect(() => {
    if (group || subgroup) {
      setLoading(true)

      if (group) {
        loadSubgroups(group?.id)
      }

      setTimeout(() => {
        setLoading(false)
      }, 200)
    }

    // eslint-disable-next-line
  }, [group])

  const handleChangeGroup = (data) => {
    setLoading(true)
    loadSubgroups(data?.id)

    setValue('subgroup', { id: 0, name: '' })
    setTimeout(() => {
      setLoading(false)
    }, 200)

    return data
  }

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      data.subgroupId = data.subgroup.id
      if (isEdit) {
        await service.dponet.segments.put({
          segmentId: segment?.id,
          segment: data,
        })
      } else {
        await service.dponet.segments.post({
          segment: data,
        })
      }

      snackbar.open({
        message: `Segmento ${isEdit ? 'atualizado' : 'criado'} com sucesso!`,
        variant: 'success',
      })
      history.push(routes.segments.all)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || `Falha ao ${isEdit ? 'atualizar' : 'criar'} segmento!`,
        variant: 'error',
      })
      setLoading(false)
      reset(segment)
    }
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card title="Preencha os dados do segmento">
          <Box py={1}>
            <Grid container style={{ gap: 20 }}>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      label="Nome do segmento"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="name"
                  mode="onBlur"
                />
              </Grid>
              {groups && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="group"
                    onChange={([, data]) => handleChangeGroup(data)}
                    mode="onChange"
                    as={
                      <Autocomplete
                        options={groups || []}
                        getOptionLabel={(option) => option.name || ''}
                        getOptionSelected={(option, value) =>
                          option.id === value.id || value.id === 0 || !value.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Selecione o grupo"
                            variant="outlined"
                            error={!!errors.group}
                            helperText={errors?.group?.message}
                          />
                        )}
                      />
                    }
                  />
                </Grid>
              )}
              {subgroups && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="subgroup"
                    onChange={([, data]) => data}
                    mode="onChange"
                    as={
                      <Autocomplete
                        options={subgroups || []}
                        getOptionLabel={(option) => option.name || ''}
                        getOptionSelected={(option, value) =>
                          option.id === value.id || value.id === 0 || !value.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Selecione o subgrupo"
                            variant="outlined"
                            error={!!errors.subgroup}
                            helperText={errors?.subgroup?.message}
                          />
                        )}
                      />
                    }
                  />
                </Grid>
              )}
              {isEdit && (
                <Grid item xs={12}>
                  <Controller
                    as={
                      <SelectComponent
                        items={statusItems}
                        label="Status"
                        error={!!errors.active}
                        fullWidth
                        helperText={errors?.active && 'Preencha este campo'}
                      />
                    }
                    control={control}
                    name="active"
                    mode="onBlur"
                  />
                </Grid>
              )}
            </Grid>
          </Box>
        </Card>
        <Box mt={3} display="flex" justifyContent="flex-start">
          <Box pr={1}>
            <BackButton onClick={() => history.push(routes.segments.all)}>
              Voltar
            </BackButton>
          </Box>
          <Box>
            <Button color="primary" variant="contained" type="submit">
              Salvar
            </Button>
          </Box>
        </Box>
      </form>
    </>
  )
}

SegmentsForm.propTypes = {
  segment: PropTypes.object,
}

export default SegmentsForm
