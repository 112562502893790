import PropTypes from 'prop-types'

import { useState } from 'react'

import { TableContainer, Button } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { LoadingBox, Permitted } from 'components'

import { DialogDataCollected as DataCollectedModalEdit } from '../DialogDataCollected'
import DataCollectedModal from '../Show/DataCollectedModal/'
import { DialogRemoveDataCollected } from '../DialogRemoveDataCollected'
import * as service from 'service'
import useDataCollected from 'hooks/useDataCollected'
import helpers from 'helpers'
import useSnackbar from 'hooks/useSnackbar'

import constants from 'constants/index'

const TableDataCollecteds = ({
  classes,
  edit,
  dataCollectedOptions,
  dataProcessId,
  isLoading,
}) => {
  const snackbar = useSnackbar()
  const dataCollectedHook = useDataCollected()
  const { mountLabelList } = helpers.dataCollected
  const dataCollecteds = dataCollectedHook.data
  const [dialogRemoveDataCollected, setDialogRemoveDataCollected] =
    useState(false)
  const [removeDataCollected, setRemoveDataCollected] = useState()
  const handleRemove = (dataCollectedId) => {
    setRemoveDataCollected(dataCollectedId)
    setDialogRemoveDataCollected(true)
  }
  const deleteDataCollected = async (dataProcessId, dataCollectedId) => {
    try {
      await service.dponet.dataCollecteds.destroy({
        dataProcessId,
        dataCollectedId,
      })
      setRemoveDataCollected(null)
      setDialogRemoveDataCollected(false)
      dataCollectedHook.loadData(dataProcessId, '')
      snackbar.open({
        message: 'Dado tratado removido com sucesso',
        variant: 'success',
      })
    } catch (error) {
      setRemoveDataCollected(null)
      setDialogRemoveDataCollected(true)
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
  }

  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <TableContainer>
          <Table
            size="small"
            emptyMessage="Nenhum dado tratado encontrado"
            noWrap
          >
            <TableHead>
              <TableRow>
                <TableCell width="35%">Categoria do titular</TableCell>
                <TableCell width="35%">Faixa Etária</TableCell>
                <TableCell width="10%">
                  Quantidade aproximada de titulares
                </TableCell>
                <TableCell width="20%" align="right">
                  Dados tratados
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataCollecteds.map((dataCollected) => {
                const collectedFields = dataCollected.collectedFields.map(
                  (collectedField) => collectedField.dataCollectedOption,
                )

                const toSelectItems =
                  edit && mountLabelList(dataCollectedOptions)

                const selectedFields = edit && mountLabelList(collectedFields)
                return (
                  <TableRow key={dataCollected.id}>
                    <TableCell>
                      {helpers.dataCollected.rangeAgeContent(
                        dataCollected?.titularAgeRange,
                      )}
                    </TableCell>
                    <TableCell>
                      {dataCollected?.holderNumber || 'Não definido'}
                    </TableCell>
                    <TableCell align="right" disableTooltip>
                      {edit ? (
                        <DataCollectedModalEdit
                          classes={classes}
                          dataCollected={dataCollected}
                          toSelectItems={toSelectItems}
                          selectedFields={selectedFields}
                          dataProcessId={dataProcessId}
                          mode="edit"
                        >
                          <Permitted
                            tag={constants.permissions.DATA_PROCESSES.CREATE}
                          >
                            <Button
                              color="secondary"
                              fullWidth
                              onClick={() => handleRemove(dataCollected.id)}
                            >
                              Excluir
                            </Button>
                          </Permitted>
                        </DataCollectedModalEdit>
                      ) : (
                        <DataCollectedModal
                          classes={classes}
                          dataProcessId={dataProcessId}
                          collectedFields={dataCollected.collectedFields}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <DialogRemoveDataCollected
        open={dialogRemoveDataCollected}
        setOpen={setDialogRemoveDataCollected}
        handleRemove={deleteDataCollected}
        dataProcessId={dataProcessId}
        dataCollectedId={removeDataCollected}
      />
    </>
  )
}

TableDataCollecteds.propTypes = {
  classes: PropTypes.object.isRequired,
  edit: PropTypes.bool,
  dataCollectedOptions: PropTypes.arrayOf(Object),
  dataProcessId: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
}

export default TableDataCollecteds
