import { colors, makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  chip: {
    color: theme.palette.common.white,
    borderRadius: '4px',
    fontWeight: 700,
    height: '21px',
    textTransform: 'uppercase',
  },
  indicated: {
    backgroundColor: colors.blue[500],
  },
  approved: {
    backgroundColor: colors.green[500],
  },
  refused: {
    backgroundColor: colors.red[500],
  },
  inactive: {
    backgroundColor: theme.palette.yellowOrange,
  },
}))

export default styles
