import { Box, Typography } from '@material-ui/core'

import useStyles from './styles'

import constants from 'constants/index'

const CouponUsesTypes = () => {
  const classes = useStyles()

  return (
    <Box className={classes.mainBox} p={2} ml={2}>
      <Box display="flex">
        <Typography variant="body2">
          <strong>{constants.discountCoupon.MULTI_USES_LABEL}: </strong> é um
          código promocional que pode ser usado várias vezes por diferentes
          clientes, sem restrições de ou limitações quanto ao número de vezes
          que pode ser utilizado.
        </Typography>
      </Box>
      <Box display="flex">
        <Typography variant="body2">
          <strong>{constants.discountCoupon.ONE_USES_LABEL}: </strong> é um
          código promocional que só pode ser utilizado uma vez por um cliente
          registrado.
        </Typography>
      </Box>
    </Box>
  )
}

export default CouponUsesTypes
