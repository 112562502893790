import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  logoBox: {
    width: theme.spacing(13),
    height: theme.spacing(13),
  },
  inputImageContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px ${theme.palette.primary.main} dashed`,
    borderRadius: '5px',
    color: theme.palette.primary.main,
    flexDirection: 'column',
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.secondary.superLight}`,
  },
}))

export default styles
