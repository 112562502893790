/* eslint-disable no-unused-expressions */
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Typography, Box, Tooltip, ClickAwayListener } from '@material-ui/core'

import { CardRadio } from 'components'

import useStyles from './styles'

import helpers from 'helpers'

const CardCompanySize = ({
  title,
  subTitle,
  price,
  selected,
  handleSelect,
  isRealAndIsEdit,
  code = '',
  companyTemplate = {},
}) => {
  const classes = useStyles()

  const [openTitle, setOpenTitle] = useState(false)
  const [openSubtitle, setOpenSubtitle] = useState(false)
  const companySize = helpers.functions.letterOnly(code)

  const handleTooltipClose = () => {
    setOpenTitle(false)
    setOpenSubtitle(false)
  }

  const handleTooltipOpen = {
    openTitle: () => setOpenTitle(true),
    openSubtitle: () => setOpenSubtitle(true),
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Box m={0}>
        <CardRadio
          display="flex"
          alignCard="space-between"
          action={handleSelect}
          checked={selected}
          isRealAndIsEdit={isRealAndIsEdit}
        >
          <Box p={2}>
            <Box>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={openTitle}
                disableFocusListener
                disableTouchListener
                title={title}
                placement="bottom"
              >
                <Typography
                  onClick={handleTooltipOpen.openTitle}
                  className={classes.title}
                  noWrap
                >
                  {title} {companySize && `(${companySize})`}
                </Typography>
              </Tooltip>
            </Box>

            <Box>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={openSubtitle}
                disableFocusListener
                disableTouchListener
                title={subTitle}
                placement="right"
              >
                <Typography
                  onClick={handleTooltipOpen.openSubtitle}
                  className={classes.subTitle}
                  noWrap
                >
                  {subTitle}
                </Typography>
              </Tooltip>
            </Box>
            <Box mt={1}>
              <Typography className={classes.price}>
                Empresa modelo: {companyTemplate?.name}
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography className={classes.price}>
                {`R$ ${price}/mês`}
              </Typography>
            </Box>
          </Box>
        </CardRadio>
      </Box>
    </ClickAwayListener>
  )
}

CardCompanySize.propTypes = {
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  companyTemplate: PropTypes.object,
  implementationPrice: PropTypes.string.isRequired,
  implementationSplit: PropTypes.number.isRequired,
  selected: PropTypes.bool,
  handleSelect: PropTypes.func,
  isRealAndIsEdit: PropTypes.bool,
}

CardCompanySize.defaultProps = {
  subTitle: '',
  title: '',
  selected: false,
  handleSelect: () => {},
}

export default CardCompanySize
