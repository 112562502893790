import permissions from '../../permissions'

const SELLER_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE,
    tag: permissions.SELLERS.CREATE,
  },
]

export default SELLER_LIST
