import { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { Box, TableBody } from '@material-ui/core'

import { Table, TableHead, TableRow, TableCell } from 'components/Table'
import { UnlinkUserModal, DepartmentUserRow } from './components'

const DepartmentUsersTable = ({
  availableUsersRefresh,
  department,
  isLoading = false,
  refresh,
  setLoading = () => {},
  users,
}) => {
  const [userData, setUserData] = useState({})
  const [openModal, setOpenModal] = useState(false)

  return (
    <Box width="100%" maxHeight={300}>
      <PerfectScrollbar>
        <Table
          size="small"
          emptyMessage="Não há nenhum usuário vinculado a este departamento"
          isLoading={isLoading}
          stickyHeader
          noWrap
        >
          <TableHead>
            <TableRow>
              <TableCell width="30%">Nome</TableCell>
              <TableCell width="50%">Email</TableCell>
              <TableCell width="20%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users?.map((user) => (
              <DepartmentUserRow
                user={user}
                key={user.id}
                setOpen={setOpenModal}
                open={openModal}
                setUserData={setUserData}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <UnlinkUserModal
        availableUsersRefresh={availableUsersRefresh}
        department={department}
        open={openModal}
        refresh={refresh}
        setLoading={setLoading}
        setOpen={setOpenModal}
        user={userData}
      />
    </Box>
  )
}

DepartmentUsersTable.propTypes = {
  availableUsersRefresh: PropTypes.func.isRequired,
  department: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  refresh: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
}

export default DepartmentUsersTable
