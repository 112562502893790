import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'

import { Box, Button, Grid, Paper } from '@material-ui/core'
import { CompanyTypeAndSeachInput, LoadingFeedback } from 'components'
import { AccordionCheckboxList } from '../'

import { useFetch, useSnackbar } from 'hooks'

import { routes } from 'Routes'

import * as service from 'service'
import schema from './schema'
import helpers from 'helpers'

const CopyForm = () => {
  const [loading, setLoading] = useState(false)
  const [questionnaires, setQuestionnaires] = useState([])
  const [questionControls, setQuestionControls] = useState([])

  const snackbar = useSnackbar()
  const history = useHistory()

  const { handleSubmit, control, errors, watch } = useForm({
    validationSchema: schema,
  })

  const sourceCompany = watch('sourceCompany')

  const { response } = useFetch(
    service.dponet.questionnaires.get,
    {
      companyId: sourceCompany?.id,
      newVersion: true,
    },
    [sourceCompany],
    !!sourceCompany,
  )

  const { response: questionControlsResponse } = useFetch(
    service.dponet.questionControls.list,
    {
      questionControl: {
        sourceCompanyId: sourceCompany?.id,
      },
    },
    [sourceCompany],
    !!sourceCompany,
  )

  const sourceQuestionnaires = response?.data?.questionnaires
  const sourceQuestionControls =
    questionControlsResponse?.data?.questionControls

  const handleBack = () => history.push(routes.questionnaires.all)

  const handleQuestionnairesSelectAll = (checked) => {
    if (checked) {
      const ids = sourceQuestionnaires.map((q) => q.quizTemplateId)
      setQuestionnaires(ids)
    } else {
      setQuestionnaires([])
    }
  }

  const handleQuestionnairesSelectSingle = (checked, id) => {
    if (checked) {
      setQuestionnaires([...questionnaires, id])
    } else {
      setQuestionnaires(
        questionnaires.filter((selectedId) => selectedId !== id),
      )
    }
  }

  const handleControlsSelectAll = (isChecked) => {
    if (isChecked) {
      setQuestionControls(sourceQuestionControls.map((control) => control.id))
    } else {
      setQuestionControls([])
    }
  }

  const handleControlsSelectSingle = (isChecked, controlId) => {
    if (isChecked) {
      setQuestionControls((prev) => [...prev, controlId])
    } else {
      setQuestionControls((prev) => prev.filter((id) => id !== controlId))
    }
  }

  const onSubmit = async (data) => {
    setLoading(true)

    const destinationCompanyIds = data.destinationCompanies?.map(
      (company) => company.id,
    )
    const sourceCompanyId = data.sourceCompany?.id
    delete data.companies
    delete data.sourceCompany

    try {
      await service.dponet.questionnaires.copy({
        questionnaire: {
          quizTemplateIds: questionnaires,
          questionControlKinds: questionControls,
          sourceCompanyId,
          destinationCompanyIds,
        },
      })

      snackbar.open({
        message: 'Questionários copiados com sucesso!',
        variant: 'success',
      })
    } catch (e) {
      snackbar.open({
        message: helpers.formatters.errorMessage(e, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingFeedback open={loading} />
      <Paper component={Box} p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CompanyTypeAndSeachInput
              control={control}
              errors={errors}
              label="Empresa origem"
              fieldName="sourceCompany"
            />
          </Grid>
          <Grid item xs={12}>
            <CompanyTypeAndSeachInput
              multiple
              control={control}
              errors={errors}
              label="Destinatários"
              fieldName="destinationCompanies"
            />
          </Grid>
          <Grid item xs={12}>
            <AccordionCheckboxList
              title="Questionários"
              items={sourceQuestionnaires}
              selectedItems={questionnaires}
              handleSelectAll={handleQuestionnairesSelectAll}
              handleSelectSingle={handleQuestionnairesSelectSingle}
              allLabel="Todos os questionários"
              getLabel={(item) => item?.kind}
              getItemId={(item) => item?.quizTemplateId}
            />
          </Grid>
          <Grid item xs={12}>
            <AccordionCheckboxList
              title="Medidas de segurança"
              items={sourceQuestionControls}
              selectedItems={questionControls}
              handleSelectAll={handleControlsSelectAll}
              handleSelectSingle={handleControlsSelectSingle}
              allLabel="Todas as categorias"
              getLabel={(item) => item?.name}
              getItemId={(item) => item?.id}
            />
          </Grid>
        </Grid>
      </Paper>
      <Box
        width="100%"
        display="flex"
        justifyContent="flex-end"
        py={2}
        gridGap={4}
      >
        <Button disabled={loading} variant="outlined" onClick={handleBack}>
          Voltar
        </Button>
        <Button
          disabled={loading}
          type="submit"
          variant="contained"
          color="primary"
        >
          Copiar
        </Button>
      </Box>
    </form>
  )
}

export default CopyForm
