import { useState } from 'react'
import { Button, Box, Grid } from '@material-ui/core'
import {
  MenuButton,
  LabelIconStart,
  BaseDialog,
  LoadingFeedback,
  Select,
} from 'components'
import { DatePicker } from '@material-ui/pickers'

import useSnackbar from 'hooks/useSnackbar'
import * as service from 'service'
import helpers from 'helpers'
import { Repeat as RepeatIcon } from 'react-feather'

import schema from './schema'

import { isEmpty } from 'lodash'

import { Controller, useForm } from 'react-hook-form'

import moment from 'moment'

const DemoPartnerMenuItems = ({
  refresh,
  companyId,
  editionMode,
  demoPartner,
}) => {
  const [openChangeStatusDialog, setOpenChangeStatusDialog] = useState(false)
  const handleActionDialog = () => setOpenChangeStatusDialog((open) => !open)
  const [isLoading, setIsloading] = useState(false)
  const snackbar = useSnackbar()

  const cardActionsItem = {
    icon: <RepeatIcon size={20} />,
    label: 'Alterar benefício',
    action: handleActionDialog,
    visible: true,
  }

  const optionMember = [
    { id: 'true', name: 'Membro' },
    { id: 'false', name: 'Parceiro' },
    { id: 'null', name: 'Cliente' },
  ]

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      demoEndDate: moment(demoPartner?.demoEndDate).format('MM/DD/YYYY') || '',
      member: isEmpty(demoPartner?.member)
        ? 'null'
        : demoPartner?.member
        ? 'true'
        : 'false',
    },
  })

  const onSubmit = async (data) => {
    setIsloading(true)
    const { demoEndDate, member } = data

    try {
      await service.dponet.companies.updateDemoPartner({
        companyId: companyId,
        demoPartner: {
          demoEndDate: new Date(demoEndDate),
          member: member,
        },
      })
      snackbar.open({
        message: 'Benefício atualizado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Falha ao alterar benefício!',
        variant: 'error',
      })
    }
    setIsloading(false)
    setOpenChangeStatusDialog(false)
    refresh()
  }

  return (
    <>
      {editionMode && (
        <Box display="flex" alignItems="center">
          <MenuButton>
            <Button
              key={cardActionsItem.label}
              color="secondary"
              fullWidth
              onClick={cardActionsItem.action}
            >
              <LabelIconStart
                icon={cardActionsItem.icon}
                label={cardActionsItem.label}
              />
            </Button>
          </MenuButton>
        </Box>
      )}
      <LoadingFeedback open={isLoading} />
      <BaseDialog
        open={openChangeStatusDialog}
        setOpen={setOpenChangeStatusDialog}
        title="Alterar o benefício"
        closeButtonText="Cancelar"
        actionButtonText="Salvar"
        formRef="demoPartnerUpdate"
      >
        <form onSubmit={handleSubmit(onSubmit)} id="demoPartnerUpdate">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                as={
                  <DatePicker
                    label="Data limite"
                    color="primary"
                    format="DD/MM/yyyy"
                    variant="outlined"
                    error={!!errors?.demoEndDate}
                    helperText={errors?.demoEndDate?.message}
                    fullWidth
                    disablePast
                  />
                }
                control={control}
                name="demoEndDate"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="member"
                mode="onBlur"
                as={
                  <Select
                    label="Tipo"
                    items={optionMember || []}
                    error={!!errors?.member}
                    helperText={errors?.member?.message}
                  />
                }
              />
            </Grid>
          </Grid>
        </form>
      </BaseDialog>
    </>
  )
}

export default DemoPartnerMenuItems
