import { useState } from 'react'

import PropTypes from 'prop-types'

import {
  Modal,
  Grid,
  Typography,
  Paper,
  TableContainer,
  IconButton as IconButtonMaterial,
} from '@material-ui/core'

import {
  VisibilityOutlined as VisibilityOutlinedIcon,
  Close as CloseIcon,
} from '@material-ui/icons'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { IconButton } from 'components'

const DataCollectedModal = ({ collectedFields, classes }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IconButton
        size="small"
        Icon={VisibilityOutlinedIcon}
        text="Visualizar"
        onClick={handleOpen}
      />
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby="modal-collected-field"
        aria-describedby="modal-collected-field-description"
        disableAutoFocus
      >
        <Paper className={classes.paperModal}>
          <Grid container alignItems="center">
            <Typography variant="h4" id="modal-collected-field">
              Visualizar dados tratados
            </Typography>
            <IconButtonMaterial
              onClick={handleClose}
              color="primary"
              aria-label="close modal"
            >
              <CloseIcon />
            </IconButtonMaterial>
          </Grid>
          <TableContainer className={classes.tableContainerModal}>
            <Table
              size="small"
              emptyMessage="Nenhum campo coletado encontrado"
              noWrap
              stickyHeader
              id="modal-collected-description"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Dado</TableCell>
                  <TableCell>Tipo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {collectedFields.map((collectedField) => (
                  <TableRow key={collectedField.dataCollectedOption?.id}>
                    <TableCell>
                      {collectedField.dataCollectedOption?.name}
                    </TableCell>
                    <TableCell>
                      {collectedField.dataCollectedOption?.dataTypeName}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Modal>
    </>
  )
}

DataCollectedModal.propTypes = {
  collectedFields: PropTypes.array,
  classes: PropTypes.object.isRequired,
}

export default DataCollectedModal
