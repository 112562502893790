import moment from 'moment'

const mountConfirmationPay = (data, voucherFile, paymentOrderId) => {
  const { paymentMethod, paymentMethodOther, confirmationDatePaid } = data

  const verifyPaymentMethod =
    paymentMethod !== 'another' ? paymentMethod : paymentMethodOther

  return {
    ...data,
    files: voucherFile,
    id: paymentOrderId,
    confirmationDatePaid: moment(confirmationDatePaid).format('YYYY-MM-DD'),
    paymentMethod: verifyPaymentMethod,
  }
}

export default mountConfirmationPay
