import { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, TextField, Grid } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'

import {
  Card,
  Select as SelectComponent,
  LoadingFeedback,
  ButtonComponent,
} from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import helpers from 'helpers'
import schema from './schema'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'
import constants from 'constants/index'

const Form = ({ seller }) => {
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema(),
    defaultValues: {
      name: seller?.name || '',
      email: seller?.email || '',
      kind: seller?.kind || constants.seller.SELLER_KIND,
    },
  })
  const handleRedirect = () => history.push(routes.sellers.all)

  const onSubmit = async (data) => {
    try {
      setLoading(true)

      const method = seller ? 'update' : 'create'

      await service.dponet.sellers[method]({ ...data, sellerId: seller?.id })
      snackbar.open({
        message: `${
          constants.seller.TRANSLATED_KINDS[data.kind]
        } criado com sucesso!`,
        variant: 'success',
      })
      handleRedirect()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) ||
          `Falha em criar ${constants.seller.TRANSLATED_KINDS[data.kind]}!`,
        variant: 'error',
      })
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingFeedback loading={loading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card title="Preencha os dados">
          <Box py={1}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      label="Nome"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="name"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  as={
                    <TextField
                      label="E-mail"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.email}
                      helperText={errors?.email?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="email"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Controller
                  as={
                    <SelectComponent
                      items={constants.seller.OPTIONS}
                      label="Tipo"
                      error={!!errors.kind}
                      fullWidth
                      helperText={errors?.kind?.messsage}
                    />
                  }
                  control={control}
                  name="kind"
                  mode="onBlur"
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
        <Box display="flex" justifyContent="flex-end">
          <Box pr={2}>
            <ButtonComponent
              title="Voltar"
              type=" button"
              variant="outlined"
              onClick={handleRedirect}
            />
          </Box>
          <ButtonComponent title="Salvar" />
        </Box>
      </form>
    </>
  )
}

Form.propTypes = {
  sale: PropTypes.object,
}

export default Form
