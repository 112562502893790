import moment from 'moment'
import 'moment/locale/pt-br'

const dateTime = (date) => {
  if (!date) {
    return null
  }

  return moment(date).format('DD/MM/YYYY [às] HH:mm')
}

export default dateTime
