import { Container, ContentHeader, Page } from 'components'
import { FaqForm } from './components'

const FaqsNew = () => {
  return (
    <Page title="Nova FAQ">
      <Container spacingXl={40} spacingLg={25} spacingMd={10} spacingSm={10}>
        <ContentHeader menu="Central de ajuda" title="Nova" subtitle="FAQs" />
        <FaqForm editionPermitted />
      </Container>
    </Page>
  )
}

export default FaqsNew
