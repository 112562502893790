import permissions from '../../permissions'

const LAST_UPDATE_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.VISUALIZE,
    tag: permissions.LAST_UPDATES.LIST,
  },
]

export default LAST_UPDATE_LIST
