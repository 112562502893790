import PropTypes from 'prop-types'
import theme from 'theme'

import {
  makeStyles,
  Card,
  Typography,
  Box,
  Grid,
  Tooltip,
} from '@material-ui/core'

import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
} from 'react-feather'

import styles from './styles'
import helpers from 'helpers'
import clsx from 'clsx'

const useStyles = makeStyles(styles)

const PrivacyPoliciesInfoCards = ({ privacyPolicy }) => {
  const classes = useStyles()

  return (
    <Box display="flex" width="100%">
      <Grid container spacing={3}>
        <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
          <Card className={classes.root}>
            <Box display="flex" alignItems="center" width="100%" p={1} px={2}>
              <Box pr={1}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className={clsx(
                    classes.statusCard,
                    classes.bgColorPrimaryMain,
                  )}
                >
                  <BriefcaseIcon color={theme.palette.white} size="18px" />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  className={classes.titleBox}
                >
                  <Tooltip title="Empresa" placement="bottom-start">
                    <Typography
                      color="textSecondary"
                      variant="h6"
                      className={classes.titleCard}
                      noWrap
                    >
                      EMPRESA
                    </Typography>
                  </Tooltip>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    className={classes.valueCard}
                  >
                    {privacyPolicy?.company?.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Card>
        </Grid>

        <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
          <Card className={classes.root}>
            <Box display="flex" alignItems="center" width="100%" p={1} px={2}>
              <Box pr={1}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className={clsx(
                    classes.statusCard,
                    classes.bgColorPrimaryMain,
                  )}
                >
                  <CalendarIcon color={theme.palette.white} size="18px" />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  className={classes.titleBox}
                >
                  <Tooltip title="Data de criação" placement="bottom-start">
                    <Typography
                      color="textSecondary"
                      variant="h6"
                      className={classes.titleCard}
                      noWrap
                    >
                      DATA DE CRIAÇÃO
                    </Typography>
                  </Tooltip>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    className={classes.valueCard}
                  >
                    {helpers.formatters.date(privacyPolicy?.createdAt)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Card>
        </Grid>

        <Grid item xl={3} lg={4} md={4} sm={6} xs={12}>
          <Card className={classes.root}>
            <Box display="flex" alignItems="center" width="100%" p={1} px={2}>
              <Box pr={1}>
                {helpers.privacyPolicies.defineInfoCardIcon(
                  privacyPolicy?.status,
                  classes,
                )}
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  className={classes.titleBox}
                >
                  <Tooltip title="Status" placement="bottom-start">
                    <Typography
                      color="textSecondary"
                      variant="h6"
                      className={classes.titleCard}
                      noWrap
                    >
                      STATUS
                    </Typography>
                  </Tooltip>
                  <Typography
                    color="textPrimary"
                    variant="body1"
                    className={classes.valueCard}
                  >
                    {helpers.privacyPolicies.statusLabel(privacyPolicy?.status)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

PrivacyPoliciesInfoCards.propTypes = {
  privacyPolicy: PropTypes.object,
}

export default PrivacyPoliciesInfoCards
