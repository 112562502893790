import PropTypes from 'prop-types'

import { ConfirmationDialog } from 'components'

const DialogRemoveDocument = ({ document, open, setOpen, handleRemove }) => {
  return (
    <ConfirmationDialog
      open={open}
      setOpen={setOpen}
      message="Tem certeza que deseja remover este documento?"
      cancelButtonText="Cancelar"
      buttonText="Continuar"
      actionAcceptButton={() => handleRemove(document)}
    />
  )
}

DialogRemoveDocument.propTypes = {
  document: PropTypes.node.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
}

DialogRemoveDocument.defaultProps = {
  setOpen: () => {},
  handleRemove: () => {},
}
export default DialogRemoveDocument
