import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'

import { Select } from 'components'

import constants from 'constants/index'
import helpers from 'helpers'

const CookiePlanSelect = ({ defaultScanChecked = true }) => {
  const { control, errors, watch } = useFormContext()

  const numbersChange = ([event]) => {
    return parseInt(helpers.formatters.onlyNumbers(event.target.value))
  }

  return (
    <>
      <Grid item xs={12}>
        <Typography>Plano do banner de cookies</Typography>
        <Controller
          control={control}
          name="cookiePlan"
          mode="onChange"
          as={
            <Select
              items={
                constants.integrationPartner
                  .SELECT_COOKIE_PLAN_INTEGRATION_PARTNER ?? []
              }
              error={!!errors.cookiePlan}
              helperText={errors?.cookiePlan?.message}
              variant="outlined"
              fullWidth
            />
          }
        />
      </Grid>
      {watch('cookiePlan') ===
        constants.integrationPartner.COOKIE_PLANS.custom && (
        <Grid item xs={12}>
          <Box component={Paper} variant="outlined" p={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>Número de domínios</Typography>
                <Controller
                  as={
                    <TextField
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.cookiePlanConfig?.bannersLimit}
                      helperText={
                        errors?.cookiePlanConfig?.bannersLimit?.message
                      }
                      fullWidth
                    />
                  }
                  onChange={numbersChange}
                  control={control}
                  name="cookiePlanConfig.bannersLimit"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Limite de acessos</Typography>
                <Controller
                  as={
                    <TextField
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.cookiePlanConfig?.requestsLimit}
                      helperText={
                        errors?.cookiePlanConfig?.requestsLimit?.message
                      }
                      fullWidth
                    />
                  }
                  onChange={numbersChange}
                  control={control}
                  name="cookiePlanConfig.requestsLimit"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>Scanner automático</Typography>
                <Controller
                  as={
                    <FormControlLabel
                      label="Scanner mensal automático habilitado"
                      control={<Checkbox defaultChecked={defaultScanChecked} />}
                    />
                  }
                  defaultValue={true}
                  onChange={([_, checked]) => !!checked}
                  control={control}
                  name="cookiePlanConfig.automaticScan"
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      )}
    </>
  )
}

export default CookiePlanSelect
