import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  active: {
    backgroundColor: colors.green[800],
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    textTransform: 'uppercase',
    height: '21px',
  },
  inactive: {
    backgroundColor: colors.red[800],
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    textTransform: 'uppercase',
    height: '21px',
  },
}))
export default styles
