import constants from 'constants/index'

const filterUnnecessaryCollectedFields = (rmc) => {
  if (!rmc?.unnecessaryDatas) {
    return []
  }
  return rmc.unnecessaryDatas.filter(
    (unnecessaryData) =>
      unnecessaryData.dataType ===
      constants.dataProcess.UNNECESSARY_DATA_TYPE.COLLECTED_FIELD,
  )
}

export default filterUnnecessaryCollectedFields
