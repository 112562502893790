import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  iconBox: {
    borderRadius: '40px',
    padding: '15px',
    maxHeight: '54px',
    marginRight: '20px',
    marginLeft: '12px',
    color: theme.palette.white,
    backgroundColor: theme.palette.primary.main,
  },
  overFlow: {
    display: 'block',
    maxWidth: '70%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export default styles
