import { default as mountMenuItems } from './mountMenuItems'
import { default as isPmo } from './isPmo'
import { default as isResponsable } from './isResponsable'
import { default as getHomePageByPermission } from './getHomePageByPermission'

const auth = {
  mountMenuItems,
  isPmo,
  isResponsable,
  getHomePageByPermission,
}

export default auth
