import { Page, Container, ContentHeader } from 'components'
import { GroupsForm } from './components'

const GroupsNew = () => {
  return (
    <Page title="Novo grupo">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader title="Novo" menu="Grupo" subtitle="Criação de grupo" />
        <GroupsForm />
      </Container>
    </Page>
  )
}
export default GroupsNew
