import { useEffect } from 'react'
import { useForm, FormContext } from 'react-hook-form'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Card, CardHeader, Box, Typography, Divider } from '@material-ui/core'

import { NoData, Permitted, RmcActionContent } from 'components'
import FinalizedOrContinuedAlert from '../../Show/FinalizedOrContinuedAlert'
import {
  TableNecPropDataCollected,
  TableNecPropDataTreatments,
  TableNecPropDataLifeCycle,
} from './components'

import { useDataProcess, useRmc } from 'hooks'
import helpers from 'helpers'
import useStyles from './styles'
import constants from 'constants/index'

const GeneralInformation = () => {
  const { data: dataProcess } = useDataProcess()

  const { loadData, data: rmc, loading } = useRmc()

  const classes = useStyles()

  const showStep =
    dataProcess?.dataLifeCycles?.length > 0 ||
    dataProcess?.dataTreatments?.length > 0 ||
    dataProcess?.dataCollecteds?.length > 0

  const showResult =
    dataProcess.nonComplianceReport &&
    dataProcess.version ===
      dataProcess.nonComplianceReport.dataProcessVersion &&
    !helpers.nonComplianceReports.finalityCheck.fragility(
      dataProcess.nonComplianceReport,
    )

  const formMethods = useForm()

  useEffect(() => {
    if (dataProcess.nonComplianceReport) {
      loadData(dataProcess.nonComplianceReport.id)
    }

    //eslint-disable-next-line
  }, [])

  return (
    <>
      {showStep || showResult ? (
        <>
          {showStep && (
            <>
              <Card>
                <CardHeader title="Análise da Necessidade e Proporcionalidade" />
                <Divider />
                <Box className={classes.root}>
                  <Typography variant="body1">
                    Os seguintes itens foram indicados como
                    desnecessários/desproporcionais:
                  </Typography>
                </Box>
              </Card>
              {dataProcess?.dataCollecteds?.length > 0 && (
                <Box mt={2}>
                  <Card>
                    <CardHeader title="Dados tratados não necessários" />
                    <Divider />
                    <Box m={2}>
                      <Card>
                        <FormContext
                          className={classes.fullHeight}
                          {...formMethods}
                        >
                          <PerfectScrollbar>
                            <TableNecPropDataCollected
                              dataCollecteds={dataProcess.dataCollecteds}
                              editType
                            />
                          </PerfectScrollbar>
                        </FormContext>
                      </Card>
                    </Box>
                  </Card>
                </Box>
              )}
              {dataProcess?.dataTreatments?.length > 0 && (
                <Box mt={2}>
                  <Card>
                    <CardHeader title="Dados compartilhados não necessários" />
                    <Divider />
                    <Box m={2}>
                      <Card>
                        <FormContext
                          className={classes.fullHeight}
                          {...formMethods}
                        >
                          <PerfectScrollbar>
                            <TableNecPropDataTreatments
                              dataTreatments={dataProcess.dataTreatments}
                              editType
                            />
                          </PerfectScrollbar>
                        </FormContext>
                      </Card>
                    </Box>
                  </Card>
                </Box>
              )}
              {dataProcess?.lifeCycles?.length > 0 && (
                <Box mt={2}>
                  <Card>
                    <CardHeader title="Necessidade e Proporcionalidade" />
                    <Divider />
                    <Box m={2}>
                      <Card>
                        <FormContext
                          className={classes.fullHeight}
                          {...formMethods}
                        >
                          <PerfectScrollbar>
                            <TableNecPropDataLifeCycle
                              dataLifeCycles={dataProcess.lifeCycles}
                              editType
                            />
                          </PerfectScrollbar>
                        </FormContext>
                      </Card>
                    </Box>
                  </Card>
                </Box>
              )}
            </>
          )}
          {showResult && !loading && rmc?.unnecessaryDatas && (
            <Permitted tag={constants.permissions.NON_COMPLIANCE_REPORT.EDIT}>
              <Box mt={showStep && 2}>
                <RmcActionContent
                  titleCard="Resultado da reavaliação de necessidade e
                proporcionalidade"
                />
              </Box>
            </Permitted>
          )}
          {showResult && !loading && (
            <Box mt={2}>
              <FinalizedOrContinuedAlert nonComplianceReport={rmc} />
            </Box>
          )}
        </>
      ) : (
        <Box display="flex" alignItem="center" justifyContent="center">
          <Box width="50%">
            <NoData message="Nenhum dado de análise da necessidade e proporcionalidade encontrado." />
          </Box>
        </Box>
      )}
    </>
  )
}

export default GeneralInformation
