import dataProcessConstants from 'constants/dataProcess'

const sourceDescriptionText = (sourceDescriptionId) => {
  const sourceDescriptionTexts = {
    [dataProcessConstants.TITULAR_DATA_SOURCE]:
      dataProcessConstants.TITULAR_DESCRIPTION,
    [dataProcessConstants.DEPARTMENT_SOURCE]:
      dataProcessConstants.DEPARTMENT_DESCRIPTION,
    [dataProcessConstants.THIRD_PARTY_SOURCE]:
      dataProcessConstants.THIRD_PARTY_SOURCE_DESCRIPTION,
    [dataProcessConstants.PUBLIC_SOURCE]:
      dataProcessConstants.PUBLIC_SOURCE_DESCRIPTION,
  }

  return sourceDescriptionTexts[sourceDescriptionId]
}

export default sourceDescriptionText
