import propTypes from 'prop-types'

import {
  Box,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'

import { Check as CheckIcon, Trash as TrashIcon } from 'react-feather'

import helpers from 'helpers'

import constants from 'constants/index'

import useStyles from './styles'
import clsx from 'clsx'

const ListItemCustom = ({ label, dataType, item }) => {
  const classes = useStyles()

  const isEliminated =
    item?.status === constants.unnecessaryData.STATUS.ELIMINATED

  return (
    <ListItem divider>
      <Grid container>
        <Grid item xs={12}>
          <Box display="flex">
            <ListItemAvatar className={classes.iconBox}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={clsx(classes.iconStyle, {
                  [classes.iconStyleEliminated]: isEliminated,
                  [classes.iconStyleNotEliminated]: !isEliminated,
                })}
              >
                {isEliminated ? (
                  <TrashIcon size={20} />
                ) : (
                  <CheckIcon size={20} />
                )}
              </Box>
            </ListItemAvatar>
            <Box>
              <ListItemText
                primary={helpers.functions.capitalizeText(label)}
                classes={{ primary: classes.labelText }}
              />
              <ListItemText
                primary={helpers.nonComplianceReports.eliminateNotEliminateSubtitleMessages(
                  dataType,
                  item,
                )}
                primaryTypographyProps={{
                  color: 'secondary',
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ListItem>
  )
}

ListItemCustom.propTypes = {
  label: propTypes.string,
  item: propTypes.object,
  dataType: propTypes.oneOf(['collectedField', 'dataTreatment', 'lifeCycle']),
}

export default ListItemCustom
