import { Box, CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'

const LoadingBox = ({ minHeight }) => {
  return (
    <Box
      display="flex"
      width="100%"
      minHeight={minHeight}
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  )
}

LoadingBox.propTypes = {
  minHeight: PropTypes.string,
}

LoadingBox.defaultParams = {
  minHeight: '700px',
}

export default LoadingBox
