import { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Typography } from '@material-ui/core'
import { reverse } from 'named-urls'
import { useHistory } from 'react-router-dom'

import useSnackbar from 'hooks/useSnackbar'

import { TableRow, TableCell } from 'components/Table'
import { MenuButton, Permitted, ConfirmationDialog } from 'components'

import helpers from 'helpers'
import constants from 'constants/index'
import { routes } from 'Routes'
import { getBaseURL } from 'service/env'
import * as service from 'service'
import useStyles from './styles'

const ContractsTableRow = ({ paymentContract, refresh }) => {
  const snackbar = useSnackbar()
  const [openDestroy, setOpenDestroy] = useState(false)
  const history = useHistory()
  const classes = useStyles()

  const { PAYMENT_CONTRACTS } = constants.permissions

  const contractIdentifier = paymentContract?.contractIdentifier

  const handleShowInvoice = () => {
    if (paymentContract?.invoiceUrl) {
      window.open(
        `${getBaseURL('dponet')}/${paymentContract?.invoiceUrl}`,
        '__blank',
      )
    }
  }

  const handleShowContract = () => {
    if (paymentContract?.platformContractUrl) {
      window.open(paymentContract.platformContractUrl, '__blank')
    }
  }

  const handlePreRegistration = () => {
    history.push(
      reverse(routes.preRegistrations.show, {
        preRegistrationToken: paymentContract?.preRegistrationToken,
      }),
    )
  }

  const handleEdit = () => {
    history.push(
      reverse(routes.contracts.edit, {
        contractId: paymentContract?.id,
      }),
    )
  }

  const handleShow = () => {
    history.push(
      reverse(routes.contracts.show, {
        contractId: paymentContract?.id,
      }),
    )
  }

  const handleOpenDestroy = () => setOpenDestroy(true)

  const handleDestroy = async () => {
    try {
      await service.dponet.paymentContract.destroy({
        paymentContractId: paymentContract?.id,
      })

      snackbar.open({
        message: `Contrato ${contractIdentifier} excluído com sucesso!`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          `Ocorreu um erro ao tentar excluir o contrato ${contractIdentifier}`,
        variant: 'error',
      })
    } finally {
      refresh()
      setOpenDestroy(false)
    }
  }

  return (
    <>
      <TableRow key={paymentContract?.id}>
        <TableCell>#{paymentContract?.id}</TableCell>
        <TableCell>{contractIdentifier} </TableCell>
        <TableCell pointer overFlowSize="100%">
          <Typography
            variant="body2"
            onClick={handlePreRegistration}
            className={classes.link}
          >
            {paymentContract?.identifier}
          </Typography>
        </TableCell>
        <TableCell>
          {
            constants.preRegistration.KIND_PAYMENT_FEATURES[
              paymentContract?.kind
            ]
          }
        </TableCell>
        <TableCell>
          <Box display="flex" alignItems="center" flexDirection="column">
            <Typography variant="body2">
              R$ {helpers.formatters.currencyInput(paymentContract?.price)}
            </Typography>
            <Typography variant="body2">
              {
                constants.paymentContract.PAYMENT_METHODS[
                  paymentContract?.methodPayment
                ]
              }
            </Typography>
          </Box>
        </TableCell>
        <TableCell>
          {helpers.formatters.date(paymentContract?.nextDateAdvance) || '-'}
        </TableCell>
        <TableCell>
          {helpers.formatters.date(paymentContract?.nextBillingDate) || '-'}
        </TableCell>
        <TableCell disableTooltip noOverFlow align="center">
          <MenuButton>
            <Permitted tag={PAYMENT_CONTRACTS.MANAGE}>
              <Button fullWidth size="small" onClick={handleEdit}>
                Editar
              </Button>
            </Permitted>
            <Permitted tag={PAYMENT_CONTRACTS.SHOW}>
              <Button fullWidth size="small" onClick={handleShow}>
                Visualizar
              </Button>
            </Permitted>
            <Button
              fullWidth
              size="small"
              onClick={handleShowContract}
              disabled={!paymentContract?.platformContractUrl}
            >
              Contrato
            </Button>
            <Button
              fullWidth
              size="small"
              onClick={handleShowInvoice}
              disabled={!paymentContract?.codeContractOmie}
            >
              Recibo
            </Button>
            <Permitted tag={PAYMENT_CONTRACTS.DESTROY}>
              <Button fullWidth size="small" onClick={handleOpenDestroy}>
                Excluir
              </Button>
            </Permitted>
          </MenuButton>
        </TableCell>
      </TableRow>
      {openDestroy && (
        <ConfirmationDialog
          open={openDestroy}
          setOpen={setOpenDestroy}
          actionAcceptButton={handleDestroy}
          message={`Exclusão do contrato ${contractIdentifier}`}
          subMessage="O contrato será excluído permanentemente e no omie sera cancelado, concorda com a exclusão?"
        />
      )}
    </>
  )
}

ContractsTableRow.propTypes = {
  paymentContract: PropTypes.object,
}

export default ContractsTableRow
