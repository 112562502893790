import { useState } from 'react'

import PropTypes from 'prop-types'

import { Box, Card, Typography, Collapse, Divider } from '@material-ui/core'
import { ToggleButton } from 'components'

import useStyles from './styles'
import { isEmpty } from 'lodash'

const ButtonHeader = ({ expanded, setExpanded }) => {
  const switchState = () => {
    setExpanded(!expanded)
  }

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box pr={1}>
        <ToggleButton size={18} expanded={expanded} onClick={switchState} />
      </Box>
    </Box>
  )
}

const mountBody = (objectContent) => {
  return (
    <Box my={2}>
      {objectContent.map((object) => (
        <Box mb={2}>
          {object.title === 'hasMany' ? (
            object.data.map((contentArray, index) => {
              return (
                <>
                  <Box>
                    <Divider />
                  </Box>
                  <Box mx={2} key={index}>
                    {contentArray.map((content, index) => (
                      <Box my={2} key={index}>
                        <Typography variant="h6">{content.title}</Typography>
                        <Typography variant="body1">{content.data}</Typography>
                      </Box>
                    ))}
                  </Box>
                </>
              )
            })
          ) : (
            <Box mx={2} m={2}>
              <Typography variant="h6">{object.title}</Typography>
              <Typography variant="body1">{object.data}</Typography>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  )
}

const DropdownDetails = ({
  children,
  title,
  subtitles,
  iconTitle,
  objectContent,
}) => {
  const [expanded, setExpanded] = useState(true)

  const classes = useStyles()

  const iconIsPresent = () => {
    return !isEmpty(iconTitle._source)
  }

  return (
    <Card>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        pl={2}
        py={2}
      >
        <Box display="flex" justifyContent="center" alignItems="space-around">
          {iconIsPresent() && <Box className={classes.icon}>{iconTitle}</Box>}
          <Box display="grid" justifyContent="center" alignItems="center">
            <Typography variant="h5">{title}</Typography>
            {subtitles?.map((subtitle) => (
              <Typography variant="body1">{subtitle}</Typography>
            ))}
          </Box>
        </Box>
        <ButtonHeader expanded={expanded} setExpanded={setExpanded} />
      </Box>
      <Divider />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {children || mountBody(objectContent)}
      </Collapse>
    </Card>
  )
}

DropdownDetails.propTypes = {
  title: PropTypes.string,
  subtitles: PropTypes.arrayOf(PropTypes.string),
  iconTitle: PropTypes.node,
  objectContent: PropTypes.array,
  children: PropTypes.node,
}

DropdownDetails.defaultProps = {
  title: '',
  objectContent: [{}],
  subtitles: [''],
}

export default DropdownDetails
