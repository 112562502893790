import constants from 'constants/index'

const eliminateNotEliminateTexts = (type, chosen) => {
  switch (type) {
    case constants.dataProcess.DATA_TYPE_CHOSEN.LIFE_CYCLE:
      if (chosen === constants.nonComplianceReports.ELIMINATED_CHOSEN_ACTION) {
        return constants.nonComplianceReports.ELIMINATE_LIFE_CYCLES_TEXTS
      }
      return constants.nonComplianceReports.NOT_ELIMINATE_LIFE_CYCLES_TEXTS
    case constants.dataProcess.DATA_TYPE_CHOSEN.COLLECTED_FIELD:
      if (chosen === constants.nonComplianceReports.ELIMINATED_CHOSEN_ACTION) {
        return constants.nonComplianceReports.ELIMINATE_COLLECTED_FIELD_TEXTS
      }
      return constants.nonComplianceReports.NOT_ELIMINATE_COLLECTED_FIELD_TEXTS
    case constants.dataProcess.DATA_TYPE_CHOSEN.DATA_TREATMENT:
      if (chosen === constants.nonComplianceReports.ELIMINATED_CHOSEN_ACTION) {
        return constants.nonComplianceReports.ELIMINATE_DATA_TREATMENT_TEXTS
      }
      return constants.nonComplianceReports.NOT_ELIMINATE_DATA_TREATMENT_TEXTS
    default:
      return { text: '-', textButton: '-' }
  }
}

export default eliminateNotEliminateTexts
