import PropTypes from 'prop-types'
import { ConfirmationDialog } from 'components'

import helpers from 'helpers'

import * as service from 'service'

import useSnackbar from 'hooks/useSnackbar'

const ChangeStatusOrDestroy = ({
  companySize,
  refresh,
  setOpenSize,
  openSize,
  isDestroy,
}) => {
  const snackbar = useSnackbar()

  const statuslabel = companySize?.active ? 'inativar' : 'ativar'

  const MESSAGE = `Deseja realmente ${
    isDestroy ? 'excluir' : statuslabel
  } o tamanho de empresa?`

  const handleChageStatus = async () => {
    try {
      await service.dponet.companySizes.changeStatus({
        sizeId: companySize?.id,
      })
      refresh()

      snackbar.open({
        message: `Tamanho de empresa ${
          companySize?.active ? 'inativado' : 'ativado'
        } com sucesso.`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          `Erro ao ${statuslabel} o tamanho de empresa.`,
      })
    }
    setOpenSize(false)
  }

  const handleDestroy = async () => {
    try {
      await service.dponet.companySizes.destroy({
        sizeId: companySize?.id,
      })
      refresh()

      snackbar.open({
        message: 'Tamanho de empresa excluído com sucesso.',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Erro ao excluir o tamanho de empresa.',
      })
    }
    setOpenSize(false)
  }

  return (
    <ConfirmationDialog
      open={openSize}
      setOpen={setOpenSize}
      message={MESSAGE}
      cancelButtonText="Cancelar"
      buttonText="Confirmar"
      actionAcceptButton={isDestroy ? handleDestroy : handleChageStatus}
    />
  )
}

ChangeStatusOrDestroy.propTypes = {
  companySize: PropTypes.object.isRequired,
  refresh: PropTypes.func,
  setOpenSize: PropTypes.func,
  openSize: PropTypes.bool,
  isDestroy: PropTypes.bool,
}

ChangeStatusOrDestroy.propTypes = {
  refresh: () => {},
  setOpenSize: () => {},
  openSize: false,
}

export default ChangeStatusOrDestroy
