/* eslint-disable react-hooks/exhaustive-deps */
import { Filters } from 'components'
import constants from 'constants/index'

const LastUpdateFilter = ({ filter }) => {
  return (
    <Filters filter={filter}>
      <input textfieldinput="true" label="Identificador" name="id" />
      <input textfieldinput="true" label="Título" name="title" />
      <input textfieldinput="true" label="Publicado por" name="publishedBy" />

      <select textfieldinput="true" label="Tipo" name="tag">
        <option value=""></option>
        {constants.lastUpdates.SELECT_LAST_UPDATE_TYPE.map((type) => (
          <option key={type.id} value={type.name}>
            {type.name}
          </option>
        ))}
      </select>
      <input
        datepickerinput="true"
        label="Última atualização antes de"
        name="updatedAtLt"
      />
      <input
        datepickerinput="true"
        label="Última atualização depois de"
        name="updatedAtGt"
      />
    </Filters>
  )
}

export default LastUpdateFilter
