import preRegistration from 'constants/preRegistration'
import formatters from 'helpers/formatters'
const tableGrid = (paymentContrats) => {
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
    },
    {
      field: 'contractIdentifier',
      headerName: 'Numero do contrato',
      width: 200,
    },
    {
      field: 'identifier',
      headerName: 'Cliente/Subclicenciamento',
      width: 500,
    },
    {
      field: 'kind',
      headerName: 'Status',
      width: 200,
    },
    {
      field: 'price',
      headerName: 'Mensalidade',
      width: 200,
    },
    {
      field: 'nextBillingDate',
      headerName: 'Próximo vencimento',
      width: 200,
    },
  ]

  const rows = paymentContrats.map((contract) => {
    return {
      id: contract?.id,
      contractIdentifier: contract?.contractIdentifier,
      identifier: contract?.identifier,
      kind: preRegistration.KIND_PAYMENT_FEATURES[contract.kind],
      price: formatters.currencyInput(contract?.price),
      nextBillingDate: formatters.date(contract?.nextBillingDate),
    }
  })

  return {
    columns,
    rows,
  }
}
export default tableGrid
