import chipStatusColor from './chipStatusColor'
import chipStatusLabel from './chipStatusLabel'
import contractKindLabel from './contractKindLabel'

const platformContracts = {
  chipStatusColor,
  chipStatusLabel,
  contractKindLabel,
}

export default platformContracts
