/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'
import { useCompany } from 'hooks'
import PropTypes from 'prop-types'
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core'
import { Card } from 'components'

import useStyles from './styles'
import { Controller } from 'react-hook-form'

const PermissionForm = ({
  control,
  disableCertFields,
  company,
  register,
  checked,
  setChecked,
}) => {
  const isChechedAcceptFragility = disableCertFields
    ? company?.acceptProcessFragility
    : false
  const [checkedFragility, setCheckedFragility] = useState(
    isChechedAcceptFragility,
  )
  const classes = useStyles()
  const { isLoading } = useCompany()

  return (
    <Card title="Permissões da empresa">
      {!isLoading && (
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.checkBox}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              }
              inputRef={register}
              name="blockUpdate"
              labelPlacement="end"
              label="Bloquear que clientes editem os Dados da Empresa (informações cadastrais)"
            />
          </Grid>
          <Grid item xs={12} className={classes.checkBox}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checkedFragility}
                  onChange={() => setCheckedFragility(!checkedFragility)}
                />
              }
              inputRef={register}
              name="acceptProcessFragility"
              labelPlacement="end"
              label="Permitir personalização manual de risco"
            />
          </Grid>
          <Grid item xs={12} className={classes.checkBox}>
            <Controller
              as={
                <FormControlLabel
                  control={<Checkbox color="primary" />}
                  labelPlacement="end"
                  label="Notificar os responsáveis de um departamento sobre mudanças nos processos"
                />
              }
              name="notifyDataProcessChanges"
              control={control}
            />
          </Grid>
        </Grid>
      )}
    </Card>
  )
}

PermissionForm.propTypes = {
  register: PropTypes.func,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  disableCertFields: PropTypes.bool,
  company: PropTypes.object,
}

PermissionForm.defaultProps = {
  company: {},
  register: () => {},
  setChecked: () => {},
}

export default PermissionForm
