import constants from 'constants/index'

const pending = (dataProcess) =>
  dataProcess?.statusId === constants.dataProcess.PENDING_STATUS

const disapproved = (dataProcess) =>
  dataProcess?.statusId === constants.dataProcess.DISAPPROVED_STATUS

const approved = (dataProcess) =>
  dataProcess?.statusId === constants.dataProcess.APPROVED_STATUS

const inactive = (dataProcess) =>
  dataProcess?.statusId === constants.dataProcess.INACTIVE_STATUS

const reviewing = (dataProcess) =>
  dataProcess?.statusId === constants.dataProcess.REVIEWING_STATUS

const waitingReview = (dataProcess) =>
  dataProcess?.statusId === constants.dataProcess.WAITING_REVIEW_STATUS

const autoReview = (dataProcess) =>
  dataProcess?.statusId === constants.dataProcess.AUTO_REVIEW_STATUS

const statusesCheck = {
  pending,
  disapproved,
  approved,
  inactive,
  reviewing,
  waitingReview,
  autoReview,
}
export default statusesCheck
