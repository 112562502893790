import PropTypes from 'prop-types'
import { TableRow, TableCell } from 'components/Table'
import { MenuButton, Permitted } from 'components'
import RemoveGroup from '../RemoveGroup'
import { Chip, Button } from '@material-ui/core'
import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from './styles'
import clsx from 'clsx'

import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { routes } from 'Routes'

const GroupsTableRow = ({ group, refresh }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <TableRow key={group.id}>
        <TableCell>{group?.id}</TableCell>
        <TableCell>{group?.name}</TableCell>
        <TableCell disableTooltip>
          <Chip
            className={clsx(classes.root, {
              [classes.active]: group.active,
              [classes.inactive]: !group.active,
            })}
            align="center"
            size="small"
            label={group.active ? 'Ativo' : 'Inativo'}
          />
        </TableCell>
        <TableCell align="center">
          {group?.inactivateAt
            ? helpers.formatters.date(group?.inactivateAt)
            : ' - '}
        </TableCell>
        <TableCell disableTooltip align="right">
          <Permitted
            someTags={[
              constants.permissions.GROUPS.EDIT,
              constants.permissions.GROUPS.DESTROY,
            ]}
          >
            <MenuButton>
              <Permitted tag={constants.permissions.GROUPS.EDIT}>
                <Button
                  fullWidth
                  size="small"
                  onClick={() =>
                    history.push(
                      reverse(routes.groups.edit, {
                        groupId: group?.id,
                      }),
                    )
                  }
                >
                  Editar
                </Button>
              </Permitted>
              <Permitted tag={constants.permissions.GROUPS.DESTROY}>
                <RemoveGroup groupId={group?.id} refresh={refresh} />
              </Permitted>
            </MenuButton>
          </Permitted>
        </TableCell>
      </TableRow>
    </>
  )
}

GroupsTableRow.propTypes = {
  group: PropTypes.object,
  refresh: PropTypes.func,
}

GroupsTableRow.defaultProps = {
  refresh: () => {},
}

export default GroupsTableRow
