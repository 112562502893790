import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import {
  Avatar,
  Badge,
  Box,
  Grid,
  Paper,
  Tooltip,
  makeStyles,
} from '@material-ui/core'
import { getBaseURL } from 'service/env'

import helpers from 'helpers'

import styles from './styles'
const useStyles = makeStyles(styles)

const SupplierAvatar = ({ company }) => {
  const classes = useStyles()
  const logoPath =
    company?.logo?.url && `${getBaseURL('dponet')}${company?.logo?.url}`

  const avatarLetters = (company?.name || 'Empresa Fornecedora')
    .split(' ')
    .map((word) => word[0])
    .join('')

  const Icon = helpers.companySuppliers.iconByStatus(company?.status)

  return (
    <Grid item xs={12}>
      <Box
        pt={4}
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.avatarRoot}
      >
        <Badge
          overlap="circular"
          classes={{ badge: classes.avatarBadge }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          badgeContent={
            <Tooltip title={helpers.companySuppliers.label(company?.status)}>
              <Avatar
                className={clsx(
                  helpers.companySuppliers.chipStatusColor(
                    company?.status,
                    classes,
                  ),
                  classes.badgeSize,
                )}
              >
                <Icon size={22} />
              </Avatar>
            </Tooltip>
          }
        >
          <Paper
            square
            elevation={3}
            component={Avatar}
            className={classes.avatar}
            src={logoPath}
            imgProps={{ className: classes.avatarImage }}
          >
            {avatarLetters[0]}
            {avatarLetters[avatarLetters.length - 1]}
          </Paper>
        </Badge>
      </Box>
    </Grid>
  )
}

SupplierAvatar.propTypes = {
  company: PropTypes.object,
}

export default SupplierAvatar
