import { Page, Container, ContentHeader } from 'components'
import { ProfilesForm } from 'components/Profiles'

const CollaboratorProfilesNew = () => {
  return (
    <Page title="Novo perfil de colaborador">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Novo"
          menu="Perfis de colaborador"
          subtitle="Novo perfil de colaborador"
        />
        <ProfilesForm />
      </Container>
    </Page>
  )
}

export default CollaboratorProfilesNew
