import * as yup from 'yup'

const schemaActivility = () =>
  yup.object().shape({
    name: yup.string().required(),
    classCnae: yup.string(),
    active: yup.bool(),
    session: yup.string(),
    division: yup.string(),
    group: yup.string(),
  })

export default schemaActivility
