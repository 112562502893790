import { useState, useEffect } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'

import { Controller } from 'react-hook-form'

import * as service from 'service'

const Contractor = ({
  errors,
  isNew,
  control,
  setLoading = () => {},
  preRegistrationIds,
}) => {
  const [preRegistrations, setPreRegistrations] = useState([])
  const [nameds, setNameds] = useState('')

  const observable = isNew && nameds?.length >= 3

  const getNameds = async () => {
    setLoading(true)
    try {
      const response = await service.dponet.preRegistrations.get({
        perPage: 1000,
        nameOrDocument: nameds,
        idNotIn: preRegistrationIds,
        originsToExclude: ['easy', 'easy_promotion'],
      })

      setPreRegistrations(response?.data?.preRegistrations || [])
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (observable) getNameds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observable])

  return (
    <Controller
      as={
        <Autocomplete
          disabled={!isNew}
          options={preRegistrations}
          getOptionLabel={(option) =>
            `${option?.name} - ${option?.document}` || ''
          }
          noOptionsText={
            !observable && 'Digite pelo menos 3 caracteres para buscar'
          }
          getOptionSelected={(option, value) =>
            !value || option?.id === value?.id || value?.id === 0
          }
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!errors?.preRegistration}
              helperText={errors?.preRegistration?.message}
              label="Listagem de clientes e sublicenciamentos"
              variant="outlined"
              onChange={(e) => setNameds(e.target.value)}
            />
          )}
        />
      }
      control={control}
      name="preRegistration"
      mode="onChange"
      onChange={([, data]) => {
        return data
      }}
    />
  )
}

export default Contractor
