import * as service from 'service'

const mountCompanyParams = (companyInputValue, filters) => {
  const defaultParams = {
    minimal: true,
    perPage: 1000 * 1000,
  }

  if (!!companyInputValue) {
    return {
      name: companyInputValue,
      ...defaultParams,
    }
  }

  return {
    id: filters?.companyId,
    ...defaultParams,
  }
}

const mountOriginCompaniesParams = (originCompaniesInputValue, filters) => {
  const defaultParams = {
    companyKind: 'T',
    minimal: true,
    perPage: 1000 * 1000,
  }

  if (!!originCompaniesInputValue) {
    return {
      name: originCompaniesInputValue,
      ...defaultParams,
    }
  }

  return {
    ids: filters?.originCompanies,
    ...defaultParams,
  }
}

const mountSupplierParams = (suppliersValue, filters) => {
  const defaultParams = {
    companyKind: 'S',
    minimal: true,
    perPage: 1000 * 1000,
  }

  if (!!suppliersValue) {
    return {
      name: suppliersValue,
      ...defaultParams,
    }
  }

  return {
    id: filters?.companyId,
    ...defaultParams,
  }
}

const loadCompanies = async (params, setIsLoading, setCompanies) => {
  setIsLoading(true)

  const response = await service.dponet.companies.get(params)
  const responseCompanies = response.data?.companies || []

  setCompanies(responseCompanies)

  setIsLoading(false)

  return responseCompanies
}

const instructionsText = (companyInputValue) => {
  return companyInputValue.length < 3
    ? 'Digite no mínimo 3 letras pra buscar'
    : 'Sem opções'
}

const typeAndSearch = {
  loadCompanies,
  mountOriginCompaniesParams,
  mountCompanyParams,
  instructionsText,
  mountSupplierParams,
}

export default typeAndSearch
