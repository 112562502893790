import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import {
  Box,
  TextField,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { BaseDialog, Select, Label } from 'components'

import useFetch from 'hooks/useFetch'
import { Controller, useForm } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'

import constants from 'constants/index'
import * as service from 'service'

const CreateAgentDialog = ({
  open,
  setOpen,
  isCompanySelfPresent,
  refresh,
  dataProcessId,
  companyId,
}) => {
  const [radioValue, setRadioValue] = useState(null)

  const snackbar = useSnackbar()

  const { control, errors, setValue, handleSubmit } = useForm({
    defaultValues: {
      agentType: '',
      name: '',
      document: '',
    },
  })

  const { CONTROLLER_TYPE, OPERATOR_TYPE, CONTROLLER_LABEL, OPERATOR_LABEL } =
    constants.dataTreatmentAgent

  const { response } = useFetch(
    service.dponet.dataTreatmentAgents.listOptions,
    { dataProcessId, companyId },
    [],
  )

  const handleCreate = (agent) =>
    service.dponet.dataTreatmentAgents.create({
      dataProcessId,
      not_validation: true,
      agents: [agent],
    })

  const onSubmit = async (data) => {
    try {
      if (!isCompanySelfPresent()) {
        await handleCreate({ agentType: radioValue, companySelf: true })
        setRadioValue(null)
        refresh()
      } else {
        await handleCreate(data)
        refresh()
        setOpen(false)
      }

      snackbar.open({
        message: 'Agente adicionado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      setRadioValue(null)
      setOpen(false)
      snackbar.open({
        message: 'Erro ao adicionar o agente!',
        variant: 'error',
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="agent-edit-form">
      <BaseDialog
        dialogSize="sm"
        open={open}
        setOpen={setOpen}
        closeButtonText="Cancelar"
        actionButtonText="Cadastrar"
        formRef="agent-edit-form"
        title="Adicionar novo agente"
      >
        <>
          {!isCompanySelfPresent() ? (
            <Box>
              <Box mb={1}>
                <Typography variant="body1">
                  Antes de adicionar os agentes selecione o seu papel nesse
                  processo
                </Typography>
              </Box>
              <RadioGroup
                onClick={(event) => setRadioValue(event.target.value)}
                defaultValue={CONTROLLER_TYPE}
              >
                <FormControlLabel
                  value={CONTROLLER_TYPE}
                  control={<Radio />}
                  label={CONTROLLER_LABEL}
                  checked={radioValue === CONTROLLER_TYPE}
                />
                <FormControlLabel
                  value={OPERATOR_TYPE}
                  control={<Radio />}
                  label={OPERATOR_LABEL}
                  checked={radioValue === OPERATOR_TYPE}
                />
              </RadioGroup>
            </Box>
          ) : (
            <Grid container spacing={2}>
              {!isEmpty(response?.data) && (
                <Grid item xs={12}>
                  <Box mb={2}>
                    <Label title="Buscar organizações">
                      <Controller
                        control={control}
                        name="searchAgent"
                        onChange={([, data]) => {
                          setValue('document', data.document)
                          setValue('name', data.name)
                          return data
                        }}
                        mode="onChange"
                        as={
                          <Autocomplete
                            options={response?.data}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) =>
                              option.name === value.name || value.name === 0
                            }
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" />
                            )}
                          />
                        }
                      />
                    </Label>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Label
                  title={
                    !isEmpty(response?.data) ? 'Adicionar nova organização' : ''
                  }
                >
                  <Controller
                    as={
                      <TextField
                        label="Organização"
                        color="primary"
                        variant="outlined"
                        error={!!errors?.name}
                        helperText={errors?.name && 'Preencha este campo'}
                        fullWidth
                      />
                    }
                    control={control}
                    rules={{ required: isCompanySelfPresent() }}
                    name="name"
                    mode="onBlur"
                  />
                </Label>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      inputProps={{
                        maxLength: 18,
                      }}
                      label="CPF/CNPJ"
                      color="primary"
                      variant="outlined"
                      error={!!errors?.document}
                      helperText={errors?.document && 'Preencha este campo'}
                      fullWidth
                    />
                  }
                  control={control}
                  name="document"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <Select
                      items={constants.dataTreatmentAgent.OPTIONS_TYPES}
                      label="Tipo"
                      error={!!errors?.agentType}
                      helperText={errors?.agentType && 'Preencha este campo'}
                      fullWidth
                    />
                  }
                  control={control}
                  rules={{ required: isCompanySelfPresent() }}
                  name="agentType"
                  mode="onBlur"
                />
              </Grid>
            </Grid>
          )}
        </>
      </BaseDialog>
    </form>
  )
}

CreateAgentDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isCompanySelfPresent: PropTypes.func,
  refresh: PropTypes.func,
  dataProcessId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

CreateAgentDialog.defaultProps = {
  isCompanySelfPresent: () => {},
  setOpen: () => {},
  refresh: () => {},
}

export default CreateAgentDialog
