import { Box } from '@material-ui/core'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { IconButton } from 'components'
import { VisibilityOutlined as VisibilityOutlinedIcon } from '@material-ui/icons'

import PerfectScrollbar from 'react-perfect-scrollbar'

import usePermissions from 'hooks/usePermissions'
import { useHistory } from 'react-router-dom'

import constants from 'constants/index'
import { reverse } from 'named-urls'
import { routes } from 'Routes'

import PropTypes from 'prop-types'

const AffectedProcessTableRow = ({ dataProcess }) => {
  const history = useHistory()

  const handleOpenDataProcess = (dataProcessId) =>
    history.push(
      reverse(routes.dataProcess.view, { dataProcessId: dataProcessId }),
    )

  return (
    <TableRow>
      <TableCell>{dataProcess?.id}</TableCell>
      <TableCell>{dataProcess?.name}</TableCell>
      <TableCell>{dataProcess?.department?.name}</TableCell>
      <TableCell>{dataProcess?.code}</TableCell>
      <TableCell disableTooltip align="right">
        <IconButton
          size="small"
          Icon={VisibilityOutlinedIcon}
          text="Visualizar"
          onClick={() => handleOpenDataProcess(dataProcess?.id)}
          noHover
        />
      </TableCell>
    </TableRow>
  )
}

const AffectedProcessTable = ({ dataProcesses }) => {
  const { permitted } = usePermissions()
  const widthPercentage = permitted(constants.permissions.DATA_PROCESSES.LIST)
    ? ['10%', '40%', '35%', '15%']
    : ['10%', '35%', '25%', '15%']
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhum processo afetado." noWrap>
          <TableHead>
            <TableRow>
              <TableCell width={widthPercentage[0]} align="left">
                ID
              </TableCell>
              <TableCell width={widthPercentage[1]} align="left">
                Nome
              </TableCell>
              <TableCell width={widthPercentage[2]} align="left">
                Departamento
              </TableCell>
              <TableCell width={widthPercentage[3]} align="left">
                Código
              </TableCell>
              {permitted(constants.dataProcess.SHOW) && (
                <TableCell width="15%" align="right">
                  Ações
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataProcesses?.map((dataProcess) => (
              <AffectedProcessTableRow
                dataProcess={dataProcess}
                key={dataProcess.id}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

AffectedProcessTable.prototype = {
  dataProcesses: PropTypes.array,
}
export default AffectedProcessTable
