import PropTypes from 'prop-types'

import { Box, Grid, Hidden, Typography } from '@material-ui/core'
import { SupplierDetails } from '..'
import CompanyCompliance from '../CompanyCompliance'

const GeneralInformation = ({ company }) => {
  return (
    <Box mt={4}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <Typography variant="h4" gutterBottom>
            Dados empresariais
          </Typography>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={12} sm={6} lg={6}>
            <Typography variant="h4" gutterBottom>
              Conformidade da empresa
            </Typography>
          </Grid>
        </Hidden>
        <SupplierDetails company={company} />
        <CompanyCompliance companyCompliance={company?.companyCompliance} />
      </Grid>
    </Box>
  )
}

GeneralInformation.propTypes = {
  company: PropTypes.object,
}

export default GeneralInformation
