import permissions from '../../permissions'

const CHURN_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE,
    tag: permissions.CHURNS.MANAGE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.CHURNS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.SHOW,
    tag: permissions.CHURNS.SHOW,
  },
]

export default CHURN_LIST
