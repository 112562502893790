import PropTypes from 'prop-types'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import constants from 'constants/index'

import { ConfirmationDialog } from 'components'

import helpers from 'helpers'

const InactivateDataProcessDialog = ({
  dataProcessId,
  open,
  setOpen = () => {},
  action = () => {},
  setLoading,
}) => {
  const snackbar = useSnackbar()

  const handleInactivate = async () => {
    try {
      setLoading(true)
      await service.dponet.dataProcesses.changeStatus({
        dataProcessId: dataProcessId,
        statusId: constants.dataProcess.INACTIVE_STATUS,
      })
      snackbar.open({
        message: 'Processamento de dados inativado com sucesso!',
        variant: 'success',
      })
      setOpen(false)
      action()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
      setOpen(false)
    }
    setLoading(false)
  }

  return (
    <ConfirmationDialog
      open={open}
      setOpen={setOpen}
      message="Ao inativar um processo, você está indicando que a atividade de
          tratamento de dados pessoais não existe mais na rotina da sua empresa."
      cancelButtonText="Cancelar"
      buttonText="Confirmar inativação"
      actionAcceptButton={handleInactivate}
    />
  )
}

InactivateDataProcessDialog.propTypes = {
  dataProcessId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
}

InactivateDataProcessDialog.defaultProps = {
  setOpen: () => {},
  action: () => {},
  setLoading: () => {},
}

export default InactivateDataProcessDialog
