import PropTypes from 'prop-types'

import { useSelectCountry, useDataProcess, useSnackbar } from 'hooks'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

import Form from './components/Form'

import helpers from 'helpers'
import * as service from 'service'
import constants from 'constants/index'

const DialogDataTreatment = ({
  dataProcessId,
  open,
  setLoading,
  setOpen,
  onEvent,
  ...props
}) => {
  const { reloadOptions, reloadDataProcess } = useDataProcess()
  const snackbar = useSnackbar()
  const edition = !!props?.dataTreatment?.id

  const { selectedCountries, manySelectedCountries } = useSelectCountry()

  const onSubmit = (dataTreatment) => {
    if (props.dataTreatment && props.dataTreatment.id) {
      handleEdit(dataTreatment)
    } else {
      handleCreate(dataTreatment)
    }
  }

  const handleCreate = async (dataTreatment) => {
    try {
      setLoading(true)

      let response = await service.dponet.dataTreatments.create({
        dataProcessId: dataProcessId,
        dataTreatment: {
          ...dataTreatment,
          international_transfer_countries: selectedCountries,
        },
      })

      await submitSharedSoftwares(
        dataTreatment.sharedSoftwares,
        response?.data?.dataTreatment?.id,
        response?.data?.dataTreatment?.dataProcessId,
      )
      onEvent()
      setOpen(false)
      snackbar.open({
        message: 'Compartilhamento de dados criado com sucesso!',
        variant: 'success',
      })
      reloadDataProcess()
      reloadOptions()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
    setLoading(false)
  }

  const handleEdit = async (dataTreatment) => {
    try {
      setLoading(true)

      let response = await service.dponet.dataTreatments.put({
        dataProcessId: dataProcessId,
        dataTreatmentId: dataTreatment.dataTreatmentId,
        dataTreatment: {
          ...dataTreatment,
          international_transfer_countries: selectedCountries,
        },
      })

      await submitSharedSoftwares(
        dataTreatment.sharedSoftwares,
        response?.data?.dataTreatment?.id,
        response?.data?.dataTreatment?.dataProcessId,
      )

      onEvent()
      snackbar.open({
        message: 'Compartilhamento de dados atualizado com sucesso!',
        variant: 'success',
      })
      reloadDataProcess()
      reloadOptions()
      setOpen(false)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
    setLoading(false)
  }

  const submitSharedSoftwares = async (
    sharedSoftwares,
    dataTreatmentId,
    dataProcessId,
  ) => {
    if (!sharedSoftwares?.length) return

    for (let index in sharedSoftwares) {
      let sharedSoftware = sharedSoftwares[index]

      const defaultInternationTransfers =
        sharedSoftware.serverAllocation ===
        constants.sharedSoftware.NATIONAL_SERVER_ALLOCATION_TYPE
          ? []
          : manySelectedCountries[`sharedSoftwares-${index}`]

      if (!!sharedSoftware.id) {
        await service.dponet.sharedSoftwares.put({
          dataProcessId,
          dataTreatmentId,
          sharedSoftwareId: sharedSoftware.id,
          sharedSoftware: {
            ...sharedSoftware,
            international_transfer_countries: defaultInternationTransfers,
          },
        })
      } else {
        await service.dponet.sharedSoftwares.create({
          dataProcessId,
          dataTreatmentId,
          sharedSoftware: {
            ...sharedSoftware,
            international_transfer_countries: defaultInternationTransfers,
          },
        })
      }
    }
  }

  const handleClose = (_, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h4">
            {edition ? 'Editar' : 'Adicionar'} compartilhamento de dados
          </Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Form
          onSubmit={onSubmit}
          onEvent={onEvent}
          dataTreatment={props.dataTreatment}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Voltar
          </Button>
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          form="data-treatment-form"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogDataTreatment.propTypes = {
  dataProcessId: PropTypes.number.isRequired,
  open: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
  onEvent: PropTypes.func,
}

DialogDataTreatment.defaultProps = {
  setLoading: () => {},
  setOpen: () => {},
  onEvent: () => {},
}

export default DialogDataTreatment
