import helpers from 'helpers'
import components from '../../components'

import { GAP } from '../../constants'

import palette from 'theme/palette'

const riskConsequence = async (pdf, date, { incident }) => {
  const incidentRiskDataHolder = incident?.incidentRiskDataHolder

  pdf.addPage('a4', 'p')

  components.subtitle(pdf, 'Riscos e Consequências', GAP)

  components.lineWithDate(pdf, date)

  components.paragraph(
    pdf,
    'Riscos e Consequências aos Titulares dos Dados',
    GAP * 1.5,
    palette.BlueDownDark,
    'Roboto-Bold',
  )

  const incidentData = [
    [
      'Foi elaborado um Relatório de Impacto à Proteção de Dados Pessoais (RIPD) das atividades de tratamento afetadas pelo incidente?',
      helpers.formatters.convertBoolToString(
        incidentRiskDataHolder?.ripdPreparedIncident || 'N/A',
      ),
    ],
    [
      'Qual a quantidade total de titulares cujos dados são tratados nas atividades afetadas pelo incidente?',
      incidentRiskDataHolder?.totalHolderAffected || 'N/A',
    ],
    [
      'Se aplicável, descreva as categorias de titulares vulneráveis afetados:',
      incidentRiskDataHolder?.descriptionHolderCategoryAffected || 'N/A',
    ],
    [
      'Quais as categorias de titulares foram afetados pelo incidentes',
      incident?.dataOptionIncidents?.categoryHolderAffectedIncident
        ? incident?.dataOptionIncidents?.categoryHolderAffectedIncident
            .map((categoryAffected) => categoryAffected.name + '\n')
            .join('')
        : 'N/A',
    ],
    [
      'Informe a quantidade de titulares afetados, por categoria',
      incidentRiskDataHolder?.descriptionHolderCategoryAmountAffected || 'N/A',
    ],
    [
      'Quais as prováveis consequências do incidente para os titulares?',
      incident?.dataOptionIncidents?.probableConsequenceIncidentHolder
        ? incident?.dataOptionIncidents?.probableConsequenceIncidentHolder
            .map((probableConsequence) => probableConsequence.name + '\n')
            .join('')
        : 'N/A',
    ],
    [
      'Se cabível, descreva as prováveis consequências do incidente para cada grupo de titulares:',
      incidentRiskDataHolder?.descriptionPossibleConsequenceGroupHolder ||
        'N/A',
    ],
    [
      'Qual o provável impacto do incidente sobre os titulares? (admite só uma marcação)',
      helpers.incidents.optionImpactIncidentHolderStatus(
        incidentRiskDataHolder?.optionImpactIncidentHolder,
      ) || 'N/A',
    ],
    [
      'Se cabível, quais medidas foram adotadas para mitigação dos riscos causados pelo incidente aos titulares?',
      incidentRiskDataHolder?.measuresAdoptedMitigateIncidentHolder || 'N/A',
    ],
  ]

  components.table(pdf, [], incidentData, GAP * 1.6, 0.4)

  components.numberPage(pdf, palette.black, 2)
}

export default riskConsequence
