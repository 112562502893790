import PropTypes from 'prop-types'

import { Box, Typography, TextField, Grid, makeStyles } from '@material-ui/core'

import { Table, TableRow, TableCell, TableBody } from 'components/Table'

import styles from './styles'

import { LoadingBox } from 'components'

const useStyles = makeStyles(styles)

const LegalFrameworks = ({ data, isLoading }) => {
  const classes = useStyles()
  return (
    <>
      <Grid container className={classes.legalFrameworkContainer}>
        <Grid className={classes.legalFrameworkTitle} item xs={12}>
          <Typography variant="h5">Enquadramento Legal</Typography>
        </Grid>
        {isLoading ? (
          <LoadingBox />
        ) : (
          <>
            <Box display="flex" alignItems="flex-end" width="100%">
              <Grid container>
                <Grid item className={classes.inputContainer} md={6} xs={12}>
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    className={classes.topicContainer}
                  >
                    Qual é a base legal do tratamento?
                  </Typography>
                  <TextField
                    label="Base legal"
                    defaultValue={data.legalBase || 'Base legal não encontrada'}
                    multiline
                    rows={6}
                    fullWidth
                    variant="outlined"
                    disabled
                  />
                </Grid>
                <Grid item className={classes.inputContainer} md={6} xs={12}>
                  <Typography
                    color="textSecondary"
                    variant="subtitle2"
                    className={classes.topicContainer}
                  >
                    Legítimo interesse
                  </Typography>
                  <TextField
                    label="Legítimo Interesse (LIA)"
                    defaultValue={
                      data.lia || 'Legitimo interesse não encontrado'
                    }
                    multiline
                    rows={6}
                    fullWidth
                    variant="outlined"
                    disabled
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid container>
              <Grid item className={classes.inputContainer} md={4} xs={12}>
                <Typography
                  color="textSecondary"
                  variant="subtitle2"
                  className={classes.topicContainer}
                >
                  Enquadramentos legais
                </Typography>
                <Table
                  size="small"
                  emptyMessage="Nenhum enquadramento legal"
                  isLoading={isLoading}
                  noWrap
                >
                  <TableBody>
                    {!isLoading &&
                      data.legalFrameworks.map((legalFramework) => (
                        <TableRow key={legalFramework.id}>
                          <TableCell>{legalFramework?.name}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  )
}

LegalFrameworks.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default LegalFrameworks
