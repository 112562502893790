import { routes } from 'Routes'
import humps from 'humps'

const getHomePageByPermission = (permissions = []) => {
  const priorityList = {
    root: routes.root,
    dataProcesses: routes.dataProcess.all,
    companies: routes.companies.all,
    users: routes.users.all,
    userCollaborators: routes.collaborators.all,
    incidents: routes.incidents.all,
    preRegistrations: routes.preRegistrations.all,
  }

  const availableRoutes = camelizeArray(
    permissions
      .filter(filterByStatus)
      .filter(filterByList)
      .map((item) => item.tag),
  )

  for (let key in priorityList) {
    const newRoute = findByKey(availableRoutes, key)
    if (newRoute) {
      return priorityList[newRoute]
    }
  }

  return routes.noPermissions
}

const filterByStatus = (obj) => obj?.status
const filterByList = (obj) => obj?.tag?.includes('list')
const findByKey = (arr, key) => arr.find((item) => item === key)
const camelizeArray = (arr) =>
  arr.map((str) => humps.camelize(str?.replace('list_', '')))

export default getHomePageByPermission
