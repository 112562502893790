import { Typography, Box } from '@material-ui/core'

import useStyles from './styles'

import constants from 'constants/index'

const CouponTypes = () => {
  const classes = useStyles()

  return (
    <Box className={classes.mainBox} p={2} ml={2}>
      <Box display="flex">
        <Typography variant="body2">
          <strong>{constants.discountCoupon.VALUE_LABEL}: </strong> Insira um
          valor fixo de desconto diretamente no valor mensal do produto,
          reduzindo o preço final.
        </Typography>
      </Box>
      <Box display="flex">
        <Typography variant="body2">
          <strong>{constants.discountCoupon.PERCENTAGE_LABEL}: </strong> Aplique
          um desconto percentual no valor mensal do produto.
        </Typography>
      </Box>
      <Box display="flex">
        <Typography variant="body2">
          <strong>{constants.discountCoupon.FIXED_PRICE_LABEL}: </strong>
          Altere diretamente o valor do produto, sem a necessidade de calcular
          manualmente o desconto.
        </Typography>
      </Box>
    </Box>
  )
}

export default CouponTypes
