import constants from 'constants/companies'

const chipStatusColor = (status, classes) => {
  switch (status) {
    case constants.STATUSES.ACTIVE:
      return classes.chipActive
    case constants.STATUSES.ONBOARDING:
      return classes.chipOnboarding
    case constants.STATUSES.EVALUATION:
      return classes.chipEvaluation
    case constants.STATUSES.TRIAL_LIMITED:
      return classes.chipTrial
    case constants.STATUSES.TRIAL_COMPLETE:
      return classes.chipTrial
    case constants.STATUSES.TRIAL_CANCELED:
      return classes.chipTrialCanceled
    case constants.STATUSES.MAINTENANCE:
      return classes.chipMaintenance
    case constants.STATUSES.SUSPENDED:
      return classes.chipSuspended
    case constants.STATUSES.INACTIVE:
      return classes.chipInactive
    case constants.STATUSES.INDICATED:
      return classes.chipOnboarding
    case constants.STATUSES.TEST:
      return classes.chipTest
    default:
      return null
  }
}

export default chipStatusColor
