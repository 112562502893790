import { colors, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  button: (props) => ({
    width: !props.loading ? 'auto' : props.dimensions.width,
    backgroundColor: props.alert ? colors.red[600] : theme.palette.primary.main,
    '&:hover': {
      backgroundColor: props.alert
        ? colors.red[400]
        : theme.palette.primary.hover,
    },
  }),
}))

export default useStyles
