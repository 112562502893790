import { Grid } from '@material-ui/core'
import { DisplayCard } from '..'

import { Card } from 'components'

import helpers from 'helpers'

const ContextCard = ({ isLoading, title, children, data }) => {
  const gridMd = data?.length > 3 ? 3 : 4
  return (
    <Card title={title}>
      <Grid container spacing={2}>
        {data?.map((item) => (
          <Grid item md={gridMd}>
            <DisplayCard
              title={item?.title}
              amount={helpers.formatters.currencyInput(item?.amount)}
              quantity={item?.quantity || 0}
              position={item?.position}
              description={item?.description}
              isLoading={isLoading}
            />
          </Grid>
        ))}
        <Grid xs={12} item>
          {children}
        </Grid>
      </Grid>
    </Card>
  )
}

export default ContextCard
