import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Switch,
  FormControlLabel,
  Box,
  Typography,
  TextField,
  Button,
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { Plus as PlusIcon } from 'react-feather'
import { useHistory } from 'react-router-dom'

import { Label, Card, BackButton } from 'components'
import {
  QuestionsTable,
  QuestionDialog,
  QuestionInativationDialog,
} from './components'

import { useSnackbar, usePermissions } from 'hooks'
import helpers from 'helpers'
import schema from './schema'
import constants from 'constants/index'
import * as service from 'service'
import { routes } from 'Routes'

const QuestionnaireForm = ({ isEdit, questionnaire, refresh }) => {
  const [isVisible, setIsVisible] = useState(!!questionnaire?.visible)
  const [openQuestionDialog, setOpenQuestionDialog] = useState(false)
  const [questionMode, setQuestionMode] = useState(constants.mode.SHOW)
  const [selectedQuestion, setSelectedQuestion] = useState({})
  const [openInactivateQuestionDialog, setOpenInactivateQuestionDialog] =
    useState(false)

  const history = useHistory()
  const permissions = usePermissions()
  const snackbar = useSnackbar()
  const { control, register, handleSubmit } = useForm({
    validationSchema: schema(),
    defaultValues: {
      name: questionnaire?.name,
      visible: questionnaire?.visible,
    },
  })

  const hasPermissionToEditQuestionnaire = permissions.permitted(
    constants.permissions.QUESTIONNAIRE_BY_COMPANY.EDIT,
  )

  const handleClickOnVisibleSwitch = () => setIsVisible((state) => !state)

  const goToQuestionnaireMain = () => history.push(routes.questionnaires.all)

  const labelSwitch = () => (isVisible ? 'Sim' : 'Não')

  const onSubmit = async (questionnaireParams) => {
    if (!isEdit) return

    try {
      delete questionnaireParams.name

      await service.dponet.questionnaires.update({
        questionnaireId: questionnaire.id,
        questionnaire: questionnaireParams,
      })

      snackbar.open({
        message: 'Questionário editado com sucesso!',
        variant: 'success',
      })

      goToQuestionnaireMain()
    } catch (error) {
      const errorMessage =
        helpers.formatters.errorMessage(error?.response?.data?.error, false) ||
        'Erro ao editar questionário!'

      snackbar.open({ message: errorMessage, variant: 'error' })
    }
  }

  const handleOpenQuestionDialog = (mode, question = {}) => {
    question.questionnaireId = questionnaire.id

    setQuestionMode(mode)
    setSelectedQuestion(question)
    setOpenQuestionDialog(true)
  }

  const handleOpenInactivateQuestionDialog = (question) => {
    setSelectedQuestion(question)
    setOpenInactivateQuestionDialog(true)
  }

  const handleCloseQuestionDialog = () => {
    if (questionMode !== constants.mode.SHOW)
      setQuestionMode(constants.mode.SHOW)
    if (selectedQuestion !== {}) setSelectedQuestion({})
    if (openQuestionDialog) setOpenQuestionDialog(false)
    if (openInactivateQuestionDialog) setOpenInactivateQuestionDialog(false)
  }

  return (
    <Grid item xs={12}>
      <Box mt={4} mb={2}>
        <Typography variant="h4">Informações gerais</Typography>
      </Box>
      <Card title="Informações Gerais">
        <form id="form-questionnaire" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Label
                padBuTitle={0}
                titleVariant="body1"
                title="Exibir questionário?"
                marginTopChildren={0}
              >
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isVisible}
                        onChange={handleClickOnVisibleSwitch}
                        disabled={!isEdit}
                      />
                    }
                    inputRef={register}
                    name="visible"
                  />
                  <Typography variant="body1">{labelSwitch()}</Typography>
                </Box>
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Tipo de questionário"
                    type="text"
                    color="primary"
                    variant="outlined"
                    fullWidth
                    disabled
                  />
                }
                control={control}
                name="name"
                mode="onBlur"
              />
            </Grid>
          </Grid>
        </form>
      </Card>

      <Box mt={4} mb={2}>
        <Typography variant="h4">Questões</Typography>
      </Box>
      <Card
        title="Questões"
        paddingContent={0}
        actionButton={
          <>
            {hasPermissionToEditQuestionnaire && isEdit && (
              <Button
                color="primary"
                variant="outlined"
                startIcon={<PlusIcon />}
                onClick={() => handleOpenQuestionDialog(constants.mode.CREATE)}
              >
                Novo
              </Button>
            )}
          </>
        }
      >
        <QuestionsTable
          isEdit={isEdit}
          questions={questionnaire.questions}
          handleOpenQuestionDialog={handleOpenQuestionDialog}
          handleOpenInactivateQuestionDialog={
            handleOpenInactivateQuestionDialog
          }
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
        />
      </Card>

      <Box mt={3} display="flex">
        <BackButton onClick={goToQuestionnaireMain} />
        {isEdit && (
          <Box ml={2}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              form="form-questionnaire"
            >
              Salvar
            </Button>
          </Box>
        )}
      </Box>

      <QuestionDialog
        question={selectedQuestion}
        mode={questionMode}
        open={openQuestionDialog}
        handleClose={handleCloseQuestionDialog}
        refresh={refresh}
      />
      <QuestionInativationDialog
        open={openInactivateQuestionDialog}
        setOpen={handleCloseQuestionDialog}
        handleCloseQuestionDialog={handleCloseQuestionDialog}
        questionnaire={questionnaire}
        selectedQuestion={selectedQuestion}
        refresh={refresh}
      />
    </Grid>
  )
}

QuestionnaireForm.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  questionnaire: PropTypes.object,
  refresh: PropTypes.func.isRequired,
}

QuestionnaireForm.defaultProps = {
  questionnaire: {},
}

export default QuestionnaireForm
