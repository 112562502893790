import dataProcessList from './dataProcessList'
import companyList from './companyList'
import departmentList from './departmentList'
import userList from './userList'
import clientProfileList from './clientProfileList'
import collaboratorList from './collaboratorList'
import collaboratorProfileList from './collaboratorProfileList'
import incidentList from './incidentList'
import exportInfoList from './exportInfoList'
import exportRipdList from './exportRipdList'
import privacyPolicyList from './privacyPolicyList'
import questionnaireByCompanyList from './questionnaireByCompanyList'
import rmcResumeList from './rmcResumeList'
import rmcListingList from './rmcListingList'
import formOptionList from './formOptionList'
import legalFrameworkList from './legalFrameworkList'
import anpdContactList from './anpdContactList'
import documentationLinkList from './documentationLinkList'
import groupList from './groupList'
import subgroupList from './subgroupList'
import segmentList from './segmentList'
import economicActivityList from './economicActivityList'
import companySizeList from './companySizeList'
import planList from './planList'
import couponList from './couponList'
import preRegistrationList from './preRegistrationList'
import companySupplierList from './companySupplierList'
import reportList from './reportList'
import lastUpdatesList from './lastUpdateList'
import faqsDocumentationList from './faqsDocumentationList'
import documentTemplateList from './documentTemplateList'
import paymentContractList from './paymentContractList'
import paymentOrdersList from './paymentOrdersList'
import sellerList from './sellerList'
import churnList from './churnList'

const collaboratorProfileLists = {
  dataProcessList,
  companyList,
  departmentList,
  userList,
  clientProfileList,
  collaboratorList,
  collaboratorProfileList,
  incidentList,
  exportInfoList,
  exportRipdList,
  privacyPolicyList,
  questionnaireByCompanyList,
  rmcResumeList,
  rmcListingList,
  formOptionList,
  legalFrameworkList,
  anpdContactList,
  documentationLinkList,
  groupList,
  subgroupList,
  segmentList,
  economicActivityList,
  companySizeList,
  planList,
  couponList,
  preRegistrationList,
  companySupplierList,
  reportList,
  lastUpdatesList,
  faqsDocumentationList,
  documentTemplateList,
  paymentContractList,
  paymentOrdersList,
  sellerList,
  churnList,
}

export default collaboratorProfileLists
