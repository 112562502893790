import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { Check as CheckIcon, Moon as MoonIcon } from 'react-feather'

import { Page, Container, ContentHeader, StatusCard } from 'components'
import { FormUsers, ChangeStatusButton } from 'components/Users'

import { useFetch } from 'hooks'

import * as service from 'service'
import palette from 'theme/palette'

const UsersEdit = ({ match }) => {
  const {
    params: { userId },
  } = match

  const { response, isLoading, refresh } = useFetch(
    service.dponet.user.get,
    {
      userId,
    },
    [],
  )
  const user = response?.data?.user
  const activeUser = user?.active

  return (
    <Page title="Edição de usuário">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Edição"
          menu="Usuários"
          subtitle="Edição de usuário"
        >
          {activeUser && (
            <ChangeStatusButton
              user={user}
              isCollaborator={false}
              refresh={refresh}
            />
          )}
        </ContentHeader>

        {!isLoading && (
          <>
            <Box mb={2}>
              <StatusCard
                backgroundIconColor={
                  activeUser ? palette.greenPantone : palette.secondary.light
                }
                icon={
                  activeUser ? (
                    <CheckIcon size={18} color="white" />
                  ) : (
                    <MoonIcon size={18} color="white" />
                  )
                }
                label="STATUS"
                value={activeUser ? 'Ativo' : 'Inativo'}
              />
            </Box>
            <FormUsers isEdit userClient user={user} refreshUser={refresh} />
          </>
        )}
      </Container>
    </Page>
  )
}

UsersEdit.propTypes = {
  match: PropTypes.object,
}

export default UsersEdit
