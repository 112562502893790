import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import { Label } from 'components'
import useStyles from '../../styles'
import helpers from 'helpers'
import PropTypes from 'prop-types'

const PlanCard = ({ plan }) => {
  const classes = useStyles()

  return (
    <Card>
      <CardHeader title="Dados do plano" />
      <Divider />
      <Box className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Label title="Nome">
              <Typography>{plan?.name}</Typography>
            </Label>
          </Grid>
          <Grid item xs={12} md={6}>
            <Label title="Segmento">
              <Typography>{plan?.segment?.name}</Typography>
            </Label>
          </Grid>
          <Grid item xs={12} md={6}>
            <Label title="Valor">
              <Typography>
                R$ {helpers.formatters.currencyInput(plan?.price)}
              </Typography>
            </Label>
          </Grid>
          <Grid item xs={12} md={6}>
            <Label title="Empresa modelo">
              <Typography>{plan?.companyTemplate?.name}</Typography>
            </Label>
          </Grid>
          <Grid item xs={12} md={6}>
            <Label title="Porte">
              <Typography>
                {helpers.functions.letterOnly(plan?.code || '-')}
              </Typography>
            </Label>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

PlanCard.propTypes = {
  plan: PropTypes.object,
}

export default PlanCard
