import { Grid } from '@material-ui/core'

import PropTypes from 'prop-types'

import {
  AlertCircle as AlertCircleIcon,
  Layers as LayersIcon,
  Flag as FlagIcon,
} from 'react-feather'

import helpers from 'helpers'

import { CardInfo } from '../'

const CardBar = ({ rmc }) => {
  const rmcOriginName = helpers.nonComplianceReports.getOriginName(rmc)
  const fragilityLabel = helpers.dataProcesses.fragilityLabel(
    rmc?.entity?.dataProcess?.adoptedFragilityId,
  )

  if (rmcOriginName || rmc)
    return (
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <CardInfo
            icon={<LayersIcon />}
            title="Registro de origem"
            subtitle={rmcOriginName.name}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CardInfo
            icon={<AlertCircleIcon />}
            title="Origem"
            subtitle={helpers.nonComplianceReports.origin(rmc.finality)}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <CardInfo
            icon={<FlagIcon />}
            title="risco"
            subtitle={fragilityLabel || '-'}
          />
        </Grid>
      </Grid>
    )
}

CardBar.propTypes = {
  rmc: PropTypes.object,
}

export default CardBar
