import api from 'service/api'

const dponetAPI = api.create('dponet')
const dponetAPINotTreat = api.create('dponet', false)

const get = async ({ userId = '', ...params }) => {
  return await dponetAPI.get(`/users/${userId}`, { params })
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('/users', data)
}

const edit = async ({ userId = '', ...data }) => {
  return await dponetAPI.put(`/users/${userId}`, data)
}

const newPassword = async (data) => {
  return await dponetAPI.put(`/users/password`, { api_user: data })
}

const permissions = async () => {
  return await dponetAPI.get('/user/permissions')
}

const me = async () => {
  return await dponetAPI.get('/user/me')
}

const changeStatus = async ({ userId }) => {
  return await dponetAPINotTreat.put(`/users/${userId}/change_status`)
}

const updatePassword = async (data) => {
  return await dponetAPI.post(`/users/update_password`, data)
}

const updateStep = async ({ userId, stepId, ...data }) => {
  return await dponetAPI.put(`/users/${userId}/users_steps/${stepId}`, {
    user_step: data,
  })
}

const exportArchive = async ({ ...params }) => {
  return await dponetAPI.get('/users/export', { params })
}

const generateTemporaryPassword = async (userId) => {
  return await dponetAPI.put(`/users/${userId}/generate_temporary_password`)
}

const users = {
  get,
  create,
  edit,
  newPassword,
  permissions,
  me,
  changeStatus,
  updatePassword,
  updateStep,
  generateTemporaryPassword,
  exportArchive,
}

export default users
