import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import { useTheme } from '@material-ui/styles'
import {
  makeStyles,
  useMediaQuery,
  Container,
  Card,
  Grid,
  Box,
  TablePagination,
} from '@material-ui/core'

import { Page, ContentHeader, FilterButton, LoadingFeedback } from 'components'
import { RmcFilter } from './components'
import RmcTable from './components/RmcTable'

import { useFetch, useFilter, usePagination, useRmcsList } from 'hooks'

import styles from './styles'
import * as service from 'service'
import constants from 'constants/index'

const useStyles = makeStyles(styles)

const RmcMain = () => {
  const location = useLocation()
  const search = qs.parse(location.search)
  const filter = useFilter({ perPage: 10, page: 1, ...search })
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { loadingProvider } = useRmcsList()

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.nonComplianceReports.get,
    {
      perPage,
      page,
      entityTypeIn: ['DataProcess'],
      ...{ ...filter.filters },
    },
    [page, perPage, filter.filters],
  )

  const subtitleContent = () => {
    switch (filter.filters.entityType) {
      case constants.nonComplianceReports.ENTITY_DATA_PROCESS:
        return 'Processos'
      case constants.nonComplianceReports.ENTITY_QUESTION:
        return 'Questionários'
      case constants.nonComplianceReports.ENTITY_INCIDENT:
        return 'Incidentes'
      default:
        return 'Todos'
    }
  }

  const pageTitle = () => {
    switch (filter.filters.entityType) {
      case constants.nonComplianceReports.ENTITY_DATA_PROCESS:
        return 'Registros de melhoria contínua de processos'
      case constants.nonComplianceReports.ENTITY_QUESTION:
        return 'Questionários'
      case constants.nonComplianceReports.ENTITY_INCIDENT:
        return 'Registros de melhoria contínua de incidentes'
      default:
        return 'Todos os registros de melhoria contínua'
    }
  }

  const loading = loadingProvider || isLoading

  useEffect(() => {
    if (!search.entityType && !search.finality) {
      if (filter?.filters?.entityType) {
        filter.setFilters({
          ...filter.filters,
          entityType: null,
          finality: null,
        })
      }
      return
    }
    filter.setFilters({ ...search })
    refresh()
    // eslint-disable-next-line
  }, [search?.entityType, search?.finality])

  return (
    <>
      <LoadingFeedback open={loading} />
      <Page title={pageTitle()}>
        <Container maxWidth={false} className={classes.container}>
          <ContentHeader
            menu="RMC"
            title="Melhoria contínua"
            subtitle={subtitleContent()}
          >
            <Box ml={1}>
              <FilterButton setDrawerOpen={filter.setDrawerOpen} />
            </Box>
          </ContentHeader>
          <Card>
            <Box display="flex" width="100%" mt={2}>
              <Grid container>
                <RmcTable
                  nonComplianceReports={response?.data?.nonComplianceReports}
                  isLoading={loading}
                  onEvent={refresh}
                  entityType={search?.entityType}
                />
              </Grid>
            </Box>
            <Box px={2} display="flex" justifyContent="flex-end">
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            </Box>
          </Card>
          <RmcFilter filter={filter} search={search} />
        </Container>
      </Page>
    </>
  )
}

export default RmcMain
