import { Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import useStyles from './styles'

const GreyChip = ({ text, complement }) => {
  const classes = useStyles()

  return (
    <Box className={classes.chip}>
      <Typography variant="body1">
        {text}
        {!!complement && (
          <span className={classes.complement}> - {complement} </span>
        )}
      </Typography>
    </Box>
  )
}

GreyChip.propTypes = {
  text: PropTypes.string,
  complement: PropTypes.string,
}

export default GreyChip
