import StatusHeader from './StatusHeader'
import Tabs from './Tabs'
import GeneralInformation from './GeneralInformation'
import CardBar from './CardBar'
import DataCollecteds from './DataCollecteds'
import LifeCycles from './LifeCycles'
import DataTreatments from './DataTreatments'
import DocumentedInformation from './DocumentedInformation'
import NecessityProportionality from './NecessityProportionality'

const view = {
  StatusHeader,
  Tabs,
  GeneralInformation,
  CardBar,
  DataCollecteds,
  LifeCycles,
  DataTreatments,
  DocumentedInformation,
  NecessityProportionality,
}

export default view
