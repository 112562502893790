import { useState } from 'react'
import { Controller, FormContext, useForm } from 'react-hook-form'
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core'

import { Card, Label, Select } from 'components'
import { BenefitsSection } from './components'

import helpers from 'helpers'
import * as service from 'service'
import PropTypes from 'prop-types'

import { useSnackbar } from 'hooks'

import theme from 'theme'
import schema from './schema'
import useStyles from './styles'
import constants from 'constants/index'

const ResultStep = ({ churn, setLoading, handleNextStep, show }) => {
  const [hasBenefits, setHasBenefits] = useState(
    churn?.discount || churn?.gratuity ? true : false,
  )

  const classes = useStyles()
  const snackbar = useSnackbar()

  const formMethods = useForm({
    validationSchema: schema({ hasBenefits }),
    defaultValues: {
      reason: churn?.reason || '',
      status: churn?.status || '',
      description: churn?.description || '',
      currentValue: churn?.paymentContract?.price || '',
      discount: churn?.discount || '',
      gratuity: churn?.gratuity || '',
      benefits: churn?.discount ? 'discount' : 'freeMonthlyFee' || '',
    },
  })

  const { control, errors, handleSubmit, watch } = formMethods

  const status = watch('status')

  const isChurn = status === 'churn'
  const isConverted = status === 'converted'

  const onSubmit = async (data) => {
    setLoading(true)

    try {
      await service.dponet.churns.changeContract({
        churnId: churn.id,
        churn: {
          status: data?.status,
          reason: data?.reason,
          description: data?.description,
          discount: helpers.formatters.float.convert(data?.discount),
          gratuity: data?.gratuity,
        },
      })

      snackbar.open({
        message: `Resultado atualizado com sucesso!`,
        variant: 'success',
      })
      handleNextStep()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <FormContext {...formMethods}>
      <Box
        component="form"
        id={constants.churn.CHURN_STEPPER_RESULT_ID_FORM}
        onSubmit={handleSubmit(onSubmit)}
        className={classes.container}
      >
        <Card title="Informações após a reunião" paddingCard={0}>
          <Box className={classes.container}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  as={
                    <RadioGroup>
                      <Label title="Resultado da reunião" />
                      <Box display="flex" gridGap={theme.spacing(2)}>
                        <FormControlLabel
                          label="Convertido"
                          value="converted"
                          control={<Radio color="primary" />}
                          disabled={show}
                        />
                        <FormControlLabel
                          label="Não convertido"
                          value="churn"
                          control={<Radio color="primary" />}
                          disabled={show}
                        />
                      </Box>
                    </RadioGroup>
                  }
                  control={control}
                  name="status"
                />
                {!!errors?.status && (
                  <FormHelperText error={!!errors?.status}>
                    {errors?.status?.message}
                  </FormHelperText>
                )}
              </Grid>
              {isChurn && (
                <Grid item xs={12}>
                  <Controller
                    as={
                      <Select
                        items={constants.churn.REASON_CHURN_ITEMS}
                        label="Motivo"
                        disabled={show}
                      />
                    }
                    control={control}
                    name="reason"
                    mode="onChange"
                  />
                </Grid>
              )}
              {(isChurn || isConverted) && (
                <Grid item xs={12}>
                  <Controller
                    as={
                      <TextField
                        type="text"
                        color="primary"
                        variant="outlined"
                        label="Descrição do motivo"
                        error={!!errors?.description}
                        helperText={<>{errors?.description?.message}</>}
                        fullWidth
                        disabled={show}
                      />
                    }
                    control={control}
                    name="description"
                  />
                </Grid>
              )}
            </Grid>
            {isConverted && (
              <BenefitsSection
                hasBenefits={hasBenefits}
                setHasBenefits={setHasBenefits}
                show={show}
              />
            )}
          </Box>
        </Card>
      </Box>
    </FormContext>
  )
}

ResultStep.propTypes = {
  churn: PropTypes.object,
  setLoading: PropTypes.func,
  handleNextStep: PropTypes.func,
  show: PropTypes.bool,
}

export default ResultStep
