import { Box, Typography, List } from '@material-ui/core'

import PropsTypes from 'prop-types'

import helpers from 'helpers'

import { ListItemCustom } from '../'

import { isEmpty } from 'lodash'
import constants from 'constants/index'
import useStyles from './styles'

const NecessaryAndFragilityContent = ({ rmc }) => {
  const classes = useStyles()

  const unnecessaryCollectedFields =
    helpers.nonComplianceReports.filterUnnecessaryCollectedFields(rmc)

  const unnecessaryDataTreatments =
    helpers.nonComplianceReports.filterUnnecessaryDataTreatments(rmc)

  const unnecessaryLifeCycles =
    helpers.nonComplianceReports.filterUnnecessaryLifeCycles(rmc)

  return (
    <Box>
      <Typography variant="h5">Evidência de implantação</Typography>
      <Box mt={1} mb={2.5}>
        <Typography>
          Finalizada a reanálise de necessidade e proporcionalidade
        </Typography>
      </Box>
      {!isEmpty(unnecessaryCollectedFields) && (
        <Box mt={2}>
          <Typography variant="h5">Dados tratados</Typography>
          <List className={classes.root}>
            {unnecessaryCollectedFields.map((unnecessaryData) => (
              <ListItemCustom
                key={unnecessaryData.dataId}
                dataType={constants.unnecessaryData.COLLECTED_FIELD_TYPE}
                item={unnecessaryData}
                label={unnecessaryData.dataName}
              />
            ))}
          </List>
        </Box>
      )}
      {!isEmpty(unnecessaryDataTreatments) && (
        <Box mt={2}>
          <Typography variant="h5">Compartilhamento</Typography>
          <List className={classes.root}>
            {unnecessaryDataTreatments.map((unnecessaryData) => (
              <ListItemCustom
                key={unnecessaryData.dataId}
                dataType={constants.unnecessaryData.DATA_TREATMENT_TYPE}
                item={unnecessaryData}
                label={unnecessaryData.dataName}
              />
            ))}
          </List>
        </Box>
      )}

      {!isEmpty(unnecessaryLifeCycles) && (
        <Box mt={2}>
          <Typography variant="h5">Forma de proteção</Typography>
          <List className={classes.root}>
            {unnecessaryLifeCycles.map((unnecessaryData) => (
              <ListItemCustom
                key={unnecessaryData.dataId}
                dataType={constants.unnecessaryData.LIFE_CYCLES_TYPE}
                item={unnecessaryData}
                label={unnecessaryData.dataName}
              />
            ))}
          </List>
        </Box>
      )}
    </Box>
  )
}

NecessaryAndFragilityContent.propTypes = {
  rmc: PropsTypes.object,
}

export default NecessaryAndFragilityContent
