import { useState } from 'react'

import { NavLink as RouterLink } from 'react-router-dom'

import {
  Button,
  Collapse,
  ListItem,
  makeStyles,
  Typography,
} from '@material-ui/core'
import {
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@material-ui/icons'

import PropTypes from 'prop-types'

import styles from './styles'
import { Permitted } from 'components'

const useStyles = makeStyles(styles)
const NavItem = ({
  title,
  href,
  depth,
  permission,
  children,
  somePermissions,
  show,
  menuDisabled = false,
  icon: Icon,
  open: isOpen,
  info: Info,
  main,
  highlight,
  ...rest
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(isOpen)
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }
  let paddingLeft = 8
  if (depth > 0) {
    paddingLeft = 32 + 8 * depth
  }
  const btnStyle = { paddingLeft }

  if (children) {
    return (
      <Permitted
        tag={permission}
        someTags={somePermissions}
        key={title}
        show={show}
      >
        <ListItem className={classes.item} disableGutters key={title} {...rest}>
          <Button
            className={classes.button}
            onClick={handleToggle}
            style={btnStyle}
            fullWidth
            disableElevation={true}
            disabled={menuDisabled}
          >
            {Icon && <Icon className={classes.icon} size="20" />}
            <Typography variant="body1" className={classes.title}>
              {title}
            </Typography>
            {open ? (
              <ExpandLessIcon size="small" color="inherit" />
            ) : (
              <ExpandMoreIcon size="small" color="inherit" />
            )}
          </Button>
          <Collapse in={open}>{children}</Collapse>
        </ListItem>
      </Permitted>
    )
  }
  return (
    <Permitted tag={permission} someTags={somePermissions} key={title}>
      <ListItem
        className={main ? classes.item : classes.itemLeaf}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          activeClassName={classes.active}
          className={main ? classes.button : classes.buttonLeaf}
          component={RouterLink}
          exact
          style={btnStyle}
          fullWidth
          to={href}
          disabled={menuDisabled}
        >
          {Icon && <Icon className={classes.icon} size="20" />}
          {main ? (
            <Typography variant="body1" className={classes.title}>
              {title}
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.title}>
              {title}
            </Typography>
          )}

          {Info && <Info className={classes.info} />}
        </Button>
      </ListItem>
    </Permitted>
  )
}
NavItem.propTypes = {
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
}
NavItem.defaultProps = {
  open: false,
}
export default NavItem
