import { Tabs, Tab, Box } from '@material-ui/core'

import constants from 'constants/index'

const TabsMain = ({
  filter,
  currentTab,
  setCurrentTab,
  companies,
  hasOnGoingCompanyToAudit,
}) => {
  const tabs = hasOnGoingCompanyToAudit
    ? constants.dataProcess.TABS
    : [constants.dataProcess.ALL_PROCESSES_TAB]

  const handleChangeTab = (_, value) => {
    filter.setFilters({})
    setCurrentTab(value)
  }

  return (
    <Box>
      <Tabs
        value={currentTab}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="primary"
      >
        {tabs.map((tab) => (
          <Tab
            label={tab.label}
            value={tab.value}
            key={tab.value}
            onClick={(e) => handleChangeTab(e, tab.value)}
          />
        ))}
      </Tabs>
    </Box>
  )
}

export default TabsMain
