import { Card, Label } from 'components'

import useStyles from './styles'
import { Button, Chip, Grid } from '@material-ui/core'

import helpers from 'helpers'

const ContractDetails = ({ platformContract }) => {
  const classes = useStyles()
  if (!platformContract) return <></>

  const handleShowContract = () => {
    if (platformContract?.url) {
      window.open(platformContract.url, '__blank')
    }
  }

  return (
    <Card
      title="Detalhes do contrato no D4Sign"
      actionButton={
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleShowContract}
          disabled={!platformContract?.url}
        >
          Ver contrato
        </Button>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Label
              item
              xs={12}
              title="ID do contrato no D4Sign"
              description={platformContract?.externalId ?? '-'}
            />
            <Label item xs={12} title="Status do contrato">
              <Chip
                size="small"
                className={helpers.platformContracts.chipStatusColor(
                  platformContract?.status,
                  classes,
                )}
                label={helpers.platformContracts.chipStatusLabel(
                  platformContract?.status,
                )}
              />
            </Label>
            <Label
              item
              xs={12}
              title="Tipo de contrato"
              description={helpers.platformContracts.contractKindLabel(
                platformContract?.kind,
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          {platformContract?.data?.map((data) => (
            <Label title={data?.name} description={data?.email} />
          ))}
        </Grid>
      </Grid>
    </Card>
  )
}

export default ContractDetails
