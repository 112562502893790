import { Chip } from '@material-ui/core'
import clsx from 'clsx'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import useStyles from './styles'
import { Card } from 'components'
import formatters from 'helpers/formatters'

const CompaniesTable = ({ companies }) => {
  const classes = useStyles()

  return (
    <Card title="Empresas vinculadas" dropdown defaultExpanded>
      <Table emptyMessage="Nenhuma empresa encontrada">
        <TableHead>
          <TableRow>
            <TableCell width="25%">Token</TableCell>
            <TableCell width="35">Empresa</TableCell>
            <TableCell width="15%">Documento</TableCell>
            <TableCell width="10%">Status</TableCell>
            <TableCell width="15%">Data de criação</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companies?.map((company) => (
            <TableRow key={company?.token} hover>
              <TableCell disableTooltip noOverFlow>
                {company?.token}
              </TableCell>
              <TableCell>{company?.name}</TableCell>
              <TableCell>{formatters.cnpj(company?.cnpj)}</TableCell>
              <TableCell disableTooltip>
                <Chip
                  className={clsx(classes.root, {
                    [classes.active]: company?.active,
                    [classes.inactive]: !company?.active,
                  })}
                  align="center"
                  size="small"
                  label={company?.active ? 'Ativo' : 'Inativo'}
                />
              </TableCell>
              <TableCell>{formatters.date(company?.createdAt)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  )
}

export default CompaniesTable
