import PropTypes from 'prop-types'
import { TableRow, TableCell } from 'components/Table'
import helpers from 'helpers'
import constants from 'constants/index'
import { useHistory } from 'react-router-dom'
import qs from 'query-string'

const CompanyAuditsTableRow = ({ companyAudit }) => {
  const history = useHistory()

  const handleGoView = () => {
    history.replace(
      '/data-processes/' +
        '?' +
        qs.stringify({
          statusId: null,
          auditStatus: constants.auditProcess.WAITING_ACTION_STATUS,
          companyId: companyAudit.company.id,
        }),
    )
  }

  return (
    <TableRow key={companyAudit?.id} onClick={() => handleGoView()}>
      <TableCell pointer>
        {helpers.formatters.date(companyAudit?.startDate)}
      </TableCell>
      <TableCell pointer>
        {helpers.formatters.date(companyAudit?.endDate)}
      </TableCell>
      <TableCell pointer>{companyAudit?.company?.name}</TableCell>
      <TableCell pointer>{companyAudit?.processesOnAudit}</TableCell>
      <TableCell pointer>{companyAudit?.processesConfirmedCount}</TableCell>
      <TableCell pointer>{companyAudit?.processesUpdatedCount}</TableCell>
      <TableCell pointer>
        {companyAudit?.processesAutomaticallyApprovedCount}
      </TableCell>
      <TableCell pointer>{companyAudit?.processesWaitingActionCount}</TableCell>
      <TableCell pointer>{companyAudit?.successPercentage}</TableCell>
    </TableRow>
  )
}

CompanyAuditsTableRow.propTypes = {
  CompanyAudit: PropTypes.object,
}

export default CompanyAuditsTableRow
