import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Button } from '@material-ui/core'

import { MenuButton } from 'components'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'
import QuestionnaireStatusChip from '../QuestionnaireStatusChip'

import { routes } from 'Routes'
import constants from 'constants/index'
import { usePermissions } from 'hooks'

const QuestionnaireTable = ({ questionnaires }) => {
  const history = useHistory()
  const permissions = usePermissions()

  const hasPermissionToEditQuestionnaire = permissions.permitted(
    constants.permissions.QUESTIONNAIRE_BY_COMPANY.EDIT,
  )

  const navigateTo = (route, questionnaireId) => {
    history.push(reverse(route, { questionnaireId: questionnaireId }))
  }

  const handleNavigateToEditPage = (questionnaireId) => {
    navigateTo(routes.questionnaires.edit, questionnaireId)
  }

  const handleNavigateToShowPage = (questionnaireId) => {
    navigateTo(routes.questionnaires.show, questionnaireId)
  }

  return (
    <PerfectScrollbar
      options={{ wheelPropagation: false, useBothWheelAxes: true }}
    >
      <Table emptyMessage="Nenhum questionário encontrado" noWrap>
        <TableHead>
          <TableRow>
            <TableCell width="5%">ID</TableCell>
            <TableCell width="20%">Questionário</TableCell>
            <TableCell width="20%">Empresa</TableCell>
            <TableCell width="10%">Documento</TableCell>
            <TableCell width="10%">Status</TableCell>
            <TableCell width="10%">Visível</TableCell>
            <TableCell width="10%">Respondida</TableCell>
            <TableCell width="10%">Nº de questões</TableCell>
            <TableCell width="5%" align="right">
              Ações
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {questionnaires.map((questionnaire) => (
            <TableRow key={questionnaire.id}>
              <TableCell>{questionnaire.id}</TableCell>
              <TableCell>{questionnaire.name}</TableCell>
              <TableCell>{questionnaire.company?.name || '-'}</TableCell>
              <TableCell>{questionnaire.company?.document || '-'}</TableCell>
              <TableCell>
                <QuestionnaireStatusChip status={questionnaire.status} />
              </TableCell>
              <TableCell>{questionnaire.visible ? 'Sim' : 'Não'}</TableCell>
              <TableCell>{questionnaire.answerPercentage}%</TableCell>
              <TableCell>{questionnaire.totalQuestions}</TableCell>
              <TableCell disableTooltip align="right">
                <MenuButton>
                  {hasPermissionToEditQuestionnaire && (
                    <Button
                      color="secondary"
                      onClick={() => handleNavigateToEditPage(questionnaire.id)}
                      fullWidth
                      size="small"
                    >
                      Editar
                    </Button>
                  )}
                  <Button
                    color="secondary"
                    onClick={() => handleNavigateToShowPage(questionnaire.id)}
                    fullWidth
                    size="small"
                  >
                    Visualizar
                  </Button>
                </MenuButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </PerfectScrollbar>
  )
}

QuestionnaireTable.propTypes = {
  questionnaires: PropTypes.array,
}

QuestionnaireTable.defaultProps = {
  questionnaires: [],
}

export default QuestionnaireTable
