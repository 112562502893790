import helpers from 'helpers'

import constants from 'constants/index'

const mountNecessityAndProportionalityParams = (data, dataLifeCycleId) => {
  let dataLifeCycle = {}

  Object.keys(data).map((key) => {
    let splitKey = key.split('-')
    let keyType = splitKey[0]
    let keyId = splitKey[1]

    if (
      keyType === constants.unnecessaryData.LIFE_CYCLES_TYPE &&
      +keyId === dataLifeCycleId
    ) {
      return (dataLifeCycle = {
        necessary: helpers.formatters.convertBinaryToBool(data[key]),
      })
    }

    return key
  })

  return dataLifeCycle
}

export default mountNecessityAndProportionalityParams
