import constants from 'constants/index'

const necessary = (rmc) =>
  rmc.finality === constants.nonComplianceReports.NECESSARY_FINALITY

const fragility = (rmc) =>
  rmc?.finality === constants.nonComplianceReports.FRAGILITY_FINALITY

const fragilityAndNecessary = (rmc) =>
  rmc?.finality ===
  constants.nonComplianceReports.FRAGILITY_AND_NECESSARY_FINALITY

const finality = {
  necessary,
  fragility,
  fragilityAndNecessary,
}

export default finality
