import PropTypes from 'prop-types'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  TextField,
} from '@material-ui/core'
import { Label } from 'components'
import { useForm, Controller } from 'react-hook-form'

import * as service from 'service'
import useSnackbar from 'hooks/useSnackbar'
import helpers from 'helpers'
import schema from './schema'

const FormDeleteDialog = ({ open, setOpen, refresh, companyId }) => {
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema(),
    defaultValues: {
      description: '',
    },
  })

  const onSubmit = async (data) => {
    let { description } = data
    try {
      await service.dponet.companies.destroy({ companyId, description })

      snackbar.open({
        message:
          'Serviço de exclusão iniciado, este procedimento pode demorar até 5 minutos para ser concluído. O resultado será enviado para o seu e-mail',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
      })
    }
    setOpen(false)
    refresh()
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Box>
            <Typography variant="h5">Excluir empresa</Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box>
            <Typography variant="h4" align="center">
              Tem certeza que deseja excluir esta empresa? Não será possível
              reverter esta ação!
            </Typography>
          </Box>
          <Box pt={2}>
            <Label
              title="Motivo da exclusão"
              description="Descreva o motivo da exclusão"
              item
              xs={12}
              padBuTitle={0}
            >
              <Controller
                as={
                  <TextField
                    color="primary"
                    multiline
                    rows={3}
                    variant="outlined"
                    error={!!errors.description}
                    helperText={errors?.description?.message}
                    onKeyDown={(e) => {
                      e.stopPropagation()
                    }}
                    fullWidth
                  />
                }
                control={control}
                name="description"
                mode="onBlur"
              />
            </Label>
          </Box>
        </DialogContent>
        <DialogActions p={2}>
          <Box pl={2} pr={1}>
            <Button
              type="button"
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Cancelar
            </Button>
          </Box>
          <Button variant="contained" type="submit" color="primary">
            Excluir
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

FormDeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  companyId: PropTypes.number,
}

FormDeleteDialog.defaultProps = {
  refresh: () => {},
  setOpen: () => {},
}

export default FormDeleteDialog
