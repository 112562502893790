import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ ...params }) => {
  return await dponetAPI.get(`/questionnaires/all`, { params })
}

const show = async (questionnaireId) => {
  return await dponetAPI.get(`/questionnaires/${questionnaireId}`)
}

const update = async ({ questionnaireId, ...rest }) => {
  return await dponetAPI.put(`/questionnaires/${questionnaireId}`, rest)
}

const reply = async ({ questionnaireId, ...params }) => {
  return await dponetAPI.post(
    `/questionnaires/${questionnaireId}/reply`,
    params,
  )
}

const copy = async ({ ...data }) =>
  await dponetAPI.post('/questionnaires/copy', data)

const questionnaires = {
  get,
  show,
  update,
  reply,
  copy,
}

export default questionnaires
