import { Fragment, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { reverse } from 'named-urls'
import { Button, Chip, TableContainer } from '@material-ui/core'
import clsx from 'clsx'

import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from 'components/Table'
import { ConfirmationDialog, MenuButton } from 'components'

import { useSnackbar } from 'hooks'

import helpers from 'helpers'
import * as service from 'service'
import { routes } from 'Routes'
import useStyles from './styles'
import constants from 'constants/index'

const TechnicalFaqsTable = ({
  technicalFaqs,
  refresh,
  faqName,
  masculineGrammaticalGender,
}) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openActivationDialog, setOpenActivationDialog] = useState(false)
  const [openInactivationDialog, setOpenInactivationDialog] = useState(false)

  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()

  const handleDelete = async (technicalFaqId) => {
    try {
      await service.dponet.technicalFaqs.destroy({
        technicalFaqId,
      })
      snackbar.open({
        message: `${faqName} excluída com sucesso!`,
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setOpenDeleteDialog(false)
    }
  }

  const handleChangeStatus = async (technicalFaqId, status) => {
    try {
      await service.dponet.technicalFaqs.update({
        technicalFaqId,
        status,
      })
      snackbar.open({
        message: `${faqName} ${helpers.technicalFaqs.snackbarStatusTranslation(
          status,
          true,
        )} com sucesso!`,
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setOpenInactivationDialog(false)
      setOpenActivationDialog(false)
    }
  }

  return (
    <TableContainer>
      <PerfectScrollbar>
        <Table
          emptyMessage={`${
            masculineGrammaticalGender ? 'Nenhum' : 'Nenhuma'
          } ${faqName} ${
            masculineGrammaticalGender ? 'encontrado' : 'encontrada'
          }`}
        >
          <TableHead>
            <TableRow>
              <TableCell width="5%" align="left">
                ID
              </TableCell>
              <TableCell width="30" align="left">
                Título
              </TableCell>
              <TableCell width="15%" align="left">
                Categoria
              </TableCell>
              <TableCell width="15%" align="left">
                Subcategoria
              </TableCell>
              <TableCell width="15%" align="left">
                Publicado por
              </TableCell>
              <TableCell width="20%" align="center">
                Última atualização
              </TableCell>
              <TableCell width="10%" align="center">
                Status
              </TableCell>
              <TableCell width="5%" align="left">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {technicalFaqs?.map((faq) => (
              <TableRow key={faq?.id}>
                <TableCell>{faq?.id}</TableCell>
                <TableCell overFlowSize="300px">{faq?.title}</TableCell>
                <TableCell overFlowSize="300px">
                  {faq?.category?.title}
                </TableCell>
                <TableCell overFlowSize="200px">
                  {faq?.subCategory?.title}
                </TableCell>
                <TableCell overFlowSize="150px">{faq?.author?.name}</TableCell>
                <TableCell align="center">
                  {helpers.formatters.dateTime(faq?.updatedAt)}
                </TableCell>
                <TableCell align="center" disableTooltip>
                  <Chip
                    align="center"
                    size="small"
                    label={helpers.technicalFaqs.statusTranslation(
                      faq?.status,
                      true,
                    )}
                    className={clsx(classes.root, classes.chip, {
                      [classes.published]:
                        faq?.status === constants.technicalFaqs.ACTIVE_STATUS,
                      [classes.inactive]:
                        faq?.status === constants.technicalFaqs.INACTIVE_STATUS,
                      [classes.sketch]:
                        faq?.status === constants.technicalFaqs.DRAFT_STATUS,
                    })}
                  />
                </TableCell>
                <TableCell disableTooltip>
                  <MenuButton>
                    <Button
                      size="small"
                      onClick={() =>
                        history.push(
                          reverse(routes.technicalFaqs.edit, {
                            technicalFaqId: faq?.id,
                          }),
                        )
                      }
                    >
                      Editar
                    </Button>
                    {(faq?.status === constants.technicalFaqs.INACTIVE_STATUS ||
                      faq?.status === constants.technicalFaqs.DRAFT_STATUS) && (
                      <Fragment>
                        <Button
                          size="small"
                          onClick={() => setOpenActivationDialog(true)}
                        >
                          Publicar
                        </Button>
                        <ConfirmationDialog
                          open={openActivationDialog}
                          setOpen={setOpenActivationDialog}
                          message="Deseja realmente publicar a FAQ?"
                          cancelButtonText="Cancelar"
                          buttonText="Publicar"
                          actionAcceptButton={() =>
                            handleChangeStatus(
                              faq?.id,
                              constants.technicalFaqs.ACTIVE_STATUS,
                            )
                          }
                        />
                      </Fragment>
                    )}
                    {(faq?.status === constants.technicalFaqs.ACTIVE_STATUS ||
                      faq?.status === constants.technicalFaqs.DRAFT_STATUS) && (
                      <Fragment>
                        <Button
                          size="small"
                          onClick={() => setOpenInactivationDialog(true)}
                        >
                          Inativar
                        </Button>
                        <ConfirmationDialog
                          open={openInactivationDialog}
                          setOpen={setOpenInactivationDialog}
                          message="Deseja realmente inativar a FAQ?"
                          subMessage="Lembre-se de que você pode reativá-la posteriormente, caso necessário."
                          cancelButtonText="Cancelar"
                          buttonText="Inativar"
                          actionAcceptButton={() =>
                            handleChangeStatus(
                              faq?.id,
                              constants.technicalFaqs.INACTIVE_STATUS,
                            )
                          }
                        />
                      </Fragment>
                    )}
                    <Button
                      size="small"
                      onClick={() => setOpenDeleteDialog(true)}
                    >
                      Excluir
                    </Button>
                    <ConfirmationDialog
                      open={openDeleteDialog}
                      setOpen={setOpenDeleteDialog}
                      message="Deseja realmente excluir a FAQ?"
                      alert="Lembre-se de que esta ação é irreversível!"
                      cancelButtonText="Cancelar"
                      buttonText="Excluir"
                      actionAcceptButton={() => handleDelete(faq?.id)}
                    />
                  </MenuButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </TableContainer>
  )
}

TechnicalFaqsTable.propTypes = {
  technicalFaqs: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func,
  faqName: PropTypes.string,
  masculineGrammaticalGender: PropTypes.bool,
}

export default TechnicalFaqsTable
