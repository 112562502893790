import permissions from '../../permissions'

const COLLABORATOR_PROFILE_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.COLLABORATOR_PROFILES.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.CREATE,
    tag: permissions.COLLABORATOR_PROFILES.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.EDIT,
    tag: permissions.COLLABORATOR_PROFILES.EDIT,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.ACTIVATE_OR_INACTIVATE,
    tag: permissions.COLLABORATOR_PROFILES.CHANGE_STATUS,
  },
]

export default COLLABORATOR_PROFILE_LIST
