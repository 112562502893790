import api from 'service/api'

const dponetAPI = api.create('dponet')

const show = async ({ observerInviteId = '' }) => {
  return await dponetAPI.get(`/observer_invites/${observerInviteId}`)
}

const create = async ({ data, companyId = '' }) => {
  return await dponetAPI.post(`/observer_invites`, {
    companyId: companyId,
    outsourcedDpo: data,
  })
}

const update = async ({ observerInviteId = '', data }) => {
  return await dponetAPI.put(`/observer_invites/${observerInviteId}`, {
    outsourcedDpo: data,
  })
}

const destroy = async ({ observerInviteId = '' }) => {
  return await dponetAPI.delete(`/observer_invites/${observerInviteId}`)
}

const observerInvite = {
  show,
  create,
  update,
  destroy,
}

export default observerInvite
