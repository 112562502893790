import { Grid } from '@material-ui/core'

import { Page, Container, ContentHeader, LoadingFeedback } from 'components'
import { QuestionnaireInformativeCards, QuestionnaireForm } from './components'

import { routes } from 'Routes'
import * as service from 'service'
import useFetch from 'hooks/useFetch'

const QuestionnairesEdit = ({ match }) => {
  const {
    params: { questionnaireId },
  } = match

  const menuNavigations = [
    { route: routes.questionnaires.all, text: 'Listagem' },
  ]

  const { response, isLoading, refresh } = useFetch(
    service.dponet.questionnaires.show,
    questionnaireId,
  )

  const questionnaire = response?.data?.questionnaire

  return (
    <Page title="Edição de questionário">
      <LoadingFeedback open={isLoading} />
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Questionários por empresa"
          menuNavigations={menuNavigations}
          title="Edição questionários por empresa"
          subtitle="Edição de questionário"
        />
        {!isLoading && (
          <Grid container spacing={3}>
            <QuestionnaireInformativeCards questionnaire={questionnaire} />
            <Grid item xs={12}>
              <QuestionnaireForm
                isEdit
                questionnaire={questionnaire}
                refresh={refresh}
              />
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  )
}

export default QuestionnairesEdit
