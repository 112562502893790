import { useQuery, useQueryClient, useQueries } from '@tanstack/react-query'

import constants from 'constants/index'

const useCompaniesCache = () => {
  const queryClient = useQueryClient()

  const useList = (params, options) => {
    const query = constants.queryKeys.companies.listCache(params, options)

    return useQuery(query)
  }

  const useView = (id) => {
    const query = constants.queryKeys.companies.viewCache(id)

    return useQuery(query)
  }

  const useLogs = (id, options) => {
    const query = constants.queryKeys.companies.logsCache(id, options)

    return useQuery(query)
  }

  const useListWithCompanyAudits = (params) => {
    const query =
      constants.queryKeys.companies.listWithCompanyAuditsCache(params)

    return useQuery(query)
  }

  const useOptions = (id, options) => {
    const queries = [
      constants.queryKeys.dataProcesses.relationOptionsCache(id, options),
      constants.queryKeys.departments.listCache({ companyId: id }, options),
    ]

    return useQueries({
      queries,
    })
  }

  const useUpdateOptionsCache = (id = undefined) => {
    const query = constants.queryKeys.dataProcesses.relationOptionsCache(id, {})
    queryClient.invalidateQueries(query)
  }

  const useUpdateCache = (id = undefined) => {
    constants.queryKeys.companies
      .updateCache(id)
      .map((query) => queryClient.invalidateQueries(query))
  }

  return {
    useList,
    useView,
    useLogs,
    useUpdateCache,
    useListWithCompanyAudits,
    useOptions,
    useUpdateOptionsCache,
  }
}

export default useCompaniesCache
