import PropTypes from 'prop-types'

import {
  Box,
  Typography,
  Breadcrumbs,
  makeStyles,
  Grid,
} from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { FilterTags } from 'components'

import { useHistory } from 'react-router-dom'

import styles from './styles'
import { isEmpty } from 'lodash'

const useStyles = makeStyles(styles)

const ContentHeader = ({
  menu,
  title,
  menuNavigations,
  subtitle,
  children,
  filter,
}) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <Box
      my={4}
      display="flex"
      justifyContent="space-between"
      alignItems="flex-end"
    >
      <Grid container spacing={3}>
        <Grid item xl={8} lg={7} md={6} sm={6} xs={12}>
          <Box className={classes.menuBox}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {!!menu && (
                <Typography
                  className={classes.menu}
                  variant="body1"
                  color="textPrimary"
                >
                  {menu}
                </Typography>
              )}
              {!isEmpty(menuNavigations) &&
                menuNavigations.map((item, index) => (
                  <Box key={index}>
                    <Typography
                      className={classes.menuNavigation}
                      variant="body1"
                      color="textPrimary"
                      onClick={() => history.push(item?.route)}
                    >
                      {item?.text}
                    </Typography>
                  </Box>
                ))}
              {!!title && (
                <Typography
                  className={classes.menu}
                  variant="body1"
                  color="textPrimary"
                >
                  {title}
                </Typography>
              )}
            </Breadcrumbs>
            <Typography className={classes.h4} variant="h4" color="textPrimary">
              {subtitle}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={4} lg={5} md={6} sm={6} xs={12}>
          <Box display="flex" justifyContent="flex-end">
            {children}
          </Box>
        </Grid>
        {!isEmpty(filter?.filters) && (
          <Grid item xs={12} className={classes.filters}>
            <FilterTags filter={filter} />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

ContentHeader.prototype = {
  menu: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node,
  menuNavigations: PropTypes.arrayOf(PropTypes.object),
}

export default ContentHeader
