import PropTypes from 'prop-types'

import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
} from '@material-ui/core'
import { LoadingButton } from 'components'

import { Upload as UploadIcon } from 'react-feather'

import * as service from 'service'
import useSnackbar from 'hooks/useSnackbar'

import useStyles from './styles'

const FormDeleteDialog = ({ open, setOpen, companyId }) => {
  const [isLoading, setIsloading] = useState(false)
  const snackbar = useSnackbar()

  const classes = useStyles()
  const [file, setFile] = useState(null)

  const onDrop = useCallback((acceptedFile) => {
    setFile(acceptedFile[0])
    // eslint-disable-next-line
  }, [])

  const validateFile = () => {
    const validTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
    ]
    return file && validTypes.includes(file.type)
  }

  const handleSubmit = async () => {
    if (validateFile()) {
      setIsloading(true)
      await service.dponet.companies.importDataProcesses({
        companyId: companyId,
        fileToImport: file,
      })
      snackbar.open({
        message:
          'Arquivo enviado para importação, será encaminhado um email após a finalização.',
        variant: 'success',
      })
    } else {
      snackbar.open({
        message: 'Por favor selecione um documento Excel (xlsx/xls)',
        variant: 'error',
      })
    }
    setFile(null)
    setIsloading(false)
    setOpen(false)
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const { ref, ...rootProps } = getRootProps()

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h5">Importar processos</Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box pt={2}></Box>
        <Box
          {...rootProps}
          className={classes.inputImageContainer}
          mb={3}
          rootref={ref}
        >
          <input {...getInputProps()} />
          <Box m={1} mt={2}>
            <UploadIcon />
          </Box>
          <Box m={1} mb={2}>
            <Typography>
              Arraste e solte ou selecione o documento com os processos a serem
              importados
            </Typography>
          </Box>
        </Box>
        {file && (
          <Box>
            <Typography>Arquivo:</Typography>
            <Typography variant="h5"> {file.path}</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <LoadingButton
          text="Importar"
          loading={isLoading}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  )
}

FormDeleteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  companyId: PropTypes.number,
}

FormDeleteDialog.defaultProps = {
  setOpen: () => {},
}

export default FormDeleteDialog
