/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useCompany } from 'hooks'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'

import {
  Box,
  Grid,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Card } from 'components'

import constants from 'constants/index'
import helpers from 'helpers'

const CopyCompanySelection = ({
  control,
  errors,
  reset,
  getValues,
  disableCertFields,
  company,
}) => {
  const { isLoading } = useCompany()
  const [copyFromReal, setCopyFromReal] = useState(false)

  const [companies, setCompanies] = useState([])
  const [companyInputValue, setCompanyInputValue] = useState('')
  const [isLoadingCompaniesInput, setIsLoadingCompaniesInput] = useState(false)

  const { loadCompanies, instructionsText } = helpers.companies.typeAndSearch
  const { nameWithDocumentByArrayObject } = helpers.companies.nameWithDocument
  const templateCompany = company?.templateCompany

  const handleTypeCompaniesInput = (e) => {
    setCompanyInputValue(e.target.value)
  }

  const companiesOptionsValues = nameWithDocumentByArrayObject(
    isLoadingCompaniesInput ? [] : companies,
  )

  const handleChangeTemplateOptions = () => {
    setCompanyInputValue('')
    setCompanies([])
    reset({ ...getValues(), companyTemplateId: { id: 0, name: '' } })
    setCopyFromReal((copyFromReal) => !copyFromReal)
  }

  const mountCompanyParams = () => {
    const defaultParams = {
      minimal: true,
      perPage: 1000 * 1000,
      name: companyInputValue,
    }

    if (copyFromReal) {
      return {
        ...defaultParams,
        kinds: [
          constants.companies.KINDS.COMPANY,
          constants.companies.KINDS.PERSONAL,
        ],
      }
    }

    return {
      ...defaultParams,
      kind: constants.companies.KINDS.TEMPLATE,
    }
  }

  useEffect(() => {
    if (
      !isEmpty(templateCompany) &&
      templateCompany?.kind !== constants.companies.KINDS.TEMPLATE
    ) {
      setCopyFromReal(true)
    }
  }, [])

  useEffect(() => {
    const getCompanies = setTimeout(() => {
      if (companyInputValue.length < 3) return

      loadCompanies(
        mountCompanyParams(),
        setIsLoadingCompaniesInput,
        setCompanies,
      )
    }, 1000)

    return () => clearTimeout(getCompanies)
  }, [companyInputValue])

  return (
    <Card title="Cópia de empresa">
      <Box mb={1}>
        <Typography variant="body2" align="justify" gutterBottom>
          Caso você deseje realizar a cópia de uma empresa template selecione a
          mesma abaixo, se desejar realizar a cópia com base em uma emrpesa não
          template, clique na opção{' '}
          <strong>Gerar a partir de empresa não template</strong>, e
          posteriormente selecione a empresa que deseja utilizar como base para
          cópia.
        </Typography>
      </Box>
      {!isLoading && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={copyFromReal}
                  disabled={disableCertFields}
                  color="primary"
                  onChange={handleChangeTemplateOptions}
                  name="copyFromReal"
                />
              }
              labelPlacement="start"
              label="Gerar a partir de empresa não template"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="companyTemplateId"
              onChange={([, data]) => data}
              mode="onChange"
              as={
                <Autocomplete
                  options={companiesOptionsValues}
                  getOptionLabel={(option) => option.name}
                  disabled={disableCertFields}
                  getOptionSelected={(option, value) =>
                    option.id === value.id || value.id === 0
                  }
                  noOptionsText={instructionsText(companyInputValue)}
                  loading={isLoadingCompaniesInput}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={handleTypeCompaniesInput}
                      label={
                        isEmpty(templateCompany)
                          ? 'Empresa a ser copiada'
                          : templateCompany?.name
                      }
                      variant="outlined"
                      error={!!errors.companyTemplateId}
                      helperText={errors?.companyTemplateId?.message}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {isLoadingCompaniesInput ? (
                              <CircularProgress color="primary" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              }
            />
          </Grid>
        </Grid>
      )}
    </Card>
  )
}

CopyCompanySelection.prototype = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
  disableCertFields: PropTypes.bool,
  company: PropTypes.object,
}

CopyCompanySelection.defaultProps = {
  reset: () => {},
  getValues: () => {},
  setLoading: () => {},
  company: {},
}

export default CopyCompanySelection
