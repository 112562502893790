import { isArray } from 'lodash'

const mountQueries = (keyArrays, exact = true, ...rest) => {
  if (!isArray(keyArrays))
    return [{ queryKey: keyArrays, exact: exact, ...rest }]

  return keyArrays.map((keyArray) => {
    return { queryKey: keyArray, exact: exact, ...rest }
  })
}

export default mountQueries
