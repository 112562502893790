import { useCallback, useState } from 'react'

import { Container, ContentHeader, Page, LoadingFeedback } from 'components'
import {
  CompaniesTable,
  IntegrationPartnerForm,
  IntegrationTokensTable,
} from './components'

import { useFetch, useSnackbar } from 'hooks'

import * as service from 'service'

const IntegrationPartnerEdit = ({ match }) => {
  const [loading, setLoading] = useState(false)
  const snackbar = useSnackbar()
  const {
    params: { integrationPartnerId },
  } = match

  const { response, isLoading, refresh } = useFetch(
    service.dponet.integrationPartners.get,
    { integrationPartnerId },
    [],
  )
  const integrationPartner = response?.data?.integrationPartner

  const handleCreateToken = useCallback(
    async () => {
      if (integrationPartner?.id) {
        try {
          setLoading(true)
          await service.dponet.integrationPartners.createToken(
            integrationPartner?.id,
          )
          snackbar.open({
            message: 'Token criado com sucesso',
            variant: 'success',
          })
          refresh()
        } catch {
          snackbar.open({
            message: 'Ocorreu um erro ao criar o token',
            variant: 'error',
          })
        } finally {
          setLoading(false)
        }
      }
    },
    //eslint-disable-next-line
    [integrationPartner],
  )

  return (
    <Page title="Editar Parceiro">
      <LoadingFeedback open={isLoading || loading} />
      <Container spacingXl={20} spacingLg={12} spacingMd={5} spacingSm={5}>
        <ContentHeader menu="Parceiros" title="Editar" subtitle="Parceiro" />
        {!isLoading && (
          <>
            <IntegrationPartnerForm
              isEdit
              editionPermitted
              integrationPartner={integrationPartner}
              refresh={refresh}
            />
            <IntegrationTokensTable
              handleCreateToken={handleCreateToken}
              integrationPartnerId={integrationPartner?.id}
              integrationPartnerTokens={
                integrationPartner?.integrationPartnerTokens
              }
            />
            <CompaniesTable companies={integrationPartner?.companies} />
          </>
        )}
      </Container>
    </Page>
  )
}

export default IntegrationPartnerEdit
