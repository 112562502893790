import {
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
  Box,
} from '@material-ui/core'

import constants from 'constants/index'

import { Label } from 'components'

import useDataProcess from 'hooks/useDataProcess'

import useStyles from './styles'

const InfoCompanies = () => {
  const { data } = useDataProcess()

  const classes = useStyles()

  return (
    <Card className={classes.rootCard}>
      <CardHeader title="Informações da empresa" />
      <Divider />
      <Box m={2.5}>
        <Grid container spacing={2}>
          {data.company?.kind !== constants.companies.KINDS.TEMPLATE && (
            <Grid item xs={12}>
              <Label title="CNPJ/CPF">
                <Typography>{data?.company?.document}</Typography>
              </Label>
            </Grid>
          )}
          <Grid item xs={12}>
            <Label title="Empresa">
              <Typography>{data?.company?.name}</Typography>
            </Label>
          </Grid>
          <Grid item xs={12}>
            <Label title="Departamento">
              <Typography>{data?.department?.name}</Typography>
            </Label>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

export default InfoCompanies
