import { makeStyles } from '@material-ui/core'

const styles = makeStyles(() => ({
  iframe: {
    width: '100%',
    height: '100%',
  },
}))

export default styles
