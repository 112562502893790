import { useState } from 'react'
import FilterTagsContext from 'contexts/FilterTagsContext'

const FilterTagsProvider = ({ children }) => {
  const [companies, setCompanies] = useState([])
  const [departments, setDepartments] = useState([])
  const [titularCategories, setTitularCategories] = useState([])
  const [templateCompanies, setTemplateCompanies] = useState([])
  const [legalFrameworks, setLegalFrameworks] = useState([])

  const formatResponse = (response) => {
    var list = []

    response?.map((item) => {
      return list.push({ id: item.id, name: item.name })
    })

    return list
  }

  const defineDepartments = (departments) => {
    setDepartments(formatResponse(departments))
  }

  const defineCompanies = (companies) => {
    setCompanies(formatResponse(companies))
  }

  const defineTitularCategories = (titularCategories) => {
    !!titularCategories &&
      setTitularCategories(formatResponse(titularCategories))
  }

  const defineTemplateCompanies = (templateCompanies) => {
    setTemplateCompanies(formatResponse(templateCompanies))
  }

  const defineLegalFrameworks = (legalFrameworks) => {
    setLegalFrameworks(formatResponse(legalFrameworks))
  }

  return (
    <FilterTagsContext.Provider
      value={{
        companies,
        departments,
        titularCategories,
        templateCompanies,
        legalFrameworks,
        defineDepartments,
        defineCompanies,
        defineTitularCategories,
        defineTemplateCompanies,
        defineLegalFrameworks,
      }}
    >
      {children}
    </FilterTagsContext.Provider>
  )
}

export default FilterTagsProvider
