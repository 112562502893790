import * as yup from 'yup'

const schemaCoupon = () =>
  yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    kind: yup.string().required(),
  })

export default schemaCoupon
