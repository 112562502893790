import * as yup from 'yup'

const schemaDocumentationLink = () =>
  yup.object().shape({
    url: yup.string().required(),
    pageUri: yup.string().required(),
    status: yup.bool(),
  })

export default schemaDocumentationLink
