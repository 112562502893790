import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: 0,
    padding: 0,
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    height: 230,
    overflow: 'auto',
    width: '100%',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}))

export default styles
