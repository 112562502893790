import { useState } from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import useSnackbar from 'hooks/useSnackbar'

import { BaseDialog, LoadingFeedback } from 'components'
import Contractor from '../../../Contractor'

import helpers from 'helpers'

import * as service from 'service'
import schema from './schema'

const LinkedDialog = ({
  paymentContractId,
  refresh = () => {},
  openNew = false,
  setOpenNew = () => {},
  preRegistrationIds,
}) => {
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)

  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema(),
    defaultValues: {},
  })

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      await service.dponet.paymentContract.linkSubsidiary({
        preRegistrationId: data?.preRegistration?.id,
        paymentContractId: paymentContractId,
      })
      snackbar.open({
        message: 'Cliente vinculado com sucesso!',
        variant: 'success',
      })
      refresh()
      setOpenNew(false)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Falha ao vincular cliente.',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseDialog
      open={openNew}
      title="Vincular cliente ao contrato"
      actionButtonText="Salvar"
      closeButtonText="Cancelar"
      setOpen={setOpenNew}
      formRef="id-linked-dialog-pre-contract"
    >
      <LoadingFeedback open={loading} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        id="id-linked-dialog-pre-contract"
      >
        <Contractor
          control={control}
          errors={errors}
          setLoading={setLoading}
          isNew
          preRegistrationIds={preRegistrationIds}
        />
      </form>
    </BaseDialog>
  )
}

LinkedDialog.propTypes = {
  paymentContractId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  refresh: PropTypes.func,
  openNew: PropTypes.bool,
  setOpenNew: PropTypes.func,
  preRegistrationIds: PropTypes.array,
}

export default LinkedDialog
