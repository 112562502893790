import { useState } from 'react'

import PropTypes from 'prop-types'

import useSnackbar from 'hooks/useSnackbar'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'
import { LoadingButton } from 'components'

import * as service from 'service'

import useStyles from './styles'

const LastUpdateInactiveOrActiveDialog = ({
  lastUpdate,
  open,
  setOpen,
  refresh,
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()

  const InactiveOrActive = async () => {
    setLoading(true)
    try {
      if (lastUpdate.active) {
        await service.dponet.lastUpdates.put({
          lastUpdateId: lastUpdate.id,
          active: false,
        })
      } else {
        await service.dponet.lastUpdates.put({
          lastUpdateId: lastUpdate.id,
          active: true,
        })
      }
      refresh()
      snackbar.open({
        message: `Atualização ${
          lastUpdate.active ? 'inativada' : 'ativada'
        }com sucesso`,
        variant: 'success',
      })
    } catch {
      snackbar.open({
        message: `Falha ao inativar atualização`,
        variant: 'error',
      })
    }
    setOpen(false)
    setLoading(false)
    refresh()
  }

  const noticeText = () => {
    if (lastUpdate.active) {
      return (
        <Box pb={1}>
          <Typography className={classes.textColor}>
            Tem certeza que deseja inativar?
          </Typography>
          <Typography className={classes.text}>
            Se inativada, ela não será mais vísivel para os clientes
          </Typography>
        </Box>
      )
    }

    return (
      <Box pb={1} className={classes.text}>
        <Typography className={classes.textColor}>
          Tem certeza que deseja reativar?
        </Typography>
        <Typography className={classes.text}>
          Se reativada, ela será vísivel para os clientes
        </Typography>
      </Box>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="change-last-update-status"
      fullWidth
    >
      <DialogTitle>
        <Typography className={classes.textTitle}>
          {`TEM CERTEZA QUE DESEJA ${
            lastUpdate?.active ? 'INATIVAR' : 'REATIVAR'
          } ESSA NOTÍCIA?`}
        </Typography>
      </DialogTitle>
      <Divider />
      <Box>
        <DialogContent>{noticeText()}</DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <LoadingButton
          variant="contained"
          type="submit"
          color="primary"
          text={lastUpdate.active ? 'INATIVAR' : 'ATIVAR'}
          loading={loading}
          onClick={InactiveOrActive}
        />
      </DialogActions>
    </Dialog>
  )
}

LastUpdateInactiveOrActiveDialog.propTypes = {
  lastUpdate: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
}
LastUpdateInactiveOrActiveDialog.defaultProps = {
  lastUpdate: {},
  setOpen: () => {},
  refresh: () => {},
}

export default LastUpdateInactiveOrActiveDialog
