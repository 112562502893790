import { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, TextField, Grid } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'

import {
  BackButton,
  Card,
  LoadingBox,
  Select as SelectComponent,
} from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import helpers from 'helpers'
import schema from './schema'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

const GroupsForm = ({ group, isEdit }) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const snackbar = useSnackbar()
  const statusItems = [
    { id: 'true', name: 'Ativo' },
    { id: 'false', name: 'Inativo' },
  ]

  const { control, handleSubmit, errors, reset } = useForm({
    validationSchema: schema(),
    defaultValues: {
      name: group?.name || '',
      active: group ? group?.active?.toString() : '',
    },
  })

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      if (isEdit) {
        await service.dponet.groups.put({
          groupId: group?.id,
          group: data,
        })
      } else {
        await service.dponet.groups.post({ group: data })
      }

      snackbar.open({
        message: `Grupo ${isEdit ? 'atualizado' : 'criado'} com sucesso!`,
        variant: 'success',
      })
      history.push(routes.groups.all)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || `Falha ao ${isEdit ? 'atualizar' : 'criar'} grupo!`,
        variant: 'error',
      })
      setLoading(false)
      reset(group)
    }
  }

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card title="Preencha os dados do grupo">
            <Box py={1}>
              <Grid container style={{ gap: 20 }}>
                <Grid item xs={12}>
                  <Controller
                    as={
                      <TextField
                        label="Nome do grupo"
                        type="text"
                        color="primary"
                        variant="outlined"
                        error={!!errors.name}
                        helperText={errors?.name?.message}
                        fullWidth
                      />
                    }
                    control={control}
                    name="name"
                    mode="onBlur"
                  />
                </Grid>
                {isEdit && (
                  <Grid item xs={12}>
                    <Controller
                      as={
                        <SelectComponent
                          items={statusItems}
                          label="Status"
                          error={!!errors.active}
                          fullWidth
                          helperText={errors?.active && 'Preencha este campo'}
                        />
                      }
                      control={control}
                      name="active"
                      mode="onBlur"
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Card>
          <Box mt={3} display="flex" justifyContent="flex-start">
            <Box pr={1}>
              <BackButton onClick={() => history.push(routes.groups.all)}>
                Voltar
              </BackButton>
            </Box>
            <Box>
              <Button color="primary" variant="contained" type="submit">
                Salvar
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </>
  )
}

GroupsForm.propTypes = {
  group: PropTypes.object,
}

export default GroupsForm
