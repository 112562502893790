import theme from 'theme'
import constants from 'constants/index'

import {
  Loader as LoaderIcon,
  Eye as EyeIcon,
  AlertCircle as AlertCircleIcon,
  Check as CheckIcon,
  Moon as MoonIcon,
} from 'react-feather'

const statusColor = (statusId, size = 18) => {
  switch (statusId) {
    case constants.dataProcess.PENDING_STATUS:
      return <LoaderIcon color={theme.palette.white} size={size} />
    case constants.dataProcess.WAITING_REVIEW_STATUS:
      return <EyeIcon color={theme.palette.white} size={size} />
    case constants.dataProcess.DISAPPROVED_STATUS:
      return <AlertCircleIcon color={theme.palette.white} size={size} />
    case constants.dataProcess.APPROVED_STATUS:
      return <CheckIcon color={theme.palette.white} size={size} />
    case constants.dataProcess.REVIEWING_STATUS:
      return <EyeIcon color={theme.palette.white} size={size} />
    case constants.dataProcess.INACTIVE_STATUS:
      return <MoonIcon color={theme.palette.white} size={size} />
    case constants.dataProcess.AUTO_REVIEW_STATUS:
      return <EyeIcon color={theme.palette.white} size={size} />
    default:
      return <></>
  }
}

export default statusColor
