import PropTypes from 'prop-types'

import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { Card } from 'components'
import CouponTypes from './components/CouponTypes'

import helpers from 'helpers'
import constants from 'constants/index'

const TypeAndValue = ({ formMethods }) => {
  const { errors, control, setValue, watch } = formMethods
  const discountType = watch('discountType')
  const isPercentage = discountType === constants.discountCoupon.PERCENTAGE_TYPE

  const getType = () => {
    if (isPercentage) return 'number'

    return 'text'
  }

  const getLabel = () => {
    if (isPercentage) return 'Valor (%)'

    return 'Valor (R$)'
  }

  const handleChange = (event) => {
    setValue(
      'discountValue',
      helpers.discountCoupon.discountFormatter(
        event.target.value,
        watch('discountValue'),
      ),
    )

    return event.target.value
  }

  const handleValueTextField = (event) => {
    if (isPercentage) return helpers.formatters.percentage(event.target.value)

    return helpers.formatters.currencyInput(event.target.value)
  }

  return (
    <Card title="Desconto" paddingCard={3} fullHeight>
      <Grid container>
        <Grid item xs={6}>
          <Box mb={1}>
            <Typography variant="h5">Opção de desconto</Typography>
            <Controller
              as={
                <RadioGroup>
                  <FormControlLabel
                    value={constants.discountCoupon.VALUE_TYPE}
                    control={<Radio color="primary" />}
                    label={constants.discountCoupon.VALUE_LABEL}
                  />
                  <FormControlLabel
                    value={constants.discountCoupon.PERCENTAGE_TYPE}
                    control={<Radio color="primary" />}
                    label={constants.discountCoupon.PERCENTAGE_LABEL}
                  />
                  <FormControlLabel
                    value={constants.discountCoupon.FIXED_PRICE_TYPE}
                    control={<Radio color="primary" />}
                    label={constants.discountCoupon.FIXED_PRICE_LABEL}
                  />
                </RadioGroup>
              }
              onChange={([event]) => handleChange(event)}
              control={control}
              name="discountType"
              mode="onBlur"
            />
          </Box>
          <Box>
            <Controller
              as={
                <TextField
                  label={getLabel()}
                  type={getType()}
                  {...(!isPercentage && {
                    inputProps: {
                      maxLength: 7,
                    },
                  })}
                  color="primary"
                  variant="outlined"
                  error={!!errors.discountValue}
                  helperText={errors?.discountValue?.message}
                  fullWidth
                />
              }
              onChange={([event]) => handleValueTextField(event)}
              control={control}
              name="discountValue"
              mode="onBlur"
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <CouponTypes />
        </Grid>
      </Grid>
    </Card>
  )
}

TypeAndValue.propTypes = {
  formMethods: PropTypes.object.isRequired,
}

export default TypeAndValue
