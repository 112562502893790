import { isUndefined } from 'lodash'

import * as service from 'service'

const listCache = (params) => {
  const keys = isUndefined(params)
    ? ['dataProcesses', 'list']
    : ['dataProcesses', 'list', params]

  const queryFn = () =>
    service.dponet.dataProcesses.get(params).then((response) => response.data)

  return { queryKey: keys, queryFn: queryFn, exact: false }
}

const viewCache = (id) => {
  const keys = ['dataProcesses', 'view', parseInt(id)]

  const queryFn = () =>
    service.dponet.dataProcesses
      .get({ dataProcessId: id })
      .then((response) => response.data)

  return { queryKey: keys, queryFn: queryFn, exact: true }
}

const logsCache = (id, options) => {
  const keys = ['dataProcesses', 'logs', parseInt(id)]

  const queryFn = () =>
    service.dponet.dataProcesses
      .logs({ dataProcessId: id })
      .then((response) => response.data)

  return {
    queryKey: keys,
    exact: true,
    queryFn: queryFn,
    ...options,
  }
}

const listLegalFrameworksCache = () => {
  const keys = ['dataProcesses', 'legalFrameworks']

  const queryFn = () =>
    service.dponet.legalFrameworks.get().then((response) => response.data)

  return { queryKey: keys, queryFn: queryFn, exact: false }
}

const processesStatsCache = (params) => {
  const keys = isUndefined(params)
    ? ['dataProcesses', 'processStats']
    : ['dataProcesses', 'processStats', params]

  const queryFn = () =>
    service.dponet.dataProcesses
      .getProcessStats(params)
      .then((response) => response.data)

  return { queryKey: keys, queryFn: queryFn, exact: false }
}

const relationOptionsCache = (id, options) => {
  const keys = ['dataProcesses', 'relationOptions', parseInt(id)]

  const queryFn = () =>
    service.dponet.dataProcesses
      .getRelationOptions({ companyId: id })
      .then((response) => response.data)

  return {
    queryKey: keys,
    exact: true,
    queryFn: queryFn,
    ...options,
  }
}

const updateCache = (id) => {
  if (isUndefined(id)) return [listCache(), processesStatsCache()]

  return [
    listCache(),
    processesStatsCache(),
    viewCache(parseInt(id)),
    logsCache(parseInt(id)),
  ]
}

const optionsCache = (id, options = {}) => {
  var queries = [viewCache(id), listLegalFrameworksCache(), logsCache(id)]

  if (!!options?.withoutLogs) {
    queries.pop()
    return queries
  }

  return queries
}

const dataProcesses = {
  listCache,
  logsCache,
  updateCache,
  viewCache,
  processesStatsCache,
  relationOptionsCache,
  listLegalFrameworksCache,
  optionsCache,
}

export default dataProcesses
