import * as yup from 'yup'

const schema = yup.object().shape({
  titularAgeRanges: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(),
      }),
    )
    .min(1),
  titularCategoryId: yup.string().required(),
  holderNumber: yup.number().nullable(),
  anotherPersonalNames: yup.string(),
  anotherComportamentalNames: yup.string(),
  anotherFinancialNames: yup.string(),
})

export default schema
