import * as yup from 'yup'

const schemaPlan = () =>
  yup.object().shape({
    segmentId: yup.object().required(),
    name: yup.string().required(),
    implementationPrice: yup.string().required(),
    code: yup.string().required(),
    price: yup.string().required(),
    companyId: yup.object().required('Selecione uma empresa').nullable(),
    implementationSplit: yup.number().required(),
  })

export default schemaPlan
