import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Tab,
  TablePagination,
  Tabs,
  Typography,
  useMediaQuery,
} from '@material-ui/core'

import {
  Container,
  ContentHeader,
  FilterButton,
  LoadingFeedback,
  Page,
} from 'components'
import { SubcategoryFilter, SubcategoryTable } from './components'

import { useFetch, useFilter, usePagination } from 'hooks'

import constants from 'constants/index'
import * as service from 'service'
import { routes } from 'Routes'
import theme from 'theme'

const SubcategoryMain = () => {
  const [selectedTab, setSelectedTab] = useState(
    constants.technicalFaqs.ALL_STATUS,
  )

  const filter = useFilter()
  const history = useHistory()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination()

  const { response, isLoading, refresh } = useFetch(
    service.dponet.technicalFaqs.getSubcategory,
    {
      ...filter.filters,
      page,
      perPage,
      status: selectedTab,
    },
    [filter.filters, page, perPage, selectedTab],
  )

  const faqSubcategories = response?.data?.knowledgeBaseSubcategories

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const handleTabChange = (_, clickedTab) => {
    setSelectedTab(clickedTab)
  }

  return (
    <Page title="Subcategorias">
      <LoadingFeedback open={isLoading} />
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Apoio Técnico"
          title="Listagem"
          subtitle="Subcategorias"
        >
          <FilterButton setDrawerOpen={filter.setDrawerOpen} />
        </ContentHeader>
        <Box
          mt={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              {constants.technicalFaqs.SUBCATEGORIES_TABS.map((tab) => (
                <Tab key={tab.value} label={tab.label} value={tab.value} />
              ))}
            </Tabs>
          </Box>
        </Box>
        <Card>
          <Box width="100%" display="flex" alignItems="center">
            <Grid container>
              <Grid item xs={6}>
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  ml={2}
                >
                  <Typography variant="h5">Subcategorias</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box p={2} display="flex" justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<PlusCircleIcon width={15} height={15} />}
                    onClick={() =>
                      history.push(routes.technicalFaqs.newSubcategory)
                    }
                  >
                    Criar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <SubcategoryTable
            subcategories={faqSubcategories}
            refresh={refresh}
          />
          <Box px={2} display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Card>
      </Container>
      <SubcategoryFilter filter={filter} />
    </Page>
  )
}

export default SubcategoryMain
