import PropTypes from 'prop-types'

import { Box, Typography, Grid } from '@material-ui/core'

const Description = ({
  dataProcess: { description, automaticDescription },
}) => {
  return (
    <>
      {!automaticDescription && (
        <Box pb={3}>
          <Box mt={3} mb={2}>
            <Typography variant="h5">Descrição</Typography>
          </Box>
          <Grid container>
            <Grid item xs={12}>
              <Typography align="justify" color="textPrimary" variant="body2">
                {description}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  )
}

Description.propTypes = {
  description: PropTypes.string,
  automaticDescription: PropTypes.bool.isRequired,
}

export default Description
