const dataTypeLabelOptions = {
  0: 'Outros',
  1: 'Dados sensíveis',
  2: 'Dados pessoais',
  3: 'Dados financeiros',
  4: 'Dados comportamentais',
  5: 'Outros dados pessoais',
  6: 'Outros dados financeiros',
  7: 'Outros dados comportamentais',
}

const dataTypeLabel = (id) => {
  return dataTypeLabelOptions[id || 0]
}

export default dataTypeLabel
