import tableCellColor from './tableCellColor'
import formattedValue from './formattedValue'
import changeLogs from './changeLogs'
import status from './status'

const logs = {
  tableCellColor,
  formattedValue,
  changeLogs,
  status,
}

export default logs
