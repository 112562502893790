import palette from 'theme/palette'

import { logowhite } from '../assets/logoWhite64'

import { WIDTH as WIDTHPdf, HEIGHT as HEIGHTPdf, PADDING } from '../constants'

import { ratio } from '../helpers'

const lastCover = (pdf) => {
  const WIDTH = WIDTHPdf(pdf)
  const HEIGHT = HEIGHTPdf(pdf)

  pdf.addPage('a4', 'p')

  pdf.setFillColor(palette.BlueDownDark)
  pdf.rect(0, 0, WIDTH, HEIGHT, 'F')

  pdf.addImage(
    logowhite,
    'png',
    WIDTH / 2 - PADDING * 1.25,
    HEIGHT / 2,
    ratio(500),
    ratio(158),
  )
}

export default lastCover
