import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    maxWidth: 'none !important',
  },
  headerWhite: {
    backgroundColor: theme.palette.white,
  },
}))
export default styles
