import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/styles'

import {
  Box,
  Card,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  Button,
  TablePagination,
  Divider,
} from '@material-ui/core'
import { Plus as PlusIcon } from 'react-feather'

import {
  Container,
  ContentHeader,
  FilterButton,
  LoadingFeedback,
  Page,
  Permitted,
} from 'components'
import { DocumentationTable, DocumentationFilter } from './components'

import { useFetch, useFilter, usePagination } from 'hooks'

import constants from 'constants/index'
import { routes } from 'Routes'
import * as service from 'service'

const DocumentationMain = () => {
  const theme = useTheme()
  const history = useHistory()
  const filter = useFilter()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const [tab, setTab] = useState(constants.faqsDocumentations.ALL_TAB)
  const [refresh, setRefresh] = useState(false)

  const handleChangeTab = (_, value) => {
    setTab(value)
    if (value !== constants.faqsDocumentations.DRAFT_TAB) {
      return filter.setFilters({
        ...filter.filters,
      })
    }

    const filters = filter.filters
    filter.setFilters({ ...filters })
  }

  const paramsFilter = {
    ...filter.filters,
    draft: tab === constants.faqsDocumentations.DRAFT_TAB,
    active: tab !== constants.faqsDocumentations.INACTIVE_TAB,
  }

  const paramsFilterAll = {
    ...filter.filters,
  }

  const paramsFilterInactive = {
    ...filter.filters,
    active: false,
  }

  const params = () => {
    if (
      tab !== constants.faqsDocumentations.ALL_TAB &&
      tab !== constants.faqsDocumentations.INACTIVE_TAB
    ) {
      return paramsFilter
    } else if (tab === constants.faqsDocumentations.INACTIVE_TAB) {
      return paramsFilterInactive
    }
    return paramsFilterAll
  }

  const { response: responseDocs, isLoading: docsIsLoading } = useFetch(
    service.dponet.faqsDocumentations.get,
    {
      ...params(),
      kind: constants.faqsDocumentations.KINDS.DOCUMENTATION,
      perPage,
      page,
    },
    [page, perPage, filter.filters, refresh],
  )

  const documentations = responseDocs?.data?.faqsDocumentations
  const totalCount = responseDocs?.data?.meta?.totalCount

  const { response: responseCategories, isLoading: categoriesIsLoading } =
    useFetch(
      service.dponet.faqsDocumentationsCategories.get,
      {
        categoryType: 'doc_category',
      },
      [page, perPage, filter.filters, refresh],
    )

  const categories = responseCategories?.data?.faqsDocumentationsCategories

  return (
    <Page title="Documentação">
      <LoadingFeedback open={docsIsLoading || categoriesIsLoading} />
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Central de ajuda"
          title="Listagem"
          subtitle="Documentação"
        >
          <Box ml={1}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
        </ContentHeader>
        <Box
          mt={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              {constants.faqsDocumentations.TABS.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  onClick={(event) => handleChangeTab(event, tab.value)}
                />
              ))}
            </Tabs>
          </Box>
        </Box>
        <Card>
          <Box width="100%" display="flex" alignItems="center">
            <Grid container>
              <Grid item xs={6}>
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  ml={2}
                >
                  <Typography variant="h5">Documentação</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box p={2} display="flex" justifyContent="flex-end">
                  <Permitted
                    tag={constants.permissions.FAQS_DOCUMENTATIONS.CREATE}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<PlusIcon />}
                      onClick={() => history.push(routes.documentations.new)}
                    >
                      Criar
                    </Button>
                  </Permitted>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <DocumentationTable
            documentations={documentations ?? []}
            refresh={() => setRefresh(!refresh)}
          />
          <Box px={2} display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              count={totalCount ?? 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Card>
      </Container>
      <DocumentationFilter filter={filter} categories={categories} />
    </Page>
  )
}

export default DocumentationMain
