import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
  TextField,
} from '@material-ui/core'

import { Label } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import { schema } from './schema'
import helpers from 'helpers'
import constants from 'constants/index'

const DialogReopen = ({ dataProcess, open, setOpen, setLoading, refresh }) => {
  const snackbar = useSnackbar()
  const { errors, handleSubmit, control } = useForm({
    validationSchema: schema,
    defaultValues: {
      description: '',
    },
  })

  const onSubmit = async (data) => {
    setLoading(true)

    try {
      await service.dponet.dataProcesses.reopen(
        { dataProcessId: dataProcess.id },
        data,
      )

      snackbar.open({
        message: 'Processamento de dados reaberto com sucesso',
        variant: 'success',
      })

      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }

    setLoading(false)
    setOpen(false)
  }

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Reabrir processo</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mb={1}>
          <DialogContent>
            {dataProcess?.company?.kind ===
              constants.companies.KINDS.TEMPLATE && (
              <Box mb={2}>
                <Typography>
                  Este processo será alterado em uma empresa template que possui
                  empresas reais criadas a partir dela. A alteração deste
                  processo e o motivo serão informados a todas as empresas reais
                  e a alteração será exibida para que seja aceita. Você tem
                  certeza de que deseja alterar este processo na empresa
                  template?
                </Typography>
              </Box>
            )}
            <Label
              title="Motivo da reabertura"
              description="Descreva o motivo da reabertura"
            >
              <Controller
                as={
                  <TextField
                    color="primary"
                    multiline
                    rows={3}
                    variant="outlined"
                    error={!!errors.description}
                    helperText={errors?.description?.message}
                    fullWidth
                  />
                }
                control={control}
                name="description"
                mode="onBlur"
              />
            </Label>
          </DialogContent>
        </Box>
        <Divider />
        <DialogActions p={2}>
          <Box pl={2} pr={1}>
            <Button
              type="button"
              variant="outlined"
              onClick={() => setOpen(false)}
            >
              Cancelar
            </Button>
          </Box>
          <Button variant="contained" type="submit" color="primary">
            Reabrir
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

DialogReopen.propTypes = {
  dataProcess: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  refresh: PropTypes.func,
}

DialogReopen.defaultProps = {
  setOpen: () => {},
  setLoading: () => {},
  refresh: () => {},
}

export default DialogReopen
