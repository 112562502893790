import { makeStyles } from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const Auth = ({ children }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <main className={classes.wrapper}>{children}</main>
    </div>
  )
}

export default Auth
