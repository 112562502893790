import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useForm, Controller } from 'react-hook-form'
import { Grid, TextField, makeStyles } from '@material-ui/core'

import useDataProcess from 'hooks/useDataProcess'
import useSnackbar from 'hooks/useSnackbar'

import {
  Label,
  Select as SelectComponent,
  CompanyTypeAndSeachInput,
} from 'components'
import { DescriptionPaper } from '../../'

import { isEmpty } from 'lodash'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'

import schemaGeneralInformation from './schema'
import ContextDataProcessSource from '../../ContextDataProcessSource'
import { VolumetryForm } from '../../'

import styles from '../styles'
const useStyles = makeStyles(styles)

const GeneralInformation = ({ description }) => {
  const {
    code,
    setCode,
    setData,
    data,
    nextStep,
    toNextStep,
    setToNextStep,
    departments,
    companyId,
    setCompanyId,
    reloadOptions,
    reloadDataProcess,
  } = useDataProcess()

  const [selectDepartmentOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [allSources, setAllSources] = useState([])
  const [volumetryType, setVolumetryType] = useState(
    data?.storageMode || constants.dataProcess.STORY_MODE_UNDEFINED_TYPE,
  )
  const dataLaws = data?.dataProcess?.laws?.map((law) => law?.id)
  const [laws, setLaws] = useState(dataLaws || [])

  const blockSubmit =
    volumetryType === constants.dataProcess.STORY_MODE_PERMANENT_TYPE &&
    isEmpty(laws)

  const snackbar = useSnackbar()

  const classes = useStyles()

  const {
    setValue,
    handleSubmit,
    errors,
    control,
    watch,
    getValues,
    register,
  } = useForm({
    validationSchema: schemaGeneralInformation(volumetryType),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      ...data,
      name: data.name ?? '',
      value: data.value ?? '',
      deadlineJustification: data.deadlineJustification ?? '',
      code: code ?? '',
      volumetry: data.volumetry ?? '',
      departmentId: data.department?.id ?? '',
      companyId: data.company?.id ?? '',
      sourceDescription: '',
      sourceDescriptionAnother: '',
      dataProcessDataSources: data?.dataProcessDataSources
        ? helpers.dataProcesses.covertDataSourcesToOptions(
            data?.dataProcessDataSources,
          )
        : [],
    },
  })

  useEffect(() => {
    if (toNextStep) {
      handleSubmit(onSubmit)()

      if (errors) setToNextStep(false)
    }
    // eslint-disable-next-line
  }, [toNextStep])

  useEffect(() => {
    if (data?.dataProcessDataSources) {
      setAllSources(
        helpers.dataProcesses.covertDataSourcesToOptions(
          data?.dataProcessDataSources,
        ),
      )
      setIsEdit(true)
    }
    // eslint-disable-next-line
  }, [])

  const isEmptyLawsAndPermanentIsBlockSubmit = () => {
    snackbar.open({
      message:
        'Verifique se os campos do tempo de armazenamento estão devidamente preenchidos!',
      variant: 'error',
    })
  }

  const onSubmit = async (dataProcess) => {
    try {
      if (blockSubmit) return isEmptyLawsAndPermanentIsBlockSubmit()
      let response = undefined
      let dataProcessId = data?.id
      delete dataProcess['sourceDescription']
      if (dataProcessId) {
        response = await service.dponet.dataProcesses.put({
          dataProcessId,
          dataProcess: {
            ...dataProcess,
            storageMode: volumetryType,
            laws: laws.map((law) => ({
              id: law,
            })),
          },
        })
      } else {
        response = await service.dponet.dataProcesses.create({
          dataProcess: {
            ...dataProcess,
            storageMode: volumetryType,
            laws: laws.map((law) => ({
              id: law,
            })),
            companyId,
          },
        })
      }

      await setData(response.data.dataProcess)

      if (!!companyId) {
        reloadOptions()
      }

      reloadDataProcess()
      nextStep()
      setToNextStep(false)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error, false) ||
          error?.error?.message,
        variant: 'error',
      })
    }
  }

  const setCodeByDepartment = async (departmentId) => {
    const response = await service.dponet.departments.getCode({ departmentId })
    setCode(response.data.code)
    setValue('code', response.data.code)
  }

  const handleChangeCompany = () => {
    setValue('code', '')
    setValue('departmentId', '')
    setValue('dataProcessDataSources', '')
    setAllSources([])
  }

  const watchSourceDescription = watch('sourceDescription')

  return (
    <form id="general-information">
      <DescriptionPaper description={description} />
      <Grid container spacing={2} className={classes.root}>
        <Label
          title="Empresa"
          description="Selecione a empresa em que o processamento de dados será criado."
          xs={6}
          item
        >
          {!!data?.company?.id ? (
            <Controller
              as={
                <TextField
                  disabled
                  label=""
                  type="text"
                  color="primary"
                  variant="outlined"
                  value={data?.company?.name}
                  fullWidth
                />
              }
              control={control}
              name="companyId"
              mode="onChange"
            />
          ) : (
            <CompanyTypeAndSeachInput
              handleChangeCompany={handleChangeCompany}
              setCompanyId={setCompanyId}
              control={control}
              errors={errors}
              kind={constants.companies.KINDS.TEMPLATE}
              label=""
            />
          )}
        </Label>
        <Label
          title="Departamentos"
          description="Selecione o departamento em que os dados serão armazenados."
          xs={6}
          item
        >
          <Controller
            as={
              <SelectComponent
                disabled={!companyId}
                items={departments}
                open={selectDepartmentOpen}
                error={!!errors.departmentId}
                helperText={errors?.departmentId?.message}
              />
            }
            onChange={([event]) => {
              setCodeByDepartment(event.target.value)
              return event.target.value
            }}
            control={control}
            name="departmentId"
            mode="onChange"
          />
        </Label>
        <Label
          title="Código"
          description="Identificador único do registro em sua empresa"
          lg={6}
          item
          xs={12}
        >
          <Controller
            as={
              <TextField
                error={!!errors.code}
                helperText={errors?.code?.message}
                fullWidth
              />
            }
            control={control}
            name="code"
            mode="onChange"
          />
        </Label>
        <Label
          title="Versão"
          description="Versão do novo processamento de dados."
          lg={6}
          item
          xs={12}
        >
          <TextField value="0" disabled />
        </Label>
        <Label
          title="Nome do registro"
          description="Descreva qual o nome do registro que vamos mapear, ou seja, aqui é onde damos início ao mapeamento"
          item
          xs={12}
        >
          <Controller
            as={
              <TextField
                variant="outlined"
                error={!!errors.name}
                helperText={errors?.name?.message}
                fullWidth
              />
            }
            control={control}
            name="name"
            mode="onChange"
          />
        </Label>
        <ContextDataProcessSource
          disabled={!companyId}
          errors={errors}
          control={control}
          classes={classes}
          setValue={setValue}
          getValues={getValues}
          allSources={allSources}
          setAllSources={setAllSources}
          watchSourceDescription={watchSourceDescription}
          isEdit={isEdit}
        />
        <Label
          title="Por que o dado é tratado?"
          description="Para que? Por qual motivo a empresa trata esses dados? O objetivo é descobrir a finalidade"
          item
          xs={12}
        >
          <Controller
            as={
              <TextField
                multiline
                rows={3}
                error={!!errors.finality}
                helperText={errors?.finality?.message}
                fullWidth
              />
            }
            control={control}
            name="finality"
            mode="onChange"
          />
        </Label>
        <Grid item xs={12}>
          <VolumetryForm
            setValue={setValue}
            register={register}
            errors={errors}
            control={control}
            setVolumetryType={setVolumetryType}
            volumetryType={volumetryType}
            laws={laws}
            setLaws={setLaws}
            data={data?.dataProcess}
            enable={companyId}
            storageMode={data?.storageMode}
          />
        </Grid>
      </Grid>
    </form>
  )
}

GeneralInformation.propTypes = {
  description: PropTypes.string.isRequired,
}

export default GeneralInformation
