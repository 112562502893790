import { Box, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'

import { TitleSection, TopicSection, GreyChip } from '../'

import { Share2 as ShareIcon } from 'react-feather'

const DataTreatments = ({ dataTreatments }) => {
  return (
    <Box m={2}>
      <TitleSection
        title="Dados compartilhados"
        subtitle="com quem os dados são compartilhados?"
        icon={ShareIcon}
      />
      <Grid container>
        {dataTreatments?.map((dataTreatment) => (
          <Grid item xs={6} key={dataTreatment?.id}>
            <TopicSection text={dataTreatment?.shareProcess?.name} />
            <Box display="flex" mt={0.5}>
              <GreyChip text={dataTreatment?.shareProcess?.name} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

DataTreatments.propTypes = {
  dataTreatments: PropTypes.array,
}

DataTreatments.defaultProps = {
  dataTreatments: [],
}

export default DataTreatments
