import { useState } from 'react'

import { Button } from '@material-ui/core'

import { TableRow, TableCell } from 'components/Table'

import { MenuButton } from 'components'
import { useSnackbar, useDataProcess, useSelectCountry } from 'hooks'

import PropTypes from 'prop-types'

import DialogRemoveDataTreatment from '../../../../DialogRemoveDataTreatment'
import DialogDataTreatment from '../../../../DialogDataTreatment'

import * as service from 'service'
import helpers from 'helpers'

const DataTreatmentRow = ({ dataTreatment, setLoading }) => {
  const snackbar = useSnackbar()
  const {
    data: dataProcess,
    reloadOptions,
    reloadDataProcess,
  } = useDataProcess()
  const { setSelectedCountries } = useSelectCountry()

  const [dialogDataTreatment, setDialogDataTreatment] = useState(false)
  const [dialogRemoveDataTreatment, setDialogRemoveDataTreatment] =
    useState(false)
  const [editionDataTreatment, setEditionDataTreatment] = useState(false)
  const [removableDataTreatmentId, setRemovableDataTreatmentId] = useState()

  const handleDelete = async () => {
    try {
      setLoading(true)
      await service.dponet.dataTreatments.destroy({
        dataProcessId: dataProcess.id,
        dataTreatmentId: dataTreatment.id,
      })
      reloadDataProcess()
      snackbar.open({
        message: 'Compartilhamento de dados removido com sucesso!',
        variant: 'success',
      })

      reloadOptions()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
    setLoading(false)
  }

  const handleEdit = (dataTreatment) => {
    setEditionDataTreatment(dataTreatment)
    setSelectedCountries(dataTreatment?.internationalTransfers)
    setDialogDataTreatment(true)
  }

  const handleOpenRemoveDialog = (dataTreatment) => {
    setRemovableDataTreatmentId(dataTreatment.id)
    setDialogRemoveDataTreatment(true)
  }

  return (
    <TableRow>
      <TableCell>{dataTreatment?.shareProcess?.name}</TableCell>
      <TableCell>{dataTreatment?.description}</TableCell>
      <TableCell>{dataTreatment?.controllMechanismView}</TableCell>
      <TableCell>{dataTreatment?.shareType?.name}</TableCell>
      <TableCell align="right" disableTooltip>
        <MenuButton>
          <Button
            color="secondary"
            fullWidth
            onClick={() => handleEdit(dataTreatment)}
          >
            Editar
          </Button>
          <Button
            color="secondary"
            fullWidth
            onClick={() => handleOpenRemoveDialog(dataTreatment?.id)}
          >
            Excluir
          </Button>
        </MenuButton>
      </TableCell>
      <DialogDataTreatment
        dataProcessId={dataProcess?.id}
        open={dialogDataTreatment}
        setOpen={setDialogDataTreatment}
        setLoading={setLoading}
        dataTreatment={editionDataTreatment}
      />
      <DialogRemoveDataTreatment
        open={dialogRemoveDataTreatment}
        setOpen={setDialogRemoveDataTreatment}
        dataProcessId={dataProcess?.id}
        dataTreatmentId={removableDataTreatmentId}
        handleRemove={handleDelete}
      />
    </TableRow>
  )
}

DataTreatmentRow.propTypes = {
  setLoading: PropTypes.func,
  dataTreatment: PropTypes.object.isRequired,
}

export default DataTreatmentRow
