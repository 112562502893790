/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Plus as PlusIcon } from 'react-feather'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  Button,
  TextField,
  Grid,
  Typography,
  Link,
  useMediaQuery,
  TablePagination,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import {
  UserCompaniesTable,
  UserCompaniesForm,
  ProfileSection,
} from './components'
import {
  Select as SelectComponent,
  Card,
  Permitted,
  LoadingBox,
  CompanyTypeAndSeachInput,
} from 'components'

import useSnackbar from 'hooks/useSnackbar'
import usePagination from 'hooks/usePagination'

import * as service from 'service'
import helpers from 'helpers'
import { routes } from 'Routes'

import schema from './schema'
import constants from 'constants/index'
import useStyles from './styles'

const FormUsers = ({ userClient, isEdit, user, refreshUser }) => {
  const createProfilePermissionTag = userClient
    ? constants.permissions.CLIENT_PROFILES.CREATE
    : constants.permissions.COLLABORATOR_PROFILES.CREATE

  const [loading, setLoading] = useState(false)
  const [loadingCompanies, setLoadingCompanies] = useState(false)
  const [companyId, setCompanyId] = useState()
  const [userCompaniesData, setUserCompaniesData] = useState([])
  const [linkedCompany, setLinkedCompany] = useState(false)
  const [profile, setProfile] = useState({})
  const [profileTypeResponsible, setProfileTypeResponsible] = useState(false)
  const [profiles, setProfiles] = useState([])

  const [reload, setReload] = useState(0)
  const theme = useTheme()

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const classes = useStyles()

  const refresh = () => {
    setReload(reload + 1)
    refreshUser()
  }

  const history = useHistory()
  const snackbar = useSnackbar()

  const { setValue, handleSubmit, errors, reset, getValues, watch, control } =
    useForm({
      validationSchema: schema(userClient, isEdit, profileTypeResponsible),
      defaultValues: {
        companyId: null,
        name: user?.name ?? '',
        email: user?.email ?? '',
        profileId: !userClient ? user?.profile?.id ?? '' : '',
        phone: helpers.formatters.phoneDynamicMask(user?.phone ?? ''),
      },
    })

  const phoneEnableField = profileTypeResponsible || !userClient

  const redirectRollBack = () => {
    userClient
      ? history.push(routes.users.all)
      : history.push(routes.collaborators.all)
  }

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      if (isEdit) {
        if (userClient) {
          await service.dponet.user.edit({
            userId: user.id,
            user: { ...data, companyId },
          })
        } else {
          await service.dponet.userCollaborators.edit({
            userId: user.id,
            user: data,
          })
        }
      } else {
        if (userClient) {
          await service.dponet.user.create({ user: { ...data, companyId } })
        } else {
          await service.dponet.userCollaborators.create({ user: data })
        }
      }

      snackbar.open({
        message: `Usuário ${isEdit ? 'editado' : 'criado'} com sucesso`,
        variant: 'success',
      })
      redirectRollBack()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error.response.data.error,
          false,
        ),
        variant: 'error',
      })
      reset(data)
      setLoading(false)
    }
  }

  const navigateToCreateProfile = () => {
    if (userClient) {
      history.push(routes.userProfiles.new)
    } else {
      history.push(routes.collaboratorProfiles.new)
    }
  }

  const selectedProfile = () => {
    const profileId = getValues('profileId')

    if (!!profileId && !isEmpty(profiles)) {
      const profile = profiles.find((profile) => profile.id === profileId)

      if (!!profile) {
        return setProfile(profile)
      }
    }

    return setProfile({})
  }

  const handleChangeCompany = () => {
    setValue('profileId', '')
  }

  useEffect(() => {
    const loadProfiles = async () => {
      if (!companyId && userClient) return

      const profileRoute = userClient
        ? service.dponet.userProfiles.get
        : service.dponet.collaboratorProfiles.get

      const response = await profileRoute({
        perPage: 100000,
        companyId,
        listDefaults: true,
        status: true,
      })

      setProfiles(response?.data?.profiles)
    }

    loadProfiles()
  }, [companyId])

  useEffect(() => {
    selectedProfile()
  }, [profiles, watch('profileId')])

  useEffect(() => {
    const getUserCompanies = async () => {
      setLoadingCompanies(true)
      const response = await service.dponet.userCompanies.get({
        userId: user?.id,
        perPage: perPage,
        page: page,
      })
      setUserCompaniesData(response?.data)
      setProfileTypeResponsible(
        response?.data?.userCompanies?.filter((userCompany) => {
          return userCompany.responsable === true
        }).length === 0
          ? false
          : true,
      )
      setLoadingCompanies(false)
    }

    if (userClient && isEdit) {
      getUserCompanies()
    }
  }, [page, perPage, reload])

  return (
    <>
      {loading || loadingCompanies ? (
        <LoadingBox />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card
            title={`Preencha os dados do ${
              userClient ? 'usuário' : 'colaborador'
            }`}
          >
            <Grid container spacing={1}>
              <Grid item md={6} xs={12}>
                <Controller
                  as={
                    <TextField
                      label="Nome"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="name"
                  mode="onBlur"
                />
              </Grid>
              {userClient && !isEdit && (
                <Grid item md={6} xs={12}>
                  <CompanyTypeAndSeachInput
                    setCompanyId={setCompanyId}
                    handleChangeCompany={handleChangeCompany}
                    control={control}
                    errors={errors}
                  />
                </Grid>
              )}
              <Grid item md={6} xs={12}>
                <Controller
                  as={
                    <TextField
                      label="Email"
                      type="email"
                      color="primary"
                      variant="outlined"
                      error={!!errors.email}
                      helperText={errors?.email?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="email"
                  mode="onBlur"
                />
              </Grid>
              {phoneEnableField && (
                <Grid item md={6} xs={12}>
                  <Controller
                    as={
                      <TextField
                        label={
                          userClient
                            ? 'Telefone do usuário'
                            : 'Telefone do colaborador'
                        }
                        type="text"
                        color="primary"
                        variant="outlined"
                        placeholder="(99) 9999-9999"
                        error={!!errors.phone}
                        helperText={errors?.phone?.message}
                        fullWidth
                      />
                    }
                    onChange={([event]) => {
                      return helpers.formatters.phoneDynamicMask(
                        event.target.value,
                      )
                    }}
                    control={control}
                    name="phone"
                    mode="onBlur"
                  />
                </Grid>
              )}
            </Grid>
          </Card>

          {(!isEdit || (!userClient && user?.active)) && (
            <Box mt={3}>
              <Typography variant="h4">
                Perfil de usuário e permissão
              </Typography>
              <Box mt={1} mb={2}>
                <Box className={classes.selectProfileBox}>
                  <Controller
                    control={control}
                    name="profileId"
                    mode="onBlur"
                    as={
                      <SelectComponent
                        disabled={!companyId && userClient}
                        label={
                          userClient
                            ? 'Perfil de usuário'
                            : 'Perfil de colaborador'
                        }
                        items={profiles || []}
                        error={!!errors.profileId}
                        helperText={errors?.profileId?.message}
                      />
                    }
                  />
                </Box>
                <Permitted tag={createProfilePermissionTag}>
                  <Box pt={1}>
                    <Typography variant="body2" color="textPrimary">
                      Não encontrou a opção? Clique{' '}
                      <Link
                        onClick={navigateToCreateProfile}
                        className={classes.link}
                      >
                        aqui
                      </Link>{' '}
                      para adicionar
                    </Typography>
                  </Box>
                </Permitted>
              </Box>
              {!isEmpty(profile) && (
                <Box>
                  <ProfileSection
                    simplifiedProfile={profile}
                    profileId={profile.id}
                    userClient={userClient}
                  />
                </Box>
              )}
            </Box>
          )}

          {userClient && isEdit && (
            <Card
              actionButton={
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<PlusIcon />}
                  onClick={() => setLinkedCompany(true)}
                >
                  Criar
                </Button>
              }
              title="Empresas vinculadas"
              dropdown
            >
              {!loadingCompanies && (
                <Box>
                  <UserCompaniesTable
                    user={user}
                    refresh={refresh}
                    userCompanies={userCompaniesData?.userCompanies}
                  />
                  <Box px={2} display="flex" justifyContent="flex-end">
                    <TablePagination
                      component="div"
                      count={userCompaniesData?.meta?.totalCount || 0}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      page={page - 1}
                      rowsPerPage={perPage}
                      rowsPerPageOptions={[5, 10, 25, 100]}
                      labelRowsPerPage={isDesktop ? 'Por página' : ''}
                      nextIconButtonProps={{ size: 'small' }}
                      backIconButtonProps={{ size: 'small' }}
                    />
                  </Box>
                </Box>
              )}
            </Card>
          )}

          <Box display="flex" justifyContent="flex-start">
            <Box pr={1}>
              <Button
                type="button"
                variant="contained"
                onClick={redirectRollBack}
              >
                Voltar
              </Button>
            </Box>
            <Box>
              <Button color="primary" variant="contained" type="submit">
                Salvar
              </Button>
            </Box>
          </Box>

          {userClient && isEdit && (
            <UserCompaniesForm
              userId={user?.id}
              setOpen={setLinkedCompany}
              refresh={refresh}
              open={linkedCompany}
            />
          )}
        </form>
      )}
    </>
  )
}

FormUsers.propTypes = {
  userClient: PropTypes.bool,
  isEdit: PropTypes.bool,
  user: PropTypes.object,
  refreshUser: PropTypes.func,
}

FormUsers.defaultProps = {
  userClient: false,
  isEdit: false,
  refreshUser: () => {},
}

export default FormUsers
