import constants from 'constants/platformContract'

const chipStatusColor = (status, classes) => {
  if (constants.AWAITING_STATUSES.includes(status)) return classes.awaiting

  switch (status) {
    case constants.GENERATING_STATUS:
      return classes.generating
    case constants.AWAITING_PAYMENT_ORDER_STATUS:
      return classes.awaitingPaymentOrder
    case constants.FAILED_STATUS:
      return classes.failed
    case constants.CANCELED_STATUS:
      return classes.canceled
    case constants.FINALIZED_STATUS:
      return classes.finalized
    default:
      return null
  }
}

export default chipStatusColor
