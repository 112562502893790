import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  chip: {
    borderRadius: 4,
    textTransform: 'uppercase',
    fontWeight: 700,
    color: theme.palette.white,
  },
  published: {
    backgroundColor: colors.green[800],
  },
  sketch: {
    backgroundColor: colors.blue[800],
  },
  inactive: {
    backgroundColor: colors.grey[400],
  },
}))
export default styles
