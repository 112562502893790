import constants from 'constants/index'

const formatOrderValue = (filterOrder) => {
  switch (filterOrder) {
    case constants.orderFilter.NEWLY_CREATED:
      return 'created_at DESC'
    case constants.orderFilter.OLDER_CREATED:
      return 'created_at ASC'
    case constants.orderFilter.NEWLY_UPDATED:
      return 'updated_at DESC'
    default:
      return 'updated_at ASC'
  }
}

export default formatOrderValue
