import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  chip: {
    borderRadius: 4,
    textTransform: 'uppercase',
    fontWeight: 700,
    color: theme.palette.white,
  },
}))

export default styles
