import { Breadcrumbs, Grid, Typography, makeStyles } from '@material-ui/core'
import { NavigateNext as NavigateNextIcon } from '@material-ui/icons'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

import styles from './styles'

const useStyles = makeStyles(styles)

const Header = ({ children }) => {
  const classes = useStyles()
  const history = useHistory()
  const handleRedirect = () => {
    return history.push(routes.suppliers.all)
  }

  return (
    <Grid
      className={classes.root}
      container
      justifyContent="space-between"
      alignItems="center"
      spacing={1}
    >
      <Grid item sm={6} xs={10} className={classes.gridNavigator}>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          classes={{
            li: classes.whiteTypography,
            ol: classes.whiteTypography,
          }}
          aria-label="breadcrumb"
        >
          <Typography variant="body1" color="inherit">
            Fornecedores
          </Typography>
          <Typography
            className={classes.menuNavigation}
            onClick={handleRedirect}
            variant="body1"
            color="inherit"
          >
            Listagem
          </Typography>
          <Typography variant="body1" color="inherit">
            Visualizar fornecedor
          </Typography>
        </Breadcrumbs>
        <Typography variant="h3" className={classes.whiteTypography}>
          Visualizar fornecedor
        </Typography>
      </Grid>
      {children}
    </Grid>
  )
}

export default Header
