import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import clsx from 'clsx'

import { CardCompanySize } from './components'
import { Select } from 'components'

import useFetch from 'hooks/useFetch'
import useStyles from './styles'

import * as service from 'service'

const Plans = ({ control, errors, watch, setValue, isEdit, plan }) => {
  const [selectedPlan, setSelectedPlan] = useState(null)

  const classes = useStyles()

  const { response: segmentResponse, isLoading: segmentIsLoading } = useFetch(
    service.dponet.segments.get,
    {
      order: 'name ASC',
      active: true,
      perPage: 10000,
      activePlans: true,
      partnership: true,
    },
    [],
  )

  const { response: plansResponse, isLoading: plansIsLoading } = useFetch(
    service.dponet.plans.get,
    {
      segmentId: watch('segmentId'),
      status: true,
    },
    [watch('segmentId')],
    watch('segmentId') !== null,
  )
  const handleSelect = (plan) => setSelectedPlan(plan)

  useEffect(() => {
    setValue('planId', selectedPlan?.id)

    if (!isEdit && selectedPlan?.typeService && selectedPlan?.vertical) {
      setValue('vertical', selectedPlan?.vertical)
      setValue('typeService', selectedPlan?.typeService)
    }

    if (isEdit && !selectedPlan) {
      setSelectedPlan(plan)
    }

    //eslint-disable-next-line
  }, [selectedPlan?.id])

  const errorsPlans = errors.planId && !watch('planId')

  return (
    <Grid item xs={12}>
      {!segmentIsLoading && (
        <Controller
          control={control}
          name="segmentId"
          mode="onChange"
          onChange={([e]) => {
            setValue('planId', '')
            return e
          }}
          as={
            <Select
              label="Segmento da Empresa"
              items={segmentResponse?.data?.segments}
              error={!!errors?.segmentId}
              helperText={errors?.segmentId?.message}
            />
          }
        />
      )}
      <Controller
        control={control}
        name="planId"
        mode="onChange"
        as={
          <Box className={classes.boxPlans} mt={2}>
            {!plansIsLoading &&
              watch('segmentId') &&
              plansResponse?.data?.plans.map((plan) => (
                <Box
                  key={plan?.id}
                  className={clsx(classes.boxCardPlans, {
                    [classes.boxCardPlansError]: errorsPlans,
                  })}
                  error={!!errors?.planId}
                  helperText={errors?.planId?.message}
                >
                  <CardCompanySize
                    title={plan?.name}
                    subTitle={plan?.segment?.name}
                    price={plan?.price}
                    companyTemplate={plan?.companyTemplate}
                    implementationPrice={plan?.implementationPrice}
                    implementationSplit={plan?.implementationSplit}
                    selected={selectedPlan?.id === plan?.id}
                    handleSelect={() => handleSelect(plan)}
                    code={plan?.code}
                  />
                </Box>
              ))}
          </Box>
        }
      />

      {errorsPlans && (
        <Typography className={classes.errorsPlans}>
          Selecione um plano
        </Typography>
      )}
    </Grid>
  )
}

Plans.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  isEdit: PropTypes.bool,
  plan: PropTypes.object,
  isTrial: PropTypes.bool,
}

export default Plans
