const ALL_TAB = 0
const PUBLISHED_TAB = 1
const DRAFT_TAB = 2
const INACTIVE_TAB = 3

const VIEW_PERMISSION = {
  COLLABORATOR: 'collaborator',
  COLLABORATOR_AND_CLIENT: 'collaborator_and_client',
  COLLABORATOR_AND_ADVISOR: 'collaborator_and_advisor',
  COLLABORATOR_AND_EASY: 'collaborator_and_easy',
}

const VIEW_PERMISSION_LABEL = {
  CC: 'Colaborador e Cliente',
  C: 'Colaborador',
  CA: 'Colaborador e Advisor',
  CE: 'Colaborador e Easy',
}

const SELECT_VIEW_PERMISSION = [
  { id: VIEW_PERMISSION.COLLABORATOR, name: VIEW_PERMISSION_LABEL.C },
  {
    id: VIEW_PERMISSION.COLLABORATOR_AND_CLIENT,
    name: VIEW_PERMISSION_LABEL.CC,
  },
  {
    id: VIEW_PERMISSION.COLLABORATOR_AND_ADVISOR,
    name: VIEW_PERMISSION_LABEL.CA,
  },
  {
    id: VIEW_PERMISSION.COLLABORATOR_AND_EASY,
    name: VIEW_PERMISSION_LABEL.CE,
  },
]

const KINDS = {
  FAQ: 'faq',
  DOCUMENTATION: 'documentation',
}

const KINDS_LABELS = {
  F: 'FAQ',
  D: 'Documentação',
}

const SELECT_FAQS_DOCUMENTATION_KIND = [
  { id: KINDS.DOCUMENTATION, name: KINDS_LABELS.D },
  { id: KINDS.FAQ, name: KINDS_LABELS.F },
]

const TABS = [
  {
    value: ALL_TAB,
    label: 'TODAS',
  },
  {
    value: PUBLISHED_TAB,
    label: 'PUBLICADAS',
  },
  {
    value: DRAFT_TAB,
    label: 'RASCUNHO',
  },
  {
    value: INACTIVE_TAB,
    label: 'INATIVAS',
  },
]

const faqsDocumentations = {
  ALL_TAB,
  PUBLISHED_TAB,
  DRAFT_TAB,
  INACTIVE_TAB,
  TABS,
  KINDS,
  KINDS_LABELS,
  SELECT_FAQS_DOCUMENTATION_KIND,
  VIEW_PERMISSION,
  VIEW_PERMISSION_LABEL,
  SELECT_VIEW_PERMISSION,
}

export default faqsDocumentations
