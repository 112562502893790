import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  gapGrid: {
    gap: '20px',
  },
  labelSwitch: {
    marginRight: 0,
  },
  otherSource: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
}))

export default styles
