import helpers from 'helpers'
import constants from 'constants/index'

const formatPersonalParamsToApi = (params) => {
  if (!params) return {}

  let formattedParams = {
    name: params.name,
    companyName: null,
    description: params.description,
    cpf: helpers.formatters.onlyNumbersString(params.document),
    cnpj: null,
    siteUrl: params.siteUrl || null,
    statusId: params.statusId,
    phone: helpers.formatters.onlyNumbersString(params.phone),
    stateRegistration: null,
    municipalRegistration: null,
    email: params.email,
    kind: constants.companies.KINDS.PERSONAL,
    groupId: helpers.formatters.getIdFromObject(params.groupId),
    subgroupId: helpers.formatters.getIdFromObject(params.subgroupId),
    segmentId: helpers.formatters.getIdFromObject(params.segmentId),
    companySizeId: helpers.formatters.getIdFromObject(params.companySizeId),
    companyTemplateId: helpers.formatters.getIdFromObject(
      params.companyTemplateId,
    ),
    blockUpdate: params.blockUpdate,
    provideServiceLegalFramework: params.provideServiceLegalFramework,
    acceptProcessFragility: params.acceptProcessFragility,
    notifyDataProcessChanges: params.notifyDataProcessChanges,
    address: {
      street: params.street,
      number: params.number,
      neighborhood: params.neighborhood,
      complement: params.complement || null,
      postalCode: helpers.formatters.onlyNumbersString(params.postalCode),
      city: params.city,
      country: params.country,
      state: params.state,
    },
    responsableUser: {
      id: helpers.formatters.getIdFromObject(params.responsibleUserId),
      name: params.responsibleName || null,
      email: params.responsibleEmail || null,
      phone: helpers.formatters.onlyNumbersString(params.responsiblePhone),
    },
    economicActivities: [
      {
        mainActivity: true,
        economicActivityId: helpers.formatters.getIdFromObject(
          params.economicActivityId,
        ),
      },
    ],
  }

  return formattedParams
}

export default formatPersonalParamsToApi
