import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  dialogActions: {
    paddingBlock: theme.spacing(1),
    paddingInline: theme.spacing(3),
  },
}))

export default styles
