import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  btn: {
    height: 53,
    padding: 0,
  },
  radio: {
    padding: '0px',
    margin: 4,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}))
export default styles
