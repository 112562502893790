const GENERATING_STATUS = 'generating'
const AWAITING_PAYMENT_ORDER_STATUS = 'awaiting_payment_order'
const FAILED_STATUS = 'failed'
const AWAITING_SELLER_STATUS = 'awaiting_seller'
const AWAITING_CLIENT_STATUS = 'awaiting_client'
const AWAITING_CEO_STATUS = 'awaiting_ceo'
const AWAITING_FINANCIAL_STATUS = 'awaiting_financial'
const CANCELED_STATUS = 'canceled'
const FINALIZED_STATUS = 'finalized'

const GENERATING_STATUS_LABEL = 'Gerando contrato'
const AWAITING_PAYMENT_STATUS_LABEL = 'Aguardando ordem de pagamento'
const FAILED_STATUS_LABEL = 'Falha ao gerar contrato'
const AWAITING_SELLER_LABEL = 'Aguardando Vendedor'
const AWAITING_CLIENT_LABEL = 'Aguardando Cliente'
const AWAITING_CEO_LABEL = 'Aguardando CEO'
const AWAITING_FINANCIAL_LABEL = 'Aguardando financeiro'
const CANCELED_LABEL = 'Contrato cancelado'
const FINALIZED_STATUS_LABEL = 'Assinado por todos'

const PLATFORM_ONLY_KIND = 'platform_only'
const PLATFORM_AND_DPO_KIND = 'platform_and_dpo'
const CUSTOM_KIND_KIND = 'custom'

const PLATFORM_ONLY_LABEL = 'Apenas plataforma'
const PLATFORM_AND_DPO_LABEL = 'Plataforma e serviço de DPO'
const CUSTOM_KIND_LABEL = 'Outro'

const AWAITING_STATUSES = [
  AWAITING_SELLER_STATUS,
  AWAITING_CLIENT_STATUS,
  AWAITING_CEO_STATUS,
  AWAITING_FINANCIAL_STATUS,
]

const paymentContract = {
  GENERATING_STATUS,
  AWAITING_PAYMENT_ORDER_STATUS,
  FAILED_STATUS,
  AWAITING_SELLER_STATUS,
  AWAITING_CLIENT_STATUS,
  AWAITING_CEO_STATUS,
  AWAITING_FINANCIAL_STATUS,
  CANCELED_STATUS,
  FINALIZED_STATUS,
  GENERATING_STATUS_LABEL,
  AWAITING_PAYMENT_STATUS_LABEL,
  FAILED_STATUS_LABEL,
  AWAITING_SELLER_LABEL,
  AWAITING_CLIENT_LABEL,
  AWAITING_CEO_LABEL,
  AWAITING_FINANCIAL_LABEL,
  AWAITING_STATUSES,
  CANCELED_LABEL,
  FINALIZED_STATUS_LABEL,
  PLATFORM_ONLY_KIND,
  PLATFORM_AND_DPO_KIND,
  CUSTOM_KIND_KIND,
  PLATFORM_ONLY_LABEL,
  PLATFORM_AND_DPO_LABEL,
  CUSTOM_KIND_LABEL,
}

export default paymentContract
