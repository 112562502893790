import permissions from '../../permissions'

const COMPANY_SIZE_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.COMPANY_SIZES.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.CREATE,
    tag: permissions.COMPANY_SIZES.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.EDIT,
    tag: permissions.COMPANY_SIZES.EDIT,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.DESTROY,
    tag: permissions.COMPANY_SIZES.DESTROY,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.ACTIVATE_OR_INACTIVATE,
    tag: permissions.COMPANY_SIZES.ACTIVATE_OR_INACTIVATE,
  },
]

export default COMPANY_SIZE_LIST
