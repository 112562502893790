import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import useSnackbar from 'hooks/useSnackbar'
import PropTypes from 'prop-types'

import { Box, Button } from '@material-ui/core'
import {
  CopyCompanySelection,
  EconomicActivityForm,
  SegmentationForm,
  SecondaryEconomicActivityTable,
} from '../../components'
import { MainForm } from './components'
import { SelectSecondaryEconomicActivityDialog } from '../SecondaryEconomicActivityTable/components'

import schema from './schema'
import useStyles from './styles'
import helpers from 'helpers'
import * as service from 'service'
import { routes } from 'Routes'
import constants from 'constants/index'

const TemplateForm = ({
  setLoading,
  disableCertFields,
  editionPermitted,
  company,
  refresh,
}) => {
  const history = useHistory()
  const classes = useStyles()
  const snackbar = useSnackbar()
  const [
    selectSecondaryEconomicActivityDialog,
    setSelectSecondaryEconomicActivityDialog,
  ] = useState(false)

  const { control, handleSubmit, errors, reset, getValues, setValue } = useForm(
    {
      validationSchema: schema,
      defaultValues: {
        name: company?.name || '',
        description: company?.description || '',
        companyTemplateId: company?.template_company || {
          id: 0,
          name: '',
        },
        statusId: company?.statusId || constants.companies.STATUSES.ONBOARDING,
        groupId: company?.group || { id: 0, name: '' },
        subgroupId: company?.subgroup || { id: 0, name: '' },
        segmentId: company?.segment || { id: 0, name: '' },
        companySizeId: company?.companySize || { id: 0, name: '' },
        economicActivityId: company?.mainEconomicActivity || {
          id: 0,
          name: '',
        },
      },
    },
  )

  const onSubmit = (data) => {
    setLoading(true)

    if (company) {
      handleEdit(data)
    } else {
      handleCreate(data)
    }
  }

  const handleCreate = async (data) => {
    let params = helpers.companies.formatTemplateParamsToApi(data)

    try {
      await service.dponet.companies.create({ company: params })
      setLoading(false)

      snackbar.open({
        message: 'Empresa criada com sucesso!',
        variant: 'success',
      })

      refresh()

      history.push(routes.companies.all)
    } catch (error) {
      handleError(error, data)
    }
  }

  const handleError = (error, data) => {
    setLoading(false)
    reset(data)

    snackbar.open({
      message: helpers.formatters.errorMessage(error?.response?.data?.error),
      variant: 'error',
    })
  }

  const handleEdit = async (data) => {
    let params = helpers.companies.formatTemplateParamsToApi(data)

    try {
      await service.dponet.companies.put({
        company: params,
        companyId: company?.id,
      })

      snackbar.open({
        message: 'Empresa editada com sucesso!',
        variant: 'success',
      })

      refresh()

      history.push(routes.companies.all)
    } catch (error) {
      handleError(error, data)
    }
    setLoading(false)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.fullWidth}>
        <CopyCompanySelection
          errors={errors}
          control={control}
          reset={reset}
          getValues={getValues}
          setLoading={setLoading}
          disableCertFields={disableCertFields}
          company={company}
        />
        <Box mt={4}>
          <MainForm
            control={control}
            errors={errors}
            editionPermitted={editionPermitted}
          />
        </Box>
        <Box mt={4}>
          <SegmentationForm
            control={control}
            errors={errors}
            group={company?.group}
            subgroup={company?.subgroup}
            setValue={setValue}
            setLoading={setLoading}
            editionPermitted={editionPermitted}
          />
        </Box>
        <Box mt={4}>
          <EconomicActivityForm
            control={control}
            errors={errors}
            disableCertFields={disableCertFields}
            editionPermitted={editionPermitted}
            company={company}
          />
        </Box>
        {company && (
          <Box mt={4}>
            <SecondaryEconomicActivityTable
              companyId={company?.id}
              control={control}
              errors={errors}
              setSelectSecondaryEconomicActivityDialog={
                setSelectSecondaryEconomicActivityDialog
              }
              editionPermitted={editionPermitted}
            />
          </Box>
        )}
        {editionPermitted && (
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Button type="submit" variant="contained" color="primary">
              {company ? 'Salvar' : 'Salvar e criar empresa'}
            </Button>
          </Box>
        )}
      </form>
      {company && editionPermitted && (
        <SelectSecondaryEconomicActivityDialog
          open={selectSecondaryEconomicActivityDialog}
          setOpen={setSelectSecondaryEconomicActivityDialog}
          company={company}
        />
      )}
    </>
  )
}

TemplateForm.prototype = {
  setLoading: PropTypes.func,
}

TemplateForm.defaultProps = {
  setLoading: () => {},
}

export default TemplateForm
