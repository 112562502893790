import permissions from '../../permissions'

const DOCUMENT_TEMPLATE_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.DOCUMENTS_TEMPLATE.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.CREATE,
    tag: permissions.DOCUMENTS_TEMPLATE.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.EDIT,
    tag: permissions.DOCUMENTS_TEMPLATE.EDIT,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.DESTROY,
    tag: permissions.DOCUMENTS_TEMPLATE.DESTROY,
  },
]

export default DOCUMENT_TEMPLATE_LIST
