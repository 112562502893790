const messagesMultiSelect = () => {
  const messages = {
    searchPlaceholder: 'Buscar',
    noItemsMessage: 'Nada selecionado',
    noneSelectedMessage: 'Nenhum item selecionado',
    selectedMessage: 'selecionado(s)',
    selectAllMessage: 'Selecionar Todos',
    clearAllMessage: 'Limpar Todos',
    disabledItemsTooltip: 'Você pode selecionar apenas um arquivo',
  }
  return messages
}

export default messagesMultiSelect
