import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  title: {
    fontWeight: 'bold',
  },
  infoBox: {
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  },
}))

export default styles
