import { useContext } from 'react'

import TransferListContext from 'contexts/TransferListContext'

const useSnackbar = () => {
  const context = useContext(TransferListContext)

  if (context === undefined) {
    throw new Error(
      'useSnackbar must be used within a transferListContext.Provider',
    )
  }

  return {
    left: context.left,
    setLeft: context.setLeft,
    right: context.right,
    setRight: context.setRight,
    setInitialLeft: context.setInitialLeft,
    setInitialRight: context.setInitialRight,
    setInitialCount: context.setInitialCount,
    not: context.not,
    intersection: context.intersection,
    union: context.union,
    reloadToFormat: context.reloadToFormat,
    reload: context.reload,
    setReload: context.setReload,
  }
}

export default useSnackbar
