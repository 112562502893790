import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  buttonSelectAll: {
    fontSize: 12,
    textTransform: 'capitalize',
  },
  checkboxRoot: {
    padding: '4px 9px',
  },
  legalFrameworkRoot: {
    display: 'grid',
    gridTemplateColumns: '1fr 10fr',
  },
}))

export default styles
