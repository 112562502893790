import PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './styles'
import helpers from 'helpers'

const QuestionnaireStatusChip = ({ status }) => {
  const classes = useStyles()

  return (
    <Chip
      className={clsx(
        classes.root,
        helpers.questionnaires.statusColor(status, classes),
      )}
      align="center"
      size="small"
      label={helpers.questionnaires.statusLabel(status) ?? '-'}
    />
  )
}

QuestionnaireStatusChip.propTypes = {
  status: PropTypes.string.isRequired,
}

export default QuestionnaireStatusChip
