import { isEmpty } from 'lodash'
import constants from 'constants/index'

import moment from 'moment'

const mountTrial = (data) => {
  const {
    sellerId,
    fantasyName,
    document,
    email,
    phone,
    planId,
    name,
    kind,
    type,
    responsibleEmail,
    responsibleName,
    responsiblePhone,
    endDate,
    postalCode,
    country,
    state,
    city,
    neighborhood,
    street,
    number,
    complement,
    typeClient,
    typeService,
    vertical,
    companyTemplate,
    typeTrial,
  } = data

  const isAddressEmpty =
    isEmpty(postalCode) ||
    isEmpty(country) ||
    isEmpty(state) ||
    isEmpty(city) ||
    isEmpty(neighborhood) ||
    isEmpty(street) ||
    isEmpty(number)

  const address = isAddressEmpty
    ? null
    : {
        postalCode,
        country,
        state,
        city,
        neighborhood,
        street,
        number,
        complement,
      }

  const responsibleUser = {
    email: responsibleEmail,
    name: responsibleName,
    phone: responsiblePhone,
  }

  const partnerCompany = constants.preRegistration.PARTNER_COMPANY
  return {
    name: name || fantasyName,
    fantasyName,
    document,
    kind,
    phone,
    email,
    planId,
    type,
    typeService,
    vertical,
    typeClient,
    responsibleUser,
    sellerId,
    typeTrial,
    companyTemplateId: companyTemplate?.id,
    address: address,
    membershipRegistration: {
      segmentId: 1,
      partnerCompany: partnerCompany,
      endDate: moment(endDate).format('DD/MM/YYYY'),
    },
  }
}

export default mountTrial
