import { CreditCard as CreditCardIcon } from 'react-feather'
import {
  ReceiptOutlined as ReceiptIcon,
  AccountBalance as BankIcon,
} from '@material-ui/icons'

const INITIATED_STATUS = 'INITIATED'
const PAYMENT_FAILED_STATUS = 'PAYMENT_FAILED'
const FINALIZED_STATUS = 'FINALIZED'
const AWAITING_PAYMENT_STATUS = 'AWAITING_PAYMENT'
const DEMO_STATUS = 'DEMONSTRATION'
const TRIAL_LIMITED_STATUS = 'TRIAL_LIMITED'
const TRIAL_COMPLETE_STATUS = 'TRIAL_COMPLETE'
const ALL_TRIAL_STATUS = 'ALL_TRIAL'

const TRIAL_CANCELED_STATUS = 'TRIAL_CANCELED'
const OVERDUE_STATUS = 'OVERDUE'
const AWAITING_RESPONSE_STATUS = 'AWAITING_RESPONSE'

const INITIATED_LABEL = 'INICIADO'
const PAYMENT_FAILED_LABEL = 'FALHA NO PAGAMENTO'
const FINALIZED_LABEL = 'FINALIZADO'
const AWAITING_PAYMENT_LABEL = 'AGUARDANDO PAGAMENTO'
const DEMO_LABEL = 'DEMONSTRAÇÃO'
const TRIAL_LIMITED_LABEL = 'GRATUITO LIMITADO'
const TRIAL_COMPLETE_LABEL = 'GRATUITO COMPLETO'
const TRIAL_CANCELED_LABEL = 'GRATUIDADE CANCELADA'
const OVERDUE_LABEL = 'VENCIDO'
const AWAITING_RESPONSE_LABEL = 'AGUARDANDO RESPOSTA'

const TYPE_BY_STATUES = {
  [INITIATED_STATUS]: 'REAL',
  [PAYMENT_FAILED_STATUS]: 'REAL',
  [FINALIZED_STATUS]: 'REAL',
  [AWAITING_PAYMENT_STATUS]: 'REAL',
  [DEMO_STATUS]: 'TRIAL',
  [TRIAL_LIMITED_STATUS]: 'TRIAL',
  [TRIAL_COMPLETE_STATUS]: 'TRIAL',
  [TRIAL_CANCELED_STATUS]: 'TRIAL',
  [OVERDUE_STATUS]: 'REAL',
}

const STATUSES = {
  ALL_TRIAL_STATUS,
  AWAITING_PAYMENT_STATUS,
  AWAITING_RESPONSE_STATUS,
  DEMO_STATUS,
  FINALIZED_STATUS,
  INITIATED_STATUS,
  OVERDUE_STATUS,
  PAYMENT_FAILED_STATUS,
  TRIAL_CANCELED_STATUS,
  TRIAL_COMPLETE_STATUS,
  TRIAL_LIMITED_STATUS,
}

const STATUSES_LABELS = {
  AWAITING_PAYMENT_LABEL,
  DEMO_LABEL,
  FINALIZED_LABEL,
  INITIATED_LABEL,
  OVERDUE_LABEL,
  PAYMENT_FAILED_LABEL,
  TRIAL_CANCELED_LABEL,
  TRIAL_LIMITED_LABEL,
  TRIAL_COMPLETE_LABEL,
  AWAITING_RESPONSE_LABEL,
}

const KIND_PAYMENT_FEATURES = {
  initial: 'Em implementação',
  recurrence: 'Recorrência',
  manual: 'Manual',
  canceled: 'Cancelado',
  duplicated: 'Duplicado',
  suspended: 'Suspenso',
}

const OPTIONS_KIND_PAYMENT_FEATURES = [
  { id: 'initial', name: 'Em implementação' },
  { id: 'recurrence', name: 'Recorrência' },
  { id: 'manual', name: 'Manual' },
  { id: 'canceled', name: 'Cancelado' },
  { id: 'suspended', name: 'Suspenso' },
]

const CLOSED_STATUSES = ['canceled', 'suspended']

const OPTIONS_METHOD_PAYMENT = [
  { id: 'card', name: 'Cartão' },
  { id: 'billet', name: 'Boleto' },
  { id: 'bank_transfer', name: 'TED (OMIE)' },
]

const PAYMENT_METHOD_ICONS = {
  card: <CreditCardIcon size={40} />,
  billet: <ReceiptIcon style={{ fontSize: 40 }} />,
  bank_transfer: <BankIcon style={{ fontSize: 40 }} />,
}

const OPTIONS_GATEWAY_PAYMENT_FEATURES = [
  { id: 'superfin', name: 'Superfin (padrão)' },
  { id: 'omie', name: 'Omie' },
]

const EXPIRATION_ACTION = {
  postpone: 'prorrogada',
  anticipate: 'antecipada',
}

const PARTNER_COMPANY = 'DPONET_TRIAL'

const NEWLY_CREATED = 'newly_created'
const OLDER_CREATED = 'older_created'

const DESTROY_DIALOG =
  'Se o cliente/sublicenciamento for removido, o contrato será cancelado no Omie, e a empresa será inativada.'

const CHECKOUT_ORIGIN = 'checkout'
const BACKOFFICE_ORIGIN = 'backoffice'
const SUPPLIER_ORIGIN = 'supplier'

const OPTION_ORIGINS = [
  { id: '', name: '' },
  { id: CHECKOUT_ORIGIN, name: 'Checkout' },
  { id: BACKOFFICE_ORIGIN, name: 'Backoffice' },
  { id: SUPPLIER_ORIGIN, name: 'Fornecedor' },
]

const INVOICE_OPTIONS = [
  { id: 'monthly', name: 'Todo mês (mensal)' },
  { id: 'bimonthly', name: 'A cada 2 meses (bimestral)' },
  { id: 'quarterly', name: 'A cada 3 meses (trimestral)' },
  { id: 'fourmonthly', name: 'A cada 4 meses (quadrimestral)' },
  { id: 'fivemonthly', name: 'A cada 5 meses (quinquemestral)' },
  { id: 'semiannual', name: 'A cada 6 meses (semestral)' },
  { id: 'annual', name: 'A cada 12 meses (anual)' },
  { id: 'biannual', name: 'A cada 24 meses (bianual)' },
]

const INVOICE_LABEL = {
  monthly: 'Todo mês (mensal)',
  bimonthly: 'A cada 2 meses (bimestral)',
  quarterly: 'A cada 3 meses (trimestral)',
  fourmonthly: 'A cada 4 meses (quadrimestral)',
  fivemonthly: 'A cada 5 meses (quinquemestral)',
  semiannual: 'A cada 6 meses (semestral)',
  annual: 'A cada 12 meses (anual)',
  biannual: 'A cada 24 meses (bianual)',
}

const RECURRENCE_FEATURE_STATUS = 'recurrence'

const TERM_PAYMENT_DEFAULT =
  'Prestação de serviços de adequação às regras da Lei Geral de Proteção de Dados Pessoais - ' +
  'DPOnet Desenvolvimento de Sistemas e Consultoria em Segurança da Informação Ltda\n\n' +
  'Método de Pagamento: <Preencher método de pagamento>'

const GATEWAY_DESCRIPTION = {
  superfin:
    'As cobranças serão realizadas através do gateway Superfin. ' +
    'Todo o processo de cobrança e pagamento será realizado através da ' +
    'plataforma DPOnet.',
  omie:
    'As cobranças serão realizadas através do gateway Omie. ' +
    'Todo o processo de cobrança e pagamento será realizado através do ' +
    'sistema Omie.',
}

const KIND_DESCRIPTION = {
  initial:
    'Em fase de implementação. O contrato será gerado somente após a confirmação do pagamento.',
  recurrence:
    'O sistema automatizará a geração das cobranças, inclusive a criação do ' +
    'contrato (no Omie, se ainda não estiver criado), garantindo que a ' +
    'nota fiscal seja sempre enviada juntamente com a cobrança.',
  manual:
    'Realizar a geração de cobranças manualmente, exclusivamente por meio da plataforma do Omie.',
  canceled: 'Cancela o contrato e a cobrança não será gerada.',
  duplicated:
    'Status temporário para facilitar a identificação de empresas que possuem múltiplos contratos ativos.',
}

const DESCRIPTION_CREATE_COMPANY_IS_TRIAL = {
  true: 'A empresa será criada em tempo real após confirmações dos dados.',
  false:
    'Após o pagamento da primeira fatura, a empresa será criada no portal do cliente se a opção de ativar a geração de empresa estiver selecionada como SIM.',
}

const STATUS_TRIALS = [
  TRIAL_LIMITED_STATUS,
  TRIAL_COMPLETE_STATUS,
  TRIAL_CANCELED_STATUS,
]

const TYPE_REAL = 'REAL'
const TYPE_TRIAL = 'TRIAL'

const CLIENT_TYPE = 'client'
const SUBLICENSE_TYPE = 'sublicensing'

const COPY_COMPANY_PLAN_TYPE = 'company_plan_type'
const COPY_COMPANY_EXISTING_COMPANY = 'company_existing_company'

const TYPES_PRE_REGISTRATION = {
  REAL: TYPE_REAL,
  TRIAL: TYPE_TRIAL,
}

const TYPES_PRE_REGISTRATION_LABEL = {
  REAL: 'Empresa Real',
  TRIAL: 'Empresa Trial',
}

const TYPE_COMPANIES_PRE_REGISTRATION = [
  { id: TYPES_PRE_REGISTRATION.REAL, name: TYPES_PRE_REGISTRATION_LABEL.REAL },
  {
    id: TYPES_PRE_REGISTRATION.TRIAL,
    name: TYPES_PRE_REGISTRATION_LABEL.TRIAL,
  },
]

const TYPE_CLIENT_LABEL = {
  client: 'Cliente',
  sublicensing: 'Sublicenciamento',
}

const TYPE_CLIENT = [
  {
    id: 'client',
    name: 'Cliente',
  },
  {
    id: 'sublicensing',
    name: 'Sublicenciamento',
  },
]

const KIND_TRANSLATED = {
  initial: 'Em implementação',
  recurrence: 'Recorrência',
  manual: 'Manual',
  canceled: 'Cancelado',
  duplicated: 'Duplicado',
  suspended: 'Suspenso',
}

const GATEWAY_TRANSLATED = {
  superfin: 'Superfin',
  omie: 'Omie',
}

const TRIAL_TYPES_OPTIONS = [
  { id: TRIAL_LIMITED_STATUS, name: 'Limitado' },
  { id: TRIAL_COMPLETE_STATUS, name: 'Completo' },
]

const preRegistration = {
  AWAITING_RESPONSE_LABEL,
  BACKOFFICE_ORIGIN,
  CHECKOUT_ORIGIN,
  CLIENT_TYPE,
  CLOSED_STATUSES,
  COPY_COMPANY_EXISTING_COMPANY,
  COPY_COMPANY_PLAN_TYPE,
  DESCRIPTION_CREATE_COMPANY_IS_TRIAL,
  DESTROY_DIALOG,
  EXPIRATION_ACTION,
  GATEWAY_DESCRIPTION,
  GATEWAY_TRANSLATED,
  INVOICE_LABEL,
  INVOICE_OPTIONS,
  KIND_DESCRIPTION,
  KIND_PAYMENT_FEATURES,
  KIND_TRANSLATED,
  NEWLY_CREATED,
  OLDER_CREATED,
  OPTION_ORIGINS,
  OPTIONS_GATEWAY_PAYMENT_FEATURES,
  OPTIONS_KIND_PAYMENT_FEATURES,
  OPTIONS_METHOD_PAYMENT,
  PARTNER_COMPANY,
  PAYMENT_METHOD_ICONS,
  RECURRENCE_FEATURE_STATUS,
  STATUS_TRIALS,
  STATUSES_LABELS,
  STATUSES,
  SUBLICENSE_TYPE,
  SUPPLIER_ORIGIN,
  TERM_PAYMENT_DEFAULT,
  TRIAL_TYPES_OPTIONS,
  TYPE_BY_STATUES,
  TYPE_CLIENT_LABEL,
  TYPE_CLIENT,
  TYPE_COMPANIES_PRE_REGISTRATION,
  TYPE_REAL,
  TYPE_TRIAL,
}

export default preRegistration
