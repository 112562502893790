import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box } from '@material-ui/core'

import CouponsTableRow from '../CouponsTableRow'
import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'

const CouponsTable = ({ coupons, refresh }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhum cupom encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="15%" align="center">
                Nome
              </TableCell>
              <TableCell width="10%" align="center">
                Código
              </TableCell>
              <TableCell width="15%" align="center">
                Tipo do desconto
              </TableCell>
              <TableCell width="10%" align="center">
                Valor do desconto
              </TableCell>
              <TableCell width="15%" align="center">
                Representante
              </TableCell>
              <TableCell width="10%" align="center">
                Status
              </TableCell>
              <TableCell width="15%" align="center">
                Data de cadastro
              </TableCell>
              <TableCell width="5%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {coupons.map((coupon) => (
              <CouponsTableRow
                refresh={refresh}
                coupon={coupon}
                key={coupon.id}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

CouponsTable.propTypes = {
  coupons: PropTypes.arrayOf(Object),
  refresh: PropTypes.func,
}

CouponsTable.defaultProps = {
  refresh: () => {},
}

export default CouponsTable
