import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'

import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'

const TableNecPropDataLifeCycleShow = ({ dataLifeCycles }) => {
  if (dataLifeCycles.length === 0) {
    return null
  }

  return (
    <Box my={2}>
      <Box width="100%" my={2}>
        <Typography variant="h6">Proteção não necessária</Typography>
      </Box>
      <Box width="100%">
        <PerfectScrollbar>
          <Table size="small" emptyMessage="Nenhuma proteção encontrada" noWrap>
            <TableHead>
              <TableRow>
                <TableCell width="50%">Proteção</TableCell>
                <TableCell width="50%">Armazenamento de referência</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {dataLifeCycles.map((dataLifeCycle) => {
                return (
                  <TableRow key={dataLifeCycle?.id}>
                    <TableCell>{dataLifeCycle?.protection?.name}</TableCell>
                    <TableCell>
                      {dataLifeCycle?.storageLocation?.name}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Box>
    </Box>
  )
}

TableNecPropDataLifeCycleShow.propTypes = {
  dataLifeCycles: PropTypes.arrayOf(Object).isRequired,
}

TableNecPropDataLifeCycleShow.defaultProps = {
  dataLifeCycles: [],
}

export default TableNecPropDataLifeCycleShow
