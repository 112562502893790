import PropTypes from 'prop-types'

import PerfectScrollbar from 'react-perfect-scrollbar'

import { Box, Typography, Grid, makeStyles } from '@material-ui/core'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'

import styles from './styles'

import { LoadingBox } from 'components'
import helpers from 'helpers'

const useStyles = makeStyles(styles)

const DataLifeCycle = ({ data, isLoading, ...rest }) => {
  const classes = useStyles()

  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <>
          <Typography variant="h5" className={classes.titleContainer}>
            Salvaguarda e Ciclo de Vida
          </Typography>
          {Array.isArray(data.lifeCycles) ? (
            <Grid xs={12} item>
              <Box maxHeight="82%">
                <PerfectScrollbar>
                  <Table
                    emptyMessage="NENHUM SALVAGUARDA E CICLO DE VIDA FOI ENCONTRADO"
                    noWrap
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell width="20%">Ambiente de alocação</TableCell>
                        <TableCell width="15%">
                          Onde os dados são armazenados
                        </TableCell>
                        <TableCell width="15%">
                          Tempo de Armazenamento
                        </TableCell>
                        <TableCell width="15%">
                          Justificativa de retenção
                        </TableCell>
                        <TableCell width="20%">
                          Como os dados são dispostos
                        </TableCell>
                        <TableCell width="15%">Forma de proteção</TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {data.lifeCycles.map((dataLifeCycle) => (
                        <TableRow key={dataLifeCycle.id}>
                          <TableCell>
                            {dataLifeCycle?.storageLocation?.name}
                          </TableCell>
                          <TableCell>
                            {dataLifeCycle?.internalAllocationMode?.name}
                          </TableCell>
                          <TableCell>
                            {!dataLifeCycle.value && !dataLifeCycle.volumetry
                              ? dataLifeCycle?.storageTime?.name
                              : `${dataLifeCycle?.value} ${dataLifeCycle?.volumetry}`}
                          </TableCell>
                          <TableCell>
                            {dataLifeCycle?.retentionFinality}
                          </TableCell>
                          <TableCell>
                            {helpers.dataProcesses.discardMode(dataLifeCycle)}
                          </TableCell>
                          <TableCell>
                            {dataLifeCycle?.protection?.name}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </PerfectScrollbar>
              </Box>
            </Grid>
          ) : (
            <Grid container className={classes.root}>
              <Grid item md={4} sm={6} xs={12}>
                <Box mt={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Onde os dados são armazenados?
                  </Typography>
                  <Typography color="textPrimary" variant="body2">
                    {data?.storageLocation?.name || ''}
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Como os dados são dispostos?
                  </Typography>
                  <Typography color="textPrimary" variant="body2">
                    {helpers.dataProcesses.discardMode(data) || ''}
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Recuperação
                  </Typography>
                  <Typography color="textPrimary" variant="body2">
                    {data?.recovery?.name || ''}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Box mt={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Ambiente de alocação
                  </Typography>
                  <Typography color="textPrimary" variant="body2">
                    {data?.internalAllocationMode?.name || ''}
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Tempo de Armazenamento
                  </Typography>
                  <Typography color="textPrimary" variant="body2">
                    {data?.storageTime?.name || ''}
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Proteção
                  </Typography>
                  <Typography color="textPrimary" variant="body2">
                    {data?.protection?.name || ''}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <Box mt={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Justificativa de retenção
                  </Typography>
                  <Typography color="textPrimary" variant="body2">
                    {data?.retentionFinality || ''}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  )
}

DataLifeCycle.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default DataLifeCycle
