import { Card, CardHeader, Divider, Box, Typography } from '@material-ui/core'

import PropTypes from 'prop-types'

import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from 'components/Table'

import useDataProcess from 'hooks/useDataProcess'

import useStyles from '../../styles'
import helpers from 'helpers'

const TableRmcRow = ({ nonComplianceReport }) => {
  return (
    <TableRow>
      <TableCell>{nonComplianceReport.id}</TableCell>
      <TableCell>
        {helpers.nonComplianceReports.origin(nonComplianceReport.finality)}
      </TableCell>
      <TableCell>
        {helpers.nonComplianceReports.status(nonComplianceReport.status)}
      </TableCell>
      <TableCell>{`${helpers.formatters.date(
        nonComplianceReport.createdAt,
      )} - ${helpers.formatters.hours(
        nonComplianceReport.createdAt,
      )}`}</TableCell>
    </TableRow>
  )
}

TableRmcRow.propTypes = {
  nonComplianceReport: PropTypes.object.isRequired,
}

const LegalFramework = () => {
  const { nonComplianceReports } = useDataProcess()
  const classes = useStyles()

  return (
    <Card>
      <CardHeader title="RMCs" />
      <Divider />
      {nonComplianceReports?.length > 0 ? (
        <PerfectScrollbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Identificador</TableCell>
                <TableCell>Origem</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Criado em</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nonComplianceReports.map((nonComplianceReport) => (
                <TableRmcRow
                  nonComplianceReport={nonComplianceReport}
                  key={nonComplianceReport.id}
                />
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      ) : (
        <Box className={classes.root}>
          <Typography>
            Nenhum registro de melhoria continua foi gerado.
          </Typography>
        </Box>
      )}
    </Card>
  )
}

export default LegalFramework
