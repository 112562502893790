const buttonNameCertification = (userCompany) => {
  const certification =
    userCompany?.userCompanyCertifications[0]
      ?.certificationIntroductionLgpdStage
  if (
    !userCompany.company ||
    !certification ||
    certification === 'not_certification'
  )
    return 'Certificado'
  if (certification === 'already_certificate') return 'Visualizar Certificado'
  if (certification === 'certification_but_not_generated')
    return 'Gerar Certificado'
}

export default buttonNameCertification
