const NonNumeric = /\D/g
const mapToNumeric = (value) => value.replace(NonNumeric, '')

const format = (value) => {
  if (value === null || value === undefined) return ''

  return mapToNumeric(value)
    .slice(0, 11)
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})$/, '$1-$2')
}

export default format
