import PropTypes from 'prop-types'
import { Grid, CircularProgress } from '@material-ui/core'

import { Controller } from 'react-hook-form'
import useFetch from 'hooks/useFetch'

import { Select } from 'components'

import * as service from 'service'
import constants from 'constants/index'

const Seller = ({ control, errors }) => {
  const { response, isLoading } = useFetch(
    service.dponet.sellers.index,
    { kindNotIn: [constants.seller.PARTNER_KIND], perPage: 1000 },
    [],
  )
  const responseSeller = response?.data?.sellers

  const optionsSellers = !isLoading ? responseSeller : []

  return (
    <Grid item xs={12}>
      <Controller
        control={control}
        name="sellerId"
        onChange={([event]) => event.target.value}
        mode="onChange"
        as={
          <Select
            label="Executivo de vendas"
            items={optionsSellers}
            error={!!errors.sellerId}
            helperText={errors?.sellerId?.message}
            startIcon={
              isLoading && <CircularProgress color="primary" size={20} />
            }
          />
        }
      />
    </Grid>
  )
}

Seller.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

export default Seller
