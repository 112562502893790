import { useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box } from '@material-ui/core'

import TableRowUsers from '../TableRowUsers'
import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'
import constants from 'constants/index'
import RegeneratePasswordDialog from '../RegeneratePasswordDialog'

const UsersTable = ({ users, profileType, refresh }) => {
  const isCollaborator =
    profileType === constants.user.PROFILE_TYPE_COLLABORATOR

  const fixedWidth = isCollaborator ? '23.3%' : '35%'

  const [userState, setUser] = useState()
  const [openRegeneratePassword, setOpenRegeneratePassword] = useState(false)

  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhum usuário encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="10%">ID</TableCell>
              <TableCell width={fixedWidth} align="left">
                Nome
              </TableCell>
              <TableCell width={fixedWidth} align="left">
                E-mail
              </TableCell>
              {isCollaborator && (
                <TableCell width={fixedWidth} align="left">
                  Perfil
                </TableCell>
              )}
              <TableCell width="15%" align="left">
                Status
              </TableCell>
              <TableCell width="5%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRowUsers
                user={user}
                setUser={setUser}
                setOpenRegeneratePassword={setOpenRegeneratePassword}
                key={user.id}
                isCollaborator={isCollaborator}
                refresh={refresh}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <RegeneratePasswordDialog
        open={openRegeneratePassword}
        setOpen={setOpenRegeneratePassword}
        user={userState}
        setUser={setUser}
      />
    </Box>
  )
}

UsersTable.propTypes = {
  users: PropTypes.arrayOf(Object),
  profileType: PropTypes.string,
  refresh: PropTypes.func,
}

UsersTable.defaultProps = {
  refresh: () => {},
}

export default UsersTable
