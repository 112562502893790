import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  chip: {
    marginRight: 6,
    marginBottom: 6,
    fontWeight: 500,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    width: 'fit-content',
    padding: '2px 8px',
    borderRadius: 15,
  },
  complement: {
    color: theme.palette.text.subtitle2,
  },
}))

export default styles
