import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import useSnackbar from 'hooks/useSnackbar'
import PropTypes from 'prop-types'

import { Box, Button } from '@material-ui/core'
import {
  CopyCompanySelection,
  LogoInput,
  SegmentationForm,
  ResponsibleForm,
} from '../../components'
import { MainForm } from './components'
import { ChangeResponsibleDialog } from '../ResponsibleForm/components'

import schema from './schema'
import useStyles from './styles'
import helpers from 'helpers'
import * as service from 'service'
import { routes } from 'Routes'
import constants from 'constants/index'

const SupplierForm = ({
  setLoading,
  editionPermitted,
  company,
  disableCertFields,
  refresh,
}) => {
  const [changeResponsibleDialog, setChangeResponsibleDialog] = useState(false)

  const [logoFile, setLogoFile] = useState(null)
  const [oldResponsibleId, setOldResponsibleId] = useState(
    company?.responsibleUserCompanyId,
  )

  const history = useHistory()
  const classes = useStyles()
  const snackbar = useSnackbar()
  const {
    control,
    handleSubmit,
    errors,
    reset,
    register,
    setValue,
    getValues,
  } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: company?.name || '',
      description: company?.description || '',
      cpf: helpers.formatters.cpf(company?.cpf) || '',
      cnpj: helpers.formatters.cnpj(company?.cnpj) || '',
      siteUrl: helpers.formatters.website(company?.siteUrl) || '',
      email: company?.email || '',
      phone: helpers.formatters.phone(company?.phone) || '',
      companyTemplateId: company?.templateCompany || {
        id: 0,
        name: '',
      },
      statusId: company?.statusId || constants.companies.STATUSES.ONBOARDING,
      groupId: company?.group || { id: 0, name: '' },
      subgroupId: company?.subgroup || { id: 0, name: '' },
      segmentId: company?.segment || { id: 0, name: '' },
      companySizeId: company?.companySize || { id: 0, name: '' },
      street: company?.address?.street || '',
      number: company?.address?.number || '',
      neighborhood: company?.address?.neighborhood || '',
      complement: company?.address?.complement || '',
      postalCode: helpers.formatters.cep(company?.address?.postalCode) || '',
      city: company?.address?.city || '',
      country: company?.address?.country || 'Brasil',
      state: company?.address?.state || '',
      responsibleName: company?.responsableUser?.name || '',
      responsibleEmail: company?.responsableUser?.email || '',
      responsiblePhone: company?.responsableUser?.phone || '',
      responsibleUserId: company?.responsableUser
        ? {
            id: company?.responsableUser?.id,
            name: company?.responsableUser?.name,
          }
        : { id: 0, name: '' },
      economicActivityId: company?.mainEconomicActivity || {
        id: 0,
        name: '',
      },
    },
  })

  const onSubmit = async (data) => {
    let params = helpers.companies.formatSupplierParamsToApi(data)

    try {
      let response = await service.dponet.companies.put({
        company: params,
        companyId: company?.id,
      })

      if (logoFile) {
        await service.dponet.companies.addLogo({
          companyId: response?.data?.company?.id,
          logo: logoFile,
        })
      }

      snackbar.open({
        message: 'Empresa editada com sucesso!',
        variant: 'success',
      })
      setLoading(false)
      history.push(routes.companies.all)
    } catch (error) {
      handleError(error, data)
      setLoading(false)
    }
  }

  const handleError = (error, data) => {
    setLoading(false)
    reset(data)
    snackbar.open({
      message: helpers.formatters.errorMessage(error?.response?.data?.error),
      variant: 'error',
    })
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.fullWidth}>
        <CopyCompanySelection
          errors={errors}
          control={control}
          reset={reset}
          getValues={getValues}
          setLoading={setLoading}
          disableCertFields={disableCertFields}
        />
        <Box mt={4}>
          <LogoInput
            logo={company?.logo?.url}
            setLogoFile={setLogoFile}
            editionPermitted={editionPermitted}
          />
        </Box>
        <Box mt={4}>
          <MainForm
            control={control}
            errors={errors}
            company={company}
            editionPermitted={editionPermitted}
          />
        </Box>
        <Box mt={4}>
          <ResponsibleForm
            control={control}
            errors={errors}
            disableCertFields={disableCertFields}
            setChangeResponsibleDialog={setChangeResponsibleDialog}
            setLoading={setLoading}
            register={register}
            company={company}
            editionPermitted={false}
          />
        </Box>
        <Box mt={4}>
          <SegmentationForm
            control={control}
            errors={errors}
            setValue={setValue}
            group={company?.group}
            subgroup={company?.subgroup || company?.segment?.subgroup}
            setLoading={setLoading}
            editionPermitted={editionPermitted}
          />
        </Box>
        {editionPermitted && (
          <Box mt={3} display="flex" justifyContent="flex-end">
            <Button type="submit" variant="contained" color="primary">
              {company ? 'Salvar' : 'Salvar e criar empresa'}
            </Button>
          </Box>
        )}
      </form>
      {company && editionPermitted && (
        <>
          {oldResponsibleId && (
            <ChangeResponsibleDialog
              open={changeResponsibleDialog}
              setOpen={setChangeResponsibleDialog}
              companyId={company?.id}
              oldResponsibleId={oldResponsibleId}
              setOldResponsibleId={setOldResponsibleId}
              setValue={setValue}
              refresh={refresh}
            />
          )}
        </>
      )}
    </>
  )
}

SupplierForm.propTypes = {
  setLoading: PropTypes.func,
  editionPermitted: PropTypes.bool,
  company: PropTypes.object,
}

SupplierForm.defaultProps = {
  setLoading: () => {},
}

export default SupplierForm
