import PropTypes from 'prop-types'
import { isBoolean } from 'lodash'

import { useEffect, useState } from 'react'

import { useDataProcess, useSnackbar, useSelectCountry } from 'hooks'

import {
  Controller,
  FormContext,
  useFieldArray,
  useForm,
} from 'react-hook-form'

import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'

import {
  Label,
  LoadingBox,
  Select as SelectComponent,
  SelectCountry,
} from 'components'

import SharedSoftwareForm from '../../../SharedSoftwareForm'

import helpers from 'helpers'
import * as service from 'service'

import styles from './styles'
const useStyles = makeStyles(styles)

const Form = ({ onSubmit, onEvent, disabled, ...props }) => {
  const [isLoading, setLoading] = useState(false)
  const [dataTreatment, setDataTreatment] = useState(props?.dataTreatment)
  const snackbar = useSnackbar()
  const { shareProcesses, internalAllocationModes } = useDataProcess()
  const classes = useStyles()

  const fields_replaced = (field) => (isBoolean(field) ? field.toString() : '')

  const formMethods = useForm({
    defaultValues: {
      shareProcessId: dataTreatment?.shareProcess?.id || '',
      shareTypeId: dataTreatment?.shareType?.id || '',
      description: dataTreatment?.description || '',
      controllMechanism: dataTreatment?.controllMechanism || '',
      dataTreatmentId: dataTreatment?.id || '',
      sharedInAnotherSoftware: fields_replaced(
        dataTreatment?.sharedInAnotherSoftware,
      ),
      internationalTransferDataTreatment: fields_replaced(
        dataTreatment?.haveInternationalTransfer,
      ),
    },
  })

  const {
    selectedCountries,
    setSelectedCountries,
    manySelectedCountries,
    setManySelectedCountries,
    sortNewNamesToObject,
  } = useSelectCountry()

  const {
    handleSubmit,
    errors,
    watch,
    control,
    setValue,
    getValues,
    setError,
  } = formMethods

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sharedSoftwares',
    keyName: 'key',
  })

  const setInternationalTransfersCountries = (fields) => {
    let newObject = Object.assign({}, manySelectedCountries)

    for (let index in fields) {
      const sharedSoftware = fields[index]
      newObject[`sharedSoftwares-${index}`] =
        sharedSoftware.internationalTransfers
    }

    setManySelectedCountries(newObject)
  }

  const verifyInternationalTransfer = (data) => {
    if (
      data?.internationalTransferDataTreatment === 'true' &&
      selectedCountries <= 0
    ) {
      setError(
        'internationalTransferDataTreatment',
        'required',
        'Escolha no mínimo um país caso a resposta seja "Sim".',
      )
      return false
    }

    return true
  }

  useEffect(() => {
    if (getValues('internationalTransferDataTreatment') === 'false') {
      setSelectedCountries([])
    }

    // eslint-disable-next-line
  }, [getValues('internationalTransferDataTreatment')])

  useEffect(() => {
    if (getValues('sharedInAnotherSoftware') === 'true') {
      if (!dataTreatment?.sharedSoftwares?.length && !fields.length) {
        return append({ id: '', name: '', softwareAllocationType: '' })
      }

      if (!fields.length) {
        setInternationalTransfersCountries(fields)
        return append(dataTreatment?.sharedSoftwares)
      }
    }

    if (getValues('sharedInAnotherSoftware') === 'false') {
      setManySelectedCountries({})
      remove()
    }

    // eslint-disable-next-line
  }, [dataTreatment, getValues('sharedInAnotherSoftware')])

  const addSharedSoftware = () => {
    append({ id: '', name: '', softwareAllocationType: '' })
  }

  const handleRemoveCountrySharedSoftwares = async (sharedSoftware, index) => {
    let newManySelectedCountries = manySelectedCountries
    delete newManySelectedCountries[`sharedSoftwares-${index}`]

    newManySelectedCountries = sortNewNamesToObject(newManySelectedCountries)
    setManySelectedCountries(newManySelectedCountries)

    for (let index in sharedSoftware?.internationalTransfers) {
      const internationalTransfer =
        sharedSoftware?.internationalTransfers[index]

      await service.dponet.sharedSoftwares.internationalTransferDestroy({
        dataProcessId: dataTreatment?.dataProcessId,
        dataTreatmentId: dataTreatment?.id,
        sharedSoftwareId: sharedSoftware?.id,
        internationalTransferId: internationalTransfer?.id,
      })
    }
  }

  const removeSharedSoftware = async (index, sharedSoftware) => {
    setLoading(true)

    if (!!sharedSoftware?.id) {
      try {
        await handleRemoveCountrySharedSoftwares(sharedSoftware, index)

        await service.dponet.sharedSoftwares.destroy({
          dataProcessId: dataTreatment?.dataProcessId,
          dataTreatmentId: dataTreatment?.id,
          sharedSoftwareId: sharedSoftware.id,
        })

        remove(index)

        if (fields.length === 1) {
          await service.dponet.dataTreatments.put({
            dataProcessId: dataTreatment?.dataProcessId,
            dataTreatmentId: dataTreatment?.id,
            dataTreatment: { sharedInAnotherSoftware: false },
          })
        }

        let response = await service.dponet.dataTreatments.get({
          dataProcessId: dataTreatment?.dataProcessId,
          dataTreatmentId: dataTreatment?.id,
        })

        setDataTreatment(response?.data?.dataTreatment)
        setLoading(false)
        snackbar.open({
          message: 'Meio de compartilhamento removido com sucesso!',
          variant: 'success',
        })
        setValue(
          'sharedInAnotherSoftware',
          response?.data?.dataTreatment?.sharedInAnotherSoftware?.toString() ||
            'false',
        )
      } catch (error) {
        setLoading(false)
        snackbar.open({
          message: helpers.formatters.errorMessage(
            error?.response?.data?.error,
          ),
          variant: 'error',
        })
      }
    } else {
      remove(index)

      let newManySelectedCountries = manySelectedCountries
      delete newManySelectedCountries[`sharedSoftwares-${index}`]

      newManySelectedCountries = sortNewNamesToObject(newManySelectedCountries)
      setManySelectedCountries(newManySelectedCountries)

      if (fields.length === 1) {
        setValue('sharedInAnotherSoftware', 'false')
      }

      setLoading(false)
      snackbar.open({
        message: 'Meio de compartilhamento removido com sucesso!',
        variant: 'success',
      })
    }
  }

  const handleRemoveCountry = async (country) => {
    const internationalTransferCreated =
      dataTreatment?.internationalTransfers?.find(
        (internationalTransfer) =>
          internationalTransfer.country === country.country,
      )

    if (
      dataTreatment?.id &&
      dataTreatment?.dataProcessId &&
      internationalTransferCreated
    ) {
      await service.dponet.dataTreatments.internationalTransferDestroy({
        dataProcessId: dataTreatment?.dataProcessId,
        dataTreatmentId: dataTreatment?.id,
        internationalTransferId: internationalTransferCreated.id,
      })
    }
  }

  const submitWithValidations = (data) => {
    const match = verifyInternationalTransfer(data)

    if (match) onSubmit(data)
  }

  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <FormContext {...formMethods}>
          <form
            onSubmit={handleSubmit(submitWithValidations)}
            id="data-treatment-form"
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Controller
                  as={<></>}
                  name="dataTreatmentId"
                  control={control}
                />
                <Controller
                  as={
                    <SelectComponent
                      items={shareProcesses}
                      label="Com quem o dado é compartilhado?"
                      anotherOption
                      disabled={disabled}
                      error={!!errors.shareProcessId}
                      helperText={
                        errors?.shareProcessId && 'Preencha este campo'
                      }
                    />
                  }
                  control={control}
                  rules={{ required: true }}
                  name="shareProcessId"
                  mode="onBlur"
                />

                {watch('shareProcessId') === 'another' && (
                  <Box mr={2} mt={1} width="100%">
                    <Controller
                      as={
                        <TextField
                          label="Outro: com quem o dado é compartilhado?"
                          color="primary"
                          variant="outlined"
                          fullWidth
                          disabled={disabled}
                          error={!!errors.anotherShareProcess}
                          helperText={
                            errors?.anotherShareProcess && 'Preencha este campo'
                          }
                        />
                      }
                      control={control}
                      rules={{ required: true }}
                      name="anotherShareProcess"
                      mode="onBlur"
                    />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      multiline
                      rows={3}
                      label="Por quê o dado é compartilhado?"
                      color="primary"
                      variant="outlined"
                      disabled={disabled}
                      error={!!errors.description}
                      helperText={errors?.description && 'Preencha este campo'}
                      fullWidth
                    />
                  }
                  control={control}
                  rules={{ required: true }}
                  name="description"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      multiline
                      rows={3}
                      label="Como o dado é compartilhado?"
                      color="primary"
                      variant="outlined"
                      disabled={disabled}
                      error={!!errors.controllMechanism}
                      helperText={
                        errors.controllMechanism && 'Preencha este campo'
                      }
                      fullWidth
                    />
                  }
                  control={control}
                  rules={{ required: true }}
                  name="controllMechanism"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <SelectComponent
                      items={internalAllocationModes}
                      label="Tipo de compartilhamento"
                      error={!!errors.shareTypeId}
                      disabled={disabled}
                      fullWidth
                      helperText={errors?.shareTypeId && 'Preencha este campo'}
                    />
                  }
                  control={control}
                  rules={{ required: true }}
                  name="shareTypeId"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Label
                  title="Este compartilhamento é uma transferência internacional?"
                  description='Caso a resposta for "Sim" selecione os países que é feito o compartilhamento.'
                  item
                >
                  <Controller
                    as={
                      <TextField
                        select
                        color="primary"
                        variant="outlined"
                        disabled={disabled}
                        error={!!errors.internationalTransferDataTreatment}
                        helperText={
                          errors?.internationalTransferDataTreatment?.message
                        }
                        fullWidth
                      >
                        <MenuItem value="false">
                          <Typography>Não</Typography>
                        </MenuItem>
                        <MenuItem value="true">
                          <Typography>Sim</Typography>
                        </MenuItem>
                      </TextField>
                    }
                    control={control}
                    name="internationalTransferDataTreatment"
                    mode="onBlur"
                  />
                </Label>
              </Grid>
              {watch('internationalTransferDataTreatment') === 'true' && (
                <Grid item xs={12}>
                  <Box
                    className={classes.internationalTransferField}
                    p={2}
                    pb={3}
                  >
                    <SelectCountry
                      withTable
                      disabled={disabled}
                      label="Qual o país destinatário?"
                      handleRemoveCountry={handleRemoveCountry}
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      label="Para realizar este compartilhamento, você utiliza alguma plataforma, software ou aplicativo?"
                      select
                      color="primary"
                      variant="outlined"
                      disabled={disabled}
                      error={!!errors.sharedInAnotherSoftware}
                      helperText={
                        errors?.sharedInAnotherSoftware && 'Preencha este campo'
                      }
                      fullWidth
                    >
                      <MenuItem value="false">
                        <Typography>Não</Typography>
                      </MenuItem>
                      <MenuItem value="true">
                        <Typography>Sim</Typography>
                      </MenuItem>
                    </TextField>
                  }
                  control={control}
                  name="sharedInAnotherSoftware"
                  mode="onBlur"
                />
              </Grid>
              {watch('sharedInAnotherSoftware') === 'true' && (
                <Grid item xs={12}>
                  <Box mt={3} mr={2}>
                    <Divider />
                    {fields?.map((item, index) => (
                      <SharedSoftwareForm
                        sharedSoftware={item}
                        key={item.key}
                        index={index}
                        disabled={disabled}
                        removeSharedSoftware={removeSharedSoftware}
                      />
                    ))}
                    {!disabled && (
                      <Box display="flex" justifyContent="flex-end" mt={2}>
                        <Button variant="outlined" onClick={addSharedSoftware}>
                          Adicionar Meio de Compartilhamento
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Grid>
              )}
            </Grid>
          </form>
        </FormContext>
      )}
    </>
  )
}

Form.propTypes = {
  onSubmit: PropTypes.func,
  onEvent: PropTypes.func,
  disabled: PropTypes.bool,
}

Form.defaultProps = {
  onSubmit: () => {},
  onEvent: () => {},
  disabled: false,
}

export default Form
