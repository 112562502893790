import statusIdValue from './statusIdValue'
import filterObject from './filterObject'
import filterObjects from './filterObjects'
import documentValue from './documentValue'
import sourceDescriptionText from './sourceDescriptionText'

const dataProcess = {
  statusIdValue,
  filterObject,
  filterObjects,
  documentValue,
  sourceDescriptionText,
}

export default dataProcess
