import helpers from 'helpers'
import constants from 'constants/index'

const formatTemplateParamsToApi = (params) => {
  if (!params) return {}

  let formattedParams = {
    name: params.name,
    description: params.description,
    statusId: params.statusId,
    kind: constants.companies.KINDS.TEMPLATE,
    groupId: helpers.formatters.getIdFromObject(params.groupId),
    subgroupId: helpers.formatters.getIdFromObject(params.subgroupId),
    segmentId: helpers.formatters.getIdFromObject(params.segmentId),
    companySizeId: helpers.formatters.getIdFromObject(params.companySizeId),
    companyTemplateId: helpers.formatters.getIdFromObject(
      params.companyTemplateId,
    ),
    economicActivities: [
      {
        mainActivity: true,
        economicActivityId: helpers.formatters.getIdFromObject(
          params.economicActivityId,
        ),
      },
    ],
  }

  return formattedParams
}

export default formatTemplateParamsToApi
