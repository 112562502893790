import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  chipFinalized: {
    backgroundColor: theme.palette.greenPantone,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipInitiated: {
    backgroundColor: theme.palette.azure,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipWaitingPayment: {
    backgroundColor: theme.palette.yellowOrange,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipPaymentFailed: {
    backgroundColor: theme.palette.darkRed,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipDemonstration: {
    backgroundColor: theme.palette.rawSienna,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipTrial: {
    backgroundColor: colors.pink[900],
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipTrialCanceled: {
    backgroundColor: theme.palette.black,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  chipAwaitingResponse: {
    backgroundColor: theme.palette.russianViolet,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
}))

export default styles
