import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  awaiting: {
    backgroundColor: theme.palette.yellowOrange,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  generating: {
    backgroundColor: theme.palette.azure,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  awaitingPaymentOrder: {
    backgroundColor: theme.palette.info,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  finalized: {
    backgroundColor: theme.palette.greenPantone,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  failed: {
    backgroundColor: theme.palette.darkRed,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
  canceled: {
    backgroundColor: theme.palette.spanishGray,
    borderRadius: '4px',
    color: theme.palette.white,
    fontWeight: 700,
    height: '21px',
  },
}))

export default styles
