import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Typography,
  Grid,
  FormControlLabel,
  Switch,
} from '@material-ui/core'

import { BaseDialog } from 'components'

import { Controller, useForm } from 'react-hook-form'
import { useSnackbar } from 'hooks'

import helpers from 'helpers'
import constants from 'constants/index'
import * as service from 'service'

const ConfirmationCancel = ({
  open,
  setOpen,
  refresh,
  setLoading,
  paymentOrder,
}) => {
  const snackbar = useSnackbar()

  const { control, handleSubmit } = useForm({
    defaultValues: {
      cancelNfe: false,
    },
  })

  const onSubmit = async (data) => {
    setLoading(true)

    try {
      await service.dponet.paymentOrders.cancel({
        paymentOrderId: paymentOrder?.id,
        paymentOrder: data,
      })

      snackbar.open({
        message: 'Solicitação de cancelamento realizada com sucesso!',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Erro ao solicitar o cancelamento, tente novamente mais tarde!',
        ),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      setOpen(false)
    }
  }

  return (
    <BaseDialog
      open={open}
      title="Deseja realmente cancelar a cobrança?"
      actionButtonText="Confirmar"
      closeButtonText="Cancelar"
      setOpen={setOpen}
      formRef="id-cancel-payment-order"
    >
      <Box>
        <Box mt={1} mb={3}>
          <Typography variant="body2">
            {constants.paymentOrder.CANCEL_MESSAGE[paymentOrder?.gateway]}
          </Typography>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)} id="id-cancel-payment-order">
          <Grid container spacing={2}>
            <Grid item xs="12">
              <FormControlLabel
                label="Cancelar NFe"
                control={
                  <Controller
                    as={<Switch color="primary" />}
                    name="cancelNfe"
                    control={control}
                    onChange={([, value]) => value}
                  />
                }
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </BaseDialog>
  )
}

ConfirmationCancel.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
  setLoading: PropTypes.func,
  paymentOrder: PropTypes.object,
}

export default ConfirmationCancel
