import { useState, createRef } from 'react'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import { Typography, Box, Button, TextField } from '@material-ui/core'

import {
  Select as SelectComponent,
  Label,
  ConfirmationDialog,
} from 'components'
import DialogFormAnotherSource from './DialogFormAnotherSource'
import CollapseDataSource from './components/CollapseDataSource'

import constants from 'constants/index'
import helpers from 'helpers'

const ContextDataProcessSource = ({
  control,
  errors,
  classes,
  setValue,
  disabled,
  getValues,
  allSources,
  setAllSources,
  isEdit,
  watchSourceDescription,
}) => {
  const ref = createRef(null)
  const [dialogConfirm, setDialogConfirm] = useState(false)
  const [dialogAnotherSourceForm, setDialogAnotherSourceForm] = useState(false)
  const [anotherSource, setAnotherSource] = useState(false)
  const [index, setIndex] = useState(0)

  const handleConfirmedAnotherSource = () => {
    setValue(
      constants.dataProcess.SOURCE_DESCRIPTION_FIELD,
      constants.dataProcess.ANOTHER_SOURCE,
    )
    setAnotherSource(true)
    setDialogConfirm(false)
  }

  const sourceLabelOptions =
    helpers.dataProcesses.selectSourceOptions(anotherSource)

  const handleOpenedOtherSource = () => {
    if (disabled) {
      return
    }

    setDialogConfirm(true)
  }

  const handleOpenedOtherSourceAdd = (index) => {
    setDialogAnotherSourceForm(true)
    setIndex(index)
  }

  const handleSourceOptionsSelected = () => {
    const values = getValues()
    var sourceDescription = values.sourceDescription
    const sourceDescriptionAnother = values.sourceDescriptionAnother

    setValue('sourceDescription', '')

    if (!sourceDescription) {
      return
    }

    if (sourceDescription === constants.dataProcess.ANOTHER_SOURCE) {
      if (!sourceDescriptionAnother) {
        return
      }

      sourceDescription = sourceDescriptionAnother
    }

    if (isEdit) {
      sourceDescription =
        helpers.dataProcesses.convertSourceDescriptionToText(sourceDescription)
    }

    const existentObject = allSources.find((source) => {
      const arrayOf = [sourceDescription, source.sourceDescription]
      if (helpers.functions.isArrayOf(arrayOf, 'string')) {
        return (
          source.sourceDescription.toLowerCase() ===
          sourceDescription.toLowerCase()
        )
      }
      return source.sourceDescription === sourceDescription
    })

    if (existentObject) {
      return
    }

    const defaultObject = [
      ...allSources,
      {
        sourceDescription,
        dataProcessSources: [],
      },
    ]

    setAllSources(defaultObject)
    setValue('dataProcessDataSources', defaultObject, { shouldValidate: true })
  }

  const handleSourceDataSelected = (index, anotherEntity) => {
    const values = getValues()
    const sourceDescription = allSources[index]?.sourceDescription
    const currentSources = allSources[index]?.dataProcessSources || []

    const entity =
      anotherEntity ||
      values[`${constants.dataProcess.DATA_PROCESS_SOURCES_FIELD}-${index}`]

    if (!entity || isEmpty(Object.keys(entity))) {
      return
    }

    const existentSource = currentSources.find((source) => {
      const arrayOf = [entity.entityName, source.entityName]
      if (helpers.functions.isArrayOf(arrayOf, 'string')) {
        return (
          source.entityId === entity.entityId ||
          source.entityName.toLowerCase() === entity.entityName.toLowerCase()
        )
      }

      return (
        source.entityId === entity.entityId ||
        source.entityName === entity.entityName
      )
    })

    if (existentSource) {
      return
    }

    currentSources.push(entity)

    const newSources = allSources.filter(
      (source) => source.sourceDescription !== sourceDescription,
    )

    const dataSources = helpers.functions.insertArrayAt(newSources, index, {
      sourceDescription,
      dataProcessSources: currentSources,
    })

    setAllSources(dataSources)
    setValue('dataProcessDataSources', dataSources)
  }

  return (
    <>
      <Label
        title="De onde vêm os dados (origem)"
        description="Descreva qual é a origem dos dados"
        item
        xs={12}
      >
        <>
          <Box display="flex" justifyContent="space-evenly" alignItems="center">
            <Box width="100%" mr={2}>
              <Controller
                as={<></>}
                control={control}
                name="dataProcessDataSources"
                mode="onChange"
              />
              <Controller
                as={
                  <SelectComponent
                    disabled={disabled}
                    items={sourceLabelOptions}
                    error={
                      !!errors.sourceDescription ||
                      !!errors.dataProcessDataSources
                    }
                    helperText={
                      errors?.sourceDescription?.message ||
                      errors?.dataProcessDataSources?.message
                    }
                  />
                }
                control={control}
                name={constants.dataProcess.SOURCE_DESCRIPTION_FIELD}
                mode="onChange"
              />
              {watchSourceDescription ===
                constants.dataProcess.ANOTHER_SOURCE && (
                <Box mt={1}>
                  <Controller
                    as={
                      <TextField
                        label="Descreva a outra origem dos dados"
                        type="text"
                        variant="outlined"
                        error={!!errors.sourceDescriptionAnother}
                        helperText={errors?.sourceDescriptionAnother?.message}
                        fullWidth
                      />
                    }
                    control={control}
                    name={`${constants.dataProcess.SOURCE_DESCRIPTION_FIELD}Another`}
                    mode="onChange"
                  />
                </Box>
              )}
            </Box>
            <Button
              onClick={handleSourceOptionsSelected}
              color="primary"
              variant="contained"
              disabled={disabled}
            >
              Adicionar
            </Button>
          </Box>
          {!anotherSource && (
            <Typography variant="caption" color="secondary">
              Não encontrou a opção?{' '}
              <span
                onClick={handleOpenedOtherSource}
                className={classes.otherSource}
              >
                Clique aqui
              </span>{' '}
              para adicionar
            </Typography>
          )}
        </>
      </Label>
      {allSources.map((dataSource, index) => (
        <Box width="100%" key={`${index}-${dataSource.sourceDescription}`}>
          <CollapseDataSource
            dataSource={dataSource}
            index={index}
            sourceLabelOptions={sourceLabelOptions}
            allSources={allSources}
            errors={errors}
            control={control}
            setAllSources={setAllSources}
            setValue={setValue}
            handleOpenedOtherSourceAdd={handleOpenedOtherSourceAdd}
            handleSourceDataSelected={handleSourceDataSelected}
            isEdit={isEdit && dataSource.id !== undefined}
            lastOne={allSources.length === 1}
            ref={ref}
          />
        </Box>
      ))}
      <ConfirmationDialog
        message="Você tem certeza de que a origem de dados que você quer cadastrar não
          se encaixa nas opções da lista? Tenha certeza, pois a criação
          desnecessária de outra opção de origem fora das disponíveis pode
          prejudicar a gestão dos processos."
        buttonText="Eu concordo"
        open={dialogConfirm}
        setOpen={setDialogConfirm}
        actionAcceptButton={handleConfirmedAnotherSource}
      />
      <DialogFormAnotherSource
        open={dialogAnotherSourceForm}
        setOpen={setDialogAnotherSourceForm}
        index={index}
        onSubmit={handleSourceDataSelected}
      />
    </>
  )
}

ContextDataProcessSource.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  allSources: PropTypes.array,
  setAllSources: PropTypes.func,
  isEdit: PropTypes.bool,
  watchSourceDescription: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
}
ContextDataProcessSource.defaultProps = {
  disabled: false,
}

export default ContextDataProcessSource
