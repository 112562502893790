const styles = (theme) => ({
  root: {
    cursor: 'pointer',
    width: 'auto',
    height: '68px',
    display: 'flex',
  },
  statusCard: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
  titleBox: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  titleCard: {
    fontSize: '0.8rem',
    fontWeight: '600',
  },
  valueCard: {
    fontSize: '1rem',
    fontWeight: '600',
  },
  selectedCard: {
    backgroundColor: theme.palette.primary.light,
    border: `2px solid ${theme.palette.azure}`,
  },
  viewMore: {
    padding: '0px 8px 0px 0px !important',
  },
})

export default styles
