import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  otherSource: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
}))

export default styles
