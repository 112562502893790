import { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, CircularProgress, IconButton } from '@material-ui/core'

import { Play as PlayIcon, Pause as PauseIcon } from 'react-feather'
import { useSnackbar } from 'hooks'

import constants from 'constants/index'
import helpers from 'helpers'
import * as service from 'service'

import theme from 'theme'

const Avatar = ({ changeDataProcessStatus, dataProcessId, status }) => {
  const [loading, setLoading] = useState(false)
  const { WAITING_REVIEW_STATUS, REVIEWING_STATUS } = constants.dataProcess
  const snackbar = useSnackbar()
  const isWaitingReview = status === WAITING_REVIEW_STATUS
  const isReviewing = status === REVIEWING_STATUS

  const handlePlayPause = async () => {
    try {
      setLoading(true)
      const newStatus = isWaitingReview
        ? REVIEWING_STATUS
        : WAITING_REVIEW_STATUS
      await service.dponet.dataProcesses.changeStatus({
        dataProcessId,
        statusId: newStatus,
      })
      changeDataProcessStatus(newStatus)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  if (!isWaitingReview && !isReviewing) {
    return <></>
  }

  if (loading) {
    return (
      <Box px={1.5}>
        <CircularProgress size={30} />
      </Box>
    )
  }

  return (
    <IconButton onClick={handlePlayPause}>
      {isWaitingReview ? (
        <PlayIcon size={30} color={theme.colors.dataProcess.borders.primary} />
      ) : (
        <PauseIcon size={30} color={theme.colors.dataProcess.borders.primary} />
      )}
    </IconButton>
  )
}

Avatar.propTypes = {
  changeDataProcessStatus: PropTypes.func,
  dataProcessId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  status: PropTypes.number,
}

Avatar.defaultProps = {
  changeDataProcessStatus: () => {},
}

export default Avatar
