import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  Typography,
  makeStyles,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import PropTypes from 'prop-types'
import helpers from 'helpers'
import constants from 'constants/index'
import styles from './styles'

import useRmc from 'hooks/useRmc'

import { ListItemCustom } from './components'

const useStyles = makeStyles(styles)

const ActionContent = ({ showHeader }) => {
  const classes = useStyles()

  const { data: rmc, isEditableByFinality } = useRmc()

  const unnecessaryCollectedFields =
    helpers.nonComplianceReports.filterUnnecessaryCollectedFields(rmc)

  const unnecessaryDataTreatments =
    helpers.nonComplianceReports.filterUnnecessaryDataTreatments(rmc)

  const unnecessaryLifeCycles =
    helpers.nonComplianceReports.filterUnnecessaryLifeCycles(rmc)

  const viewMessageSecurityMeasure = () => {
    const necessaryDatas = rmc?.unnecessaryDatas?.filter(
      (item) => item.status === constants.unnecessaryData.STATUS.UNNECESSARY,
    )
    if (
      helpers.nonComplianceReports.finalityCheck.fragilityAndNecessary(rmc) &&
      helpers.nonComplianceReports.checkHighOrSevereFragility(
        rmc?.dataProcessFragility,
      ) &&
      !necessaryDatas.length > 0
    ) {
      return true
    }
    return false
  }

  return (
    <Card>
      {showHeader && <CardHeader title="Conteúdo da ação" />}
      <Divider />
      <CardContent className={classes.cardContent}>
        {!!rmc && (
          <Box mb={isEditableByFinality ? 0 : 2}>
            <Box>
              {showHeader && (
                <Typography gutterBottom>
                  <b>
                    {isEditableByFinality
                      ? 'Ação - Reavaliação de necessidade e proporcionalidade'
                      : 'Resultado da reavaliação de necessidade e proporcionalidade'}
                  </b>
                </Typography>
              )}
              <Typography gutterBottom>
                {isEditableByFinality
                  ? 'Escolha eliminar ou não eliminar o item indicado como desnecessário e/ou desproporcional clicando sobre a opção escolhida.'
                  : 'Todos os itens indicados como desnecessários/desproporcionais durante o mapeamento foram reavaliados. O resultado da reavaliação foi o seguinte:'}
              </Typography>
            </Box>
            {unnecessaryCollectedFields.length > 0 && (
              <Box mt={2}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleActionContents}
                >
                  Dados tratados
                </Typography>
                <List className={classes.root}>
                  {unnecessaryCollectedFields.map((unnecessaryData) => (
                    <ListItemCustom
                      classes={classes}
                      key={unnecessaryData.dataId}
                      unnecessaryData={unnecessaryData}
                      dataType={constants.unnecessaryData.COLLECTED_FIELD_TYPE}
                      label={unnecessaryData.dataName}
                    />
                  ))}
                </List>
              </Box>
            )}
            {unnecessaryDataTreatments.length > 0 && (
              <Box mt={2}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleActionContents}
                >
                  Compartilhamento
                </Typography>

                <List className={classes.root}>
                  {unnecessaryDataTreatments.map((unnecessaryData) => (
                    <ListItemCustom
                      classes={classes}
                      key={unnecessaryData.dataId}
                      unnecessaryData={unnecessaryData}
                      dataType={constants.unnecessaryData.DATA_TREATMENT_TYPE}
                      label={unnecessaryData.dataName}
                    />
                  ))}
                </List>
              </Box>
            )}
            {unnecessaryLifeCycles.length > 0 && (
              <Box my={2}>
                <Typography
                  variant="subtitle2"
                  className={classes.titleActionContents}
                >
                  Forma de proteção
                </Typography>

                <List className={classes.root}>
                  {unnecessaryLifeCycles.map((unnecessaryData) => (
                    <ListItemCustom
                      classes={classes}
                      key={unnecessaryData.dataId}
                      dataType={constants.unnecessaryData.LIFE_CYCLES_TYPE}
                      label={unnecessaryData.dataName}
                      unnecessaryData={unnecessaryData}
                    />
                  ))}
                </List>
              </Box>
            )}
            {showHeader && viewMessageSecurityMeasure() && (
              <Box mb={2}>
                <Alert variant="filled" severity="info">
                  <Typography>
                    Após a reavaliação de necessidade e proporcionalidade, o
                    risco calculado para o processo de origem é{' '}
                    {helpers.dataProcesses.fragilityLabel(
                      rmc?.entity?.dataProcess?.adoptedFragilityId,
                      true,
                    )}
                    . Assim, devem ser vinculadas medidas de segurança
                    específicas para a atividade de tratamento de dados
                    pessoais.
                  </Typography>
                </Alert>
              </Box>
            )}
          </Box>
        )}
      </CardContent>
    </Card>
  )
}

ActionContent.propTypes = {
  showHeader: PropTypes.bool,
}

ActionContent.defaultProps = {
  showHeader: true,
}

export default ActionContent
