import helpers from 'helpers'
import constants from 'constants/index'

const billingDayText = (paymentContract) => {
  const initial_text =
    paymentContract?.typeBillingDay ===
    constants.paymentOrder.TYPE_BILLING_DAY_DYNAMIC
      ? `${paymentContract?.billingDayDynamic} dias após a emissão. `
      : `No dia ${paymentContract?.billingDay} de cada mês. `

  return `${initial_text} próximo vencimento: ${helpers.formatters.date(
    paymentContract?.nextBillingDate,
  )}`
}

export default billingDayText
