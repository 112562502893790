import { BasePermissionsForm } from '../index'

import { Box, Typography } from '@material-ui/core'

import constants from 'constants/index'
import PropTypes from 'prop-types'

const AdminsForm = ({
  register,
  permissions,
  handleChange,
  activeProfile,
  isEdit,
}) => {
  const collaboratorProfileLists =
    constants.permissionsLists.collaboratorProfileLists

  return (
    <>
      <Box mt={2} mb={3}>
        <Typography variant="h5">Administradores</Typography>
      </Box>
      <BasePermissionsForm
        title="Processos"
        permissionsList={collaboratorProfileLists.dataProcessList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Empresas"
        permissionsList={collaboratorProfileLists.companyList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Fornecedores"
        permissionsList={collaboratorProfileLists.companySupplierList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Departamentos"
        permissionsList={collaboratorProfileLists.departmentList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Usuários"
        permissionsList={collaboratorProfileLists.userList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Perfis de Usuários"
        permissionsList={collaboratorProfileLists.clientProfileList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Colaboradores"
        permissionsList={collaboratorProfileLists.collaboratorList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Perfis de Colaboradores"
        permissionsList={collaboratorProfileLists.collaboratorProfileList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Incidentes"
        permissionsList={collaboratorProfileLists.incidentList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Exportar Informações"
        permissionsList={collaboratorProfileLists.exportInfoList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Exportar RIPD"
        permissionsList={collaboratorProfileLists.exportRipdList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Políticas de Privacidade"
        permissionsList={collaboratorProfileLists.privacyPolicyList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Questionários por Empresa"
        permissionsList={collaboratorProfileLists.questionnaireByCompanyList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />

      <BasePermissionsForm
        title="Relatórios"
        permissionsList={collaboratorProfileLists.reportList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
      />
      <BasePermissionsForm
        title="Atualizações"
        permissionsList={collaboratorProfileLists.lastUpdatesList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
      />
      <BasePermissionsForm
        title="Atualizações"
        permissionsList={collaboratorProfileLists.lastUpdatesList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
      />
      <BasePermissionsForm
        title="Modelos de Documento"
        permissionsList={collaboratorProfileLists.documentTemplateList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
      />
      <BasePermissionsForm
        title="FAQs e Documentações"
        permissionsList={collaboratorProfileLists.faqsDocumentationList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
      />
    </>
  )
}

AdminsForm.propTypes = {
  register: PropTypes.func,
  permissions: PropTypes.object,
  handleChange: PropTypes.func,
  activeProfile: PropTypes.bool,
  isEdit: PropTypes.bool,
}

export default AdminsForm
