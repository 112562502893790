import permissions from '../../permissions'

const ANPD_CONTACT_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.ANPD_CONTACTS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.CREATE,
    tag: permissions.ANPD_CONTACTS.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.EDIT,
    tag: permissions.ANPD_CONTACTS.EDIT,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.DESTROY,
    tag: permissions.ANPD_CONTACTS.DESTROY,
  },
]

export default ANPD_CONTACT_LIST
