import helpers from 'helpers'
import constants from 'constants/index'

const formatSupplierParamsToApi = (params) => {
  if (!params) return {}

  let formattedParams = {
    name: params.name,
    description: params.description,
    cnpj: helpers.formatters.onlyNumbersString(params.cnpj),
    cpf: helpers.formatters.onlyNumbersString(params.cpf),
    siteUrl: params.siteUrl || null,
    statusId: params.statusId,
    phone: helpers.formatters.onlyNumbersString(params.phone),
    email: params.email,
    kind: constants.companies.KINDS.SUPPLIER,
    groupId: helpers.formatters.getIdFromObject(params.groupId),
    subgroupId: helpers.formatters.getIdFromObject(params.subgroupId),
    segmentId: helpers.formatters.getIdFromObject(params.segmentId),
    companySizeId: helpers.formatters.getIdFromObject(params.companySizeId),
  }

  return formattedParams
}

export default formatSupplierParamsToApi
