import { Page, Container, ContentHeader } from 'components'
import { DocumentationLinksForm } from './components'

const DocumentationLinksNew = () => {
  return (
    <Page title="Novo link de ajuda">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Novo"
          menu="Links de ajuda"
          subtitle="Links de ajuda"
        />
        <DocumentationLinksForm />
      </Container>
    </Page>
  )
}
export default DocumentationLinksNew
