import api from 'service/api'

const dponetAPI = api.create('dponet')

const countryList = async () => {
  return await dponetAPI.get('/countries.json')
}

const internationalTransfers = {
  countryList,
}

export default internationalTransfers
