import constants from 'constants/index'
import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ dataProcessId = '', ...params }) => {
  return await dponetAPI.get(`/data_processes/${dataProcessId}`, { params })
}

const getWithLegalFrameworks = async ({ ...params }) => {
  return await dponetAPI.get(`/data_processes/index_with_legal_frameworks`, {
    params,
  })
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('/data_processes', data)
}

const put = async ({ dataProcessId = '', ...data }) => {
  return await dponetAPI.put(`/data_processes/${dataProcessId}`, data)
}

const getRelationOptions = async ({ ...params }) => {
  return await dponetAPI.get('/data_processes/relations_options', { params })
}

const changeStatus = async ({ dataProcessId, statusId, ...data }) => {
  return await dponetAPI.put(
    `/data_processes/${dataProcessId}/change_status/${statusId}`,
    data,
  )
}

const reopen = async ({ dataProcessId }, data) => {
  return await dponetAPI.post(`/data_processes/${dataProcessId}/reopen`, data)
}

const logs = async ({ dataProcessId }) => {
  return await dponetAPI.get(`/data_processes/${dataProcessId}/logs`)
}

const handleRecommendation = async ({ ...params }) => {
  return await dponetAPI.post('/data_processes/handle_recommendation', params)
}

const softDelete = async ({ dataProcessId, ...params }) => {
  return await dponetAPI.delete(
    `/data_processes/${dataProcessId}/soft_delete`,
    { params },
  )
}

const suggested = async ({ ...params }) => {
  return await dponetAPI.get('/data_processes/suggestions', { params })
}

const discarded = async ({ ...params }) => {
  return await dponetAPI.get('/data_processes/suggestions', {
    params: {
      suggestedDataProcessesStatus: constants.dataProcess.SUGGESTED_REFUSE,
      ...params,
    },
  })
}

const acceptSuggestion = async ({ dataProcessId, ...params }) => {
  return await dponetAPI.post(
    `/data_processes/${dataProcessId}/suggestions/accept`,
    params,
  )
}

const refuseSuggestion = async ({ dataProcessId, ...params }) => {
  return await dponetAPI.post(
    `/data_processes/${dataProcessId}/suggestions/refuse`,
    { params },
  )
}

const getProcessStats = async ({ ...params }) => {
  return await dponetAPI.get('/dashboard/process_stats', { params })
}

const destroyDataProcessDataSource = async ({
  dataProcessId,
  dataProcessDataSourceId,
  ...params
}) => {
  return await dponetAPI.delete(
    `/data_processes/${dataProcessId}/data_process_data_sources/${dataProcessDataSourceId}`,
    { params },
  )
}

const copyProcesses = async ({ ...data }) => {
  return await dponetAPI.post('/data_processes/copy_processes', data)
}

const dataProcesses = {
  get,
  getWithLegalFrameworks,
  create,
  put,
  getRelationOptions,
  changeStatus,
  logs,
  reopen,
  handleRecommendation,
  softDelete,
  suggested,
  discarded,
  acceptSuggestion,
  refuseSuggestion,
  getProcessStats,
  destroyDataProcessDataSource,
  copyProcesses,
}

export default dataProcesses
