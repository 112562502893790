import { Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import PropTypes from 'react-jss/lib/propTypes'

const LoadingTextComponent = ({ loading, children }) => {
  if (loading) {
    return (
      <Box
        height="24px"
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Skeleton variant="rect" width={30} />
      </Box>
    )
  }
  return children
}

LoadingTextComponent.prototype = {
  loading: PropTypes.bool,
  children: PropTypes.node,
}

LoadingTextComponent.defaultProps = {
  loading: false,
}

export default LoadingTextComponent
