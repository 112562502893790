import { useState } from 'react'

import DocumentContext from 'contexts/DocumentContext'

import * as service from 'service'

const DocumentsProvider = ({ children, documents }) => {
  const [data, setData] = useState(documents)
  const [loading, setLoading] = useState(false)

  const loadData = async (dataProcessId) => {
    setLoading(true)

    let response = await service.dponet.dataProcesses.get({ dataProcessId })

    setData(response?.data?.dataProcess?.documents)

    setLoading(false)
  }

  return (
    <DocumentContext.Provider
      value={{
        data,
        setData,
        loading,
        setLoading,
        loadData,
      }}
    >
      {children}
    </DocumentContext.Provider>
  )
}

export default DocumentsProvider
