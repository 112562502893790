import helpers from 'helpers'

const documentFormatter = (document) => {
  const clearedDocument = helpers.formatters.onlyNumbersString(document) || ''
  if (clearedDocument.length > 11) {
    return helpers.formatters.cnpj(clearedDocument)
  }

  return helpers.formatters.cpf(clearedDocument)
}

export default documentFormatter
