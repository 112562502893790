import * as yup from 'yup'

export const schema = yup.object().shape({
  company: yup.object().required().nullable(),
  name: yup.string().required(),
  document: yup.string().required(),
  email: yup.string().required(),
})

export default schema
