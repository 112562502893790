const getTextsSteps = () => {
  return [
    {
      title: 'Informações gerais',
      description:
        'Preencha os campos de informações gerais do tratamento de dados',
      step: 'Informações gerais',
    },
    {
      title: 'Dados tratados',
      description: 'Preencha os dados da atividade de tratamento',
      step: 'Dados tratados',
    },
    {
      title: 'Salvaguarda e ciclo de vida',
      description:
        'Preencha os campos de salvaguarda e ciclo de vida do tratamento de dados.',
      step: 'Salvaguarda e ciclo de vida',
    },
    {
      title: 'Dados compartilhados',
      description: 'Preencha os campos de compartilhamento de dados.',
      step: 'Dados compartilhados',
    },
    {
      title: 'Agentes de tratamento',
      description: 'Preencha os campos de agentes de tratamento.',
      step: 'Agentes de tratamento',
    },
    {
      title: 'Necessidade e proporcionalidade',
      description:
        'Indique possíveis alterações quantos aos dados tratados, forma de proteção e compartilhamento, sob o ponto de vista da necessidade e proporcionalidade.',
      step: 'Necessidade e proporcionalidade',
    },
    {
      title: 'Informação documentada',
      description:
        'Anexe o modelo/template do documento referente à atividade de tratamento. Não anexe documentos com dados pessoais.',
      step: 'Informação documentada',
    },
    {
      title: 'Revisão',
      description:
        'Revise todos os campos preenchidos para confirmar que as informações correspondem à realidade do tratamento de dados.',
      step: 'Revisão',
    },
  ]
}

export default getTextsSteps
