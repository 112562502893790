import { Grid } from '@material-ui/core'
import { Label } from 'components'
import { fill } from 'lodash'

const View = ({ creditCard }) => {
  const maskedCard = creditCard?.maskedCard.split('')

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <Label
          title="Número do cartão"
          description={fill(maskedCard, '*', 3, maskedCard.length - 8).join('')}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Label title="Data de expiração" description={creditCard?.expiration} />
      </Grid>
      <Grid item xs={12} md={4}>
        <Label title="Bandeira" description={creditCard?.brand} />
      </Grid>
    </Grid>
  )
}

export default View
