import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ ...params }) => {
  return await dponetAPI.get('/economic_activities', { params })
}

const show = async ({ activityId = '' }) => {
  return await dponetAPI.get(`/economic_activities/${activityId}`)
}

const create = async (data) => {
  return await dponetAPI.post('/economic_activities', data)
}

const edit = async ({ activityId = '', ...data }) => {
  return await dponetAPI.put(`/economic_activities/${activityId}`, data)
}

const destroy = async ({ activityId = '' }) => {
  return await dponetAPI.delete(`/economic_activities/${activityId}`)
}

const changeStatus = async ({ activityId = '' }) => {
  return await dponetAPI.put(`/economic_activities/${activityId}/change_status`)
}

const economicActivities = {
  get,
  create,
  edit,
  show,
  destroy,
  changeStatus,
}

export default economicActivities
