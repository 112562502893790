import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query'

import constants from 'constants/index'

const useDataProcessesCache = () => {
  const queryClient = useQueryClient()

  const useList = (params) => {
    const query = constants.queryKeys.dataProcesses.listCache(params)

    return useQuery(query)
  }

  const useListLegalFramework = (params) => {
    const query =
      constants.queryKeys.dataProcesses.listLegalFrameworksCache(params)

    return useQuery(query)
  }

  const useView = (id) => {
    const query = constants.queryKeys.dataProcesses.viewCache(id)

    return useQuery(query)
  }

  const useProcessesStats = (params) => {
    const query = constants.queryKeys.dataProcesses.processesStatsCache(params)

    return useQuery(query)
  }

  const useUpdateCache = (id) => {
    const queries = constants.queryKeys.dataProcesses.updateCache(id)

    queries.map((query) => queryClient.invalidateQueries(query))
  }

  const useOptions = (id, options) => {
    const queries = constants.queryKeys.dataProcesses.optionsCache(id, options)

    return useQueries({
      queries,
    })
  }

  return {
    useList,
    useListLegalFramework,
    useView,
    useUpdateCache,
    useProcessesStats,
    useOptions,
  }
}

export default useDataProcessesCache
