import { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import PropTypes from 'prop-types'

import { Permitted } from 'components'
import UserChangeStatusDialog from '../UserChangeStatusDialog'
import constants from 'constants/index'

const ChangeStatusButton = ({ user, isCollaborator, refresh }) => {
  const permissionToInactiveUser = isCollaborator
    ? constants.permissions.COLLABORATORS.CHANGE_STATUS
    : constants.permissions.USERS.CHANGE_STATUS

  const [open, setOpen] = useState(false)

  return (
    <Permitted tag={permissionToInactiveUser}>
      <Box mr={1}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setOpen(!open)}
        >
          {user?.active ? 'Inativar' : 'Reativar'}
        </Button>

        <UserChangeStatusDialog
          user={user}
          isCollaborator={isCollaborator}
          open={open}
          setOpen={setOpen}
          refresh={refresh}
        />
      </Box>
    </Permitted>
  )
}

ChangeStatusButton.propTypes = {
  user: PropTypes.object,
  isCollaborator: PropTypes.bool,
  refresh: PropTypes.func,
}

ChangeStatusButton.defaultProps = {
  isCollaborator: false,
  refresh: () => {},
}

export default ChangeStatusButton
