import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Backdrop, CircularProgress } from '@material-ui/core'

import useStyles from './styles'

const LoadingFeedback = ({ open }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (open) {
      setLoading(true)
    } else {
      setLoading(false)
    }

    //eslint-disable-next-line
  }, [open])

  return (
    <Backdrop open={loading} className={classes.backdrop}>
      <CircularProgress color="primary" />
    </Backdrop>
  )
}

LoadingFeedback.propTypes = {
  open: PropTypes.bool.isRequired,
}

LoadingFeedback.defaultProps = {
  open: false,
}

export default LoadingFeedback
