import { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, Hidden, Typography } from '@material-ui/core'
import { Card } from 'components'

import { DialogOutsourcedDpo } from './components'

const InviteAdvisor = ({
  refresh,
  company,
  invite,
  isEdit,
  advisorData,
  setAdvisorData,
  isDpoInvite = false,
}) => {
  const [openDialog, setOpenDialog] = useState(false)

  const inviteTypeText = isDpoInvite ? 'DPO responsável' : 'Observador'

  const handleChangeDeleteOrOpenDialog = () => {
    if (advisorData) {
      setAdvisorData(null)
    } else {
      setOpenDialog(true)
    }
  }

  const inviteText = () => {
    const statusesMessages = {
      PENDING: (
        <>
          está com o convite pendente para se tornar <b>{inviteTypeText}</b>.
        </>
      ),
      REFUSED: (
        <>
          recusou o convite para se tornar <b>{inviteTypeText}</b>.
        </>
      ),
      CANCELED: 'está com o vinculo cancelado.',
      ACTIVE: (
        <>
          está registrado como o <b>{inviteTypeText}</b> dessa empresa.
        </>
      ),
    }
    if (!invite && isEdit)
      return (
        <>
          Essa empresa não possui um <b>{inviteTypeText}</b> registrado
        </>
      )
    else if (!isEdit && !advisorData) {
      return (
        <>
          Essa empresa não possui um <b>{inviteTypeText}</b> registrado
        </>
      )
    } else if (!isEdit && advisorData) {
      return (
        <>
          {advisorData?.email} será adicionado como&nbsp;
          <b>{inviteTypeText}</b> nessa empresa
        </>
      )
    }
    return (
      <>
        <strong>{invite?.name || invite?.email}</strong>&nbsp;
        {statusesMessages[invite.status]}
      </>
    )
  }

  return (
    <Box mt={4}>
      <Card title={inviteTypeText} paddingBottom={0}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body1" color="textPrimary">
            {inviteText()}
          </Typography>
          {isEdit ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenDialog(true)}
            >
              {invite ? 'VISUALIZAR' : 'ADICIONAR'}&nbsp;
              <Hidden xsDown>{inviteTypeText}</Hidden>
            </Button>
          ) : (
            <Button
              variant="contained"
              color={advisorData ? 'secondary' : 'primary'}
              onClick={handleChangeDeleteOrOpenDialog}
            >
              {advisorData ? 'EXCLUIR' : 'ADICIONAR'}&nbsp;
              <Hidden xsDown>{inviteTypeText}</Hidden>
            </Button>
          )}
        </Box>
      </Card>
      <DialogOutsourcedDpo
        refresh={refresh}
        open={openDialog}
        setOpen={setOpenDialog}
        invite={invite}
        company={company}
        isDpoInvite={isDpoInvite}
        inviteTypeText={inviteTypeText}
        isEdit={isEdit}
        setAdvisorData={setAdvisorData}
      />
    </Box>
  )
}

InviteAdvisor.propTypes = {
  company: PropTypes.object,
  setOpenOutsourced: PropTypes.func,
  openOutsourced: PropTypes.bool,
  refresh: PropTypes.func,
  isEdit: PropTypes.bool,
  setOutsourcedDpoData: PropTypes.func,
}

InviteAdvisor.defaultProps = {
  setOpenOutsourced: () => {},
  refresh: () => {},
  setOutsourcedDpoData: () => {},
}

export default InviteAdvisor
