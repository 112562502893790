import { useState } from 'react'
import PropTypes from 'prop-types'
import { Chip, Button } from '@material-ui/core'

import { MenuButton } from 'components'
import { TableRow, TableCell } from 'components/Table'
import { RemoveLinkedDialog } from './components'

import constants from 'constants/index'
import useStyles from './styles'
import helpers from 'helpers'

const PreRegistrationsTableRow = ({
  preRegistration,
  preRegistrationPrincipalId,
  paymentContractId,
  refresh,
}) => {
  const classes = useStyles()
  const [openModal, setOpenModal] = useState(false)

  const isPrincipal = preRegistrationPrincipalId === preRegistration?.id
  const handleOpenModal = () => setOpenModal(true)

  return (
    <>
      <TableRow key={preRegistration?.id}>
        <TableCell>{preRegistration?.id}</TableCell>
        <TableCell>{preRegistration?.name}</TableCell>
        <TableCell>
          {preRegistration?.kind === constants.companies.KINDS.PERSONAL
            ? helpers.formatters.cpf(preRegistration?.document)
            : helpers.formatters.cnpj(preRegistration?.document)}
        </TableCell>
        <TableCell>
          {
            constants.preRegistration.TYPE_CLIENT_LABEL[
              preRegistration?.typeClient
            ]
          }
        </TableCell>
        <TableCell align="left">{isPrincipal ? 'Sim' : 'Não'}</TableCell>
        <TableCell align="left">
          R${' '}
          {helpers.formatters.currencyInput(
            preRegistration?.priceRecommendation,
          )}
        </TableCell>
        <TableCell disableTooltip>
          <Chip
            size="small"
            className={helpers.preRegistrations.chipStatusColor(
              preRegistration?.status,
              classes,
            )}
            label={helpers.preRegistrations.chipStatusLabel(
              preRegistration?.status,
            )}
          />
        </TableCell>
        <TableCell disableTooltip align="right">
          <MenuButton>
            <Button disabled={isPrincipal} onClick={handleOpenModal}>
              Excluir vinculo
            </Button>
          </MenuButton>
        </TableCell>
      </TableRow>
      {openModal && (
        <RemoveLinkedDialog
          open={openModal}
          paymentContractId={paymentContractId}
          preRegistrationId={preRegistration?.id}
          refresh={refresh}
          setOpen={setOpenModal}
        />
      )}
    </>
  )
}

PreRegistrationsTableRow.propTypes = {
  preRegistration: PropTypes.object,
  preRegistrationPrincipal: PropTypes.bool,
  paymentContractId: PropTypes.number,
  refresh: PropTypes.func,
}

export default PreRegistrationsTableRow
