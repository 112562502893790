import PropTypes from 'prop-types'
import { TableRow, TableCell } from 'components/Table'
import { MenuButton, Permitted } from 'components'
import RemoveSubgroup from '../RemoveSubgroup'
import { Chip, Button } from '@material-ui/core'
import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from './styles'
import clsx from 'clsx'

import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { routes } from 'Routes'

const SubgroupTableRow = ({ subgroup, refresh }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <TableRow key={subgroup?.id}>
        <TableCell>{subgroup?.id}</TableCell>
        <TableCell>{subgroup?.name}</TableCell>
        <TableCell>{subgroup?.group?.name}</TableCell>
        <TableCell disableTooltip>
          <Chip
            className={clsx(classes.root, {
              [classes.active]: subgroup.active,
              [classes.inactive]: !subgroup.active,
            })}
            align="center"
            size="small"
            label={subgroup.active ? 'Ativo' : 'Inativo'}
          />
        </TableCell>
        <TableCell align="center">
          {subgroup?.inactivateAt
            ? helpers.formatters.date(subgroup?.inactivateAt)
            : ' - '}
        </TableCell>
        <TableCell disableTooltip align="right">
          <Permitted
            someTags={[
              constants.permissions.SUBGROUPS.EDIT,
              constants.permissions.SUBGROUPS.DESTROY,
            ]}
          >
            <MenuButton>
              <Permitted tag={constants.permissions.SUBGROUPS.EDIT}>
                <Button
                  fullWidth
                  size="small"
                  onClick={() =>
                    history.push(
                      reverse(routes.subgroups.edit, {
                        subgroupId: subgroup?.id,
                      }),
                    )
                  }
                >
                  Editar
                </Button>
              </Permitted>
              <Permitted tag={constants.permissions.SUBGROUPS.DESTROY}>
                <RemoveSubgroup subgroupId={subgroup?.id} refresh={refresh} />
              </Permitted>
            </MenuButton>
          </Permitted>
        </TableCell>
      </TableRow>
    </>
  )
}

SubgroupTableRow.propTypes = {
  subgroup: PropTypes.object,
  refresh: PropTypes.func,
}

SubgroupTableRow.defaultProps = {
  refresh: () => {},
}

export default SubgroupTableRow
