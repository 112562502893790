import {
  Avatar,
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography,
} from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'
import { Link as RouterLink } from 'react-router-dom'

import Page from 'components/Page'
import ForgotPasswordForm from './components/Form'

import logo from 'images/dponet-logo-blue.png'
import useStyles from './styles'
import { routes } from 'Routes'

const ForgotPassword = () => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Esqueceu a senha?">
      <Container maxWidth="md">
        <Card className={classes.card}>
          <CardContent className={classes.content}>
            <Avatar className={classes.icon}>
              <LockIcon fontSize="large" />
            </Avatar>
            <Box justifyContent="start" display="flex">
              <img
                alt="Logo"
                src={logo}
                width="100%"
                className={classes.logo}
              />
            </Box>
            <Typography variant="h3" color="textPrimary">
              Olá!
            </Typography>
            <Typography variant="subtitle1">
              Digite seu e-mail para recuperar a senha
            </Typography>
            <Box mt={3}>
              <ForgotPasswordForm />
            </Box>
            <Box my={2}>
              <Divider />
            </Box>
            <Link
              component={RouterLink}
              to={routes.root}
              variant="body2"
              color="textSecondary"
            >
              Voltar
            </Link>
          </CardContent>
        </Card>
      </Container>
    </Page>
  )
}

export default ForgotPassword
