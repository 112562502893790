import { Grid } from '@material-ui/core'

import PropTypes from 'prop-types'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { IconButton } from 'components'

import helpers from 'helpers'

import { getBaseURL } from 'service/env'

import { VisibilityOutlined as VisibilityOutlinedIcon } from '@material-ui/icons'

const DocumentsTable = ({ documents }) => {
  return (
    <Table emptyMessage="Nenhum documento encontrado" noWrap>
      <TableHead>
        <TableRow>
          <TableCell width="90%">Documentos</TableCell>
          <TableCell align="right">Ações</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {documents.map((document, index) => (
          <TableRow key={index}>
            <TableCell>
              {helpers.functions.documentName(document.url)}
            </TableCell>
            <TableCell align="right" disableTooltip>
              <Grid container item justifyContent="flex-end">
                <IconButton
                  size="small"
                  Icon={VisibilityOutlinedIcon}
                  text="Visualizar"
                  onClick={() =>
                    window.open(
                      `${getBaseURL('dponet')}${document.url}`,
                      '_blank',
                    )
                  }
                />
              </Grid>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

DocumentsTable.propTypes = {
  documents: PropTypes.arrayOf(Object),
}

export default DocumentsTable
