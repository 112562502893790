import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  correct: {
    color: colors.green[500],
  },
  error: {
    color: colors.blueGrey[700],
  },
  errorJustSubmitted: {
    color: colors.red[900],
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}))

export default styles
