import { Permitted } from 'components'

import { Link, Typography, makeStyles } from '@material-ui/core'

import { Alert } from '@material-ui/lab'

// import { useHistory } from 'react-router-dom'

// import { routes } from 'Routes'

// import { reverse } from 'named-urls'
import PropTypes from 'prop-types'

import constants from 'constants/index'

import styles from './styles'

import helpers from 'helpers'

const useStyles = makeStyles(styles)

const ContinuedAlert = ({ nonComplianceReport, continued, isRisk }) => {
  // const history = useHistory()

  const classes = useStyles()

  const toNonComplianceReport = () => {
    // To implement
    // history.push(
    //   reverse(routes.nonComplianceReport.edit, {
    //     nonComplianceReportId: nonComplianceReport.id,
    //   }),
    // )
  }

  const textFinalityCheck = () => {
    if (
      helpers.nonComplianceReports.finalityCheck.fragility(nonComplianceReport)
    )
      return ' para visualizar o RMC de risco.'
    if (
      helpers.nonComplianceReports.finalityCheck.necessary(nonComplianceReport)
    )
      return ' para visualizar o RMC de necessidade e proporcionalidade.'
    if (
      helpers.nonComplianceReports.finalityCheck.fragilityAndNecessary(
        nonComplianceReport,
      )
    )
      return ' para visualizar o RMC de risco e necessidade e proporcionalidade.'
  }

  return (
    <Permitted tag={constants.permissions.NON_COMPLIANCE_REPORT.EDIT}>
      <Alert variant="filled" severity="warning">
        <Typography>
          {helpers.nonComplianceReports.getContinuedText(
            helpers.nonComplianceReports.finalityCheck.fragility(
              nonComplianceReport,
            ),
            continued,
            isRisk,
          )}
        </Typography>
        <Typography>
          <Link
            href="#"
            className={classes.linkInfo}
            onClick={toNonComplianceReport}
          >
            Clique aqui
          </Link>
          {textFinalityCheck()}
        </Typography>
      </Alert>
    </Permitted>
  )
}

ContinuedAlert.propTypes = {
  nonComplianceReportId: PropTypes.number.isRequired,
  nonComplianceReportFinality: PropTypes.string.isRequired,
  continued: PropTypes.bool,
}

ContinuedAlert.defaultProps = {
  continued: false,
}

export default ContinuedAlert
