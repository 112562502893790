import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
} from '@material-ui/core'

import { QuestionForm } from './components'

import constants from 'constants/index'

const QuestionDialog = ({ question, mode, open, handleClose, refresh }) => {
  const editTexts = {
    dialogTitle: 'Editar questão',
  }

  const createTexts = {
    dialogTitle: 'Criar questão',
  }

  const showTexts = {
    dialogTitle: 'Visualizar questão',
  }

  const texts = () => {
    if (mode === constants.mode.EDIT) return editTexts
    if (mode === constants.mode.CREATE) return createTexts
    if (mode === constants.mode.SHOW) return showTexts
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle disableTypography>
        <Typography variant="h5">{texts().dialogTitle}</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <QuestionForm
          mode={mode}
          question={question}
          refresh={refresh}
          handleClose={handleClose}
        />
      </DialogContent>
      <DialogActions p={2}>
        <Box pr={2} width="100%" display="flex" justifyContent="flex-end">
          <Button type="button" variant="outlined" onClick={handleClose}>
            Voltar
          </Button>
          {mode !== constants.mode.SHOW && (
            <Box ml={2}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                form="question-form"
              >
                Salvar
              </Button>
            </Box>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  )
}

QuestionDialog.propTypes = {
  question: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
}

export default QuestionDialog
