import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import { Grid, TextField } from '@material-ui/core'

import { Card } from 'components'

import helpers from 'helpers'

const Responsible = ({ errors, control }) => {
  return (
    <Card title="Preencha as informações do responsável da empresa">
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors?.responsibleName}
                helperText={errors?.responsibleName?.message}
                label="Nome do responsável pela empresa"
              />
            }
            control={control}
            name="responsibleName"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors?.responsibleEmail}
                helperText={errors?.responsibleEmail?.message}
                label="E-mail do responsável pela empresa"
              />
            }
            control={control}
            name="responsibleEmail"
            mode="onBlur"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.responsiblePhone}
                helperText={errors?.responsiblePhone?.message}
                label="Telefone do responsável da empresa"
              />
            }
            onChange={([event]) => {
              return helpers.formatters.phone(event.target.value)
            }}
            control={control}
            name="responsiblePhone"
            mode="onChange"
          />
        </Grid>
      </Grid>
    </Card>
  )
}

Responsible.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  disableCertFields: PropTypes.bool,
}

export default Responsible
