import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ companyAuditId = '', ...params }) => {
  return await dponetAPI.get(`/company_audits/${companyAuditId}`, {
    params,
  })
}

const companyAudits = {
  get,
}

export default companyAudits
