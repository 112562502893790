import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Tooltip,
  Typography,
  Paper,
} from '@material-ui/core'

const SupplierDetailsRow = ({ title, subtitle }) => {
  return (
    <Grid item xs={12}>
      <Typography gutterBottom variant="h6">
        {title}
      </Typography>
      <Tooltip title={subtitle || ''} placement="bottom-start">
        <Typography variant="body1" noWrap>
          {subtitle || '-'}
        </Typography>
      </Tooltip>
    </Grid>
  )
}

const SupplierDetails = ({ company }) => {
  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Paper component={Box} minHeight="100%">
        <CardHeader title="Dados básicos" />
        <Divider />
        <CardContent>
          <Grid container spacing={2}>
            <SupplierDetailsRow
              title="Empresa contratante"
              subtitle={company?.companyName}
            />
            <SupplierDetailsRow title="CNPJ/CPF" subtitle={company?.document} />
            <SupplierDetailsRow
              title="Segmento"
              subtitle={company?.segment?.name}
            />
            <SupplierDetailsRow
              title="Tamanho"
              subtitle={company?.size?.name}
            />
            <SupplierDetailsRow
              title="E-mail do responsável"
              subtitle={company?.supplierResponsibleEmail}
            />
          </Grid>
        </CardContent>
      </Paper>
    </Grid>
  )
}

SupplierDetails.propTypes = {
  company: PropTypes.object,
}

SupplierDetailsRow.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default SupplierDetails
