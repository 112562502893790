import Main from './CouponsMain'
import New from './CouponsNew'
import Edit from './CouponEdit'

const Coupons = {
  Main,
  New,
  Edit,
}

export default Coupons
