import constants from 'constants/index'

import * as yup from 'yup'

export const schema = (phoneEmpty, isEasy) =>
  yup.object().shape({
    name: yup.string().required().max(60),
    kind: yup.string(),
    companyName: yup.string().when(['kind'], {
      is: (kind) => kind === constants.companies.KINDS.COMPANY,
      then: yup.string().required().max(60),
    }),
    description: isEasy ? yup.string() : yup.string().required(),
    document: yup.string().required(),
    siteUrl: yup.string().url(),
    municipalRegistration: yup.string(),
    stateRegistration: yup.string(),
    email: yup.string().email().required(),
    phone: yup.string().required(),
    groupId: yup.object().nullable(),
    companySizeId: yup.object().nullable(),
    subgroupId: yup.object().nullable(),
    segmentId: yup.object().nullable(),
    companyTemplateId: yup.object(),
    street: isEasy ? yup.string() : yup.string().required(),
    number: isEasy ? yup.string() : yup.string().required().max(10),
    neighborhood: isEasy ? yup.string() : yup.string().required(),
    postalCode: isEasy ? yup.string() : yup.string().required(),
    city: isEasy ? yup.string() : yup.string().required(),
    country: isEasy ? yup.string() : yup.string().required(),
    state: isEasy ? yup.string() : yup.string().required(),
    complement: yup.string(),
    selectExistentResponsible: yup.boolean(),
    statusId: yup.number().required(),
    blockUpdate: yup.boolean(),
    acceptProcessFragility: yup.boolean(),
    notifyDataProcessChanges: yup.boolean(),
    responsibleName: yup.string().when('selectExistentResponsible', {
      is: false,
      then: yup.string().required(),
    }),
    responsibleEmail: yup.string().when('selectExistentResponsible', {
      is: false,
      then: yup.string().email().required(),
    }),
    responsibleUserId: yup.object().when('selectExistentResponsible', {
      is: true,
      then: yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(),
      }),
    }),
    ...(!isEasy && {
      economicActivityId: yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(),
      }),
    }),
    ...(phoneEmpty && {
      responsiblePhone: yup
        .string()
        .required(
          'Preencha este campo! Em caso de edições, preencha no menu de edição de usuários.',
        ),
    }),
  })

export default schema
