import React from 'react'
import { Grid, Paper, Box, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import useStyles from '../DocumentCard/styles'

const DocumentCardSkeleton = () => {
  const classes = useStyles()

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Paper component={Box}>
        <Box p={2}>
          <Box display="flex" alignItems="center">
            <Skeleton variant="circle" width={30} height={30} />
            <Skeleton height={15} width="30%" component={Box} ml={1} />
          </Box>
          <Box>
            <Typography variant="h4" gutterBottom noWrap>
              <Skeleton />
            </Typography>

            <Box>
              <Typography className={classes.textInfo} variant="subtitle2">
                <Skeleton />
              </Typography>
              <Typography className={classes.textInfo} variant="subtitle2">
                <Skeleton />
              </Typography>
              <Typography className={classes.textInfo} variant="subtitle2">
                <Skeleton />
              </Typography>
            </Box>
          </Box>

          <Box pt={2}>
            <Skeleton variant="rectangular" width={60} height={30} />
          </Box>
        </Box>
      </Paper>
    </Grid>
  )
}

export default DocumentCardSkeleton
