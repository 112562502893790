import { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

import { Grid, TextField } from '@material-ui/core'
import { Card, Select, RadioTypeCompany } from 'components'

import helpers from 'helpers'
import constants from 'constants/index'

const MainForm = ({
  control,
  errors,
  watch,
  editionPermitted,
  loadSupplierData,
  setValue,
  isEditChecked,
  setChecked,
  disableCertFields,
  isJuridic,
  company,
}) => {
  const checked = watch('kind')

  useEffect(() => {
    if (checked !== company?.kind) {
      setValue('document', '')
    } else {
      if (checked === constants.companies.KIND_COMPANY) {
        return setValue('document', helpers.formatters.cnpj(company?.document))
      }
      setValue('document', helpers.formatters.cpf(company?.document))
    }
  }, [checked])

  return (
    <Card title="Preencha os dados da empresa">
      <RadioTypeCompany control={control} watch={watch} />
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.name}
                helperText={errors?.name?.message}
                label="Nome fantasia da empresa"
                disabled={!editionPermitted}
                inputProps={{ maxLength: 60 }}
              />
            }
            control={control}
            name="name"
            mode="onBlur"
          />
        </Grid>
        {isJuridic && (
          <>
            <Grid item xs={12} md={6}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    error={!!errors.companyName}
                    helperText={errors?.companyName?.message}
                    label="Razão social da empresa"
                    disabled={!editionPermitted}
                    inputProps={{ maxLength: 60 }}
                  />
                }
                control={control}
                name="companyName"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    error={!!errors.description}
                    helperText={errors?.description?.message}
                    label="Breve descrição da empresa"
                    disabled={!editionPermitted}
                  />
                }
                control={control}
                name="description"
                mode="onBlur"
              />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <Controller
            control={control}
            name="document"
            mode="onChange"
            onChange={([event]) => {
              const document = helpers.formatters.onlyNumbersString(
                event.target.value,
              )

              if (document?.length === 14) {
                loadSupplierData(document, setValue)
              }

              if (isJuridic) {
                return helpers.formatters.cnpjInput(document)
              }

              return helpers.formatters.cpf(document)
            }}
            as={
              <TextField
                fullWidth
                error={!!errors.document}
                helperText={errors?.document?.message}
                label={isJuridic ? 'CNPJ' : 'CPF'}
              />
            }
          />
        </Grid>
        {!isJuridic && (
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  error={!!errors.description}
                  helperText={errors?.description?.message}
                  label="Breve descrição da empresa"
                  disabled={!editionPermitted}
                />
              }
              control={control}
              name="description"
              mode="onBlur"
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.siteUrl}
                helperText={errors?.siteUrl?.message}
                label="URL do site"
                disabled={!editionPermitted}
              />
            }
            onChange={([event]) => {
              return helpers.formatters.website(event.target.value)
            }}
            control={control}
            name="siteUrl"
            mode="onBlur"
          />
        </Grid>
        {isJuridic && (
          <>
            <Grid item xs={12} md={6}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    error={!!errors.stateRegistration}
                    helperText={errors?.stateRegistration?.message}
                    label="Inscrição Estadual"
                    disabled={!editionPermitted}
                  />
                }
                control={control}
                name="stateRegistration"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    error={!!errors.municipalRegistration}
                    helperText={errors?.municipalRegistration?.message}
                    label="Inscrição Municipal"
                    disabled={!editionPermitted}
                  />
                }
                control={control}
                name="municipalRegistration"
                mode="onBlur"
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.email}
                helperText={errors?.email?.message}
                label="E-mail da empresa"
                disabled={!editionPermitted}
              />
            }
            control={control}
            name="email"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.phone}
                helperText={errors?.phone?.message}
                label="Telefone da empresa"
                disabled={!editionPermitted}
              />
            }
            onChange={([event]) => {
              return helpers.formatters.phone(event.target.value)
            }}
            control={control}
            name="phone"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <Select
                items={constants.companies.STATUSES_OBJECT}
                label="Selecione o status da empresa"
                error={!!errors.statusId}
                helperText={errors?.statusId?.message}
                disabled={!editionPermitted || !disableCertFields}
              />
            }
            onChange={([event]) => {
              helpers.companies.blockUpdate(
                event.target.value,
                setChecked,
                isEditChecked,
              )
              return event
            }}
            control={control}
            name="statusId"
            mode="onChange"
          />
        </Grid>
      </Grid>
    </Card>
  )
}

MainForm.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  editionPermitted: PropTypes.bool,
  loadSupplierData: PropTypes.func,
  setValue: PropTypes.func,
  isEditChecked: PropTypes.bool,
  setChecked: PropTypes.func,
}

MainForm.defaultProps = {
  register: () => {},
  loadSupplierData: () => {},
  setValue: () => {},
  setChecked: () => {},
  isEditChecked: false,
}

export default MainForm
