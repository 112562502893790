import moment from 'moment'
import 'moment/locale/pt-br'

moment.locale('pt-br')

const date = (date, format = 'DD/MM/YYYY') => {
  if (!date) {
    return null
  }

  return moment(date).format(format)
}

export default date
