import PropTypes from 'prop-types'
import { Chip } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './styles'
import helpers from 'helpers'

const QuestionStatusChip = ({ status }) => {
  const classes = useStyles()

  return (
    <Chip
      className={clsx(
        classes.root,
        helpers.questions.statusColor(status, classes),
      )}
      align="center"
      size="small"
      label={helpers.questions.statusLabel(status) ?? '-'}
    />
  )
}

QuestionStatusChip.propTypes = {
  status: PropTypes.number.isRequired,
}

export default QuestionStatusChip
