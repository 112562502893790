import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { reverse } from 'named-urls'

import { Box, Button, Chip } from '@material-ui/core'
import { MenuButton } from 'components'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import helpers from 'helpers'
import constants from 'constants/index'

import useStyles from './styles'

const TableRowSupplier = ({ supplier, ...rest }) => {
  const classes = useStyles()
  const history = useHistory()

  const navigateToShow = () => {
    history.push(
      reverse(routes.suppliers.show, {
        companySupplierId: supplier?.id,
      }),
    )
  }

  return (
    <TableRow {...rest}>
      <TableCell>{supplier?.id}</TableCell>
      <TableCell>{supplier?.company?.name}</TableCell>
      <TableCell>{supplier?.supplier?.name || '-'}</TableCell>
      <TableCell>{supplier?.supplier?.segment?.name}</TableCell>
      <TableCell disableTooltip>
        <Chip
          size="small"
          className={clsx(
            classes.chip,
            helpers.companySuppliers.chipStatusColor(supplier?.status, classes),
          )}
          label={constants.companySuppliers.STATUSES_LABELS[supplier?.status]}
        />
      </TableCell>
      <TableCell>
        {supplier?.supplier?.companyCompliance?.toFixed(0) || 0}%
      </TableCell>
      <TableCell disableTooltip align="right">
        <MenuButton>
          <Button fullWidth size="small" onClick={navigateToShow}>
            Visualizar
          </Button>
        </MenuButton>
      </TableCell>
    </TableRow>
  )
}

const SuppliersTable = ({ suppliers }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhuma empresa encontrada" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="10%">ID</TableCell>
              <TableCell width="20%" align="left">
                Empresa contratante
              </TableCell>
              <TableCell width="20%" align="left">
                Fornecedor
              </TableCell>
              <TableCell width="15%" align="left">
                Segmento
              </TableCell>
              <TableCell width="15%" align="left">
                Status do vinculo
              </TableCell>
              <TableCell width="15%" align="left">
                Conformidade(%)
              </TableCell>
              <TableCell width="5%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {suppliers?.map((supplier) => (
              <TableRowSupplier supplier={supplier} key={supplier?.id} hover />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

TableRowSupplier.propTypes = {
  supplier: PropTypes.object,
}

SuppliersTable.propTypes = {
  suppliers: PropTypes.array,
}

export default SuppliersTable
