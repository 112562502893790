import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@material-ui/core'

import { Controller } from 'react-hook-form'

import { Card, Label } from 'components'

const MaturityOption = ({ control, disabled, defaultExpanded, ...rest }) => {
  return (
    <Box {...rest}>
      <Card
        title="Opções de vencimento"
        dropdown
        defaultExpanded={defaultExpanded}
        dropdownChildrenPadding={2}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Label title="Regra de vencimento após a emissão:">
              <Controller
                as={
                  <RadioGroup>
                    <Box>
                      <FormControlLabel
                        disabled={disabled}
                        value="false"
                        control={<Radio color="primary" />}
                        label="Sem regra de vencimento (padrão)"
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        disabled={disabled}
                        value="true"
                        control={<Radio color="primary" />}
                        label="Vencimento no próximo mês após a emissão"
                      />
                    </Box>
                  </RadioGroup>
                }
                control={control}
                name="expirationLastMonth"
                mode="onBlur"
              />
            </Label>
          </Grid>
          <Grid item xs={12}>
            <Label title="Procedimento para casos em que o vencimento ocorra em um fim de semana.">
              <Controller
                as={
                  <RadioGroup>
                    <Box>
                      <FormControlLabel
                        disabled={disabled}
                        value="postpone"
                        control={<Radio color="primary" />}
                        label="Prorrogar para depois do fim de semana (padrão)"
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        disabled={disabled}
                        value="anticipate"
                        control={<Radio color="primary" />}
                        label="Antecipar para antes do fim de semana"
                      />
                    </Box>
                  </RadioGroup>
                }
                control={control}
                name="expirationAction"
                mode="onBlur"
              />
            </Label>
          </Grid>
          <Grid item xs={12}>
            <Label title="Dia de vencimento">
              <Controller
                as={
                  <RadioGroup>
                    <Box>
                      <FormControlLabel
                        disabled={disabled}
                        value="fixed"
                        control={<Radio color="primary" />}
                        label="Dia fixo de Vencimento (padrão)"
                      />
                    </Box>
                    <Box>
                      <FormControlLabel
                        disabled={disabled}
                        value="dynamic"
                        control={<Radio color="primary" />}
                        label="Numero de dias após a emissão"
                      />
                    </Box>
                  </RadioGroup>
                }
                control={control}
                name="typeBillingDay"
                mode="onBlur"
              />
            </Label>
          </Grid>
        </Grid>
      </Card>
    </Box>
  )
}

export default MaturityOption
