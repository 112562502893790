import constants from 'constants/index'
import { isEmpty } from 'lodash'

const { CONTROLLER_TYPE, OPERATOR_TYPE } = constants.dataTreatmentAgent

const agentTypeOfCompanySelf = (agents) => {
  const types = agents?.map((agent) => {
    if (agent.companySelf) {
      return agent.agentType
    }
    return null
  })

  if (isEmpty(types)) return ''
  if (types.includes(CONTROLLER_TYPE)) return CONTROLLER_TYPE
  if (types.includes(OPERATOR_TYPE)) return OPERATOR_TYPE

  return ''
}

export default agentTypeOfCompanySelf
