import { useState, useEffect } from 'react'
import useSnackbar from 'hooks/useSnackbar'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  TextField,
  MenuItem,
} from '@material-ui/core'

import { LoadingButton, LoadingBox } from 'components'

import constants from 'constants/index'
import * as service from 'service'

const UserChangeStatusDialog = ({
  user,
  isCollaborator,
  userCompany,
  open,
  setOpen,
  refresh,
}) => {
  const isUserCompany = !isEmpty(userCompany)

  const [profiles, setProfiles] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingProfiles, setLoadingProfiles] = useState(false)

  const snackbar = useSnackbar()
  const { control, setError, getValues, errors } = useForm({
    defaultValues: {
      profileId: '',
    },
  })

  const userActive = () => {
    if (isUserCompany)
      return userCompany?.status === constants.userCompanies.ACTIVE

    return user.active
  }

  const noticeText = () => {
    if (!user) return

    const vinculedDepartmentNames = user?.vinculedDepartmentNames

    if (!isCollaborator && vinculedDepartmentNames !== '' && userActive()) {
      return `Este usuário está vinculado ao(s) seguinte(s) departamento(s): "${vinculedDepartmentNames}". Ao inativar o usuário, o(s) departamento(s) e os processos não serão excluídos. Certifique-se apenas de manter responsáveis pelo departamento.`
    }

    if (userActive()) {
      return `Você tem certeza que deseja inativar o usuário ${user.name}?`
    }

    return `Você tem certeza que deseja reativar o usuário ${user.name}?`
  }

  const onSubmit = async () => {
    try {
      const didntSelectProfile =
        getValues('profileId') === '' || !getValues('profileId')

      if (!userActive() && didntSelectProfile)
        return setError('profileId', {
          type: {
            notMatch: 'Perfil não pode ficar em branco.',
          },
        })

      setLoading(true)

      if (isUserCompany) {
        await routeToChangeStatus()({
          userCompanyId: userCompany.id,
          profileId: getValues('profileId'),
        })
      } else {
        await routeToChangeStatus()({
          userId: user.id,
          profileId: getValues('profileId'),
        })
      }

      const successMessage = userActive()
        ? 'Usuário inativado com sucesso!'
        : 'Usuário reativado com sucesso!'

      snackbar.open({ message: successMessage, variant: 'success' })

      refresh()
    } catch (error) {
      const errorMessage = userActive()
        ? 'Falha ao inativar usuário!'
        : 'Falha ao reativar usuário!'

      snackbar.open({
        message: error?.response?.data?.error?.message
          ? error?.response?.data?.error?.message
          : errorMessage,
        variant: 'error',
      })
    }

    setOpen(false)
    setLoading(false)
  }

  const routeToChangeStatus = () => {
    if (isUserCompany) {
      return service.dponet.userCompanies.changeStatus
    }

    if (isCollaborator) {
      return service.dponet.userCollaborators.changeStatus
    }

    return service.dponet.user.changeStatus
  }

  const routeToGetProfiles = () => {
    if (isCollaborator)
      return service.dponet.collaboratorProfiles.get({ perPage: 1000 })

    return service.dponet.userProfiles.get({
      perPage: 1000,
      companyId: userCompany?.company?.id,
    })
  }

  useEffect(() => {
    const loadingProfiles = async () => {
      setLoadingProfiles(true)
      const response = await routeToGetProfiles()

      setProfiles(response?.data?.profiles || [])
      setLoadingProfiles(false)
    }

    if ((isUserCompany || isCollaborator) && !userActive() && open)
      loadingProfiles()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="change-user-status"
      fullWidth
    >
      <DialogTitle>Alteração de status do usuário</DialogTitle>
      <Divider />
      <Box>
        <DialogContent>
          <Box pb={1}>
            <Typography>{noticeText()}</Typography>
            {!userActive() && (isUserCompany || isCollaborator) && (
              <Box mt={1}>
                {loadingProfiles ? (
                  <LoadingBox minHeight="100px" />
                ) : (
                  <Controller
                    control={control}
                    name="profileId"
                    as={
                      <TextField
                        label="Perfil"
                        select
                        color="primary"
                        variant="outlined"
                        error={!!errors.profileId}
                        helperText={errors?.profileId?.types?.type?.notMatch}
                        fullWidth
                      >
                        {profiles.map((profile) => (
                          <MenuItem key={profile.id} value={profile.id}>
                            <Typography>{profile.name}</Typography>
                          </MenuItem>
                        ))}
                      </TextField>
                    }
                    mode="onBlur"
                  />
                )}
              </Box>
            )}
          </Box>
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <LoadingButton
          variant="contained"
          type="submit"
          color="primary"
          text="Confirmar"
          loading={loading}
          onClick={onSubmit}
        />
      </DialogActions>
    </Dialog>
  )
}

UserChangeStatusDialog.propTypes = {
  user: PropTypes.object.isRequired,
  isCollaborator: PropTypes.bool.isRequired,
  userCompany: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
}
UserChangeStatusDialog.defaultProps = {
  user: {},
  userCompany: {},
  setOpen: () => {},
  refresh: () => {},
}

export default UserChangeStatusDialog
