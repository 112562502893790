import { useEffect, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { DialogInformation } from 'components'
import { Box, Grid, makeStyles } from '@material-ui/core'

import TableNecPropDataCollected from '../../TableNecPropDataCollected'
import TableNecPropDataTreatments from '../../TableNecPropDataTreatments'
import TableNecPropDataLifeCycle from '../../TableNecPropDataLifeCycle'
import DescriptionPaper from '../../DescriptionPaper'
import { LoadingBox } from 'components'

import { useSnackbar, useDataProcess } from 'hooks'
import { useDataProcessesCache } from 'hooks/caches'

import * as service from 'service'
import styles from '../styles'
import helpers from 'helpers'
const useStyles = makeStyles(styles)

const NecessityProportionality = ({ description }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const formMethods = useForm()
  const {
    data,
    nextStep,
    toNextStep,
    setToNextStep,
    setData,
    reloadOptions,
    reloadDataProcess,
  } = useDataProcess()
  const dataProcessesCache = useDataProcessesCache()

  const [openDialog, setOpenDialog] = useState(false)
  const [dialogMessage, setDialogMessage] = useState('')

  const dataProcessResponse = dataProcessesCache.useView(data?.id)

  const { handleSubmit, errors } = formMethods
  const dataProcess = dataProcessResponse.data?.dataProcess

  const handleOpenDialog = (message) => {
    setDialogMessage(message)
    setOpenDialog(true)
    return true
  }

  const onSubmit = async (data) => {
    try {
      if (
        helpers.necessityAndProportionality.verifyUnnecessaryScopes(
          data,
          handleOpenDialog,
        )
      ) {
        return
      }

      dataProcess.dataCollecteds.map(async (dataCollected) => {
        let dataCollecteds =
          helpers.dataCollected.mountNecessityAndProportionalityParams(
            data,
            dataCollected,
          )

        if (dataCollecteds.collectedFields.length > 0) {
          await service.dponet.dataCollecteds.put({
            dataProcessId: dataProcess.id,
            dataCollectedId: dataCollected.id,
            dataCollecteds,
          })
        }
      })

      dataProcess.dataTreatments.map(async (dataTreat) => {
        let dataTreatment =
          helpers.dataTreatment.mountNecessityAndProportionalityParams(
            data,
            dataTreat.id,
          )

        if (dataTreatment !== {}) {
          await service.dponet.dataTreatments.put({
            dataProcessId: dataProcess.id,
            dataTreatmentId: dataTreat.id,
            dataTreatment,
          })
        }
      })

      dataProcess.lifeCycles.map(async (dataLife) => {
        let dataLifeCycle =
          helpers.dataLifeCycle.mountNecessityAndProportionalityParams(
            data,
            dataLife.id,
          )

        if (dataLifeCycle !== {}) {
          await service.dponet.dataLifeCycles.put({
            dataProcessId: dataProcess.id,
            dataLifeCycleId: dataLife.id,
            lifeCycle: {
              retentionFinality: dataLife.retentionFinality,
              storageLocationId: dataLife.storageLocation.id,
              discardModeId: dataLife.discardMode.id,
              internalAllocationModeId: dataLife.internalAllocationMode.id,
              protectionId: dataLife.protection.id,
              recoveryId: dataLife.recovery.id,
              value: dataLife.value,
              volumetry: dataLife.volumetry,
              ...dataLifeCycle,
            },
          })
        }
      })

      reloadOptions()
      reloadDataProcess()

      nextStep()
      setToNextStep(false)
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
      })
    }
  }

  useEffect(() => {
    if (toNextStep) {
      handleSubmit(onSubmit)()

      if (errors) setToNextStep(false)
    }
    // eslint-disable-next-line
  }, [toNextStep])

  useEffect(() => {
    if (!dataProcessResponse.isFetching && dataProcessResponse.isFetched) {
      if (!!dataProcess) setData(dataProcess)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProcessResponse.isFetching])

  return (
    <>
      <DescriptionPaper description={description} />
      <Grid container className={classes.root}>
        {dataProcessResponse.isFetching ? (
          <LoadingBox />
        ) : (
          <>
            <Box maxHeight={600} width="100%">
              <Box width="100%" mb={3}>
                <FormContext className={classes.fullHeight} {...formMethods}>
                  <PerfectScrollbar>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <TableNecPropDataCollected
                        dataCollecteds={dataProcess?.dataCollecteds}
                      />
                    </form>
                  </PerfectScrollbar>
                </FormContext>
              </Box>
              {dataProcess?.dataTreatments?.length > 0 && (
                <Box width="100%" mb={3}>
                  <FormContext {...formMethods}>
                    <PerfectScrollbar>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <TableNecPropDataTreatments
                          dataTreatments={dataProcess?.dataTreatments}
                        />
                      </form>
                    </PerfectScrollbar>
                  </FormContext>
                </Box>
              )}
              <Box width="100%" mb={3}>
                <FormContext {...formMethods}>
                  <PerfectScrollbar>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <TableNecPropDataLifeCycle
                        dataLifeCycles={dataProcess?.lifeCycles || []}
                      />
                    </form>
                  </PerfectScrollbar>
                </FormContext>
              </Box>
            </Box>
          </>
        )}
      </Grid>
      <DialogInformation
        open={openDialog}
        setOpen={setOpenDialog}
        title="Necessidade e proporcionalidade"
        description={dialogMessage}
      />
    </>
  )
}

NecessityProportionality.propTypes = {
  description: PropTypes.string.isRequired,
}

export default NecessityProportionality
