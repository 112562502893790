import { useState } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, TextField, Grid } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'

import { Card, LoadingBox, BackButton, Select } from 'components'

import useStyles from './styles'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import constants from 'constants/index'
import helpers from 'helpers'
import schema from './schema'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

const PlansForm = ({ plan, isEdit }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors, reset } = useForm({
    validationSchema: schema(),
    defaultValues: {
      name: plan?.name || '',
      code: plan?.code || '',
      platform: plan?.platform || 'easy',
      price: helpers.formatters.currencyInput(plan?.price.toString() || ''),
    },
  })

  const onSubmit = async (data) => {
    try {
      setLoading(true)

      data.price = helpers.formatters.regularizeCurrency(data.price)
      data.kind = 'integration'

      if (isEdit) {
        await service.dponet.plans.edit({ planId: plan.id, plan: data })
      } else {
        await service.dponet.plans.create({ plan: data })
      }

      snackbar.open({
        message: `Plano de integração ${
          isEdit ? 'editado' : 'criado'
        } com sucesso`,
        variant: 'success',
      })
      history.push(routes.integrationPlans.all)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
      reset(data)
      setLoading(false)
    }
  }

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card title="Preencha os dados do segmento e preço">
            <Grid container className={classes.gapGrid}>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      label="Nome do plano"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="name"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      label="Código do plano"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="code"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="platform"
                  mode="onChange"
                  as={
                    <Select
                      label="Plataforma"
                      items={
                        constants.integrationPartner
                          .SELECT_PLATFORM_INTEGRATION_PARTNER ?? []
                      }
                      error={!!errors.platform}
                      helperText={errors?.platform?.message}
                      variant="outlined"
                      fullWidth
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      label="Mensalidade"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.price}
                      helperText={errors?.price?.message}
                      fullWidth
                    />
                  }
                  onChange={([event]) => {
                    return helpers.formatters.currencyInput(event.target.value)
                  }}
                  control={control}
                  name="price"
                  mode="onBlur"
                />
              </Grid>
            </Grid>
          </Card>
          <Box mt={2} display="flex" justifyContent="flex-start">
            <Box pr={2}>
              <BackButton
                onClick={() => history.push(routes.integrationPlans.all)}
              >
                Voltar
              </BackButton>
            </Box>
            <Box>
              <Button color="primary" variant="contained" type="submit">
                Salvar
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </>
  )
}

PlansForm.propTypes = {
  plan: PropTypes.object,
  isEdit: PropTypes.bool,
}

PlansForm.defaultProps = {
  isEdit: false,
}

export default PlansForm
