import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ ...params }) => {
  return await dponetAPI.get('/churns', { params })
}

const show = async ({ churnId }) => {
  return await dponetAPI.get(`/churns/${churnId}`)
}

const create = async ({ paymentContractId }) => {
  return await dponetAPI.post(`/churns`, {
    paymentContractId: paymentContractId,
  })
}

const update = async ({ churnId, params }) => {
  return await dponetAPI.put(`/churns/${churnId}`, params)
}

const meeting = async ({ churnId, ...data }) => {
  return await dponetAPI.post(`/churns/${churnId}/meeting`, data)
}

const changeContract = async ({ churnId, ...params }) => {
  return await dponetAPI.put(`/churns/${churnId}/change_contract`, params)
}

const overduePayment = async ({ churnId, ...params }) => {
  return await dponetAPI.get(`/churns/${churnId}/overdue_payment`, { params })
}

const churns = {
  get,
  show,
  create,
  update,
  meeting,
  changeContract,
  overduePayment,
}

export default churns
