import { useState } from 'react'

import { Button } from '@material-ui/core'
import { ConfirmationDialog, Permitted } from 'components'

import constants from 'constants/index'
import helpers from 'helpers'

import PropTypes from 'prop-types'
import * as service from 'service'

import useSnackbar from 'hooks/useSnackbar'

const ChangeStatus = ({ documentationLink, refresh }) => {
  const [openStatusDialog, setOpenStatusDialog] = useState(false)

  const snackbar = useSnackbar()

  const Message = `Deseja realmente ${
    documentationLink.status ? 'Inativar' : 'Ativar'
  } esse link de ajuda`

  const handleChage = async () => {
    try {
      await service.dponet.documentationLinks.update({
        documentationLinkId: documentationLink?.id,
        documentationLink: {
          status: !documentationLink?.status,
        },
      })

      refresh()

      snackbar.open({
        message: `Link de ajuda ${
          documentationLink.status ? 'Inativado' : 'Ativado'
        } com sucesso.`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          `erro ao ${
            documentationLink.status ? 'Inativar' : 'desativar'
          } o link de ajuda.`,
      })
    }
    setOpenStatusDialog(false)
  }

  return (
    <>
      <Permitted tag={constants.permissions.DOCUMENTATION_LINKS.EDIT}>
        <Button
          fullWidth
          size="small"
          onClick={() => setOpenStatusDialog(true)}
        >
          {documentationLink.status ? 'Inativar' : 'Ativar'}
        </Button>
      </Permitted>

      <ConfirmationDialog
        open={openStatusDialog}
        setOpen={setOpenStatusDialog}
        message={Message}
        cancelButtonText="Cancelar"
        buttonText="Confirmar"
        actionAcceptButton={handleChage}
      />
    </>
  )
}

ChangeStatus.propTypes = {
  documentationLink: PropTypes.object.isRequired,
  refresh: PropTypes.func,
}

ChangeStatus.propTypes = {
  refresh: () => {},
}

export default ChangeStatus
