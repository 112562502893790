import Copy from './DataProcessesCopy'
import Edit from './DataProcessesEdit'
import Main from './DataProcessesMain'
import New from './DataProcessesNew'
import View from './DataProcessesView'

const DataProcesses = {
  Copy,
  Edit,
  Main,
  New,
  View,
}

export default DataProcesses
