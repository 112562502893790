import PropTypes from 'prop-types'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

const DialogLayout = ({
  title,
  confirmTextButton,
  open,
  children,
  setOpen = () => {},
  handleSubmit = () => {},
}) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box>
          <Typography variant="h5">{title}</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box my={1}>
        <DialogContent>{children}</DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          onClick={handleSubmit}
        >
          {confirmTextButton}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogLayout.propTypes = {
  title: PropTypes.string.isRequired,
  confirmTextButton: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.any,
  setOpen: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

DialogLayout.defaultProps = {
  setOpen: () => {},
  handleSubmit: () => {},
}

export default DialogLayout
