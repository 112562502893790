const COLLECTED_FIELD_TYPE = 'collectedField'
const DATA_TREATMENT_TYPE = 'dataTreatment'
const LIFE_CYCLES_TYPE = 'dataLifeCycle'

const CHOOSE_ELIMINATED = 'Eliminated'

const STATUS = {
  NECESSARY: 'Necessary',
  UNNECESSARY: 'Unnecessary',
  ELIMINATED: 'Eliminated',
}

const unnecessaryData = {
  COLLECTED_FIELD_TYPE,
  DATA_TREATMENT_TYPE,
  LIFE_CYCLES_TYPE,
  CHOOSE_ELIMINATED,
  STATUS,
}

export default unnecessaryData
