import constants from 'constants/index'

const nameOfTitularAgeRange = (titularAgeRanges) => {
  return titularAgeRanges?.map((titularAgeRange) => {
    return {
      id: titularAgeRange?.id,
      name: constants.dataCollected.COVER_NAME[titularAgeRange?.id],
    }
  })
}

export default nameOfTitularAgeRange
