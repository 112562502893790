const FragilityColor = (statusId, classes) => {
  switch (statusId) {
    case 1:
      return classes.lowFragility
    case 2:
      return classes.mediumFragility
    case 3:
      return classes.highFragility
    case 4:
      return classes.severeFragility
    default:
      return classes.notCalculated
  }
}

export default FragilityColor
