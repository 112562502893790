import PropTypes from 'prop-types'
import { Stepper, Step, StepLabel } from '@material-ui/core'
import {
  Done as DoneIcon,
  ReportProblem as ReportProblemIcon,
} from '@material-ui/icons'

import { useFetch } from 'hooks'

import { LoadingFeedback, BaseDialog } from 'components'

import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'
import useStyles from './styles'

const PaymentStep = ({ open = false, setOpen = () => {}, paymentOrderId }) => {
  const classes = useStyles()

  const { response, isLoading } = useFetch(
    service.dponet.paymentOrders.steps,
    {
      paymentOrderId,
    },
    [],
  )

  const steppers = isLoading ? [] : response?.data

  const getStep = (step) => helpers.paymentOrders.getStepApi(steppers, step)

  return (
    <BaseDialog
      open={open}
      title="Etapas da cobrança"
      closeButtonText="Fechar"
      setOpen={setOpen}
      dialogSize="md"
    >
      <LoadingFeedback open={isLoading} />
      {!isLoading && (
        <Stepper alternativeLabel className={classes.stepper}>
          {constants.paymentOrder.STEPS_LISTS?.map((stepItem) => (
            <Step
              key={stepItem?.step}
              active={getStep(stepItem?.step)}
              className={
                classes[getStep(stepItem?.step) ? 'success' : 'failure']
              }
            >
              <StepLabel
                StepIconComponent={
                  getStep(stepItem?.step) ? DoneIcon : ReportProblemIcon
                }
              >
                {stepItem?.description}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
    </BaseDialog>
  )
}

PaymentStep.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  paymentOrderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default PaymentStep
