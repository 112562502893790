import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
  Chip,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import { Label } from 'components'

import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from '../../styles'

const BasicInfoCard = ({ preRegistration }) => {
  const classes = useStyles()

  return (
    <Card>
      <CardHeader title="Dados básicos" />
      <Divider />
      <Box className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Label title="Token">
              <Typography>{preRegistration?.token}</Typography>
            </Label>
          </Grid>
          <Grid item xs={12} md={4}>
            <Label title="Tipo de contratante">
              <Typography>
                {helpers.preRegistrations.typeCompany(preRegistration)}
              </Typography>
            </Label>
          </Grid>
          <Grid item xs={12} md={4}>
            <Label title="Categoria do contratante">
              <Typography>
                {
                  constants.preRegistration.TYPE_CLIENT_LABEL[
                    preRegistration?.typeClient
                  ]
                }
              </Typography>
            </Label>
          </Grid>

          <Grid item xs={12} md={4}>
            <Label title="Documento">
              <Typography>
                {helpers.formatters.cnpjAndCpfMask(preRegistration?.document)}
              </Typography>
            </Label>
          </Grid>
          <Grid item xs={12} md={4}>
            <Label title="Razão Social">
              <Typography>{preRegistration?.name}</Typography>
            </Label>
          </Grid>
          <Grid item xs={12} md={4}>
            <Label title="Nome Fantasia">
              <Typography>{preRegistration?.fantasyName || '-'}</Typography>
            </Label>
          </Grid>

          <Grid item xs={12} md={4}>
            <Label title="Telefone">
              <Typography>
                {helpers.formatters.phone(preRegistration?.phone)}
              </Typography>
            </Label>
          </Grid>
          <Grid item xs={12} md={4}>
            <Label title="Email">
              <Typography>{preRegistration?.email}</Typography>
            </Label>
          </Grid>
          <Grid item xs={12} md={4}>
            <Label title="Status">
              <Chip
                size="small"
                className={helpers.preRegistrations.chipStatusColor(
                  preRegistration?.status,
                  classes,
                )}
                label={helpers.preRegistrations.chipStatusLabel(
                  preRegistration?.status,
                )}
              />
            </Label>
          </Grid>
          <Grid item xs={12} md={4}>
            <Label title="Empresa modelo">
              <Typography>{preRegistration?.companyTemplate?.name}</Typography>
            </Label>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

BasicInfoCard.propTypes = {
  preRegistration: PropTypes.object,
}

export default BasicInfoCard
