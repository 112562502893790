import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/styles'

import {
  Box,
  Card,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  Button,
  TablePagination,
  Divider,
} from '@material-ui/core'
import { Plus as PlusIcon } from 'react-feather'

import {
  Container,
  ContentHeader,
  FilterButton,
  LoadingFeedback,
  Page,
} from 'components'
import { IntegrationPartnerFilter, IntegrationPartnerTable } from './components'

import { useFetch, useFilter, usePagination } from 'hooks'

import constants from 'constants/index'
import { routes } from 'Routes'
import * as service from 'service'

const IntegrationPartnerMain = () => {
  const theme = useTheme()
  const history = useHistory()
  const filter = useFilter()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const [tab, setTab] = useState(constants.integrationPartner.ALL_TAB)
  const [refresh, setRefresh] = useState(false)

  const handleChangeTab = (_, value) => {
    setTab(value)
    const filters = filter.filters
    filter.setFilters({ ...filters })
  }

  const paramsFilter = {
    ...filter.filters,
    status: tab,
  }

  const paramsFilterAll = {
    ...filter.filters,
  }

  const params = () => {
    if (tab !== constants.integrationPartner.ALL_TAB) {
      return paramsFilter
    }

    return paramsFilterAll
  }

  const { response: responseIntegrationPartner, isLoading: loadingPartners } =
    useFetch(
      service.dponet.integrationPartners.get,
      {
        ...params(),
        perPage,
        page,
      },
      [page, perPage, filter.filters, refresh],
    )

  const integrationPartners =
    responseIntegrationPartner?.data?.integrationPartners
  const totalCount = responseIntegrationPartner?.data?.meta?.totalCount

  return (
    <Page title="Parceiros">
      <LoadingFeedback open={loadingPartners} />
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Parceiros"
          title="Listagem"
          subtitle="Parceiros e Integrações"
        >
          <Box ml={1}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
        </ContentHeader>
        <Box
          mt={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              {constants.integrationPartner.TABS.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  onClick={(event) => handleChangeTab(event, tab.value)}
                />
              ))}
            </Tabs>
          </Box>
        </Box>
        <Card>
          <Box width="100%" display="flex" alignItems="center">
            <Grid container>
              <Grid item xs={6}>
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  ml={2}
                >
                  <Typography variant="h5">Parceiros</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box p={2} display="flex" justifyContent="flex-end">
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<PlusIcon />}
                    onClick={() => history.push(routes.integrationPartner.new)}
                  >
                    Criar
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <IntegrationPartnerTable
            integrationPartners={integrationPartners ?? []}
            refresh={() => setRefresh(!refresh)}
          />
          <Box px={2} display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              count={totalCount ?? 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Card>
      </Container>
      <IntegrationPartnerFilter filter={filter} />
    </Page>
  )
}

export default IntegrationPartnerMain
