import { Box, Card, CardHeader, Typography, Divider } from '@material-ui/core'

import PropTypes from 'prop-types'

import helpers from 'helpers'

const Cause = ({ rmc }) => {
  if (rmc?.cause) {
    return (
      <Card>
        <CardHeader title="Causa" />
        <Divider />
        <Box m={2}>
          {helpers.formatters
            .multipleParagraphs(rmc?.cause, '\n\n')
            .map((paragraph, index) => (
              <Typography paragraph key={index}>
                {paragraph}
              </Typography>
            ))}
        </Box>
      </Card>
    )
  }
  return <></>
}

Cause.propTypes = {
  rmc: PropTypes.object,
}

export default Cause
