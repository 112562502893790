import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  chip: {
    maxHeight: 20,
    fontWeight: 500,
  },
  gutterSpacing: {
    marginBottom: theme.spacing(1),
  },
}))

export default styles
