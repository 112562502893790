import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core'

import { Controller, useForm } from 'react-hook-form'
import schemaDialogFormAnotherSource from './schema'

const DialogFormAnotherSource = ({ open, setOpen, onSubmit, index }) => {
  const { control, errors, getValues, triggerValidation } = useForm({
    defaultValues: {
      entityName: '',
    },
    validationSchema: schemaDialogFormAnotherSource,
  })

  const handleSubmit = (dataForm) => {
    onSubmit(index, { entityId: dataForm.entityName, ...dataForm })

    setOpen(false)
  }

  const handleValidSubmit = (event) => {
    event.preventDefault()
    triggerValidation().then((valid) => {
      if (valid) {
        handleSubmit(getValues())
      }
    })
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth={false}>
      <DialogTitle>Adicionar local de origem</DialogTitle>
      <Box minWidth="60vw">
        <DialogContent dividers>
          <form>
            <Controller
              name="entityName"
              control={control}
              as={
                <TextField
                  label="Descreva onde são disponibilizados os dados"
                  color="primary"
                  variant="outlined"
                  multiline
                  rows={6}
                  error={!!errors.entityName}
                  helperText={errors?.entityName?.message}
                  fullWidth
                />
              }
              mode="onBlur"
            />
          </form>
        </DialogContent>
      </Box>
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={(event) => handleValidSubmit(event)}
        >
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogFormAnotherSource.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  index: PropTypes.number,
}

DialogFormAnotherSource.defaultProps = {
  setOpen: () => {},
  setData: () => {},
}

export default DialogFormAnotherSource
