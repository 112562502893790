import helpers from 'helpers'
import constants from 'constants/index'
import moment from 'moment'

const isDateString = (value) => {
  return moment(value, moment.ISO_8601, true).isValid()
}

const formattedValue = function (field, value, registerType) {
  if (registerType === 'CompanySupplier' && field === 'status') {
    return helpers.companySuppliers.label(value)
  }

  const statusHelper =
    registerType === 'Company'
      ? (status) => constants.companies.STATUSES_LABELS[status]
      : helpers.dataProcesses.status

  if (value === null) return '-'

  if (isDateString(value)) return moment(value).format('DD/MM/YYYY HH:mm')

  if (typeof value === 'object') return JSON.stringify(value)

  if (typeof value === 'boolean') {
    if (value) {
      return 'Sim'
    } else {
      return 'Não'
    }
  } else {
    switch (field) {
      case 'status_id':
        return statusHelper(parseInt(value))
      case 'fragility_id':
        return helpers.dataProcesses.fragilityLabel(parseInt(value))
      case 'adopted_fragility_id':
        return helpers.dataProcesses.fragilityLabel(parseInt(value))
      case 'suggestion_fragility_id':
        return helpers.dataProcesses.fragilityLabel(parseInt(value))
      case 'chosen_action':
        return helpers.nonComplianceReports.chosenAction(value)
      case 'status':
        return helpers.logs.status(value)
      default:
        return value
    }
  }
}

export default formattedValue
