/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import { Filters } from 'components'

import helpers from 'helpers'

const CompanyAuditFilter = ({ filter }) => {
  const [companies, setCompanies] = useState([])
  const [companyInputValue, setCompanyInputValue] = useState('')
  const [isLoadingCompaniesInput, setIsLoadingCompaniesInput] = useState(false)
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false)

  const { loadCompanies, mountCompanyParams, instructionsText } =
    helpers.companies.typeAndSearch
  const { nameWithDocumentByArrayObject } = helpers.companies.nameWithDocument
  const { filters, drawerOpen } = filter

  const handleTypeCompaniesInput = (e) => {
    setCompanyInputValue(e.target.value)
  }

  const companiesOptionsValues = nameWithDocumentByArrayObject(
    isLoadingCompaniesInput ? [] : companies,
  )

  useEffect(() => {
    const getCompanies = setTimeout(() => {
      const hasCompanyValueToFetch =
        companyInputValue.length < 3 && isEmpty(filters?.companyId)

      if (hasCompanyValueToFetch || !drawerOpen) return

      loadCompanies(
        mountCompanyParams(companyInputValue, filters),
        setIsLoadingCompaniesInput,
        setCompanies,
      )
    }, 1000)

    return () => clearTimeout(getCompanies)
  }, [companyInputValue])

  useEffect(() => {
    if (filters?.companyId) {
      loadCompanies(
        mountCompanyParams(companyInputValue, filters),
        setIsLoadingCompanies,
        setCompanies,
      )
    }
  }, [filters?.companyId])

  useEffect(() => {
    if (!drawerOpen && companyInputValue) setCompanyInputValue('')
  }, [drawerOpen])

  return (
    <Filters filter={filter} isLoading={isLoadingCompanies}>
      <input
        datepickerinput="true"
        label="Início"
        name="startDateGteq"
        data-target="startDateLteq"
        data-min
      />
      <input
        datepickerinput="true"
        label="Fim"
        name="endDateGteq"
        data-target="endDateLteq"
        data-max
      />
      <select
        autocompleteselectinput="true"
        label="Empresa"
        name="companyId"
        loading={isLoadingCompaniesInput}
        onChangeTypeSearch={handleTypeCompaniesInput}
        optionvalues={companiesOptionsValues}
        noOptionsText={instructionsText(companyInputValue)}
      />
    </Filters>
  )
}

export default CompanyAuditFilter
