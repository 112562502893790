const SELLER_KIND = 'seller'
const PARTNER_KIND = 'partner'
const SELLER_AND_PARTNER_KIND = 'seller_and_partner'

const TRANSLATED_KINDS = {
  [SELLER_KIND]: 'Vendedor',
  [PARTNER_KIND]: 'Parceiro',
  [SELLER_AND_PARTNER_KIND]: 'Vendedor e Parceiro',
}

const OPTIONS = [
  { id: SELLER_KIND, name: TRANSLATED_KINDS[SELLER_KIND] },
  { id: PARTNER_KIND, name: TRANSLATED_KINDS[PARTNER_KIND] },
  {
    id: SELLER_AND_PARTNER_KIND,
    name: TRANSLATED_KINDS[SELLER_AND_PARTNER_KIND],
  },
]

const seller = {
  SELLER_KIND,
  PARTNER_KIND,
  SELLER_AND_PARTNER_KIND,
  TRANSLATED_KINDS,
  OPTIONS,
}

export default seller
