const NEWLY_CREATED = 'newly_created'
const OLDER_CREATED = 'older_created'
const NEWLY_UPDATED = 'newly_updated'
const OLDER_UPDATED = 'older_updated'
const FORMATTED_NEWLY_CREATED = 'created_at DESC'
const FORMATTED_OLDER_CREATED = 'created_at ASC'
const FORMATTED_NEWLY_UPDATED = 'updated_at DESC'
const FORMATTED_OLDER_UPDATED = 'updated_at ASC'

const orderFilter = {
  NEWLY_CREATED,
  OLDER_CREATED,
  NEWLY_UPDATED,
  OLDER_UPDATED,
  FORMATTED_NEWLY_CREATED,
  FORMATTED_OLDER_CREATED,
  FORMATTED_NEWLY_UPDATED,
  FORMATTED_OLDER_UPDATED,
}

export default orderFilter
