import components from '../../components'

import { GAP, marginTable } from '../../constants'

import palette from 'theme/palette'

const occurrenceScience = async (pdf, date, { incident }) => {
  const operatorData = incident?.incidentDataAgents?.operator?.[0]

  pdf.addPage('a4', 'p')

  components.subtitle(pdf, 'Ciência da Ocorrência', GAP)

  components.lineWithDate(pdf, date)

  components.paragraph(
    pdf,
    'Avaliação do risco',
    GAP * 1.5,
    palette.BlueDownDark,
    'Roboto-Bold',
  )

  const incidentData = [
    [
      'Justifique, se cabível, a avaliação do risco do incidente',
      incident?.justificationRiskAssessment || 'N/A',
    ],
  ]

  components.table(pdf, [], incidentData, GAP * 1.6, 0.4)

  let tableHeight = pdf.previousAutoTable.finalY + 10

  components.paragraph(
    pdf,
    'Da ciência da ocorrência do incidente',
    tableHeight + marginTable,
    palette.BlueDownDark,
    'Roboto-Bold',
  )

  const controllerDataTable = [
    [
      'Por qual meio se tomou conhecimento do incidente?',
      incident?.dataOptionIncidents?.howDidIncidentBecomeKnown
        ? incident?.dataOptionIncidents?.howDidIncidentBecomeKnown
            .map((incidentBecomeKnown) => incidentBecomeKnown.name + '\n')
            .join('')
        : 'N/A',
    ],
    [
      'Descreva, resumidamente, de que forma a ocorrência do incidente foi conhecida',
      incident?.decriptionOccurrenceIncidentWasKnown || 'N/A',
    ],
  ]

  components.table(
    pdf,
    [],
    controllerDataTable,
    tableHeight + marginTable + GAP * 0.1,
    0.4,
  )

  tableHeight = pdf.previousAutoTable.finalY + 10

  components.paragraph(
    pdf,
    'Dados do Operador',
    tableHeight + marginTable,
    palette.BlueDownDark,
    'Roboto-Bold',
  )

  const dpoData = [
    ['Razão social/Nome', operatorData?.name || 'N/A'],
    ['CPF/CNPJ', operatorData?.document || 'N/A'],
    ['E-mail', operatorData?.email || 'N/A'],
  ]

  components.table(pdf, [], dpoData, tableHeight + marginTable + GAP * 0.1, 0.4)

  components.numberPage(pdf, palette.black, 2)
}

export default occurrenceScience
