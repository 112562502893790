import { Box, Divider, Typography } from '@material-ui/core'

import clsx from 'clsx'

import constants from 'constants/index'

import useStyles from './styles'

const PaymentMethodTabs = ({ setMethod, method, clearErrors }) => {
  const classes = useStyles()
  const options = constants.preRegistration.OPTIONS_METHOD_PAYMENT
  const icons = constants.preRegistration.PAYMENT_METHOD_ICONS

  return (
    <Box width="100%" mt={4} mb={0.5}>
      <Box display="flex" alignItems="center" justifyContent="center">
        {options.map((option) => (
          <Box
            className={clsx(classes.tab, {
              [classes.tabActive]: method === option.id,
            })}
            onClick={() => {
              setMethod(option.id)
              clearErrors()
            }}
          >
            {icons[option.id]}

            <Typography variant="h4" className={classes.cardText}>
              {option.name}
            </Typography>
          </Box>
        ))}
      </Box>
      <Divider />
    </Box>
  )
}

export default PaymentMethodTabs
