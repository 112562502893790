import permissions from '../../permissions'

const DOCUMENT_TEMPLATE_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.DOCUMENTS_TEMPLATE.LIST,
  },
]

export default DOCUMENT_TEMPLATE_LIST
