import PropTypes from 'prop-types'
import { Box, Grid, Typography, IconButton } from '@material-ui/core'

import { Sync as SyncIcon } from '@material-ui/icons/'

import { BaseDialog, Label } from 'components'

const Reckoning = ({
  open = false,
  setOpen = () => {},
  reckoning,
  refresh,
}) => {
  return (
    <BaseDialog
      open={open}
      title="Lançamento de conta a receber"
      closeButtonText="Fechar"
      setOpen={setOpen}
      dialogSize="md"
    >
      <Box p={2}>
        <Grid spacing={2} container>
          <Grid item xs={12} md={4}>
            <Label
              title="Número do contrato"
              description={reckoning?.cNumeroContrato || ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Label
              title="Data de emissão"
              description={reckoning?.dataEmissao || ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Label
              title="Data de previsão"
              description={reckoning?.dataPrevisao || ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Label
              title="Data de vencimento"
              description={reckoning?.dataVencimento || ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Label
              title="Numero da Nota fiscal"
              description={reckoning?.numeroDocumentoFiscal || ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Label
              title="Numero da parcela"
              description={reckoning?.numeroParcela || ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Label
              title="Numero do pedido"
              description={reckoning?.numeroPedido || ''}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Label
              title="Valor do documento"
              description={'R$ ' + reckoning?.valorDocumento}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Label
              tooltip={false}
              title="Status"
              description={
                <>
                  <Typography variant="caption" color="textSecondary">
                    {reckoning?.statusTitulo}
                  </Typography>
                  <Box component="span" ml={2}>
                    <IconButton
                      size="small"
                      onClick={() => refresh()}
                      color="primary"
                    >
                      <SyncIcon />
                    </IconButton>
                    <Typography variant="caption" color="textSecondary">
                      Atualizar
                    </Typography>
                  </Box>
                </>
              }
            />
          </Grid>
        </Grid>
      </Box>
    </BaseDialog>
  )
}

Reckoning.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  reckoning: PropTypes.object,
}

export default Reckoning
