import constants from 'constants/index'

const status = (statusId) => {
  switch (statusId) {
    case constants.nonComplianceReports.ON_TIME_STATUS:
      return constants.nonComplianceReports.ON_TIME_LABEL
    case constants.nonComplianceReports.OVERDUE_STATUS:
      return constants.nonComplianceReports.OVERDUE_LABEL
    case constants.nonComplianceReports.FINALIZED_STATUS:
      return constants.nonComplianceReports.FINALIZED_LABEL
    case constants.nonComplianceReports.PENDING_STATUS:
      return constants.nonComplianceReports.PENDING_LABEL
    case constants.nonComplianceReports.CLOSED_STATUS:
      return constants.nonComplianceReports.CLOSED_LABEL
    case constants.nonComplianceReports.RISK_ACCEPTED_STATUS:
      return constants.nonComplianceReports.RISK_ACCEPTED_LABEL
    case constants.nonComplianceReports.SUBSTITUTED_STATUS:
      return constants.nonComplianceReports.SUBSTITUTED_LABEL
    case constants.nonComplianceReports.FROZEN_STATUS:
      return constants.nonComplianceReports.FROZEN_LABEL
    case constants.nonComplianceReports.INACTIVE_STATUS:
      return constants.nonComplianceReports.INACTIVE_LABEL
    default:
      return '-'
  }
}

export default status
