import { Box } from '@material-ui/core'

import PropsTypes from 'prop-types'

import { GeneralInformation, SpecificRisks, ActionPlans, Cause } from '../'

const OriginNecessity = ({ rmc }) => {
  return (
    <>
      <Box mb={5}>
        <GeneralInformation rmc={rmc} />
      </Box>
      <Box mb={5}>
        <SpecificRisks rmc={rmc} />
      </Box>
      <Box mb={5}>
        <ActionPlans rmc={rmc} />
      </Box>
      <Box mb={5}>
        <Cause rmc={rmc} />
      </Box>
    </>
  )
}

OriginNecessity.propTypes = {
  rmc: PropsTypes.object,
}

export default OriginNecessity
