import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'

import {
  Box,
  Chip,
  Typography,
  Grid,
  Link,
  makeStyles,
} from '@material-ui/core'

import styles from './styles'
import { routes } from 'Routes'

const useStyles = makeStyles(styles)

const NonComplianceReport = ({ nonComplianceReport }) => {
  const history = useHistory()
  const classes = useStyles()

  // Boolean que verifica se foram adotadas medidas mitigadoras(preenchimento do campo corrective_action)
  const isMitigationMeasures = !!nonComplianceReport.correctiveAction

  // Boolean que verifica se o risco foi aceito pelo usuario
  const isRiskTaken = !!nonComplianceReport.residualRisk

  // Boolean que verifica se existe um tratamento para o RNC(Medidas mitigadoras ou aceite de risco)
  const haveTreatment = isMitigationMeasures || isRiskTaken

  const toNonComplianceReport = () => {
    history.push(
      reverse(routes.nonComplianceReport.edit, {
        nonComplianceReportId: nonComplianceReport.id,
      }),
    )
  }

  return (
    <Box pb={3}>
      <Box mt={3} mb={2} display="flex" alignItems="center">
        <Typography variant="h5">Registro de melhoria contínua</Typography>
        {haveTreatment && (
          <Box ml={1}>
            {isMitigationMeasures && (
              <Chip
                size="small"
                className={classes.mitigationMeasuresChip}
                label="Medidas mitigadoras"
              />
            )}
            {isRiskTaken && (
              <Chip
                size="small"
                className={classes.riskTakenChip}
                label="Risco assumido"
              />
            )}
          </Box>
        )}
      </Box>

      <Grid container>
        <Grid item xs={12}>
          <Box>
            {haveTreatment ? (
              <>
                {isMitigationMeasures && (
                  <Typography
                    variant="body1"
                    align="justify"
                    color="textPrimary"
                  >
                    {nonComplianceReport.correctiveAction}
                  </Typography>
                )}
                {isRiskTaken && (
                  <Typography
                    variant="body1"
                    align="justify"
                    color="textPrimary"
                  >
                    {nonComplianceReport?.residualRisk?.justification}
                  </Typography>
                )}
                {nonComplianceReport?.showRnc && (
                  <Box mt={3}>
                    <Typography variant="body1">
                      Se você deseja visualizar o registro de melhoria contínua{' '}
                      <Link
                        href="#"
                        className={classes.link}
                        onClick={() => toNonComplianceReport()}
                      >
                        clique aqui
                      </Link>
                      .
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <Box>
                <Typography variant="body1" align="justify" color="textPrimary">
                  Até o momento não foi adotada nenhuma ação com objetivo de
                  atingir a melhoria contínua deste processamento de dados,
                  porém o registro de melhoria contínua já foi criado
                  {nonComplianceReport?.showRnc ? (
                    <>
                      , se deseja visualizá-lo{' '}
                      <Link
                        href="#"
                        className={classes.link}
                        onClick={toNonComplianceReport}
                      >
                        clique aqui.
                      </Link>
                    </>
                  ) : (
                    '.'
                  )}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

NonComplianceReport.propTypes = {
  nonComplianceReport: PropTypes.object.isRequired,
}

export default NonComplianceReport
