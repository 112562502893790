import { useState } from 'react'
import PropTypes from 'prop-types'
import { DatePicker } from '@material-ui/pickers'
import { Switch, Typography, Grid, Box, TextField } from '@material-ui/core'

import { Controller, useForm } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import * as service from 'service'
import schema from './schema'

import { BaseDialog, LoadingFeedback, Label } from 'components'

const CreateOrder = ({
  paymentContractId,
  refresh = () => {},
  openNewOrder = false,
  setOpenNewOrder = () => {},
}) => {
  const snackbar = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)

  const { control, errors, handleSubmit, watch } = useForm({
    validationSchema: schema(),
    defaultValues: {
      billetExpirationDate: null,
      isNfe: false,
    },
  })

  const onSubmit = async (data) => {
    setIsLoading(true)
    try {
      await service.dponet.paymentOrders.recurrencePaymentOrder({
        paymentOrder: {
          paymentContractId,
          billetExpirationDate: data.billetExpirationDate.format('DD/MM/YYYY'),
          contractIdentifier: data.contractIdentifier,
        },
      })
      snackbar.open({
        message:
          'Sucesso! Uma nova cobrança será enviada ao cliente em até dois minutos.',
        variant: 'success',
      })
      refresh()
      setOpenNewOrder(false)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Falha ao gerar nova ordem de pagamento',
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <BaseDialog
      open={openNewOrder}
      title="Enviar nova cobrança"
      actionButtonText="Enviar"
      closeButtonText="Cancelar"
      setOpen={setOpenNewOrder}
      formRef="id-new-payment"
    >
      <LoadingFeedback open={isLoading} />
      <form onSubmit={handleSubmit(onSubmit)} id="id-new-payment">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              as={
                <DatePicker
                  label="Vencimento do boleto"
                  color="primary"
                  format="DD/MM/yyyy"
                  variant="outlined"
                  error={!!errors.billetExpirationDate}
                  helperText={errors?.billetExpirationDate?.message}
                  fullWidth
                  disablePast
                  onChange={(newValue) => ({ value: newValue })}
                />
              }
              control={control}
              name="billetExpirationDate"
              mode="onChange"
            />
          </Grid>
          <Grid item xs={12}>
            <Label title="Essa cobrança já foi faturado no omie?">
              <Controller
                as={
                  <Box display="flex" alignItems="center">
                    <Typography variant="body2">Não</Typography>
                    <Switch />
                    <Typography variant="body2">Sim</Typography>
                  </Box>
                }
                onChange={([event]) => event.target.checked}
                control={control}
                name="isNfe"
                mode="onChange"
              />
            </Label>
          </Grid>
          {watch('isNfe') === true && (
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    fullWidth
                    label="Digite o número da nota fiscal"
                    type="number"
                    error={!!errors.contractIdentifier}
                    helperText={errors?.contractIdentifier?.message}
                  />
                }
                control={control}
                name="contractIdentifier"
                mode="onChange"
              />
            </Grid>
          )}
        </Grid>
      </form>
    </BaseDialog>
  )
}

CreateOrder.propTypes = {
  preRegistrationToken: PropTypes.string,
  refresh: PropTypes.func,
  openNewOrder: PropTypes.bool,
  setOpenNewOrder: PropTypes.func,
  setIsLoading: PropTypes.func,
}

export default CreateOrder
