import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  preLine: {
    whiteSpace: 'pre-line',
  },
  box: {
    height: '400px',
  },
  button: {
    height: 52,
  },
}))
export default styles
