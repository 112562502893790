import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  uploadButtonBase: {
    backgroundColor: theme.palette.BlueDownDark,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.BlueDownDark,
      opacity: 0.9,
    },
  },
  uploadButtonDisabed: {
    backgroundColor: 'grey',
  },
  input: {
    display: 'none',
  },
}))

export default styles
