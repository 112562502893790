import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ integrationPartnerId = '', ...params }) => {
  return await dponetAPI.get(`/integration_partners/${integrationPartnerId}`, {
    params,
  })
}

const create = async (integrationPartner) => {
  return await dponetAPI.post('/integration_partners', { integrationPartner })
}

const put = async (integrationPartnerId, integrationPartner) => {
  return await dponetAPI.put(`/integration_partners/${integrationPartnerId}`, {
    integrationPartner,
  })
}

const createToken = async (integrationPartnerId) => {
  return await dponetAPI.post(
    `/integration_partners/${integrationPartnerId}/create_token`,
  )
}

const integrationPartners = {
  get,
  create,
  put,
  createToken,
}

export default integrationPartners
