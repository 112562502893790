import { Chip } from '@material-ui/core'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import useStyles from './styles'

const RoundedChip = ({ label, color, gutterSpacing }) => {
  const classes = useStyles()

  return (
    <Chip
      label={label}
      color={color}
      className={clsx(classes.chip, { [classes.gutterSpacing]: gutterSpacing })}
    />
  )
}

RoundedChip.propTypes = {
  label: PropTypes.string,
}

RoundedChip.defaultProps = {
  color: 'primary',
  gutterSpacing: false,
}

export default RoundedChip
