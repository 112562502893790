/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Button, Box, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'

import {
  PermissionsForm,
  GeneralInformationForm,
  AdminsForm,
  RmcsForm,
  SettingsForm,
  CompanySegmentationForm,
  SalesForm,
} from './components'
import { LoadingBox } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import constants from 'constants/index'
import helpers from 'helpers'
import schema from './schema'
import * as service from 'service'
import { routes } from 'Routes'

const ProfilesForm = ({ clientProfile, isEdit, profile }) => {
  const [loading, setLoading] = useState(true)
  const [permissions, setPermissions] = useState({})

  const history = useHistory()
  const snackbar = useSnackbar()

  const profileStatus = profile?.status

  const { control, handleSubmit, errors, register, setValue, reset } = useForm({
    validationSchema: schema(),
    defaultValues: {
      name: profile?.name ?? '',
      description: profile?.description ?? '',
      status: profile?.status ?? false,
      default: profile?.default ?? null,
      companyId: profile?.company ?? null,
      profileType: clientProfile
        ? constants.user.PROFILE_TYPE_CLIENT
        : constants.user.PROFILE_TYPE_COLLABORATOR,
      originProfileId: profile?.originProfileId ?? null,
    },
  })

  const redirectRollBack = () => {
    clientProfile
      ? history.push(routes.userProfiles.all)
      : history.push(routes.collaboratorProfiles.all)
  }

  const onSubmit = (data) => {
    isEdit ? handleEdit(data) : handleCreate(data)
  }

  const handleEdit = async (data) => {
    try {
      setLoading(true)
      if (clientProfile) {
        await service.dponet.userProfiles.edit({
          profileId: profile.id,
          profile: { ...data, permissions },
        })
      } else {
        await service.dponet.collaboratorProfiles.edit({
          profileId: profile.id,
          profile: { ...data, permissions },
        })
      }

      snackbar.open({
        message: 'Perfil editado com sucesso',
        variant: 'success',
      })
      redirectRollBack()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
      reset(data)
      setLoading(false)
    }
  }

  const handleCreate = async (data) => {
    try {
      setLoading(true)
      if (clientProfile) {
        await service.dponet.userProfiles.create({
          profile: { ...data, permissions },
        })
      } else {
        await service.dponet.collaboratorProfiles.create({
          profile: { ...data, permissions },
        })
      }

      snackbar.open({
        message: 'Perfil criado com sucesso',
        variant: 'success',
      })
      redirectRollBack()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
      reset(data)
      setLoading(false)
    }
  }

  const handleChange = (value, tag) => {
    setPermissions({ ...permissions, [tag]: value })
  }

  useEffect(() => {
    setLoading(true)
    if (profile) {
      for (let item of profile.profileFunctionalities) {
        permissions[item.tag] = item.status
      }
      setPermissions(permissions)
    }
    setLoading(false)
  }, [profile])

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            {profile?.default && (
              <Box mb={2}>
                <Alert variant="filled" severity="info">
                  <Typography>
                    Atenção! Este é um perfil sugerido do sistema, isto
                    significa que uma cópia do mesmo existe em cada empresa de
                    nossa plataforma, realizar alterações neste perfil não
                    impactará em atualizações nos perfis já copiados a partir
                    deste, as atualizações serão aplicadas somente para novas
                    cópias deste perfil, ou seja, na criação de uma nova empresa
                    em nossa plataforma.
                  </Typography>
                </Alert>
              </Box>
            )}
            <GeneralInformationForm
              errors={errors}
              control={control}
              clientProfile={clientProfile}
              setValue={setValue}
              profile={profile}
              isEdit={isEdit}
            />
            {clientProfile ? (
              <PermissionsForm
                register={register}
                permissions={permissions}
                handleChange={handleChange}
                activeProfile={profileStatus}
                isEdit={isEdit}
              />
            ) : (
              <>
                <AdminsForm
                  register={register}
                  permissions={permissions}
                  handleChange={handleChange}
                  activeProfile={profileStatus}
                  isEdit={isEdit}
                />
                <SalesForm
                  register={register}
                  permissions={permissions}
                  handleChange={handleChange}
                />
                <RmcsForm
                  register={register}
                  permissions={permissions}
                  handleChange={handleChange}
                  activeProfile={profileStatus}
                  isEdit={isEdit}
                />
                <SettingsForm
                  register={register}
                  permissions={permissions}
                  handleChange={handleChange}
                  activeProfile={profileStatus}
                  isEdit={isEdit}
                />
                <CompanySegmentationForm
                  register={register}
                  permissions={permissions}
                  handleChange={handleChange}
                  activeProfile={profileStatus}
                  isEdit={isEdit}
                />
              </>
            )}
            <Box mt={2} display="flex" justifyContent="flex-start">
              <Box pr={1}>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={redirectRollBack}
                >
                  Voltar
                </Button>
              </Box>
              {(!isEdit || profileStatus) && (
                <Button color="primary" variant="contained" type="submit">
                  Salvar
                </Button>
              )}
            </Box>
          </form>
        </>
      )}
    </>
  )
}

ProfilesForm.propTypes = {
  clientProfile: PropTypes.bool,
  isEdit: PropTypes.bool,
  profile: PropTypes.object,
}

export default ProfilesForm
