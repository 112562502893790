import * as yup from 'yup'

import { cookiePlanConfigSchema } from 'views/IntegrationPartner/components/CookiePlanSelect'

const schema = () =>
  yup.object().shape({
    name: yup.string().required(),
    planId: yup.string().required(),
    cookiePlan: yup.string().required(),
    cookiePlanConfig: yup.object().when('cookiePlan', {
      is: 'custom',
      then: (schema) => schema.concat(cookiePlanConfigSchema),
    }),
  })

export default schema
