import { useState, useEffect } from 'react'
import { isEmpty, isUndefined } from 'lodash'

import {
  Box,
  Typography,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from '@material-ui/core'
import { CreateAgentDialog, ListAgents } from './components'

import clsx from 'clsx'
import { PlusCircle as PlusIcon } from 'react-feather'

import useSnackbar from 'hooks/useSnackbar'
import useDataProcess from 'hooks/useDataProcess'

import * as service from 'service'

import constants from 'constants/index'
import helpers from 'helpers'
import useStyles from './styles'

const DataTreatmentAgent = () => {
  const [openDialog, setOpenDialog] = useState(false)
  const [agents, setAgents] = useState([])
  const [dataTreatmentAgentsPresent, setDataTreatmentAgentsPresent] =
    useState(false)
  const [radioValue, setRadioValue] = useState('')
  const [agentEdition, setAgentEdition] = useState(null)

  const classes = useStyles()
  const snackbar = useSnackbar()

  const { data, nextStep, toNextStep, setToNextStep } = useDataProcess()

  const dataProcess = data

  const { CONTROLLER_TYPE, OPERATOR_TYPE, CONTROLLER_LABEL, OPERATOR_LABEL } =
    constants.dataTreatmentAgent

  useEffect(() => {
    const allDataTreatementAgents = async () => {
      const response = await service.dponet.dataTreatmentAgents.index({
        dataProcessId: dataProcess?.id,
        perPage: 100_000,
      })
      setDataTreatmentAgentsPresent(
        !isEmpty(response?.data?.dataTreatmentAgents),
      )
      setRadioValue(
        helpers.dataTreatmentAgent.agentTypeOfCompanySelf(
          response?.data?.dataTreatmentAgents,
        ),
      )
      setAgents(response?.data?.dataTreatmentAgents)
    }

    allDataTreatementAgents()

    // eslint-disable-next-line
  }, [toNextStep])

  const handleCreateOrUpdate = () => {
    try {
      if (dataTreatmentAgentsPresent) {
        service.dponet.dataTreatmentAgents.updateAll({
          dataProcessId: dataProcess?.id,
          agents: agents,
        })
      } else {
        service.dponet.dataTreatmentAgents.create({
          dataProcessId: dataProcess?.id,
          agents: agents,
        })
      }
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu um erro, tente novamente mais tarde.',
        variant: 'error',
      })
    }
  }

  const verifyDataTreatmentAgent =
    helpers.dataTreatmentAgent.typeControllerIsPresent(agents)

  useEffect(() => {
    if (toNextStep) {
      if (verifyDataTreatmentAgent) {
        handleCreateOrUpdate()
      }
      nextStep()
      setToNextStep(false)
    }
    setToNextStep(false)
    // eslint-disable-next-line
  }, [nextStep])

  const handleChange = (event) => {
    if (!isUndefined(event.target.value)) {
      const agentsRebase = agents.filter(
        (agent) => agent.name !== dataProcess?.company?.name,
      )
      setAgents([
        ...agentsRebase,
        {
          name: dataProcess?.company?.name,
          agentType: event.target.value,
          companySelf: true,
        },
      ])
    }
    setRadioValue(event.target.value)
  }

  return (
    <Box className={classes.root}>
      <Typography className={classes.subTitle}>
        O que você é nessa relação?
      </Typography>
      <Typography className={classes.span}>Selecione seu papel.</Typography>
      <RadioGroup onClick={handleChange} defaultValue={CONTROLLER_TYPE}>
        <FormControlLabel
          value={CONTROLLER_TYPE}
          className={classes.radioControl}
          control={<Radio className={classes.radioCheck} />}
          label={CONTROLLER_LABEL}
          checked={radioValue === CONTROLLER_TYPE}
        />
        <Divider />
        <FormControlLabel
          value={OPERATOR_TYPE}
          control={<Radio className={classes.radioCheck} />}
          label={OPERATOR_LABEL}
          className={classes.radioControl}
          checked={radioValue === OPERATOR_TYPE}
        />
      </RadioGroup>
      {!isEmpty(agents) && (
        <>
          <ListAgents
            agents={agents}
            setOpenDialog={setOpenDialog}
            setAgentEdition={setAgentEdition}
            setAgents={setAgents}
          />
          <Box className={classes.grayBox}>
            <Typography className={classes.label}>
              {radioValue === CONTROLLER_TYPE
                ? 'Existem mais organizações envolvidas?'
                : 'Adicione mais organizações.'}
            </Typography>
            <Button
              variant="outlined"
              className={clsx({
                [classes.primaryButton]: radioValue === CONTROLLER_TYPE,
              })}
              startIcon={<PlusIcon />}
              onClick={() => setOpenDialog(true)}
            >
              Adicionar
            </Button>
          </Box>
        </>
      )}
      {openDialog && (
        <CreateAgentDialog
          open={openDialog}
          setOpen={setOpenDialog}
          setAgents={setAgents}
          agents={agents}
          agentEdition={agentEdition}
          setAgentEdition={setAgentEdition}
          companyId={dataProcess?.company?.id}
        />
      )}
    </Box>
  )
}

export default DataTreatmentAgent
