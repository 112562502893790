import { useState, Children, useEffect } from 'react'
import clsx from 'clsx'
import moment from 'moment'
import humps from 'humps'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'

import {
  Box,
  Button,
  Drawer,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  Select,
  Paper,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'

import { SorterInput, LoadingBox } from 'components'
import { MultipleSelect, AutocompleteSelect } from './components'

import useStyles from './styles'
import constants from 'constants/index'

const Filters = ({ filter, defaultOrder, children, isLoading }) => {
  const classes = useStyles()
  const [values, setValues] = useState(() => {
    let filters = filter.filters
    delete filters['page']
    return filters
  })

  const setDefaultOrderValue = () => {
    switch ({ ...filter.filters }.order) {
      case constants.orderFilter.FORMATTED_NEWLY_CREATED:
        return constants.orderFilter.NEWLY_CREATED
      case constants.orderFilter.FORMATTED_OLDER_CREATED:
        return constants.orderFilter.OLDER_CREATED
      case constants.orderFilter.FORMATTED_NEWLY_UPDATED:
        return constants.orderFilter.NEWLY_UPDATED
      case constants.orderFilter.FORMATTED_OLDER_UPDATED:
        return constants.orderFilter.OLDER_UPDATED
      default:
        return defaultOrder
    }
  }

  const [orderValue, setOrderValue] = useState(setDefaultOrderValue())
  const { register, handleSubmit } = useForm()
  const [minDates, setMinDates] = useState({})
  const [maxDates, setMaxDates] = useState({})

  const handleChange = (e) => {
    // Regras para alteracoes do entityType na tela de RNC
    if (e?.target?.name === 'finality') {
      handleChangeRNCEntityType(e)
    } else {
      setValues({ ...values, [e.target.name]: e.target.value })
    }
  }

  const handleChangeOrder = (orderType) => {
    const filters = filter.filters
    delete filters.order

    switch (orderType) {
      case constants.orderFilter.NEWLY_CREATED:
        setOrderValue(constants.orderFilter.NEWLY_CREATED)
        filter.setFilters({ order: 'created_at DESC', ...filters })
        break
      case constants.orderFilter.OLDER_CREATED:
        setOrderValue(constants.orderFilter.OLDER_CREATED)
        filter.setFilters({ order: 'created_at ASC', ...filters })
        break
      case constants.orderFilter.NEWLY_UPDATED:
        setOrderValue(constants.orderFilter.NEWLY_UPDATED)
        filter.setFilters({ order: 'updated_at DESC', ...filters })
        break
      default:
        setOrderValue(constants.orderFilter.OLDER_UPDATED)
        filter.setFilters({ order: 'updated_at ASC', ...filters })
        break
    }

    filter.setDrawerOpen(false)
  }

  const handleChangeCard = (fieldName, value) => {
    var newValues = []
    if (!!values[fieldName]) {
      if (values[fieldName].includes(value)) {
        newValues = values[fieldName].filter(
          (currentValue) => currentValue !== value,
        )
      } else {
        newValues = [...values[fieldName], value]
      }
    } else {
      newValues = [value]
    }
    setValues({ ...values, [fieldName]: newValues })
    filter.setFilters({ ...filter.filters, [fieldName]: newValues })
  }

  const handleChangeRNCEntityType = (e) => {
    if (
      e?.target?.value === constants.nonComplianceReports.FRAGILITY_FINALITY ||
      e?.target?.value === constants.nonComplianceReports.NECESSARY_FINALITY ||
      e?.target?.value ===
        constants.nonComplianceReports.FRAGILITY_AND_NECESSARY_FINALITY
    ) {
      setValues({
        ...values,
        entityType: constants.nonComplianceReports.ENTITY_DATA_PROCESS,
        [e.target.name]: e.target.value,
      })
    }

    if (e?.target.value === constants.nonComplianceReports.QUESTION_FINALITY) {
      setValues({
        ...values,
        entityType: constants.nonComplianceReports.ENTITY_QUESTION,
        [e.target.name]: e.target.value,
      })
    }

    if (e?.target?.value === constants.nonComplianceReports.INCIDENT_FINALITY) {
      setValues({
        ...values,
        entityType: constants.nonComplianceReports.ENTITY_INCIDENT,
        [e.target.name]: e.target.value,
      })
    }
  }

  const onSubmit = () => {
    filter.setFilters(values)
    filter.setDrawerOpen(false)
  }

  const clearFilters = () => {
    Children.toArray(children).map((field) => {
      if (field?.props?.multipleselectinput) {
        return setValues({ [field?.props?.name]: [] })
      }
      return setValues({ [field?.props?.name]: '' })
    })
    filter.setFilters('')
    filter.setDrawerOpen(false)
  }

  const setDateInterval = (date, props) => {
    const formattedProps = humps.camelizeKeys(props)
    const formattedDate = moment(date).format('YYYY-MM-DD')

    if (!formattedProps.dataMin && !formattedProps.dataMax) return

    if (formattedProps.dataMin) {
      return setMinDates({
        ...minDates,
        [formattedProps.dataTarget]: formattedDate,
      })
    }

    if (formattedProps.dataMax) {
      return setMaxDates({
        ...maxDates,
        [formattedProps.dataTarget]: formattedDate,
      })
    }
  }

  const willClearField = (fieldName) => {
    const newValues = values
    delete newValues[fieldName]

    setValues(newValues)
  }

  useEffect(() => {
    if (!!defaultOrder) {
      setOrderValue(defaultOrder)
    }
  }, [defaultOrder])

  return (
    <Drawer
      anchor="right"
      open={filter.drawerOpen}
      onClose={() => filter.setDrawerOpen(false)}
    >
      <Box px={2} py={4} className={classes.root}>
        <Typography variant="h4" gutterBottom>
          Filtros
        </Typography>
        <Box display="flex" alignItems="center">
          <Box>
            <Typography>Ordenar por</Typography>
          </Box>
          <FormControl>
            <Select
              id="order-select"
              value={orderValue}
              onChange={(event) => handleChangeOrder(event.target.value)}
              input={<SorterInput />}
            >
              <MenuItem value={constants.orderFilter.NEWLY_CREATED}>
                <Typography variant="body1" color="secondary">
                  Criação recente
                </Typography>
              </MenuItem>
              <MenuItem value={constants.orderFilter.OLDER_CREATED}>
                <Typography variant="body1" color="secondary">
                  Criação antiga
                </Typography>
              </MenuItem>
              <MenuItem value={constants.orderFilter.NEWLY_UPDATED}>
                <Typography variant="body1" color="secondary">
                  Atualização recente
                </Typography>
              </MenuItem>
              <MenuItem value={constants.orderFilter.OLDER_UPDATED}>
                <Typography variant="body1" color="secondary">
                  Atualização antiga
                </Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        {isLoading ? (
          <Box className={classes.loadingBox}>
            <LoadingBox />
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            {Children.toArray(children).map(
              (field, index) =>
                (field &&
                  field.props.textfieldinput &&
                  field.props.skip !== 'true' && (
                    <div key={index}>
                      <TextField
                        SelectProps={{ native: true }}
                        margin="dense"
                        className={classes.filterField}
                        fullWidth
                        value={values[field.props.name] || ''}
                        select={field.type === 'select'}
                        {...field.props}
                        onChange={(event) => {
                          var changeValue = ''
                          if (field.props.onChange) {
                            changeValue = field.props.onChange(event)
                          }
                          if (field.props.willclearfield) {
                            willClearField(field.props.willclearfield)
                          }
                          if (field.props.willreturnvalue) {
                            event.target.value = changeValue
                          }
                          return handleChange(event)
                        }}
                        inputProps={{
                          ref: register,
                          ...field.props.inputProps,
                        }}
                        key={index}
                      ></TextField>
                    </div>
                  )) ||
                (field && field.props.datepickerinput && (
                  <div key={index}>
                    <KeyboardDatePicker
                      margin="dense"
                      className={classes.filterField}
                      fullWidth
                      format="DD/MM/yyyy"
                      minDate={minDates[field.props.name]}
                      minDateMessage="A data inicial não pode ser maior que a data final"
                      maxDate={maxDates[field.props.name]}
                      maxDateMessage="A data final não pode ser menor que a data inicial"
                      value={values[field.props.name] || null}
                      onChange={(e) => {
                        setDateInterval(e, field.props)
                        setValues({
                          ...values,
                          [field.props.name]: moment(e).utc(false).format(),
                        })
                      }}
                      {...field.props}
                      inputProps={{
                        ref: register,
                        ...field.props.inputProps,
                      }}
                      key={index}
                    ></KeyboardDatePicker>
                  </div>
                )) ||
                (field?.props?.autocompleteselectinput && (
                  <div key={index}>
                    <AutocompleteSelect
                      {...field.props}
                      register={register}
                      values={values}
                      setValues={setValues}
                    />
                  </div>
                )) ||
                (field?.props?.multipleselectinput && (
                  <div key={index}>
                    <MultipleSelect
                      {...field.props}
                      register={register}
                      values={values}
                      setValues={setValues}
                    />
                  </div>
                )) ||
                (field?.props?.cardselect && (
                  <Paper
                    key={index}
                    variant="outlined"
                    className={clsx(classes.cardInput, {
                      [classes.cardSelected]: values[
                        field.props.name
                      ]?.includes(field.props.value),
                    })}
                    onClick={() =>
                      handleChangeCard(field.props.name, field.props.value)
                    }
                  >
                    {field?.props?.children}
                  </Paper>
                )),
            )}
            <Button
              className={classes.filterField}
              variant="outlined"
              color="default"
              fullWidth
              type="button"
              onClick={clearFilters}
            >
              Limpar Filtros
            </Button>

            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.filterField}
              fullWidth
            >
              Filtrar
            </Button>
          </form>
        )}
      </Box>
    </Drawer>
  )
}

Filters.propTypes = {
  filter: PropTypes.object,
  defaultOrder: PropTypes.string,
  children: PropTypes.node,
}

Filters.defaultProps = {
  defaultOrder: constants.orderFilter.NEWLY_CREATED,
  isLoading: false,
}

export default Filters
