import React, { useState } from 'react'

import { routes } from 'Routes'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'

import { BaseDialog, LoadingBox } from 'components'
import { ListContracts } from './components'

import { useSnackbar } from 'hooks'

import helpers from 'helpers'
import * as service from 'service'

const ChurnCreateModal = ({ open, setOpen }) => {
  const [loading, setLoading] = useState(false)
  const [selectedContract, setSelectedContract] = useState(null)

  const history = useHistory()
  const snackbar = useSnackbar()

  const handleEdit = (churnId) => {
    history.push(
      reverse(routes.churns.edit, {
        churnId: churnId,
      }),
    )
  }

  const handleCreateChurn = async () => {
    setLoading(true)

    try {
      const response = await service.dponet.churns.create({
        paymentContractId: selectedContract?.id,
      })

      snackbar.open({
        message: `Churn criado com sucesso!`,
        variant: 'success',
      })

      handleEdit(response?.data?.churnId)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu um erro ao tentar criar o churn',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <BaseDialog
          open={open}
          setOpen={setOpen}
          title="Criar solicitação de churn"
          closeButtonText="Voltar"
          actionButtonText="Próximo"
          justifyActions="flex-end"
          actionButton={() => handleCreateChurn()}
        >
          <Box pb={2}>
            <Typography>
              Pesquise pelo número do contrato, nome da empresa ou documento
              (CPF/CNPJ)
            </Typography>
          </Box>
          <ListContracts setSelectedContract={setSelectedContract} />
        </BaseDialog>
      )}
    </>
  )
}

ChurnCreateModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
}

export default ChurnCreateModal
