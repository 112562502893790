import constants from 'constants/preRegistration'

const chipStatusLabel = (status) => {
  const statuses = {
    [constants.STATUSES.INITIATED_STATUS]:
      constants.STATUSES_LABELS.INITIATED_LABEL,
    [constants.STATUSES.PAYMENT_FAILED_STATUS]:
      constants.STATUSES_LABELS.PAYMENT_FAILED_LABEL,
    [constants.STATUSES.FINALIZED_STATUS]:
      constants.STATUSES_LABELS.FINALIZED_LABEL,
    [constants.STATUSES.AWAITING_PAYMENT_STATUS]:
      constants.STATUSES_LABELS.AWAITING_PAYMENT_LABEL,
    [constants.STATUSES.DEMO_STATUS]: constants.STATUSES_LABELS.DEMO_LABEL,
    [constants.STATUSES.TRIAL_LIMITED_STATUS]:
      constants.STATUSES_LABELS.TRIAL_LIMITED_LABEL,
    [constants.STATUSES.TRIAL_COMPLETE_STATUS]:
      constants.STATUSES_LABELS.TRIAL_COMPLETE_LABEL,
    [constants.STATUSES.TRIAL_CANCELED_STATUS]:
      constants.STATUSES_LABELS.TRIAL_CANCELED_LABEL,
    [constants.STATUSES.OVERDUE_STATUS]:
      constants.STATUSES_LABELS.OVERDUE_LABEL,
    [constants.STATUSES.AWAITING_RESPONSE_STATUS]:
      constants.STATUSES_LABELS.AWAITING_RESPONSE_LABEL,
  }

  return statuses[status] || null
}

export default chipStatusLabel
