import Main from './CollaboratorsMain'
import New from './CollaboratorsNew'
import Edit from './CollaboratorsEdit'

const Collaborators = {
  Main,
  New,
  Edit,
}

export default Collaborators
