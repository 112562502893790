import permissions from '../../permissions'

const DOCUMENTATION_LINK_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.DOCUMENTATION_LINKS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.CREATE,
    tag: permissions.DOCUMENTATION_LINKS.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.EDIT,
    tag: permissions.DOCUMENTATION_LINKS.EDIT,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.DESTROY,
    tag: permissions.DOCUMENTATION_LINKS.DESTROY,
  },
]

export default DOCUMENTATION_LINK_LIST
