import { Box, Typography } from '@material-ui/core'
import PropTypes from 'react-jss/lib/propTypes'

import useStyles from './styles'

const InfoField = ({ title, marginBottom, children, isComponent }) => {
  const classes = useStyles()

  return (
    <Box width="100%" mb={marginBottom}>
      {title && (
        <Typography variant="body2" className={classes.title} gutterBottom>
          {title}
        </Typography>
      )}
      {isComponent ? (
        <>{children}</>
      ) : (
        <Typography display="inline" variant="body2" gutterBottom>
          {children}
        </Typography>
      )}
    </Box>
  )
}

InfoField.prototype = {
  title: PropTypes.string,
  marginBottom: PropTypes.integer,
  children: PropTypes.node,
  isComponent: PropTypes.boolean,
}

InfoField.defaultProps = {
  marginBottom: 4,
  isComponent: false,
}

export default InfoField
