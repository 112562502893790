import { useState } from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import { Chip, Button, Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import {
  Edit as EditIcon,
  Eye as ViewIcon,
  EyeOff as InactiveIcon,
} from 'react-feather'

import { MenuButton, Permitted, BaseDialog } from 'components'
import { TableRow, TableCell } from 'components/Table'
import { LastUpdateInactiveOrActiveDialog } from './components'

import helpers from 'helpers'
import constants from 'constants/index'
import { routes } from 'Routes'
import useStyles from './styles'

const LastUpdatesTableRow = ({ lastUpdate, refresh }) => {
  const classes = useStyles()
  const history = useHistory()

  const [openModalView, setOpenModalView] = useState(false)
  const [openModalActiveOrInactive, setOpenModalActiveOrInactive] =
    useState(false)

  const navigateToEdit = (lastUpdateId) => {
    history.push(
      reverse(routes.lastUpdates.edit, {
        lastUpdateId: lastUpdateId,
      }),
    )
  }

  const ModalViewLastUpdate = () => {
    setOpenModalView(true)
  }

  const handleCloseView = () => {
    setOpenModalView(false)
  }

  const ModalActiveOrInactive = () => {
    setOpenModalActiveOrInactive(true)
  }

  const newTag = lastUpdate?.tag === constants.lastUpdates.KINDS_LABELS.N

  const descriptionSanitizer = DOMPurify.sanitize(lastUpdate?.description, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  })

  return (
    <>
      <TableRow key={lastUpdate?.id}>
        <TableCell>{lastUpdate?.id}</TableCell>
        <TableCell>{lastUpdate?.title}</TableCell>
        <TableCell>{lastUpdate?.subtitle}</TableCell>
        <TableCell>{lastUpdate?.publishedBy}</TableCell>
        <TableCell disableTooltip>
          <Chip
            size="medium"
            className={newTag ? classes.new : classes.improvement}
            label={lastUpdate?.tag}
          />
        </TableCell>
        <TableCell align="center">
          {helpers.formatters.date(lastUpdate?.updatedAt)}
        </TableCell>
        <TableCell disableTooltip align="right">
          <MenuButton>
            <Permitted
              someTags={[
                constants.permissions.LAST_UPDATES.SHOW,
                constants.permissions.LAST_UPDATES.EDIT,
              ]}
            >
              <Permitted tag={constants.permissions.LAST_UPDATES.EDIT}>
                <Button
                  startIcon={<EditIcon className={classes.icons} />}
                  onClick={() => {
                    navigateToEdit(lastUpdate?.id)
                  }}
                >
                  EDITAR
                </Button>
                <Button
                  tag={constants.permissions.LAST_UPDATES.SHOW}
                  startIcon={<ViewIcon className={classes.icons} />}
                  onClick={ModalViewLastUpdate}
                >
                  VISUALIZAR
                </Button>
                <Button
                  tag={constants.permissions.LAST_UPDATES.EDIT}
                  startIcon={
                    lastUpdate?.active ? (
                      <InactiveIcon className={classes.icons} />
                    ) : (
                      ''
                    )
                  }
                  onClick={ModalActiveOrInactive}
                >
                  {lastUpdate?.active ? 'INATIVAR' : 'ATIVAR'}
                </Button>
              </Permitted>
            </Permitted>
          </MenuButton>
        </TableCell>
      </TableRow>
      <BaseDialog
        open={openModalView}
        setOpen={setOpenModalView}
        title={lastUpdate?.title}
        chipLabel={lastUpdate?.tag}
        actionButton={handleCloseView}
        withoutBottomDivider={true}
        closeIconButton={true}
        dialogSize="md"
      >
        <Box
          fontFamily="Roboto"
          dangerouslySetInnerHTML={{
            __html: descriptionSanitizer,
          }}
        />
      </BaseDialog>
      <LastUpdateInactiveOrActiveDialog
        open={openModalActiveOrInactive}
        setOpen={setOpenModalActiveOrInactive}
        lastUpdate={lastUpdate}
        refresh={refresh}
      />
    </>
  )
}

LastUpdatesTableRow.propTypes = {
  lastUpdate: PropTypes.object,
  refresh: PropTypes.func,
}

LastUpdatesTableRow.defaultProps = {
  refresh: () => {},
}

export default LastUpdatesTableRow
