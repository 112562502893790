import { useContext } from 'react'
import CompanyContext from 'contexts/CompanyContext'

const useCompany = () => {
  const context = useContext(CompanyContext)

  if (context === undefined) {
    throw new Error('useCompany must be used within a CompanyContext.Provider')
  }
  return {
    groups: context.groups,
    companySizes: context.companySizes,
    subgroups: context.subgroups,
    segments: context.segments,
    loadSubgroups: context.loadSubgroups,
    loadSegments: context.loadSegments,
    secondaryEconomicActivities: context.secondaryEconomicActivities,
    isLoading: context.isLoading,
    setIsLoading: context.setIsLoading,
    userCompanies: context.userCompanies,
    loadEconomicActivities: context.loadEconomicActivities,
    loadSecondaryEconomicActivities: context.loadSecondaryEconomicActivities,
    loaded: context.loaded,
    loadUserCompanies: context.loadUserCompanies,
  }
}

export default useCompany
