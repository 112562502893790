import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  gapGrid: {
    gap: theme.spacing(2.5),
  },
  labelSwitch: {
    marginRight: 0,
  },
}))

export default styles
