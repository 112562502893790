import api from 'service/api'

const dponetAPI = api.create('dponet')

const index = async ({ ...params }) => {
  return await dponetAPI.get('/incidents', { params })
}

const show = async ({ incidentId = '' }) => {
  return await dponetAPI.get(`/incidents/${incidentId}`)
}

const logs = async ({ incidentId }) => {
  return await dponetAPI.get(`/incidents/${incidentId}/logs`)
}

const changeStatus = async ({ incidentId }) => {
  return await dponetAPI.put(`/incidents/${incidentId}/change_status`)
}

const incidents = { index, show, logs, changeStatus }

export default incidents
