import permissions from '../../permissions'

const COMPANY_SUPPLIER_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.COMPANY_SUPPLIERS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.SHOW,
    tag: permissions.COMPANY_SUPPLIERS.SHOW,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE,
    tag: permissions.COMPANIES.UPDATE,
  },
]

export default COMPANY_SUPPLIER_LIST
