import constants from 'constants/index'

const statusChipColor = (statusId, classes) => {
  switch (statusId) {
    case constants.nonComplianceReports.ON_TIME_STATUS:
      return classes.onTime
    case constants.nonComplianceReports.OVERDUE_STATUS:
      return classes.overdue
    case constants.nonComplianceReports.FINALIZED_STATUS:
      return classes.finalized
    case constants.nonComplianceReports.PENDING_STATUS:
      return classes.pending
    case constants.nonComplianceReports.RISK_ACCEPTED_STATUS:
      return classes.finalized
    case constants.nonComplianceReports.SUBSTITUTED_STATUS:
      return classes.substituted
    case constants.nonComplianceReports.FROZEN_STATUS:
      return classes.frozen
    case constants.nonComplianceReports.INACTIVE_STATUS:
      return classes.inactive
    default:
      return ''
  }
}

export default statusChipColor
