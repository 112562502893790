import New from './DocumentationsNew'
import Main from './DocumentationsMain'
import Edit from './DocumentationsEdit'

const Documentations = {
  New,
  Main,
  Edit,
}

export default Documentations
