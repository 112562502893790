import constants from 'constants/platformContract'

const contractKindLabel = (kind) => {
  switch (kind) {
    case constants.PLATFORM_ONLY_KIND:
      return constants.PLATFORM_ONLY_LABEL
    case constants.PLATFORM_AND_DPO_KIND:
      return constants.PLATFORM_AND_DPO_LABEL
    default:
      return constants.CUSTOM_KIND_LABEL
  }
}

export default contractKindLabel
