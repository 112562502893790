import { makeStyles, colors } from '@material-ui/core'

import palette from 'theme/palette'

const styles = makeStyles((theme) => ({
  removeButton: {
    backgroundColor: colors.red[400],
    borderColor: colors.red[400],
    color: palette.white,
    '&:hover': {
      backgroundColor: colors.red[500],
      borderColor: colors.red[500],
    },
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

export default styles
