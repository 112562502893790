import PropTypes from 'prop-types'

import { useState, useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { useFormContext, Controller } from 'react-hook-form'

import {
  Box,
  Typography,
  MenuItem,
  TextField,
  Checkbox,
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'

import helpers from 'helpers'

import { useDataProcess } from 'hooks'

const TableNecPropDataLifeCycle = ({ dataLifeCycles, editType }) => {
  const { control, errors, setValue } = useFormContext()

  const [checked, setChecked] = useState(false)

  const { setNecDataLifeCycles } = useDataProcess()

  const handleChangeLifeCycle = (value, lifeCycle) => {
    const lifeCycleIndex = dataLifeCycles.indexOf(lifeCycle)

    dataLifeCycles[lifeCycleIndex].necessary =
      helpers.formatters.convertBinaryToBool(value)
    setNecDataLifeCycles(dataLifeCycles)
  }

  useEffect(() => {
    dataLifeCycles.map((dataLifeCycle) => {
      if (checked) {
        setValue(`dataLifeCycle-${dataLifeCycle.id}`, 1)
        return dataLifeCycle
      } else {
        setValue(
          `dataLifeCycle-${dataLifeCycle.id}`,
          helpers.formatters.convertBoolToBinary(dataLifeCycle.necessary),
        )
        return dataLifeCycle
      }
    })
    //eslint-disable-next-line
  }, [checked])

  return (
    <>
      {!editType && (
        <Box
          width="100%"
          mb={2}
          justifyContent="space-between"
          display="flex"
          alignItems="flex-end"
        >
          <Typography variant="h6">Proteção</Typography>

          <Box padding="checkbox">
            <Box
              justifyContent="space-between"
              display="flex"
              alignItems="center"
            >
              <Box fontWeight="fontWeightBold">
                <Typography>
                  Deseja selecionar <u>todos</u> com SIM?
                </Typography>
              </Box>
              <Checkbox
                type="checkbox"
                onChange={() => {
                  setChecked(!checked)
                }}
              />
            </Box>
          </Box>
        </Box>
      )}

      <PerfectScrollbar>
        <Box width="100%">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width="40%">Proteção</TableCell>
                <TableCell width="35%">Armazenamento de referência</TableCell>
                <TableCell width="25%">
                  Considera esta forma de proteção necessária e proporcional
                  para o tratamento?
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataLifeCycles?.map((dataLifeCycle) => (
                <TableRow key={dataLifeCycle.id}>
                  <TableCell>{dataLifeCycle.protection?.name}</TableCell>
                  <TableCell>{dataLifeCycle.storageLocation?.name}</TableCell>
                  <TableCell disableTooltip>
                    <Box my={1}>
                      <Controller
                        as={
                          <TextField
                            label="Necessário?"
                            select
                            color="primary"
                            variant="outlined"
                            error={
                              !!errors[`dataLifeCycle-${dataLifeCycle?.id}`]
                            }
                            helperText={
                              errors &&
                              errors[`dataLifeCycle-${dataLifeCycle?.id}`] &&
                              'Preencha este campo'
                            }
                            fullWidth
                          >
                            <MenuItem key="sim" value={1}>
                              Sim
                            </MenuItem>
                            <MenuItem key="não" value={0}>
                              Não
                            </MenuItem>
                          </TextField>
                        }
                        name={`dataLifeCycle-${dataLifeCycle?.id}`}
                        defaultValue={helpers.formatters.convertBoolToBinary(
                          dataLifeCycle.necessary,
                        )}
                        rules={{ required: true }}
                        control={control}
                        onChange={([event]) => {
                          handleChangeLifeCycle(
                            event.target.value,
                            dataLifeCycle,
                          )
                          return event.target.value
                        }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </>
  )
}

TableNecPropDataLifeCycle.propTypes = {
  dataLifeCycles: PropTypes.arrayOf(Object).isRequired,
  editType: PropTypes.bool,
}

TableNecPropDataLifeCycle.defaultValue = {
  editType: false,
}

export default TableNecPropDataLifeCycle
