import { Container, ContentHeader, Page } from 'components'
import { routes } from 'Routes'
import { SupplierForm } from './components'

const SuppliersNew = () => {
  const menuNavigations = [{ text: 'Listagem', route: routes.suppliers.all }]

  return (
    <Page title="Fornecedores">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menuNavigations={menuNavigations}
          title="Novo fornecedor"
          menu="Fornecedores"
          subtitle="Novo fornecedor"
        />
        <SupplierForm />
      </Container>
    </Page>
  )
}

export default SuppliersNew
