import { isEmpty } from 'lodash'

import components from '../../components'

import { GAP, marginTable } from '../../constants'

import palette from 'theme/palette'

const impactDescription = async (pdf, date, { incident }) => {
  pdf.addPage('a4', 'p')

  components.subtitle(pdf, 'Descrição e Impacto', GAP)

  components.lineWithDate(pdf, date)

  components.paragraph(
    pdf,
    'Descrição do Incidente',
    GAP * 1.5,
    palette.BlueDownDark,
    'Roboto-Bold',
  )

  const incidentData = [
    [
      'Qual o tipo de incidente? (Informe o tipo mais específico)',
      incident?.dataOptionIncidents?.whatTypeIncident
        ? incident?.dataOptionIncidents?.whatTypeIncident
            .map((incident) => incident.name + '\n')
            .join('')
        : 'N/A',
    ],
    [
      'Descreva, resumidamente, como ocorreu o incidente:',
      incident?.description || 'N/A',
    ],
    [
      'Explique, resumidamente, por que o incidente ocorreu (identifique a causa raiz, se conhecida):',
      incident?.descriptionRootIncident || 'N/A',
    ],
    [
      'Quais medidas foram adotadas para corrigir as causas do incidente?',
      incident?.descriptionMeasures || 'N/A',
    ],
  ]

  components.table(pdf, [], incidentData, GAP * 1.6, 0.4)

  let tableHeight = pdf.previousAutoTable.finalY + 10

  components.paragraph(
    pdf,
    'Impactos do Incidente Sobre os Dados Pessoais',
    tableHeight + marginTable,
    palette.BlueDownDark,
    'Roboto-Bold',
  )

  const controllerDataTable = [
    [
      'De que formas o incidente afetou os dados pessoais:',
      !isEmpty(incident?.incidentViolationTypes)
        ? incident?.incidentViolationTypes
            .map((incidentViolationType) => incidentViolationType.name + '\n')
            .join('')
        : 'N/A',
    ],
    [
      'Se aplicável, quais os tipos de dados pessoais sensíveis foram violados?',
      incident?.dataOptionIncidents?.typesSensitivePersonalDataBreached
        ? incident?.dataOptionIncidents?.typesSensitivePersonalDataBreached
            .map((sensitiveData) => sensitiveData.name + '\n')
            .join('')
        : 'N/A',
    ],
    [
      'Se aplicável, descreva os tipos de dados pessoais sensíveis violados:',
      incident?.descriptionTypePersonalBreached || 'N/A',
    ],
    [
      'Quais os demais tipos de dados pessoais violados?',
      incident?.dataOptionIncidents?.otherTypePersonalDataViolated
        ? incident?.dataOptionIncidents?.otherTypePersonalDataViolated
            .map((dataType) => dataType.name + '\n')
            .join('')
        : 'N/A',
    ],
    [
      'Descreva os tipos de dados pessoais não sensíveis violados, se cabível:',
      incident?.nonSensitiveViolationDescription || 'N/A',
    ],
  ]

  components.table(
    pdf,
    [],
    controllerDataTable,
    tableHeight + marginTable + GAP * 0.1,
    0.4,
  )

  components.numberPage(pdf, palette.black, 2)
}

export default impactDescription
