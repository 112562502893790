import PropTypes from 'prop-types'
import { Page, Container, ContentHeader } from 'components'

import { useFetch } from 'hooks'
import * as service from 'service'
import { routes } from 'Routes'

import { CompanySizeForm } from './components'

const CompanySizesEdit = ({ match }) => {
  const {
    params: { sizeId },
  } = match

  const { response, isLoading } = useFetch(
    service.dponet.companySizes.show,
    {
      sizeId,
    },
    [],
  )

  const navigationsRoutes = [
    {
      text: 'Listagem',
      route: routes.companySizes.all,
    },
  ]

  return (
    <Page title="Edição de tamanho de empresa">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Edição"
          menu="Tamanho de empresa"
          subtitle="Edição de tamanho de empresa"
          menuNavigations={navigationsRoutes}
        />
        {!isLoading && (
          <CompanySizeForm isEdit size={response?.data?.companySize} />
        )}
      </Container>
    </Page>
  )
}

CompanySizesEdit.propTypes = {
  match: PropTypes.object,
}

export default CompanySizesEdit
