import { LoadingBox, TransferList } from 'components'
import { useFetch } from 'hooks'

import * as service from 'service'
import { Grid, Typography } from '@material-ui/core'

import constants from 'constants/index'

const ProcessSelect = ({ companyId, show }) => {
  const { response, isLoading } = useFetch(
    service.dponet.dataProcesses.get,
    {
      companyId,
      minimal: true,
      perPage: 99999,
      statusId: constants.dataProcess.APPROVED_STATUS,
    },
    [companyId],
    !!companyId,
  )

  if (!show) return <></>

  if (!companyId) {
    return <Typography color="error">Selecione uma empresa origem</Typography>
  }

  if (isLoading) {
    return <LoadingBox minHeight={400} />
  }

  return (
    <Grid item xs={12}>
      <TransferList
        leftItens={response?.data?.dataProcesses ?? []}
        rightItens={[]}
      />
    </Grid>
  )
}

export default ProcessSelect
