import React from 'react'
import PropTypes from 'prop-types'

import { Grid, TextField } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import helpers from 'helpers'

const FormNfe = ({ refresh, paymentOrderId, setIsLoading, setLinkedNfe }) => {
  const snackbar = useSnackbar()

  const { control, handleSubmit } = useForm({
    defaultValues: {
      nfe: '',
    },
  })

  const onSubmit = async ({ nfe }) => {
    setIsLoading(true)
    try {
      await service.dponet.paymentOrders.linkedNfe({
        paymentOrderId,
        nfe,
      })
      snackbar.open({
        message: `Nota fiscal ${nfe} vinculada com sucesso!`,
        variant: 'success',
      })
      refresh()
      setLinkedNfe(false)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Erro ao vincular a nota fiscal!',
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="nfe-form">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Controller
            control={control}
            name="nfe"
            as={
              <TextField
                label="Código da Nota fiscal eletrônica"
                type="text"
                color="primary"
                variant="outlined"
                fullWidth
                required
              />
            }
          />
        </Grid>
      </Grid>
    </form>
  )
}

FormNfe.propTypes = {
  refresh: PropTypes.func.isRequired,
  paymentOrderId: PropTypes.string.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setLinkedNfe: PropTypes.func.isRequired,
}

export default FormNfe
