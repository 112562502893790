import { Box, Typography } from '@material-ui/core'
import clsx from 'clsx'
import moment from 'moment'

import useStyles from './styles'

const FilterDateGrid = ({ dateFilter, setDateFilter }) => {
  const classes = useStyles()
  const options = [
    {
      step: 'YESTERDAY',
      label: 'ONTEM',
      date: moment().subtract(1, 'days').format('DD/MM/YYYY'),
    },
    { step: 'TODAY', label: 'HOJE', date: moment().format('DD/MM/YYYY') },
    {
      step: 'TOMORROW',
      label: 'AMANHÃ',
      date: moment().add(1, 'days').format('DD/MM/YYYY'),
    },
  ]

  return (
    <Box className={classes.card}>
      {options.map((option) => (
        <Box
          key={option.step}
          textAlign="center"
          className={clsx(classes.tab, {
            [classes.tabActive]: dateFilter === option.step,
          })}
          onClick={() => setDateFilter(option.step)}
        >
          <Typography variant="body1">{option.label}</Typography>
          <Typography variant="caption">{option.date}</Typography>
        </Box>
      ))}
    </Box>
  )
}

export default FilterDateGrid
