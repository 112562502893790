import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ ...params }) => {
  return await dponetAPI.get('/segments', { params })
}

const show = async ({ segmentId = '' }) => {
  return await dponetAPI.get(`/segments/${segmentId}`)
}

const post = async (data) => {
  return await dponetAPI.post('/segments', data)
}

const put = async ({ segmentId = '', ...data }) => {
  return await dponetAPI.put(`/segments/${segmentId}`, data)
}

const destroy = async ({ segmentId }) => {
  return await dponetAPI.delete(`/segments/${segmentId}`)
}

const segments = {
  get,
  show,
  post,
  put,
  destroy,
}

export default segments
