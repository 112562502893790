import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ ...params }) => {
  return await dponetAPI.get('/company_sizes', { params })
}

const show = async ({ sizeId = '' }) => {
  return await dponetAPI.get(`/company_sizes/${sizeId}`)
}

const create = async (data) => {
  return await dponetAPI.post('/company_sizes', data)
}

const edit = async ({ sizeId = '', ...data }) => {
  return await dponetAPI.put(`/company_sizes/${sizeId}`, data)
}

const changeStatus = async ({ sizeId = '' }) => {
  return await dponetAPI.put(`/company_sizes/${sizeId}/change_status`)
}

const destroy = async ({ sizeId = '' }) => {
  return await dponetAPI.delete(`/company_sizes/${sizeId}`)
}

const companySizes = {
  get,
  create,
  show,
  edit,
  changeStatus,
  destroy,
}

export default companySizes
