import { Container, ContentHeader, Page } from 'components'
import { IntegrationPartnerForm } from './components'

const IntegrationPartnerNew = () => {
  return (
    <Page title="Novo perceiro">
      <Container spacingXl={40} spacingLg={25} spacingMd={10} spacingSm={10}>
        <ContentHeader
          menu="Parceiros de integração"
          title="Novo"
          subtitle="Parceiro"
        />
        <IntegrationPartnerForm editionPermitted />
      </Container>
    </Page>
  )
}

export default IntegrationPartnerNew
