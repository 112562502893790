import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  boxPlans: {
    display: 'flex',
    gap: '12px',
    flexWrap: 'wrap',
  },
  boxCardPlans: {
    minWidth: '32.5%',
  },
  boxCardPlansError: {
    border: '0.5px solid',
    borderColor: theme.palette.cgRed,
    borderRadius: '5px',
  },
  errorsPlans: {
    color: theme.palette.cgRed,
    fontWeight: 400,
    margin: 0,
    fontSize: '0.75rem',
    marginTop: '3px',
    textAlign: 'left',
    marginLeft: '14px',
    marginRight: '14px',
  },
}))

export default styles
