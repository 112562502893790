import New from './LastUpdatesNew'
import Main from './LastUpdatesMain'
import Edit from './LastUpdatesEdit'

const LastUpdates = {
  New,
  Main,
  Edit,
}

export default LastUpdates
