import { Card, CardHeader, Divider, Box, Typography } from '@material-ui/core'

import useDataProcess from 'hooks/useDataProcess'

import useStyles from '../../styles'

const Description = () => {
  const {
    data: { automaticDescription, description },
  } = useDataProcess()

  const classes = useStyles()

  return (
    <>
      {!automaticDescription && (
        <Card>
          <CardHeader title="Descrição" />
          <Divider />
          <Box className={classes.root}>
            <Typography>{description}</Typography>
          </Box>
        </Card>
      )}
    </>
  )
}

export default Description
