import constants from 'constants/index'

const labelSource = (source) => {
  switch (source) {
    case constants.dataProcess.DEPARTMENT_SOURCE:
      return 'Qual o departamento onde vêm os dados?'
    case constants.dataProcess.THIRD_PARTY_SOURCE:
      return 'Qual é o nome completo do terceiro (pessoa jurídica ou física) que compartilha os dados com a organização?'
    default:
      return 'Onde são disponibilizados os dados?'
  }
}

export default labelSource
