import { Container, ContentHeader, Page, LoadingFeedback } from 'components'
import { DocumentationForm } from './components'
import { useFetch } from 'hooks'
import * as service from 'service'

const DocumentationsEdit = ({ match }) => {
  const {
    params: { faqsDocumentationId },
  } = match

  const { response, isLoading } = useFetch(
    service.dponet.faqsDocumentations.get,
    { faqsDocumentationId },
    [],
  )

  return (
    <Page title="Editar Documentação">
      <LoadingFeedback open={isLoading} />
      <Container spacingXl={40} spacingLg={25} spacingMd={10} spacingSm={10}>
        <ContentHeader
          menu="Central de ajuda"
          title="Editar"
          subtitle="Documentação"
        />
        {!isLoading && (
          <DocumentationForm
            isEdit
            editionPermitted
            documentation={response?.data?.faqsDocumentation}
          />
        )}
      </Container>
    </Page>
  )
}

export default DocumentationsEdit
