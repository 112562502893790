import PropTypes from 'prop-types'
import {
  Page,
  Container,
  ContentHeader,
  Permitted,
  MenuButton,
} from 'components'

import { RemoveGroup } from './components'

import { useFetch } from 'hooks'
import * as service from 'service'

import constants from 'constants/index'

import { GroupsForm } from './components'

const GroupsEdit = ({ match }) => {
  const {
    params: { groupId },
  } = match

  const { response, isLoading, refresh } = useFetch(
    service.dponet.groups.show,
    {
      groupId,
    },
    [],
  )

  return (
    <Page title="Edição de grupo">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader title="Edição" menu="Grupo" subtitle="Edição de grupo">
          <Permitted someTags={[constants.permissions.GROUPS.DESTROY]}>
            <MenuButton>
              <RemoveGroup
                isEdit
                groupId={response?.data?.group?.id}
                refresh={refresh}
              />
            </MenuButton>
          </Permitted>
        </ContentHeader>
        {!isLoading && <GroupsForm isEdit group={response?.data?.group} />}
      </Container>
    </Page>
  )
}

GroupsEdit.propTypes = {
  match: PropTypes.object,
}

export default GroupsEdit
