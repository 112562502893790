/* eslint-disable react-hooks/exhaustive-deps */
import { Filters } from 'components'

const Filter = ({ filter }) => {
  return (
    <Filters filter={filter}>
      <input textfieldinput="true" label="Identificador" name="id" />
      <input textfieldinput="true" label="Nome" name="name" />
    </Filters>
  )
}

export default Filter
