import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  iconbutton: {
    '&:hover': {
      backgroundColor: theme.palette.azure,
      color: theme.palette.white,
    },
  },
}))

export default styles
