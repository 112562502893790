import PropTypes from 'prop-types'
import {
  Grid,
  Radio,
  RadioGroup,
  Box,
  FormControlLabel,
} from '@material-ui/core'

import { routes } from 'Routes'
import { useHistory } from 'react-router-dom'

import constants from 'constants/index'

import { Controller } from 'react-hook-form'

import { Card, Label, ButtonComponent } from 'components'
import Contractor from '../../../Contractor'

const Facture = ({
  control,
  errors,
  isNew,
  watch,
  displaySelected = false,
}) => {
  const history = useHistory()

  const handleCreateUser = () => {
    const typeCompany =
      watch('typeFacture') === 'newCompany'
        ? constants.preRegistration.CLIENT_TYPE
        : constants.preRegistration.SUBLICENSE_TYPE

    history.push(routes.preRegistrations.new, {
      type: constants.preRegistration.TYPE_REAL,
      typeClient: typeCompany,
      createContract: true,
    })
  }

  return (
    <Card title="Faturar para">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Label title="Crie ou selecione um cliente/sublicenciamento para ser responsável pelo faturamento do contrato">
            <Controller
              as={
                <RadioGroup>
                  <Box>
                    <FormControlLabel
                      disabled={!isNew}
                      value="newCompany"
                      control={<Radio color="primary" />}
                      label="Novo cliente"
                    />
                  </Box>
                  <Box>
                    <FormControlLabel
                      disabled={!isNew}
                      value="existing"
                      control={<Radio color="primary" />}
                      label="Cliente/sublicenciamento já existente"
                    />
                  </Box>
                </RadioGroup>
              }
              control={control}
              mode="onChange"
              name="typeFacture"
            />
          </Label>
        </Grid>
        {(watch('typeFacture') === 'existing' || displaySelected) && (
          <Grid item xs={12}>
            <Contractor
              control={control}
              errors={errors}
              isNew={isNew}
              watch={watch}
            />
          </Grid>
        )}
        {['newCompany', 'newSublicense'].includes(watch('typeFacture')) && (
          <Grid item xs={12}>
            <ButtonComponent
              type="button"
              onClick={handleCreateUser}
              title={`Criar ${
                watch('typeFacture') === 'newCompany'
                  ? 'cliente'
                  : 'sublicenciamento'
              }`}
            />
          </Grid>
        )}
      </Grid>
    </Card>
  )
}

Facture.propTypes = {
  control: PropTypes.object,
  preRegistrations: PropTypes.array,
  errors: PropTypes.object,
  isNew: PropTypes.bool,
  watch: PropTypes.func,
  displaySelected: PropTypes.bool,
}

export default Facture
