import { makeStyles } from '@material-ui/core'

const styles = makeStyles(() => ({
  checkBox: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default styles
