import PropTypes from 'prop-types'
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import { Label } from 'components'

const CompanyData = ({ company }) => {
  return (
    <Card>
      <CardHeader title="Dados da empresa vinculada" />
      <Divider />
      <Box p={2}>
        {company ? (
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Label title="Nome">
                  <Typography>{company?.name}</Typography>
                </Label>
              </Grid>
              <Grid item xs={12} md={6}>
                <Label title="CNPJ/CPF">
                  <Typography>{company?.document}</Typography>
                </Label>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Typography>Nenhuma empresa vinculada</Typography>
        )}
      </Box>
    </Card>
  )
}

CompanyData.propTypes = {
  company: PropTypes.object,
}

export default CompanyData
