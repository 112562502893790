import { useEffect } from 'react'
import { Divider, Grid, TextField, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { HelpOutline as HelpIcon } from '@material-ui/icons'
import { Alert } from '@material-ui/lab'

import { Controller } from 'react-hook-form'

import { Card, Select, Label } from 'components'

import constants from 'constants/index'
import helpers from 'helpers'

import useStyles from './styles'

const PaymentOption = ({
  control,
  errors,
  watch,
  setInfoOpen,
  paymentContract,
  methodPayment,
  mode,
  initialStatus,
  setValue,
}) => {
  const classes = useStyles()
  const CLOSED_STATUSES = constants.preRegistration.CLOSED_STATUSES

  const disabled = mode === 'show'
  const displayed = mode !== 'new'
  const isEdit = mode === 'edit'

  const gateway = watch('gateway')

  const isBankTransfer = methodPayment === 'bank_transfer'
  const isSuperfin = gateway === 'superfin'
  const isOmie = gateway === 'omie'
  const isCreditCard = methodPayment === 'card'
  const isClosed = CLOSED_STATUSES.includes(paymentContract?.kind)

  useEffect(() => {
    if (isBankTransfer && isSuperfin) setValue('gateway', 'omie')
    if (isCreditCard && isOmie) setValue('gateway', 'superfin')
    if (isCreditCard) {
      setValue('typeInvoice', 'monthly')
      if (!isClosed) {
        if (initialStatus) {
          setValue('kind', 'initial')
        } else {
          setValue('kind', 'recurrence')
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methodPayment, gateway, initialStatus])

  const nextAdvanceDisplay = paymentContract?.havePaymentOrders

  return (
    <Card title="Opções do pagamento">
      <Grid container spacing={2}>
        {!isCreditCard && (
          <Grid item xs={12} md={4}>
            <Controller
              as={
                <Select
                  disabled={disabled || isCreditCard}
                  label="Tipo de faturamento"
                  items={constants.preRegistration.INVOICE_OPTIONS || []}
                />
              }
              control={control}
              name="typeInvoice"
              mode="onBlur"
            />
          </Grid>
        )}
        <Grid item xs={12} md={isCreditCard ? 6 : 4}>
          <Controller
            control={control}
            name="price"
            mode="onChange"
            as={
              <TextField
                disabled={disabled}
                label="Valor da contratação"
                fullWidth
                variant="outlined"
                error={!!errors?.price}
                helperText={errors?.price?.message}
              />
            }
            onChange={([event]) => {
              return helpers.formatters.currencyInput(event.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} md={isCreditCard ? 6 : 4}>
          <Controller
            as={
              <Select
                disabled={disabled}
                label="Status da cobrança"
                items={
                  constants.preRegistration.OPTIONS_KIND_PAYMENT_FEATURES || []
                }
                startIcon={<HelpIcon color="primary" size={10} />}
                actionStartIcon={() =>
                  setInfoOpen({
                    open: true,
                    text: constants.preRegistration.KIND_DESCRIPTION[
                      watch('kind')
                    ],
                    title:
                      constants.preRegistration.KIND_TRANSLATED[watch('kind')],
                  })
                }
              />
            }
            control={control}
            name="kind"
            mode="onChange"
          />
        </Grid>
        {!isCreditCard && (
          <>
            <Grid item xs={12} md={6}>
              <Controller
                as={
                  <Select
                    label="Intermediador de cobrança (gateway)"
                    items={
                      constants.preRegistration.OPTIONS_GATEWAY_PAYMENT_FEATURES
                    }
                    disabled={disabled}
                    startIcon={<HelpIcon color="primary" size={10} />}
                    actionStartIcon={() =>
                      setInfoOpen({
                        open: true,
                        text: constants.preRegistration.GATEWAY_DESCRIPTION[
                          watch('gateway')
                        ],
                        title:
                          constants.preRegistration.GATEWAY_TRANSLATED[
                            watch('gateway')
                          ],
                      })
                    }
                  />
                }
                control={control}
                name="gateway"
                mode="onChange"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Conta corrente"
                fullWidth
                disabled
                value={
                  watch('gateway') === 'superfin'
                    ? 'Conta corrente Superfin (Santander)'
                    : 'Conta corrente DPOnet (Bradesco)'
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                disabled={disabled}
                control={control}
                name="daysAdvance"
                mode="onChange"
                type="number"
                as={
                  <TextField
                    label="Dia de emissão (faturação)"
                    fullWidth
                    variant="outlined"
                    error={!!errors?.daysAdvance}
                    helperText={errors?.daysAdvance?.message}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                as={
                  <TextField
                    disabled={disabled}
                    type="number"
                    label="Dia fixo de Vencimento"
                    color="primary"
                    variant="outlined"
                    error={!!errors.billingDay}
                    helperText={!!errors.billingDay?.message}
                    fullWidth
                    className={clsx({
                      [classes.hidden]:
                        watch('typeBillingDay') ===
                        constants.paymentOrder.TYPE_BILLING_DAY_DYNAMIC,
                    })}
                  />
                }
                control={control}
                name="billingDay"
                mode="onChange"
              />
              <Controller
                as={
                  <TextField
                    disabled={disabled}
                    type="number"
                    label="Numero de dias após a emissão"
                    color="primary"
                    variant="outlined"
                    error={!!errors.billingDayDynamic}
                    helperText={errors.billingDayDynamic?.message}
                    fullWidth
                    className={clsx({
                      [classes.hidden]:
                        watch('typeBillingDay') ===
                        constants.paymentOrder.TYPE_BILLING_DAY_FIXED,
                    })}
                  />
                }
                control={control}
                name="billingDayDynamic"
                mode="onChange"
              />
            </Grid>
            {displayed && nextAdvanceDisplay && (
              <>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {isEdit && (
                  <Grid item xs={12}>
                    <Alert severity="info">
                      <Typography variant="body1">
                        Os prazos de faturamento e vencimento são calculados
                        automaticamente e serão atualizados apenas quando o
                        contrato for salvo.
                      </Typography>
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Label
                    title="Data do próximo faturamento"
                    tooltip={false}
                    description={helpers.formatters.date(
                      paymentContract?.nextDateAdvance,
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Label
                    title="Data do próximo vencimento"
                    tooltip={false}
                    description={helpers.formatters.date(
                      paymentContract?.nextBillingDate,
                    )}
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </Card>
  )
}

export default PaymentOption
