import { Page, Container, ContentHeader } from 'components'
import { CouponsForm } from './components'

import { routes } from 'Routes'

const CouponsNew = () => {
  return (
    <Page title="Novo cupom de desconto">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Novo cupom"
          menu="Cupom de desconto"
          subtitle="Novo cupom"
          menuNavigations={[{ text: 'Listagem', route: routes.coupons.all }]}
        />
        <CouponsForm />
      </Container>
    </Page>
  )
}
export default CouponsNew
