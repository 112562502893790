import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '40px',
    height: '40px',
    borderRadius: '50px',
    backgroundColor: theme.palette.backgroundIconGray,
    color: theme.palette.iconGray,
    marginRight: theme.spacing(2),
  },
}))

export default styles
