import Main from './CollaboratorProfilesMain'
import New from './CollaboratorProfilesNew'
import Edit from './CollaboratorProfilesEdit'

const CollaboratorProfiles = {
  Main,
  New,
  Edit,
}

export default CollaboratorProfiles
