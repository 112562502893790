import permissions from '../../permissions'

const COUPON_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.COUPONS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE,
    tag: permissions.COUPONS.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.DESTROY,
    tag: permissions.COUPONS.DESTROY,
  },
]

export default COUPON_LIST
