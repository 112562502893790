import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import { Label } from 'components'
import useStyles from '../../styles'
import PropTypes from 'prop-types'

import helpers from 'helpers'

const MembershipCard = ({ membershipRegistration }) => {
  const classes = useStyles()

  const demoFormatted = helpers.formatters.benefits({
    partner: membershipRegistration?.partnerCompany,
    ...membershipRegistration,
  })

  return (
    <Card>
      <CardHeader title="Dados do benefício" />
      <Divider />
      <Box className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Label titleVariant="body1" title="Formulario utilizado">
              <Typography variant="body2">{demoFormatted?.partner}</Typography>
            </Label>
          </Grid>
          <Grid item xs={6}>
            <Label titleVariant="body1" title="Tipo">
              <Typography variant="body2">
                {demoFormatted.typePartner}
              </Typography>
            </Label>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

MembershipCard.propTypes = {
  membershipRegistration: PropTypes.object,
}

MembershipCard.defaultProps = {
  membershipRegistration: {},
}
export default MembershipCard
