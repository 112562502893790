const COMPANY_ALLOCATION_TYPE = 'company_allocation'
const THIRDY_ALLOCATION_TYPE = 'thirdy_allocation'

const COMPANY_ALLOCATION_LABEL = 'É da minha empresa'
const THIRDY_ALLOCATION_LABEL = 'É contratado de terceiros'

const COMPANY_ALLOCATION_ID = 1
const THIRDY_ALLOCATION_ID = 2

const NATIONAL_SERVER_ALLOCATION_TYPE = 'national_allocation'
const INTERNATIONAL_SERVER_ALLOCATION_TYPE = 'international_allocation'
const BOTH_SERVER_ALLOCATION_TYPE = 'both_allocation'

const NATIONAL_SERVER_ALLOCATION_LABEL = 'Nacional'
const INTERNATIONAL_SERVER_ALLOCATION_LABEL = 'Internacional'
const BOTH_SERVER_ALLOCATION_LABEL = 'Ambos'

const sharedSoftware = {
  COMPANY_ALLOCATION_TYPE,
  THIRDY_ALLOCATION_TYPE,
  COMPANY_ALLOCATION_ID,
  THIRDY_ALLOCATION_ID,
  COMPANY_ALLOCATION_LABEL,
  THIRDY_ALLOCATION_LABEL,
  NATIONAL_SERVER_ALLOCATION_TYPE,
  INTERNATIONAL_SERVER_ALLOCATION_TYPE,
  BOTH_SERVER_ALLOCATION_TYPE,
  NATIONAL_SERVER_ALLOCATION_LABEL,
  INTERNATIONAL_SERVER_ALLOCATION_LABEL,
  BOTH_SERVER_ALLOCATION_LABEL,
}

export default sharedSoftware
