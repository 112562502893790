const nameWithDocumentByArrayObject = (array) => {
  const newArray = []

  array?.map((obj) => {
    if (!obj.name.includes(obj.document)) {
      obj.name = `${obj.name} - ${obj.document}`
    }
    newArray.push(obj)

    return obj.name
  })
  return newArray
}

const nameWithDocument = {
  nameWithDocumentByArrayObject,
}

export default nameWithDocument
