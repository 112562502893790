import * as yup from 'yup'

const schema = yup.object().shape({
  newOrder: yup.string().required(),
  expirationBillingDate: yup.string().when('newOrder', {
    is: 'true',
    then: yup.string().required(),
  }),
})

export default schema
