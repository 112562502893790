import legalFrameworkLabelByDataType from './legalFrameworkLabelByDataType'

const formatLegalFrameworksFilterOptions = (legalFrameworks) => {
  const formattedLegalFrameworks = []

  legalFrameworks = legalFrameworks.sort((currentValue, nextValue) => {
    if (currentValue.dataType === nextValue.dataType) return 0
    if (currentValue.dataType > nextValue.dataType) return 1

    return -1
  })

  legalFrameworks.map((legalFramework) => {
    let formattedLegalFramework = {
      id: legalFramework.id,
      name: `${legalFrameworkLabelByDataType(legalFramework.dataType)} - ${
        legalFramework.name
      }`,
    }

    return formattedLegalFrameworks.push(formattedLegalFramework)
  })

  return formattedLegalFrameworks
}

export default formatLegalFrameworksFilterOptions
