import React, { forwardRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  AppBar,
  Box,
  Button,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Tooltip,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { saveAs } from 'file-saver'

import { LoadingBox } from 'components'

import { useSnackbar } from 'hooks'

import { isEmpty } from 'lodash'

import { getBaseURL } from 'service/env'
import * as service from 'service'
import helpers from 'helpers'
import useStyles from './styles'

const Transition = forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

const DialogCertificate = ({ userCompany, refresh }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [certificateUrl, setCertificateUrl] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const snackbar = useSnackbar()

  const handleClickOpen = async () => {
    setOpen(true)
    await handleGenerate()
  }

  const handleClose = () => {
    setOpen(false)
    refresh()
  }

  const downloadCertification = () => {
    const imageSrc = `${getBaseURL('dponet')}/${certificateUrl}`
    saveAs(imageSrc, 'certificado-de-introduçao-à-lgpd.jpeg')
  }

  const handleGenerate = async () => {
    try {
      setIsLoading(true)
      if (userCompany?.userCompanyCertifications[0]?.certification) {
        setCertificateUrl(
          userCompany?.userCompanyCertifications[0]?.certification,
        )
      } else {
        const response = await service.dponet.userCompanies.consultCertificate({
          userCompanyId: userCompany?.id,
          certType: 'INTRODUCTION_LGPD',
        })
        setCertificateUrl(
          response?.data?.userCompanyCertification?.certification,
        )
      }
      snackbar.open({
        message: 'Certificado pronto para ser baixado!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: 'Ocorreu um erro ao gerar certificado!',
        variant: 'error',
      })
      setOpen(false)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box width="100%">
      <Tooltip
        title={
          helpers.users.buttonNameCertification(userCompany) === 'Certificado'
            ? 'Essa empresa não tem nenhum certificado'
            : ''
        }
      >
        {helpers.users.buttonNameCertification(userCompany) !== null && (
          <Box as="span">
            <Button
              fullWidth
              size="small"
              onClick={handleClickOpen}
              disabled={
                helpers.users.buttonNameCertification(userCompany) ===
                'Certificado'
              }
            >
              {helpers.users.buttonNameCertification(userCompany)}
            </Button>
          </Box>
        )}
      </Tooltip>

      <Dialog
        classes={{ paper: classes.dialog }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        id="certificate"
        fullScreen
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Button onClick={downloadCertification} autoFocus color="inherit">
              Download
            </Button>
          </Toolbar>
        </AppBar>

        <Box className={classes.boxCertificate}>
          {!isEmpty(certificateUrl) || !isLoading ? (
            <img
              className={classes.certificateImg}
              src={`${getBaseURL('dponet')}/${certificateUrl}`}
              alt="certificado"
            />
          ) : (
            <LoadingBox />
          )}
        </Box>
      </Dialog>
    </Box>
  )
}

DialogCertificate.propTypes = {
  userCompany: PropTypes.arrayOf(Object).isRequired,
  refresh: PropTypes.func.isRequired,
}

export default DialogCertificate
