import { Grid, Box, Typography } from '@material-ui/core'

import useDataProcess from 'hooks/useDataProcess'
import useRmc from 'hooks/useRmc'

import { VolumetryShow } from 'components'
import {
  Finality,
  Infos,
  InfoCompanies,
  LegalFramework,
  RMCs,
  DataProcessSources,
  DataTreatmentAgent,
} from './components'
import { LegalFramework as LegalFrameworkEditable } from '../../Edit/GeneralInformation/components'

import FinalizedOrContinuedAlert from '../../Show/FinalizedOrContinuedAlert'

import useStyles from './styles'

import helpers from 'helpers'

const GeneralInformation = ({ legalFrameworkRef }) => {
  const { data, editLegalFramework } = useDataProcess()
  const { data: rmc } = useRmc()

  const classes = useStyles()

  const showAlert =
    helpers.nonComplianceReports.checkHighOrSevereFragility(
      data?.adoptedFragilityId,
    ) &&
    data.nonComplianceReport &&
    data.version === data.nonComplianceReport.dataProcessVersion &&
    !helpers.nonComplianceReports.finalityCheck.necessary(
      data.nonComplianceReport,
    )

  return (
    <Grid container spacing={2}>
      {showAlert && (
        <Grid item xs={12}>
          <FinalizedOrContinuedAlert nonComplianceReport={rmc} isRisk />
        </Grid>
      )}
      <Grid item xs={12} md={4}>
        <Box my={2}>
          <Typography variant="h4">Informações gerais</Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item className={classes.rootCard}>
            <Infos />
          </Grid>
          <Grid item className={classes.rootCard}>
            <InfoCompanies />
          </Grid>
          <Grid item className={classes.rootCard}>
            <DataTreatmentAgent
              dataTreatmentAgents={data?.dataTreatmentAgents}
            />
          </Grid>
          <Grid item className={classes.rootCard}>
            <RMCs />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <Box my={2}>
          <Typography variant="h4">Informações do processo</Typography>
        </Box>
        <Grid container spacing={2}>
          {data.finality && (
            <Grid item className={classes.rootCard}>
              <Finality />
            </Grid>
          )}
          <Grid item md={12} className={classes.rootCard}>
            <VolumetryShow dataProcess={data} />
          </Grid>
          <Grid item xs={12} className={classes.rootCard}>
            <DataProcessSources />
          </Grid>
          <Grid item xs={12} className={classes.rootCard}>
            <Box ref={legalFrameworkRef}>
              <Typography variant="h4">Enquadramento legal</Typography>
            </Box>
          </Grid>
          {editLegalFramework ? (
            <Grid item className={classes.rootCard}>
              <LegalFrameworkEditable />
            </Grid>
          ) : (
            <Grid item className={classes.rootCard}>
              <LegalFramework />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default GeneralInformation
