const DATA_COLLECTED_WARNING_MESSAGE =
  'Para que um processo faça sentido em sua organização o mesmo deve possuir ao menos um dado tratado necessário, caso o contrário recomendamos que você deixe de realizar este processo.'
const LIFE_CYCLE_WARNING_MESSAGE =
  'Para que um processo faça sentido em sua organização o mesmo deve possuir ao menos uma forma de proteção necessária, caso o contrário recomendamos que você deixe de realizar este processo.'
const BOTH_WARNING_MESSAGE =
  'Para que um processo faça sentido em sua organização o mesmo deve possuir ao menos uma forma de proteção e um dado tratado como necessários, caso contrário recomendados que você deixe de realizar este processo.'

const necessityAndProportionalities = {
  DATA_COLLECTED_WARNING_MESSAGE,
  LIFE_CYCLE_WARNING_MESSAGE,
  BOTH_WARNING_MESSAGE,
}

export default necessityAndProportionalities
