import { Fragment } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { uniq } from 'lodash'
import PropTypes from 'prop-types'

import { CheckboxItem } from './components'

import useStyles from './styles'
import constants from 'constants/index'

const LegalFrameworksChecklist = ({
  activePersonalDataType,
  activePersonalSensitiveDataType,
  activeMinorDataType,
  predictions,
  legalFrameworks,
  selectedLegalFrameworks,
  setSelectedLegalFrameworks,
}) => {
  const classes = useStyles()

  const handleSelectAll = (items) => {
    setSelectedLegalFrameworks(
      uniq([...selectedLegalFrameworks, ...items?.map((item) => item?.id)]),
    )
  }

  const handleCheckItem = (id) => {
    const itemExists = !!selectedLegalFrameworks?.find((item) => item === id)

    if (itemExists) {
      return setSelectedLegalFrameworks(
        selectedLegalFrameworks?.filter((item) => item !== id),
      )
    }

    return setSelectedLegalFrameworks([...selectedLegalFrameworks, id])
  }

  // O modelo de concentimento dos pais não está pronto
  const definePrediction = (item) =>
    predictions?.find(
      (prediction) =>
        prediction.name === item?.name?.toLowerCase() &&
        prediction.name !== 'consentimento dos pais',
    )?.prediction

  const disableCheckBox = (legalFramework) => {
    return {
      1: !activePersonalDataType,
      2: !activePersonalSensitiveDataType,
      3: !activeMinorDataType,
    }[legalFramework.dataType]
  }

  const titularMinor = (legalFrameworkItem) => {
    if (activeMinorDataType) {
      return (
        legalFrameworkItem?.name === constants.legalFramework.PARENTAL_CONSENT
      )
    }
  }

  return (
    <>
      {Object.values(legalFrameworks)?.map((legalFramework) => (
        <Fragment key={legalFramework?.label}>
          <Box
            mt={2}
            mb={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            pl={5.5}
          >
            <Typography variant="h5">{legalFramework?.label}</Typography>
            <Button
              variant="text"
              color="primary"
              className={classes.buttonSelectAll}
              onClick={() => handleSelectAll(legalFramework?.items)}
            >
              Selecionar todos
            </Button>
          </Box>

          {legalFramework?.items?.map((legalFrameworkItem) => (
            <CheckboxItem
              key={legalFrameworkItem?.id}
              prediction={definePrediction(legalFrameworkItem)}
              item={legalFrameworkItem}
              defaultChecked={
                selectedLegalFrameworks.includes(legalFrameworkItem?.id) ||
                titularMinor(legalFrameworkItem)
              }
              handleCheck={handleCheckItem}
              classes={classes}
              disabled={disableCheckBox(legalFrameworkItem)}
              titularMinor={activeMinorDataType}
            />
          ))}
        </Fragment>
      ))}
    </>
  )
}

LegalFrameworksChecklist.propTypes = {
  predictions: PropTypes.array,
  legalFrameworks: PropTypes.object,
  selectedLegalFrameworks: PropTypes.array,
  setSelectedLegalFrameworks: PropTypes.func,
}

LegalFrameworksChecklist.defaultProps = {
  predictions: [],
  legalFrameworks: [],
  selectedLegalFrameworks: [],
  setSelectedLegalFrameworks: () => {},
}

export default LegalFrameworksChecklist
