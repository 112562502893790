import PropTypes from 'prop-types'

import { useEffect } from 'react'

import { useFormContext, Controller } from 'react-hook-form'

import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core'

import { SelectCountry, Label } from 'components'

import styles from '../DialogDataTreatment/components/Form/styles.js'

import constants from 'constants/index'

import { useSelectCountry } from 'hooks'

const useStyles = makeStyles(styles)

const SharedSoftwareForm = ({
  sharedSoftware,
  index,
  removeSharedSoftware,
  disabled,
}) => {
  const { control, errors, setValue, watch } = useFormContext()
  const { setManySelectedCountries, manySelectedCountries } = useSelectCountry()

  const classes = useStyles()

  useEffect(() => {
    setManySelectedCountries({
      ...manySelectedCountries,
      [`sharedSoftwares-${index}`]: sharedSoftware?.internationalTransfers,
    })

    setValue(`sharedSoftwares[${index}].name`, sharedSoftware?.name)
    setValue(
      `sharedSoftwares[${index}].softwareAllocationType`,
      softwareAllocationTypeDefaultValue(),
    )
    // eslint-disable-next-line
  }, [])

  const softwareAllocationTypeDefaultValue = () => {
    if (
      sharedSoftware.softwareAllocationType ===
      constants.sharedSoftware.COMPANY_ALLOCATION_TYPE
    ) {
      return constants.sharedSoftware.COMPANY_ALLOCATION_ID
    }

    if (
      sharedSoftware.softwareAllocationType ===
      constants.sharedSoftware.THIRDY_ALLOCATION_TYPE
    ) {
      return constants.sharedSoftware.THIRDY_ALLOCATION_ID
    }

    return sharedSoftware.softwareAllocationType
  }

  return (
    <Box className={classes.internationalTransferField} px={2} pb={2} mb={2}>
      <Grid container>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <Label
              description="O servidor é nacional, internacional ou ambos?"
              item
            >
              <Controller
                as={
                  <TextField
                    select
                    className={classes.inputBackground}
                    color="primary"
                    variant="outlined"
                    disabled={disabled}
                    error={
                      !!errors?.sharedSoftwares &&
                      errors?.sharedSoftwares[index]?.serverAllocation
                    }
                    helperText={
                      errors?.sharedSoftwares &&
                      errors?.sharedSoftwares[index]?.serverAllocation &&
                      'Preencha este campo'
                    }
                    fullWidth
                  >
                    <MenuItem
                      value={
                        constants.sharedSoftware.NATIONAL_SERVER_ALLOCATION_TYPE
                      }
                    >
                      <Typography>
                        {
                          constants.sharedSoftware
                            .NATIONAL_SERVER_ALLOCATION_LABEL
                        }
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      value={
                        constants.sharedSoftware
                          .INTERNATIONAL_SERVER_ALLOCATION_TYPE
                      }
                    >
                      <Typography>
                        {
                          constants.sharedSoftware
                            .INTERNATIONAL_SERVER_ALLOCATION_LABEL
                        }
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      value={
                        constants.sharedSoftware.BOTH_SERVER_ALLOCATION_TYPE
                      }
                    >
                      <Typography>
                        {constants.sharedSoftware.BOTH_SERVER_ALLOCATION_LABEL}
                      </Typography>
                    </MenuItem>
                  </TextField>
                }
                rules={{ required: true }}
                defaultValue={sharedSoftware.serverAllocation || ''}
                control={control}
                name={`sharedSoftwares[${index}].serverAllocation`}
              />
            </Label>
          </Grid>
          <Grid item xs={12}>
            {[
              constants.sharedSoftware.INTERNATIONAL_SERVER_ALLOCATION_TYPE,
              constants.sharedSoftware.BOTH_SERVER_ALLOCATION_TYPE,
            ].includes(watch(`sharedSoftwares[${index}].serverAllocation`)) && (
              <Grid item xs={12}>
                <Box>
                  <SelectCountry
                    withTable
                    manyFields
                    disabled={disabled}
                    label="Qual o país destinatário?"
                    fieldName={`sharedSoftwares-${index}`}
                    indexField={index}
                  />
                </Box>
              </Grid>
            )}
            <Box mt={3}>
              <Controller
                as={
                  <TextField
                    label="Qual o nome da plataforma, software ou aplicativo utilizado?"
                    color="primary"
                    variant="outlined"
                    error={
                      errors?.sharedSoftwares &&
                      !!errors?.sharedSoftwares[index]?.name
                    }
                    helperText={
                      errors?.sharedSoftwares &&
                      errors?.sharedSoftwares[index]?.name &&
                      'Preencha este campo'
                    }
                    className={classes.inputBackground}
                    disabled={disabled}
                    fullWidth
                  />
                }
                rules={{ required: true }}
                defaultValue={sharedSoftware.name || ''}
                control={control}
                name={`sharedSoftwares[${index}].name`}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={3}>
              <Controller
                as={
                  <TextField
                    label="O servidor dessa plataforma, software ou aplicativo, é de propriedade da sua empresa ou é contratado, de terceiro?"
                    select
                    color="primary"
                    variant="outlined"
                    error={
                      errors.sharedSoftwares &&
                      !!errors?.sharedSoftwares[index]?.softwareAllocationType
                    }
                    helperText={
                      errors?.sharedSoftwares &&
                      errors?.sharedSoftwares[index]?.softwareAllocationType &&
                      'Preencha este campo'
                    }
                    className={classes.inputBackground}
                    disabled={disabled}
                    fullWidth
                  >
                    <MenuItem value={1}>
                      <Typography>
                        {constants.sharedSoftware.COMPANY_ALLOCATION_LABEL}
                      </Typography>
                    </MenuItem>
                    <MenuItem value={2}>
                      <Typography>
                        {constants.sharedSoftware.THIRDY_ALLOCATION_LABEL}
                      </Typography>
                    </MenuItem>
                  </TextField>
                }
                rules={{ required: true }}
                control={control}
                defaultValue={softwareAllocationTypeDefaultValue()}
                name={`sharedSoftwares[${index}].softwareAllocationType`}
              />
            </Box>
          </Grid>
          <Controller
            as={<></>}
            name={`sharedSoftwares[${index}].id`}
            control={control}
            defaultValue={sharedSoftware?.id}
          />
        </Grid>
        {!disabled && (
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              height="100%"
              mt={2}
            >
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.removeButton}
                  onClick={() => removeSharedSoftware(index, sharedSoftware)}
                >
                  Remover
                </Button>
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

SharedSoftwareForm.propTypes = {
  sharedSoftware: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  removeSharedSoftware: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

SharedSoftwareForm.defaultProps = {
  removeSharedSoftware: () => {},
  disabled: false,
}
export default SharedSoftwareForm
