import capitalizeText from './capitalizeText'
import cardFlag from './cardFlag'
import dig from './dig'
import documentName from './documentName'
import formatOrderValue from './formatOrderValue'
import insertArrayAt from './insertArrayAt'
import isArrayOf from './isArrayOf'
import isEmpty from './isEmpty'
import letterOnly from './letterOnly'
import pluck from './pluck'
import reduceRoutesToArray from './reduceRoutesToArray'
import upperText from './upperText'

const functions = {
  capitalizeText,
  cardFlag,
  dig,
  documentName,
  formatOrderValue,
  insertArrayAt,
  isArrayOf,
  isEmpty,
  letterOnly,
  pluck,
  reduceRoutesToArray,
  upperText,
}

export default functions
