import PropTypes from 'prop-types'

import clsx from 'clsx'

import helpers from 'helpers'

import { Card, Typography, Box, Grid, Tooltip } from '@material-ui/core'
import { Briefcase as BriefcaseIcon, Flag as FlagIcon } from 'react-feather'

import useStyles from './styles'

const CardBar = ({
  departmentName,
  statusId,
  fragility,
  suggestionFragilityId,
}) => {
  const classes = useStyles()

  const bigGrid = suggestionFragilityId ? 3 : 4
  const labelSuggetion = helpers.dataProcesses.fragilityLabel(
    suggestionFragilityId,
  )

  return (
    <Box display="flex" width="100%">
      <Grid container spacing={1}>
        <Grid item lg={bigGrid} sm={6} xs={12}>
          <Card className={classes.root}>
            <Box display="flex" alignItems="center" width="100%" py={1} px={2}>
              <Box pr={1}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className={classes.departmentCard}
                >
                  <BriefcaseIcon size="18px" />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  className={classes.departmentBox}
                >
                  <Typography
                    color="textSecondary"
                    variant="h6"
                    className={classes.departmentTitle}
                    noWrap
                  >
                    DEPARTAMENTO
                  </Typography>
                  <Tooltip title={departmentName} placement="bottom-start">
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      className={classes.departmentValue}
                    >
                      {departmentName}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </Card>
        </Grid>
        {suggestionFragilityId && (
          <Grid item lg={bigGrid} sm={6} xs={12}>
            <Card className={classes.root}>
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                p={1}
                px={2}
                id="flagCard"
              >
                <Box pr={1}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    className={classes.flagCard}
                  >
                    <FlagIcon size="18px" />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    className={classes.flagBox}
                  >
                    <Typography
                      color="textSecondary"
                      variant="h6"
                      className={classes.flagTitle}
                      noWrap
                    >
                      RISCO SUGERIDO
                    </Typography>
                    <Tooltip
                      title={helpers.functions.capitalizeText(labelSuggetion)}
                      placement="bottom-start"
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        className={classes.flagValue}
                      >
                        {helpers.functions.capitalizeText(labelSuggetion)}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>
        )}
        <Grid item lg={bigGrid} sm={6} xs={12}>
          <Card className={classes.root}>
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              p={1}
              px={2}
              id="flagCard"
            >
              <Box pr={1}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className={classes.flagCard}
                >
                  <FlagIcon size="18px" />
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  className={classes.flagBox}
                >
                  <Typography
                    color="textSecondary"
                    variant="h6"
                    className={classes.flagTitle}
                    noWrap
                  >
                    RISCO {suggestionFragilityId && 'ADOTADO'}
                  </Typography>
                  <Tooltip
                    title={helpers.functions.capitalizeText(fragility)}
                    placement="bottom-start"
                  >
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      className={classes.flagValue}
                    >
                      {helpers.functions.capitalizeText(fragility)}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item lg={bigGrid} sm={6} xs={12}>
          <Card className={classes.root}>
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              p={1}
              px={2}
              id="StatusCard"
            >
              <Box pr={1}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  className={clsx(
                    classes.statusCard,
                    helpers.dataProcesses.statusColor(statusId, classes),
                  )}
                >
                  {helpers.dataProcesses.statusIcon(statusId)}
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  width="100%"
                  className={classes.titleBox}
                >
                  <Typography
                    color="textSecondary"
                    variant="h6"
                    className={classes.titleCard}
                    noWrap
                  >
                    STATUS
                  </Typography>
                  <Tooltip
                    title={helpers.dataProcesses.statusLabel(statusId)}
                    placement="bottom-start"
                  >
                    <Typography
                      color="textPrimary"
                      variant="body1"
                      className={classes.valueCard}
                    >
                      {helpers.dataProcesses.statusLabel(statusId)}
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

CardBar.propTypes = {
  departmentName: PropTypes.string.isRequired,
  statusId: PropTypes.number.isRequired,
  fragility: PropTypes.string.isRequired,
}

export default CardBar
