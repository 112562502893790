import constants from 'constants/index'

const chosenAction = (text) => {
  switch (text) {
    case constants.nonComplianceReports.CONTINUED_CHOSEN_ACTION:
      return 'Continuar'
    case constants.nonComplianceReports.FINALIZED_CHOSEN_ACTION:
      return 'Finalizar'
    case constants.nonComplianceReports.ELIMINATED_CHOSEN_ACTION:
      return 'Eliminar'
    default:
      return ''
  }
}

export default chosenAction
