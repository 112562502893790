import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Card,
  Grid,
  Tab,
  TablePagination,
  Tabs,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Plus as PlusIcon } from 'react-feather'

import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  LoadingFeedback,
  Page,
  Permitted,
} from 'components'
import { SuppliersTable } from './components'

import { routes } from 'Routes'
import { useFetch, useFilter, usePagination } from 'hooks'
import { useTheme } from '@material-ui/styles'

import constants from 'constants/index'
import * as service from 'service'

import useStyles from './styles'

import helpers from 'helpers'
import { isEmpty } from 'lodash'

const SuppliersMain = () => {
  const filter = useFilter()
  const theme = useTheme()
  const classes = useStyles()
  const history = useHistory()

  const [companies, setCompanies] = useState([])
  const [companyInputValue, setCompanyInputValue] = useState('')
  const [isLoadingCompaniesInput, setIsLoadingCompaniesInput] = useState(false)
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false)
  const [suppliers, setSuppliers] = useState([])
  const [suppliersInputValue, setSuppliersInputValue] = useState('')
  const [isLoadingSuppliersInput, setIsLoadingSuppliersInput] = useState(false)
  const [isLoadingSuppliers, setIsLoadingSuppliers] = useState(false)
  const [segments, setSegments] = useState([])
  const [users, setUsers] = useState([])

  const {
    loadCompanies,
    mountCompanyParams,
    mountSupplierParams,
    instructionsText,
  } = helpers.companies.typeAndSearch

  const { nameWithDocumentByArrayObject } = helpers.companies.nameWithDocument

  const companiesOptionsValues = nameWithDocumentByArrayObject(
    isLoadingCompaniesInput ? [] : companies,
  )

  const suppliersOptionsValues = nameWithDocumentByArrayObject(
    isLoadingSuppliersInput ? [] : suppliers,
  )

  const handleTypeSuppliersInput = (e) => {
    setSuppliersInputValue(e.target.value)
  }

  const handleTypeCompaniesInput = (e) => {
    setCompanyInputValue(e.target.value)
  }

  useEffect(() => {
    const getCompanies = setTimeout(() => {
      const hasCompanyValueToFetch =
        companyInputValue.length < 3 && isEmpty(filter.filters?.companyId)

      if (hasCompanyValueToFetch || !filter.drawerOpen) return

      loadCompanies(
        mountCompanyParams(companyInputValue, filter.filters),
        setIsLoadingCompaniesInput,
        setCompanies,
      )
    }, 1000)

    return () => clearTimeout(getCompanies)
  }, [companyInputValue])

  useEffect(() => {
    const getSuppliers = setTimeout(() => {
      const hasSuppliersValueToFetch =
        suppliersInputValue.length < 3 && isEmpty(filter.filters?.supplierId)

      if (hasSuppliersValueToFetch || !filter.drawerOpen) return

      loadCompanies(
        mountSupplierParams(suppliersInputValue, filter.filters),
        setIsLoadingSuppliersInput,
        setSuppliers,
      )
    }, 1000)

    return () => clearTimeout(getSuppliers)
  }, [suppliersInputValue, filter.filters?.suppliers])

  useEffect(() => {
    if (filter.drawerOpen) {
      if (filter.filters?.companyId) {
        loadCompanies(
          mountCompanyParams(companyInputValue, filter.filters),
          setIsLoadingCompanies,
          setCompanies,
        )
      }

      if (filter.filters?.suppliersId) {
        loadCompanies(
          mountSupplierParams(suppliersInputValue, filter.filters),
          setIsLoadingSuppliers,
          setSuppliers,
        )
      }
    } else {
      if (companyInputValue) setCompanyInputValue('')
      if (suppliersInputValue) setSuppliersInputValue('')
    }
  }, [filter.drawerOpen])

  useEffect(() => {
    const getSegments = async () => {
      const response = await service.dponet.segments.get({
        perPage: 100000,
        active: true,
        order: 'name ASC',
      })
      setSegments(response?.data?.segments)
    }

    const getUsers = async () => {
      let response = await service.dponet.user.get({
        perPage: 100000,
        active: true,
        minimal: true,
        order: 'name ASC',
      })
      setUsers(
        response?.data?.users?.map((user) => ({
          ...user,
          name: `${user?.name} - ${user?.email}`,
        })),
      )
    }

    getSegments()
    getUsers()
  }, [])

  const [currentTab, setCurrentTab] = useState(
    constants.companySuppliers.ALL_TAB,
  )

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const handleChangeTabs = (event, value) => {
    setCurrentTab(value)
  }

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading } = useFetch(
    service.dponet.companySuppliers.get,
    {
      ...{ ...filter.filters },
      status:
        currentTab === constants.companySuppliers.ALL_TAB ? {} : currentTab,
      perPage,
      page,
    },
    [page, perPage, filter.filters, currentTab],
  )

  return (
    <Page title="Fornecedores">
      <LoadingFeedback open={isLoading} />
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Listagem"
          menu="Fornecedores"
          subtitle="Exibição dos fornecedores"
        >
          <FilterButton setDrawerOpen={filter.setDrawerOpen} />
        </ContentHeader>
        <Tabs
          scrollButtons="on"
          onChange={handleChangeTabs}
          textColor="primary"
          indicatorColor="primary"
          value={currentTab || constants.companySuppliers.ALL_TAB}
          className={classes.borderBottom}
          variant="scrollable"
        >
          {constants.companySuppliers.TABS.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={<Box>{tab.label}</Box>}
            />
          ))}
        </Tabs>
        <Card>
          <Box
            display="flex"
            width="100%"
            p={0}
            className={classes.borderBottom}
          >
            <Grid container>
              <Grid item xs={6}>
                <Box
                  width="100%"
                  p={2}
                  height="100%"
                  display="flex"
                  alignItems="center"
                >
                  <Typography variant="h5">Fornecedores</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box p={2} display="flex" justifyContent="flex-end">
                  <Permitted
                    tag={constants.permissions.COMPANY_SUPPLIERS.UPDATE}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<PlusIcon />}
                      onClick={() => history.push(routes.suppliers.new)}
                    >
                      Criar
                    </Button>
                  </Permitted>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <SuppliersTable suppliers={response?.data?.companySuppliers} />
          <Box px={2} display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Card>
        <Filters
          filter={filter}
          isLoading={isLoadingCompanies || isLoadingSuppliers}
        >
          <input textfieldinput="true" label="Identificador" name="id" />
          <select
            autocompleteselectinput="true"
            label="Nome Empresa Contratante"
            name="companyId"
            loading={isLoadingCompaniesInput}
            onChangeTypeSearch={handleTypeCompaniesInput}
            optionvalues={companiesOptionsValues}
            noOptionsText={instructionsText(companyInputValue)}
          />
          <input
            textfieldinput="true"
            label="Documento da Empresa Contratante"
            name="companyDocument"
          />
          <select
            autocompleteselectinput="true"
            label="Nome Fornecedor"
            name="supplierId"
            loading={isLoadingSuppliersInput}
            onChangeTypeSearch={handleTypeSuppliersInput}
            optionvalues={suppliersOptionsValues}
            noOptionsText={instructionsText(suppliersInputValue)}
          />
          <input
            textfieldinput="true"
            label="Documento do Fornecedor"
            name="supplierDocument"
          />
          <input
            autocompleteselectinput="true"
            label="Segmento do fornecedor"
            name="supplier_segment_id"
            optionvalues={segments}
          />
          <input
            autocompleteselectinput="true"
            label="Responsável pela criação do vínculo"
            name="author_id"
            optionvalues={users}
          />
        </Filters>
      </Container>
    </Page>
  )
}

export default SuppliersMain
