import constants from 'constants/index'
import helpers from 'helpers'

const discountFormatter = (discountType, discountValue) => {
  if (discountType === constants.discountCoupon.PERCENTAGE_TYPE)
    return `${helpers.formatters.percentage(discountValue)}`

  return `${helpers.formatters.currencyInput(discountValue)}`
}

export default discountFormatter
