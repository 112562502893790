/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { Filters } from 'components'

import helpers from 'helpers'
import * as service from 'service'

const CollaboratorFilter = ({ filter }) => {
  const [profiles, setProfiles] = useState([])
  const [profileInputValue, setProfileInputValue] = useState('')
  const [isLoadingProfilesInput, setIsLoadingProfilesInput] = useState(false)
  const [isLoadingProfiles, setIsLoadingProfiles] = useState(false)

  const handleTypeProfilesInput = (e) => {
    setProfileInputValue(e.target.value)
  }

  const { instructionsText } = helpers.companies.typeAndSearch

  const profilesOptionsValues = isLoadingProfilesInput ? [] : profiles
  const { filters, drawerOpen } = filter

  const mountProfilesParams = () => {
    const defaultParams = {
      perPage: 1000 * 1000,
    }

    if (!!profileInputValue) {
      return {
        name: profileInputValue,
        ...defaultParams,
      }
    }

    return {
      id: filters?.profileId,
      ...defaultParams,
    }
  }

  const loadProfiles = async (setIsLoading) => {
    setIsLoading(true)

    const response = await service.dponet.collaboratorProfiles.get(
      mountProfilesParams(),
    )
    const responseSegments = response.data?.profiles || []

    setProfiles(responseSegments)

    setIsLoading(false)

    return responseSegments
  }

  useEffect(() => {
    const getProfiles = setTimeout(() => {
      const hasProfileValueToFetch =
        profileInputValue.length < 3 && isEmpty(filters?.profileId)

      if (hasProfileValueToFetch || !drawerOpen) return

      loadProfiles(setIsLoadingProfilesInput)
    }, 1000)

    return () => clearTimeout(getProfiles)
  }, [profileInputValue])

  useEffect(() => {
    if (filters?.profileId) {
      loadProfiles(setIsLoadingProfiles)
    }
  }, [filters?.profileId])

  useEffect(() => {
    if (!drawerOpen && profileInputValue) setProfileInputValue('')
  }, [drawerOpen])

  return (
    <Filters filter={filter} isLoading={isLoadingProfiles}>
      <input textfieldinput="true" label="Identificador" name="id" />
      <input textfieldinput="true" label="Nome" name="name" />
      <input textfieldinput="true" label="E-mail" name="email" />
      <select
        autocompleteselectinput="true"
        label="Perfil"
        name="profileId"
        loading={isLoadingProfilesInput}
        onChangeTypeSearch={handleTypeProfilesInput}
        optionvalues={profilesOptionsValues}
        noOptionsText={instructionsText(profileInputValue)}
      />
    </Filters>
  )
}

export default CollaboratorFilter
