import { useState } from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import { Chip, Button, Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import {
  Edit as EditIcon,
  Eye as ViewIcon,
  EyeOff as InactiveIcon,
} from 'react-feather'

import { MenuButton, Permitted, BaseDialog } from 'components'
import { TableRow, TableCell } from 'components/Table'
import { FaqInactiveOrActiveDialog } from './components'

import helpers from 'helpers'
import constants from 'constants/index'
import { routes } from 'Routes'
import useStyles from './styles'

const FaqTableRow = ({ faq, refresh }) => {
  const classes = useStyles()
  const history = useHistory()

  const [openModalView, setOpenModalView] = useState(false)
  const [openModalActiveOrInactive, setOpenModalActiveOrInactive] =
    useState(false)

  const navigateToEdit = (faqsId) => {
    history.push(
      reverse(routes.faqs.edit, {
        faqsDocumentationId: faqsId,
      }),
    )
  }

  const ModalViewFaqs = () => {
    setOpenModalView(true)
  }

  const handleCloseView = () => {
    setOpenModalView(false)
  }

  const ModalActiveOrInactive = () => {
    setOpenModalActiveOrInactive(true)
  }

  const descriptionSanitizer = DOMPurify.sanitize(faq?.description, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  })

  return (
    <>
      <TableRow key={faq?.id}>
        <TableCell>{faq?.id}</TableCell>
        <TableCell>{faq?.title}</TableCell>
        <TableCell>{faq?.publishedBy}</TableCell>
        <TableCell disableTooltip>
          <Chip
            size="medium"
            className={classes.chip}
            label={faq?.faqsDocumentationsCategory?.name}
          />
        </TableCell>
        <TableCell align="center">
          {helpers.faqsDocumentations.visualizationLabel(faq?.visualization)}
        </TableCell>
        <TableCell align="center">
          {helpers.formatters.date(faq?.updatedAt)}
        </TableCell>
        <TableCell disableTooltip align="center">
          <MenuButton>
            <Permitted
              someTags={[constants.permissions.FAQS_DOCUMENTATIONS.EDIT]}
            >
              <Permitted tag={constants.permissions.FAQS_DOCUMENTATIONS.EDIT}>
                <Button
                  startIcon={<EditIcon className={classes.icons} />}
                  onClick={() => {
                    navigateToEdit(faq?.id)
                  }}
                >
                  EDITAR
                </Button>
                <Button
                  startIcon={<ViewIcon className={classes.icons} />}
                  onClick={ModalViewFaqs}
                >
                  VISUALIZAR
                </Button>
                <Button
                  tag={constants.permissions.FAQS_DOCUMENTATIONS.EDIT}
                  startIcon={
                    faq?.active && <InactiveIcon className={classes.icons} />
                  }
                  onClick={ModalActiveOrInactive}
                >
                  {faq?.active ? 'INATIVAR' : 'ATIVAR'}
                </Button>
              </Permitted>
            </Permitted>
          </MenuButton>
        </TableCell>
      </TableRow>
      <BaseDialog
        open={openModalView}
        setOpen={setOpenModalView}
        title={faq?.title}
        chipLabel={faq?.tag}
        actionButton={handleCloseView}
        withoutBottomDivider={true}
        closeIconButton={true}
        dialogSize="md"
      >
        <Box
          fontFamily="Roboto"
          dangerouslySetInnerHTML={{
            __html: descriptionSanitizer,
          }}
        />
      </BaseDialog>
      <FaqInactiveOrActiveDialog
        open={openModalActiveOrInactive}
        setOpen={setOpenModalActiveOrInactive}
        faq={faq}
        refresh={refresh}
      />
    </>
  )
}

FaqTableRow.propTypes = {
  faq: PropTypes.object,
  refresh: PropTypes.func,
}

FaqTableRow.defaultProps = {
  refresh: () => {},
}

export default FaqTableRow
