import { useCallback, useState } from 'react'
import { Controller } from 'react-hook-form'

import PropTypes from 'prop-types'

import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'

import { Upload as UploadIcon } from 'react-feather'

import { useDropzone } from 'react-dropzone'

import theme from 'theme'

import useStyles from './styles'

const GenerateContract = ({
  control,
  errors,
  setPlatformContract = () => {},
  disabled = false,
  platformContract = null,
  setFile,
}) => {
  const classes = useStyles()

  const [generateContract, setGenerateContract] = useState(
    platformContract !== null || false,
  )
  const [fileName, setFileName] = useState(null)

  const onChangeGenerateContract = (checked) => {
    setPlatformContract(null)
    setFile(null)
    setFileName(null)
    setGenerateContract(checked)
    return checked
  }

  const onChangeContractKind = (e) => setPlatformContract(e.target.value)
  const onDrop = useCallback((acceptedFile) => {
    const file = acceptedFile[0]
    setFile(file)
    setFileName(file?.name)
    // eslint-disable-next-line
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['application/pdf', '.pdf'],
  })

  const { ref, ...rootProps } = getRootProps()

  return (
    <Paper
      component={Box}
      display="flex"
      width="100%"
      flexDirection="column"
      gridGap={theme.spacing(2)}
      p={2}
      mb={2}
    >
      <FormControlLabel
        control={
          <Controller
            as={
              <Switch
                defaultChecked={generateContract}
                color="primary"
                disabled={disabled}
              />
            }
            name="generateContract"
            control={control}
            onChange={([, value]) => onChangeGenerateContract(value)}
          />
        }
        label="Gerar um contrato jurídico"
      />
      {generateContract && (
        <Box mt={-1}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="clientEmail"
                mode="onBlur"
                type="text"
                as={
                  <TextField
                    label="E-mail de quem assinará pelo cliente"
                    fullWidth
                    variant="outlined"
                    error={!!errors?.clientEmail}
                    helperText={errors?.clientEmail?.message}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                onChange={onChangeContractKind}
                defaultValue={platformContract}
              >
                <FormControlLabel
                  disabled={disabled}
                  value="platform_and_dpo"
                  control={<Radio color="primary" size="small" />}
                  label="Plataforma + Serviço de DPO"
                />
                <FormControlLabel
                  disabled={disabled}
                  value="platform_only"
                  control={<Radio color="primary" size="small" />}
                  label="Apenas plataforma"
                />
                <FormControlLabel
                  disabled={disabled}
                  value="custom"
                  control={<Radio color="primary" size="small" />}
                  label="Enviar contrato"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </Box>
      )}
      {platformContract === 'custom' && (
        <Box
          {...rootProps}
          className={classes.inputContainer}
          mb={3}
          rootref={ref}
        >
          <input {...getInputProps()} />
          <Box m={1} mt={2}>
            <UploadIcon />
          </Box>
          <Box m={1} mb={2}>
            <Typography>
              {fileName
                ? fileName
                : 'Arraste e solte ou selecione o contrato. Dê prioridade para o formato em PDF.'}
            </Typography>
          </Box>
        </Box>
      )}
      <Typography>
        <b>Atenção!</b> Esta opção de geração de contrato só deve ser utilizada
        caso a organização não aceite apenas os <b>Termos de Uso</b> como prova
        da contratação da DPOnet.
      </Typography>
      <Typography>
        Nos casos em que houver negociação de <b>condições especiais</b> de
        contrato, é necessário acionar o departamento jurídico para elaborá-lo.
      </Typography>
    </Paper>
  )
}

GenerateContract.propTypes = {
  setPlatformContract: PropTypes.func,
  disabled: PropTypes.bool,
  platformContract: PropTypes.string,
}

export default GenerateContract
