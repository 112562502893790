import {
  Card,
  CardHeader,
  Divider,
  Box,
  Grid,
  Typography,
  Chip,
} from '@material-ui/core'

import PropTypes from 'prop-types'

import helpers from 'helpers'

import useStyles from './styles'

const InfoCard = ({ title, info, classes }) => (
  <Box className={classes.infoCard}>
    <Typography variant="h6">{title}</Typography>
    <Box mt={1}>{info}</Box>
  </Box>
)

const GeneralInformation = ({ rmc }) => {
  const classes = useStyles()
  return (
    <Card>
      <CardHeader title="Informações gerais" />
      <Divider />
      <Box my={2.5} mx={2.5}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <InfoCard
              classes={classes}
              title="Empresa"
              info={<Typography>{rmc?.company?.name}</Typography>}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InfoCard
              classes={classes}
              title="Status"
              info={
                <Chip
                  size="small"
                  className={helpers.nonComplianceReports.statusChipColor(
                    rmc.status,
                    classes,
                  )}
                  label={helpers.nonComplianceReports.status(rmc.status)}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InfoCard
              classes={classes}
              title="Prazo"
              info={
                <Typography>
                  {helpers.formatters.date(rmc.deadlineDate) || 'Não definido'}
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InfoCard
              classes={classes}
              title="Criado em"
              info={
                <Typography>
                  {helpers.formatters.date(rmc.createdAt)}
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InfoCard
              classes={classes}
              title="Responsável"
              info={
                <Typography>
                  {rmc?.responsableUser?.name ||
                    helpers.nonComplianceReports.getResponsibleNames(rmc) ||
                    'Sem responsável'}
                </Typography>
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InfoCard
              classes={classes}
              title="Departamento"
              info={
                <Typography>
                  {rmc?.department?.name || rmc?.questionnaireName || '-'}
                </Typography>
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

GeneralInformation.propTypes = {
  rmc: PropTypes.object,
}

export default GeneralInformation
