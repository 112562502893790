import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() => ({
  root: {
    width: '100% !important',
  },
  boxStartIcon: {
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.8',
    },
  },
}))

export default styles
