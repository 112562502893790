import * as yup from 'yup'

const schema = yup.object().shape({
  contractIdentifier: yup.string().required(),
  typeService: yup.string().required().typeError('Escolha um tipo de serviço'),
  vertical: yup.string().required().typeError('Escolha uma vertical'),
  generateContract: yup.boolean(),
  clientEmail: yup.string().when('generateContract', {
    is: true,
    then: yup.string().required(),
  }),
  partnerId: yup.string().when('isPartner', {
    is: true,
    then: yup.string().required().typeError('Selecione um parceiro'),
  }),
})

export default schema
