import useSnackbar from 'hooks/useSnackbar'
import PropTypes from 'prop-types'

import { ConfirmationDialog, LoadingFeedback } from 'components'

import { getBaseURL } from 'service/env'
import * as service from 'service'
import { useState } from 'react'
import helpers from 'helpers'

const UserExportDialog = ({ filters, open, setOpen, refresh, userClient }) => {
  const snackbar = useSnackbar()

  const [isLoading, setIsloading] = useState(false)

  const onSubmit = async () => {
    try {
      setIsloading(true)

      const response = userClient
        ? await service.dponet.user.exportArchive({
            ...filters,
            perPage: 10000000,
          })
        : await service.dponet.userCollaborators.exportArchive({
            showSelf: true,
            ...filters,
            perPage: 10000000,
          })

      window.open(`${getBaseURL('dponet')}/${response?.data?.url}`, '_blank')

      setIsloading(false)
      snackbar.open({
        message: 'Relatório foi gerado com sucesso!',
        variant: 'success',
      })
      setOpen(false)
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Falha na geração do relatório!',
        variant: 'error',
      })
      setIsloading(false)
      setOpen(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <ConfirmationDialog
        open={open}
        setOpen={setOpen}
        message="Você tem certeza que deseja gerar o relatório?"
        buttonText="Confirmar"
        actionAcceptButton={() => onSubmit()}
      />
    </>
  )
}

UserExportDialog.propType = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
  filters: PropTypes.object,
  userClient: PropTypes.bool,
}
UserExportDialog.defaultProps = {
  setOpen: () => {},
  refresh: () => {},
  userClient: false,
}

export default UserExportDialog
