import React, { useState, useEffect } from 'react'
import { Button, Grid } from '@material-ui/core'
import { range } from 'lodash'

import {
  Page,
  ContentHeader,
  Container,
  ConfirmationDialog,
  NoData,
} from 'components'
import {
  DocumentCard,
  DocumentCardSkeleton,
  DocumentFilter,
  DocumentDialog,
} from './components'

import useFilter from 'hooks/useFilter'
import useFetch from 'hooks/useFetch'

import useStyles from './styles'
import * as service from 'service'

const DocumentsMain = () => {
  const classes = useStyles()
  const filter = useFilter()

  const [openDialog, setOpenDialog] = useState(false)
  const [openDestroyDialog, setOpenDestroyDialog] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState({})
  const [action, setAction] = useState('show')

  const {
    response: documentsTemplateResponse,
    refresh,
    isLoading,
  } = useFetch(
    service.dponet.documentsTemplate.get,
    {
      perPage: 1000,
      ...filter.filters,
    },
    [filter.filters],
  )

  const { response: documentCategoriesResponse, refresh: refreshCategories } =
    useFetch(service.dponet.documentsTemplate.getCategories)

  const companyDocuments = documentsTemplateResponse?.data?.documents
  const documentCategories =
    documentCategoriesResponse?.data?.documentCategories

  const resetDocumentState = () => {
    setSelectedDocument({})
  }

  const handleOpenDialog = (action, document) => {
    if (action === 'destroy') setOpenDestroyDialog(true)
    else setOpenDialog(true)

    setSelectedDocument(document)
    setAction(action)
  }

  const handleCloseDialog = () => {
    if (action === 'destroy') setOpenDestroyDialog(false)
    else setOpenDialog(false)
  }

  const reloadDocuments = () => {
    handleCloseDialog()
    refresh()
    refreshCategories()
  }

  const destroyAction = async () => {
    await service.dponet.documentsTemplate.destroy({
      documentId: selectedDocument?.id,
    })
    reloadDocuments()
  }

  useEffect(() => {
    if (!openDialog) resetDocumentState()
  }, [openDialog])

  return (
    <Page title="Documentos">
      <Container maxWidth={false} className={classes.container}>
        <ContentHeader
          menu="Documentos"
          title="Listagem"
          subtitle="Documentos modelo"
        >
          <Button
            color="primary"
            variant="contained"
            className={classes.headerActionButton}
            onClick={() => handleOpenDialog('create', {})}
          >
            Novo documento
          </Button>
          <DocumentFilter filter={filter} categories={documentCategories} />
        </ContentHeader>
        <Grid container spacing={3}>
          {isLoading
            ? range(8).map(() => <DocumentCardSkeleton />)
            : companyDocuments?.map((document) => (
                <DocumentCard
                  key={document.id}
                  document={document}
                  handleOpenDialog={handleOpenDialog}
                />
              ))}
        </Grid>
      </Container>

      <DocumentDialog
        action={action}
        document={selectedDocument}
        onClose={handleCloseDialog}
        open={openDialog}
        categories={documentCategories || []}
        reloadDocuments={reloadDocuments}
      />
      <ConfirmationDialog
        open={openDestroyDialog}
        setOpen={setOpenDestroyDialog}
        message="Você tem certeza que deseja excluir o documento?"
        buttonText="Excluir"
        cancelButtonText="Cancelar"
        actionAcceptButton={destroyAction}
      />
      {companyDocuments?.length === 0 && (
        <Grid item sm={12}>
          <NoData message="Nenhum documento modelo foi encontrado." />
        </Grid>
      )}
    </Page>
  )
}

export default DocumentsMain
