import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
  },
  checked: {
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.brightBlue,
    borderWidth: '2px',
  },
  noBorder: {
    border: '0px !important',
  },
  content: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}))

export default styles
