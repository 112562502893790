import PropTypes from 'prop-types'

import { useState, useRef } from 'react'

import { Box, Menu, IconButton, Button, makeStyles } from '@material-ui/core'

import { MoreVertical } from 'react-feather'

import styles from './styles'

const useStyles = makeStyles(styles)

const MenuButton = ({ iconButton, children, disabled, ...rest }) => {
  const actionRef = useRef(null)
  const [isMenuOpen, setMenuOpen] = useState(false)

  const classes = useStyles()

  return (
    <Box minHeight="48px">
      {!disabled && (
        <>
          <Menu
            anchorEl={actionRef.current}
            onClose={() => setMenuOpen(false)}
            open={isMenuOpen}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            {...rest}
          >
            {isMenuOpen && (
              <Box className={classes.itemsContainer}>{children}</Box>
            )}
          </Menu>
          {iconButton ? (
            <IconButton ref={actionRef} onClick={() => setMenuOpen(true)}>
              <MoreVertical />
            </IconButton>
          ) : (
            <Button
              variant="contained"
              color="primary"
              ref={actionRef}
              onClick={() => setMenuOpen(true)}
            >
              Opções
            </Button>
          )}
        </>
      )}
    </Box>
  )
}

MenuButton.propTypes = {
  iconButton: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
}

MenuButton.defaultProps = {
  iconButton: true,
}

export default MenuButton
