import useAuth from './useAuth'

const usePermissions = () => {
  const { permissions } = useAuth()

  const permitted = (tag) => {
    const permission = permissions?.find((permission) => permission.tag === tag)

    if (!permission) {
      return true
    }

    return permission.status
  }

  const some = (tags) => tags.some(permitted)

  return { permitted, some }
}

export default usePermissions
