import api from 'service/api'
import axios from 'axios'
import { getBaseURL } from '../../env'

import * as service from 'service'

const dponetAPI = api.create('dponet')
const baseURL = getBaseURL('dponet')

const deleteDocument = async ({ dataProcessesId, documentName, ...params }) => {
  return await dponetAPI.delete(
    `data_processes/${dataProcessesId}/destroy_document?document_name=${documentName}`,
    { params },
  )
}

const deleteRncDocument = async ({
  nonComplianceReportId,
  documentName,
  ...params
}) => {
  return await dponetAPI.delete(
    `non_compliance_reports/${nonComplianceReportId}/destroy_document`,
    {
      params: {
        documentName,
        ...params,
      },
    },
  )
}

const addDocument = async ({ dataProcessId, files }) => {
  var documentFormData = new FormData()

  for (var [key, value] of Object.entries(files)) {
    documentFormData.append(`document-${key}`, value)
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/data_processes/${dataProcessId}/add_document`,
    data: documentFormData,
    ...config,
  })
}

const addDocumentRnc = async ({ nonComplianceReportId, files }) => {
  var documentFormData = new FormData()

  for (var [key, value] of Object.entries(files)) {
    documentFormData.append(`document-${key}`, value)
  }

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/non_compliance_reports/${nonComplianceReportId}/add_document`,
    data: documentFormData,
    ...config,
  })
}

const documents = {
  deleteDocument,
  deleteRncDocument,
  addDocument,
  addDocumentRnc,
}

export default documents
