import Main from './PaymentOrdersMain'

import Show from './PaymentOrdersShow'

const PaymentOrders = {
  Main,
  Show,
}

export default PaymentOrders
