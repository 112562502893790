import { Card, CardHeader, Divider, Box, Typography } from '@material-ui/core'

import useDataProcess from 'hooks/useDataProcess'

import useStyles from '../../styles'

const Finality = () => {
  const { data } = useDataProcess()

  const classes = useStyles()

  return (
    <Card>
      <CardHeader title="Por que o dado é tratado?" />
      <Divider />
      <Box className={classes.root}>
        <Typography>{data.finality}</Typography>
      </Box>
    </Card>
  )
}

export default Finality
