import { Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'

import { Database as DatabaseIcon } from 'react-feather'

import useStyles from './styles'

const TitleSection = ({ title, subtitle, icon: Icon }) => {
  const classes = useStyles()

  return (
    <Box display="flex" alignItems="center" mb={2}>
      <Box
        mr={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.boxIcon}
      >
        <Icon size={20} />
      </Box>
      <Box>
        <Typography variant="h4">{title}</Typography>
        {!!subtitle && <Typography variant="body2">{subtitle}</Typography>}
      </Box>
    </Box>
  )
}

TitleSection.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.any,
}

TitleSection.defaultProps = {
  icon: DatabaseIcon,
}

export default TitleSection
