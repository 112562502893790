import permissions from '../../permissions'

const SEGMENT_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.SEGMENTS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.CREATE,
    tag: permissions.SEGMENTS.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.EDIT,
    tag: permissions.SEGMENTS.EDIT,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.DESTROY,
    tag: permissions.SEGMENTS.DESTROY,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.ACTIVATE_OR_INACTIVATE,
    tag: permissions.SEGMENTS.ACTIVATE_OR_INACTIVATE,
  },
]

export default SEGMENT_LIST
