import { useState, useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'
import PropTypes from 'prop-types'
import { Tabs, Tab, Box, Divider, Button } from '@material-ui/core'

import { Container } from 'components'
import GeneralInformation from '../GeneralInformation'
import DataCollecteds from '../DataCollecteds'
import DataTreatments from '../DataTreatments'
import LifeCycles from '../LifeCycles'
import DocumentedInformation from '../DocumentedInformation'
import NecessityProportionality from '../NecessityProportionality'
import ShortcutBar from '../../ShortcutBar'

import { useDataProcess, useRmc } from 'hooks'
import RmcProvider from 'providers/RmcProvider'

import constants from 'constants/index'
import useStyles from './styles'

const TabsDataProcess = ({ loading, setLoading }) => {
  const location = useLocation()
  const history = useHistory()
  const search = qs.parse(location.search)
  const classes = useStyles()

  const {
    nonComplianceReport,
    setSubmitting,
    submitting,
    editLegalFramework,
    reloadEntireDataProcess,
  } = useDataProcess()

  const onSubmit = () => {
    setSubmitting(submitting + 1)
  }
  const { setData } = useRmc()

  useEffect(() => {
    if (nonComplianceReport) {
      setData(nonComplianceReport)
    }
    // eslint-disable-next-line
  }, [])

  const [currentTab, setCurrentTab] = useState(
    search.tab || constants.dataProcess.TAB_VIEW_VALUE.GENERAL,
  )

  const replaceLocation = (origin) => {
    history.replace(`${location.pathname}?tab=${origin}`)
  }

  const handleTabsChange = (value) => {
    setCurrentTab(value)
    replaceLocation(value)
  }

  const legalFrameworkRef = useRef(null)

  const executeScroll = () =>
    legalFrameworkRef?.current?.scrollIntoView({
      behavior: 'smooth',
    })

  return (
    <>
      <ShortcutBar
        handleTabsChange={handleTabsChange}
        executeScroll={executeScroll}
        onEvent={reloadEntireDataProcess}
        loading={loading}
        setLoading={setLoading}
      />
      <Box className={classes.headerWhite}>
        <Container
          maxWidth={false}
          spacingXl={20}
          spacingLg={15}
          spacingMd={10}
          spacingSm={5}
          spacingXs={5}
          className={classes.container}
        >
          <Box width="100%" display="flex" justifyContent="center">
            <Tabs
              onChange={handleTabsChange}
              scrollButtons="auto"
              value={currentTab}
              variant="scrollable"
              textColor="secondary"
            >
              {constants.dataProcess.TABS_VIEW.map((tab) => (
                <Tab
                  className={classes.root}
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  onClick={() => handleTabsChange(tab.value)}
                />
              ))}
            </Tabs>
          </Box>
          <Divider />
        </Container>
      </Box>
      <RmcProvider>
        <Container
          maxWidth={false}
          spacingXl={20}
          spacingLg={15}
          spacingMd={10}
          spacingSm={5}
          spacingXs={5}
          className={classes.container}
        >
          <Box mt={2}>
            {currentTab === constants.dataProcess.TAB_VIEW_VALUE.GENERAL && (
              <GeneralInformation legalFrameworkRef={legalFrameworkRef} />
            )}
            {currentTab ===
              constants.dataProcess.TAB_VIEW_VALUE.DATA_COLLECTS && (
              <DataCollecteds />
            )}
            {currentTab ===
              constants.dataProcess.TAB_VIEW_VALUE.LIFE_CYCLES && (
              <LifeCycles />
            )}
            {currentTab ===
              constants.dataProcess.TAB_VIEW_VALUE.DATA_TREATMENTS && (
              <DataTreatments />
            )}
            {currentTab === constants.dataProcess.TAB_VIEW_VALUE.DOCUMENTS && (
              <DocumentedInformation />
            )}
            {currentTab ===
              constants.dataProcess.TAB_VIEW_VALUE
                .NECESSITY_PROPORTIONALITY && <NecessityProportionality />}
          </Box>
        </Container>
        <Container
          maxWidth={false}
          spacingXl={20}
          spacingLg={15}
          spacingMd={10}
          spacingSm={5}
          spacingXs={5}
          className={classes.container}
        >
          {editLegalFramework && (
            <Box display="flex" my={2}>
              <Button
                color="primary"
                variant="contained"
                onClick={onSubmit}
                id="submit-button-data-process"
              >
                Salvar
              </Button>
            </Box>
          )}
        </Container>
      </RmcProvider>
    </>
  )
}

TabsDataProcess.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
}

TabsDataProcess.defaultProps = {
  loading: false,
  setLoading: () => {},
}

export default TabsDataProcess
