import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import { Label } from 'components'
import useStyles from '../../styles'
import PropTypes from 'prop-types'
import helpers from 'helpers'
import constants from 'constants/index'

const CouponCard = ({ coupon }) => {
  const classes = useStyles()

  return (
    <Card>
      <CardHeader title="Dados do cupom" />
      <Divider />
      <Box className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Label title="Código">
              <Typography>{coupon?.code}</Typography>
            </Label>
          </Grid>
          <Grid item xs={6}>
            <Label title="Tipo do desconto">
              <Typography>
                {helpers.discountCoupon.discountType(coupon?.discountType)}
              </Typography>
            </Label>
          </Grid>
          <Grid item xs={6}>
            <Label title="Valor do desconto">
              <Typography>
                {helpers.discountCoupon.discountValue(
                  coupon?.discountType,
                  coupon?.discountValue,
                )}
              </Typography>
            </Label>
          </Grid>
          <Grid item xs={6}>
            <Label title="Representante Comercial">
              <Typography>{coupon?.responsibleName || '-'}</Typography>
            </Label>
          </Grid>
          <Grid item xs={6}>
            <Label title="Destinatário do cupom">
              <Typography>
                {coupon?.document
                  ? constants.discountCoupon.ONE_USES_LABEL
                  : constants.discountCoupon.MULTI_USES_LABEL}
              </Typography>
            </Label>
          </Grid>
          <Grid item xs={6}>
            <Label title="Validade">
              <Box display="flex">
                <Box mr={4}>
                  <Typography>
                    Inicio: {helpers.formatters.date(coupon?.startDate) || '-'}
                  </Typography>
                </Box>
                <Typography>
                  Fim: {helpers.formatters.date(coupon?.expirationDate) || '-'}
                </Typography>
              </Box>
            </Label>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

CouponCard.propTypes = {
  coupon: PropTypes.object,
}

export default CouponCard
