import PropTypes from 'prop-types'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core'

import logo from 'images/dponet-logo-white.svg'
import styles from './styles'

const useStyles = makeStyles(styles)

const ScreenLoading = ({ loaded, children }) => {
  const classes = useStyles()

  return (
    <>
      <div className={clsx(classes.root, { [classes.rootLoaded]: loaded })}>
        <div className={clsx(classes.bg, { [classes.bgLoaded]: loaded })} />
        <figure
          className={clsx(classes.logo, { [classes.logoLoaded]: loaded })}
        >
          <img alt="Logo" src={logo} width="100%" />
        </figure>
      </div>

      {loaded && children}
    </>
  )
}

ScreenLoading.prototype = {
  loaded: PropTypes.bool.isRequired,
  children: PropTypes.any,
}

export default ScreenLoading
