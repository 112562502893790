import companyContants from 'constants/companies'
const benefits = (demoPartner) => {
  const { partner, member, price } = demoPartner
  return {
    partner:
      partner === companyContants.DEMO_PARTNER_DPONET_TRIAL_KIND
        ? 'Teste gratuito'
        : partner,
    typePartner:
      member === null ? 'Cliente DPOnet' : member ? 'Membro' : 'Parceiro',
    price:
      price > 0
        ? `Parcelas fixa de R$ ${price}`
        : 'Nenhum plano foi vinculado a essa empresa.',
  }
}

export default benefits
