import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ ...params }) => {
  return await dponetAPI.get(`/privacy_policies/`, { params })
}

const show = async ({ privacyPolicyId = '' }) => {
  return await dponetAPI.get(`/privacy_policies/${privacyPolicyId}`)
}

const privacyPolicies = {
  get,
  show,
}

export default privacyPolicies
