import constants from 'constants/index'

const discountType = (discountType) => {
  switch (discountType) {
    case constants.discountCoupon.VALUE_TYPE:
      return constants.discountCoupon.VALUE_LABEL
    case constants.discountCoupon.FIXED_PRICE_TYPE:
      return constants.discountCoupon.FIXED_PRICE_LABEL
    default:
      return constants.discountCoupon.PERCENTAGE_LABEL
  }
}

export default discountType
