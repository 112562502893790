import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Route } from 'react-router-dom'

import * as service from 'service'

import SnackbarProvider from 'providers/SnackbarProvider'
import { routes } from 'Routes'
import { useAuth } from 'hooks'

const DefaultComponent = ({ children }) => <>{children}</>

const RouteWithLayout = ({
  layout,
  provider,
  component: Component,
  permission,
  somePermissions,
  reduceMargin,
  admin,
  redirect,
  auth,
  ...rest
}) => {
  const Layout = layout || DefaultComponent
  const Provider = provider || DefaultComponent
  const isAuthenticated = service.dponet.auth.isAuthenticated()
  const { homePage } = useAuth()

  const routeRedirect = () => {
    if (isAuthenticated) {
      if (!auth && redirect) {
        if (homePage) {
          service.history.push(homePage)
        }
        return null
      }
    }
    if (auth && !isAuthenticated) {
      service.history.push(routes.root)
      return null
    }
  }

  useEffect(() => {
    routeRedirect()

    return () => {}
    //eslint-disable-next-line
  }, [homePage])

  const renderRoute = (matchProps) => {
    return (
      <SnackbarProvider>
        <Layout
          permission={permission}
          somePermissions={somePermissions}
          reduceMargin={reduceMargin}
          admin={admin}
        >
          <Provider component={Provider}>
            <Component {...matchProps} />
          </Provider>
        </Layout>
      </SnackbarProvider>
    )
  }

  return <Route {...rest} render={(matchProps) => renderRoute(matchProps)} />
}

RouteWithLayout.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  path: PropTypes.string,
  permission: PropTypes.string,
  admin: PropTypes.bool,
  somePermissions: PropTypes.array,
  reduceMargin: PropTypes.bool,
  redirect: PropTypes.bool,
  auth: PropTypes.bool,
}

RouteWithLayout.defaultProps = {
  admin: false,
  redirect: true,
  auth: false,
}

export default RouteWithLayout
