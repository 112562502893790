import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ technicalFaqId = '', ...params }) => {
  return await dponetAPI.get(`/technical_faqs/${technicalFaqId}`, {
    params,
  })
}

const create = async ({ ...data }) => {
  return await dponetAPI.post(`/technical_faqs`, data)
}

const update = async ({ technicalFaqId = '', ...data }) => {
  return await dponetAPI.put(`/technical_faqs/${technicalFaqId}`, data)
}

const destroy = async ({ technicalFaqId = '' }) => {
  return await dponetAPI.delete(`/technical_faqs/${technicalFaqId}`)
}

const getCategories = async ({ ...params }) => {
  return await dponetAPI.get(`/knowledge_base_categories`, {
    params,
  })
}

const getSubcategory = async ({ subCategoryId = '', ...params }) => {
  return await dponetAPI.get(`/knowledge_base_subcategories/${subCategoryId}`, {
    params,
  })
}

const createSubcategory = async ({ ...data }) => {
  return await dponetAPI.post(`/knowledge_base_subcategories`, data)
}

const updateSubcategory = async ({ subCategoryId = '', ...data }) => {
  return await dponetAPI.put(
    `/knowledge_base_subcategories/${subCategoryId}`,
    data,
  )
}

const destroySubcategory = async ({ subCategoryId = '' }) => {
  return await dponetAPI.delete(
    `/knowledge_base_subcategories/${subCategoryId}`,
  )
}

const technicalFaqs = {
  get,
  create,
  update,
  destroy,
  getCategories,
  getSubcategory,
  createSubcategory,
  updateSubcategory,
  destroySubcategory,
}

export default technicalFaqs
