import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import { Filters } from 'components'

import * as service from 'service'

const SegmentFilter = ({ filter }) => {
  const [subgroups, setSubgroups] = useState([])
  const [isLoaingSubgroups, setIsLoadingSubgroups] = useState(false)

  const { drawerOpen } = filter

  useEffect(() => {
    const getGroups = async () => {
      setIsLoadingSubgroups(true)

      const groupsResponse = await service.dponet.subgroups.get({
        perPage: 1000 * 1000,
        order: 'name ASC',
      })

      setSubgroups(groupsResponse?.data?.subgroups)
      setIsLoadingSubgroups(false)
    }

    if (drawerOpen && isEmpty(subgroups)) getGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen])

  return (
    <Filters filter={filter} isLoading={isLoaingSubgroups}>
      <input textfieldinput="true" label="Identificador" name="id" />
      <input textfieldinput="true" label="Nome do segmento" name="name" />
      <select
        autocompleteselectinput="true"
        name="subgroupdId"
        label="Subgrupo"
        optionvalues={subgroups}
      />
    </Filters>
  )
}

export default SegmentFilter
