import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Tab,
  TablePagination,
  Tabs,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { Plus as PlusIcon } from 'react-feather'

import {
  Container,
  ContentHeader,
  FilterButton,
  LoadingFeedback,
  Page,
  Permitted,
} from 'components'

import { PlansTable, PlanFilter } from './components'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'
import { routes } from 'Routes'

const PlansMain = () => {
  const filter = useFilter()
  const history = useHistory()

  const [tab, setTab] = useState(constants.plan.ALL_TAB)

  const theme = useTheme()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const transformFilters = (filters) => {
    const Gteq = filters.priceGteq
    const Lteq = filters.priceLteq
    const implementationPrice = filters.implementationPrice

    if (Gteq) {
      filters.priceGteq = helpers.formatters.regularizeCurrency(Gteq)
    }

    if (Lteq) {
      filters.priceLteq = helpers.formatters.regularizeCurrency(Lteq)
    }

    if (implementationPrice) {
      filters.implementationPrice =
        helpers.formatters.regularizeCurrency(implementationPrice)
    }

    return filters
  }

  const { refresh, response, isLoading } = useFetch(
    service.dponet.plans.get,
    {
      ...{ ...transformFilters(filter.filters) },
      ...(tab !== constants.plan.ALL_TAB && {
        status: tab === constants.plan.ACTIVE_TAB,
      }),
      kind: 'normal',
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  const handleChangeTab = (_, value) => {
    setTab(value)
    if (value !== constants.plan.ALL_TAB) {
      return filter.setFilters({
        ...filter.filters,
        active: value === constants.plan.ACTIVE_TAB,
      })
    }

    const filters = filter.filters
    delete filters.active
    filter.setFilters({ ...filters })
  }

  return (
    <>
      <Page title="Planos">
        <LoadingFeedback open={isLoading} />
        <Container maxWidth={false}>
          <ContentHeader
            title="Listagem"
            menu="Planos"
            subtitle="Exibição dos planos"
          >
            <Box ml={1}>
              <FilterButton setDrawerOpen={filter.setDrawerOpen} />
            </Box>
          </ContentHeader>
          <Box
            mt={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
              >
                {constants.plan.TABS.map((tab) => (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                    onClick={(event) => handleChangeTab(event, tab.value)}
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
          <Card>
            <Box width="100%">
              <Grid container>
                <Grid item xs={6}>
                  <Box
                    width="100%"
                    p={2}
                    height="100%"
                    display="flex"
                    alignItems="center"
                  >
                    <Typography variant="h5">Planos</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box p={2} display="flex" justifyContent="flex-end">
                    <Permitted tag={constants.permissions.PLANS.CREATE}>
                      <Button
                        onClick={() => history.push(routes.plans.new)}
                        variant="outlined"
                        color="primary"
                        startIcon={<PlusIcon />}
                      >
                        Criar
                      </Button>
                    </Permitted>
                  </Box>
                </Grid>
              </Grid>
              <Divider />
            </Box>
            <PlansTable plans={response?.data?.plans || []} refresh={refresh} />
            <Box px={2} display="flex" justifyContent="flex-end">
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            </Box>
          </Card>
        </Container>

        <PlanFilter filter={filter} />
      </Page>
    </>
  )
}

export default PlansMain
