import { useState, useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import { isEmpty, isString } from 'lodash'

import {
  Box,
  Typography,
  Grid,
  Tooltip,
  TextField,
  Button,
  makeStyles,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import * as service from 'service'

import { Collapse as CollapseComponent } from 'components'
import { DataProcessSourcesTable } from 'views/DataProcesses/components/'

import useDataProcess from 'hooks/useDataProcess'
import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import constants from 'constants/index'

import styles from '../../../New/styles'
const useStyles = makeStyles(styles)

const Collapse = forwardRef(
  (
    {
      dataSource,
      index,
      sourceLabelOptions,
      allSources,
      handleSourceDataSelected,
      handleOpenedOtherSourceAdd,
      setAllSources,
      setValue,
      errors,
      control,
      isEdit,
      lastOne,
    },
    ref,
  ) => {
    const defaultOpen = allSources.length - 1 === index && !dataSource.id

    useEffect(() => {
      if (defaultOpen && ref.current) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
      //eslint-disable-next-line
    }, [])

    const snackbar = useSnackbar()
    const {
      data,
      dataProcessSources,
      departments,
      payload: formatDataProcess,
    } = useDataProcess()

    const payload = formatDataProcess()

    const classes = useStyles()

    const [sourceOptions, setSourceOptions] = useState([])
    const [destroying, setDestroying] = useState(false)

    const isVisible = constants.dataProcess.SOURCES_WITH_ENTITY.includes(
      dataSource.sourceDescription,
    )

    useEffect(() => {
      if (isVisible) {
        if (dataProcessSources) {
          const source = isString(dataSource.sourceDescription)
            ? helpers.dataProcesses.convertSourceDescriptionToSource(
                dataSource.sourceDescription,
              )
            : dataSource.sourceDescription
          const formattedSources =
            helpers.dataProcesses.mountDataProcessSources(
              dataProcessSources,
              departments,
              source,
            )
          setSourceOptions(formattedSources)
        }
      }
      //eslint-disable-next-line
    }, [])

    const removeOption = (entity) => {
      const currentData = allSources[index]
      const currentSources = currentData?.dataProcessSources

      const dataProcessSources = currentSources.filter(
        (source) => source.entityId !== entity.entityId,
      )

      const newDataSources = allSources.filter(
        (source) => source.sourceDescription !== dataSource.sourceDescription,
      )

      const dataSources = helpers.functions.insertArrayAt(
        newDataSources,
        index,
        {
          sourceDescription: dataSource.sourceDescription,
          dataProcessSources,
        },
      )

      setAllSources(dataSources)
      setValue('dataProcessDataSources', dataSources)
    }

    const removeSource = async () => {
      if (destroying) {
        return
      }

      if (isEdit) {
        setDestroying(true)
        try {
          await service.dponet.dataProcesses.destroyDataProcessDataSource({
            dataProcessId: data?.id || payload.dataProcessId,
            dataProcessDataSourceId: dataSource.id,
            lastOne,
          })
          snackbar.open({
            message: 'Origem dos dados removida com sucesso',
            variant: 'success',
          })
          setDestroying(false)
        } catch (error) {
          snackbar.open({
            message: helpers.formatters.errorMessage(
              error?.response?.data?.error,
            ),
            variant: 'error',
          })
          return setDestroying(false)
        }
      }

      const newDataSources = allSources.filter(
        (source) => source.sourceDescription !== dataSource.sourceDescription,
      )

      setValue('dataProcessDataSources', newDataSources)
      setAllSources(newDataSources)
    }

    const convertedSourceDescription =
      typeof dataSource.sourceDescription === 'number'
        ? helpers.dataProcesses.convertSourceDescriptionToText(
            dataSource.sourceDescription,
          )
        : helpers.dataProcesses.convertSourceDescriptionToSource(
            dataSource.sourceDescription,
          )

    return (
      <CollapseComponent
        title={
          <Typography ref={ref}>
            {sourceLabelOptions.find(
              (source) => source.id === dataSource.sourceDescription,
            )?.name || dataSource.sourceDescription}
          </Typography>
        }
        defaultOpen={defaultOpen && isVisible}
        visibilityChildren={isVisible}
        onRemove={removeSource}
      >
        <Grid container>
          <Grid className={classes.otherInputSource} item xs={12}>
            <Box width="100%">
              <Box my={1}>
                <Tooltip
                  noWrap
                  title={helpers.dataProcesses.labelSource(
                    dataSource.sourceDescription,
                  )}
                  placement="bottom-start"
                >
                  <Typography variant="body2" color="textSecondary">
                    {helpers.dataProcesses.labelSource(
                      dataSource.sourceDescription,
                    )}
                  </Typography>
                </Tooltip>
              </Box>
              <Box
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
              >
                <Box width="100%" mr={3}>
                  <Controller
                    as={
                      <Autocomplete
                        options={sourceOptions}
                        getOptionLabel={(option) => option.entityName}
                        classes={{
                          inputRoot: classes.autoComplete,
                        }}
                        getOptionSelected={(option, value) =>
                          option.entityId === value.entityId ||
                          option.entityId === value.entityName
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={errors?.dataProcessSources?.message}
                            error={!!errors?.dataProcessSources}
                            variant="outlined"
                          />
                        )}
                      />
                    }
                    control={control}
                    rules={{ required: true }}
                    onChange={([, data]) => data}
                    mode="onChange"
                    name={`${constants.dataProcess.DATA_PROCESS_SOURCES_FIELD}-${index}`}
                  />
                </Box>
                <Button
                  onClick={() => handleSourceDataSelected(index)}
                  color="primary"
                  variant="contained"
                >
                  Adicionar
                </Button>
              </Box>
            </Box>
            {!isEmpty(dataSource.dataProcessSources) && (
              <Box pt={2} width="100%">
                <Typography variant="body2" color="textSecondary">
                  {helpers.dataProcesses.labelDataProcessSourcesTable([
                    helpers.dataProcesses.convertSourceKind(
                      convertedSourceDescription,
                    ),
                  ])}
                </Typography>
                <DataProcessSourcesTable
                  dataProcessSources={dataSource.dataProcessSources}
                  removeOption={removeOption}
                  type={helpers.dataProcesses.convertSourceKind(
                    convertedSourceDescription,
                  )}
                />
              </Box>
            )}

            {dataSource.sourceDescription !==
              constants.dataProcess.DEPARTMENT_SOURCE &&
              dataSource.sourceDescription !==
                constants.dataProcess.DEPARTMENT_DESCRIPTION && (
                <Box mt={1}>
                  <Typography variant="body2" color="textSecondary">
                    Não encontrou onde os dados são disponibilizados?{' '}
                    <span
                      onClick={() => handleOpenedOtherSourceAdd(index)}
                      className={classes.otherSource}
                    >
                      Clique aqui
                    </span>{' '}
                    para adicionar
                  </Typography>
                </Box>
              )}
            <Box mt={2} width="100%" display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                color="secondary"
                className={classes.redBackground}
                onClick={removeSource}
              >
                Remover
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CollapseComponent>
    )
  },
)

Collapse.propTypes = {
  dataSource: PropTypes.object.isRequired,
  index: PropTypes.number,
  sourceLabelOptions: PropTypes.array,
  allSources: PropTypes.array,
  handleSourceDataSelected: PropTypes.func,
  handleOpenedOtherSourceAdd: PropTypes.func,
  setAllSources: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  control: PropTypes.object,
  isEdit: PropTypes.bool,
  lastOne: PropTypes.bool,
}

export default Collapse
