import {
  Box,
  Card,
  Divider,
  Grid,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import {
  Container,
  ContentHeader,
  FilterButton,
  LoadingFeedback,
  Page,
} from 'components'
import { PrivacyPolicyFilter } from './components'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'
import PrivacyPoliciesTable from './components/PrivacyPoliciesTable'
import * as service from 'service'

const PrivacyPoliciesMain = () => {
  const filter = useFilter()
  const theme = useTheme()

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading } = useFetch(
    service.dponet.privacyPolicies.get,
    {
      ...filter.filters,
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  return (
    <>
      <Page title="Políticas de privacidade">
        <LoadingFeedback open={isLoading} />
        <Container
          maxWidth={false}
          spacingXl={30}
          spacingLg={25}
          spacingMd={10}
          spacingSm={10}
        >
          <ContentHeader
            title="Listagem"
            menu="Políticas de privacidade"
            subtitle="Exibição das políticas de privacidade"
          >
            <Box ml={1}>
              <FilterButton setDrawerOpen={filter.setDrawerOpen} />
            </Box>
          </ContentHeader>
          <Card>
            <Box width="100%">
              <Grid container>
                <Grid item xs={6}>
                  <Box
                    width="100%"
                    p={2}
                    height="100%"
                    display="flex"
                    alignItems="center"
                  >
                    <Typography variant="h5">
                      Políticas de privacidade
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Divider />
            </Box>
            <PrivacyPoliciesTable
              privacyPolicies={response?.data?.privacyPolicies || []}
            />
            <Box px={2} display="flex" justifyContent="flex-end">
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            </Box>
          </Card>
        </Container>
        <PrivacyPolicyFilter filter={filter} />
      </Page>
    </>
  )
}

export default PrivacyPoliciesMain
