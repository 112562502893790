import { Fragment } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Box,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Switch,
  TextField,
} from '@material-ui/core'

import { Label, Select } from 'components'

import helpers from 'helpers'
import PropTypes from 'prop-types'

import theme from 'theme'
import constants from 'constants/index'

const BenefitsSection = ({ hasBenefits, setHasBenefits, show }) => {
  const { control, errors, watch } = useFormContext()

  const benefits = watch('benefits')

  const isDiscount = benefits === 'discount'
  const isFreeMonthlyFee = benefits === 'freeMonthlyFee'

  const handleChangeSwitch = (event) => setHasBenefits(event?.target?.checked)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControlLabel
          label="Conceder benefícios"
          control={
            <Switch
              color="primary"
              checked={hasBenefits}
              onChange={handleChangeSwitch}
              disabled={show}
            />
          }
          name="hasBenefits"
        />
      </Grid>
      {hasBenefits && (
        <Fragment>
          <Grid item xs={12}>
            <Controller
              as={
                <RadioGroup>
                  <Label title="Benefícios concedidos" />
                  <Box display="flex" gridGap={theme.spacing(2)}>
                    <FormControlLabel
                      label="Desconto na mensalidade"
                      value="discount"
                      control={<Radio color="primary" />}
                      disabled={show}
                    />
                    <FormControlLabel
                      label="Gratuidade na mensalidade"
                      value="freeMonthlyFee"
                      control={<Radio color="primary" />}
                      disabled={show}
                    />
                  </Box>
                </RadioGroup>
              }
              control={control}
              name="benefits"
            />
            {!!errors?.benefits && (
              <FormHelperText error={!!errors?.benefits}>
                {errors?.benefits?.message}
              </FormHelperText>
            )}
          </Grid>
          {isDiscount &&
            constants.churn.DISCOUNT_BENEFITS_FROM_RESULTS_CONTROL_ITEMS.map(
              (item, index) => (
                <Grid key={index} item xs={12} md={3}>
                  <Controller
                    as={
                      <TextField
                        type="text"
                        color="primary"
                        variant="outlined"
                        label={item?.label}
                        error={!!errors?.[item?.controlName]}
                        helperText={<>{errors?.[item?.controlName]?.message}</>}
                        InputProps={{
                          inputMode: 'numeric',
                          startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                          ),
                        }}
                        fullWidth
                        disabled={item?.controlName === 'currentValue' || show}
                      />
                    }
                    control={control}
                    name={item?.controlName}
                    onChange={([event]) =>
                      helpers.formatters.float.input(event?.target?.value)
                    }
                  />
                </Grid>
              ),
            )}
          {isFreeMonthlyFee && (
            <Grid item xs={12} md={3}>
              <Controller
                as={
                  <Select
                    items={constants.churn.QUANTITY_MONTHS_ITEMS}
                    label="Quantidade meses"
                    variant="outlined"
                    error={!!errors?.gratuity}
                    helperText={<>{errors?.gratuity?.message}</>}
                    fullWidth
                    disabled={show}
                  />
                }
                control={control}
                name="gratuity"
                mode="onChange"
              />
            </Grid>
          )}
        </Fragment>
      )}
    </Grid>
  )
}

BenefitsSection.propTypes = {
  hasBenefits: PropTypes.bool,
  setHasBenefits: PropTypes.func,
  show: PropTypes.bool,
}

export default BenefitsSection
