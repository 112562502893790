import React from 'react'
import PropTypes from 'prop-types'
import { TextField, Grid } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'

import { Select as SelectComponent } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import schema from './schema'
import * as service from 'service'
import helpers from 'helpers'

const DocumentForm = ({
  categories,
  document,
  action,
  reloadDocuments,
  setLoading,
}) => {
  const snackbar = useSnackbar()

  const formattedDocumentToDefaultValues = {
    title: document.title,
    link: helpers.formatters.website(document.link),
    privateAccess: document.privateAccess?.toString(),
    documentCategoryId: document.documentCategory?.id,
    otherDocumentCategory: '',
  }
  const defaulDocumentValues = {
    title: '',
    link: '',
    privateAccess: 'false',
    documentCategoryId: '',
    otherDocumentCategory: '',
  }
  const currentDocument = {
    create: defaulDocumentValues,
    edit: formattedDocumentToDefaultValues,
    show: formattedDocumentToDefaultValues,
  }

  const disabledInput = action === 'show'

  const { control, errors, watch, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: { ...currentDocument[action] },
  })

  const onSubmit = async (documentData) => {
    if (disabledInput) return

    setLoading(true)

    try {
      if (action === 'create') await createAction(documentData)
      else await updateAction(documentData)
      reloadDocuments()
    } catch (error) {
      const formattedErrorMessage =
        helpers.formatters.errorMessage(error?.response?.data?.error, false) ||
        'Ocorreu um erro! Tente novamente.'

      snackbar.open({
        message: formattedErrorMessage,
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const updateAction = async (documentData) => {
    await service.dponet.documentsTemplate.put({
      documentId: document?.id,
      ...documentData,
      privateAccess: documentData.privateAccess === 'false',
    })
  }
  const createAction = async (documentData) => {
    await service.dponet.documentsTemplate.create({
      ...documentData,
      privateAccess: documentData.privateAccess === 'false',
    })
  }

  return (
    <form id="document-form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                disabled={disabledInput}
                label="Nome do documento"
                fullWidth
                error={!!errors.title}
                helperText={errors?.title?.message}
              />
            }
            control={control}
            name="title"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                disabled={disabledInput}
                label="Link"
                fullWidth
                error={!!errors.link}
                helperText={errors?.link?.message}
              />
            }
            onChange={([event]) =>
              helpers.formatters.website(event.target.value)
            }
            control={control}
            name="link"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="documentCategoryId"
            control={control}
            as={
              <SelectComponent
                disabled={disabledInput}
                error={!!errors.documentCategoryId}
                fullWidth
                items={categories}
                label="Categoria"
                anotherOption
              />
            }
            mode="onBlur"
          />
        </Grid>
        {watch('documentCategoryId') === 'another' && (
          <Grid item xs={12}>
            <Controller
              name="otherDocumentCategory"
              control={control}
              as={
                <TextField
                  disabled={disabledInput}
                  label="Outra: Categoria"
                  color="primary"
                  variant="outlined"
                  error={!!errors.otherDocumentCategory}
                  helperText={errors?.otherDocumentCategory?.message}
                  fullWidth
                />
              }
              mode="onBlur"
            />
          </Grid>
        )}
      </Grid>
    </form>
  )
}

DocumentForm.propTypes = {
  document: PropTypes.object.isRequired,
  action: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired,
  reloadDocuments: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
}

export default DocumentForm
