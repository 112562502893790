import { Box, Grid, Typography } from '@material-ui/core'

import { NoData } from 'components'
import { DropdownDetails } from '../../'

import useDataProcess from 'hooks/useDataProcess'

import { Share2 as Share2Icon } from 'react-feather'
import helpers from 'helpers'
import { isEmpty } from 'lodash'
import constants from 'constants/index'

const DataTreatments = () => {
  const { data } = useDataProcess()
  const dataTreatments = data.dataTreatments

  const mountInternationalTransfers = (internationalTransfers) => {
    if (isEmpty(internationalTransfers)) return '-'

    let internationalTransferString = ''

    internationalTransfers.forEach((internationalTransfer, index) => {
      if (index === 0) {
        internationalTransferString += internationalTransfer.country
      } else {
        internationalTransferString += `, ${internationalTransfer.country}`
      }
    })

    return internationalTransferString
  }

  const objectToMountBody = (dataTreatment) => {
    return [
      {
        title: 'Com quem',
        data: dataTreatment?.shareProcess?.name,
      },
      {
        title: 'Por que',
        data: dataTreatment?.description,
      },
      {
        title: 'Como',
        data: dataTreatment?.controllMechanism,
      },
      {
        title: 'Tipo',
        data: dataTreatment?.shareType?.name,
      },
      {
        title: 'País(es)',
        data: mountInternationalTransfers(
          dataTreatment?.internationalTransfers,
        ),
      },
      {
        title: 'hasMany',
        data: dataTreatment?.sharedSoftwares?.map((sharedSoftware) => {
          if (
            sharedSoftware?.serverAllocation ===
            constants.sharedSoftware.NATIONAL_SERVER_ALLOCATION_TYPE
          ) {
            return [
              {
                title: 'O servidor é nacional, internacional ou ambos?',
                data: helpers.sharedSoftware.serverAllocationType(
                  sharedSoftware?.serverAllocation,
                ),
              },
            ]
          }
          return [
            {
              title: 'O servidor é nacional, internacional ou ambos?',
              data: helpers.sharedSoftware.serverAllocationType(
                sharedSoftware?.serverAllocation,
              ),
            },
            {
              title:
                'Para realizar este compartilhamento, você utiliza alguma plataforma, software ou aplicativo?',
              data: sharedSoftware?.name,
            },
            {
              title: `O servidor dessa plataforma, software ou aplicativo, é de propriedade 
          da sua empresa ou é contratado, de terceiro?`,
              data: helpers.sharedSoftware.softwareAllocationType(
                sharedSoftware?.softwareAllocationType,
              ),
            },
            {
              title: 'País(es)',
              data: mountInternationalTransfers(
                sharedSoftware?.internationalTransfers,
              ),
            },
          ]
        }),
      },
    ]
  }

  return (
    <Box>
      {!isEmpty(dataTreatments) ? (
        <>
          <Box my={2}>
            <Typography variant="h4">Todos dados compartilhados</Typography>
          </Box>
          <Grid container spacing={2}>
            {dataTreatments?.map((dataTreatment) => (
              <Grid item xs={12} md={6}>
                <DropdownDetails
                  title={dataTreatment?.shareProcess?.name}
                  iconTitle={<Share2Icon />}
                  objectContent={objectToMountBody(dataTreatment)}
                />
              </Grid>
            ))}
          </Grid>
        </>
      ) : (
        <NoData message="Nenhum dado compartilhado encontrado" />
      )}
    </Box>
  )
}

export default DataTreatments
