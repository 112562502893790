import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'

import { LoadingFeedback, Permitted, ConfirmationDialog } from 'components'

import { useSnackbar } from 'hooks'

import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'

const ProfilesChangeStatus = ({ profile, isCollaborator, reloadProfile }) => {
  const changeStatusPermission = isCollaborator
    ? constants.permissions.COLLABORATOR_PROFILES.CHANGE_STATUS
    : constants.permissions.CLIENT_PROFILES.CHANGE_STATUS
  const changeStatusRoute = isCollaborator
    ? service.dponet.collaboratorProfiles.changeStatus
    : service.dponet.userProfiles.changeStatus
  const labelToChangeStatus = profile?.status ? 'inativar' : 'ativar '

  const [openChangeStatusDialog, setOpenChangeStatusDialog] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const snackbar = useSnackbar()

  const handleActionDialog = () => setOpenChangeStatusDialog((open) => !open)

  const changeProfileStatus = async () => {
    setIsLoading(true)
    try {
      handleActionDialog()
      await changeStatusRoute(profile?.id)

      reloadProfile()

      snackbar.open({
        message: 'Status do perfil alterado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
    setIsLoading(false)
  }

  return (
    <Permitted tag={changeStatusPermission}>
      <LoadingFeedback open={isLoading} />
      <Button onClick={handleActionDialog} variant="contained">
        {labelToChangeStatus} perfil
      </Button>

      <ConfirmationDialog
        open={openChangeStatusDialog}
        setOpen={setOpenChangeStatusDialog}
        message={`Você tem certeza que deseja ${labelToChangeStatus} o perfil?`}
        cancelButtonText="Cancelar"
        buttonText="Confirmar"
        actionAcceptButton={changeProfileStatus}
      />
    </Permitted>
  )
}

ProfilesChangeStatus.propTypes = {
  profile: PropTypes.object.isRequired,
  isCollaborator: PropTypes.bool.isRequired,
  reloadProfile: PropTypes.func.isRequired,
}

export default ProfilesChangeStatus
