import PropTypes from 'prop-types'
import { Page, Container, ContentHeader, MenuButton } from 'components'

import { useFetch } from 'hooks'
import * as service from 'service'
import { PlansForm, ActionButtons } from './components'

const PlansEdit = ({ match }) => {
  const {
    params: { planId },
  } = match

  const { response, isLoading, refresh } = useFetch(
    service.dponet.plans.get,
    {
      planId,
    },
    [],
  )

  return (
    <Page title="Edição do plano">
      <Container maxWidth={false}>
        <ContentHeader title="Edição" menu="Plano" subtitle="Edição do plano">
          <MenuButton>
            <ActionButtons plan={response?.data?.plan} refresh={refresh} />
          </MenuButton>
        </ContentHeader>
        {!isLoading && <PlansForm isEdit plan={response?.data?.plan} />}
      </Container>
    </Page>
  )
}

PlansEdit.propTypes = {
  match: PropTypes.object,
}

export default PlansEdit
