import PropTypes from 'prop-types'
import { Box, Paper } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'

import helpers from 'helpers'

const ContractTable = ({ paymentContracts, setContractIds = () => {} }) => {
  const { columns, rows } = helpers.preRegistrations.tableGrid(paymentContracts)

  return (
    <Paper>
      <Box height="500px" width="100%">
        <DataGrid
          rows={rows}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          disableColumnFilter
          hideFooterPagination
          onSelectionModelChange={(ids) => setContractIds(ids)}
          localeText={{
            noRowsLabel: 'Nenhum contrato encontrado',
            columnMenuUnsort: 'Desfazer ordenação',
            columnMenuSortAsc: 'Ordenar de forma ascendente',
            columnMenuSortDesc: 'Ordenar de forma descendente',
            columnMenuHideColumn: 'Ocultar coluna',
            columnMenuShowColumns: 'Mostrar colunas',
          }}
        />
      </Box>
    </Paper>
  )
}

ContractTable.propTypes = {
  paymentContracts: PropTypes.arrayOf(Object),
}

export default ContractTable
