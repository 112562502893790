import { useState } from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import { Chip, Button, Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import {
  Edit as EditIcon,
  Eye as ViewIcon,
  EyeOff as InactiveIcon,
} from 'react-feather'

import { MenuButton, Permitted, BaseDialog } from 'components'
import { TableRow, TableCell } from 'components/Table'
import { DocumentationInactiveOrActiveDialog } from './components'

import helpers from 'helpers'
import constants from 'constants/index'
import { routes } from 'Routes'
import useStyles from './styles'

const DocumentationsTableRow = ({ documentation, refresh }) => {
  const classes = useStyles()
  const history = useHistory()

  const [openModalView, setOpenModalView] = useState(false)
  const [openModalActiveOrInactive, setOpenModalActiveOrInactive] =
    useState(false)

  const navigateToEdit = (faqsDocumentationId) => {
    history.push(
      reverse(routes.documentations.edit, {
        faqsDocumentationId,
      }),
    )
  }

  const ModalViewDocumentation = () => {
    setOpenModalView(true)
  }

  const handleCloseView = () => {
    setOpenModalView(false)
  }

  const ModalActiveOrInactive = () => {
    setOpenModalActiveOrInactive(true)
  }

  const descriptionSanitizer = DOMPurify.sanitize(documentation?.description, {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
  })

  return (
    <>
      <TableRow key={documentation?.id}>
        <TableCell>{documentation?.id}</TableCell>
        <TableCell>{documentation?.title}</TableCell>
        <TableCell>{documentation?.publishedBy}</TableCell>
        <TableCell disableTooltip>
          <Chip
            size="medium"
            className={classes.chip}
            label={documentation?.faqsDocumentationsCategory?.name}
          />
        </TableCell>
        <TableCell align="center">
          {documentation?.onlyCollaborators
            ? 'Colaborador'
            : 'Colaborador e Cliente'}
        </TableCell>
        <TableCell align="center">
          {helpers.formatters.date(documentation?.updatedAt)}
        </TableCell>
        <TableCell disableTooltip align="center">
          <MenuButton>
            <Permitted
              someTags={[constants.permissions.FAQS_DOCUMENTATIONS.EDIT]}
            >
              <Permitted tag={constants.permissions.FAQS_DOCUMENTATIONS.EDIT}>
                <Button
                  startIcon={<EditIcon className={classes.icons} />}
                  onClick={() => {
                    navigateToEdit(documentation?.id)
                  }}
                >
                  EDITAR
                </Button>
                <Button
                  startIcon={<ViewIcon className={classes.icons} />}
                  onClick={ModalViewDocumentation}
                >
                  VISUALIZAR
                </Button>
                <Button
                  tag={constants.permissions.FAQS_DOCUMENTATIONS.EDIT}
                  startIcon={
                    documentation?.active && (
                      <InactiveIcon className={classes.icons} />
                    )
                  }
                  onClick={ModalActiveOrInactive}
                >
                  {documentation?.active ? 'INATIVAR' : 'ATIVAR'}
                </Button>
              </Permitted>
            </Permitted>
          </MenuButton>
        </TableCell>
      </TableRow>
      <BaseDialog
        open={openModalView}
        setOpen={setOpenModalView}
        title={documentation?.title}
        chipLabel={documentation?.tag}
        actionButton={handleCloseView}
        withoutBottomDivider={true}
        closeIconButton={true}
        dialogSize="md"
      >
        <Box
          fontFamily="Roboto"
          dangerouslySetInnerHTML={{
            __html: descriptionSanitizer,
          }}
        />
      </BaseDialog>
      <DocumentationInactiveOrActiveDialog
        open={openModalActiveOrInactive}
        setOpen={setOpenModalActiveOrInactive}
        documentation={documentation}
        refresh={refresh}
      />
    </>
  )
}

DocumentationsTableRow.propTypes = {
  documentation: PropTypes.object,
  refresh: PropTypes.func,
}

DocumentationsTableRow.defaultProps = {
  refresh: () => {},
}

export default DocumentationsTableRow
