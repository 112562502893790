import constants from 'constants/index'

const StatusColor = (status, classes) => {
  switch (status) {
    case constants.questionnaire.ANSWERED_STATUS:
      return classes.answered
    case constants.questionnaire.AWAITING_RESPONSE_STATUS:
      return classes.awaiting_response
    default:
      return classes.partially_answer
  }
}

export default StatusColor
