import {
  Card,
  CardHeader,
  Divider,
  Box,
  Grid,
  Typography,
} from '@material-ui/core'
import useDataProcess from 'hooks/useDataProcess'

import { Label } from 'components'
import useStyles from '../../styles'

const Infos = () => {
  const { data } = useDataProcess()

  const classes = useStyles()

  return (
    <>
      <Card>
        <CardHeader title="Detalhes do processo" />
        <Divider />
        <Box className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Label title="Versão">
                <Typography>{data.version}</Typography>
              </Label>
            </Grid>
            <Grid item xs={12}>
              <Label title="Código">
                <Typography>{data.code}</Typography>
              </Label>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  )
}

export default Infos
