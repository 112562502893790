import PropTypes from 'prop-types'
import { ConfirmationDialog } from 'components'

import helpers from 'helpers'

import * as service from 'service'

import useSnackbar from 'hooks/useSnackbar'

const ChangeStatusOrDestroy = ({
  activity,
  refresh,
  setOpenActivity,
  openActivity,
  isDestroy,
}) => {
  const snackbar = useSnackbar()

  const MESSAGE_CHANGE_STATUS = `Deseja realmente ${
    activity?.active ? 'inativar' : 'ativar'
  } essa atividade econômica?`

  const MESSAGE_DESTROY = 'Deseja realmente excluir essa atividade econômica?'

  const handleChageStatus = async () => {
    try {
      await service.dponet.economicActivities.changeStatus({
        activityId: activity?.id,
      })
      refresh()

      snackbar.open({
        message: `Atividade econômica ${
          activity.active ? 'inativada' : 'ativada'
        } com sucesso.`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          `Erro ao ${
            activity.active ? 'inativar' : 'ativar'
          } a atividade econômica.`,
      })
    }
    setOpenActivity(false)
  }

  const handleDestroy = async () => {
    try {
      await service.dponet.economicActivities.destroy({
        activityId: activity?.id,
      })
      refresh()

      snackbar.open({
        message: 'Atividade econômica excluída com sucesso.',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Erro ao excluir a atividade econômica.',
      })
    }
    setOpenActivity(false)
  }

  return (
    <ConfirmationDialog
      open={openActivity}
      setOpen={setOpenActivity}
      message={isDestroy ? MESSAGE_DESTROY : MESSAGE_CHANGE_STATUS}
      cancelButtonText="Cancelar"
      buttonText="Confirmar"
      actionAcceptButton={isDestroy ? handleDestroy : handleChageStatus}
    />
  )
}

ChangeStatusOrDestroy.propTypes = {
  activity: PropTypes.object.isRequired,
  refresh: PropTypes.func,
  setOpenActivity: PropTypes.func,
  openActivity: PropTypes.bool,
  isDestroy: PropTypes.bool,
}

ChangeStatusOrDestroy.propTypes = {
  refresh: () => {},
  setOpenActivity: () => {},
  openActivity: false,
}

export default ChangeStatusOrDestroy
