import { Fragment } from 'react'
import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'

import { TitleSection, TopicSection, GreyChip } from '../'
import helpers from 'helpers'

const LifeCycles = ({ lifeCycles }) => {
  return (
    <Box m={2}>
      <TitleSection title="Todos salvaguardas e ciclo de vida" />
      {lifeCycles?.map((lifeCycle) => (
        <Fragment key={lifeCycle?.id}>
          <TopicSection text={lifeCycle?.storageLocation?.name} />
          <Box display="flex" flexWrap="wrap">
            <GreyChip
              text="Onde os dados são armazenados"
              complement={lifeCycle?.storageLocation?.name}
            />
            <GreyChip
              text="Ambiente de alocação"
              complement={lifeCycle?.internalAllocationMode?.name}
            />
            <GreyChip
              text="Tempo  de armazenamento"
              complement={`${lifeCycle?.value} ${lifeCycle?.volumetry}`}
            />
            <GreyChip
              text="Como os dados são tratados"
              complement={helpers.dataProcesses.discardMode(lifeCycle)}
            />
            <GreyChip
              text="Forma de proteção"
              complement={lifeCycle?.protection?.name}
            />
            <GreyChip
              text="Justificativa de retenção"
              complement={lifeCycle?.retentionFinality}
            />
          </Box>
        </Fragment>
      ))}
    </Box>
  )
}

LifeCycles.propTypes = {
  lifeCycles: PropTypes.array,
}

LifeCycles.defaultProps = {
  lifeCycles: [],
}

export default LifeCycles
