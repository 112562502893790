import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'

import { Grid, TextField, Box } from '@material-ui/core'
import { DialogLayout } from 'components'
import schema from './schema'

import * as service from 'service'

const FormAccess = ({ open, setOpen, paramsFilter }) => {
  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      email: '',
    },
    validationSchema: schema,
  })
  const snackbar = useSnackbar()

  const onSubmit = async (data) => {
    try {
      await service.dponet.companies.companiesListCsv({
        emailForSend: data.email,
        ...paramsFilter,
      })

      snackbar.open({
        message:
          'Sucesso! O relatório de acessos será enviado ao e-mail informado.',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: 'Erro ao solicitar relatorio!',
        variant: 'error',
      })
    }
    setOpen(false)
  }

  return (
    <DialogLayout
      open={open}
      setOpen={setOpen}
      title="Relatório de acessos"
      confirmTextButton="Confirmar"
      handleSubmit={handleSubmit(onSubmit)}
    >
      <Box width={500}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  label="E-mail"
                  type="email"
                  color="primary"
                  variant="outlined"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  fullWidth
                />
              }
              control={control}
              name="email"
              mode="onBlur"
            />
          </Grid>
        </form>
      </Box>
    </DialogLayout>
  )
}

FormAccess.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  paramsFilter: PropTypes.object,
}

FormAccess.defaultProps = {
  setOpen: () => {},
}

export default FormAccess
