import { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles, Box } from '@material-ui/core'
import { TopBar, Permitted, ScreenLoading } from 'components'
import { NavBar } from './components'
import styles from './styles'
import { useAuth } from 'hooks'
import { routes } from 'Routes'
import { Redirect } from 'react-router-dom'

const useStyles = makeStyles(styles)

const Main = ({ permission, somePermissions, reduceMargin, children }) => {
  const classes = useStyles()
  const auth = useAuth()
  const [isMobileNavOpen, setMobileNavOpen] = useState(false)

  return (
    <ScreenLoading loaded={auth.loaded}>
      <Permitted
        someTags={somePermissions}
        tag={permission}
        fallback={<Redirect to={routes.forbidden} />}
      >
        <Box className={classes.root}>
          <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} withSidebar />
          <NavBar
            onMobileClose={() => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
          />
          <Box className={classes.wrapper}>
            <Box
              className={clsx(classes.contentContainer, {
                [classes.contentContainerMarginReduce]: reduceMargin,
              })}
            >
              <Box className={classes.content}>{children}</Box>
            </Box>
          </Box>
        </Box>
      </Permitted>
    </ScreenLoading>
  )
}

Main.propTypes = {
  permission: PropTypes.string,
  somePermissions: PropTypes.array,
  children: PropTypes.node,
  reduceMargin: PropTypes.bool,
}

Main.defaultProps = {
  reduceMargin: false,
}

export default Main
