import PropTypes from 'prop-types'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import helpers from 'helpers'

const QuestionContent = ({ rmc }) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width="10%">ID</TableCell>
          <TableCell width="20%">Responsável</TableCell>
          <TableCell width="20%">Ação corretiva</TableCell>
          <TableCell width="30%">Evidência da implantação</TableCell>
          <TableCell width="20%">Prazo</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rmc.nonComplianceReportActions.map((nonComplianceReportAction) => (
          <TableRow key={nonComplianceReportAction?.id}>
            <TableCell>{nonComplianceReportAction?.id}</TableCell>
            <TableCell>
              {nonComplianceReportAction?.responsableUser?.name}
            </TableCell>
            <TableCell>
              {nonComplianceReportAction?.correctionEvidence}
            </TableCell>
            <TableCell>{nonComplianceReportAction?.correctiveAction}</TableCell>
            <TableCell>
              {helpers.formatters.date(nonComplianceReportAction?.deadlineDate)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

QuestionContent.propTypes = {
  rmc: PropTypes.object,
}

export default QuestionContent
