import { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Box, Button } from '@material-ui/core'
import { useForm } from 'react-hook-form'

import { BackButton, LoadingBox } from 'components'
import { DiscountCoupon, GeneralInformation, Segment } from './Components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import helpers from 'helpers'
import schema from './schema'
import { routes } from 'Routes'
import constants from 'constants/index'

const CouponsForm = ({ coupon, isEdit }) => {
  const [loading, setLoading] = useState(false)
  const [segmentToSubmit, setSegmentToSubmit] = useState([])

  const history = useHistory()
  const snackbar = useSnackbar()

  const formMethods = useForm({
    validationSchema: schema(),
    defaultValues: {
      segments:
        helpers.discountCoupon.toConvertIdForSegmentId(segmentToSubmit) || [],
      name: coupon?.name || '',
      status: coupon ? coupon?.status?.toString() : '',
      code: coupon?.code || '',
      discountType: coupon?.discountType || constants.discountCoupon.VALUE_TYPE,
      discountValue:
        helpers.discountCoupon.discountFormatter(
          coupon?.discountType,
          coupon?.discountValue || '',
        ) || '',
      couponUses: !coupon?.document ? 'true' : 'false',
      document: helpers.formatters.cnpjAndCpfMask(coupon?.document) || '',
      startDate: coupon?.startDate || null,
      expirationDate: coupon?.expirationDate || null,
      sellerId: coupon?.seller?.id || '',
    },
  })

  const { handleSubmit, reset, watch } = formMethods

  const onSubmit = async (discountCoupon) => {
    delete discountCoupon.couponUses
    const segments =
      helpers.discountCoupon.toConvertIdForSegmentId(segmentToSubmit) || []

    discountCoupon.startDate = helpers.formatters.date(
      discountCoupon?.startDate,
    )
    discountCoupon.expirationDate = helpers.formatters.date(
      discountCoupon?.expirationDate,
    )
    discountCoupon.discountValue =
      helpers.discountCoupon.discountFormatterToSubmit(
        discountCoupon?.discountType,
        discountCoupon?.discountValue,
      )
    try {
      setLoading(true)
      if (isEdit) {
        await service.dponet.coupons.update({
          couponId: coupon?.id,
          discountCoupon: { segments, ...discountCoupon },
        })
      } else {
        await service.dponet.coupons.create({
          discountCoupon: { segments, ...discountCoupon },
        })
      }

      snackbar.open({
        message: `Cupom de desconto ${
          isEdit ? 'atualizado' : 'criado'
        } com sucesso!`,
        variant: 'success',
      })
      history.push(routes.coupons.all)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) ||
          `Falha ao ${isEdit ? 'atualizar' : 'criar'} o cupom de desconto!`,
        variant: 'error',
      })
      setLoading(false)
      reset(discountCoupon)
    }
  }

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <GeneralInformation
            formMethods={formMethods}
            isEdit={isEdit}
            coupon={coupon}
          />
          <DiscountCoupon formMethods={formMethods} />
          {watch('couponUses') === 'true' && (
            <Segment
              isEdit={isEdit}
              coupon={coupon}
              setSegmentToSubmit={setSegmentToSubmit}
            />
          )}
          <Box display="flex" justifyContent="flex-start">
            <Box pr={2.5}>
              <BackButton onClick={() => history.push(routes.coupons.all)}>
                Voltar
              </BackButton>
            </Box>
            <Box>
              <Button color="primary" variant="contained" type="submit">
                Salvar
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </>
  )
}

CouponsForm.propTypes = {
  coupon: PropTypes.object,
  isEdit: PropTypes.bool,
}

CouponsForm.defaultProps = {
  isEdit: false,
}

export default CouponsForm
