import { Page, Container, ContentHeader, LoadingFeedback } from 'components'
import { Form } from './components'
import { useFetch } from 'hooks'

import * as service from 'service'
import constants from 'constants/index'

const Edit = ({ match }) => {
  const { sellerId } = match.params

  const { response, isLoading } = useFetch(
    service.dponet.sellers.show,
    sellerId,
    [],
  )

  const seller = response?.data?.seller

  return (
    <>
      <LoadingFeedback open={isLoading} />
      {!isLoading && (
        <Page title="Editar Vendedor & Parceiro">
          <Container maxWidth={false}>
            <ContentHeader
              title="Editar"
              menu="Vendedor & Parceiro"
              subtitle={constants.seller.TRANSLATED_KINDS[seller.kind]}
            />
            <Form seller={seller} />
          </Container>
        </Page>
      )}
    </>
  )
}
export default Edit
