import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'

import usePermissions from 'hooks/usePermissions'

import {
  Box,
  Button,
  Chip,
  makeStyles,
  Link,
  Typography,
  Tooltip,
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { MenuButton, Permitted, LoadingFeedback } from 'components'

import styles from './styles'
import helpers from 'helpers'
import { routes } from 'Routes'
import constants from 'constants/index'

const useStyles = makeStyles(styles)

const TableRowRmc = ({
  nonComplianceReport,
  entityType,
  isLoading,
  ...rest
}) => {
  const classes = useStyles()
  const history = useHistory()
  const permissions = usePermissions()

  const nonComplianceReportOriginName =
    helpers.nonComplianceReports.getOriginName(nonComplianceReport)

  const handleClick = (nonComplianceReport, e) => {
    switch (nonComplianceReport.entityType) {
      case constants.nonComplianceReports.ENTITY_DATA_PROCESS:
        return handleToDataProcess(nonComplianceReport?.entity?.id, e)
      case constants.nonComplianceReports.ENTITY_QUESTION:
        return handleToOpenQuestionnaire(nonComplianceReport.questionnaireId, e)
      case constants.nonComplianceReports.ENTITY_INCIDENT:
        return handleToOpenIncident(nonComplianceReport?.entity?.id, e)
      default:
        return ''
    }
  }

  const handleToDataProcess = (dataProcessId, e) => {
    if (!permissions.permitted(constants.permissions.DATA_PROCESSES.LIST)) {
      return e.preventDefault()
    }

    history.push(
      reverse(routes.dataProcess.view, {
        dataProcessId: dataProcessId,
      }),
    )
  }

  const handleToOpenQuestionnaire = (questionnaireId, e) => {
    if (!permissions.permitted(constants.permissions.QUESTIONNAIRES.REPLY)) {
      return e.preventDefault()
    }

    history.push(
      reverse(routes.questionnaire.reply, {
        questionnaireId: questionnaireId,
      }),
    )
  }

  const handleToOpenIncident = (incidentId, e) => {
    if (
      !permissions.some([
        constants.permissions.INCIDENTS.SHOW,
        constants.permissions.INCIDENTS.EDIT,
      ])
    ) {
      return e.preventDefault()
    }

    history.push(
      reverse(routes.incidents.edit, {
        incidentId: incidentId,
      }),
    )
  }

  const hrefPointTo = (nonComplianceReport) => {
    if (
      nonComplianceReport.entityType !==
      constants.nonComplianceReports.ENTITY_QUESTION
    )
      return '#'

    return `#${nonComplianceReport?.entity?.id}`
  }

  const disableLink = (nonComplianceReport) => {
    if (
      nonComplianceReport.entityType ===
      constants.nonComplianceReports.ENTITY_QUESTION
    ) {
      if (!permissions.permitted(constants.permissions.QUESTIONNAIRES.REPLY)) {
        return classes.disableLink
      }
    }

    if (
      nonComplianceReport.entityType ===
      constants.nonComplianceReports.ENTITY_INCIDENT
    ) {
      if (
        !permissions.some([
          constants.permissions.INCIDENTS.SHOW,
          constants.permissions.INCIDENTS.EDIT,
        ])
      ) {
        return classes.disableLink
      }
    }

    if (
      nonComplianceReport.entityType ===
      constants.nonComplianceReports.ENTITY_DATA_PROCESS
    ) {
      if (!permissions.permitted(constants.permissions.DATA_PROCESSES.LIST)) {
        return classes.disableLink
      }
    }
  }

  const rncDepartment = (nonComplianceReport) =>
    nonComplianceReport?.department?.name
  const rncQuestionary = (nonComplianceReport) =>
    nonComplianceReport?.questionnaireName

  const isDisabled = () => {
    if (
      [
        constants.nonComplianceReports.SUBSTITUTED_STATUS,
        constants.nonComplianceReports.FROZEN_STATUS,
        constants.nonComplianceReports.INACTIVE_STATUS,
      ].includes(nonComplianceReport.status)
    ) {
      return classes.disabledTableCell
    }
  }

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <TableRow {...rest}>
        <TableCell className={isDisabled()}>{nonComplianceReport.id}</TableCell>
        <TableCell className={isDisabled()}>
          {nonComplianceReport?.company?.name}
        </TableCell>
        <TableCell className={isDisabled()}>
          {helpers.nonComplianceReports.origin(nonComplianceReport.finality)}
        </TableCell>
        <TableCell className={isDisabled()}>
          <Link
            color="secondary"
            className={disableLink(nonComplianceReport)}
            href={hrefPointTo(nonComplianceReport)}
            onClick={(e) => handleClick(nonComplianceReport, e)}
          >
            <Tooltip
              title={nonComplianceReportOriginName.name}
              placement="bottom-start"
            >
              <Typography className={classes.nameEllipsis}>
                {nonComplianceReportOriginName.name}
              </Typography>
            </Tooltip>
          </Link>
        </TableCell>
        {entityType === constants.nonComplianceReports.ENTITY_DATA_PROCESS && (
          <TableCell align="center" className={isDisabled()} disableTooltip>
            <Chip
              align="center"
              size="small"
              label={helpers.dataProcesses.fragilityLabel(
                nonComplianceReport?.dataProcessFragility,
              )}
              className={helpers.dataProcesses.fragilityColor(
                nonComplianceReport?.dataProcessFragility,
                classes,
              )}
            />
          </TableCell>
        )}
        <TableCell className={isDisabled()}>
          {nonComplianceReport?.responsableUser?.name ||
            helpers.nonComplianceReports.getResponsibleNames(
              nonComplianceReport,
            )}
        </TableCell>
        {(entityType === constants.nonComplianceReports.ENTITY_DATA_PROCESS ||
          !entityType) && (
          <TableCell className={isDisabled()}>
            {rncDepartment(nonComplianceReport) ||
              rncQuestionary(nonComplianceReport) ||
              '-'}
          </TableCell>
        )}
        <TableCell align="center" className={isDisabled()} disableTooltip>
          <Tooltip
            title={
              nonComplianceReport.status ===
              constants.nonComplianceReports.FROZEN_STATUS
                ? 'O processo que gerou este RMC está em edição'
                : ''
            }
          >
            <Chip
              size="small"
              className={helpers.nonComplianceReports.statusChipColor(
                nonComplianceReport.status,
                classes,
              )}
              label={helpers.nonComplianceReports.status(
                nonComplianceReport.status,
              )}
            />
          </Tooltip>
        </TableCell>
        <TableCell className={isDisabled()}>
          {helpers.formatters.date(nonComplianceReport.deadlineDate) ||
            'Não definido'}
        </TableCell>
        <TableCell className={isDisabled()}>
          {helpers.formatters.date(nonComplianceReport.createdAt)}
        </TableCell>
        <Permitted tag={constants.permissions.NON_COMPLIANCE_REPORT.SHOW}>
          <TableCell align="right" disableTooltip>
            <MenuButton>
              <Button
                color="secondary"
                onClick={() =>
                  history.push(
                    reverse(routes.nonComplianceReport.show, {
                      nonComplianceReportId: nonComplianceReport.id,
                    }),
                  )
                }
                fullWidth
                size="small"
              >
                Informações
              </Button>
            </MenuButton>
          </TableCell>
        </Permitted>
      </TableRow>
    </>
  )
}

TableRowRmc.propTypes = {
  nonComplianceReport: PropTypes.object,
  entityType: PropTypes.string,
  isLoading: PropTypes.bool,
}

const RmcTable = ({ nonComplianceReports, isLoading, entityType }) => {
  const permissions = usePermissions()

  const widthCell =
    entityType === constants.nonComplianceReports.ENTITY_DATA_PROCESS ||
    !entityType
      ? '10%'
      : `${100 / 9}%`

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <Box width="100%">
        <PerfectScrollbar
          options={{ wheelPropagation: false, useBothWheelAxes: true }}
        >
          <Table
            size={
              permissions.permitted(constants.permissions.DATA_PROCESSES.SHOW)
                ? 'medium'
                : 'small'
            }
            emptyMessage="Nenhum Registros de melhoria contínua encontrada"
            isLoading={isLoading}
            noWrap
          >
            <TableHead>
              <TableRow>
                <TableCell width={widthCell}>Identificador</TableCell>
                <TableCell width={widthCell}>Empresa</TableCell>
                <TableCell width={widthCell}>Origem</TableCell>
                <TableCell width={widthCell}>
                  {entityType === constants.nonComplianceReports.ENTITY_INCIDENT
                    ? 'Descrição do incidente'
                    : 'Registro de origem'}
                </TableCell>
                {entityType ===
                  constants.nonComplianceReports.ENTITY_DATA_PROCESS && (
                  <TableCell width={widthCell} align="center">
                    Risco
                  </TableCell>
                )}
                <TableCell width={widthCell}>Responsável</TableCell>
                {(entityType ===
                  constants.nonComplianceReports.ENTITY_DATA_PROCESS ||
                  !entityType) && (
                  <TableCell width={widthCell}>
                    {!entityType ? 'Departamento/Questiónario' : 'Departamento'}
                  </TableCell>
                )}
                <TableCell width={widthCell}>Status</TableCell>
                <TableCell width={widthCell}>Prazo</TableCell>
                <TableCell width={widthCell}>Criado em</TableCell>
                <Permitted
                  tag={constants.permissions.NON_COMPLIANCE_REPORT.SHOW}
                >
                  <TableCell width={widthCell} align="right">
                    Ações
                  </TableCell>
                </Permitted>
              </TableRow>
            </TableHead>

            <TableBody>
              {nonComplianceReports?.map((nonComplianceReport) => (
                <TableRowRmc
                  nonComplianceReport={nonComplianceReport}
                  key={nonComplianceReport.id}
                  entityType={entityType}
                  hover
                />
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Box>
    </>
  )
}

RmcTable.propTypes = {
  nonComplianceReports: PropTypes.arrayOf(Object),
  entityType: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default RmcTable
