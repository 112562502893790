import PropTypes from 'prop-types'
import { useState } from 'react'
import { DepartmentUsersTable, LinkUserModal } from './components'
import { Card } from 'components'
import { Box, Button } from '@material-ui/core'

import { Users as UsersIcon } from 'react-feather'

const DepartmentUsers = ({
  availableUsers,
  availableUsersRefresh = () => {},
  department,
  isLoading = false,
  refresh = () => {},
  setLoading = () => {},
}) => {
  const [openModal, setOpenModal] = useState(false)

  const handleOpenModal = () => setOpenModal(true)

  return (
    <Box>
      <Card
        dropdown
        iconTitle={<UsersIcon />}
        title="Responsáveis"
        actionButton={
          <Button color="primary" variant="contained" onClick={handleOpenModal}>
            Adicionar
          </Button>
        }
      >
        <DepartmentUsersTable
          availableUsersRefresh={availableUsersRefresh}
          department={department}
          isLoading={isLoading}
          refresh={refresh}
          setLoading={setLoading}
          users={department?.users}
        />
      </Card>
      <LinkUserModal
        availableUsers={availableUsers}
        availableUsersRefresh={availableUsersRefresh}
        department={department}
        open={openModal}
        refresh={refresh}
        setLoading={setLoading}
        setOpen={setOpenModal}
      />
    </Box>
  )
}

DepartmentUsers.propTypes = {
  availableUsers: PropTypes.arrayOf(PropTypes.object),
  availableUsersRefresh: PropTypes.func,
  department: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  refresh: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
}

export default DepartmentUsers
