import { Box } from '@material-ui/core'

import PropsTypes from 'prop-types'

import { GeneralInformation, ActionPlans } from '../'

const OriginFragility = ({ rmc }) => {
  return (
    <>
      <Box mb={5}>
        <GeneralInformation rmc={rmc} />
      </Box>
      <Box mb={5}>
        <ActionPlans rmc={rmc} />
      </Box>
    </>
  )
}

OriginFragility.propTypes = {
  rmc: PropsTypes.object,
}
export default OriginFragility
