import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Link } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { isEmpty } from 'lodash'

import { Card, LoadingBox, Permitted } from 'components'

import constants from 'constants/index'
import * as service from 'service'
import { routes } from 'Routes'
import useStyles from './styles'

const ProfileSection = ({ simplifiedProfile, profileId, userClient }) => {
  const routeToShowProfile = userClient
    ? service.dponet.userProfiles.get
    : service.dponet.collaboratorProfiles.get
  const profileTypePermissionsList = userClient
    ? constants.permissionsLists.clientProfileLists
    : constants.permissionsLists.collaboratorProfileLists
  const translatedPermissions = userClient
    ? constants.permissions.TRANSLATED_CLIENT_PERMISSIONS_LIST
    : constants.permissions.TRANSLATED_COLLABORATOR_PERMISSIONS_LIST
  const editProfilePermission = userClient
    ? constants.permissions.CLIENT_PROFILES.EDIT
    : constants.permissions.COLLABORATOR_PROFILES.EDIT
  const adminProfile = profileId === constants.user.ADMIN_PROFILE_ID

  const history = useHistory()
  const classes = useStyles()

  const [permissionsResume, setPermissionsResume] = useState('-')
  const [profile, setProfile] = useState({})
  const [loading, setLoading] = useState(true)

  const navigateToEditProfile = () => {
    if (userClient) {
      history.push(
        reverse(routes.userProfiles.edit, {
          userProfileId: profile?.id,
        }),
      )
    } else {
      history.push(
        reverse(routes.collaboratorProfiles.edit, {
          collaboratorProfileId: profile?.id,
        }),
      )
    }
  }

  const loadProfilePermissionsResume = () => {
    if (adminProfile) {
      setPermissionsResume('Todas as permissões')

      return
    }

    if (isEmpty(profile)) {
      setDefaultPermissionsResume()

      return
    }

    const profileFunctionalities = profile.profileFunctionalities

    Object.values(profileTypePermissionsList)
      .flat()
      .forEach((permission) => {
        if (
          !profileFunctionalities.some(
            (profileFunctionality) =>
              profileFunctionality.tag === permission.tag,
          )
        ) {
          profileFunctionalities.push({
            ...permission,
            status: true,
          })
        }
      })

    const truePermissions = profileFunctionalities.filter((permission) => {
      return permission.status === true
    })

    if (isEmpty(truePermissions)) {
      setDefaultPermissionsResume()

      return
    }

    const truePermissionsTags = truePermissions.map((permission) => {
      return permission.tag
    })

    const permissionsListsKeys = Object.keys(profileTypePermissionsList)
    const labelledPermissions = {}

    permissionsListsKeys.forEach((permissionListKey) => {
      profileTypePermissionsList[permissionListKey].forEach((permission) => {
        if (truePermissionsTags.includes(permission.tag)) {
          if (!labelledPermissions[permissionListKey]) {
            labelledPermissions[permissionListKey] = []
          }

          labelledPermissions[permissionListKey].push(permission.name)
        }
      })
    })

    setPermissionsResume(mountResume(labelledPermissions))
  }

  const setDefaultPermissionsResume = () => {
    if (permissionsResume !== '-') setPermissionsResume('-')
  }

  const mountResume = (labelledPermissions) => {
    var resume = ''

    Object.keys(labelledPermissions).forEach((permissionKey) => {
      resume =
        resume +
        translatedPermissions[permissionKey] +
        ': ' +
        labelledPermissions[permissionKey].join(', ') +
        ';\n'
    })

    return resume
  }

  const getProfile = async () => {
    if (adminProfile) {
      setProfile({})
    } else {
      setLoading(true)
      const response = await routeToShowProfile({ profileId })
      setProfile(response?.data?.profile || {})
    }

    setLoading(false)
  }

  useEffect(() => {
    getProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId])

  useEffect(() => {
    if (!loading) loadProfilePermissionsResume()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile, loading])

  return (
    <Card title="Perfil do usuário selecionado" paddingBottom={0}>
      {loading ? (
        <LoadingBox minHeight="200px" />
      ) : (
        <>
          <Box>
            <Typography variant="subtitle2" color="textPrimary">
              Nome do perfil
            </Typography>
            <Typography variant="body2">
              <Permitted
                show={!adminProfile}
                tag={editProfilePermission}
                fallback={simplifiedProfile?.name}
              >
                <Link onClick={navigateToEditProfile} className={classes.link}>
                  {simplifiedProfile?.name}
                </Link>
              </Permitted>
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle2" color="textPrimary">
              Descrição
            </Typography>
            <Typography variant="body2">
              {simplifiedProfile?.description}
            </Typography>
          </Box>
          <Box mt={2}>
            <Typography variant="subtitle2" color="textPrimary">
              Acessos e permissões
            </Typography>
            <Typography variant="body2" className={classes.permissionsResume}>
              {permissionsResume}
            </Typography>
          </Box>
        </>
      )}
    </Card>
  )
}

ProfileSection.propTypes = {
  simplifiedProfile: PropTypes.object.isRequired,
  profileId: PropTypes.number.isRequired,
}

export default ProfileSection
