import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Container, Grid, makeStyles } from '@material-ui/core'

import { DescriptionPaper } from '../../'
import PaperShowDataProcess from '../../PaperShowDataProcess'
import { ConfirmationDialog } from 'components'

import useDataProcess from 'hooks/useDataProcess'
import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import styles from '../styles'
import { routes } from 'Routes'
import constants from 'constants/index'
import helpers from 'helpers'

const useStyles = makeStyles(styles)

const Revision = ({ description }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const history = useHistory()
  const {
    data: dataProcess,
    nextStep,
    toNextStep,
    setToNextStep,
    reloadOptions,
    reloadDataProcess,
  } = useDataProcess()

  const [confirmationDialog, setConfirmationDialog] = useState(false)

  useEffect(() => {
    if (toNextStep && !confirmationDialog) {
      setToNextStep(false)
      setConfirmationDialog(true)
    }
    // eslint-disable-next-line
  }, [nextStep])

  const handleFinalize = async () => {
    try {
      await service.dponet.dataProcesses.changeStatus({
        dataProcessId: dataProcess.id,
        statusId: constants.dataProcess.WAITING_REVIEW_STATUS,
      })
      snackbar.open({
        message:
          'Processamento de dados criado e enviado para revisão com sucesso!',
        variant: 'success',
      })

      reloadOptions()
      reloadDataProcess()

      history.push(routes.dataProcess.all)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
  }

  return (
    <>
      <DescriptionPaper description={description} />
      <Grid container spacing={2} className={classes.root}>
        <Container maxWidth={false}>
          <PaperShowDataProcess dataProcessId={dataProcess.id} />
        </Container>
      </Grid>
      <ConfirmationDialog
        open={confirmationDialog}
        setOpen={setConfirmationDialog}
        message='Você confirma que revisou as informações de acordo com o processo
              de sua empresa? Após a confirmação, o processo será enviado para
              análise jurídica, e terá seu status alterado para "Aguardando
              revisão".'
        buttonText="Confirmar"
        cancelButtonText="Voltar e revisar"
        actionAcceptButton={handleFinalize}
      />
    </>
  )
}

Revision.propTypes = {
  description: PropTypes.string.isRequired,
}

export default Revision
