import { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { Tabs, Tab } from '@material-ui/core'

import constants from 'constants/index'

import { ContractForm, PaymentsForm, PreRegistrationsCard } from './components'

const TabsForm = ({ mode, preRegistration, refresh, paymentContract }) => {
  const location = useLocation()

  const tabDefault = location?.state?.tab
  const [tab, setTab] = useState(tabDefault || 'contract')

  useEffect(() => {
    if (tabDefault) setTab(tabDefault)
    // eslint-disable-next-line
  }, [tabDefault])

  const isNew = mode === 'new'

  const handleChange = (_, value) => {
    setTab(value)
  }

  return (
    <>
      <Tabs value={tab} indicatorColor="primary" textColor="primary">
        {constants.paymentContract.TABS.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            disabled={isNew && tab.value !== 'contract'}
            onClick={(event) => handleChange(event, tab.value)}
          />
        ))}
      </Tabs>
      {tab === 'contract' && (
        <ContractForm
          mode={mode}
          preRegistration={preRegistration}
          refresh={refresh}
          paymentContract={paymentContract}
        />
      )}
      {tab === 'contractor' && (
        <PreRegistrationsCard
          paymentContract={paymentContract}
          contractRefresh={refresh}
          mode={mode}
        />
      )}
      {tab === 'payment' && (
        <PaymentsForm
          mode={mode}
          preRegistration={preRegistration}
          paymentContract={paymentContract}
        />
      )}
    </>
  )
}
export default TabsForm
