import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ dataProcessId, dataTreatmentId = '', ...params }) => {
  return await dponetAPI.get(
    `data_processes/${dataProcessId}/data_treatments/${dataTreatmentId}`,
    { params },
  )
}

const create = async ({ dataProcessId, ...data }) => {
  return await dponetAPI.post(
    `data_processes/${dataProcessId}/data_treatments`,
    data,
  )
}

const put = async ({ dataProcessId, dataTreatmentId, ...data }) => {
  return await dponetAPI.put(
    `data_processes/${dataProcessId}/data_treatments/${dataTreatmentId}`,
    data,
  )
}

const destroy = async ({ dataProcessId, dataTreatmentId }) => {
  return await dponetAPI.delete(
    `data_processes/${dataProcessId}/data_treatments/${dataTreatmentId}`,
  )
}

const list = async ({ dataProcessId }) => {
  return await dponetAPI.get(`data_processes/${dataProcessId}/data_treatments`)
}

const internationalTransferDestroy = async ({
  dataProcessId,
  dataTreatmentId,
  internationalTransferId,
}) => {
  return await dponetAPI.delete(
    `data_processes/${dataProcessId}/data_treatments/${dataTreatmentId}/international_transfers/${internationalTransferId}`,
  )
}

const dataTreatments = {
  get,
  create,
  put,
  destroy,
  list,
  internationalTransferDestroy,
}

export default dataTreatments
