import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

import { Page } from 'components'

import constants from 'constants/index'
import history from 'service/history'
import { routes } from 'Routes'
import usePermissions from 'hooks/usePermissions'

import useStyles from './styles'

const ReportsMain = ({ match }) => {
  const classes = useStyles()
  const { permitted } = usePermissions()

  const {
    params: { report },
  } = match

  const REPORTS = ['activation', 'seal', 'companyQuery', 'ticket']

  const REPORT_TITLES = {
    activation: 'Relatório de Ativação - DPOnet - Ativação',
    seal: 'Uso do Selo - DPOnet - Índices Gerais',
    companyQuery: 'Consulta de Empresas - DPOnet - Consulta de Empresas',
    ticket: 'Tickets - DPOnet - Produto',
  }

  const REPORT_LINKS = {
    activation:
      'https://app.powerbi.com/view?r=eyJrIjoiODQ4MGY5N2UtYjM4Yi00NWUzLTlhYWMtNTI0YjkxMDI3MjdmIiwidCI6IjE1OTU3NTllLWFkOTYtNGExZi05NzJiLThmM2VkMmZkMWRhMCJ9&pageName=ReportSection81a97122634d1fc4a97c',
    seal: 'https://app.powerbi.com/view?r=eyJrIjoiZWUyMTE0M2EtNDViYi00MDczLWE4YTktZGExZDA3Y2ZjZjJiIiwidCI6IjE1OTU3NTllLWFkOTYtNGExZi05NzJiLThmM2VkMmZkMWRhMCJ9&pageName=ReportSection',
    companyQuery:
      'https://app.powerbi.com/view?r=eyJrIjoiYjMyZWI4ZGUtYmUzNi00Y2I5LWJjZWEtMGVlYTAwMzM0OTUxIiwidCI6IjE1OTU3NTllLWFkOTYtNGExZi05NzJiLThmM2VkMmZkMWRhMCJ9&pageName=ReportSection15691d3cb0cf06d25d8f',
    ticket:
      'https://app.powerbi.com/view?r=eyJrIjoiYzUyZGNhZTYtNzQ5Zi00ZThhLThkNzktZjhjMjkwMTZiODUwIiwidCI6IjE1OTU3NTllLWFkOTYtNGExZi05NzJiLThmM2VkMmZkMWRhMCJ9&pageName=ReportSection',
  }

  const REPORT_PERMISSIONS = {
    activation: constants.permissions.REPORTS.ACTIVATION,
    seal: constants.permissions.REPORTS.SEAL,
    companyQuery: constants.permissions.REPORTS.COMPANY_QUERY,
    ticket: constants.permissions.REPORTS.TICKET,
  }

  const hasPermitionToShowReport = () => permitted(REPORT_PERMISSIONS[report])

  useEffect(() => {
    if (!REPORTS.includes(report)) history.push(routes.notFound)
    if (!hasPermitionToShowReport()) history.push(routes.forbidden)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page title={REPORT_TITLES[report]} height="100%">
      <Box px={2} height="100%" display="flex">
        {REPORTS.includes(report) && (
          <iframe
            className={classes.iframe}
            title={REPORT_TITLES[report]}
            src={REPORT_LINKS[report]}
            frameBorder="0"
            allowFullScreen
          />
        )}
      </Box>
    </Page>
  )
}

ReportsMain.propTypes = {
  match: PropTypes.object,
}

export default ReportsMain
