import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'

import { LoadingFeedback, Select as SelectComponent } from 'components'

import { useFetch, useSnackbar } from 'hooks'

import helpers from 'helpers'
import { routes } from 'Routes'
import * as service from 'service'
import schema from './schema'
import theme from 'theme'

const SubcategoryForm = ({ setLoading, refresh, isEdit, formData }) => {
  const snackbar = useSnackbar()
  const history = useHistory()
  const { handleSubmit, errors, control, reset } = useForm({
    validationSchema: schema,
    defaultValues: {
      categoryId: formData?.category?.id ?? '',
      title: formData?.title ?? '',
      description: formData?.description ?? '',
    },
  })

  const { response: categoriesResponse } = useFetch(
    service.dponet.technicalFaqs.getCategories,
    {},
  )

  const categoriesFaqs = categoriesResponse?.data?.knowledgeBaseCategories

  const categoriesFormattingFaqs =
    categoriesFaqs &&
    categoriesFaqs.map((category) => {
      return { id: category?.id, name: category?.title }
    })

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      if (isEdit) {
        await service.dponet.technicalFaqs.updateSubcategory({
          subCategoryId: formData?.id,
          ...data,
        })
        refresh()
      } else {
        await service.dponet.technicalFaqs.createSubcategory({
          ...data,
        })
        reset({ category: '', title: '', description: '' })
      }
      snackbar.open({
        message: `Subcategoria ${isEdit ? 'editada' : 'salva'} com sucesso!`,
        variant: 'success',
      })
      history.push(routes.technicalFaqs.subcategory)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingFeedback open={!categoriesFormattingFaqs && isEdit} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">Categoria *</Typography>
          {(categoriesFormattingFaqs || !isEdit) && (
            <Controller
              as={
                <SelectComponent
                  variant="outlined"
                  items={categoriesFormattingFaqs ?? []}
                  error={!!errors.categoryId}
                  helperText={errors?.categoryId?.message}
                  fullWidth
                />
              }
              control={control}
              name="categoryId"
              mode="onChange"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Título da subcategoria*</Typography>
          <Controller
            as={
              <TextField
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.title}
                helperText={errors?.title?.message}
                fullWidth
              />
            }
            control={control}
            name="title"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6">Descrição *</Typography>
          <Controller
            as={
              <TextField
                type="text"
                color="primary"
                variant="outlined"
                error={!!errors.description}
                helperText={errors?.description?.message}
                fullWidth
                multiline
                minRows={18}
                maxRows={18}
              />
            }
            control={control}
            name="description"
            mode="onChange"
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="end" gridGap={theme.spacing(1)}>
            <Button variant="contained" type="submit" color="primary">
              Salvar
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}

export default SubcategoryForm
