import { Filters } from 'components'

const SubcategoryFilter = ({ filter }) => {
  return (
    <Filters filter={filter}>
      <input
        textfieldinput="true"
        label="Identificador"
        type="number"
        name="id"
      />
      <input textfieldinput="true" label="Título" name="title" />
      <input textfieldinput="true" label="Descrição" name="description" />
      <input textfieldinput="true" label="Autor" name="author" />
      <input datepickerinput="true" label="FAQ's antes de" name="updatedAtLt" />
      <input
        datepickerinput="true"
        label="FAQ's depois de"
        name="updatedAtGt"
      />
    </Filters>
  )
}

export default SubcategoryFilter
