import helpers from 'helpers/index'

const formatPayload = (dataProcess) => {
  const payload = {
    dataProcessId: dataProcess.id,
    departmentId: dataProcess?.department?.id ?? '',
    name: dataProcess?.name,
    legalBase: dataProcess?.legalBase ?? '',
    lia: dataProcess?.lia ?? '',
    recomendations: dataProcess?.recomendations ?? '',
    necessaryProtection: dataProcess?.necessaryProtection ?? '',
    storageLocationId: dataProcess?.storageLocationId ?? '',
    internalAllocationModeId: dataProcess?.internalAllocationModeId ?? '',
    storageTimeId: dataProcess?.storageTimeId ?? '',
    discardModeId: dataProcess?.discardModeId ?? '',
    protectionId: dataProcess?.protectionId ?? '',
    recoveryId: dataProcess?.recoveryId ?? '',
    value: dataProcess?.value ?? '',
    volumetry: dataProcess?.volumetry ?? '',
    code: dataProcess?.code ?? '',
    version: dataProcess?.version ?? '',
    finality: dataProcess?.finality ?? '',
    deadlineJustification: dataProcess?.deadlineJustification ?? '',
    legalFrameworks: dataProcess?.legalFrameworks ?? '',
    dataProcessDataSources: helpers.dataProcesses.covertDataSourcesToOptions(
      dataProcess?.dataProcessDataSources || [],
    ),
  }
  return payload
}

export default formatPayload
