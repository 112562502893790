import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box } from '@material-ui/core'

import PlansTableRow from '../PlansTableRow'
import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'

const PlansTable = ({ plans, refresh }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: true, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhum segmento e preço encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="20%" align="left">
                Nome da empresa modelo
              </TableCell>
              <TableCell width="10%" align="left">
                Código
              </TableCell>
              <TableCell width="15%" align="left">
                Segmento
              </TableCell>
              <TableCell width="25%" align="left">
                Nome do plano
              </TableCell>
              <TableCell width="10%" align="left">
                Mensalidade
              </TableCell>
              <TableCell width="10%" align="left">
                Status
              </TableCell>
              <TableCell width="5%" align="left">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {plans.map((plan) => (
              <PlansTableRow plan={plan} key={plan.id} refresh={refresh} />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

PlansTable.propTypes = {
  plans: PropTypes.arrayOf(Object),
  refresh: PropTypes.func,
}

PlansTable.defaultProps = {
  refresh: () => {},
}

export default PlansTable
