const STATUSES = {
  INACTIVE: 0,
  ACTIVE: 1,
}

const STATUSES_LABELS = {
  0: 'INATIVA',
  1: 'ATIVA',
}

const COMMUNICATION_HOLDER_OPTIONS = [
  {
    id: 'yes',
    name: 'Sim.',
  },
  {
    id: 'not_risk',
    name: 'Não, por não haver risco ou dano relevante a eles',
  },
  {
    id: 'comunication_progress',
    name: 'Não, mas o processo de comunicação está em andamento.',
  },
  {
    id: 'risk_investigated',
    name: 'Não, vez que o risco do incidente ainda está sendo apurado. (comunicação preliminar)',
  },
]

const IMPACT_HOLDER = [
  {
    id: 'may_not_take_damage',
    name: 'Podem não sofrer danos negligenciáveis ou superáveis sem dificuldade.',
  },
  {
    id: 'may_suffer_damage',
    name: 'Podem sofrer danos, superáveis com certa dificuldade.',
  },
  {
    id: 'may_suffer_significant_damage',
    name: 'Podem sofrer danos importantes, superáveis com muita dificuldade.',
  },
  {
    id: 'may_suffer_injury',
    name:
      'Podem sofrer lesão ou ofensa a direitos ou interesses difusos, coletivos ou individuais, que dadas as ' +
      'circunstâncias, ocasionam ou tem potencial para ocasionar dano significativo ou irreversível.',
  },
]

const SECURITY_OPTIONS = [
  {
    id: 'yes_complete',
    name: '<strong>Sim</strong>, integralmente protegidos por criptografia / pseudonimização / etc.',
    status:
      'Sim, integralmente protegidos por criptografia / pseudonimização / etc.',
  },
  {
    id: 'yes_partial',
    name: '<strong>Sim</strong>, parcialmente protegidos por criptografia / pseudonimização / etc',
    status:
      'Sim, parcialmente protegidos por criptografia / pseudonimização / etc',
  },
  {
    id: 'no',
    name: '<strong>Não</strong>',
    status: 'Não',
  },
]

const incidents = {
  STATUSES,
  STATUSES_LABELS,
  COMMUNICATION_HOLDER_OPTIONS,
  IMPACT_HOLDER,
  SECURITY_OPTIONS,
}

export default incidents
