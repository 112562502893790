import Main from './Main'
import New from './New'
import Edit from './Edit'

const Sellers = {
  Main,
  New,
  Edit,
}

export default Sellers
