import PropTypes from 'prop-types'

import { TableRow, TableCell } from 'components/Table'
import { MenuButton, Permitted } from 'components'
import { Button } from '@material-ui/core'

import helpers from 'helpers'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'
import { reverse } from 'named-urls'

import constants from 'constants/index'

const ProfilesTableRow = ({
  profile,
  isCollaborator,
  handleInactivateProfile,
}) => {
  const history = useHistory()

  const permissions = isCollaborator
    ? constants.permissions.COLLABORATOR_PROFILES
    : constants.permissions.CLIENT_PROFILES
  const labelToChangeStatus = profile?.status ? 'inativar' : 'ativar '

  const redirectEdit = () =>
    isCollaborator
      ? history.push(
          reverse(routes.collaboratorProfiles.edit, {
            collaboratorProfileId: profile.id,
          }),
        )
      : history.push(
          reverse(routes.userProfiles.edit, {
            userProfileId: profile.id,
          }),
        )

  const isAdmin = profile?.id === 1

  return (
    <>
      <TableRow key={profile.id}>
        <TableCell disableTooltip>{profile.id}</TableCell>
        <TableCell>{profile.name}</TableCell>
        <TableCell>{profile.description}</TableCell>

        {!isCollaborator && (
          <>
            <TableCell>
              {profile?.company?.name || 'Todas as empresas'}
            </TableCell>
            <TableCell disableTooltip>
              {helpers.profiles.checkDefault(profile)}
            </TableCell>
          </>
        )}
        <TableCell disableTooltip align="right">
          <Permitted someTags={[permissions.EDIT, permissions.DESTROY]}>
            <MenuButton disabled={isAdmin}>
              {profile?.id !== 1 && (
                <>
                  <Permitted tag={permissions.EDIT}>
                    <Button onClick={redirectEdit} fullWidth size="small">
                      Editar
                    </Button>
                  </Permitted>
                  {!profile?.default && (
                    <Permitted tag={permissions.DESTROY}>
                      <Button
                        onClick={() => handleInactivateProfile(profile)}
                        fullWidth
                        size="small"
                      >
                        {labelToChangeStatus}
                      </Button>
                    </Permitted>
                  )}
                </>
              )}
            </MenuButton>
          </Permitted>
        </TableCell>
      </TableRow>
    </>
  )
}

ProfilesTableRow.propTypes = {
  profile: PropTypes.object,
  isCollaborator: PropTypes.bool,
  handleInactivateProfile: PropTypes.func,
}

export default ProfilesTableRow
