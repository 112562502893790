import PropTypes from 'prop-types'
import { TextField, Grid } from '@material-ui/core'

import { CompanyTypeAndSeachInput } from 'components'

const DepartmentCompanies = ({
  control,
  errors,
  isEdit = false,
  companyName = '',
}) => {
  return (
    <Grid item xs={12}>
      {!isEdit ? (
        <CompanyTypeAndSeachInput control={control} errors={errors} />
      ) : (
        <TextField
          disabled
          label="Empresa"
          type="text"
          color="primary"
          variant="outlined"
          value={companyName}
          fullWidth
        />
      )}
    </Grid>
  )
}

DepartmentCompanies.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  isEdit: PropTypes.bool,
  companyName: PropTypes.string,
}

export default DepartmentCompanies
