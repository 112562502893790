/* eslint-disable react-hooks/exhaustive-deps */
import { Filters } from 'components'
import constants from 'constants/index'

const Filter = ({ filter, categories }) => {
  return (
    <Filters filter={filter}>
      <input textfieldinput="true" label="Identificador" name="id" />
      <input textfieldinput="true" label="Título" name="title" />
      <input textfieldinput="true" label="Publicado por" name="publishedBy" />

      <select
        multipleselectinput="true"
        label="Categorias"
        name="categories"
        optionvalues={categories}
      />
      <select
        multipleselectinput="true"
        label="Visualização"
        name="visualizations"
        optionvalues={constants.faqsDocumentations.SELECT_VIEW_PERMISSION}
      />
      <input datepickerinput="true" label="FAQ's antes de" name="updatedAtLt" />
      <input
        datepickerinput="true"
        label="FAQ's depois de"
        name="updatedAtGt"
      />
    </Filters>
  )
}

export default Filter
