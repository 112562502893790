import { jsPDF } from 'jspdf'
import moment from 'moment'

import templates from '../templates'

import '../fonts/Raleway-Bold-normal'
import '../fonts/Raleway-Regular-normal'
import '../fonts/Raleway-Thin-normal'
import '../fonts/Roboto-Bold-normal'
import '../fonts/Roboto-Regular-normal'

import pages from './pages'

moment.locale('pt-br')
var dateNow = moment().format('MMM YYYY')
dateNow = dateNow.charAt(0).toUpperCase() + dateNow.slice(1)

const exportCompliance = async (company, questionnaireFormatted) => {
  const ComplianceReport = async (company) => {
    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'px',
      userUnit: 300,
    })

    try {
      templates.firstCover(
        pdf,
        company?.name,
        company?.cnpj,
        dateNow,
        'Relatório de \nConformidade',
      )

      templates.chapterCover(
        pdf,
        'VISÃO GERAL',
        'Síntese das principais informações e métricas apresentadas na plataforma.',
      )

      await pages.generalInformations(
        pdf,
        `Visão Geral | ${dateNow}`,
        company,
        questionnaireFormatted,
      )

      await pages.openQuestions(
        pdf,
        `Medidas adotadas | ${dateNow}`,
        questionnaireFormatted,
        'Medidas adotadas',
      )

      await pages.openQuestions(
        pdf,
        `Medidas não adotadas | ${dateNow}`,
        questionnaireFormatted,
        'Medidas não adotadas',
      )

      await pages.openQuestions(
        pdf,
        `Questões em aberto | ${dateNow}`,
        questionnaireFormatted,
        'Questões em aberto',
      )

      templates.lastCover(pdf)

      pdf.save(
        `Relatorio_de_conformidade_${company?.name
          ?.split(' ')
          ?.join('_')
          ?.toLowerCase()}_${dateNow}.pdf`,
      )
    } catch (e) {}
  }
  return ComplianceReport(company)
}

export default exportCompliance
