import { useState } from 'react'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Collapse,
  makeStyles,
  Grid,
  Paper,
  IconButton,
  Typography,
} from '@material-ui/core'
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@material-ui/icons/'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import styles from './styles'
import helpers from 'helpers'

const useStyles = makeStyles(styles)

const TableChangesCollapse = ({
  changes,
  registerType,
  auditableName,
  action,
  open,
  setOpen,
  showRegisterSession,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Box margin={1} className={classes.collapseBackground}>
        <Box mx={2}>
          <Grid container spacing={2}>
            <Grid item md={showRegisterSession ? 6 : 12} sm={12} xs={12}>
              <Paper className={classes.justifyHeight}>
                <Box
                  display="flex"
                  justifyContent="center"
                  p={1}
                  flexWrap="wrap"
                >
                  <Box width="100%" display="flex" justifyContent="center">
                    <Typography variant="h6">Tipo de registro</Typography>
                  </Box>
                  <Box width="100%" display="flex" justifyContent="center">
                    <Typography variant="body1">
                      {t(`registerTypes.${registerType}`)}
                    </Typography>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            {showRegisterSession && (
              <Grid item md={6} xs={12}>
                <Paper className={classes.justifyHeight}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    p={1}
                    flexWrap="wrap"
                  >
                    <Box width="100%" display="flex" justifyContent="center">
                      <Typography variant="h6">Registro</Typography>
                    </Box>
                    <Box width="100%" display="flex" justifyContent="center">
                      <Typography variant="body1">
                        {auditableName?.responsableUserId ? '' : auditableName}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box my={2} ml={2}>
          <Typography variant="h6" gutterBottom component="div">
            Alterações
          </Typography>
        </Box>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width="33%">Informação</TableCell>
              <TableCell width="33%">Antes</TableCell>
              <TableCell width="33%">Depois</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {changes.map((change, index) => (
              <TableRow key={index} nowrap="true">
                <TableCell
                  disableTooltip
                  noOverFlow
                  className={helpers.logs.tableCellColor(action, classes)}
                >
                  <Box className={classes.rowWraper}>
                    {t(`${registerType}.${change[0]}`)}
                  </Box>
                </TableCell>
                <TableCell
                  noOverFlow
                  disableTooltip
                  className={helpers.logs.tableCellColor(action, classes)}
                >
                  <Box className={classes.rowWraper}>
                    {helpers.logs.formattedValue(
                      change[0],
                      change[1][0],
                      registerType,
                    )}
                  </Box>
                </TableCell>
                <TableCell
                  noOverFlow
                  disableTooltip
                  className={helpers.logs.tableCellColor(action, classes)}
                >
                  <Box className={classes.rowWraper}>
                    {helpers.logs.formattedValue(
                      change[0],
                      change[1][1],
                      registerType,
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Collapse>
  )
}

const TableRowLog = ({ log, showRegisterSession, ...rest }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  return (
    <>
      <TableRow className={classes.root} {...rest}>
        <TableCell disableTooltip>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell disableTooltip>
          <Typography variant="body2">
            {helpers.formatters.date(log.createdAt)}
          </Typography>
          <Box pt={1}>
            <Typography variant="body2" color="secondary">
              {helpers.formatters.hours(log.createdAt, false)}
            </Typography>
          </Box>
        </TableCell>
        <TableCell disableTooltip>
          <Typography variant="body2">{log?.author?.name}</Typography>
          <Box pt={1}>
            <Typography variant="body2" color="secondary">
              {log?.author?.email}
            </Typography>
          </Box>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          disableTooltip
          overFlowSize="100%"
          colSpan={3}
          className={classes.noCellPadding}
        >
          <TableChangesCollapse
            changes={log.changes}
            registerType={log.auditableType}
            auditableName={log.auditableName}
            action={log.action}
            open={open}
            setOpen={setOpen}
            showRegisterSession={showRegisterSession}
          />
        </TableCell>
      </TableRow>
    </>
  )
}

const LogsTable = ({ logs, showRegisterSession }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar>
        <Table size="medium" noWrap stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell disableTooltip width="10%" />
              <TableCell disableTooltip width="40%">
                Data
              </TableCell>
              <TableCell disableTooltip width="50%">
                Autor
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logs.map((log, index) => (
              <TableRowLog
                key={index}
                log={log}
                showRegisterSession={showRegisterSession}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

LogsTable.propTypes = {
  logs: PropTypes.array.isRequired,
  showRegisterSession: PropTypes.bool,
}

export default LogsTable
