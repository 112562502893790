import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  card: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '10px',
    padding: theme.spacing(2),
  },
  cardColorBlue: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  cardColorGreen: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  },
  cardColorViolet: {
    backgroundColor: colors.pink[300],
    color: theme.palette.common.white,
  },
  cardColorRed: {
    backgroundColor: colors.red[600],
    color: theme.palette.common.white,
  },
  icon: {
    color: theme.palette.common.white,
  },
}))

export default styles
