import {
  Select,
  MenuItem,
  OutlinedInput,
  Grid,
  Box,
  Chip,
} from '@material-ui/core'
import { Label } from 'components'

import { isEmpty } from 'lodash'

import useDataProcess from 'hooks/useDataProcess'

import useStyles from '../styles'

const LawsForm = ({ laws, setLaws }) => {
  const { lawsOptions } = useDataProcess()

  const classes = useStyles()

  const handleChange = (event) => {
    const {
      target: { value },
    } = event
    setLaws(typeof value === 'string' ? value.split(',') : value)
  }

  const findLawers = (value) => {
    return lawsOptions.find((law) => value === law.id).name
  }

  return (
    <Box className={classes.box}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Label
            title="Motivo"
            description="Qual lei que obriga fazer o armazenamento permanente?"
            item
            xs={12}
          >
            <Select
              fullWidth
              multiple
              value={laws}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected) => (
                <Box className={classes.chip}>
                  {selected.map((value, index) => (
                    <>
                      <Chip key={index} label={findLawers(value)} />{' '}
                    </>
                  ))}
                </Box>
              )}
              error={isEmpty(laws)}
            >
              {lawsOptions.map((option, index) => (
                <MenuItem key={index} value={option?.id}>
                  {option?.name}
                </MenuItem>
              ))}
            </Select>
          </Label>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LawsForm
