import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { isEmpty } from 'lodash'

import { TitleSection, TopicSection, RoundedChip, GreyChip } from '../'

import constants from 'constants/index'
import rangeAgeContent from 'helpers/dataCollected/rangeAgeContent'

const DataCollecteds = ({ dataCollecteds }) => {
  const { dataCollectedLabelOptions } = constants.dataCollected
  const dataCollectedsByType = (dataCollected) =>
    Object.entries(dataCollectedLabelOptions).map(([type, label]) => ({
      label,
      items: dataCollected?.collectedFields?.filter(
        (collectedField) =>
          parseInt(type) === collectedField?.dataCollectedOption?.dataType,
      ),
    }))

  const titularCategoryLabel = (dataCollected) =>
    `Faixa Etária: ${rangeAgeContent(dataCollected?.titularAgeRange)}`

  return (
    <Box m={2}>
      <TitleSection title="Dados tratados" />

      {dataCollecteds?.map((dataCollected) => {
        const collectedFields = dataCollectedsByType(dataCollected)

        return (
          <Fragment key={dataCollected?.id}>
            <TopicSection text={dataCollected?.titularCategory?.name} />
            <RoundedChip
              color="secondary"
              label={titularCategoryLabel(dataCollected)}
              gutterSpacing
            />
            <br />

            {collectedFields.map((collectedField) => (
              <Fragment key={collectedField?.label}>
                {!isEmpty(collectedField?.items) && (
                  <>
                    <RoundedChip label={collectedField?.label} />
                    <Box display="flex" flexWrap="wrap" mt={0.5}>
                      {collectedField?.items?.map((item) => (
                        <GreyChip
                          key={item?.id}
                          text={item?.dataCollectedOption?.name}
                        />
                      ))}
                    </Box>
                  </>
                )}
              </Fragment>
            ))}
          </Fragment>
        )
      })}
    </Box>
  )
}

DataCollecteds.propTypes = {
  dataCollecteds: PropTypes.array,
}

DataCollecteds.defaultProps = {
  dataCollecteds: [],
}

export default DataCollecteds
