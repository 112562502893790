const LAST_ID_BEFORE_UPDATE_DESCRIPTION = 3594

const PENDING_STATUS = 1
const WAITING_REVIEW_STATUS = 2
const DISAPPROVED_STATUS = 3
const APPROVED_STATUS = 4
const REVIEWING_STATUS = 5
const INACTIVE_STATUS = 6
const AUTO_REVIEW_STATUS = 9

const NOT_EDITABLE_STATUS = [
  WAITING_REVIEW_STATUS,
  APPROVED_STATUS,
  REVIEWING_STATUS,
  INACTIVE_STATUS,
  AUTO_REVIEW_STATUS,
]

const PHYSIC_PROTECTION = 'Físico'

const PENDING_LABEL = 'PENDENTE'
const WAITING_REVIEW_LABEL = 'AGUARDANDO REVISÃO'
const DISAPPROVED_LABEL = 'REPROVADO NA REVISÃO'
const APPROVED_LABEL = 'APROVADO'
const REVIEWING_LABEL = 'EM REVISÃO'
const INACTIVE_LABEL = 'INATIVO'
const AUTO_REVIEW_LABEL = 'REVISÃO AUTOMÁTICA'
const TOTAL_LABEL = 'TODOS'

const SUGGESTED_PENDING_STATUS = 1
const SUGGESTED_APPROVING_STATUS = 2
const SUGGESTED_REFUSE = 3

const SUGGESTED_DATA_PROCESS_ORIGIN = 'DataProcessSuggestion'
const DISCARDED_DATA_PROCESS_ORIGIN = 'DataProcessDiscarded'

const SOURCE_DEPARTMENT_KIND = 'Department'
const SOURCE_THIRD_PARTY_KIND = 'ThirdParty'
const SOURCE_PUBLIC_KIND = 'Public'

const SOURCE_DESCRIPTION_FIELD = 'sourceDescription'
const DATA_PROCESS_SOURCES_FIELD = 'dataProcessSources'

const TITULAR_DESCRIPTION =
  'O próprio titular fornece os dados (diretamente para a organização).'
const DEPARTMENT_DESCRIPTION =
  'Outro departamento compartilha os dados com este departamento.'
const THIRD_PARTY_SOURCE_DESCRIPTION =
  'Um terceiro (de fora da organização) compartilha os dados com a organização.'
const PUBLIC_SOURCE_DESCRIPTION = 'Os dados são públicos.'

const TITULAR_DATA_SOURCE = 1
const DEPARTMENT_SOURCE = 2
const THIRD_PARTY_SOURCE = 3
const PUBLIC_SOURCE = 4
const ANOTHER_SOURCE = 5

const SOURCES_WITH_ENTITY = [
  DEPARTMENT_SOURCE,
  THIRD_PARTY_SOURCE,
  PUBLIC_SOURCE,
  DEPARTMENT_DESCRIPTION,
  THIRD_PARTY_SOURCE_DESCRIPTION,
  PUBLIC_SOURCE_DESCRIPTION,
]

const LEGITIMATE_INTEREST_ID = 11

const LEGITIMATE_INTEREST_NAME =
  'Legítimo interesse do controlador ou de terceiro'

const FRAGILITY_FILTER_OPTIONS = [
  { id: '1', name: 'Baixa' },
  { id: '2', name: 'Média' },
  { id: '3', name: 'Alta' },
  { id: '4', name: 'Severa' },
]

const COMPANY_KIND_FILTER_OPTIONS = [
  { id: 'C', name: 'Pessoa jurídica' },
  { id: 'P', name: 'Pessoa física' },
  { id: 'T', name: 'Template' },
]

const DATA_TYPE_OPTIONS = [
  { id: '1', name: 'Sensíveis' },
  { id: ['2', '5'], name: 'Pessoais' },
  { id: ['3', '6'], name: 'Financeiros' },
  { id: ['4', '7'], name: 'Comportamental' },
]

const SHARE_TYPE_OPTIONS = [
  { id: '1', name: 'Interno' },
  { id: '2', name: 'Externo' },
  { id: '3', name: 'Interno e Externo' },
]

const ALL_PROCESSES_VALUE = 'ALL_PROCESSES'
const AUDIT_PROCESSES_VALUE = 'AUDIT_PROCESSES'

const ALL_PROCESSES_TAB = {
  value: ALL_PROCESSES_VALUE,
  label: 'Todos os processos',
}

const AUDIT_PROCESSES_TAB = {
  value: AUDIT_PROCESSES_VALUE,
  label: 'Em auditoria',
}

const TABS = [AUDIT_PROCESSES_TAB, ALL_PROCESSES_TAB]

const DATA_TYPE_CHOSEN = {
  LIFE_CYCLE: 'lifeCycle',
  COLLECTED_FIELD: 'collectedField',
  DATA_TREATMENT: 'dataTreatment',
}

const UNNECESSARY_DATA_TYPE = {
  COLLECTED_FIELD: 'CollectedField',
  DATA_TREATMENT: 'DataTreatment',
  LIFE_CYCLE: 'LifeCycle',
}

const TAB_VIEW_VALUE = {
  GENERAL: 'general',
  DATA_COLLECTS: 'dataCollects',
  LIFE_CYCLES: 'lifeCycles',
  DATA_TREATMENTS: 'dataTreatments',
  DATA_TREATMENT_AGENTS: 'dataTreatmentAgents',
  DOCUMENTS: 'documents',
  NECESSITY_PROPORTIONALITY: 'necessityProportionality',
}

const TAB_VIEW_LABEL = {
  GENERAL: 'Informações gerais',
  DATA_COLLECTS: 'Dados Tratados',
  LIFE_CYCLES: 'Salvaguarda e ciclo de vida',
  DATA_TREATMENTS: 'Compartilhamentos',
  DATA_TREATMENT_AGENTS: 'Agentes de tratamento',
  DOCUMENTS: 'Informação documentada',
  NECESSITY_PROPORTIONALITY: 'Análise da necessidade e proporcionalidade',
}

const TABS_VIEW = [
  { value: TAB_VIEW_VALUE.GENERAL, label: TAB_VIEW_LABEL.GENERAL },
  { value: TAB_VIEW_VALUE.DATA_COLLECTS, label: TAB_VIEW_LABEL.DATA_COLLECTS },
  { value: TAB_VIEW_VALUE.LIFE_CYCLES, label: TAB_VIEW_LABEL.LIFE_CYCLES },
  {
    value: TAB_VIEW_VALUE.DATA_TREATMENTS,
    label: TAB_VIEW_LABEL.DATA_TREATMENTS,
  },
  { value: TAB_VIEW_VALUE.DOCUMENTS, label: TAB_VIEW_LABEL.DOCUMENTS },
  {
    value: TAB_VIEW_VALUE.NECESSITY_PROPORTIONALITY,
    label: TAB_VIEW_LABEL.NECESSITY_PROPORTIONALITY,
  },
]

const TABS_EDIT = [
  { value: TAB_VIEW_VALUE.GENERAL, label: TAB_VIEW_LABEL.GENERAL },
  { value: TAB_VIEW_VALUE.DATA_COLLECTS, label: TAB_VIEW_LABEL.DATA_COLLECTS },
  { value: TAB_VIEW_VALUE.LIFE_CYCLES, label: TAB_VIEW_LABEL.LIFE_CYCLES },
  {
    value: TAB_VIEW_VALUE.DATA_TREATMENTS,
    label: TAB_VIEW_LABEL.DATA_TREATMENTS,
  },
  {
    value: TAB_VIEW_VALUE.DATA_TREATMENT_AGENTS,
    label: TAB_VIEW_LABEL.DATA_TREATMENT_AGENTS,
  },
  { value: TAB_VIEW_VALUE.DOCUMENTS, label: TAB_VIEW_LABEL.DOCUMENTS },
  {
    value: TAB_VIEW_VALUE.NECESSITY_PROPORTIONALITY,
    label: TAB_VIEW_LABEL.NECESSITY_PROPORTIONALITY,
  },
]

const LIA_LEGITIMATE_INTEREST_TEXT =
  'I. Situação concreta:\n\nA situação concreta em análise constitui atividade de tratamento de dados pessoais para a exclusiva situação indicada como a finalidade específica deste tratamento, finalidade esta que é legítima, limitada a apoio e promoção de atividades do agente de tratamento controlador;\n\nII. Outras bases legais:\n\nNão há outras bases legais (hipóteses legais de tratamento) que autorizam o tratamento de dados pessoais em análise;\n\nIII. Legítima expectativa:\n\nHá legítima expectativa dos titulares no sentido de que os dados pessoais sejam tratados para a finalidade específica indicada neste tratamento;\n\nIV. Direitos e liberdades individuais:\n\nO tratamento em análise, sob o fundamento do legítimo interesse, não viola direitos e liberdades fundamentais dos titulares de dados. Inclusive, não há tratamento de dados pessoais sensíveis, o que garante, portanto, a inviolabilidade de direitos e liberdades fundamentais.'

const STORY_MODE_UNDEFINED_TYPE = 'undefined_type'
const STORY_MODE_DEFINED_TYPE = 'defined_type'
const STORY_MODE_PERMANENT_TYPE = 'permanent_type'

const STORAGE_DEFINED_TYPE = 1
const STORAGE_UNDEFINED_TYPE = 2
const STORAGE_PERMANENT_TYPE = 3

const dataProcess = {
  ALL_PROCESSES_TAB,
  ALL_PROCESSES_VALUE,
  ANOTHER_SOURCE,
  APPROVED_LABEL,
  APPROVED_STATUS,
  AUDIT_PROCESSES_TAB,
  AUDIT_PROCESSES_VALUE,
  AUTO_REVIEW_LABEL,
  AUTO_REVIEW_STATUS,
  COMPANY_KIND_FILTER_OPTIONS,
  DATA_PROCESS_SOURCES_FIELD,
  DATA_TYPE_CHOSEN,
  DATA_TYPE_OPTIONS,
  DEPARTMENT_DESCRIPTION,
  DEPARTMENT_SOURCE,
  DISAPPROVED_LABEL,
  DISAPPROVED_STATUS,
  DISCARDED_DATA_PROCESS_ORIGIN,
  FRAGILITY_FILTER_OPTIONS,
  INACTIVE_LABEL,
  INACTIVE_STATUS,
  LAST_ID_BEFORE_UPDATE_DESCRIPTION,
  LEGITIMATE_INTEREST_ID,
  LEGITIMATE_INTEREST_NAME,
  LIA_LEGITIMATE_INTEREST_TEXT,
  NOT_EDITABLE_STATUS,
  PENDING_LABEL,
  PENDING_STATUS,
  PHYSIC_PROTECTION,
  PUBLIC_SOURCE_DESCRIPTION,
  PUBLIC_SOURCE,
  REVIEWING_LABEL,
  REVIEWING_STATUS,
  SHARE_TYPE_OPTIONS,
  SOURCE_DEPARTMENT_KIND,
  SOURCE_DESCRIPTION_FIELD,
  SOURCE_PUBLIC_KIND,
  SOURCE_THIRD_PARTY_KIND,
  SOURCES_WITH_ENTITY,
  STORY_MODE_DEFINED_TYPE,
  STORY_MODE_PERMANENT_TYPE,
  STORY_MODE_UNDEFINED_TYPE,
  SUGGESTED_APPROVING_STATUS,
  SUGGESTED_DATA_PROCESS_ORIGIN,
  SUGGESTED_PENDING_STATUS,
  SUGGESTED_REFUSE,
  TAB_VIEW_LABEL,
  TAB_VIEW_VALUE,
  TABS_EDIT,
  STORAGE_DEFINED_TYPE,
  STORAGE_PERMANENT_TYPE,
  STORAGE_UNDEFINED_TYPE,
  TABS_VIEW,
  TABS,
  THIRD_PARTY_SOURCE_DESCRIPTION,
  THIRD_PARTY_SOURCE,
  TITULAR_DATA_SOURCE,
  TITULAR_DESCRIPTION,
  TOTAL_LABEL,
  UNNECESSARY_DATA_TYPE,
  WAITING_REVIEW_LABEL,
  WAITING_REVIEW_STATUS,
}

export default dataProcess
