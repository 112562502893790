import PropTypes from 'prop-types'

import { memo, useCallback } from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

import { isEqual } from 'lodash'

const DialogInformation = ({ title, description, open, setOpen }) => {
  const handleClose = useCallback(
    (_, reason) => {
      if (reason !== 'backdropClick') {
        setOpen(false)
      }
    },
    // eslint-disable-next-line
    [],
  )

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick>
      <DialogTitle>
        <Box>
          <Typography variant="h4">{title}</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box my={1}>
        <DialogContent>
          <Box>
            <Typography>{description}</Typography>
          </Box>
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Button type="button" variant="outlined" onClick={handleClose}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogInformation.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
}

DialogInformation.defaultProps = {
  setOpen: () => {},
}

export default memo(DialogInformation, isEqual)
