const env = process.env.REACT_APP_API || 'development'

export const getBaseURL = (project) => envsBaseUrl[project][env]
export const getProfilesURL = () => profilesBaseUrl[env]
export const getGoogleAnalyticsTag = () => googleAnalyticsTag[env]
export const getGoogleRecaptchaToken = () => googleRecaptchaToken[env]
export const getEditorTyneMceToken = () => editorTyneMceToken[env]

const envsBaseUrl = {
  dponet: {
    development: 'http://api.localhost:3001',
    sandbox: 'https://api.sandbox.dponet.com.br',
    production: 'https://api.dponet.com.br',
  },
  website: {
    development: 'http://localhost:3002',
    sandbox: 'https://app.sandbox.dponet.com.br',
    production: 'https://app.dponet.com.br',
  },
}

const profilesBaseUrl = {
  development: 'http://localhost:3001/profiles?auth_token_v2=',
  sandbox: 'https://dpms.sandbox.immunizesystem.com/profiles?auth_token_v2=',
  production: 'https://dpms.immunizesystem.com/profiles?auth_token_v2=',
}

const googleAnalyticsTag = {
  development: '',
  sandbox: 'G-579FW2GB8T',
  production: 'G-RSRSGTQL19',
}

const googleRecaptchaToken = {
  development: '6LcUO9wlAAAAAFP4FAWvGoNeWOtbK-yCq40eKOvC',
  sandbox: '6LcUO9wlAAAAAFP4FAWvGoNeWOtbK-yCq40eKOvC',
  production: '6LfkOdwlAAAAAKtuo2MBKEEFkn9BRr9wvEPRu_6Y',
}

const editorTyneMceToken = {
  development: 'mhfqqnu8h9sdp9poi354uh2bqva111r9vm65bhe462kyux5i',
  sandbox: '04cwdxlxaqi28ar132hvaii2e6v6lmmk8z11ccd3nms3bscj',
  production: '04cwdxlxaqi28ar132hvaii2e6v6lmmk8z11ccd3nms3bscj',
}
