const MASTERCARD_BRAND_LABEL = 'Mastercard'
const VISA_BRAND_LABEL = 'Visa'
const AMEX_BRAND_LABEL = 'Amex'
const ELO_BRAND_LABEL = 'Elo'
const HIPERCARD_BRAND_LABEL = 'Hipercard'

const MASTERCARD_ID = 1
const VISA_ID = 2
const AMEX_ID = 3
const ELO_ID = 4
const HIPERCARD_ID = 5

const AMEX_PATTERN = /^3[47]/
const ELO_PATTERN =
  /^401178|^401179|^431274|^438935|^451416|^457393|^457631|^457632|^504175|^627780|^636297|^636369|^636368|^(506699|5067[0-6]\d|50677[0-8])|^(50900\d|5090[1-9]\d|509[1-9]\d{2})|^65003[1-3]|^(65003[5-9]|65004\d|65005[0-1])|^(65040[5-9]|6504[1-3]\d)|^(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|^(65054[1-9]|6505[5-8]\d|65059[0-8])|^(65070\d|65071[0-8])|^65072[0-7]|^(65090[1-9]|65091\d|650920)|^(65165[2-9]|6516[6-7]\d)|^(65500\d|65501\d)|^(65502[1-9]|6550[3-4]\d|65505[0-8])|^(65092[1-9]|65097[0-8])/
const HIPERCARD_PATTERN =
  /^(384100|384140|384160|606282|637095|637568|60(?!11))/
const MASTERCARD_PATTERN =
  /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/
const VISA_PATTERN = /^4/

const DEFAULT_FORMAT = /(\d{1,4})/g
const AMEX_FORMAT = /(\d{1,4})(\d{1,6})?(\d{1,5})?/

const BRANDS = [
  {
    id: MASTERCARD_ID,
    name: MASTERCARD_BRAND_LABEL,
  },
  {
    id: VISA_ID,
    name: VISA_BRAND_LABEL,
  },
  {
    id: AMEX_ID,
    name: AMEX_BRAND_LABEL,
  },
  {
    id: ELO_ID,
    name: ELO_BRAND_LABEL,
  },
  {
    id: HIPERCARD_ID,
    name: HIPERCARD_BRAND_LABEL,
  },
]

const CARD_FLAG_PATTERN_STANDARD_VALIDATION = {
  [AMEX_ID]: AMEX_PATTERN,
  [ELO_ID]: ELO_PATTERN,
  [HIPERCARD_ID]: HIPERCARD_PATTERN,
  [MASTERCARD_ID]: MASTERCARD_PATTERN,
  [VISA_ID]: VISA_PATTERN,
}

const CARD_FLAG_FORMAT_VALIDATION = {
  [AMEX_ID]: AMEX_FORMAT,
}

const creditCards = {
  CARD_FLAG_FORMAT_VALIDATION,
  DEFAULT_FORMAT,
  BRANDS,
  CARD_FLAG_PATTERN_STANDARD_VALIDATION,
}

export default creditCards
