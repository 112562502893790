import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box } from '@material-ui/core'

import CompanyAuditsTableRow from '../CompanyAuditsTableRow'
import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'

const CompanyAuditsTable = ({ companyAudits }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhuma auditoria encontrada" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="10%" align="left">
                Início (00:00)
              </TableCell>
              <TableCell width="10%" align="left">
                Fim (23:59)
              </TableCell>
              <TableCell width="20%" align="left">
                Empresa
              </TableCell>
              <TableCell width="10%" align="left">
                Auditados
              </TableCell>
              <TableCell width="10%" align="left">
                Confirmados
              </TableCell>
              <TableCell width="10%" align="left">
                Editados
              </TableCell>
              <TableCell width="10%" align="left">
                Enviados para revisão automática
              </TableCell>
              <TableCell width="10%" align="left">
                Enviados para revisão
              </TableCell>
              <TableCell width="10%" align="left">
                Rendimento
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyAudits.map((companyAudit) => (
              <CompanyAuditsTableRow
                companyAudit={companyAudit}
                key={companyAudit.id}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

CompanyAuditsTable.propTypes = {
  CompanyAudits: PropTypes.arrayOf(Object),
}

export default CompanyAuditsTable
