import { useState, useRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { Box, Button, TextField, Link, Hidden } from '@material-ui/core'

import ReCAPTCHA from 'react-google-recaptcha'

import { PasswordInputField } from 'components'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

import { getGoogleRecaptchaToken } from 'service/env'

import { routes } from 'Routes'

import * as service from 'service'
import helpers from 'helpers'

import schemas from '../../schemas'

export const Form = () => {
  const [loading, setLoading] = useState(false)
  const [recaptcha, setRecaptcha] = useState(undefined)

  const recaptchaRef = useRef(null)

  const production = process.env.REACT_APP_API === 'production'
  const disableSubmitButton = production ? !recaptcha || loading : false

  const auth = useAuth()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schemas.login,
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onSubmit = async (data) => {
    if (disableSubmitButton) return

    try {
      setLoading(true)
      await service.dponet.auth.login({
        ...data,
        recaptcha,
      })

      await auth.loadDataWithAnimation()
    } catch (error) {
      if (production) resetRecaptcha()
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error) ||
          'Ocorreu algum erro! Tente novamente!',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const resetRecaptcha = () => {
    recaptchaRef.current.reset()
    setRecaptcha(undefined)
  }

  const handleAcceptedRecaptcha = (recaptchaToken) => {
    setRecaptcha(recaptchaToken)
  }
  const disableRecaptcha = () => setRecaptcha(undefined)

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        as={
          <TextField
            label="E-mail"
            type="email"
            color="primary"
            variant="outlined"
            error={!!errors.email}
            helperText={errors?.email?.message}
            fullWidth
          />
        }
        control={control}
        name="email"
        mode="onBlur"
      />
      <Controller
        as={
          <PasswordInputField
            label="Senha"
            type="password"
            variant="outlined"
            margin="normal"
            error={!!errors.password}
            helperText={errors?.password?.message}
            fullWidth
          />
        }
        control={control}
        name="password"
        mode="onBlur"
      />
      {production && (
        <Box mt={1} display="flex" alignItems="center" justifyContent="center">
          <ReCAPTCHA
            ref={recaptchaRef}
            render="explicit"
            sitekey={getGoogleRecaptchaToken()}
            onChange={handleAcceptedRecaptcha}
            onExpired={disableRecaptcha}
          />
        </Box>
      )}
      <Box
        mt={2}
        display="flex"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Button
          color="primary"
          type="submit"
          variant="contained"
          disabled={disableSubmitButton}
        >
          {loading ? 'Carregando...' : 'Entrar'}
        </Button>
        <Hidden smDown>
          <Link
            component={RouterLink}
            to={routes.forgotPassword}
            variant="body2"
            color="textSecondary"
          >
            Esqueceu sua senha?
          </Link>
        </Hidden>
      </Box>
      <Hidden mdUp>
        <Box mt={2}>
          <Link
            component={RouterLink}
            to={routes.forgotPassword}
            variant="body2"
            color="textSecondary"
          >
            Esqueceu sua senha?
          </Link>
        </Box>
      </Hidden>
    </form>
  )
}

export default Form
