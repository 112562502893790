import { useContext } from 'react'

import DataProcessContext from 'contexts/DataProcessContext'

const useDataProcess = () => {
  const context = useContext(DataProcessContext)

  if (context === undefined) {
    throw new Error(
      'useDataProcess must be used within a DataProcessContext.Provider',
    )
  }

  const toStep = (step, activeStep) => {
    if (step < activeStep) {
      context.setActiveStep(step)
    }
  }

  const nextStep = () => {
    context.setActiveStep((prevActiveStep) => prevActiveStep + 1)
    if (context.lastActiveStep === context.activeStep) {
      context.setLastActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const backStep = () => {
    context.setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  return {
    activeStep: context.activeStep,
    backStep,
    clearLoadedDataProcess: context.clearLoadedDataProcess,
    code: context.code,
    companyId: context.companyId,
    counter: context.counter,
    data: context.data,
    dataCollectedOptions: context.dataCollectedOptions,
    dataCollecteds: context.dataCollecteds,
    dataProcessSources: context.dataProcessSources,
    departments: context.departments,
    discardModes: context.discardModes,
    editLegalFramework: context.editLegalFramework,
    internalAllocationModes: context.internalAllocationModes,
    lastActiveStep: context.lastActiveStep,
    legalFrameworks: context.legalFrameworks,
    loadLogs: context.loadLogs,
    loadRelationOptions: context.loadRelationOptions,
    logs: context.logs,
    necDataCollecteds: context.necDataCollecteds,
    necDataLifeCycles: context.necDataLifeCycles,
    necDataTreatments: context.necDataTreatments,
    nextStep,
    nonComplianceReports: context.nonComplianceReports,
    payload: context.payload,
    protections: context.protections,
    recoveries: context.recoveries,
    reloadDataProcess: context.reloadDataProcess,
    reloadEntireDataProcess: context.reloadEntireDataProcess,
    reloadOptions: context.reloadOptions,
    setActiveStep: context.setActiveStep,
    setCode: context.setCode,
    setCompanyId: context.setCompanyId,
    setData: context.setData,
    setDataCollectedOptions: context.setDataCollectedOptions,
    setDataCollecteds: context.setDataCollecteds,
    setDataProcessSources: context.setDataProcessSources,
    setDepartments: context.setDepartments,
    setDiscardModes: context.setDiscardModes,
    setEditLegalFramework: context.setEditLegalFramework,
    setInternalAllocationModes: context.setInternalAllocationModes,
    setLastActiveStep: context.setLastActiveStep,
    setLegalFrameworks: context.setLegalFrameworks,
    setLogs: context.setLogs,
    setNecDataCollecteds: context.setNecDataCollecteds,
    setNecDataLifeCycles: context.setNecDataLifeCycles,
    setNecDataTreatments: context.setNecDataTreatments,
    setNonComplianceReports: context.setNonComplianceReports,
    setProtections: context.setProtections,
    setRecoveries: context.setRecoveries,
    setShareProcesses: context.setShareProcesses,
    setSourceOptions: context.setSourceOptions,
    setStorageLocations: context.setStorageLocations,
    setStorageTimes: context.setStorageTimes,
    setSubmitting: context.setSubmitting,
    setTitularCategories: context.setTitularCategories,
    setToNextStep: context.setToNextStep,
    setValidForms: context.setValidForms,
    shareProcesses: context.shareProcesses,
    sourceOptions: context.sourceOptions,
    storageLocations: context.storageLocations,
    storageTimes: context.storageTimes,
    submitting: context.submitting,
    titularCategories: context.titularCategories,
    toNextStep: context.toNextStep,
    toStep,
    validForms: context.validForms,
    setLawsOptions: context.setLawsOption,
    lawsOptions: context.lawsOptions,
    laws: context.laws,
    setLaws: context.setLaws,
    volumetryType: context.volumetryType,
    setVolumetryType: context.setVolumetryType,
    submitUpdate: context.submitUpdate,
    setSubmitUpdate: context.setSubmitUpdate,
  }
}

export default useDataProcess
