import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Box, Button, TextField, Grid } from '@material-ui/core'

import { Card, BackButton } from 'components'
import { DepartmentCompanies } from './components'
import { DepartmentUsers } from '../'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import schema from './schema'
import { routes } from 'Routes'
import * as service from 'service'
import theme from 'theme'

const DepartmentForm = ({
  availableUsers,
  availableUsersRefresh,
  department,
  isEdit = false,
  refresh,
  setLoading,
  loading,
}) => {
  const history = useHistory()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors, reset } = useForm({
    validationSchema: schema(isEdit),
    defaultValues: {
      name: department?.name || '',
      description: department?.description || '',
      companyId: department?.company || '',
    },
  })

  const handleRedirect = () => history.push(routes.departments.all)

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      if (isEdit) {
        await service.dponet.departments.put({
          departmentId: department?.id,
          department: { ...data, companyId: department?.company?.id },
        })
      } else {
        const { id } = data?.companyId
        data.company_id = id

        await service.dponet.departments.create({
          department: data,
        })
        handleRedirect()
      }
      snackbar.open({
        message: `departamento ${
          isEdit ? 'atualizado' : 'criado'
        }  com sucesso`,
        variant: 'success',
      })
      reset(data)
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) ?? `Falha ao ${isEdit ? 'atualizar' : 'criar'} o departamento!`,
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
      <Card title="Preencha os dados de departamento">
        <form id="departmentForm" onSubmit={handleSubmit(onSubmit)}>
          <Grid container component={Box} gridGap={20}>
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Nome do departamento"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.name}
                    helperText={errors?.name?.message}
                    fullWidth
                  />
                }
                control={control}
                name="name"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Descrição"
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.description}
                    helperText={errors?.description?.message}
                    fullWidth
                  />
                }
                control={control}
                name="description"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <DepartmentCompanies
                companyName={department?.company?.name || ''}
                control={control}
                errors={errors}
                isEdit={isEdit}
              />
            </Grid>
          </Grid>
        </form>
      </Card>
      {isEdit && (
        <DepartmentUsers
          availableUsers={availableUsers}
          availableUsersRefresh={availableUsersRefresh}
          department={department}
          isLoading={loading}
          refresh={refresh}
          setLoading={setLoading}
        />
      )}
      <Box
        display="flex"
        justifyContent="flex-start"
        gridGap={theme.spacing(1)}
      >
        <BackButton onClick={handleRedirect}>Voltar</BackButton>
        <Button
          form="departmentForm"
          color="primary"
          variant="contained"
          type="submit"
        >
          Salvar
        </Button>
      </Box>
    </Box>
  )
}

DepartmentForm.propTypes = {
  availableUsers: PropTypes.arrayOf(PropTypes.object),
  availableUsersRefresh: PropTypes.func,
  department: PropTypes.object,
  isEdit: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  refresh: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
}

export default DepartmentForm
