import { useEffect, useState } from 'react'

import qs from 'querystring'

import { useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'

import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { CompanyTypeAndSeachInput, LoadingFeedback } from 'components'

import schema from './schema'
import { DepartmentSelect, ProcessSelect } from '../'

import { useSnackbar, useTransferList } from 'hooks'

import { routes } from 'Routes'

import * as service from 'service'

import helpers from 'helpers'
import constants from 'constants/index'

const CopyForm = () => {
  const SOURCE_TYPE_PROCESS = 'process'
  const SOURCE_TYPE_DEPARTMENT = 'department'
  const SOURCE_TYPE_COMPANY = 'company'

  const [loading, setLoading] = useState(false)
  const snackbar = useSnackbar()
  const history = useHistory()
  const { handleSubmit, control, errors, watch, setValue } = useForm({
    validationSchema: schema,
    defaultValues: {
      sourceType: SOURCE_TYPE_COMPANY,
    },
  })

  const sourceType = watch('sourceType')
  const isProcessSelect = sourceType === SOURCE_TYPE_PROCESS
  const isDepartmentSelect = sourceType === SOURCE_TYPE_DEPARTMENT
  const isAllSelect = !isProcessSelect && !isDepartmentSelect

  const originCompany = watch('originCompany')

  const { right } = useTransferList()

  const handleBack = () => history.push(routes.dataProcess.all)
  const handleProcessWithFilter = () => {
    const filters = qs.stringify({
      statusId: constants.dataProcess.APPROVED_STATUS,
      createdAt: new Date().toDateString(),
    })
    history.push(`${routes.dataProcess.all}?${filters}`)
  }

  const onSubmit = async (data) => {
    setLoading(true)
    const companyIds = data.companies?.map((company) => company.id)
    const originCompanyId = data.originCompany?.id
    delete data.companies
    delete data.originCompany

    try {
      await service.dponet.dataProcesses.copyProcesses({
        ...data,
        companyIds,
        originCompanyId,
      })
      snackbar.open({
        message: 'Processos copiados com sucesso!',
        variant: 'success',
      })
      handleProcessWithFilter()
    } catch (e) {
      snackbar.open({
        message: helpers.formatters.errorMessage(e, false),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!!originCompany && sourceType === SOURCE_TYPE_COMPANY) {
      setValue('sourceIds', [originCompany?.id])
    } else {
      if (!isEmpty(right)) {
        setValue(
          'sourceIds',
          right?.map((source) => source?.id),
        )
      } else {
        setValue('sourceIds', undefined)
      }
    }
    //eslint-disable-next-line
  }, [right, sourceType, originCompany])

  return (
    <form id="form-copy-data-process" onSubmit={handleSubmit(onSubmit)}>
      <LoadingFeedback open={loading} />
      <Paper component={Box} p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CompanyTypeAndSeachInput
              control={control}
              errors={errors}
              label="Empresa origem"
              fieldName="originCompany"
            />
          </Grid>
          <Grid item xs={12}>
            <CompanyTypeAndSeachInput
              multiple
              control={control}
              errors={errors}
              label="Destinatários"
              fieldName="companies"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <RadioGroup row>
                  <FormControlLabel
                    value={SOURCE_TYPE_COMPANY}
                    control={<Radio />}
                    label="Todos os processos"
                  />
                  <FormControlLabel
                    value={SOURCE_TYPE_DEPARTMENT}
                    control={<Radio />}
                    label="Por departamento"
                  />
                  <FormControlLabel
                    value={SOURCE_TYPE_PROCESS}
                    control={<Radio />}
                    label="Escolher processos"
                  />
                </RadioGroup>
              }
              name="sourceType"
              control={control}
            />
            {isAllSelect && (
              <Typography variant="caption" color="textSecondary">
                Serão copiados apenas processos aprovados
              </Typography>
            )}
          </Grid>
          <ProcessSelect companyId={originCompany?.id} show={isProcessSelect} />
          <DepartmentSelect
            companyId={originCompany?.id}
            show={isDepartmentSelect}
          />
          <Controller as={<></>} name="sourceIds" control={control} />
        </Grid>
      </Paper>
      <Box width="100%" display="flex" py={2} gridGap={4}>
        <Button disabled={loading} variant="outlined" onClick={handleBack}>
          Voltar
        </Button>
        <Button
          disabled={loading}
          type="submit"
          variant="contained"
          color="primary"
        >
          Copiar
        </Button>
      </Box>
    </form>
  )
}

export default CopyForm
