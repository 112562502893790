import { useState } from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import {
  MenuButton,
  Permitted,
  LoadingBox,
  ConfirmationDialog,
} from 'components'
import { Box, Button, Chip } from '@material-ui/core'
import { routes } from 'Routes'
import { reverse } from 'named-urls'
import { useHistory } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { useSnackbar } from 'hooks'

import helpers from 'helpers'
import constants from 'constants/index'
import * as service from 'service'
import PropTypes from 'react-jss/lib/propTypes'
import useStyles from './styles'

const TableRowIncidents = ({ incident, refresh, ...rest }) => {
  const history = useHistory()
  const [openInactivateDialog, setOpenInactivateDialog] = useState(false)
  const classes = useStyles()

  const navigateToShow = (incidentId) => {
    history.push(
      reverse(routes.incidents.show, {
        incidentId: incidentId,
      }),
    )
  }

  const snackbar = useSnackbar()

  const handleInactive = async () => {
    try {
      await service.dponet.incidents.changeStatus({ incidentId: incident?.id })

      snackbar.open({
        message: 'Incidente inativado com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: 'Falha ao inativar incidente.',
        variant: 'error',
      })
    }
    setOpenInactivateDialog(false)
    refresh()
  }

  return (
    <TableRow {...rest}>
      <TableCell>{incident.id}</TableCell>
      <TableCell>
        {helpers.formatters.date(incident.incidentDatetime, false)}
      </TableCell>
      <TableCell>
        {helpers.formatters.hours(incident.incidentDatetime, false)}
      </TableCell>
      <TableCell>{helpers.formatters.date(incident.createdAt)}</TableCell>
      <TableCell>{incident?.companyName}</TableCell>
      <TableCell>
        <Chip
          size="small"
          className={helpers.incidents.chipStatusColor(
            incident?.status ? 1 : 0,
            classes,
          )}
          label={constants.incidents.STATUSES_LABELS[incident?.status ? 1 : 0]}
        />
      </TableCell>
      <TableCell disableTooltip align="right">
        <MenuButton>
          <Permitted tag={constants.permissions.INCIDENTS.LIST}>
            <Button
              onClick={() => {
                navigateToShow(incident.id)
              }}
              fullWidth
              size="small"
            >
              Visualizar
            </Button>
          </Permitted>
          <Permitted tag={constants.permissions.INCIDENTS.INACTIVATE}>
            <Button
              onClick={() => {
                setOpenInactivateDialog(true)
              }}
              fullWidth
              size="small"
            >
              Inativar
            </Button>
          </Permitted>
        </MenuButton>
      </TableCell>
      <ConfirmationDialog
        open={openInactivateDialog}
        setOpen={setOpenInactivateDialog}
        message="Tem certeza que deseja desativar este incidente?"
        cancelButtonText="Cancelar"
        buttonText="Desativar"
        actionAcceptButton={handleInactive}
      />
    </TableRow>
  )
}

const IncidentsTable = ({ incidents, isLoading, onEvent }) => {
  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Box width="100%">
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
          >
            <Table
              size="medium"
              emptyMessage="Nenhum incidente encontrado"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow>
                  <TableCell width="10%">Identificador</TableCell>
                  <TableCell width="15%">Data do incidente</TableCell>
                  <TableCell width="15%">Hora do incidente</TableCell>
                  <TableCell width="20%">
                    Data de criação do incidente
                  </TableCell>
                  <TableCell width="20%">Empresa</TableCell>
                  <TableCell width="20%">Status</TableCell>
                  <TableCell width="10%" align="right">
                    <Permitted tag={constants.permissions.INCIDENTS.LIST}>
                      Ações
                    </Permitted>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {incidents?.map((incident) => (
                  <TableRowIncidents
                    incident={incident}
                    key={incident.id}
                    refresh={onEvent}
                    hover
                  />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      )}
    </>
  )
}

TableRowIncidents.prototype = {
  incident: PropTypes.object,
}

IncidentsTable.prototype = {
  incidents: PropTypes.object,
  isLoading: PropTypes.bool,
}
export default IncidentsTable
