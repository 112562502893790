import permissions from '../../permissions'

const PAYMENT_ORDER_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE_PAYMENT,
    tag: permissions.PAYMENT_ORDERS.MANAGE_PAYMENT,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.PAYMENT_ORDERS.LIST,
  },
]

export default PAYMENT_ORDER_LIST
