import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Button, Chip } from '@material-ui/core'

import useSnackbar from 'hooks/useSnackbar'

import { TableRow, TableCell } from 'components/Table'
import { MenuButton, Permitted } from 'components'
import ChangeStatusDialog from '../UserChangeStatusDialog'

import useStyles from './styles'
import constants from 'constants/index'
import { routes } from 'Routes'
import { dponet } from 'service'
import helpers from 'helpers'

const TableRowUsers = ({
  user,
  setUser,
  setOpenRegeneratePassword,
  isCollaborator,
  refresh,
}) => {
  const history = useHistory()
  const snackbar = useSnackbar()

  const [changeStatusDialog, setChangeStatusDialog] = useState(false)

  const classes = useStyles()
  const permissions = isCollaborator
    ? constants.permissions.COLLABORATORS
    : constants.permissions.USERS

  const redirectEdit = () =>
    isCollaborator
      ? history.push(
          reverse(routes.collaborators.edit, {
            userId: user.id,
          }),
        )
      : history.push(
          reverse(routes.users.edit, {
            userId: user.id,
          }),
        )

  const enableCourse = async () => {
    try {
      await dponet.moodleLotus.enableCourse({
        userId: user?.id,
      })
      refresh()
      snackbar.open({
        message: 'Curso habilitado com sucesso',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Falha em habilitar o curso!',
        variant: 'error',
      })
    }
  }

  const handleRegeneratePassword = () => {
    setUser(user)
    setOpenRegeneratePassword(true)
  }

  return (
    <>
      <TableRow key={user.id}>
        <TableCell>{user.id}</TableCell>
        <TableCell>{user.name}</TableCell>
        <TableCell>{user.email}</TableCell>
        {isCollaborator && <TableCell>{user?.profile?.name}</TableCell>}
        <TableCell disableTooltip>
          <Chip
            className={clsx(classes.root, {
              [classes.active]: user.active,
              [classes.inactive]: !user.active,
            })}
            align="center"
            size="small"
            label={user.active ? 'Ativo' : 'Inativo'}
          />
        </TableCell>
        <TableCell disableTooltip align="right">
          <Permitted someTags={[permissions.EDIT, permissions.CHANGE_STATUS]}>
            <MenuButton>
              <Permitted tag={permissions.EDIT}>
                <Button fullWidth size="small" onClick={redirectEdit}>
                  Editar
                </Button>
              </Permitted>
              <Permitted
                tag={permissions.CHANGE_STATUS}
                show={isCollaborator || user.active}
              >
                <Button
                  fullWidth
                  size="small"
                  onClick={() => setChangeStatusDialog(true)}
                >
                  {user.active ? 'Inativar' : 'Ativar'}
                </Button>
              </Permitted>
              {!isCollaborator && (
                <Permitted tag={permissions.TEMP_PASSOWRD}>
                  <Button
                    fullWidth
                    size="small"
                    onClick={handleRegeneratePassword}
                  >
                    Redefinir senha
                  </Button>
                </Permitted>
              )}
              <Permitted tag={permissions.EDIT}>
                <Button fullWidth size="small" onClick={enableCourse}>
                  Habilitar acesso ao curso
                </Button>
              </Permitted>
            </MenuButton>
          </Permitted>
        </TableCell>
      </TableRow>

      <ChangeStatusDialog
        user={user}
        isCollaborator={isCollaborator}
        open={changeStatusDialog}
        setOpen={setChangeStatusDialog}
        refresh={refresh}
      />
    </>
  )
}

TableRowUsers.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  setOpenRegeneratePassword: PropTypes.func,
  isCollaborator: PropTypes.bool,
  refresh: PropTypes.func,
}

TableRowUsers.defaultProps = {
  refresh: () => {},
}

export default TableRowUsers
