import Main from './EconomicActivitiesMain'
import New from './EconomicActivitiesNew'
import Edit from './EconomicActivitiesEdit'

const EconomicActivities = {
  Main,
  New,
  Edit,
}

export default EconomicActivities
