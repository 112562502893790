import { makeStyles } from '@material-ui/core'

import styles from './styles'

import logo from 'images/dponet-logo-white.svg'

import { Box, Typography, Card } from '@material-ui/core'
import helpers from 'helpers'
import PropTypes from 'prop-types'

import clsx from 'clsx'
const useStyles = makeStyles(styles)

const StatusHeader = ({ name, id, companyName, statusId }) => {
  const classes = useStyles()

  return (
    <Card
      className={clsx(
        classes.root,
        helpers.dataProcesses.statusColor(statusId, classes),
      )}
    >
      <Box p={2} display="flex" justifyContent="space-between">
        <Typography variant="body1">ID: {id}</Typography>
        <img alt="Logo" src={logo} width="60px" />
      </Box>
      <Box p={2} display="flex" justifyContent="space-between">
        <Typography variant="h3">{name}</Typography>
      </Box>
    </Card>
  )
}

StatusHeader.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  statusId: PropTypes.number.isRequired,
}

export default StatusHeader
