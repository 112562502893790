import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    overflow: 'auto',
    width: 320,
  },
  filterField: {
    marginTop: theme.spacing(2),
  },
  filterSelectField: {
    width: '100% !important',
    marginTop: theme.spacing(2),
  },
  loadingBox: {
    display: 'flex',
    flexGrow: 100,
    width: '100%',
  },
  cardInput: {
    borderColor: 'rgba(0, 0, 0, 0.23)',
    height: 37,
    marginTop: theme.spacing(2),
    cursor: 'pointer',
    userSelect: 'none',
  },
  cardSelected: {
    backgroundColor: theme.colors.dataProcess.borders.primary,
  },
}))

export default styles
