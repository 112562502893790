const CONTROLLER_TYPE = 'CONTROLLER'
const OPERATOR_TYPE = 'OPERATOR'
const SUBOPERATOR_TYPE = 'SUBOPERATOR'

const CONTROLLER_LABEL = 'Controlador'
const OPERATOR_LABEL = 'Operador'
const SUBOPERATOR_LABEL = 'Suboperador'

const OPTIONS_TYPES = [
  { id: CONTROLLER_TYPE, name: CONTROLLER_LABEL },
  { id: OPERATOR_TYPE, name: OPERATOR_LABEL },
  { id: SUBOPERATOR_TYPE, name: SUBOPERATOR_LABEL },
]

const TRANSLATED_TYPE = {
  CONTROLLER: CONTROLLER_LABEL,
  OPERATOR: OPERATOR_LABEL,
  SUBOPERATOR: SUBOPERATOR_LABEL,
}

const dataTreatmentAgent = {
  CONTROLLER_TYPE,
  OPERATOR_TYPE,
  SUBOPERATOR_TYPE,
  CONTROLLER_LABEL,
  OPERATOR_LABEL,
  SUBOPERATOR_LABEL,
  OPTIONS_TYPES,
  TRANSLATED_TYPE,
}

export default dataTreatmentAgent
