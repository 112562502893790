import React from 'react'
import { Card, CardHeader, Divider, Typography, Box } from '@material-ui/core'
import { isEmpty } from 'lodash'

import { Collapse } from 'components'
import DataProcessSourcesTable from '../../../../DataProcessSourcesTable'

import helpers from 'helpers'
import useDataProcess from 'hooks/useDataProcess'

const DataProcessSource = () => {
  const { data } = useDataProcess()

  return (
    <Card>
      <CardHeader title="De onde vêm os dados (origem)" />
      <Divider />
      {isEmpty(data?.dataProcessDataSources) ? (
        <Box m={2}>
          <Typography>Não definido</Typography>
        </Box>
      ) : (
        <Box>
          {data?.dataProcessDataSources?.map((dataSource) => (
            <Box key={dataSource?.id}>
              <Collapse
                mx={0}
                title={<Typography>{dataSource.sourceDescription}</Typography>}
                visibilityChildren={!isEmpty(dataSource.dataProcessSources)}
              >
                {!isEmpty(dataSource.dataProcessSources) && (
                  <>
                    <Typography variant="body2" color="textSecondary">
                      {helpers.dataProcesses.labelDataProcessSourcesTable(
                        dataSource?.dataProcessSources.map(
                          (dataType) => dataType.type,
                        ),
                      )}
                    </Typography>
                    <DataProcessSourcesTable
                      dataProcessSources={dataSource?.dataProcessSources}
                      type={dataSource.dataProcessSources[0].type}
                    />
                  </>
                )}
              </Collapse>
            </Box>
          ))}
        </Box>
      )}
    </Card>
  )
}

export default DataProcessSource
