import { useState } from 'react'
import PropTypes from 'prop-types'

import { ConfirmationDialog, LoadingFeedback } from 'components'

import { useSnackbar } from 'hooks'

import helpers from 'helpers'
import * as service from 'service'

const RemoveLinkedDialog = ({
  open = false,
  paymentContractId,
  preRegistrationId,
  refresh = () => {},
  setOpen = () => {},
}) => {
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()

  const handleRemoveLinked = async () => {
    try {
      setLoading(true)
      await service.dponet.paymentContract.removeLinkSubsidiary({
        paymentContractId,
        preRegistrationId,
      })

      snackbar.open({
        message: 'Vínculo removido com sucesso!',
        variant: 'success',
      })
      refresh()
      setOpen(false)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Falha ao remover vinculo do cliente.',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <ConfirmationDialog
        open={open}
        setOpen={setOpen}
        message="Deseja realmente excluir o vinculo com o cliente?"
        cancelButtonText="Cancelar"
        buttonText="Confirmar"
        actionAcceptButton={handleRemoveLinked}
      />
    </>
  )
}

RemoveLinkedDialog.propTypes = {
  open: PropTypes.bool,
  paymentContractId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  preRegistrationId: PropTypes.number.isRequired,
  refresh: PropTypes.func,
  setOpen: PropTypes.func,
}

export default RemoveLinkedDialog
