import { useState } from 'react'

import { BaseDialog, LoadingFeedback } from 'components'
import { Form } from './components'

import { find } from 'lodash'

import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import * as service from 'service'

const CreatePlanDialog = ({
  plans,
  open,
  setOpen,
  refresh,
  integrationPartner,
  integrationPartnerPlan,
  setUpgradePlans = [],
  setIntegrationPartnerPlan = () => {},
}) => {
  const handleOpen = (state) => {
    setOpen(state)
    if (!state) {
      setTimeout(setIntegrationPartnerPlan, 200)
    }
  }

  const snackbar = useSnackbar()
  const [isLoading, setIsLoading] = useState(false)

  const mountData = (data) => {
    const plan = find(plans, { id: data?.planId })

    return {
      ...data,
      plan,
    }
  }

  const isEdit = !!integrationPartnerPlan?.id

  const onSubmit = async (data) => {
    setIsLoading(true)
    try {
      if (integrationPartner?.id) {
        if (isEdit) {
          await service.dponet.integrationPartnerPlans?.update(
            integrationPartnerPlan?.id,
            data,
          )
        } else {
          await service.dponet.integrationPartnerPlans?.create({
            integrationPartnerId: integrationPartner?.id,
            integrationPartnerPlan: {
              main: false,
              ...data,
            },
          })
        }
        refresh()
      } else {
        setUpgradePlans((upgradePlans) => [...upgradePlans, mountData(data)])
      }

      snackbar.open({
        message: `Plano de upgrade ${
          isEdit ? 'editado' : 'criado'
        } com sucesso.`,
        variant: 'success',
      })
      handleOpen(false)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          `Erro ao ${isEdit ? 'editar' : 'criar'} plano de upgrade.`,
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <BaseDialog
      open={open}
      title={`${isEdit ? 'Editar' : 'Adicionar'} plano de upgrade`}
      actionButtonText="Salvar"
      closeButtonText="Cancelar"
      setOpen={handleOpen}
      formRef="id-new-plan-upgrade"
      dialogSize="md"
    >
      <LoadingFeedback open={isLoading} />
      <Form
        integrationPartnerPlan={integrationPartnerPlan}
        plans={plans}
        onSubmit={onSubmit}
      />
    </BaseDialog>
  )
}

export default CreatePlanDialog
