/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'

import { Page, Container, ContentHeader, DialogLogs } from 'components'

import { Button, Box } from '@material-ui/core'

import { History as HistoryIcon } from '@material-ui/icons'

import * as service from 'service'

import { getBaseURL } from 'service/env'
import { isEmpty } from 'lodash'
import useSnackbar from 'hooks/useSnackbar'

import {
  CardBar,
  OriginNecessary,
  OriginFragility,
  OriginFragilityAndNecessary,
  QuestionContent,
  IncidentContent,
} from './components'

import { Printer as PrinterIcon } from 'react-feather'

import constants from 'constants/index'
import helpers from 'helpers'

const RmcsView = ({ match }) => {
  const snackbar = useSnackbar()

  const [rmc, setRmc] = useState({})
  const [history, setHistory] = useState([])
  const [dialogLogs, setDialogLogs] = useState(false)
  const [isLoadingLogs, setIsLoadingLogs] = useState(true)

  useEffect(() => {
    const getRmc = async () => {
      const response = await service.dponet.nonComplianceReports.get({
        nonComplianceReportId: match.params.nonComplianceReportId,
      })

      setRmc(response?.data?.nonComplianceReport)
    }
    getRmc()
  }, [])

  useEffect(() => {
    const getRmcHistory = async () => {
      setIsLoadingLogs(true)

      const responseLogs = await service.dponet.nonComplianceReports.logs({
        nonComplianceReportId: match.params.nonComplianceReportId,
      })

      setHistory(
        helpers.logs.changeLogs(responseLogs?.data?.dponetAudits || []),
      )
      setIsLoadingLogs(false)
    }

    if (isEmpty(history) || !dialogLogs) getRmcHistory()
  }, [dialogLogs])

  const exportPDF = async () => {
    try {
      const response = await service.dponet.nonComplianceReports.exportPDF({
        id: match.params.nonComplianceReportId,
      })

      snackbar.open({
        message: response?.data?.message || 'PDF exportado com sucesso!',
        variant: 'success',
      })
      window.open(`${getBaseURL('dponet')}${response.data.url}`, '_blank')
    } catch (err) {
      snackbar.open({
        message: 'Ocorreu um erro ao exportar o PDF',
        variant: 'error',
      })
    }
  }

  const contentByFinality = () => {
    switch (rmc.finality) {
      case constants.nonComplianceReports.NECESSARY_FINALITY:
        return <OriginNecessary rmc={rmc} />
      case constants.nonComplianceReports.FRAGILITY_FINALITY:
        return <OriginFragility rmc={rmc} />
      case constants.nonComplianceReports.FRAGILITY_AND_NECESSARY_FINALITY:
        return <OriginFragilityAndNecessary rmc={rmc} />
      default:
        return <></>
    }
  }

  const contentByEntityType = () => {
    switch (rmc.entityType) {
      case constants.nonComplianceReports.ENTITY_DATA_PROCESS:
        return contentByFinality()
      case constants.nonComplianceReports.ENTITY_QUESTION:
        return <QuestionContent rmc={rmc} />
      case constants.nonComplianceReports.ENTITY_INCIDENT:
        return <IncidentContent rmc={rmc} />
      default:
        return <></>
    }
  }

  return (
    <Page title="Detalhes RMCs">
      <Container
        spacingXl={35}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
        maxWidth={false}
      >
        <ContentHeader
          menu="RMC"
          title="Melhoria contínua"
          subtitle="Registro de Melhoria Contínua"
        >
          <Box mr={2}>
            <Button
              variant="contained"
              onClick={() => setDialogLogs(true)}
              startIcon={<HistoryIcon />}
            >
              Histórico
            </Button>
          </Box>
          <Button
            variant="contained"
            onClick={exportPDF}
            startIcon={<PrinterIcon />}
          >
            Imprimir
          </Button>
        </ContentHeader>
        {rmc && (
          <>
            <Box mt={2.5} mb={5}>
              <CardBar rmc={rmc} />
            </Box>
            {contentByEntityType()}
          </>
        )}
      </Container>
      <DialogLogs
        logs={isEmpty(history) ? undefined : history}
        open={dialogLogs}
        setOpen={setDialogLogs}
        showRegisterSession={false}
        isLoading={isLoadingLogs}
      />
    </Page>
  )
}

export default RmcsView
