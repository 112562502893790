import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { MenuButton } from 'components'
import { Box, Chip, Button } from '@material-ui/core'

import useStyles from './styles'
import { useHistory } from 'react-router-dom'

import helpers from 'helpers'
import { reverse } from 'named-urls'
import { routes } from 'Routes'

import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'

const PrivacyPoliciesTableRow = ({ privacyPolicy }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <TableRow>
      <TableCell>{privacyPolicy?.id}</TableCell>
      <TableCell>{privacyPolicy?.title}</TableCell>
      <TableCell>{privacyPolicy?.company?.name}</TableCell>
      <TableCell disableTooltip align="center">
        <Chip
          size="small"
          className={helpers.privacyPolicies.chipStatusColor(
            privacyPolicy?.status,
            classes,
          )}
          label={helpers.privacyPolicies.statusLabel(privacyPolicy?.status)}
        />
      </TableCell>
      <TableCell disableTooltip align="right">
        <MenuButton>
          <Button
            fullWidth
            size="small"
            onClick={() =>
              history.push(
                reverse(routes.privacyPolicy.show, {
                  privacyPolicyId: privacyPolicy?.id,
                }),
              )
            }
          >
            Visualizar
          </Button>
        </MenuButton>
      </TableCell>
    </TableRow>
  )
}

const PrivacyPoliciesTable = ({ privacyPolicies }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhuma política de privacidade encontrada" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="20%">ID</TableCell>
              <TableCell width="25%" align="left">
                Título
              </TableCell>
              <TableCell width="25%" align="left">
                Empresa
              </TableCell>
              <TableCell width="20%" align="left">
                Status
              </TableCell>
              <TableCell width="10%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {privacyPolicies.map((privacyPolicy) => (
              <PrivacyPoliciesTableRow
                privacyPolicy={privacyPolicy}
                key={privacyPolicy.id}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

PrivacyPoliciesTable.propTypes = {
  privacyPolicies: PropTypes.arrayOf(Object),
}

PrivacyPoliciesTableRow.propTypes = {
  privacyPolicy: PropTypes.object,
}

export default PrivacyPoliciesTable
