import Main from './ContractsMain'
import Edit from './ContractEdit'
import New from './ContractNew'
import Show from './ContractShow'

const Contracts = {
  Main,
  Edit,
  New,
  Show,
}

export default Contracts
