/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Autocomplete } from '@material-ui/lab'
import { TextField, CircularProgress } from '@material-ui/core'
import { Controller } from 'react-hook-form'

import helpers from 'helpers'

const CompanyTypeAndSeachInput = ({
  control,
  errors,
  setCompanyId,
  kind,
  handleChangeCompany,
  label,
  fieldName = 'companyId',
  multiple = false,
}) => {
  const [companies, setCompanies] = useState([])
  const [companyInputValue, setCompanyInputValue] = useState('')
  const [isLoadingCompaniesInput, setIsLoadingCompaniesInput] = useState(false)

  const { loadCompanies, instructionsText } = helpers.companies.typeAndSearch
  const { nameWithDocumentByArrayObject } = helpers.companies.nameWithDocument
  const companiesOptionsValues = nameWithDocumentByArrayObject(
    isLoadingCompaniesInput ? [] : companies,
  )

  const onChangeCompany = (data) => {
    setCompanyId(data.id)
    handleChangeCompany()
  }

  const handleTypeCompaniesInput = (e) => {
    setCompanyInputValue(e.target.value)
  }

  const mountCompanyParams = () => {
    return {
      name: companyInputValue,
      minimal: true,
      perPage: 1000 * 1000,
      kind,
    }
  }

  useEffect(() => {
    const getCompanies = setTimeout(() => {
      if (companyInputValue.length < 3) return

      loadCompanies(
        mountCompanyParams(),
        setIsLoadingCompaniesInput,
        setCompanies,
      )
    }, 1000)

    return () => clearTimeout(getCompanies)
  }, [companyInputValue])

  return (
    <Controller
      control={control}
      name={fieldName}
      onChange={([, data]) => {
        onChangeCompany(data)
        return data
      }}
      mode="onBlur"
      as={
        <Autocomplete
          multiple={multiple}
          options={companiesOptionsValues}
          getOptionLabel={(option) => option?.name || ''}
          getOptionSelected={(option, value) =>
            option?.id === value?.id || null
          }
          disableClearable
          noOptionsText={instructionsText(companyInputValue)}
          loading={isLoadingCompaniesInput}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!errors?.[fieldName]}
              onChange={handleTypeCompaniesInput}
              helperText={errors?.[fieldName]?.message}
              label={label}
              variant="outlined"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoadingCompaniesInput ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      }
    />
  )
}

CompanyTypeAndSeachInput.defaultProps = {
  setCompanyId: () => {},
  handleChangeCompany: () => {},
  label: 'Empresa',
  kind: undefined,
  companyId: undefined,
}

CompanyTypeAndSeachInput.propTypes = {
  errors: PropTypes.object,
  control: PropTypes.object,
  Controller: PropTypes.func,
  setCompanyId: PropTypes.func,
  kind: PropTypes.string,
  handleChangeCompany: PropTypes.func,
  label: PropTypes.string,
  companyId: PropTypes.number,
  fieldName: PropTypes.string,
  multiple: PropTypes.bool,
}

export default CompanyTypeAndSeachInput
