import constants from 'constants/index'

const id = (
  cardNumber,
  permittedFlags = constants.creditCards.CARD_FLAG_PATTERN_STANDARD_VALIDATION,
) => {
  const cardNumberDiff = cardNumber?.replace(/[^0-9]+/g, '')

  if (cardNumberDiff) {
    for (const flagId in permittedFlags) {
      if (permittedFlags[flagId].test(cardNumberDiff)) {
        return Number(flagId)
      }
    }
  }

  return undefined
}

const format = (id) => {
  return (
    (id && constants.creditCards.CARD_FLAG_FORMAT_VALIDATION[id]) ||
    constants?.creditCards.DEFAULT_FORMAT
  )
}

const cardFlag = { id, format }

export default cardFlag
