import permissions from '../../permissions'

const DEPARTMENT_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.VISUALIZE,
    tag: permissions.DEPARTMENT.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.MANAGE,
    tag: permissions.DEPARTMENT.CREATE,
  },
  {
    name: 'Vincular Responsável',
    tag: permissions.DEPARTMENT.LINK_USERS,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.ACTIVATE_OR_INACTIVATE,
    tag: permissions.DEPARTMENT.ACTIVATE_OR_INACTIVATE,
  },
]

export default DEPARTMENT_LIST
