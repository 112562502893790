import { Avatar, Box, Card, CardContent, Typography } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'

import LoginForm from './components/Form'
import Page from 'components/Page'

import logo from 'images/dponet-logo-blue.png'
import useStyles from './styles'

const Login = () => {
  const classes = useStyles()

  return (
    <Page className={classes.root} title="Login">
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Avatar className={classes.icon}>
            <LockIcon fontSize="large" />
          </Avatar>
          <Box justifyContent="start" display="flex">
            <img alt="Logo" src={logo} width="100%" className={classes.logo} />
          </Box>
          <Typography variant="h3" color="textPrimary">
            Bem-vindo(a)!
          </Typography>
          <Box mt={3}>
            <LoginForm />
          </Box>
        </CardContent>
      </Card>
    </Page>
  )
}

export default Login
