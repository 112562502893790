import regex from './regex'

const expiringDate = (date) => {
  date = regex.onlyNumbers(date)
  date = date.replace(/(\d{2})(?=\d)/, '$1/')

  return date.slice(0, 5)
}

const number = (value) =>
  regex
    .onlyNumbers(value)
    .replace(/(\d{4})(?=\d)/g, '$1 ')
    .slice(0, 23)

const creditCards = {
  expiringDate,
  number,
}

export default creditCards
