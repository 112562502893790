import { Typography, Grid, TextField } from '@material-ui/core'
import { Select } from 'components'

import { Controller, useForm, FormContext } from 'react-hook-form'

import { CookiePlanSelect } from 'views/IntegrationPartner/components'

import schema from './schema'

const Form = ({ integrationPartnerPlan, plans, onSubmit }) => {
  const { control, errors, handleSubmit, ...rest } = useForm({
    validationSchema: schema(),
    defaultValues: {
      name: integrationPartnerPlan?.name || '',
      planId: integrationPartnerPlan?.plan?.id || '',
      cookiePlan: integrationPartnerPlan?.cookiePlan || '',
      cookiePlanConfig: integrationPartnerPlan?.config,
    },
  })

  return (
    <FormContext
      control={control}
      errors={errors}
      handleSubmit={handleSubmit}
      {...rest}
    >
      <form onSubmit={handleSubmit(onSubmit)} id="id-new-plan-upgrade">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Nome</Typography>
            <Controller
              as={
                <TextField
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  fullWidth
                />
              }
              control={control}
              name="name"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Plano</Typography>
            <Controller
              control={control}
              name="planId"
              mode="onChange"
              as={
                <Select
                  items={plans ?? []}
                  error={!!errors.planId}
                  helperText={errors?.planId?.message}
                  variant="outlined"
                  fullWidth
                />
              }
            />
          </Grid>
          <CookiePlanSelect
            defaultScanChecked={integrationPartnerPlan?.config?.automaticScan}
          />
        </Grid>
      </form>
    </FormContext>
  )
}

export default Form
