import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box } from '@material-ui/core'

import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'
import DocumentationsTableRow from '../DocumentationTableRow/DocumentationsTableRow'

const DocumentationsTable = ({ documentations, refresh }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhuma documentação encontrada" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="25%" align="left">
                Título
              </TableCell>
              <TableCell width="15%" align="left">
                Publicado por
              </TableCell>
              <TableCell width="15%" align="left">
                Categoria
              </TableCell>
              <TableCell width="15%" align="center">
                Visualização
              </TableCell>
              <TableCell width="10%" align="center">
                Última atualização
              </TableCell>
              <TableCell width="5%" align="left">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentations?.map((documentation) => (
              <DocumentationsTableRow
                documentation={documentation}
                refresh={refresh}
                key={documentation.id}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

DocumentationsTable.propTypes = {
  documentations: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func,
}

DocumentationsTable.defaultProps = {
  refresh: () => {},
}

export default DocumentationsTable
