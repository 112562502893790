import React from 'react'
import { useLocation, Router } from 'react-router-dom'
import { ThemeProvider, withStyles } from '@material-ui/styles'
import { createGenerateClassName } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import JssProvider from 'react-jss/lib/JssProvider'

import 'moment/locale/pt-br'
import 'react-perfect-scrollbar/dist/css/styles.css'
import '@kenshooui/react-multi-select/dist/style.css'
import 'configs/yup/locale'

import theme from 'theme'
import history from 'service/history'
import {
  AuthProvider,
  TransferListProvider,
  SelectCountryProvider,
  FilterTagsProvider,
  QueryProvider,
} from 'providers'
import Routes from './Routes'

const defaultGenerateClassName = createGenerateClassName({
  disableGlobal: true,
  productionPrefix: 'c',
})

const App = () => (
  <QueryProvider>
    <JssProvider generateClassName={defaultGenerateClassName}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider locale="pt-br" utils={MomentUtils}>
          <Router history={history}>
            <AuthProvider>
              <FilterTagsProvider>
                <TransferListProvider>
                  <SelectCountryProvider>
                    <ScrollToTop />
                    <Routes />
                  </SelectCountryProvider>
                </TransferListProvider>
              </FilterTagsProvider>
            </AuthProvider>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </JssProvider>
  </QueryProvider>
)

const styles = {
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      height: '100%',
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
    },
    a: {
      textDecoration: 'none',
    },
    '#root': {
      height: '100%',
    },
  },
}

function ScrollToTop() {
  const { pathname } = useLocation()

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export default withStyles(styles)(App)
