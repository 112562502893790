import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { MenuButton, Permitted } from 'components'
import { Box, Chip, Button } from '@material-ui/core'
import helpers from 'helpers'

import useStyles from './styles'
import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import constants from 'constants/index'
import { routes } from 'Routes'
import { ChangeStatus, RemoveDocumentationLink } from '../../components'

const DocumentationLinksTableRow = ({ documentationLink, refresh }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <TableRow>
      <TableCell>{documentationLink?.id}</TableCell>
      <TableCell>{documentationLink?.pageUri}</TableCell>
      <TableCell>{documentationLink?.url}</TableCell>
      <TableCell disableTooltip align="center">
        <Chip
          size="small"
          className={helpers.documentationLinks.chipStatusColor(
            documentationLink?.status,
            classes,
          )}
          label={helpers.documentationLinks.statusName(
            documentationLink?.status,
          )}
        />
      </TableCell>
      <TableCell disableTooltip align="right">
        <Permitted someTags={[constants.permissions.DOCUMENTATION_LINKS.EDIT]}>
          <MenuButton>
            <Permitted tag={constants.permissions.DOCUMENTATION_LINKS.EDIT}>
              <Button
                fullWidth
                size="small"
                onClick={() =>
                  history.push(
                    reverse(routes.documentationLinks.edit, {
                      documentationLinkId: documentationLink?.id,
                    }),
                  )
                }
              >
                Editar
              </Button>
              <ChangeStatus
                documentationLink={documentationLink}
                refresh={refresh}
              />
            </Permitted>
            <Permitted tag={constants.permissions.DOCUMENTATION_LINKS.DESTROY}>
              <RemoveDocumentationLink
                documentationLinkId={documentationLink?.id}
                refresh={refresh}
              />
            </Permitted>
          </MenuButton>
        </Permitted>
      </TableCell>
    </TableRow>
  )
}

const DocumentationLinksTable = ({ documentationLinks, refresh }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhum link de ajuda encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="30%" align="left">
                Página de origem
              </TableCell>
              <TableCell width="30%" align="center">
                Redirecionar
              </TableCell>
              <TableCell width="30%" align="center">
                Status
              </TableCell>
              <TableCell width="5%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentationLinks.map((documentationLink) => (
              <DocumentationLinksTableRow
                refresh={refresh}
                documentationLink={documentationLink}
                key={documentationLink.id}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

DocumentationLinksTable.propTypes = {
  documentationLinks: PropTypes.arrayOf(Object),
  refresh: PropTypes.func,
}

DocumentationLinksTable.defaultProps = {
  refresh: () => {},
}

export default DocumentationLinksTable
