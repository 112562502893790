import permissions from '../../permissions'

const RMC_LISTING_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.VISUALIZE,
    tag: permissions.NON_COMPLIANCE_REPORT.LIST,
  },
]

export default RMC_LISTING_LIST
