import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

import { Grid, TextField } from '@material-ui/core'
import { Card, Select } from 'components'

import helpers from 'helpers'
import constants from 'constants/index'

const MainForm = ({ control, errors, editionPermitted }) => {
  return (
    <Card title="Preencha os dados da empresa">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.name}
                helperText={errors?.name?.message}
                label="Nome fantasia da empresa"
                disabled={!editionPermitted}
              />
            }
            control={control}
            name="name"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                inputProps={{
                  maxLength: 18,
                }}
                error={!!errors.cnpj}
                helperText={errors?.cnpj?.message}
                label="CNPJ"
                disabled={!editionPermitted}
              />
            }
            onChange={([event]) => {
              return helpers.formatters.cnpjInput(event.target.value)
            }}
            control={control}
            name="cnpj"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                inputProps={{
                  maxLength: 18,
                }}
                error={!!errors.cpf}
                helperText={errors?.cpf?.message}
                label="CPF"
                disabled={!editionPermitted}
              />
            }
            onChange={([event]) => {
              return helpers.formatters.cpfInput(event.target.value)
            }}
            control={control}
            name="cpf"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                fullWidth
                multiline
                rows={4}
                error={!!errors.description}
                helperText={errors?.description?.message}
                label="Breve descrição da empresa"
                disabled={!editionPermitted}
              />
            }
            control={control}
            name="description"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.siteUrl}
                helperText={errors?.siteUrl?.message}
                label="URL do site"
                disabled={!editionPermitted}
              />
            }
            onChange={([event]) => {
              return helpers.formatters.website(event.target.value)
            }}
            control={control}
            name="siteUrl"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.email}
                helperText={errors?.email?.message}
                label="E-mail da empresa"
                disabled={!editionPermitted}
              />
            }
            control={control}
            name="email"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.phone}
                helperText={errors?.phone?.message}
                label="Telefone da empresa"
                disabled={!editionPermitted}
              />
            }
            onChange={([event]) => {
              return helpers.formatters.phone(event.target.value)
            }}
            control={control}
            name="phone"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <Select
                items={constants.companies.STATUSES_OBJECT}
                label="Selecione o status da empresa"
                error={!!errors.statusId}
                helperText={errors?.statusId?.message}
                disabled={!editionPermitted}
              />
            }
            control={control}
            name="statusId"
            mode="onBlur"
          />
        </Grid>
      </Grid>
    </Card>
  )
}

MainForm.prototype = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
}

export default MainForm
