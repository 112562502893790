import { TabsForm } from './components'
import {
  Container,
  ContentHeader,
  Page,
  LoadingFeedback,
  MenuButton,
  ButtonLogs,
} from 'components'

import { useFetch } from 'hooks'

import * as service from 'service'

const ContractShow = ({ match }) => {
  const {
    params: { contractId },
  } = match

  const { response, isLoading, refresh } = useFetch(
    service.dponet.paymentContract.show,
    { paymentContractId: contractId },
    [],
  )

  return (
    <Page title="Exibir contrato">
      <LoadingFeedback open={isLoading} />
      <Container maxWidth={false}>
        <ContentHeader
          menu="Contrato"
          title="Exibir contrato"
          subtitle="Exibir contrato"
        >
          <MenuButton>
            <ButtonLogs routes="payment_contracts" entityId={contractId} />
          </MenuButton>
        </ContentHeader>
        {!isLoading && (
          <TabsForm
            refresh={refresh}
            paymentContract={response?.data?.paymentContract}
            mode="show"
          />
        )}
      </Container>
    </Page>
  )
}

export default ContractShow
