import { useState } from 'react'

import PropTypes from 'prop-types'

import useSnackbar from 'hooks/useSnackbar'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'
import { LoadingButton } from 'components'

import * as service from 'service'

import useStyles from './styles'

const IntegrationPartnerInactiveOrActiveDialog = ({
  integrationPartner,
  isActive,
  open,
  setOpen,
  refresh,
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const snackbar = useSnackbar()

  const inactiveOrActive = async () => {
    setLoading(true)
    try {
      await service.dponet.integrationPartners.put(integrationPartner?.id, {
        status: isActive ? 'inactive' : 'active',
      })
      refresh()
      snackbar.open({
        message: `Parceiro ${
          integrationPartner?.active ? 'inativado' : 'ativado'
        }com sucesso`,
        variant: 'success',
      })
    } catch {
      snackbar.open({
        message: `Falha ao inativar parceiro`,
        variant: 'error',
      })
    }
    setOpen(false)
    setLoading(false)
    refresh()
  }

  const noticeText = () => {
    if (isActive) {
      return (
        <Box pb={1}>
          <Typography className={classes.textColor}>
            Tem certeza que deseja inativar este parceiro?
          </Typography>
          <Typography className={classes.text}>
            Se inativado, o parceiro não será mais vísivel para os clientes
          </Typography>
        </Box>
      )
    }

    return (
      <Box pb={1} className={classes.text}>
        <Typography className={classes.textColor}>
          Tem certeza que deseja reativar?
        </Typography>
        <Typography className={classes.text}>
          Se reativada, esse parceiro será vísivel para os clientes
        </Typography>
      </Box>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="change-last-update-status"
      fullWidth
    >
      <DialogTitle>
        <Typography className={classes.textTitle}>
          {`TEM CERTEZA QUE DESEJA ${
            isActive ? 'INATIVAR' : 'REATIVAR'
          } ESSE PARCEIRO?`}
        </Typography>
      </DialogTitle>
      <Divider />
      <Box>
        <DialogContent>{noticeText()}</DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <LoadingButton
          variant="contained"
          type="submit"
          color="primary"
          text={isActive ? 'INATIVAR' : 'ATIVAR'}
          loading={loading}
          onClick={inactiveOrActive}
        />
      </DialogActions>
    </Dialog>
  )
}

IntegrationPartnerInactiveOrActiveDialog.propTypes = {
  integrationPartner: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
}

IntegrationPartnerInactiveOrActiveDialog.defaultProps = {
  integrationPartner: {},
  setOpen: () => {},
  refresh: () => {},
}

export default IntegrationPartnerInactiveOrActiveDialog
