import { Grid, TextField } from '@material-ui/core'
import { Select } from 'components'
import { Controller } from 'react-hook-form'

import constants from 'constants/index'
import helpers from 'helpers'

const Form = ({ disabled, control, errors, setValue }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Controller
          disabled={disabled}
          control={control}
          name="card.name"
          mode="onChange"
          as={
            <TextField
              label="Nome do titular no cartão"
              fullWidth
              variant="outlined"
              error={!!errors?.card?.name}
              helperText={errors?.card?.name?.message}
            />
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          disabled={disabled}
          control={control}
          name="card.number"
          mode="onChange"
          onChange={([e]) => {
            const value = helpers.formatters.creditCards.number(
              e?.target?.value,
            )
            e.target.value = value
            setValue('card.brand', helpers.functions.cardFlag.id(value) ?? 1)
            return e
          }}
          as={
            <TextField
              label="Número do cartão"
              fullWidth
              variant="outlined"
              error={!!errors?.card?.number}
              helperText={errors?.card?.number?.message}
            />
          }
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          disabled={disabled}
          control={control}
          name="card.cvv"
          mode="onChange"
          as={
            <TextField
              label="CVV"
              InputProps={{
                maxLength: 4,
              }}
              fullWidth
              variant="outlined"
              error={!!errors?.card?.cvv}
              helperText={errors?.card?.cvv?.message}
            />
          }
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          disabled={disabled}
          control={control}
          onChange={([e]) => {
            e.target.value = helpers.formatters.creditCards.expiringDate(
              e?.target?.value,
            )
            return e
          }}
          name="card.expiration"
          mode="onChange"
          as={
            <TextField
              label="Data de expiração (mm/aa)"
              fullWidth
              variant="outlined"
              error={!!errors?.card?.expiration}
              helperText={errors?.card?.expiration?.message}
            />
          }
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Controller
          disabled={disabled}
          control={control}
          name="card.brand"
          mode="onChange"
          as={
            <Select
              disabled={disabled}
              label="Bandeira do cartão"
              items={constants.creditCards.BRANDS}
            />
          }
        />
      </Grid>
    </Grid>
  )
}

export default Form
