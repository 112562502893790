import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
}))
export default styles
