import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  infoCard: {
    border: '1px solid',
    borderColor: '#e0e0e0',
    borderRadius: '4px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '12px',
    paddingBottom: '12px',
  },
  pending: {
    textTransform: 'uppercase',
    backgroundColor: colors.cyan[800],
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: '4px',
    height: '21px',
  },
  onTime: {
    textTransform: 'uppercase',
    backgroundColor: colors.blue[800],
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: '4px',
    height: '21px',
  },
  substituted: {
    textTransform: 'uppercase',
    backgroundColor: colors.grey[800],
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: '4px',
    height: '21px',
  },
  finalized: {
    textTransform: 'uppercase',
    backgroundColor: colors.green[800],
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: '4px',
    height: '21px',
  },
  overdue: {
    textTransform: 'uppercase',
    backgroundColor: colors.red[800],
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: '4px',
    height: '21px',
  },
  frozen: {
    textTransform: 'uppercase',
    backgroundColor: colors.teal[800],
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: '4px',
    height: '21px',
  },
  inactive: {
    textTransform: 'uppercase',
    backgroundColor: colors.grey[800],
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: '4px',
    height: '21px',
  },
}))

export default styles
