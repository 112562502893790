import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  Typography,
} from '@material-ui/core'

import { LoadingFeedback } from 'components'
import LogsTable from './components/LogsTable'

const DialogLogs = ({
  logs,
  open,
  showRegisterSession,
  setOpen,
  isLoading,
}) => {
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth fullWidth>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Histórico de alterações</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <LoadingFeedback open={isLoading} />

        {!logs ? (
          <Typography>
            Não existe nenhum histórico de alterações para este registro.
          </Typography>
        ) : (
          <LogsTable logs={logs} showRegisterSession={showRegisterSession} />
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button type="button" variant="outlined" onClick={() => setOpen(false)}>
          Voltar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DialogLogs.propTypes = {
  logs: PropTypes.array,
  open: PropTypes.bool,
  showRegisterSession: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
}

DialogLogs.defaultProps = {
  showRegisterSession: true,
  setOpen: () => {},
}

export default DialogLogs
