import PropTypes from 'prop-types'

import { Chip } from '@material-ui/core'

import useStyles from './styles'

import helpers from 'helpers'

import clsx from 'clsx'

const DataProcessFragilityChip = ({ title, fragilityId }) => {
  const classes = useStyles()

  return (
    <Chip
      className={clsx(
        classes.root,
        helpers.dataProcesses.fragilityColor(fragilityId, classes),
      )}
      align="center"
      size="small"
      label={title || '-'}
    />
  )
}

DataProcessFragilityChip.propTypes = {
  title: PropTypes.string.isRequired,
  fragilityId: PropTypes.number,
}

export default DataProcessFragilityChip
