import api from 'service/api'

const dponetAPI = api.create('dponet')

const update = async ({ questionnaireId, questionId, ...rest }) => {
  return await dponetAPI.put(
    `/questionnaires/${questionnaireId}/questions/${questionId}`,
    rest,
  )
}

const create = async ({ questionnaireId, ...rest }) => {
  return await dponetAPI.post(
    `/questionnaires/${questionnaireId}/questions`,
    rest,
  )
}

const inactivate = async ({ questionnaireId, questionId, ...rest }) => {
  return await dponetAPI.put(
    `/questionnaires/${questionnaireId}/questions/${questionId}/inactivate`,
    rest,
  )
}

const question = {
  update,
  create,
  inactivate,
}

export default question
