import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { Button } from '@material-ui/core'
import { isEmpty } from 'lodash'

import { MenuButton, DialogLogs, LoadingFeedback } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import incidentReportPdf from 'helpers/reports/simplifiedIncident/incidentReportPdf'
import completeIncidentReportPdf from 'helpers/reports/completeIncident/completeIncidentReportPdf'

import * as service from 'service'
import helpers from 'helpers'

const IncidentMenuItems = ({ incident, ...rest }) => {
  const snackbar = useSnackbar()

  const [dialogLogs, setDialogLogs] = useState(false)
  const [isLoadingLogs, setIsLoadingLogs] = useState(true)
  const [updatedLogs, setUpdatedLogs] = useState([])
  const [loadingExportReport, setLoadingExportReport] = useState(false)

  const incidentId = incident?.id
  const companyId = incident?.companyId
  const simplifiedForm = incident?.simplifiedForm
  const generateIncidentReport = async () => {
    try {
      setLoadingExportReport(true)
      const [{ data: incident }, { data: company }] = await Promise.all([
        service.dponet.incidents.show({ incidentId }),
        service.dponet.companies.get({ companyId }),
      ])

      simplifiedForm
        ? await incidentReportPdf(company?.company, incident)
        : await completeIncidentReportPdf(company?.company, incident)
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.message ||
          'Erro ao gerar o Relatório de Incidentes simplificado',
        variant: 'error',
      })
    } finally {
      setLoadingExportReport(false)
    }
  }

  const items = [
    {
      label: 'HISTÓRICO DE ALTERAÇÕES',
      action: () => setDialogLogs(true),
    },
  ]

  items.push({
    label: 'EXPORTAR RELATÓRIO',
    action: () => generateIncidentReport(),
  })

  useEffect(() => {
    const getLogs = async () =>
      await service.dponet.incidents.logs({
        incidentId,
      })

    if (dialogLogs && isEmpty(updatedLogs)) {
      setIsLoadingLogs(true)
      getLogs().then((response) => {
        const updated_logs = helpers.logs.changeLogs(
          response?.data?.dponetAudits || [],
        )
        setUpdatedLogs(updated_logs)
        setIsLoadingLogs(false)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogLogs])

  return (
    <>
      <LoadingFeedback open={loadingExportReport} />
      <MenuButton iconButton={false} {...rest}>
        {items.map((item) => (
          <Button
            key={item.label}
            fullWidth
            onClick={() => {
              item.action()
            }}
          >
            {item.label}
          </Button>
        ))}
      </MenuButton>

      <DialogLogs
        open={dialogLogs}
        setOpen={setDialogLogs}
        logs={isEmpty(updatedLogs) ? undefined : updatedLogs}
        showRegisterSession={false}
        isLoading={isLoadingLogs}
      />
    </>
  )
}

IncidentMenuItems.propTypes = {
  incident: PropTypes.object,
}

export default IncidentMenuItems
