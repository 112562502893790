import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box } from '@material-ui/core'

import ContractsTableRow from '../ContractsTableRow'
import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'

const ContractsTable = ({ paymentContracts, refresh }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: true, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhum contrato encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="15%" align="left">
                Numero do contrato
              </TableCell>
              <TableCell width="20%" align="left">
                Cliente/Subclicenciamento
              </TableCell>
              <TableCell width="10%" align="left">
                Status
              </TableCell>
              <TableCell width="15%" align="center">
                Mensalidade
              </TableCell>
              <TableCell width="15%" align="left">
                Próximo faturamento
              </TableCell>
              <TableCell width="15%" align="left">
                Próximo vencimento
              </TableCell>
              <TableCell width="5%" align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentContracts?.map((paymentContract) => (
              <ContractsTableRow
                paymentContract={paymentContract}
                key={paymentContract?.id}
                refresh={refresh}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

ContractsTable.propTypes = {
  preRegistrations: PropTypes.arrayOf(Object),
}

export default ContractsTable
