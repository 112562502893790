import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  buttonViewAll: {
    backgroundColor: theme.palette.BlueDownDark,
  },
  buttonView: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  viewText: {
    marginLeft: '5px',
    color: 'white',
  },
  boxBackSave: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  buttonSave: {
    backgroundColor: theme.palette.BlueDownDark,
    color: 'white',
  },
  bodyUpdate: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  description: {
    fontFamily: 'Roboto',
    fontSize: theme.spacing(1.75),
    fontWeight: theme.spacing(62.5),
    width: '100%',
    height: '200px',
    resize: 'vertical',
  },
  errorDescription: {
    fontFamily: 'Roboto',
    fontSize: theme.spacing(1.75),
    fontWeight: theme.spacing(62.5),
    borderColor: theme.palette.error.light,
    width: '100%',
    height: '200px',
    resize: 'vertical',
  },
  buttonIcons: {
    minWidth: theme.spacing(3),
    padding: 0,
  },
  errorDescriptionMessage: {
    color: theme.palette.error.light,
    fontSize: '0.75rem',
    margin: 0,
    marginTop: '3px',
    textAlign: 'left',
    fontWeight: '400',
    lineHeight: '1.66',
    marginLeft: theme.spacing(1.75),
    marginRight: theme.spacing(1.75),
  },
  descriptionDialog: {
    fontFamily: 'Roboto',
    fontSize: theme.spacing(2.5),
    fontWeight: theme.spacing(50),
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    '& img': {
      maxWidth: '100%',
    },
  },
  menuButton: {
    margin: '0 5px',
  },
}))
export default styles
