import constants from 'constants/index'

const getOriginName = (nonComplianceReport) => {
  if (
    nonComplianceReport?.entityType ===
    constants.nonComplianceReports.ENTITY_DATA_PROCESS
  ) {
    if (nonComplianceReport?.entity?.name) {
      return {
        name: nonComplianceReport?.entity?.name,
        id: nonComplianceReport?.entity?.id,
      }
    }
    return {
      name: nonComplianceReport?.entity?.dataProcess?.name,
      id: nonComplianceReport?.entity?.dataProcess?.id,
    }
  }

  if (
    nonComplianceReport?.entityType ===
    constants.nonComplianceReports.ENTITY_QUESTION
  ) {
    return { name: nonComplianceReport?.questionTitle }
  }

  if (
    nonComplianceReport?.entityType ===
    constants.nonComplianceReports.ENTITY_INCIDENT
  ) {
    if (nonComplianceReport?.entity?.description) {
      return { name: nonComplianceReport.entity.description }
    }
    return { name: nonComplianceReport?.entity?.incident?.description }
  }
  return ''
}

export default getOriginName
