import { colors } from '@material-ui/core'

const styles = (theme) => ({
  nameEllipsis: {
    whiteSpace: 'noWrap',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '20vw',
  },
  pending: {
    textTransform: 'uppercase',
    backgroundColor: colors.cyan[800],
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: '4px',
    height: '21px',
  },
  onTime: {
    textTransform: 'uppercase',
    backgroundColor: colors.blue[800],
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: '4px',
    height: '21px',
  },
  substituted: {
    textTransform: 'uppercase',
    backgroundColor: colors.grey[800],
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: '4px',
    height: '21px',
  },
  finalized: {
    textTransform: 'uppercase',
    backgroundColor: colors.green[800],
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: '4px',
    height: '21px',
  },
  overdue: {
    textTransform: 'uppercase',
    backgroundColor: colors.red[800],
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: '4px',
    height: '21px',
  },
  frozen: {
    textTransform: 'uppercase',
    backgroundColor: colors.teal[800],
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: '4px',
    height: '21px',
  },
  inactive: {
    textTransform: 'uppercase',
    backgroundColor: colors.grey[800],
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: '4px',
    height: '21px',
  },
  disableLink: {
    pointerEvents: 'none',
  },
  disabledTableCell: {
    color: colors.grey[500],
  },

  // Classes de estilo do chip de risco do processo

  lowFragility: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: '4px',
    height: '21px',
  },
  mediumFragility: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: '4px',
    height: '21px',
  },
  highFragility: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: '4px',
    height: '21px',
  },
  severeFragility: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: '4px',
    height: '21px',
  },
})

export default styles
