import MuiTableCell from './MuiTableCell'
import MuiButton from './MuiButton'
import MuiFormLabel from './MuiFormLabel'
import MuiDialogActions from './MuiDialogActions'
import MuiPopover from './MuiPopover'
import MuiSelect from './MuiSelect'
import MuiStepLabel from './MuiStepLabel'
import MuiTab from './MuiTab'

const overrides = {
  MuiTableCell,
  MuiButton,
  MuiFormLabel,
  MuiDialogActions,
  MuiPopover,
  MuiSelect,
  MuiStepLabel,
  MuiTab,
}

export default overrides
