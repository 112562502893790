const filterObject = (value, list, isNumeric = false) => {
  const objectId = isNumeric ? Number(value) : value

  const getObject = (objectId) => {
    return list?.filter((object) => object.id === objectId)
  }

  if (getObject(objectId)) {
    return getObject(objectId)[0]?.name
  }
}

export default filterObject
