import { Page, Container, ContentHeader } from 'components'
import { PlansForm } from './components'

const PlansNew = () => {
  return (
    <Page title="Novo plano">
      <Container maxWidth={false}>
        <ContentHeader title="Novo" menu="Plano" subtitle="Plano" />
        <PlansForm />
      </Container>
    </Page>
  )
}
export default PlansNew
