import PropTypes from 'prop-types'
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'

import { Label } from 'components'

import helpers from 'helpers'
import useStyles from '../../styles'

const AddressCard = ({ address }) => {
  const classes = useStyles()

  return (
    <Card>
      <CardHeader title="Dados de endereço" />
      <Divider />
      <Box className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Label title="CEP">
              <Typography>
                {helpers.formatters.cep(address?.postalCode)}
              </Typography>
            </Label>
          </Grid>
          <Grid item xs={12} md={4}>
            <Label title="País">
              <Typography>{address?.country}</Typography>
            </Label>
          </Grid>
          <Grid item xs={12} md={4}>
            <Label title="Estado">
              <Typography>{address?.state}</Typography>
            </Label>
          </Grid>
          <Grid item xs={12} md={4}>
            <Label title="Cidade">
              <Typography>{address?.city}</Typography>
            </Label>
          </Grid>
          <Grid item xs={12} md={4}>
            <Label title="Bairro">
              <Typography>{address?.neighborhood}</Typography>
            </Label>
          </Grid>
          <Grid item xs={12} md={4}>
            <Label title="Rua">
              <Typography>{address?.street}</Typography>
            </Label>
          </Grid>
          <Grid item xs={12} md={4}>
            <Label title="Complemento">
              <Typography>{address?.complement || '-'}</Typography>
            </Label>
          </Grid>
          <Grid item xs={12} md={4}>
            <Label title="Número">
              <Typography>{address?.number}</Typography>
            </Label>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

AddressCard.propTypes = {
  address: PropTypes.object,
}

export default AddressCard
