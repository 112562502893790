import { Container, ContentHeader, Page } from 'components'
import { DocumentationForm } from './components'

const DocumentationsNew = () => {
  return (
    <Page title="Nova documentação">
      <Container spacingXl={40} spacingLg={25} spacingMd={10} spacingSm={10}>
        <ContentHeader
          menu="Atualizações"
          title="Documentação"
          subtitle="Documentação"
        />
        <DocumentationForm editionPermitted />
      </Container>
    </Page>
  )
}

export default DocumentationsNew
