import palette from 'theme/palette'

import { logoBlue } from '../../assets/logoBlue64'

import { ratio } from '../../helpers'

import {
  WIDTH as WidthPdf,
  HEIGHT as HeightPdf,
  fontSize,
  PADDING,
} from '../../constants'

const disclaimer = (pdf, date) => {
  const WIDTH = WidthPdf(pdf)
  const HEIGHT = HeightPdf(pdf)

  pdf.addPage()
  pdf.setFillColor(palette.white)
  pdf.rect(0, 0, WIDTH, HEIGHT, 'F')

  pdf.addImage(
    logoBlue,
    'png',
    WIDTH / 2 - PADDING * 1.25,
    PADDING,
    ratio(500),
    ratio(158),
  )
  pdf.setFontSize(fontSize.subtitle)
  pdf.setLineHeightFactor(1.2)
  pdf.setFont('Raleway-Bold')

  pdf.setTextColor(palette.BlueDownDark)
  pdf.text(
    'Aviso Importante',
    PADDING,
    HEIGHT / 2 - ratio(300),
    null,
    null,
    'left',
  )

  pdf.setFontSize(fontSize.paragraph)
  pdf.setFont('Raleway-Regular')
  var splited_infos = pdf.splitTextToSize(
    '     Este relatório não deve ser utilizado para comunicar a Autoridade Nacional de Proteção de Dados (ANPD) sobre a ocorrência de um incidente.',
    WIDTH - PADDING * 2,
  )
  pdf.text(
    splited_infos,
    PADDING,
    HEIGHT / 2 - ratio(150),
    null,
    null,
    'justify',
  )

  pdf.text(
    date,
    WIDTH / 2 - PADDING * 0.75,
    HEIGHT - PADDING,
    null,
    null,
    'justify',
  )
}

export default disclaimer
