import { useFetch } from 'hooks'
import { Grid, Box } from '@material-ui/core'
import {
  User as UserIcon,
  Check as CheckIcon,
  Moon as MoonIcon,
} from 'react-feather'

import {
  Page,
  Container,
  ContentHeader,
  LoadingFeedback,
  StatusCard,
} from 'components'
import { ProfilesForm, ProfilesChangeStatus } from 'components/Profiles'

import * as service from 'service'
import palette from 'theme/palette'

const CollaboratorProfilesEdit = ({ match }) => {
  const { collaboratorProfileId } = match.params

  const { response, isLoading, refresh } = useFetch(
    service.dponet.collaboratorProfiles.get,
    {
      profileId: collaboratorProfileId,
    },
  )

  const profile = response?.data?.profile

  return (
    <Page title="Edição de perfil">
      <LoadingFeedback open={isLoading} />
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Edição"
          menu="Perfis de colaborador"
          subtitle="Edição de perfil de colaborador"
        >
          <ProfilesChangeStatus
            isCollaborator={true}
            profile={profile}
            reloadProfile={refresh}
          />
        </ContentHeader>

        {!isLoading && (
          <Box width="100%" mt={4}>
            <Box mb={4}>
              <Grid container spacing={2}>
                <StatusCard
                  backgroundIconColor={palette.primary.main}
                  icon={<UserIcon color={palette.white} />}
                  label="Usuários vínculados"
                  value={String(profile?.linkedUsers)}
                />
                <StatusCard
                  backgroundIconColor={
                    profile?.status
                      ? palette.greenPantone
                      : palette.secondary.light
                  }
                  icon={
                    profile?.status ? (
                      <CheckIcon size={18} color="white" />
                    ) : (
                      <MoonIcon size={18} color="white" />
                    )
                  }
                  label="Status"
                  value={profile?.status ? 'Ativo' : 'Inativo'}
                />
              </Grid>
            </Box>
            <ProfilesForm isEdit profile={profile} />
          </Box>
        )}
      </Container>
    </Page>
  )
}

export default CollaboratorProfilesEdit
