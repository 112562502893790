import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from '@material-ui/core'

import schema from './schema'

const AddInviteAdvisor = ({
  onSubmit,
  disabled,
  setDisabled,
  invite,
  buttonClick,
  setButtonClick,
}) => {
  const [checked, setChecked] = useState(invite?.fantasyDpo)

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      email: invite?.email ?? '',
      fantasyDpo: invite?.fantasyDpo || '',
    },
  })

  useEffect(() => {
    if (disabled) setDisabled(false)
    //eslint-disable-next-line
  }, [disabled])

  useEffect(() => {
    if (buttonClick) {
      handleSubmit(onSubmit)()
      setButtonClick(false)
    }
    // eslint-disable-next-line
  }, [buttonClick])

  return (
    <Box my={2}>
      <form>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  label="Email"
                  type="email"
                  color="primary"
                  variant="outlined"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  fullWidth
                />
              }
              control={control}
              name="email"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              }
              labelPlacement="end"
              label="Ativar DPO Fantasia"
            />
          </Grid>
          {checked && (
            <Grid item xs={12}>
              <Controller
                as={
                  <TextField
                    label="Nome"
                    variant="outlined"
                    error={!!errors.fantasyDpo}
                    helperText={errors?.fantasyDpo?.message}
                    fullWidth
                  />
                }
                control={control}
                name="fantasyDpo"
                mode="onBlur"
              />
            </Grid>
          )}
        </Grid>
      </form>
    </Box>
  )
}

AddInviteAdvisor.propTypes = {
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  setDisabled: PropTypes.func,
  invite: PropTypes.object,
  buttonClick: PropTypes.func,
  setButtonClick: PropTypes.func,
}

AddInviteAdvisor.defaultProps = {
  onSubmit: () => {},
  setDisabled: () => {},
  invite: {},
  buttonClick: () => {},
  setButtonClick: () => {},
}

export default AddInviteAdvisor
