import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ profileId = '', ...params }) => {
  return await dponetAPI.get(`/collaborator_profiles/${profileId}`, { params })
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('/collaborator_profiles', data)
}

const edit = async ({ profileId = '', ...data }) => {
  return await dponetAPI.put(`/collaborator_profiles/${profileId}`, data)
}

const changeStatus = async (profileId) => {
  return await dponetAPI.put(
    `/collaborator_profiles/${profileId}/change_status`,
  )
}

const collaboratorProfiles = {
  get,
  create,
  edit,
  changeStatus,
}

export default collaboratorProfiles
