import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'

import { Label, Select as SelectComponent } from 'components'

import { useForm } from 'react-hook-form'
import dataLifeCycleSchema from '../schema'
import helpers from 'helpers'

import useDataProcess from 'hooks/useDataProcess'

import useStyles from './styles'
import constants from 'constants/index'

const Form = ({ onSubmit, dataLifeCycle, dataProcess }) => {
  const {
    STORY_MODE_PERMANENT_TYPE,
    STORAGE_DEFINED_TYPE,
    STORAGE_UNDEFINED_TYPE,
    STORAGE_PERMANENT_TYPE,
  } = constants.dataProcess

  const volumetryOptions = helpers.dataProcesses.selectVolumetryOptions()

  const {
    storageLocations,
    internalAllocationModes,
    discardModes,
    recoveries,
    protections,
  } = useDataProcess()

  const storageMode = dataProcess?.storageMode
  const storageTypeFormated = dataLifeCycle?.storageType

  const classes = useStyles()
  const { handleSubmit, errors, watch, control, setError } = useForm({
    validationSchema: dataLifeCycleSchema,
    defaultValues: {
      storageLocationId: dataLifeCycle?.storageLocation?.id || '',
      internalAllocationModeId: dataLifeCycle?.internalAllocationMode?.id || '',
      storageType: `${storageTypeFormated}` ?? '',
      value: dataLifeCycle?.value || '',
      volumetry: dataLifeCycle?.volumetry || '',
      retentionFinality: dataLifeCycle?.retentionFinality || '',
      discardModeId: dataLifeCycle?.discardMode?.id || '',
      recoveryId: dataLifeCycle?.recovery?.id || '',
      protectionId: dataLifeCycle?.protection?.id || '',
      anotherStorageLocation: dataLifeCycle?.storageLocation?.id || '',
      anotherDiscardMode: dataLifeCycle?.discardMode?.id || '',
      anotherRecovery: dataLifeCycle?.recovery?.id || '',
    },
  })

  return (
    <form
      onSubmit={handleSubmit((data) => onSubmit(data, watch, setError))}
      id="data-life-cycle-form"
    >
      <Grid container spacing={2} alignItems="center" className={classes.root}>
        <Grid item md={6} xs={12}>
          <Controller control={control} name="dataLifeCycleId" as={<></>} />
          <Controller
            control={control}
            name="storageLocationId"
            as={
              <SelectComponent
                items={storageLocations}
                label="Onde os dados são armazenados?"
                anotherOption
                error={!!errors?.storageLocationId}
                helperText={
                  errors?.storageLocationId?.message ||
                  'Selecione o local em que os dados são armazenados.'
                }
              />
            }
            mode="onBlur"
          />
          {watch('storageLocationId') === 'another' && (
            <Box mr={0} mt={1}>
              <Controller
                name="anotherStorageLocation"
                control={control}
                as={
                  <TextField
                    label="Outro: onde os dados são armazenados?"
                    color="primary"
                    variant="outlined"
                    error={!!errors.anotherStorageLocation}
                    helperText={errors?.anotherStorageLocation?.message}
                    fullWidth
                  />
                }
                mode="onBlur"
              />
            </Box>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            name="internalAllocationModeId"
            control={control}
            as={
              <SelectComponent
                items={internalAllocationModes}
                label="Ambiente de alocação"
                error={!!errors.internalAllocationModeId}
                helperText={
                  errors.internalAllocationModeId?.message ||
                  'A informação é alocada em que ambiente?'
                }
              />
            }
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}>
          <Box mt={1}>
            <Label
              title="Tempo de armazenamento"
              description="Por quanto tempo esses dados serão armazenados"
              item
            >
              <Controller
                control={control}
                name="storageType"
                as={
                  <RadioGroup>
                    <FormControlLabel
                      key={`${STORAGE_DEFINED_TYPE}`}
                      value={`${STORAGE_DEFINED_TYPE}`}
                      control={<Radio />}
                      label="Definido"
                    />
                    <FormControlLabel
                      key={`${STORAGE_UNDEFINED_TYPE}`}
                      value={`${STORAGE_UNDEFINED_TYPE}`}
                      control={<Radio />}
                      label="Indefinido"
                    />
                    {storageMode === STORY_MODE_PERMANENT_TYPE && (
                      <FormControlLabel
                        key={`${STORAGE_PERMANENT_TYPE}`}
                        value={`${STORAGE_PERMANENT_TYPE}`}
                        control={<Radio />}
                        label="Permanente"
                      />
                    )}
                  </RadioGroup>
                }
                mode="onChange"
              />
            </Label>
            {watch('storageType') === `${STORAGE_DEFINED_TYPE}` && (
              <>
                <Grid container spacing={1}>
                  <Grid item lg={6} xs={12}>
                    <Label
                      title="Valor"
                      description="Qual é a quantidade do tempo?"
                      item
                    >
                      <Controller
                        name="value"
                        control={control}
                        as={
                          <TextField
                            type="number"
                            variant="outlined"
                            error={!!errors.value}
                            fullWidth
                            helperText={
                              errors?.value?.message ||
                              errors?.value?.types?.type?.invalid
                            }
                          />
                        }
                        mode="onBlur"
                        onChange={([e]) =>
                          e.target.value < 0 ? '0' : e.target.value
                        }
                      />
                    </Label>
                  </Grid>

                  <Grid item lg={6} xs={12}>
                    <Label
                      title="Unidade de tempo"
                      description="Qual é o formato do tempo?"
                      item
                    >
                      <Controller
                        name="volumetry"
                        control={control}
                        as={
                          <SelectComponent
                            items={volumetryOptions}
                            error={!!errors.volumetry}
                            fullWidth
                            helperText={
                              errors.volumetry?.message ||
                              errors?.value?.types?.type?.invalid
                            }
                          />
                        }
                        mode="onBlur"
                      />
                    </Label>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      *Atenção: o tempo informado deve ser menor ou igual ao das
                      informações gerais do processo
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Box>
        </Grid>

        {/*  */}
        <Grid item xs={12}>
          <Controller
            name="retentionFinality"
            control={control}
            as={
              <TextField
                label="Justificativa de retenção"
                multiline
                rows={3}
                color="primary"
                variant="outlined"
                error={!!errors.retentionFinality}
                helperText={
                  errors.retentionFinality?.message ||
                  'Justifique porque o registro é retido no departamento pelo tempo informado.'
                }
              />
            }
            mode="onChange"
            fullWidth
          />
        </Grid>

        {watch('storageType') !== `${STORAGE_PERMANENT_TYPE}` && (
          <Grid item xs={12}>
            <Controller
              name="discardModeId"
              control={control}
              as={
                <SelectComponent
                  items={discardModes}
                  label="Como os dados são dispostos?"
                  color="primary"
                  anotherOption
                  error={!!errors.discardModeId}
                  helperText={
                    errors.discardModeId?.message ||
                    'Modo que os dados são dispostos após a utilização.'
                  }
                  fullWidth
                />
              }
              mode="onBlur"
            />
            {watch('discardModeId') === 'another' && (
              <Box mt={1}>
                <Controller
                  name="anotherDiscardMode"
                  control={control}
                  as={
                    <TextField
                      label="Outro: como os dados são dispostos?"
                      color="primary"
                      variant="outlined"
                      error={!!errors.anotherDiscardMode}
                      helperText={errors.anotherDiscardMode?.message}
                      fullWidth
                    />
                  }
                />
              </Box>
            )}
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Controller
            name="recoveryId"
            control={control}
            as={
              <SelectComponent
                items={recoveries}
                label="Forma de recuperação"
                color="primary"
                anotherOption
                error={!!errors.recoveryId}
                helperText={
                  errors.recoveryId?.message ||
                  'Qual dado é utilizado para localizar e acessar/recuperar o registro?'
                }
              />
            }
            mode="onBlur"
          />
          {watch('recoveryId') === 'another' && (
            <Box mt={1}>
              <Controller
                name="anotherRecovery"
                control={control}
                as={
                  <TextField
                    label="Outro: forma de recuperação"
                    color="primary"
                    variant="outlined"
                    error={!!errors.anotherRecovery}
                    helperText={errors.anotherRecovery?.message}
                  />
                }
              />
            </Box>
          )}
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            name="protectionId"
            control={control}
            as={
              <SelectComponent
                items={protections}
                label="Forma de proteção"
                color="primary"
                error={!!errors.protectionId}
                helperText={
                  errors.protectionId?.message ||
                  'A informação é protegida fisicamente(impressa) ou eletronicamente (sistemas, e-mail, servidor,etc)?'
                }
                name="protectionId"
              />
            }
            mode="onBlur"
          />
        </Grid>
      </Grid>
    </form>
  )
}

Form.propTypes = {
  onSubmit: PropTypes.func,
  dataLifeCycle: PropTypes.object,
}

Form.defaultProps = {
  onSubmit: () => {},
  dataLifeCycle: {},
}

export default Form
