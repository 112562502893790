const styles = (theme) => ({
  root: {
    width: 'auto',
    height: '68px',
    display: 'flex',
  },
  statusCard: {
    height: theme.spacing(5),
    width: theme.spacing(5),
    borderRadius: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
  titleBox: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  titleCard: {
    fontSize: '0.8rem',
    fontWeight: '600',
  },
  valueCard: {
    fontSize: '1rem',
    fontWeight: '600',
  },
  bgColorPrimaryMain: {
    backgroundColor: theme.palette.primary.main,
  },
  bgColorAzure: {
    backgroundColor: theme.palette.azure,
  },
  bgColorGreenPantone: {
    backgroundColor: theme.palette.greenPantone,
  },
  bgColorSpanishGray: {
    backgroundColor: theme.palette.spanishGray,
  },
})

export default styles
