import Main from './DocumentationLinksMain'
import New from './DocumentationLinksNew'
import Edit from './DocumentationLinksEdit'

const DocumentationLinks = {
  Main,
  New,
  Edit,
}

export default DocumentationLinks
