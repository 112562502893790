import { Container as ContainerMaterial } from '@material-ui/core'
import PropTypes from 'prop-types'

import styles from './styles'

const Container = ({
  children,
  spacingXl,
  spacingLg,
  spacingMd,
  spacingSm,
  spacingXs,
  ...rest
}) => {
  const useStyles = styles(
    spacingXl,
    spacingLg,
    spacingMd,
    spacingSm,
    spacingXs,
  )
  const classes = useStyles()

  return (
    <ContainerMaterial {...rest} className={classes.container} maxWidth={false}>
      {children}
    </ContainerMaterial>
  )
}

Container.propTypes = {
  children: PropTypes.node,
  spacingXl: PropTypes.number,
  spacingLg: PropTypes.number,
  spacingMd: PropTypes.number,
  spacingSm: PropTypes.number,
  spacingXs: PropTypes.number,
}

Container.defaultProps = {
  spacingXl: 8,
  spacingLg: 8,
  spacingMd: 8,
  spacingSm: 8,
  spacingXs: 2,
}

export default Container
