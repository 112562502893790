import * as yup from 'yup'
import constants from 'constants/index'
import helpers from 'helpers'

const { TYPE_BILLING_DAY_DYNAMIC, TYPE_BILLING_DAY_FIXED } =
  constants.paymentOrder

const schema = (validateCreditCard, methodPayment) =>
  yup.object().shape({
    terms: yup.string(),
    observations: yup.string(),
    expirationLastMonth: yup.string(),
    expirationAction: yup.string(),
    typeInvoice: yup.string(),
    price: yup.string().required(),
    typeBillingDay: yup.string(),
    billingDay: yup
      .number()
      .nullable()
      .when('typeBillingDay', {
        is: TYPE_BILLING_DAY_FIXED,
        then: yup
          .number()
          .required()
          .max(31)
          .min(0)
          .typeError('Confirme um dia'),
      }),
    payInBackoffice: yup.boolean(),
    billingDayDynamic: yup
      .number()
      .nullable()
      .when('typeBillingDay', {
        is: (value) =>
          value === TYPE_BILLING_DAY_DYNAMIC && methodPayment !== 'card',
        then: yup.number().required().min(0).typeError('Confirme um dia'),
      }),
    daysAdvance:
      methodPayment === 'card'
        ? yup.number().optional()
        : yup.number().required().max(31).min(1).typeError('Confirme um dia'),
    ...(validateCreditCard && {
      card: yup.object().when('payInBackoffice', {
        is: true,
        then: yup.object().shape({
          name: yup.string().required(),
          cvv: yup
            .string()
            .min(3, 'Código Inválido')
            .max(4, 'Código Inválido')
            .required(),
          expiration: yup.string().length(5, 'Data inválida').required(),
          number: yup
            .string()
            .min(16)
            .max(23)
            .required()
            .test(
              'cardNumberIsValid',
              'Cartão inválido',
              (value) => !!helpers.functions.cardFlag.id(value),
            ),
          brand: yup.string().required(),
        }),
      }),
    }),
  })

export default schema
