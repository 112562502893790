import statusLabel from './statusLabel'
import chipStatusColor from './chipStatusColor'
import defineInfoCardIcon from './defineInfoCardIcon'

const privacyPolicies = {
  statusLabel,
  chipStatusColor,
  defineInfoCardIcon,
}

export default privacyPolicies
