import {
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Divider,
  Box,
  Typography,
} from '@material-ui/core'

import useStyles from '../../styles'

import helpers from 'helpers'

import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'

const CustomListWithLabel = ({
  title,
  items,
  handleToggle,
  handleToggleAll,
  numberOfChecked,
  checked,
}) => {
  const classes = useStyles()

  const keys = Object.keys(items)

  const generalItems = () => {
    try {
      return helpers.functions.pluck(
        [...keys.map((key) => items[key])],
        'items',
      )
    } catch {
      return items
    }
  }

  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={() => handleToggleAll(generalItems())}
            checked={
              (numberOfChecked(generalItems()) === generalItems()?.length) !== 0
            }
            indeterminate={
              numberOfChecked(generalItems()) !== generalItems()?.length &&
              numberOfChecked(generalItems()) !== 0
            }
            disabled={isEmpty(generalItems())}
            inputProps={{
              'aria-label': 'Todos os itens selecionados.',
              color: 'primary',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(generalItems())}/${
          generalItems()?.length || '0'
        } selecionados`}
      />
      <Divider />
      {items && (
        <List className={classes.list} dense component="div" role="list">
          {keys.map((key, index) => (
            <Box key={index}>
              <Box ml={3}>
                <Typography>{items[key].label}</Typography>
              </Box>
              {items[key].items?.map((value) => {
                return (
                  <ListItem
                    key={value.id}
                    role="listitem"
                    button
                    onClick={() => handleToggle(value)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        checked={checked.indexOf(value) !== -1}
                        tabIndex={-1}
                        color="primary"
                        disableRipple
                        inputProps={{ 'aria-labelledby': value.id }}
                      />
                    </ListItemIcon>
                    <ListItemText id={value.id} primary={value.name} />
                  </ListItem>
                )
              })}
            </Box>
          ))}
          <ListItem />
        </List>
      )}
    </Card>
  )
}

CustomListWithLabel.propTypes = {
  handleToggleAll: PropTypes.func,
  numberOfChecked: PropTypes.func,
  handleToggle: PropTypes.func,
  checked: PropTypes.array,
}

CustomListWithLabel.defaultProps = {
  handleToggleAll: () => {},
  numberOfChecked: () => {},
  handleToggle: () => {},
}

export default CustomListWithLabel
