import { useTheme } from '@material-ui/styles'
import { useFetch, useFilter, usePagination } from 'hooks'

import { Box, Card, TablePagination, useMediaQuery } from '@material-ui/core'
import {
  Container,
  ContentHeader,
  FilterButton,
  LoadingFeedback,
  Page,
} from 'components'
import { CompanyAuditsTable, CompanyAuditFilter } from './components'

import * as service from 'service'

const CompanyAuditsMain = () => {
  const filter = useFilter()
  const theme = useTheme()

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading } = useFetch(
    service.dponet.companyAudits.get,
    {
      ...filter.filters,
      perPage,
      page,
    },
    [page, perPage, filter.filters, filter.reload],
  )

  return (
    <>
      <Page title="Auditoria de processos">
        <LoadingFeedback open={isLoading} />
        <Container
          maxWidth={false}
          spacingXl={30}
          spacingLg={25}
          spacingMd={10}
          spacingSm={10}
        >
          <ContentHeader
            title="Auditoria"
            menu="Processos"
            subtitle="Auditoria de processos"
            filter={filter}
          >
            <Box ml={1}>
              <FilterButton setDrawerOpen={filter.setDrawerOpen} />
            </Box>
          </ContentHeader>
          <Card>
            <CompanyAuditsTable
              companyAudits={response?.data?.companyAudits || []}
            />
            <Box px={2} display="flex" justifyContent="flex-end">
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            </Box>
          </Card>
        </Container>

        <CompanyAuditFilter filter={filter} />
      </Page>
    </>
  )
}

export default CompanyAuditsMain
