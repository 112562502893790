import constants from 'constants/index'

const isFinalized = (rmc) =>
  rmc?.status === constants.nonComplianceReports.FINALIZED_STATUS

const isFrozen = (rmc) =>
  rmc?.status === constants.nonComplianceReports.FROZEN_STATUS

const riskAccepted = (rmc) =>
  rmc?.status === constants.nonComplianceReports.RISK_ACCEPTED_STATUS

const isSubstituted = (rmc) =>
  rmc?.status === constants.nonComplianceReports.SUBSTITUTED_STATUS

const statusesCheck = {
  isFinalized,
  isFrozen,
  riskAccepted,
  isSubstituted,
}

export default statusesCheck
