import permissions from '../../permissions'

const COMPANY_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.COMPANIES.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.SHOW,
    tag: permissions.COMPANIES.SHOW,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.EDIT,
    tag: permissions.COMPANIES.UPDATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.CREATE,
    tag: permissions.COMPANIES.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.ACTIVATE_OR_INACTIVATE,
    tag: permissions.COMPANIES.INACTIVE_AND_ACTIVE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.DESTROY,
    tag: permissions.COMPANIES.DELETE,
  },
  {
    name: 'Exportar Listagem',
    tag: permissions.COMPANIES.EXPORT_LIST,
  },
  {
    name: 'Exportar processamentos de dados',
    tag: permissions.COMPANIES.EXPORT_DATA_PROCESSES,
  },
  {
    name: 'Alterar benefício de parceiro',
    tag: permissions.COMPANIES.UPDATE_DEMO_PARTNER,
  },
]

export default COMPANY_LIST
