import helpers from 'helpers'

import { isEmpty, isArray } from 'lodash'

const orderLegalFrameworks = (legalFrameworks) => {
  if (!isArray(legalFrameworks)) return
  let formattedLegalFrameworks = {}

  legalFrameworks.map((legalFramework) => {
    const dataTypeValue = helpers.dataProcesses.legalFrameworkValueByDataType(
      legalFramework.dataType,
    )

    if (isEmpty(formattedLegalFrameworks[dataTypeValue])) {
      formattedLegalFrameworks[dataTypeValue] = {
        label: helpers.dataProcesses.legalFrameworkLabelByDataType(
          legalFramework.dataType,
        ),
        items: [legalFramework],
      }
    } else {
      formattedLegalFrameworks[dataTypeValue].items = [
        ...formattedLegalFrameworks[dataTypeValue].items,
        legalFramework,
      ]
    }
    return legalFramework
  })

  return formattedLegalFrameworks
}

export default orderLegalFrameworks
