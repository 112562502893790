import PropTypes from 'prop-types'
import { CircularProgress, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import useStyles from '../styles'

const MultipleSelect = ({
  optionvalues,
  name,
  compareByName,
  inputProps,
  onChangeTypeSearch,
  label,
  loading,
  register,
  values,
  setValues,
  noOptionsText,
}) => {
  const classes = useStyles()

  const handleMultipleChange = (selectedValues, targetName) => {
    setValues({
      ...values,
      [targetName]: selectedValues.map((value) => {
        return value.id
      }),
    })
  }

  const getMultipleSelectedOptions = (fieldName, options, compareByName) => {
    var selectedOptions = []
    if (!values[fieldName] || !options) return selectedOptions

    if (typeof values[fieldName] === 'object') {
      values[fieldName].map((selectedValue) => {
        return options.map((option) => {
          if (compareByName) {
            if (option.name === selectedValue) {
              return selectedOptions.push(option)
            }
          } else if (parseInt(option.id) === parseInt(selectedValue)) {
            return selectedOptions.push(option)
          } else if (option.id === selectedValue) {
            return selectedOptions.push(option)
          }
          return null
        })
      })
    } else {
      options.map((option) => {
        if (compareByName) {
          if (option.name === values[fieldName]) {
            return selectedOptions.push(option)
          }
        } else if (parseInt(option.id) === parseInt(values[fieldName])) {
          return selectedOptions.push(option)
        } else if (option.id === values[fieldName]) {
          return selectedOptions.push(option)
        }

        return null
      })
    }

    return selectedOptions
  }

  return (
    <Autocomplete
      options={optionvalues}
      className={classes.filterSelectField}
      defaultValue={getMultipleSelectedOptions(
        name,
        optionvalues,
        compareByName,
      )}
      ChipProps={{
        size: 'small',
      }}
      onChange={(event, newInputValue) => {
        handleMultipleChange(newInputValue, name)
      }}
      multiple
      inputprops={{
        ref: register,
        ...inputProps,
      }}
      getOptionLabel={(option) => option.name}
      loading={loading || false}
      noOptionsText={noOptionsText}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            onChange={onChangeTypeSearch}
            label={label}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="primary" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )
      }}
    />
  )
}

MultipleSelect.propTypes = {
  noOptionsText: PropTypes.string,
}

MultipleSelect.defaultProps = {
  noOptionsText: 'Sem opções',
  optionvalues: [],
}

export default MultipleSelect
