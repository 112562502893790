import { isEmpty } from 'lodash'
import { useState } from 'react'
import { Button, Box, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'

import {
  Container,
  ConfirmationDialog,
  ContentHeader,
  Page,
  LoadingFeedback,
  MenuButton,
  Permitted,
  ButtonLogs,
} from 'components'
import { Form } from './components'

import { useSnackbar, useFetch } from 'hooks'

import constants from 'constants/index'
import helpers from 'helpers'
import * as service from 'service'
import { routes } from 'Routes'

const PreRegistrationsEdit = ({ match }) => {
  const {
    params: { preRegistrationToken },
  } = match

  const history = useHistory()

  const [openConvertDialog, setOpenConvertDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const snackbar = useSnackbar()

  const { response, isLoading, refresh } = useFetch(
    service.dponet.preRegistrations.get,
    { preRegistrationToken },
    [],
  )

  const type =
    constants.preRegistration.TYPE_BY_STATUES[
      response?.data?.preRegistration?.status
    ]

  const isCOdeOmie = !isEmpty(response?.data?.preRegistration?.codeClientOmie)
  const isRelatedCompany = response?.data?.preRegistration?.isRelatedCompany

  const isCLient =
    response?.data?.preRegistration?.typeClient !==
    constants.preRegistration.SUBLICENSE_TYPE

  const handleCreateCompany = async () => {
    setLoading(true)
    try {
      await service.dponet.preRegistrations.generateCompany(
        preRegistrationToken,
      )

      snackbar.open({
        message: 'Empresa gerada com sucesso',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Erro ao gerar empresa',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const HandleCreateUserOmie = async () => {
    setLoading(true)
    try {
      await service.dponet.preRegistrations.createUserOmie(preRegistrationToken)
      snackbar.open({
        message: 'Cliente criado no OMIE com sucesso',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Erro ao criar cliente no OMIE',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const HandleConvertTrialToReal = async () => {
    try {
      await service.dponet.preRegistrations.convertTrialToReal(
        preRegistrationToken,
      )
      snackbar.open({
        message: 'Empresa convertida com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Erro ao converter a empresa',
        variant: 'error',
      })
    }
    setOpenConvertDialog(false)
    refresh()
  }

  const handleDelete = async () => {
    try {
      await service.dponet.preRegistrations.destroy({
        preRegistrationToken,
      })

      snackbar.open({
        message: `${
          isCLient ? 'Cliente' : 'sublicenciamento'
        } excluido com sucesso!`,
        variant: 'success',
      })

      if (isCLient) return history.push(routes.preRegistrations.all)

      history.push(routes.sublicensings.all)
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
      })
    }
    setOpenDeleteDialog(false)
  }

  const handleOpenDeleteDialog = () => setOpenDeleteDialog(true)

  const title = isCLient ? 'Cliente' : 'Sublicenciamento'

  return (
    <Page title={`Editar ${title}`}>
      <LoadingFeedback open={isLoading || loading} />
      <Container maxWidth={false}>
        <ContentHeader
          menu={title}
          title={`Editar ${title}`}
          subtitle={`Editar ${title}`}
        >
          <MenuButton>
            <Button
              fullWidth
              size="small"
              onClick={() => {
                history.push(
                  reverse(routes.preRegistrations.show, {
                    preRegistrationToken,
                  }),
                )
              }}
            >
              Visualizar
            </Button>
            <ButtonLogs
              routes="pre_registrations"
              entityId={preRegistrationToken}
            />
            <Permitted tag={constants.permissions.PRE_REGISTRATIONS.DESTROY}>
              <Button fullWidth size="small" onClick={handleOpenDeleteDialog}>
                Excluir
              </Button>
            </Permitted>
            <Button
              fullWidth
              size="small"
              onClick={HandleCreateUserOmie}
              disabled={isCOdeOmie}
            >
              Criar cliente no OMIE
            </Button>
            <Button
              fullWidth
              size="small"
              onClick={setOpenConvertDialog}
              disabled={type === 'REAL'}
            >
              Converter para real
            </Button>
            <Button
              fullWidth
              size="small"
              onClick={handleCreateCompany}
              disabled={isRelatedCompany}
            >
              Gerar empresa
            </Button>
          </MenuButton>
        </ContentHeader>
        <Box mt={4} mb={4}>
          <Alert variant="filled" severity={isCOdeOmie ? 'success' : 'warning'}>
            <Typography>
              {isCOdeOmie
                ? `Esse ${
                    isCLient ? 'cliente' : 'sublicenciamento'
                  } tem vínculo com o omie`
                : `Esse ${
                    isCLient ? 'cliente' : 'sublicenciamento'
                  } não tem vínculo com o omie`}
            </Typography>
          </Alert>
        </Box>
        {!isLoading && (
          <Form
            typeDefault={type}
            isEdit
            preRegistration={response?.data?.preRegistration}
            refresh={refresh}
          />
        )}
      </Container>
      {openConvertDialog && (
        <ConfirmationDialog
          open={openConvertDialog}
          setOpen={setOpenConvertDialog}
          message="Tem certeza que deseja converter esta empresa para real?"
          cancelButtonText="Cancelar"
          buttonText="Confirmar"
          actionAcceptButton={HandleConvertTrialToReal}
        />
      )}
      {openDeleteDialog && (
        <ConfirmationDialog
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          message={constants.preRegistration.DESTROY_DIALOG}
          cancelButtonText="Cancelar"
          buttonText="Excluir"
          actionAcceptButton={handleDelete}
        />
      )}
    </Page>
  )
}

export default PreRegistrationsEdit
