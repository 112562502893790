/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import { Filters } from 'components'

import { useAuth, useRmcsList } from 'hooks'

import helpers from 'helpers'
import constants from 'constants/index'

const RmcFilter = ({ filter, search }) => {
  const { users, departments, loadDepartments, loadUsers } = useRmcsList()
  const { questionnaires } = useAuth()

  const [companies, setCompanies] = useState([])
  const [companyInputValue, setCompanyInputValue] = useState('')
  const [isLoadingCompaniesInput, setIsLoadingCompaniesInput] = useState(false)
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false)
  const [searchFinality, setSearchFinality] = useState(
    filter?.filters?.finality || '',
  )

  const { loadCompanies, mountCompanyParams, instructionsText } =
    helpers.companies.typeAndSearch
  const { nameWithDocumentByArrayObject } = helpers.companies.nameWithDocument
  const { filters, drawerOpen } = filter

  const handleTypeCompaniesInput = (e) => {
    setCompanyInputValue(e.target.value)
  }

  const handleChangeFinality = (event) => {
    setSearchFinality(event.target.value)
  }

  const loadCompanyOptions = (companyId) => {
    loadDepartments(companyId)
    loadUsers(companyId)
  }

  const companiesOptionsValues = nameWithDocumentByArrayObject(
    isLoadingCompaniesInput ? [] : companies,
  )

  useEffect(() => {
    const getCompanies = setTimeout(() => {
      const hasCompanyValueToFetch =
        companyInputValue.length < 3 && isEmpty(filters?.companyId)

      if (hasCompanyValueToFetch || !drawerOpen) return

      loadCompanies(
        mountCompanyParams(companyInputValue, filters),
        setIsLoadingCompaniesInput,
        setCompanies,
      )
    }, 1000)

    return () => clearTimeout(getCompanies)
  }, [companyInputValue])

  useEffect(() => {
    if (filters?.companyId) {
      loadCompanies(
        mountCompanyParams(companyInputValue, filters),
        setIsLoadingCompanies,
        setCompanies,
      )
    }
  }, [filters?.companyId])

  useEffect(() => {
    if (!drawerOpen && companyInputValue) setCompanyInputValue('')
  }, [drawerOpen])

  useEffect(() => {
    setSearchFinality(filter?.filters?.finality || '')
    //eslint-disable-next-line
  }, [filter?.filters?.entityType])

  return (
    <Filters filter={filter} isLoading={isLoadingCompanies}>
      <input textfieldinput="true" label="Identificador" name="id" />
      <input
        textfieldinput="true"
        label="Registro de Origem"
        name="entityName"
      />
      <select
        autocompleteselectinput="true"
        label="Empresa"
        name="companyId"
        loading={isLoadingCompaniesInput}
        onChangeTypeSearch={handleTypeCompaniesInput}
        onChange={(companyId) => loadCompanyOptions(companyId)}
        optionvalues={companiesOptionsValues}
        noOptionsText={instructionsText(companyInputValue)}
      />
      {!isEmpty(departments) && (
        <select
          textfieldinput="true"
          label="Departamento"
          name="departmentId"
          skip={
            search &&
            search.entityType === constants.nonComplianceReports.ENTITY_QUESTION
              ? 'true'
              : 'false'
          }
        >
          <option value=""></option>
          {departments.map((department) => (
            <option key={department.id} value={department.id}>
              {department.name}
            </option>
          ))}
        </select>
      )}
      <select textfieldinput="true" label="Status" name="status">
        <option value=""></option>
        <option value={constants.nonComplianceReports.PENDING_STATUS}>
          Pendente
        </option>
        <option value={constants.nonComplianceReports.ON_TIME_STATUS}>
          No prazo
        </option>
        <option value={constants.nonComplianceReports.OVERDUE_STATUS}>
          Vencido
        </option>
        <option value={constants.nonComplianceReports.SUBSTITUTED_STATUS}>
          Substituído
        </option>
        <option value={constants.nonComplianceReports.RISK_ACCEPTED_STATUS}>
          Risco assumido
        </option>
        <option value={constants.nonComplianceReports.FINALIZED_STATUS}>
          Finalizado
        </option>
        <option value={constants.nonComplianceReports.FROZEN_STATUS}>
          Congelado
        </option>
        <option value={constants.nonComplianceReports.INACTIVE_STATUS}>
          Inativo
        </option>
      </select>
      <select
        textfieldinput="true"
        label="Origem"
        name="finality"
        onChange={handleChangeFinality}
      >
        <option value=""></option>
        <option value={constants.nonComplianceReports.FRAGILITY_FINALITY}>
          Risco
        </option>
        <option value={constants.nonComplianceReports.NECESSARY_FINALITY}>
          Necessidade e proporcionalidade
        </option>
        <option
          value={
            constants.nonComplianceReports.FRAGILITY_AND_NECESSARY_FINALITY
          }
        >
          Risco e necessidade e proporcionalidade
        </option>
      </select>
      {searchFinality === constants.nonComplianceReports.QUESTION_FINALITY && (
        <select
          textfieldinput="true"
          label="Questionário"
          name="originQuestionnaireId"
        >
          <option value=""></option>
          {questionnaires?.map((questionaryItem) => (
            <option key={questionaryItem.id} value={questionaryItem.id}>
              {questionaryItem.name}
            </option>
          ))}
        </select>
      )}
      {search?.entityType ===
        constants.nonComplianceReports.ENTITY_DATA_PROCESS && (
        <select
          textfieldinput="true"
          label="Risco"
          name="dataProcessFragilityId"
          skip={
            search?.entityType !==
            constants.nonComplianceReports.ENTITY_DATA_PROCESS
          }
        >
          <option value=""></option>
          <option value={constants.nonComplianceReports.LOW_FRAGILITY_STATUS}>
            Baixo
          </option>
          <option
            value={constants.nonComplianceReports.MEDIUM_FRAGILITY_STATUS}
          >
            Médio
          </option>
          <option value={constants.nonComplianceReports.HIGH_FRAGILITY_STATUS}>
            Alto
          </option>
          <option
            value={constants.nonComplianceReports.SEVERE_FRAGILITY_STATUS}
          >
            Severo
          </option>
        </select>
      )}
      {!isEmpty(users) && (
        <select
          textfieldinput="true"
          label="Responsável"
          name="responsableUserId"
        >
          <option value=""></option>
          {users.map((user) => (
            <option key={user.id} value={user.id}>
              {user.name}
            </option>
          ))}
        </select>
      )}
      <input
        datepickerinput="true"
        label="Data de criação inicial"
        name="createdAtGt"
        data-target="createdAtLt"
        data-min
      />
      <input
        datepickerinput="true"
        label="Data de criação final"
        name="createdAtLt"
        data-target="createdAtGt"
        data-max
      />
      <input
        datepickerinput="true"
        label="Prazo inicial"
        name="deadlineDateGt"
        data-target="deadlineDateLt"
        data-min
      />
      <input
        datepickerinput="true"
        label="Prazo final"
        name="deadlineDateLt"
        data-target="deadlineDateGt"
        data-max
      />
    </Filters>
  )
}

export default RmcFilter
