import { Hidden } from '@material-ui/core'

import {
  ChevronDown as DownIcon,
  ChevronUp as UpIcon,
  ChevronLeft as LeftIcon,
  ChevronRight as RigthIcon,
} from 'react-feather'

const IconTransform = ({ left }) => {
  return (
    <>
      <Hidden xsDown>{left ? <RigthIcon /> : <LeftIcon />}</Hidden>
      <Hidden smUp>{left ? <DownIcon /> : <UpIcon />}</Hidden>
    </>
  )
}

export default IconTransform
