import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
    border: 'none',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialog: {
    border: 'none',
  },
  certificateImg: {
    overflow: 'hidden',
    maxWidth: '100vw',
    maxHeight: 'calc(100vh - 85px)',
    border: '1px solid gray',
    objectFit: 'contain',
  },
  boxCertificate: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    height: '100%',
    border: 'none',
  },
}))

export default styles
