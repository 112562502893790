import New from './FaqsNew'
import Main from './FaqsMain'
import Edit from './FaqsEdit'

const Faqs = {
  New,
  Main,
  Edit,
}

export default Faqs
