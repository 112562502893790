import { useState } from 'react'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { useCompany, useSnackbar } from 'hooks'

import { isEmpty } from 'lodash'
import { Grid, FormControlLabel, Switch, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { BaseDialog, PhoneInput, LoadingFeedback } from 'components'

import helpers from 'helpers'
import schema from './schema'
import * as service from 'service'

const ChangeResponsibleDialog = ({
  companyId,
  oldResponsibleId,
  setOldResponsibleId,
  open,
  setOpen,
  setValue,
  refresh,
}) => {
  const snackbar = useSnackbar()

  const [selectExistentResponsible, setSelectExistentResponsible] =
    useState(false)
  const { isLoading, userCompanies, loadUserCompanies } = useCompany()
  const [loading, setLoading] = useState(false)
  const [phoneSelected, setPhoneSelected] = useState('')

  const phoneIsEmpty = isEmpty(phoneSelected)

  const { handleSubmit, errors, control, register } = useForm({
    validationSchema: schema(phoneIsEmpty),
    defaultValues: {
      oldResponsibleId: oldResponsibleId,
      name: '',
      email: '',
      phone: '',
      userCompanyId: { id: 0, name: '' },
      selectExistentResponsible: false,
    },
  })

  const onSubmit = async (data) => {
    setLoading(true)

    let params = formatParams(data)

    try {
      let response = await service.dponet.companies.changeResponsable({
        companyId: companyId,
        params,
      })

      let newResponsible = await service.dponet.companies.responsableUser({
        companyId: companyId,
      })

      setValue('responsibleName', response?.data?.user?.name)
      setValue('responsibleEmail', response?.data?.user?.email)
      setValue('responsiblePhone', response?.data?.user?.phone)
      setOldResponsibleId(newResponsible?.data?.userCompany?.id)
      setValue('oldResponsibleId', newResponsible?.data?.userCompany?.id)

      setLoading(false)
      snackbar.open({
        message: 'Responsável pela empresa alterado com sucesso!',
        variant: 'success',
      })

      refresh()
      setOpen(false)
      loadUserCompanies(companyId)
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
      setOpen(false)
    }
  }

  const formatParams = (data) => {
    if (data.userCompanyId) {
      return {
        userCompanyId: data?.userCompanyId?.id,
        oldResponsibleId: data?.oldResponsibleId,
        phone: phoneIsEmpty
          ? helpers.formatters.phoneDynamicMask(data?.phone)
          : phoneSelected,
      }
    } else {
      return {
        name: data?.name,
        email: data?.email,
        phone: data?.phone,
        oldResponsibleId: data?.oldResponsibleId,
      }
    }
  }

  const handleChangeResponsibleSelection = () => {
    if (!selectExistentResponsible) {
      loadUserCompanies(companyId)
    }

    setSelectExistentResponsible(!selectExistentResponsible)
  }

  return (
    <>
      <LoadingFeedback open={loading || isLoading} />
      <BaseDialog
        open={open}
        setOpen={setOpen}
        title="Alteração de responsável da empresa"
        closeButtonText="Cancelar"
        actionButtonText="Alterar responsável"
        dialogSize="sm"
        formRef="change-responsible-form"
        noMargin
      >
        <form onSubmit={handleSubmit(onSubmit)} id="change-responsible-form">
          <Grid container spacing={1}>
            <Controller as={<></>} name="oldResponsibleId" control={control} />
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={selectExistentResponsible}
                    color="primary"
                    onChange={handleChangeResponsibleSelection}
                  />
                }
                inputRef={register}
                name="selectExistentResponsible"
                label="Selecionar um usuário já existente"
              />
            </Grid>
            {selectExistentResponsible ? (
              <>
                {!isLoading && !loading && (
                  <>
                    <Grid item xs={12}>
                      <Controller
                        control={control}
                        name="userCompanyId"
                        onChange={([, data]) => {
                          setPhoneSelected(data?.phone)
                          return data
                        }}
                        mode="onChange"
                        as={
                          <Autocomplete
                            options={userCompanies || []}
                            getOptionLabel={(option) => option.name || ''}
                            getOptionSelected={(option, value) =>
                              !value || option.id === value.id || value.id === 0
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={!!errors?.userCompanyId}
                                helperText={
                                  errors?.userCompanyId
                                    ? 'Selecione um usuário'
                                    : ''
                                }
                                label="Selecione o usuário que deseja tornar representante"
                                variant="outlined"
                              />
                            )}
                          />
                        }
                      />
                    </Grid>
                    {phoneIsEmpty && (
                      <Grid item xs={12}>
                        <Controller
                          as={
                            <TextField
                              label="Telefone do usuário"
                              type="text"
                              color="primary"
                              variant="outlined"
                              placeholder="(99) 9999-9999"
                              error={!!errors.phone}
                              helperText={errors?.phone?.message}
                              fullWidth
                            />
                          }
                          onChange={([event]) => {
                            return helpers.formatters.phoneDynamicMask(
                              event.target.value,
                            )
                          }}
                          control={control}
                          name="phone"
                          mode="onBlur"
                        />
                      </Grid>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <Grid item xs={12}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        error={!!errors.name}
                        helperText={errors?.name?.message}
                        label="Nome do novo responsável pela empresa"
                      />
                    }
                    control={control}
                    name="name"
                    mode="onBlur"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    as={
                      <TextField
                        fullWidth
                        error={!!errors.email}
                        helperText={errors?.email?.message}
                        label="E-mail do novo responsável pela empresa"
                      />
                    }
                    control={control}
                    name="email"
                    mode="onBlur"
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneInput
                    control={control}
                    errors={errors}
                    fieldName="phone"
                    label="Telefone do responsável pela empresa"
                    phoneNumber={''}
                    editionPermitted
                  />
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </BaseDialog>
    </>
  )
}

ChangeResponsibleDialog.propTypes = {
  companyId: PropTypes.number.isRequired,
  oldResponsibleId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  setOldResponsibleId: PropTypes.func,
  setOpen: PropTypes.func,
  setLoading: PropTypes.func,
  setValue: PropTypes.func,
}

ChangeResponsibleDialog.defaultProps = {
  setOldResponsibleId: () => {},
  setOpen: () => {},
  setLoading: () => {},
  setValue: () => {},
}

export default ChangeResponsibleDialog
