import { useState } from 'react'
import { Card, Typography, Box, IconButton } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import clsx from 'clsx'
import { HelpOutline as HelpIcon } from '@material-ui/icons'

import { InfoDialog } from 'components'

import useStyles from './styles'

const DisplayCard = ({
  title,
  amount,
  quantity,
  position,
  isLoading,
  description,
}) => {
  const [openHelp, setOpenHelp] = useState(false)
  const classes = useStyles()

  const handleOpenHelp = () => setOpenHelp(true)

  return (
    <>
      {isLoading ? (
        <Skeleton variant="rect" height="70px" />
      ) : (
        <Card
          textAlign="center"
          className={clsx(classes.card, {
            [classes.cardColorBlue]: position === 0,
            [classes.cardColorRed]: position === 1,
            [classes.cardColorGreen]: position === 2,
            [classes.cardColorViolet]: position === 3,
          })}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body1">{title}</Typography>
            <IconButton className={classes.icon} onClick={handleOpenHelp}>
              <HelpIcon />
            </IconButton>
          </Box>
          <Typography variant="body1" className={classes.descripton}>
            R$ {amount}
          </Typography>
          <Typography variant="caption">Quantidade: {quantity}</Typography>
        </Card>
      )}
      {openHelp && (
        <InfoDialog
          open={openHelp}
          setOpen={setOpenHelp}
          text={description}
          title={title}
        />
      )}
    </>
  )
}

export default DisplayCard
