import PropTypes from 'prop-types'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'

import helpers from 'helpers'
import { Box, Typography } from '@material-ui/core'

import PerfectScrollbar from 'react-perfect-scrollbar'

const TableNecPropDataCollectedShow = ({ collectedFields }) => {
  if (collectedFields.length === 0) {
    return null
  }

  return (
    <Box my={2}>
      <Box my={2}>
        <Typography variant="h6">Dados tratados não necessários</Typography>
      </Box>
      <PerfectScrollbar>
        <Box width="100%">
          <Table
            size="small"
            emptyMessage="Nenhum dado tratado encontrado"
            noWrap
          >
            <TableHead>
              <TableRow>
                <TableCell width="25%">Dado</TableCell>
                <TableCell width="25%">Tipo</TableCell>
                <TableCell width="25%">Categoria do Titular</TableCell>
                <TableCell width="15%">Faixa Etária</TableCell>
                <TableCell width="10%">
                  Quantidade aproximada de titulares
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {collectedFields.map((collectedField) => {
                return (
                  <TableRow key={collectedField.dataCollectedOption.id}>
                    <TableCell>
                      {collectedField?.dataCollectedOption?.name}
                    </TableCell>
                    <TableCell>
                      {helpers.dataCollected.dataTypeLabel(
                        collectedField?.dataCollectedOption?.dataType,
                      )}
                    </TableCell>
                    <TableCell>
                      {collectedField.titularCategory?.name}
                    </TableCell>
                    <TableCell>
                      {helpers.dataCollected.rangeAgeContent(
                        collectedField?.titularAgeRange,
                      )}
                    </TableCell>
                    <TableCell>
                      {collectedField?.holderNumber || 'Não definido'}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Box>
  )
}

TableNecPropDataCollectedShow.propTypes = {
  collectedFields: PropTypes.arrayOf(Object).isRequired,
}

TableNecPropDataCollectedShow.defaultProps = {
  collectedFields: [],
}

export default TableNecPropDataCollectedShow
