import { useState, useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'
import useDataProcess from 'hooks/useDataProcess'

import {
  Grid,
  Button,
  makeStyles,
  Box,
  Tooltip,
  Typography,
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'
import { LoadingBox, MenuButton } from 'components'

import DialogRemoveDataLifeCycle from '../../DialogRemoveDataLifeCycle'
import DialogDataLifeCycle from '../../DialogDataLifeCycle'
import { DescriptionPaper } from '../..'

import { PlusCircle } from 'react-feather'

import * as service from 'service'
import styles from '../styles'
import helpers from 'helpers'

const useStyles = makeStyles(styles)

const DataLifeCycle = ({ description }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const {
    data: dataProcess,
    nextStep,
    toNextStep,
    setToNextStep,
    reloadOptions,
    reloadDataProcess,
  } = useDataProcess()

  const [dialogRemoveDataLifeCycle, setDialogRemoveDataLifeCycle] =
    useState(false)
  const [removableDataLifeCycleId, setRemovableDataLifeCycleId] = useState()
  const [dialogDataLifeCycle, setDialogDataLifeCycle] = useState(false)
  const [editableDataLifeCycle, setEditableDataLifeCycle] = useState({})

  const [loading, setLoading] = useState(false)
  const [mode, setMode] = useState('create')

  const { response, isLoading, refresh } = useFetch(
    service.dponet.dataLifeCycles.get,
    {
      dataProcessId: dataProcess.id,
    },
  )

  useEffect(() => {
    if (toNextStep) {
      if (!isLoading) {
        if (verifyRuleToNextStep()) {
          nextStep()
          setToNextStep(false)
        } else {
          snackbar.open({
            message:
              'É necessário ter pelo menos um dado salvaguarda e ciclo de vida.',
            variant: 'error',
          })
          setToNextStep(false)
        }
      }
    }
    // eslint-disable-next-line
  }, [nextStep])

  const verifyRuleToNextStep = () => {
    return response.data.lifeCycles.length > 0
  }

  const handleDelete = async (dataProcessId, dataLifeCycleId) => {
    setLoading(true)
    try {
      await service.dponet.dataLifeCycles.destroy({
        dataProcessId,
        dataLifeCycleId,
      })
      setDialogRemoveDataLifeCycle(false)

      snackbar.open({
        message: 'Salvaguarda e ciclo de vida removido com sucesso!',
        variant: 'success',
      })

      refresh()
      reloadOptions()
      reloadDataProcess()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
    setLoading(false)
  }

  const handleOpenRemoveDialog = (dataLifeCycle) => {
    setRemovableDataLifeCycleId(dataLifeCycle.id)
    setDialogRemoveDataLifeCycle(true)
  }

  const handleOpenDialogDataLifeCycle = (
    dataLifeCycle = {},
    mode = 'create',
  ) => {
    setEditableDataLifeCycle(dataLifeCycle)
    setMode(mode)
    setDialogDataLifeCycle(true)
  }

  const valueVolumetry = true

  return (
    <>
      {isLoading || loading ? (
        <LoadingBox />
      ) : (
        <>
          <DescriptionPaper description={description}>
            <Button
              variant="outlined"
              startIcon={<PlusCircle size={16} />}
              onClick={() => handleOpenDialogDataLifeCycle()}
            >
              Adicionar
            </Button>
          </DescriptionPaper>
          <Grid container className={classes.root}>
            <Grid xs={12} item>
              <Box maxHeight="82%">
                <PerfectScrollbar>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width="20%">Ambiente de alocação</TableCell>
                        <TableCell width="20%">
                          Onde os dados são armazenados
                        </TableCell>
                        <TableCell width="20%">
                          Tempo de Armazenamento
                        </TableCell>
                        <TableCell width="15%">
                          Justificativa de retenção
                        </TableCell>
                        <TableCell width="15%">
                          Como os dados são dispostos
                        </TableCell>
                        <TableCell width="10%" align="right">
                          Ações
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {response?.data?.lifeCycles?.map((dataLifeCycle) => (
                        <TableRow key={dataLifeCycle.id}>
                          <TableCell>
                            <Tooltip
                              title={
                                dataLifeCycle?.internalAllocationMode?.name ||
                                ''
                              }
                            >
                              <Typography className={classes.ellipsisTable}>
                                {dataLifeCycle?.internalAllocationMode?.name ||
                                  ''}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              title={dataLifeCycle?.storageLocation?.name || ''}
                            >
                              <Typography className={classes.ellipsisTable}>
                                {dataLifeCycle?.storageLocation?.name || ''}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              title={helpers.dataProcesses.storageModeType(
                                dataLifeCycle?.storageType,
                                dataLifeCycle?.value,
                                dataLifeCycle?.volumetry,
                                !!dataLifeCycle?.value &&
                                  !!dataLifeCycle?.volumetry,
                              )}
                            >
                              <Typography className={classes.ellipsisTable}>
                                {helpers.dataProcesses.storageModeType(
                                  dataLifeCycle?.storageType,
                                  dataLifeCycle?.value,
                                  dataLifeCycle?.volumetry,
                                  !!dataLifeCycle?.value &&
                                    !!dataLifeCycle?.volumetry,
                                )}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              title={dataLifeCycle?.retentionFinality || ''}
                            >
                              <Typography className={classes.ellipsisTable}>
                                {dataLifeCycle?.retentionFinality || ''}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              title={
                                helpers.dataProcesses.discardMode(
                                  dataLifeCycle,
                                ) || ''
                              }
                            >
                              <Typography className={classes.ellipsisTable}>
                                {helpers.dataProcesses.discardMode(
                                  dataLifeCycle,
                                ) || ''}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell disableTooltip>
                            <Box display="flex" justifyContent="flex-end">
                              <MenuButton>
                                <Button
                                  color="secondary"
                                  fullWidth
                                  onClick={() =>
                                    handleOpenDialogDataLifeCycle(
                                      dataLifeCycle,
                                      'edit',
                                    )
                                  }
                                >
                                  Editar
                                </Button>
                                <Button
                                  color="secondary"
                                  fullWidth
                                  onClick={() =>
                                    handleOpenRemoveDialog(dataLifeCycle)
                                  }
                                >
                                  Excluir
                                </Button>
                              </MenuButton>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </PerfectScrollbar>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
      <DialogRemoveDataLifeCycle
        open={dialogRemoveDataLifeCycle}
        setOpen={setDialogRemoveDataLifeCycle}
        dataProcessId={dataProcess?.id}
        dataLifeCycleId={removableDataLifeCycleId}
        handleRemove={handleDelete}
      />
      <DialogDataLifeCycle
        open={dialogDataLifeCycle}
        setOpen={setDialogDataLifeCycle}
        dataProcessId={dataProcess?.id}
        setLoading={setLoading}
        dataLifeCycle={editableDataLifeCycle}
        dataProcess={dataProcess}
        refresh={refresh}
        mode={mode}
      />
    </>
  )
}

DataLifeCycle.propTypes = {
  description: PropTypes.string.isRequired,
}

export default DataLifeCycle
