import { useState } from 'react'

import { Button } from '@material-ui/core'
import { ConfirmationDialog, Permitted } from 'components'

import constants from 'constants/index'
import helpers from 'helpers'

import PropTypes from 'prop-types'
import * as service from 'service'

import useSnackbar from 'hooks/useSnackbar'

const ChangeStatus = ({ department, refresh }) => {
  const [openStatusDialog, setOpenStatusDialog] = useState(false)

  const snackbar = useSnackbar()

  const Message = `Deseja realmente ${
    department.active ? 'Inativar' : 'Ativar'
  } esse departamento`

  const handleChage = async () => {
    try {
      await service.dponet.departments.changeStatus({
        departmentId: department?.id,
      })

      refresh()

      snackbar.open({
        message: `Departamento ${
          department.active ? 'Inativado' : 'Ativado'
        } com sucesso.`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          `erro ao ${
            department.active ? 'Inativar' : 'desativar'
          } o departamento.`,
      })
    }
    setOpenStatusDialog(false)
  }

  return (
    <>
      <Permitted tag={constants.permissions.DEPARTMENT.ACTIVATE_OR_INACTIVATE}>
        <Button
          fullWidth
          size="small"
          onClick={() => setOpenStatusDialog(true)}
        >
          {department.active ? 'Inativar' : 'Ativar'}
        </Button>
      </Permitted>

      <ConfirmationDialog
        open={openStatusDialog}
        setOpen={setOpenStatusDialog}
        message={Message}
        cancelButtonText="Cancelar"
        buttonText="Confirmar"
        actionAcceptButton={handleChage}
      />
    </>
  )
}

ChangeStatus.propTypes = {
  department: PropTypes.object.isRequired,
  refresh: PropTypes.func,
}

ChangeStatus.propTypes = {
  refresh: () => {},
}

export default ChangeStatus
