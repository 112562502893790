import { Chip } from '@material-ui/core'

import PropTypes from 'prop-types'

import { MenuButton } from 'components'
import { TableRow, TableCell } from 'components/Table'

import helpers from 'helpers'
import constants from 'constants/index'

import useStyles from './styles'
import ActionButtons from '../ActionButtons'

const PreRegistrationsTableRow = ({ preRegistration, refresh }) => {
  const classes = useStyles()

  return (
    <>
      <TableRow key={preRegistration?.id}>
        <TableCell>{preRegistration?.id}</TableCell>
        <TableCell>{preRegistration?.name}</TableCell>
        <TableCell>
          {preRegistration?.kind === constants.companies.KINDS.PERSONAL
            ? helpers.formatters.cpf(preRegistration?.document)
            : helpers.formatters.cnpj(preRegistration?.document)}
        </TableCell>
        <TableCell>
          {constants.companies.ORIGINS_LABELS[preRegistration?.origin]}
        </TableCell>
        <TableCell disableTooltip>
          <Chip
            size="small"
            className={helpers.preRegistrations.chipStatusColor(
              preRegistration?.status,
              classes,
            )}
            label={helpers.preRegistrations.chipStatusLabel(
              preRegistration?.status,
            )}
          />
        </TableCell>
        <TableCell>
          {helpers.formatters.date(preRegistration?.createdAt)}
        </TableCell>
        <TableCell disableTooltip align="right">
          <MenuButton>
            <ActionButtons
              preRegistration={preRegistration}
              refresh={refresh}
              isList
              typeClient={constants.preRegistration.CLIENT_TYPE}
            />
          </MenuButton>
        </TableCell>
      </TableRow>
    </>
  )
}

PreRegistrationsTableRow.propTypes = {
  preRegistration: PropTypes.object,
}

export default PreRegistrationsTableRow
