import { BasePermissionsForm } from '../index'

import { Box, Typography } from '@material-ui/core'

import constants from 'constants/index'
import PropTypes from 'prop-types'

const SettingsForm = ({
  register,
  permissions,
  handleChange,
  activeProfile,
  isEdit,
}) => {
  const collaboratorProfileLists =
    constants.permissionsLists.collaboratorProfileLists

  return (
    <>
      <Box mt={2} mb={3}>
        <Typography variant="h5">Configurações</Typography>
      </Box>
      <BasePermissionsForm
        title="Opções de Formulários"
        permissionsList={collaboratorProfileLists.formOptionList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Enquadramento Legal"
        permissionsList={collaboratorProfileLists.legalFrameworkList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Contatos ANPD"
        permissionsList={collaboratorProfileLists.anpdContactList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Links de Ajuda"
        permissionsList={collaboratorProfileLists.documentationLinkList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
    </>
  )
}

SettingsForm.propTypes = {
  register: PropTypes.func,
  permissions: PropTypes.object,
  handleChange: PropTypes.func,
  activeProfile: PropTypes.bool,
  isEdit: PropTypes.bool,
}

export default SettingsForm
