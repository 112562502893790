import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  icons: {
    width: '18px',
    height: '18px',
  },
  chip: {
    backgroundColor: colors.lightGreen[500],
    borderRadius: '44px',
    color: theme.palette.white,
    textTransform: 'uppercase',
    fontWeight: 600,
    height: '26px',
    fontSize: '12px',
  },
  descriptionDialog: {
    fontFamily: 'Roboto',
    fontSize: theme.spacing(2.5),
    fontWeight: theme.spacing(50),
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
    '& img': {
      maxWidth: '100%',
    },
  },
}))
export default styles
