const { makeStyles } = require('@material-ui/styles')

const styles = makeStyles((theme) => ({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  expandedText: {
    wordWrap: 'break-word',
    whiteSpace: 'break-spaces',
    display: 'block',
    wordBreak: 'break-word',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  tableHeader: {
    borderBottom: '12px !important',
    borderTop: '12px !important',
  },
}))

export default styles
