import { BasePermissionsForm } from '../index'

import { Box, Typography } from '@material-ui/core'

import constants from 'constants/index'
import PropTypes from 'prop-types'

const RmcsForm = ({
  register,
  permissions,
  handleChange,
  activeProfile,
  isEdit,
}) => {
  const collaboratorProfileLists =
    constants.permissionsLists.collaboratorProfileLists

  return (
    <>
      <Box mt={2} mb={3}>
        <Typography variant="h5">Registros de Melhorias</Typography>
      </Box>
      <BasePermissionsForm
        title="Resumo"
        permissionsList={collaboratorProfileLists.rmcResumeList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Listagem"
        permissionsList={collaboratorProfileLists.rmcListingList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
    </>
  )
}

RmcsForm.propTypes = {
  register: PropTypes.func,
  permissions: PropTypes.object,
  handleChange: PropTypes.func,
  activeProfile: PropTypes.bool,
  isEdit: PropTypes.bool,
}

export default RmcsForm
