import PropTypes from 'prop-types'
import { Typography, Box, TextField } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'

import { BaseDialog } from 'components'
import { useSnackbar } from 'hooks'
import helpers from 'helpers'
import * as service from 'service'

import schema from './schema'

const QuestionInativationDialog = ({
  open,
  setOpen,
  handleCloseQuestionDialog,
  questionnaire,
  selectedQuestion,
  refresh,
}) => {
  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: {
      reason: '',
    },
  })

  const snackbar = useSnackbar()

  const handleInactivateQuestion = async (data) => {
    try {
      await service.dponet.questions.inactivate({
        questionnaireId: questionnaire.id,
        questionId: selectedQuestion.id,
        reason: data.reason,
      })

      handleCloseQuestionDialog()
      refresh()
      snackbar.open({
        message: 'Questão inativada com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      const errorMessage =
        helpers.formatters.errorMessage(error?.response?.data?.error, false) ||
        'Erro ao inativar a questão!'

      snackbar.open({ message: errorMessage, variant: 'error' })
    }
  }

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title="Inativar questão"
      closeButtonText="Cancelar"
      actionButtonText="Reprovar"
      formRef="id-form-reason"
      noMargin
    >
      <Typography>Deseja realmente inativar esta questão?</Typography>
      <Box mt={2}>
        <form
          onSubmit={handleSubmit(handleInactivateQuestion)}
          id="id-form-reason"
        >
          <Controller
            as={
              <TextField
                multiline
                label="Motivo da inativação"
                color="primary"
                variant="outlined"
                error={!!errors.reason}
                helperText={errors.reason?.message}
                fullWidth
                minRows={4}
                maxRows={4}
              />
            }
            fullWidth
            name="reason"
            control={control}
          />
        </form>
      </Box>
    </BaseDialog>
  )
}

QuestionInativationDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  handleCloseQuestionDialog: PropTypes.func,
  questionnaire: PropTypes.object,
  selectedQuestion: PropTypes.object,
  refresh: PropTypes.func,
}

QuestionInativationDialog.defaultProps = {
  setOpen: () => {},
  handleCloseQuestionDialog: () => {},
  refresh: () => {},
}

export default QuestionInativationDialog
