import React from 'react'
import {
  Grid,
  Paper,
  Box,
  Button,
  Typography,
  Chip,
  Tooltip,
} from '@material-ui/core'
import {
  File as FileIcon,
  Eye as EyeIcon,
  Edit as EditIcon,
  Trash as TrashIcon,
} from 'react-feather'
import PropTypes from 'prop-types'

import { MenuButton } from 'components'

import constants from 'constants/index'
import helpers from 'helpers'
import useStyles from './styles'

const DocumentCard = ({ document, handleOpenDialog }) => {
  const { id, title, link, documentCategory } = document

  const classes = useStyles()

  const menuOptions = [
    {
      icon: EyeIcon,
      title: 'Visualizar',
      permission: constants.permissions.DOCUMENTS_TEMPLATE.LIST,
      handleClick: () => handleOpenDialog('show', document),
    },
    {
      icon: EditIcon,
      title: 'Editar',
      permission: constants.permissions.DOCUMENTS_TEMPLATE.CREATE,
      handleClick: () => handleOpenDialog('edit', document),
    },
    {
      icon: TrashIcon,
      title: 'Excluir',
      permission: constants.permissions.DOCUMENTS_TEMPLATE.DESTROY,
      handleClick: () => handleOpenDialog('destroy', document),
    },
  ]

  const redirectToDocument = () => {
    window.open(helpers.formatters.website(link), '_blank')
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <Paper component={Box} className={classes.cards}>
        <Box p={2}>
          <Box className={classes.header}>
            <Box display="flex" alignItems="center">
              <FileIcon />
              <Chip
                label={documentCategory.name.toUpperCase()}
                size="small"
                className={classes.chipRoot}
              />
            </Box>
            <Box>
              <MenuButton iconButtonFontSize={16}>
                {menuOptions.map((menuOption) => (
                  <Button
                    color="secondary"
                    fullWidth
                    onClick={menuOption.handleClick}
                  >
                    {menuOption.title}
                  </Button>
                ))}
              </MenuButton>
            </Box>
          </Box>
          <Box>
            <Tooltip title={title}>
              <Typography variant="h4" gutterBottom className={classes.title}>
                #{id} {title}
              </Typography>
            </Tooltip>
          </Box>
        </Box>

        <Box className={classes.boxAction}>
          <Button color="primary" onClick={redirectToDocument}>
            ABRIR DOCUMENTO
          </Button>
        </Box>
      </Paper>
    </Grid>
  )
}

DocumentCard.propTypes = {
  document: PropTypes.object.isRequired,
  handleOpenDialog: PropTypes.func.isRequired,
}

export default DocumentCard
