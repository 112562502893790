import palette from 'theme/palette'
import { colors } from '@material-ui/core'

const styles = (theme) => ({
  detachedBox: {
    backgroundColor: palette.primary.light,
    padding: '9px',
    borderRadius: '4px',
  },
  inactiveBox: {
    backgroundColor: palette.secondary.superLight,
    padding: '9px',
    borderRadius: '4px',
  },
  answered: {
    backgroundColor: colors.green[500],
    borderRadius: '4px',
    color: palette.white,
    fontWeight: 700,
    height: '21px',
  },
  pending: {
    backgroundColor: colors.blue[500],
    borderRadius: '4px',
    color: palette.white,
    fontWeight: 700,
    height: '21px',
  },
  inactive: {
    backgroundColor: colors.grey[500],
    borderRadius: '4px',
    color: palette.white,
    fontWeight: 700,
    height: '21px',
  },
  linkButton: {
    color: colors.lightBlue[500],
    '&:hover': {
      textDecoration: 'underline',
      backgroundColor: 'transparent',
    },
  },
  linkText: {
    color: colors.lightBlue[500],
    fontWeight: 700,
  },
  inactivateReasonBox: {
    backgroundColor: palette.primary.light,
  },
})

export default styles
