import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'
import { Trash2 } from 'react-feather'

import { TableRow, TableCell } from 'components/Table'
import { Permitted } from 'components'

import permission from 'constants/permissions'

const DepartmenUserRow = ({ user, open, setOpen, setUserData, ...rest }) => {
  const handleRemove = (user) => {
    setUserData(user)
    setOpen(!open)
  }

  return (
    <TableRow {...rest}>
      <TableCell>{user.name}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell align="right" disableTooltip>
        <Permitted tag={permission.DEPARTMENT.CREATE}>
          <IconButton
            onClick={() => handleRemove(user)}
            aria-label="Remover"
            variant="outlined"
            color="secondary"
          >
            <Trash2 />
          </IconButton>
        </Permitted>
      </TableCell>
    </TableRow>
  )
}

DepartmenUserRow.propTypes = {
  user: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setUserData: PropTypes.func,
}

DepartmenUserRow.defaultProps = {
  open: false,
  setOpen: () => {},
  setUserData: () => {},
}

export default DepartmenUserRow
