import PropTypes from 'prop-types'

import helpers from 'helpers'

import { Box, Tooltip, Typography, Card } from '@material-ui/core'

import useStyles from './styles'

const CardInfo = ({ icon, title, subtitle }) => {
  const classes = useStyles()

  return (
    <Card>
      <Box width="100%" display="flex" alignItems="center" p={1}>
        <Box px={1} py={2} className={classes.iconBox}>
          {icon}
        </Box>
        <Box width="100%">
          <Tooltip title={title || ''} placement="bottom-start">
            <Typography
              variant="subtitle2"
              align="left"
              className={classes.overFlow}
            >
              {title.toUpperCase()}
            </Typography>
          </Tooltip>

          <Box>
            <Tooltip title={subtitle || ''} placement="bottom-start">
              <Typography
                variant="h5"
                align="left"
                className={classes.overFlow}
              >
                {helpers.functions.capitalizeText(subtitle)}
              </Typography>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Card>
  )
}

CardInfo.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default CardInfo
