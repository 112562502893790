import { Page, Container, ContentHeader } from 'components'
import { EconomicActivityForm } from './components'

import { routes } from 'Routes'

const EconomicActivitiesNew = () => {
  const navigationsRoutes = [
    {
      text: 'Listagem',
      route: routes.economicActivities.all,
    },
  ]
  return (
    <Page title="Nova atividade econômica">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Nova atividade econômica"
          menu="Atividade econômica"
          subtitle="Nova atividade econômica"
          menuNavigations={navigationsRoutes}
        />
        <EconomicActivityForm />
      </Container>
    </Page>
  )
}
export default EconomicActivitiesNew
