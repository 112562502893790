import theme from 'theme'

import {
  Check as ActiveIcon,
  Moon as InactiveIcon,
  Loader as DraftIcon,
} from 'react-feather'
import PrivacyPoliciesStatusCard from 'views/PrivacyPolicies/components/PrivacyPoliciesStatusCard'
import constants from 'constants/index'

const defineInfoCardIcon = (statusId, classes) => {
  switch (statusId) {
    case constants.privacyPolicy.DRAFT_STATUS_VALUE:
      return (
        <PrivacyPoliciesStatusCard
          bgColorClass={classes.bgColorAzure}
          icon={<DraftIcon color={theme.palette.white} size="18px" />}
          classes={classes}
        />
      )
    case constants.privacyPolicy.ACTIVE_STATUS_VALUE:
      return (
        <PrivacyPoliciesStatusCard
          bgColorClass={classes.bgColorGreenPantone}
          icon={<ActiveIcon color={theme.palette.white} size="18px" />}
          classes={classes}
        />
      )
    case constants.privacyPolicy.INACTIVE_STATUS_VALUE:
      return (
        <PrivacyPoliciesStatusCard
          bgColorClass={classes.bgColorSpanishGray}
          icon={<InactiveIcon color={theme.palette.white} size="18px" />}
          classes={classes}
        />
      )
    default:
      return <></>
  }
}

export default defineInfoCardIcon
