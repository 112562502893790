import { Box } from '@material-ui/core'
import constants from 'constants/index'
import { useDataProcess } from 'hooks'
import { Description, Infos, LegalFramework } from './components'

import {
  Infos as InfosShow,
  LegalFramework as LegalFrameworkShow,
} from '../../View/GeneralInformation/components'

const GeneralInformation = () => {
  const { data } = useDataProcess()

  const isTemplate = data?.company?.kind === constants.companies.KINDS.TEMPLATE

  const editLegalFramework = [
    constants.dataProcess.REVIEWING_STATUS,
    constants.dataProcess.WAITING_REVIEW_STATUS,
  ].includes(data?.statusId)

  return (
    <>
      {isTemplate ? (
        <>
          <Box mb={3}>
            <Infos />
          </Box>
          <Box mb={3}>
            <Description />
          </Box>
        </>
      ) : (
        <Box mb={3}>
          <InfosShow />
        </Box>
      )}
      <Box>
        {editLegalFramework ? <LegalFramework /> : <LegalFrameworkShow />}
      </Box>
    </>
  )
}

export default GeneralInformation
