import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ dataProcessId = '', ...params }) => {
  return await dponetAPI.get(`/data_processes/${dataProcessId}/life_cycles`, {
    params,
  })
}

const destroy = async ({ dataProcessId, dataLifeCycleId }) => {
  return await dponetAPI.delete(
    `/data_processes/${dataProcessId}/life_cycles/${dataLifeCycleId}`,
  )
}

const put = async ({ dataProcessId, dataLifeCycleId, ...params }) => {
  return await dponetAPI.put(
    `/data_processes/${dataProcessId}/life_cycles/${dataLifeCycleId}`,
    params,
  )
}

const create = async ({ dataProcessId, ...params }) => {
  return await dponetAPI.post(
    `/data_processes/${dataProcessId}/life_cycles`,
    params,
  )
}

const dataLifeCycles = { get, destroy, put, create }

export default dataLifeCycles
