import constants from 'constants/index'

const checkDefault = (profile) => {
  if (profile?.profileType === constants.profile.PROFILE_TYPE_CLIENT) {
    if (
      profile?.default === constants.profile.DEFAULT_TRUE &&
      profile?.company === null
    ) {
      return 'Perfil sugerido'
    }
    return 'Perfil de empresa'
  }
}

export default checkDefault
