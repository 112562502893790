import { useState } from 'react'
import { Button } from '@material-ui/core'

import PropTypes from 'prop-types'

import { TableRow, TableCell } from 'components/Table'

import { MenuButton } from 'components'

import { DialogCollectedFields } from '../'
import { DialogRemoveDataCollected } from '../../../../DialogRemoveDataCollected'
import { DialogDataCollected } from '../../../../DialogDataCollected'

import useSnackbar from 'hooks/useSnackbar'
import useDataProcess from 'hooks/useDataProcess'

import * as service from 'service'

import helpers from 'helpers'

const DataCollectedRow = ({ dataCollected, setLoading, index, refresh }) => {
  const {
    data,
    dataCollecteds,
    setDataCollecteds,
    reloadOptions,
    reloadDataProcess,
  } = useDataProcess()
  const [open, setOpen] = useState(false)
  const [dialogRemoveDataCollected, setDialogRemoveDataCollected] =
    useState(false)
  const [dialogDataCollected, setDialogDataCollected] = useState(false)
  const [dataCollectedId, setDataCollectedId] = useState(dataCollected.id)
  const [editionDataCollected, setEditionDataCollected] = useState()

  const snackbar = useSnackbar()

  const handleDelete = async () => {
    try {
      setLoading(true)
      await service.dponet.dataCollecteds.destroy({
        dataProcessId: data.id,
        dataCollectedId: dataCollectedId,
      })
      delete dataCollecteds[index]
      setDataCollecteds(dataCollecteds)
      reloadDataProcess()
      snackbar.open({
        message: 'Dado tratado removido com sucesso!',
        variant: 'success',
      })
      reloadOptions()
    } catch (err) {
      snackbar.open({
        message: 'Não foi possível deletar dado tratado. Tente novamente!',
        variant: 'error',
      })
    }
    setLoading(false)
  }

  const handleEdit = (dataCollected) => {
    setEditionDataCollected(dataCollected)
    setDialogDataCollected(true)
  }

  const removeDataCollected = (dataCollectedId) => {
    setDataCollectedId(dataCollectedId)
    setDialogRemoveDataCollected(true)
  }

  return (
    <TableRow>
      <TableCell>{dataCollected?.titularCategory?.name}</TableCell>
      <TableCell>
        {helpers.dataCollected.rangeAgeContent(dataCollected?.titularAgeRange)}
      </TableCell>
      <TableCell>{dataCollected?.holderNumber || 'Não definido'}</TableCell>
      <TableCell align="right" disableTooltip>
        <MenuButton>
          <Button
            color="secondary"
            fullWidth
            onClick={() => handleEdit(dataCollected)}
          >
            Editar
          </Button>
          <Button
            color="secondary"
            fullWidth
            onClick={() => removeDataCollected(dataCollected.id)}
          >
            Excluir
          </Button>
          <Button
            color="secondary"
            fullWidth
            onClick={() => {
              setOpen(true)
            }}
          >
            Visualizar
          </Button>
        </MenuButton>
      </TableCell>
      <DialogCollectedFields
        open={open}
        setOpen={setOpen}
        collectedFields={dataCollected.collectedFields}
      />
      <DialogRemoveDataCollected
        handleRemove={handleDelete}
        open={dialogRemoveDataCollected}
        setOpen={setDialogRemoveDataCollected}
        dataProcessId={data.id}
        dataCollectedId={dataCollectedId}
      />
      <DialogDataCollected
        dataProcessId={data.id}
        open={dialogDataCollected}
        setOpen={setDialogDataCollected}
        setLoading={setLoading}
        dataCollected={editionDataCollected || {}}
      />
    </TableRow>
  )
}

DataCollectedRow.propTypes = {
  dataCollected: PropTypes.object.isRequired,
  setLoading: PropTypes.func,
  index: PropTypes.number.isRequired,
}

export default DataCollectedRow
