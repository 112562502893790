const NonNumeric = /\D/g
const mapToNumeric = (value) => value.replace(NonNumeric, '')

const format = (value) =>
  mapToNumeric(value)
    .slice(0, 14)
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})$/, '$1-$2')

export default format
