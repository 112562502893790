import constants from 'constants/index'
import PropTypes from 'prop-types'

import {
  FinalizedAlert,
  ContinuedAlert,
} from 'components/RmcActionContent/components'

const FinalizedOrContinuedAlert = ({ nonComplianceReport, isRisk }) => {
  const rmcFinalized =
    nonComplianceReport.status ===
    constants.nonComplianceReports.FINALIZED_STATUS

  const finalized = () => {
    if (isRisk) {
      return rmcFinalized
    }

    return (
      nonComplianceReport?.unnecessaryDatas?.filter(
        (unnecessaryData) =>
          unnecessaryData.status ===
          constants.unnecessaryData.STATUS.UNNECESSARY,
      )?.length === 0
    )
  }

  if (finalized()) {
    return (
      <FinalizedAlert
        nonComplianceReportId={nonComplianceReport.id}
        nonComplianceReportFinality={nonComplianceReport.finality}
        finalized
      />
    )
  }
  return (
    <ContinuedAlert
      nonComplianceReport={nonComplianceReport}
      continued={
        nonComplianceReport.chosenAction ===
        constants.nonComplianceReports.CONTINUED_CHOSEN_ACTION
      }
      isRisk={isRisk}
      finalized={false}
    />
  )
}

FinalizedOrContinuedAlert.propTypes = {
  nonComplianceReport: PropTypes.object.isRequired,
  isRisk: PropTypes.bool.isRequired,
}

FinalizedOrContinuedAlert.defaultProps = {
  isRisk: false,
}

export default FinalizedOrContinuedAlert
