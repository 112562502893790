import constants from 'constants/index'
import helpers from 'helpers'

const discountFormatterToSubmit = (discountType, discountValue) => {
  const replaceObject = {
    ',': '.',
    '.': '',
  }

  if (discountType === constants.discountCoupon.PERCENTAGE_TYPE)
    return helpers.formatters.percentage(discountValue)
  return parseFloat(
    helpers.formatters
      .currencyInput(discountValue)
      .replace(/[,.]/g, (m) => replaceObject[m]),
  )
}

export default discountFormatterToSubmit
