import * as yup from 'yup'

export const schemaNewPassword = yup.object().shape({
  password: yup
    .string()
    .required()
    .min(8)
    .matches(
      // eslint-disable-next-line
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[ `!*@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]).{8,}$/,
      'Sua nova senha deve possuir letras minúsculas, maiúsculas, números e pelo menos um caractere especial',
    ),
  passwordConfirm: yup
    .string()
    .required()
    .min(8)
    .oneOf([yup.ref('password'), null], 'Senhas não coincidem.'),
  resetPasswordToken: yup.string(),
})

export default schemaNewPassword
