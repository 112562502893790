import { useState } from 'react'
import useSnackbar from 'hooks/useSnackbar'
import PropTypes from 'prop-types'

import { ConfirmationDialog, Permitted, LoadingFeedback } from 'components'

import helpers from 'helpers'
import constants from 'constants/index'
import * as service from 'service'

const InactivateProfileDialog = ({
  profile,
  open,
  setOpen,
  refresh,
  isCollaborator,
}) => {
  const snackbar = useSnackbar()
  const [isLoading, setIsloading] = useState(false)

  const changeStatusPermission = isCollaborator
    ? constants.permissions.COLLABORATOR_PROFILES.CHANGE_STATUS
    : constants.permissions.CLIENT_PROFILES.CHANGE_STATUS
  const changeStatusRoute = isCollaborator
    ? service.dponet.collaboratorProfiles.changeStatus
    : service.dponet.userProfiles.changeStatus
  const labelToChangeStatus = profile?.status ? 'inativar' : 'ativar '

  const onSubmit = async (profile) => {
    try {
      setIsloading(true)
      await changeStatusRoute(profile?.id)

      setIsloading(false)
      snackbar.open({
        message: 'Perfil foi inativado com sucesso!',
        variant: 'success',
      })
      setOpen(false)
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Falha ao inativar o perfil!',
        variant: 'error',
      })
      setIsloading(false)
      setOpen(false)
    }
  }

  return (
    <Permitted tag={changeStatusPermission}>
      <LoadingFeedback open={isLoading} />
      <ConfirmationDialog
        open={open}
        setOpen={setOpen}
        message={`Você tem certeza que deseja ${labelToChangeStatus} o perfil?`}
        buttonText="Confirmar"
        actionAcceptButton={() => onSubmit(profile)}
      />
    </Permitted>
  )
}

InactivateProfileDialog.propTypes = {
  profile: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  refresh: PropTypes.func,
  isCollaborator: PropTypes.bool,
}
InactivateProfileDialog.defaultProps = {
  setOpen: () => {},
  refresh: () => {},
  isCollaborator: false,
}

export default InactivateProfileDialog
