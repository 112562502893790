import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import clsx from 'clsx'

const PrivacyPoliciesInfoCards = ({ bgColorClass, icon, classes }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={clsx(classes.statusCard, bgColorClass)}
    >
      {icon}
    </Box>
  )
}

PrivacyPoliciesInfoCards.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.element,
  classes: PropTypes.object,
}

export default PrivacyPoliciesInfoCards
