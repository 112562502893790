import useSnackbar from 'hooks/useSnackbar'
import { BaseDialog, LoadingFeedback } from 'components'

import * as service from 'service'
import {
  Grid,
  FormControlLabel,
  Switch,
  Typography,
  Box,
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import { getBaseURL } from 'service/env'

import { DatePicker } from '@material-ui/pickers'
import { useState } from 'react'
import helpers from 'helpers'

const ExportConciliationPaymentsDialog = ({ open, setOpen, refresh }) => {
  const [isLoading, setIsloading] = useState(false)
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      initialDate: moment().format('YYYY-MM-DD'),
      finalDate: moment().format('YYYY-MM-DD'),
      isWithdraw: false,
    },
  })

  const onSubmit = async (data) => {
    try {
      setIsloading(true)

      const initialDate = (data.initialDate = moment(data.initialDate).format(
        'YYYY-MM-DD',
      ))
      const finalDate = (data.finalDate = moment(data.finalDate).format(
        'YYYY-MM-DD',
      ))

      const response =
        await service.dponet.preRegistrations.exportConciliationPayments({
          ...data,
          initialDate,
          finalDate,
        })

      downloadFile(`${getBaseURL('dponet')}/${response?.data?.url}`)

      setIsloading(false)
      snackbar.open({
        message: 'Relatório foi gerado com sucesso!',
        variant: 'success',
      })
      setOpen(false)
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Falha na geração do relatório!',
        variant: 'error',
      })
      setIsloading(false)
    }
  }

  const downloadFile = (urlOfx) => {
    fetch(urlOfx)
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob)

        var link = document.createElement('a')
        link.href = url

        const initialDate = moment(watch('initialDate')).format('YYYY-MM-DD')
        const finalDate = moment(watch('finalDate')).format('YYYY-MM-DD')

        link.download = `Conciliacao-inicio-${initialDate}-fim-${finalDate}.ofx`

        document.body.appendChild(link)

        link.click()

        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      })
  }
  return (
    <>
      <LoadingFeedback open={isLoading} />
      <BaseDialog
        formRef="export_conciliation"
        open={open}
        setOpen={setOpen}
        title="Exportar Conciliação"
        closeButtonText="Fechar"
        actionButtonText="Exportar"
      >
        <form id="export_conciliation" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">Exportar por data de:</Typography>
              <Box display="flex" alignItems="center">
                <Box mr={2}>
                  <Typography variant="body1">Pagamento</Typography>
                </Box>
                <FormControlLabel
                  control={
                    <Controller
                      as={<Switch color="primary" />}
                      name="isWithdraw"
                      control={control}
                      onChange={([event]) => event.target.checked}
                    />
                  }
                  label="Saque"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <DatePicker
                    label="Data Início"
                    color="primary"
                    format="DD/MM/yyyy"
                    variant="outlined"
                    error={!!errors.initialDate}
                    helperText={errors?.initialDate?.message}
                    fullWidth
                    required
                  />
                }
                control={control}
                name="initialDate"
                mode="onBlur"
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                as={
                  <DatePicker
                    label="Data Final"
                    color="primary"
                    format="DD/MM/yyyy"
                    variant="outlined"
                    error={!!errors.finalDate}
                    helperText={errors?.finalDate?.message}
                    fullWidth
                    required
                  />
                }
                control={control}
                name="finalDate"
                mode="onBlur"
              />
            </Grid>
          </Grid>
        </form>
      </BaseDialog>
    </>
  )
}

export default ExportConciliationPaymentsDialog
