import helpers from 'helpers'

import constants from 'constants/index'

const mountNecessityAndProportionalityParams = (data, dataTreatmentId) => {
  let dataTreatment = {}

  Object.keys(data).map((key) => {
    let splitKey = key.split('-')
    let keyType = splitKey[0]
    let keyId = splitKey[1]

    if (
      keyType === constants.unnecessaryData.DATA_TREATMENT_TYPE &&
      +keyId === dataTreatmentId
    ) {
      return (dataTreatment = {
        necessary: helpers.formatters.convertBinaryToBool(data[key]),
      })
    }

    return key
  })

  return dataTreatment
}

export default mountNecessityAndProportionalityParams
