import { Container, ContentHeader, Page } from 'components'
import { CompanyForm } from './components'

const CompaniesNew = () => {
  return (
    <Page title="Nova empresa">
      <Container maxWidth={false}>
        <ContentHeader
          menu="Empresas"
          title="Nova empresa"
          subtitle="Criar empresa"
        />
        <CompanyForm editionPermitted />
      </Container>
    </Page>
  )
}

export default CompaniesNew
