import { useState } from 'react'
import { Container, Button } from '@material-ui/core'
import { Download as DownloadIcon } from 'react-feather'

import { useSnackbar, useFetch } from 'hooks'

import { Page, ContentHeader, LoadingFeedback } from 'components'
import { FilterDateGrid, ContextCard, ContextList } from './components'

import * as service from 'service'

import helpers from 'helpers'
import constants from 'constants/index'

const FinancialDashboard = () => {
  const snackbar = useSnackbar()
  const [dateFilter, setDateFilter] = useState('TODAY')
  const [loading, setLoading] = useState(false)

  const { response, isLoading } = useFetch(
    service.dponet.fiancial.stats,
    {
      date: helpers.paymentOrders.calcDate(dateFilter),
    },
    [dateFilter],
  )

  const { response: responseOrders, isLoading: isLoadingOrders } = useFetch(
    service.dponet.fiancial.orders,
    {
      date: helpers.paymentOrders.calcDate(dateFilter),
    },
    [dateFilter],
  )

  const stats = response?.data || {}
  const orders = responseOrders?.data || {}

  const handleReport = async () => {
    setLoading(true)
    try {
      const response = await service.dponet.fiancial.report()

      snackbar.open({
        message:
          response?.data?.message ||
          'Relatório solicitado com sucesso e enviado por e-mail',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.message || 'Erro ao solicitar o Relatório',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Page title="Dashboard financeiro">
      <LoadingFeedback open={loading} />
      <Container maxWidth={false}>
        <ContentHeader
          menu="Vendas"
          title="Dashboard financeiro"
          subtitle="Dashboard financeiro"
        >
          <Button
            variant="outlined"
            color="primary"
            type="button"
            onClick={handleReport}
            startIcon={<DownloadIcon size={20} />}
          >
            Solicitar relatório
          </Button>
        </ContentHeader>
        <FilterDateGrid setDateFilter={setDateFilter} dateFilter={dateFilter} />
        <ContextCard
          isLoading={isLoading}
          title="Faturamento"
          data={stats?.billing}
        >
          <ContextList
            title="Faturamentos Pendentes"
            isLoading={isLoadingOrders}
            orders={orders?.billing}
          />
        </ContextCard>
        <ContextCard
          isLoading={isLoading}
          title="Recebimento"
          data={stats?.collect}
        >
          <ContextList
            title={
              constants.paymentOrder.DESCRIPTION_ORDERS_OVERDUE[dateFilter]
            }
            isLoading={isLoadingOrders}
            orders={orders?.collect}
          />
        </ContextCard>
      </Container>
    </Page>
  )
}

export default FinancialDashboard
