import { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
} from '@material-ui/core'

import { LoadingButton } from 'components'
import { Eye as EyeIcon, EyeOff as EyeOffIcon } from 'react-feather'

import { useSnackbar } from 'hooks'

import * as service from 'service'

const RegeneratePasswordDialog = ({ user, setUser, open, setOpen }) => {
  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState()
  const snackbar = useSnackbar()

  const onSubmit = async () => {
    try {
      setLoading(true)
      const response = await service.dponet.user.generateTemporaryPassword(
        user.id,
      )

      setPassword(response?.data?.password)
    } finally {
      setLoading(false)
    }
  }

  const handleCopy = () => {
    window.navigator.clipboard.writeText(password)
    snackbar.open({
      message: 'Senha copiada com sucesso!',
      variant: 'success',
    })
  }

  const onClose = (_, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
      setUser()
      setPassword()
      setShow(false)
    }
  }

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Redefinir senha de {user?.name}</DialogTitle>
      <DialogContent dividers>
        <TextField
          fullWidth
          disabled
          name="password"
          type={show ? 'text' : 'password'}
          value={password}
          InputProps={{
            endAdornment: (
              <Box display="flex">
                <IconButton onClick={() => setShow((show) => !show)}>
                  {show ? <EyeOffIcon /> : <EyeIcon />}
                </IconButton>
                <Button onClick={handleCopy} disabled={!password}>
                  Copiar
                </Button>
              </Box>
            ),
          }}
        />
      </DialogContent>
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button type="button" variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
        </Box>
        <LoadingButton
          variant="contained"
          type="submit"
          color="primary"
          text="Gerar uma senha"
          loading={loading}
          onClick={onSubmit}
        />
      </DialogActions>
    </Dialog>
  )
}

RegeneratePasswordDialog.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  setOpen: PropTypes.func,
  open: PropTypes.bool,
}

export default RegeneratePasswordDialog
