import clsx from 'clsx'

import PropTypes from 'prop-types'

import {
  Stepper as MuiStepper,
  Step,
  StepConnector,
  StepLabel,
  Typography,
  makeStyles,
  Grid,
  Box,
} from '@material-ui/core'
import {
  Check as CheckIcon,
  Clear as ClearIcon,
  Remove as RemoveIcon,
} from '@material-ui/icons'

import styles from './styles'

import useDataProcess from 'hooks/useDataProcess'

const useStyles = makeStyles(styles)

const Stepper = ({ lastActiveStep, steps, children }) => {
  const classes = useStyles()

  const { setActiveStep } = useDataProcess()

  const Icon = (props) => {
    let { icon } = props
    icon -= 1
    const active = icon === lastActiveStep
    const completed = icon < lastActiveStep
    return (
      <Box
        className={clsx(classes.stepIcon, {
          [classes.stepIconActive]: active,
          [classes.stepIconCompleted]: completed,
        })}
      >
        {icon === lastActiveStep ? (
          <RemoveIcon />
        ) : icon < lastActiveStep ? (
          <CheckIcon />
        ) : (
          <ClearIcon />
        )}
      </Box>
    )
  }

  return (
    <Grid className={classes.root}>
      <MuiStepper
        className={classes.stepper}
        activeStep={lastActiveStep}
        nonLinear
        orientation="vertical"
        connector={<StepConnector className={classes.stepConnector} />}
      >
        {steps.map((label, index) => {
          let labelProps = {}
          if (lastActiveStep < index) {
            labelProps = (
              <Typography
                variant="caption"
                className={classes.stepLabelTextOptional}
              >
                Responder
              </Typography>
            )
          } else if (lastActiveStep === index) {
            labelProps = (
              <Typography
                variant="caption"
                className={classes.stepLabelTextOptional}
              >
                Respondendo
              </Typography>
            )
          } else {
            labelProps = (
              <Typography
                variant="caption"
                className={classes.stepLabelTextOptional}
              >
                Concluído
              </Typography>
            )
          }

          return (
            <Step key={label} className={classes.step}>
              <StepLabel
                StepIconComponent={Icon}
                onClick={() =>
                  setActiveStep(
                    index <= lastActiveStep ? index : lastActiveStep,
                  )
                }
                style={{ cursor: index <= lastActiveStep ? 'pointer' : '' }}
                className={classes.stepLabelText}
                optional={labelProps}
              >
                {label}
              </StepLabel>
            </Step>
          )
        })}
      </MuiStepper>
      <Grid className={classes.actionsContainer}>{children}</Grid>
    </Grid>
  )
}

Stepper.propTypes = {
  lastActiveStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(String).isRequired,
  children: PropTypes.node.isRequired,
}

export default Stepper
