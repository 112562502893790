import * as service from 'service'
import helpers from 'helpers'

import {
  Page,
  Container,
  ContentHeader,
  LoadingFeedback,
  TopCards,
  Card,
  InfoField,
  FormattedPhraseList,
} from 'components'

import { AffectedProcessTable } from './components'

import useFetch from 'hooks/useFetch'

import { Box } from '@material-ui/core'

import { Calendar as CalendarIcon, Clock as ClockIcon } from 'react-feather'
import IncidentMenuItems from './components/IncidentMenuItems'

const IncidentsShow = ({ match }) => {
  const { incidentId } = match.params
  const { response, isLoading } = useFetch(service.dponet.incidents.show, {
    incidentId,
  })
  const cardsList = [
    {
      title: 'DATA DO INCIDENTE',
      description: `${helpers.formatters.date(
        response?.data?.incident?.incidentDatetime,
        false,
      )}`,
      icon: <CalendarIcon color="white" size={18} />,
    },
    {
      title: 'HORA DO INCIDENTE',
      description: `${helpers.formatters.hours(
        response?.data?.incident?.incidentDatetime,
        false,
      )}`,
      icon: <ClockIcon color="white" size={18} />,
    },
  ]
  const incident = response?.data?.incident
  return (
    <Page title="Incidente">
      <LoadingFeedback open={isLoading} />
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Incidentes"
          title="Visualizar incidente"
          subtitle="Incidente"
        >
          {!isLoading && (
            <IncidentMenuItems
              incident={incident}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            />
          )}
        </ContentHeader>
        <TopCards cardsList={cardsList} loading={isLoading} />
        <Card title="Informações sobre Incidentes">
          <Box mt={1}>
            <Box display="inline">
              <InfoField title="Tipo de violação" isComponent>
                <FormattedPhraseList
                  list={incident?.incidentViolationTypes}
                  variant="body2"
                />
              </InfoField>
              <InfoField title="Utilizados por terceiros">
                {incident?.usedExternally ? 'Sim' : 'Não'}
              </InfoField>
              <InfoField title="Descrição do incidente (o que aconteceu e como aconteceu o incidente)">
                {incident?.description}
              </InfoField>
              <InfoField title="Medidas de contenção adotadas durante e logo após o incidente">
                {incident?.descriptionMeasures}
              </InfoField>
              <InfoField
                title="Quantidade estimada de titulares afetados"
                marginBottom={2}
              >
                {incident?.affectedHolders}
              </InfoField>
            </Box>
          </Box>
        </Card>
        <Card title="Processos afetados" dropdown={true}>
          <AffectedProcessTable dataProcesses={incident?.dataProcesses} />
        </Card>
      </Container>
    </Page>
  )
}

export default IncidentsShow
