import { useState, useEffect } from 'react'

import TransferListContext from 'contexts/TransferListContext'

const TransferListProvider = ({ children }) => {
  const [left, setLeft] = useState([])
  const [right, setRight] = useState([])

  const [initialLeft, setInitialLeft] = useState([])
  const [initialRight, setInitialRight] = useState([])

  const [initialCount, setInitialCount] = useState(0)
  const [reload, setReload] = useState(0)

  const reloadToFormat = () => {
    setReload(reload + 1)
  }

  const not = (firstArray, secondArray) => {
    return firstArray.filter((value) => secondArray.indexOf(value) === -1)
  }

  const intersection = (firstArray, secondArray) => {
    return firstArray.filter((value) => secondArray.indexOf(value) !== -1)
  }

  const union = (firstArray, secondArray) => {
    return [...firstArray, ...not(secondArray, firstArray)]
  }

  const itensToDelete = () => {
    return [...not(left, initialLeft)]
  }

  const itensToCreate = () => {
    return [...not(right, initialRight)]
  }

  useEffect(() => {
    if ((left.length > 0 || right.length > 0) && initialCount <= 0) {
      setInitialLeft(left)
      setInitialRight(right)
      setInitialCount(1)
    }
    // eslint-disable-next-line
  }, [left, right, initialCount])

  return (
    <TransferListContext.Provider
      value={{
        left,
        setLeft,
        right,
        setRight,
        setInitialLeft,
        setInitialRight,
        setInitialCount,
        itensToDelete,
        itensToCreate,
        not,
        intersection,
        union,
        reloadToFormat,
        reload,
        setReload,
      }}
    >
      {children}
    </TransferListContext.Provider>
  )
}

export default TransferListProvider
