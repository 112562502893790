import Main from './SegmentsMain'
import New from './SegmentsNew'
import Edit from './SegmentsEdit'

const Segments = {
  Main,
  New,
  Edit,
}

export default Segments
