import benefits from './benefits'
import cep from './cep'
import cnpj from './cnpj'
import cnpjAndCpfMask from './cnpjAndCpfMask'
import cnpjInput from './cnpjInput'
import convertBinaryToBool from './convertBinaryToBool'
import convertBoolToBinary from './convertBoolToBinary'
import convertBoolToString from './convertBoolToString'
import cpf from './cpf'
import cpfInput from './cpfInput'
import creditCards from './creditCards'
import currencyInput from './currencyInput'
import date from './date'
import dateFromNow from './dateFromNow'
import dateTime from './dateTime'
import documentFormatter from './documentFormatter'
import errorMessage from './errorMessage'
import floatToMoney from './floatToMoney'
import formatByNameInFilter from './formatByNameInFilter'
import getIdFromObject from './getIdFromObject'
import hours from './hours'
import isValidEmail from './isValidEmail'
import multipleParagraphs from './multipleParagraphs'
import onlyNumbersString from './onlyNumbersString'
import percentage from './percentage'
import phone from './phone'
import phoneDynamicMask from './phoneDynamicMask'
import phoneMaskBuilder from './phoneMaskBuilder'
import regex from './regex'
import regularizeCurrency from './regularizeCurrency'
import website from './website'
import onlyNumbers from './onlyNumbers'
import float from './float'

const formatters = {
  benefits,
  cep,
  cnpj,
  cnpjAndCpfMask,
  cnpjInput,
  creditCards,
  convertBinaryToBool,
  convertBoolToBinary,
  convertBoolToString,
  cpf,
  cpfInput,
  currencyInput,
  date,
  dateFromNow,
  dateTime,
  documentFormatter,
  errorMessage,
  floatToMoney,
  formatByNameInFilter,
  getIdFromObject,
  hours,
  isValidEmail,
  multipleParagraphs,
  onlyNumbersString,
  percentage,
  phone,
  phoneDynamicMask,
  phoneMaskBuilder,
  regex,
  regularizeCurrency,
  website,
  onlyNumbers,
  float,
}

export default formatters
