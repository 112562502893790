import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

import { Grid, TextField } from '@material-ui/core'
import { Card, Select } from 'components'

import constants from 'constants/index'

const MainForm = ({ control, errors, editionPermitted }) => {
  return (
    <Card title="Preencha os dados da empresa">
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.name}
                helperText={errors?.name?.message}
                label="Nome da empresa template"
                disabled={!editionPermitted}
              />
            }
            control={control}
            name="name"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <Select
                items={constants.companies.STATUSES_OBJECT}
                label="Selecione o status da empresa"
                error={!!errors.statusId}
                helperText={errors?.statusId?.message}
                disabled={!editionPermitted}
              />
            }
            control={control}
            name="statusId"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                fullWidth
                multiline
                rows={4}
                error={!!errors.description}
                helperText={errors?.description?.message}
                label="Breve descrição da empresa"
                disabled={!editionPermitted}
              />
            }
            control={control}
            name="description"
            mode="onBlur"
          />
        </Grid>
      </Grid>
    </Card>
  )
}

MainForm.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  editionPermitted: PropTypes.bool,
}

export default MainForm
