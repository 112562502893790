import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  closeButton: {
    color: theme.palette.black,
    backgroundColor: `${theme.palette.background.default} !important`,
  },
}))

export default styles
