import { useState } from 'react'

import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { Typography, Box, CircularProgress } from '@material-ui/core'

import { BaseDialog, Select as SelectComponent } from 'components'

import { useSnackbar } from 'hooks'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'
import schema from './schema'

const ChangeStatusTrialDialog = ({ open, setOpen, company, refresh }) => {
  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: {
      statusId: company?.statusId || '',
    },
  })

  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()

  const handleChangeStatus = async (data) => {
    setLoading(true)

    try {
      await service.dponet.companies.changeStatusCompanyInTrial({
        companyId: company?.id,
        statusId: data.statusId,
      })
      snackbar.open({
        message: 'Status da empresa atualizada com sucesso!',
        variant: 'success',
      })
      refresh()
      setOpen(false)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Erro ao atualizar status da empresa!',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title="Mudar status da empresa"
      closeButtonText="cancelar"
      actionButtonText="Confirmar"
      formRef="id-form-status_trial"
      noMargin
    >
      <Typography>
        Tem certeza de que deseja alterar o status desta empresa? Lembre-se de
        que isso afetará a disponibilidade das funcionalidades oferecidas a este
        cliente.
      </Typography>
      {loading ? (
        <Box mt={2} display="flex" justifyContent="center" alignItems="center">
          <CircularProgress />
        </Box>
      ) : (
        <Box mt={2}>
          <form
            onSubmit={handleSubmit(handleChangeStatus)}
            id="id-form-status_trial"
          >
            <Controller
              as={
                <SelectComponent
                  items={constants.companies.CHANGE_STATUS_TRIALS}
                  error={!!errors?.statusId}
                  helperText={errors?.statusId?.message}
                />
              }
              fullWidth
              name="statusId"
              control={control}
            />
          </form>
        </Box>
      )}
    </BaseDialog>
  )
}

ChangeStatusTrialDialog.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  company: PropTypes.object.isRequired,
  refresh: PropTypes.func,
}

ChangeStatusTrialDialog.defaultProps = {
  setOpen: () => {},
  refresh: () => {},
}

export default ChangeStatusTrialDialog
