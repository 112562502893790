import * as yup from 'yup'

const schemaPlan = () =>
  yup.object().shape({
    name: yup.string().required(),
    code: yup.string().required(),
    price: yup.string().required(),
  })

export default schemaPlan
