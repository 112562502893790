import chipStatusColor from './chipStatusColor'
import chipStatusLabel from './chipStatusLabel'
import getStepApi from './getStepApi'
import mountConfirmationPay from './mountConfirmationPay'
import saveText from './saveText'
import calcDate from './calcDate'

const paymentOrders = {
  chipStatusColor,
  chipStatusLabel,
  getStepApi,
  mountConfirmationPay,
  saveText,
  calcDate,
}

export default paymentOrders
