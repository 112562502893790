import constants from 'constants/index'

const eliminateNotEliminateSubtitleMessages = (type, chosen) => {
  switch (type) {
    case constants.unnecessaryData.LIFE_CYCLES_TYPE:
      if (chosen === constants.nonComplianceReports.ELIMINATED_CHOSEN_ACTION) {
        return constants.nonComplianceReports.ELIMINATE_LIFE_CYCLES_MESSAGES
      }
      return constants.nonComplianceReports.NOT_ELIMINATE_LIFE_CYCLES_MESSAGES
    case constants.unnecessaryData.COLLECTED_FIELD_TYPE:
      if (chosen === constants.nonComplianceReports.ELIMINATED_CHOSEN_ACTION) {
        return constants.nonComplianceReports.ELIMINATE_COLLECTED_FIELD_MESSAGES
      }
      return constants.nonComplianceReports
        .NOT_ELIMINATE_COLLECTED_FIELD_MESSAGES
    case constants.unnecessaryData.DATA_TREATMENT_TYPE:
      if (chosen === constants.nonComplianceReports.ELIMINATED_CHOSEN_ACTION) {
        return constants.nonComplianceReports.ELIMINATE_DATA_TREATMENT_MESSAGES
      }
      return constants.nonComplianceReports
        .NOT_ELIMINATE_DATA_TREATMENT_MESSAGES
    default:
      return '-'
  }
}

export default eliminateNotEliminateSubtitleMessages
