import Copy from './QuestionnairesCopy'
import Edit from './QuestionnairesEdit'
import Main from './QuestionnairesMain'
import Show from './QuestionnairesShow'

const Questionnaires = {
  Copy,
  Edit,
  Main,
  Show,
}

export default Questionnaires
