import { useState } from 'react'

import {
  Card,
  CardHeader,
  Divider,
  Grid,
  Button,
  Box,
  Typography,
} from '@material-ui/core'

import PropTypes from 'prop-types'

import { PlusCircle as PlusCircleIcon } from 'react-feather'

import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { getBaseURL } from 'service/env'

import { MenuButton, ConfirmationDialog } from 'components'

import { Controller, useForm } from 'react-hook-form'

import { useSnackbar, useDocuments, useDataProcess } from 'hooks'

import helpers from 'helpers'

import * as service from 'service'

const DocumentRow = ({
  document,
  loading,
  setLoading,
  dataProcessId,
  index,
}) => {
  const snackbar = useSnackbar()

  const { setData, data: documents } = useDocuments()

  const onClickDeleteDocument = async (document, index) => {
    setLoading(true)
    const documentName = helpers.functions.documentName(document.url)
    try {
      await service.dponet.documents.deleteDocument({
        dataProcessesId: dataProcessId,
        documentName: documentName,
      })
      delete documents[index]
      setData(documents)
      setLoading(false)
    } catch {
      snackbar.open({
        message: `Ocorreu algum erro ao deletar ${documentName}!\n`,
        variant: 'error',
      })
      setLoading(false)
    }
  }

  return (
    <TableRow>
      <TableCell>{helpers.functions.documentName(document.url)}</TableCell>
      <TableCell disableTooltip align="right">
        <MenuButton>
          <Button
            color="secondary"
            fullWidth
            onClick={() =>
              window.open(`${getBaseURL('dponet')}${document.url}`, '_blank')
            }
          >
            Visualizar
          </Button>
          <Button
            color="secondary"
            fullWidth
            onClick={() => onClickDeleteDocument(document, index)}
            disabled={loading}
          >
            {loading ? 'Aguarde...' : 'Remover'}
          </Button>
        </MenuButton>
      </TableCell>
    </TableRow>
  )
}

DocumentRow.propTypes = {
  document: PropTypes.object.isRequired,
}

const DocumentedInformation = () => {
  const snackbar = useSnackbar()

  const [dialogConfirmAddDocument, setDialogConfirmAddDocument] =
    useState(false)

  const [loading, setLoading] = useState(false)

  const { data: dataProcess } = useDataProcess()

  const { data: documents, loadData } = useDocuments()

  const { control } = useForm()

  const onChangeCreate = async () => {
    const documentInput = document.getElementById('documents')
    const files = documentInput.files

    if (files.length === 0) return

    try {
      setLoading(true)
      await service.dponet.documents.addDocument({
        dataProcessId: dataProcess.id,
        files: files,
      })
      await loadData(dataProcess.id)
      setLoading(false)
      setDialogConfirmAddDocument(false)

      snackbar.open({
        message: 'Documento(s) adicionado(s) com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      setLoading(false)
      setDialogConfirmAddDocument(false)

      snackbar.open({
        message: 'Ocorreu um erro ao adicionar o(s) documento(s)',
        variant: 'error',
      })
    }
  }

  return (
    <Card>
      <Grid container>
        <Grid item xs={6}>
          <CardHeader title="Documentos anexados" />
        </Grid>
        <Grid item xs={6}>
          <Box m={1} display="flex" justifyContent="flex-end">
            <form id="documents-form">
              <Controller
                as={
                  <Button
                    variant="contained"
                    htmlFor="documents"
                    component="label"
                    size="small"
                  >
                    <Box pl={1} pt={1}>
                      <PlusCircleIcon size="18px" />
                    </Box>
                    <Box px={1}>
                      <Typography variant="h6">ADICIONAR</Typography>
                    </Box>
                    <input
                      type="file"
                      multiple
                      id="documents"
                      style={{ display: 'none' }}
                    />
                  </Button>
                }
                control={control}
                onChange={() => setDialogConfirmAddDocument(true)}
                name="documents"
              />
            </form>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <PerfectScrollbar>
        <Table emptyMessage="Nenhum documento encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="75%">Documentos</TableCell>
              <TableCell width="25%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document, index) => (
              <DocumentRow
                document={document}
                key={index}
                loading={loading}
                setLoading={setLoading}
                dataProcessId={dataProcess.id}
                index={index}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <ConfirmationDialog
        open={dialogConfirmAddDocument}
        setOpen={setDialogConfirmAddDocument}
        message="Você confirma que não existe nenhum dado pessoal no modelo/template
          selecionado? Lembre-se de que a existência de qualquer informação no
          documento é de responsabilidade do usuário."
        cancelButtonText="Cancelar"
        buttonText="Confirmo"
        actionAcceptButton={onChangeCreate}
      />
    </Card>
  )
}

export default DocumentedInformation
