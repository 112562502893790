const PROFILE_TYPE_CLIENT = 'Client'
const PROFILE_TYPE_COLLABORATOR = 'Collaborator'
const ALL_TAB = 0
const ACTIVE_TAB = 1
const INACTIVE_TAB = 2
const ADMIN_PROFILE_ID = 1
const ACTIVE = true
const INACTIVE = false

const NEWLY_CREATED = 'newly_created'
const OLDER_CREATED = 'older_created'

const TABS = [
  {
    value: ALL_TAB,
    label: 'Todos',
  },
  {
    value: ACTIVE_TAB,
    label: 'Ativos',
  },
  {
    value: INACTIVE_TAB,
    label: 'Inativos',
  },
]

const user = {
  ADMIN_PROFILE_ID,
  PROFILE_TYPE_CLIENT,
  PROFILE_TYPE_COLLABORATOR,
  TABS,
  ALL_TAB,
  ACTIVE_TAB,
  INACTIVE_TAB,
  INACTIVE,
  ACTIVE,
  NEWLY_CREATED,
  OLDER_CREATED,
}

export default user
