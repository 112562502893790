import * as yup from 'yup'

const schema = (phoneIsEmpty) => {
  return yup.object().shape({
    selectExistentResponsible: yup.boolean(),
    oldResponsibleId: yup.number().required(),
    name: yup.string().when('selectExistentResponsible', {
      is: false,
      then: yup.string().required(),
    }),
    email: yup.string().when('selectExistentResponsible', {
      is: false,
      then: yup.string().email().required(),
    }),
    userCompanyId: yup.object().when('selectExistentResponsible', {
      is: true,
      then: yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(),
      }),
    }),
    ...(phoneIsEmpty && { phone: yup.string().required() }),
  })
}

export default schema
