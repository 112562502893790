import { isUndefined } from 'lodash'

import * as service from 'service'

const listCache = (params, options = {}) => {
  const keys = isUndefined(params)
    ? ['lastUpdates', 'list']
    : ['lastUpdates', 'list', params]

  const queryFn = () =>
    service.dponet.lastUpdates.get(params).then((response) => response.data)

  return { queryKey: keys, queryFn: queryFn, exact: false, ...options }
}

const viewCache = (id, options = {}) => {
  const keys = ['lastUpdates', 'view', parseInt(id)]
  const queryFn = () =>
    service.dponet.lastUpdates
      .get({ lastUpdateId: id })
      .then((response) => response.data)

  return { queryKey: keys, queryFn: queryFn, exact: true, ...options }
}

const lastUpdates = {
  listCache,
  viewCache,
}

export default lastUpdates
