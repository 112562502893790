/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react'

import { Filters } from 'components'

import helpers from 'helpers'

const PlanFilter = ({ filter }) => {
  const [currencyValues, setCurrencyValues] = useState({
    priceGteq: helpers.formatters.currencyInput(
      filter?.filters?.priceGteq || '',
    ),
    priceLteq: helpers.formatters.currencyInput(
      filter?.filters?.priceLteq || '',
    ),
    implementationPrice: helpers.formatters.currencyInput(
      filter?.filters?.implementationPrice || '',
    ),
  })

  const handleChangeCurrency = (e) => {
    const convertedValue = helpers.formatters.currencyInput(e.target.value)
    setCurrencyValues({ ...currencyValues, [e.target.name]: convertedValue })
    return convertedValue
  }

  return (
    <Filters filter={filter}>
      <input textfieldinput="true" label="Identificador" name="id" />
      <input textfieldinput="true" label="Nome do plano" name="name" />
      <input
        textfieldinput="true"
        label="Mensalidade maior que"
        name="priceGteq"
        willreturnvalue="true"
        value={currencyValues.priceGteq}
        onChange={handleChangeCurrency}
      />
      <input
        textfieldinput="true"
        label="Mensalidade menor que"
        name="priceLteq"
        willreturnvalue="true"
        value={currencyValues.priceLteq}
        onChange={handleChangeCurrency}
      />
    </Filters>
  )
}

export default PlanFilter
