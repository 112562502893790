import { useContext } from 'react'

import RmcContext from 'contexts/RmcContext'

const useRmc = () => {
  const context = useContext(RmcContext)

  if (context === undefined) {
    throw new Error('useRmc must be used within a RmcContext.Provider')
  }

  return {
    data: context.data,
    setData: context.setData,
    loading: context.loading,
    setLoading: context.setLoading,
    loadData: context.loadData,
    reload: context.reload,
    isEditable: context.isEditable,
    setIsEditable: context.setIsEditable,
    riskStep: context.riskStep,
    setRiskStep: context.setRiskStep,
    isEditableByFinality: context.isEditableByFinality,
    oldScreen: context.oldScreen,
    unnecessaryDataValues: context.unnecessaryDataValues,
    setUnnecessaryDataValues: context.setUnnecessaryDataValues,
    securityMeasures: context.securityMeasures,
    setSecurityMeasures: context.setSecurityMeasures,
    initialSecurityMeasures: context.initialSecurityMeasures,
    setInitialSecurityMeasures: context.setInitialSecurityMeasures,
    isContinuedFinalized: context.isContinuedFinalized,
  }
}

export default useRmc
