import PropTypes from 'prop-types'

import { isEmpty } from 'lodash'

import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  makeStyles,
} from '@material-ui/core'

import styles from './styles'

const useStyles = makeStyles(styles)

const QuestionRow = ({ question, index }) => {
  return (
    <>
      <Box p={2}>
        <Typography variant="h5" gutterBottom>
          {`${index}. ${question?.theme}`}
        </Typography>
        <Box pl={2.3}>
          <Typography variant="body1" gutterBottom>
            {question?.question}
          </Typography>
        </Box>
      </Box>
      <Divider />
    </>
  )
}

const QuestionnaireDetails = ({ questionnaire }) => {
  const classes = useStyles()

  return (
    <Box mt={4}>
      <Box my={2}>
        <Typography variant="h4" gutterBottom>
          Todas as medidas adotadas
        </Typography>
      </Box>
      <Card classes={{ root: classes.cardContent }}>
        <CardHeader title="Medidas adotadas" />
        <Divider />
        <CardContent className={classes.cardContent}>
          {questionnaire?.questionsPositive?.map((question, index) => (
            <QuestionRow question={question} index={index + 1} key={index} />
          ))}
          {isEmpty(questionnaire?.questionsPositive) && (
            <Box p={2}>
              <Typography variant="body1" gutterBottom>
                Não foram encontradas medidas adotadas para esse questionário
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
      <Box mt={4} mb={2}>
        <Typography variant="h4" gutterBottom>
          Todas as medidas não adotadas
        </Typography>
      </Box>
      <Card>
        <CardHeader title="Medidas não adotadas" />
        <Divider />
        <CardContent className={classes.cardContent}>
          {questionnaire?.questionsNegative?.map((question, index) => (
            <QuestionRow question={question} index={index + 1} key={index} />
          ))}
          {isEmpty(questionnaire?.questionsNegative) && (
            <Box p={2}>
              <Typography variant="body1" gutterBottom>
                Não foram encontradas medidas não adotadas para esse
                questionário
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}

QuestionnaireDetails.propTypes = {
  questionnaire: PropTypes.object,
}

QuestionRow.propTypes = {
  question: PropTypes.object,
  index: PropTypes.number,
}

export default QuestionnaireDetails
