import permissions from '../../permissions'

const DIAGNOSTICS_LIST = [
  {
    name: 'Editar todos os controles',
    tag: permissions.DIAGNOSTICS.UPDATE,
  },
  {
    name: 'Assumir risco de controles',
    tag: permissions.DIAGNOSTICS.TAKE_RISK,
  },
  {
    name: 'Reabrir controle para edição',
    tag: permissions.DIAGNOSTICS.OPEN_WRITE,
  },
]

export default DIAGNOSTICS_LIST
