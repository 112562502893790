import { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { Eye, PlusCircle } from 'react-feather'

import { Grid, makeStyles, Button, Box } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'
import { DialogDataCollected } from '../../DialogDataCollected'
import { DialogRemoveDataCollected } from '../../DialogRemoveDataCollected'
import { DialogShowDataCollected } from '../../DialogShowDataCollected'
import { DescriptionPaper } from '../..'

import { LoadingBox, MenuButton, Permitted } from 'components'

import useFetch from 'hooks/useFetch'
import useDataProcess from 'hooks/useDataProcess'
import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import constants from 'constants/index'

import styles from '../styles'
import * as service from 'service'

const useStyles = makeStyles(styles)

const DataCollected = ({ description }) => {
  const [dialogViewDataCollected, setDialogViewDataCollected] = useState(false)
  const [dialogRemoveDataCollected, setDialogRemoveDataCollected] =
    useState(false)
  const [dialogDataCollected, setDialogDataCollected] = useState(false)
  const [dataCollectedId, setDataCollectedId] = useState()
  const [editionDataCollected, setEditionDataCollected] = useState()
  const [showDataCollected, setShowDataCollected] = useState()
  const [loading, setLoading] = useState(false)

  const {
    data: dataProcess,
    nextStep,
    toNextStep,
    setToNextStep,
    reloadOptions,
    reloadDataProcess,
  } = useDataProcess()

  const classes = useStyles()
  const snackbar = useSnackbar()

  const { isLoading, refresh, response } = useFetch(
    service.dponet.dataCollecteds.get,
    {
      dataProcessId: dataProcess.id,
    },
  )

  useEffect(() => {
    if (toNextStep) {
      if (!isLoading) {
        if (verifyRuleToNextStep()) {
          nextStep()
          setToNextStep(false)
        } else {
          snackbar.open({
            message: 'É necessário ter pelo menos um dado tratado cadastrado.',
            variant: 'error',
          })
          setToNextStep(false)
        }
      }
    }
    // eslint-disable-next-line
  }, [nextStep])

  const verifyRuleToNextStep = () => {
    return response.data.dataCollecteds.length > 0
  }

  const handleDelete = async (dataProcessId, dataCollectedId) => {
    try {
      setLoading(true)
      await service.dponet.dataCollecteds.destroy({
        dataProcessId,
        dataCollectedId,
      })
      setDialogRemoveDataCollected(false)
      setLoading(false)
      snackbar.open({
        message: 'Dado tratado removido com sucesso!',
        variant: 'success',
      })

      refresh()
      reloadOptions()
      reloadDataProcess()
    } catch (err) {
      setLoading(false)
      snackbar.open({
        message: 'Não foi possível deletar dado tratado. Tente novamente!',
        variant: 'error',
      })
    }
  }

  const removeDataCollected = (dataCollectedId) => {
    setDataCollectedId(dataCollectedId)
    setDialogRemoveDataCollected(true)
  }

  const handleEdit = (dataCollected) => {
    setEditionDataCollected(dataCollected)
    setDialogDataCollected(true)
  }

  const handleCreate = () => {
    setEditionDataCollected(null)
    setDialogDataCollected(true)
  }

  const handleShow = (dataCollected) => {
    setShowDataCollected(dataCollected)
    setDialogViewDataCollected(true)
  }

  return (
    <>
      {isLoading || loading ? (
        <LoadingBox />
      ) : (
        <>
          <DescriptionPaper description={description}>
            <Permitted tag={constants.permissions.DATA_PROCESSES.CREATE}>
              <Button
                variant="outlined"
                startIcon={<PlusCircle size={16} />}
                onClick={() => handleCreate()}
              >
                Adicionar
              </Button>
            </Permitted>
          </DescriptionPaper>
          <Grid container className={classes.root}>
            <Grid xs={12} item>
              <Box maxHeight="82%">
                <PerfectScrollbar>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width="20%">Identificador</TableCell>
                        <TableCell width="20%">Categoria do titular</TableCell>
                        <TableCell width="20%">Faixa Etária</TableCell>
                        <TableCell width="10%">
                          Quantidade aproximada de titulares
                        </TableCell>
                        <TableCell width="20%" align="center">
                          Dados Tratados
                        </TableCell>
                        <TableCell width="10%" align="right">
                          Ações
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {response?.data?.dataCollecteds.map((dataCollected) => (
                      <TableBody key={dataCollected.id}>
                        <TableRow>
                          <TableCell>{dataCollected.id}</TableCell>
                          <TableCell>
                            {dataCollected?.titularCategory?.name}
                          </TableCell>
                          <TableCell>
                            {helpers.dataCollected.rangeAgeContent(
                              dataCollected?.titularAgeRange,
                            )}
                          </TableCell>
                          <TableCell>
                            {dataCollected?.holderNumber || 'Não definido'}
                          </TableCell>
                          <TableCell align="center" disableTooltip>
                            <Button
                              size="small"
                              variant="outlined"
                              onClick={() => handleShow(dataCollected)}
                              startIcon={<Eye size={16} />}
                            >
                              Visualizar
                            </Button>
                          </TableCell>
                          <TableCell disableTooltip>
                            <Permitted
                              tag={constants.permissions.DATA_PROCESSES.CREATE}
                            >
                              <Box display="flex" justifyContent="flex-end">
                                <MenuButton>
                                  <Button
                                    color="secondary"
                                    fullWidth
                                    onClick={() => handleEdit(dataCollected)}
                                  >
                                    Editar
                                  </Button>
                                  <Button
                                    color="secondary"
                                    fullWidth
                                    onClick={() =>
                                      removeDataCollected(dataCollected.id)
                                    }
                                  >
                                    Excluir
                                  </Button>
                                </MenuButton>
                              </Box>
                            </Permitted>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ))}
                  </Table>
                </PerfectScrollbar>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
      <DialogShowDataCollected
        open={dialogViewDataCollected}
        setOpen={setDialogViewDataCollected}
        dataCollected={showDataCollected}
      />
      <DialogRemoveDataCollected
        handleRemove={handleDelete}
        open={dialogRemoveDataCollected}
        setOpen={setDialogRemoveDataCollected}
        dataProcessId={dataProcess.id}
        dataCollectedId={dataCollectedId}
      />
      {dialogDataCollected ? (
        <DialogDataCollected
          dataProcessId={dataProcess.id}
          open={dialogDataCollected}
          setOpen={setDialogDataCollected}
          onEvent={refresh}
          setLoading={setLoading}
          dataCollected={editionDataCollected || {}}
        />
      ) : null}
    </>
  )
}

DataCollected.propTypes = {
  description: PropTypes.string.isRequired,
}

export default DataCollected
