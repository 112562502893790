import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { Autocomplete } from '@material-ui/lab'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Divider,
  Box,
  Button,
} from '@material-ui/core'

import helpers from 'helpers'

import { useSnackbar } from 'hooks'

import * as service from 'service'

import schema from './schema'
import useStyles from './styles'

const LinkUserModal = ({
  availableUsers,
  availableUsersRefresh,
  department,
  open = false,
  refresh = () => {},
  setLoading = () => {},
  setOpen = () => {},
}) => {
  const snackbar = useSnackbar()
  const classes = useStyles()

  const { handleSubmit, control, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      responsible: [],
    },
  })

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      await service.dponet.departments.linkUser({
        departmentId: department?.id,
        ...data,
      })

      snackbar.open({
        message: 'Usuário vinculado ao departamento com sucesso!',
        variant: 'success',
      })
      setOpen(false)
      refresh()
      availableUsersRefresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="add-user"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        <Box>
          <Typography variant="h5">
            Adicionar usuário ao departamento
          </Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {isEmpty(availableUsers) ? (
          <Box pb={1}>
            <Typography variant="h6">
              Não há nenhum usuário para ser adicionado ao departamento
            </Typography>
          </Box>
        ) : (
          <Box
            py={1}
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            id="add-user-form"
          >
            <Controller
              control={control}
              name="responsible"
              mode="onChange"
              onChange={([, data]) => data}
              as={
                <Autocomplete
                  id="combo-box-user"
                  options={availableUsers || []}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) =>
                    option.id === value.id || value.id === 0
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Usuário"
                      error={!!errors.responsible}
                      helperText={errors?.responsible?.message}
                      fullWidth
                    />
                  )}
                  multiple
                  fullWidth
                />
              }
            />
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions className={classes.dialogActions}>
        <Button type="button" variant="outlined" onClick={() => setOpen(false)}>
          Cancelar
        </Button>
        <Button
          variant="contained"
          type="submit"
          disabled={isEmpty(availableUsers)}
          color="primary"
          form="add-user-form"
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

LinkUserModal.propTypes = {
  availableUsers: PropTypes.arrayOf(PropTypes.object),
  availableUsersRefresh: PropTypes.func,
  department: PropTypes.object,
  open: PropTypes.bool,
  refresh: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
  setOpen: PropTypes.func,
}

export default LinkUserModal
