/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

const useFetch = (
  fn = () => {},
  params,
  observable = [],
  condition = true,
  oneTime = false,
) => {
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const request = () => {
    setIsLoading(true)

    fn(params)
      .then((data) => setResponse(data))
      .catch((error) => setError(error))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    if (condition && (!oneTime || !response)) request()
  }, [...observable, condition])

  return { response, error, isLoading, refresh: request }
}

export default useFetch
