import PropTypes from 'prop-types'

import {
  Grid,
  Tooltip,
  Typography,
  makeStyles,
  Hidden,
} from '@material-ui/core'

import logo from 'images/dponet-logo-blue.png'

import styles from './styles'

import { LoadingBox } from 'components'

const useStyles = makeStyles(styles)

const Header = ({ data, isLoading, ...rest }) => {
  const classes = useStyles()
  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <Grid
          className={classes.root}
          container
          alignItems="flex-start"
          spacing={3}
        >
          <Hidden smUp>
            <Grid item xs={12}>
              <img alt="Logo" src={logo} width="75%" />
            </Grid>
          </Hidden>
          <Grid xl={10} lg={9} sm={8} xs={12} item>
            <Tooltip title={data.department?.name}>
              <Typography variant="h4" className={classes.ellipsis}>
                {data.department?.name}
              </Typography>
            </Tooltip>
          </Grid>
          <Hidden smDown>
            <Grid item xl={2} lg={3} sm={4} xs={12}>
              <img alt="Logo" src={logo} width="75%" />
            </Grid>
          </Hidden>
        </Grid>
      )}
    </>
  )
}

Header.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default Header
