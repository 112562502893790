import { Button, Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { ContentHeader, Permitted, FilterButton } from 'components'

import constants from 'constants/index'
import { routes } from 'Routes'

const ContentHeaderMain = ({ filter }) => {
  const history = useHistory()

  return (
    <ContentHeader
      menu="Processos"
      title="Todos os processos"
      subtitle="Todos os processos"
      filter={filter}
    >
      <Permitted tag={constants.permissions.DATA_PROCESSES.CREATE}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push(routes.dataProcess.new)}
        >
          NOVO PROCESSO
        </Button>
        <Box mx={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(routes.dataProcess.copy)}
          >
            COPIAR
          </Button>
        </Box>
      </Permitted>
      <FilterButton setDrawerOpen={filter.setDrawerOpen} />
    </ContentHeader>
  )
}

export default ContentHeaderMain
