const KIND = {
  ROPA: 'ropa',
  COMPLIANCE: 'compliance',
  RIPD: 'ripd',
}

const report = {
  KIND,
}

export default report
