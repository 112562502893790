const EDIT = 'edit'
const SHOW = 'show'
const CREATE = 'create'

const mode = {
  EDIT,
  SHOW,
  CREATE,
}

export default mode
