import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box } from '@material-ui/core'

import IntegrationPartnerTableRow from '../IntegrationPartnerTableRow'
import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'

const IntegrationPartnerTable = ({ integrationPartners, refresh }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhum parceiro encontrada" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="25%" align="left">
                Nome
              </TableCell>
              <TableCell width="15%" align="left">
                Plano
              </TableCell>
              <TableCell width="10%" align="left">
                Valor
              </TableCell>
              <TableCell width="15%" align="left">
                Plataforma
              </TableCell>
              <TableCell width="15%" align="center">
                Status
              </TableCell>
              <TableCell width="10%" align="center">
                Última atualização
              </TableCell>
              <TableCell width="5%" align="left">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {integrationPartners?.map((integrationPartner) => (
              <IntegrationPartnerTableRow
                integrationPartner={integrationPartner}
                refresh={refresh}
                key={integrationPartner.id}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

IntegrationPartnerTable.propTypes = {
  integrationPartners: PropTypes.arrayOf(PropTypes.object),
  refresh: PropTypes.func,
}

IntegrationPartnerTable.defaultProps = {
  refresh: () => {},
}

export default IntegrationPartnerTable
