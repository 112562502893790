import { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'

import { Grid, Avatar, Box, Typography } from '@material-ui/core'
import { Card } from 'components'
import { Upload } from 'react-feather'

import { getBaseURL } from 'service/env'
import useStyles from './styles'

const LogoInput = ({ logo, setLogoFile, editionPermitted }) => {
  const classes = useStyles()
  const onDrop = useCallback((acceptedFile) => {
    setLogoFile(acceptedFile[0])
    setImage(URL.createObjectURL(acceptedFile[0]))
    // eslint-disable-next-line
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const { ref, ...rootProps } = getRootProps()
  const [image, setImage] = useState(logo ? getBaseURL('dponet') + logo : '')

  return (
    <Card title="Logotipo da empresa">
      <Grid container>
        <Grid item md={2} xs={12} className={classes.logoContainer}>
          <Avatar src={image} className={classes.logoBox} />
        </Grid>
        <Grid item md={10} xs={12}>
          <Box
            {...rootProps}
            className={classes.inputImageContainer}
            mb={3}
            rootref={ref}
          >
            <input {...getInputProps()} disabled={!editionPermitted} />
            <Box>
              <Upload />
            </Box>
            <Box>
              <Typography>Arraste e solte ou selecione uma logo</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Card>
  )
}

LogoInput.prototype = {
  logo: PropTypes.any,
  setLogoFile: PropTypes.func,
  editionPermitted: PropTypes.bool,
}

LogoInput.defaultProps = {
  setLogoFile: () => {},
}

export default LogoInput
