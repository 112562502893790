import { Controller } from 'react-hook-form'
import clsx from 'clsx'

import constants from 'constants/index'

import useStyles from './styles'

import {
  Grid,
  FormControl,
  FormControlLabel,
  Card as CardMaterial,
  Radio,
  RadioGroup,
} from '@material-ui/core'

const RadioTypeCompany = ({ control, watch, disabled = false }) => {
  const classes = useStyles()

  return (
    <Grid item xs={12}>
      <FormControl size="small" variant="outlined">
        <Controller
          control={control}
          name="kind"
          as={
            <RadioGroup row className={classes.radioGroup}>
              {constants.companies.KINDS_REALS.map((kind) => (
                <CardMaterial
                  className={clsx({
                    [classes.selected]: watch('kind') === kind.id,
                    [classes.cardMaterial]: watch('kind') !== kind.id,
                  })}
                  key={kind.id}
                >
                  <FormControlLabel
                    label={kind.name}
                    value={kind.id}
                    control={<Radio />}
                    disabled={disabled}
                  />
                </CardMaterial>
              ))}
            </RadioGroup>
          }
        />
      </FormControl>
    </Grid>
  )
}

export default RadioTypeCompany
