import { useState } from 'react'

import {
  Card,
  CardHeader,
  Divider,
  Grid,
  Button,
  Box,
  Typography,
} from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'

import { PlusCircle as PlusCircleIcon } from 'react-feather'

import { useSnackbar, useDataProcess } from 'hooks'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'

import { LoadingFeedback, MenuButton } from 'components'

import DialogRemoveDataLifeCycle from '../../DialogRemoveDataLifeCycle'
import DialogDataLifeCycle from '../../DialogDataLifeCycle'

import * as service from 'service'
import helpers from 'helpers'

const TableLifeCyclesRow = ({ lifeCycle, setLoading, reloadOptions }) => {
  const snackbar = useSnackbar()
  const { data: dataProcess, reloadDataProcess } = useDataProcess()
  const [dialogRemoveDataLifeCycle, setDialogRemoveDataLifeCycle] =
    useState(false)
  const [removableDataLifeCycleId, setRemovableDataLifeCycleId] = useState()
  const [dialogDataLifeCycle, setDialogDataLifeCycle] = useState(false)
  const [editableDataLifeCycle, setEditableDataLifeCycle] = useState({})

  const [mode, setMode] = useState('create')

  const handleDelete = async () => {
    setLoading(true)
    try {
      await service.dponet.dataLifeCycles.destroy({
        dataProcessId: dataProcess.id,
        dataLifeCycleId: lifeCycle.id,
      })
      reloadDataProcess()
      snackbar.open({
        message: 'Salvaguarda e ciclo de vida removido com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }

    setLoading(false)
    reloadOptions()
  }

  const handleOpenRemoveDialog = (dataLifeCycle) => {
    setRemovableDataLifeCycleId(dataLifeCycle.id)
    setDialogRemoveDataLifeCycle(true)
  }

  const handleOpenDialogDataLifeCycle = (dataLifeCycle = {}, mode = 'edit') => {
    setEditableDataLifeCycle(dataLifeCycle)
    setMode(mode)
    setDialogDataLifeCycle(true)
  }

  const valueVolumetry = !!lifeCycle?.value && lifeCycle?.volumetry

  return (
    <TableRow key={lifeCycle.id}>
      <TableCell>{lifeCycle?.storageLocation?.name}</TableCell>
      <TableCell> {lifeCycle?.internalAllocationMode?.name}</TableCell>
      <TableCell>
        {helpers.dataProcesses.storageModeType(
          lifeCycle?.storageType,
          lifeCycle?.value,
          lifeCycle?.volumetry,
          valueVolumetry,
        )}
      </TableCell>
      <TableCell>{lifeCycle?.retentionFinality}</TableCell>
      <TableCell>{helpers.dataProcesses.discardMode(lifeCycle)}</TableCell>
      <TableCell>{lifeCycle?.protection?.name}</TableCell>
      <TableCell disableTooltip>
        <Box display="flex" justifyContent="flex-end">
          <MenuButton>
            <Button
              color="secondary"
              fullWidth
              onClick={() => handleOpenDialogDataLifeCycle(lifeCycle, 'edit')}
            >
              Editar
            </Button>
            <Button
              color="secondary"
              fullWidth
              onClick={() => handleOpenRemoveDialog(lifeCycle)}
            >
              Excluir
            </Button>
          </MenuButton>
        </Box>
      </TableCell>
      <DialogRemoveDataLifeCycle
        open={dialogRemoveDataLifeCycle}
        setOpen={setDialogRemoveDataLifeCycle}
        dataProcessId={dataProcess?.id}
        dataLifeCycleId={removableDataLifeCycleId}
        handleRemove={handleDelete}
      />
      <DialogDataLifeCycle
        open={dialogDataLifeCycle}
        setOpen={setDialogDataLifeCycle}
        dataProcessId={dataProcess?.id}
        setLoading={setLoading}
        dataLifeCycle={editableDataLifeCycle}
        mode={mode}
      />
    </TableRow>
  )
}

TableLifeCyclesRow.propTypes = {
  lifeCycle: PropTypes.object.isRequired,
  setLoading: PropTypes.func,
  reloadOptions: PropTypes.func,
}

const LifeCycles = () => {
  const [dialogDataLifeCycle, setDialogDataLifeCycle] = useState(false)
  const [editableDataLifeCycle, setEditableDataLifeCycle] = useState({})

  const [loading, setLoading] = useState(false)
  const [mode, setMode] = useState('create')

  const { data, reloadOptions } = useDataProcess()

  const handleOpenDialogDataLifeCycle = (
    dataLifeCycle = {},
    mode = 'create',
  ) => {
    setEditableDataLifeCycle(dataLifeCycle)
    setMode(mode)
    setDialogDataLifeCycle(true)
  }

  return (
    <Card>
      <Grid container>
        <Grid item xs={6}>
          <CardHeader title="Salvaguarda e Ciclo de Vida" />
        </Grid>
        <Grid item xs={6}>
          <Box m={1} display="flex" justifyContent="flex-end">
            <Button
              type="button"
              variant="contained"
              size="small"
              onClick={() => handleOpenDialogDataLifeCycle()}
            >
              <Box pl={1} pt={1}>
                <PlusCircleIcon size="18px" />
              </Box>
              <Box px={1}>
                <Typography variant="h6">ADICIONAR</Typography>
              </Box>
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Divider />
      <LoadingFeedback open={loading} />
      <PerfectScrollbar>
        <Table
          emptyMessage="Nenhum salvaguarda e ciclo de vida encontrado"
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell>Onde os dados são armazenados</TableCell>
              <TableCell>Ambiente de alocação</TableCell>
              <TableCell>Tempo de Armazenamento</TableCell>
              <TableCell>Justificativa de retenção</TableCell>
              <TableCell>Como os dados são dispostos</TableCell>
              <TableCell>Forma de proteção</TableCell>
              <TableCell width="10%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.lifeCycles?.map((lifeCycle) => (
              <TableLifeCyclesRow
                lifeCycle={lifeCycle}
                setLoading={setLoading}
                reloadOptions={reloadOptions}
                key={lifeCycle.id}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <DialogDataLifeCycle
        open={dialogDataLifeCycle}
        setOpen={setDialogDataLifeCycle}
        dataProcessId={data?.id}
        setLoading={setLoading}
        dataLifeCycle={editableDataLifeCycle}
        dataProcesses={data}
        mode={mode}
      />
    </Card>
  )
}
export default LifeCycles
