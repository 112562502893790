import PropTypes from 'prop-types'
import { useQuery, useQueryClient } from '@tanstack/react-query'

const useCache = (key, fn = () => {}, dependentDataCache) => {
  const queryClient = useQueryClient()

  const onSuccess = () => {
    dependentDataCache.map(({ queryKey, exact = true }) =>
      queryClient.invalidateQueries({ queryKey, exact }),
    )
  }

  const onError = () => {
    queryClient.invalidateQuery({ queryKey: key, exact: true })
  }

  return useQuery({
    queryKey: key,
    queryFn: fn,
    onSuccess,
    onError,
  })
}

useCache.propTypes = {
  key: PropTypes.array.isRequired,
  fn: PropTypes.func.isRequired,
  dependentDataCache: PropTypes.array,
}

useCache.defaultProps = {
  dependentDataCache: [],
}

export default useCache
