const ALL_TAB = 0
const PUBLISHED_TAB = 1
const DRAFT_TAB = 2
const INACTIVE_TAB = 3

const KINDS = {
  NEW: 'N',
  IMPROVEMENT: 'I',
}

const KINDS_LABELS = {
  N: 'Novidade',
  I: 'Melhoria',
}

const SELECT_LAST_UPDATE_TYPE = [
  { id: KINDS.NEW, name: KINDS_LABELS.N },
  { id: KINDS.IMPROVEMENT, name: KINDS_LABELS.I },
]

const TABS = [
  {
    value: ALL_TAB,
    label: 'TODAS',
  },
  {
    value: PUBLISHED_TAB,
    label: 'PUBLICADAS',
  },
  {
    value: DRAFT_TAB,
    label: 'RASCUNHO',
  },
  {
    value: INACTIVE_TAB,
    label: 'INATIVAS',
  },
]

const lastUpdates = {
  ALL_TAB,
  PUBLISHED_TAB,
  DRAFT_TAB,
  INACTIVE_TAB,
  TABS,
  KINDS,
  KINDS_LABELS,
  SELECT_LAST_UPDATE_TYPE,
}

export default lastUpdates
