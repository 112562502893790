import * as yup from 'yup'

const schema = ({ hasBenefits }) =>
  yup.object().shape({
    reason: yup.string().when('status', {
      is: 'churn',
      then: yup.string().required(),
    }),
    status: yup.string().required(),
    description: yup.string().min(4).max(1000),
    benefits: yup.string().when([], {
      is: () => hasBenefits,
      then: yup.string().required(),
    }),
    discount: yup
      .string()
      .max(10)
      .when('benefits', {
        is: 'discount',
        then: yup.string().max(10).required(),
      }),
    gratuity: yup
      .string()
      .max(2)
      .when('benefits', {
        is: 'freeMonthlyFee',
        then: yup.string().max(2).required(),
      }),
  })

export default schema
