import { useState } from 'react'

import {
  Card,
  Container,
  ContentHeader,
  LoadingFeedback,
  Page,
} from 'components'
import { TechnicalFaqsForm } from './components'

const TechnicalFaqsNew = () => {
  const [loading, setLoading] = useState(false)

  return (
    <Page title="Nova FAQ - Apoio Técnico">
      <LoadingFeedback open={loading} />
      <Container spacingXl={40} spacingLg={25} spacingMd={10} spacingSm={10}>
        <ContentHeader menu="Apoio Técnico" title="Nova" subtitle="FAQs" />
        <Card title="Adicionar nova FAQ ao apoio técnico">
          <TechnicalFaqsForm setLoading={setLoading} />
        </Card>
      </Container>
    </Page>
  )
}

export default TechnicalFaqsNew
