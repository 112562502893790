import { Filters } from 'components'

const TechnicalFaqsFilter = ({ filter, subcategory }) => {
  return (
    <Filters filter={filter}>
      <input
        textfieldinput="true"
        label="Identificador"
        type="number"
        name="id"
      />
      <input textfieldinput="true" label="Título" name="title" />
      <select
        multipleselectinput="true"
        label="Subcategoria"
        name="subCategoryId"
        optionvalues={subcategory}
      />
      <input textfieldinput="true" label="Autor" name="author" />
      <input datepickerinput="true" label="FAQ's antes de" name="updatedAtLt" />
      <input
        datepickerinput="true"
        label="FAQ's depois de"
        name="updatedAtGt"
      />
    </Filters>
  )
}

export default TechnicalFaqsFilter
