const styles = (theme) => ({
  buttonIB: {
    padding: '0px',
    margin: '0px',
    minWidth: theme.spacing(4),
  },
  buttonLabelIB: {
    textTransform: 'capitalize',
  },
  iconMarginIB: {
    margin: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
  buttonTitleIB: {
    paddingRight: theme.spacing(1),
  },
})

export default styles
