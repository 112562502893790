import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ nonComplianceReportId = '', ...params }) => {
  return await dponetAPI.get(
    `/non_compliance_reports/${nonComplianceReportId}`,
    {
      params,
    },
  )
}

const exportPDF = async ({ id }) => {
  return await dponetAPI.get(`/non_compliance_reports/${id}/export`)
}

const logs = async ({ nonComplianceReportId }) => {
  return await dponetAPI.get(
    `/non_compliance_reports/${nonComplianceReportId}/logs`,
  )
}

const nonComplianceReports = {
  get,
  exportPDF,
  logs,
}

export default nonComplianceReports
