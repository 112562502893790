import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import PerfectScrollbar from 'react-perfect-scrollbar'

import { useSelectCountry, useDataProcess, useSnackbar, useFetch } from 'hooks'

import {
  Box,
  Grid,
  makeStyles,
  Typography,
  Button,
  Tooltip,
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'
import { LoadingBox, MenuButton, Permitted } from 'components'

import DialogDataTreatment from '../../DialogDataTreatment'
import DialogRemoveDataTreatment from '../../DialogRemoveDataTreatment'
import { DescriptionPaper } from '../../'

import { PlusCircle } from 'react-feather'

import * as service from 'service'
import styles from '../styles'
import helpers from 'helpers'
import constants from 'constants/index'

const useStyles = makeStyles(styles)

const DataTreatments = ({ description }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const {
    data: dataProcess,
    nextStep,
    toNextStep,
    setToNextStep,
    reloadOptions,
    reloadDataProcess,
  } = useDataProcess()
  const { setSelectedCountries, setManySelectedCountries } = useSelectCountry()

  const [dialogDataTreatment, setDialogDataTreatment] = useState(false)
  const [dialogRemoveDataTreatment, setDialogRemoveDataTreatment] =
    useState(false)
  const [editionDataTreatment, setEditionDataTreatment] = useState(false)
  const [removableDataTreatmentId, setRemovableDataTreatmentId] = useState()
  const [loading, setLoading] = useState(false)

  const { isLoading, refresh, response } = useFetch(
    service.dponet.dataTreatments.get,
    {
      dataProcessId: dataProcess.id,
    },
  )

  useEffect(() => {
    if (toNextStep) {
      if (!isLoading) {
        nextStep()
        setToNextStep(false)
      }
    }
    // eslint-disable-next-line
  }, [nextStep])

  const handleCreate = () => {
    setSelectedCountries([])
    setManySelectedCountries({})
    setEditionDataTreatment(null)
    setDialogDataTreatment(true)
  }

  const handleEdit = (dataTreatment) => {
    setEditionDataTreatment(dataTreatment)
    setSelectedCountries(dataTreatment?.internationalTransfers)
    setDialogDataTreatment(true)
  }

  const handleDelete = async (dataProcessId, dataTreatmentId) => {
    try {
      setLoading(true)
      await service.dponet.dataTreatments.destroy({
        dataProcessId,
        dataTreatmentId,
      })
      setSelectedCountries([])
      setManySelectedCountries({})

      setDialogRemoveDataTreatment(false)
      setLoading(false)

      refresh()
      reloadOptions()
      reloadDataProcess()

      snackbar.open({
        message: 'Compartilhamento de dados removido com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
  }

  const handleOpenRemoveDialog = (dataTreatment) => {
    setRemovableDataTreatmentId(dataTreatment.id)
    setDialogRemoveDataTreatment(true)
  }

  return (
    <>
      {isLoading || loading ? (
        <LoadingBox />
      ) : (
        <>
          <DescriptionPaper description={description}>
            <Permitted tag={constants.permissions.DATA_PROCESSES.CREATE}>
              <Button
                variant="outlined"
                startIcon={<PlusCircle size={16} />}
                onClick={() => handleCreate()}
              >
                Adicionar
              </Button>
            </Permitted>
          </DescriptionPaper>
          <Grid container className={classes.root}>
            <Grid xs={12} item>
              <Box maxHeight="82%">
                <PerfectScrollbar>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width="20%">
                          Com quem é compartilhado
                        </TableCell>
                        <TableCell widht="25%">
                          Porque é compartilhado
                        </TableCell>
                        <TableCell width="25%">Como é compartilhado</TableCell>
                        <TableCell width="20%">
                          Tipo de compartilhamento
                        </TableCell>
                        <TableCell width="10%" align="right">
                          Ações
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {response?.data?.dataTreatments?.map((dataTreatment) => (
                        <TableRow key={dataTreatment.id}>
                          <TableCell>
                            <Tooltip title={dataTreatment?.shareProcess?.name}>
                              <Typography className={classes.ellipsisTable}>
                                {dataTreatment?.shareProcess?.name}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip title={dataTreatment.description}>
                              <Typography className={classes.ellipsisTable}>
                                {dataTreatment.description}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip
                              title={dataTreatment.controllMechanismView}
                            >
                              <Typography className={classes.ellipsisTable}>
                                {dataTreatment.controllMechanismView}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <Tooltip title={dataTreatment?.shareType?.name}>
                              <Typography className={classes.ellipsisTable}>
                                {dataTreatment?.shareType?.name}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell disableTooltip>
                            <Permitted
                              tag={constants.permissions.DATA_PROCESSES.CREATE}
                            >
                              <Box display="flex" justifyContent="flex-end">
                                <MenuButton>
                                  <Button
                                    color="secondary"
                                    fullWidth
                                    onClick={() => handleEdit(dataTreatment)}
                                  >
                                    Editar
                                  </Button>
                                  <Button
                                    color="secondary"
                                    fullWidth
                                    onClick={() =>
                                      handleOpenRemoveDialog(dataTreatment)
                                    }
                                  >
                                    Excluir
                                  </Button>
                                </MenuButton>
                              </Box>
                            </Permitted>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </PerfectScrollbar>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
      <DialogDataTreatment
        dataProcessId={dataProcess.id}
        open={dialogDataTreatment}
        setOpen={setDialogDataTreatment}
        setLoading={setLoading}
        onEvent={refresh}
        dataTreatment={editionDataTreatment}
      />
      <DialogRemoveDataTreatment
        open={dialogRemoveDataTreatment}
        setOpen={setDialogRemoveDataTreatment}
        dataProcessId={dataProcess.id}
        dataTreatmentId={removableDataTreatmentId}
        handleRemove={handleDelete}
      />
    </>
  )
}

DataTreatments.propTypes = {
  description: PropTypes.string.isRequired,
}

export default DataTreatments
