import { makeStyles } from '@material-ui/core'

const styles = (backgroundIconColor) => {
  return makeStyles((theme) => ({
    card: {
      cursor: 'pointer',
      width: 'auto',
      height: '68px',
      display: 'flex',
      padding: theme.spacing(1, 2),
    },
    iconBox: {
      backgroundColor: backgroundIconColor,
      minHeight: theme.spacing(5),
      minWidth: theme.spacing(5),
      maxHeight: theme.spacing(5),
      maxWidth: theme.spacing(5),
      marginRight: theme.spacing(2),
      borderRadius: '50%',
    },
    titleTypography: {
      fontSize: '0.8rem',
      fontWeight: '600',
    },
    valueTypography: {
      fontSize: '1rem',
      fontWeight: '600',
    },
  }))
}

export default styles
