import { makeStyles } from '@material-ui/core'

import palette from 'theme/palette'

const styles = makeStyles(() => ({
  root: {
    borderRadius: '2px',
    fontWeight: 700,
    color: palette.white,
  },
  answered: {
    backgroundColor: palette.greenPantone,
  },
  inactive: {
    backgroundColor: palette.spanishGray,
  },
  pending: {
    backgroundColor: palette.azure,
  },
}))

export default styles
