import auditProcess from './auditProcess'
import auth from './auth'
import churns from './churns'
import companies from './companies'
import companySuppliers from './companySuppliers'
import dataCollected from './dataCollected'
import dataLifeCycle from './dataLifeCycle'
import dataProcesses from './dataProcesses'
import dataTreatment from './dataTreatment'
import dataTreatmentAgent from './dataTreatmentAgent'
import discountCoupon from './discountCoupon'
import documentationLinks from './documentationLinks'
import faqsDocumentations from './faqsDocumentations'
import filterTags from './filterTags'
import formatters from './formatters'
import functions from './functions'
import incidents from './incidents'
import inviteAdvisor from './inviteAdvisor'
import logs from './logs'
import necessityAndProportionality from './necessityAndProportionality'
import nonComplianceReports from './nonComplianceReports'
import paymentOrders from './paymentOrders'
import platformContracts from './platformContracts'
import preRegistrations from './preRegistrations'
import privacyPolicies from './privacyPolicies'
import profiles from './profiles'
import queries from './queries'
import questionnaires from './questionnaires'
import questions from './questions'
import sharedSoftware from './sharedSoftware'
import technicalFaqs from './technicalFaqs'
import users from './users'
import validates from './validates'

const helpers = {
  auditProcess,
  auth,
  churns,
  companies,
  companySuppliers,
  dataCollected,
  dataLifeCycle,
  dataProcesses,
  dataTreatment,
  dataTreatmentAgent,
  discountCoupon,
  documentationLinks,
  faqsDocumentations,
  filterTags,
  formatters,
  functions,
  incidents,
  inviteAdvisor,
  logs,
  necessityAndProportionality,
  nonComplianceReports,
  paymentOrders,
  platformContracts,
  preRegistrations,
  privacyPolicies,
  profiles,
  queries,
  questionnaires,
  questions,
  sharedSoftware,
  technicalFaqs,
  users,
  validates,
}

export default helpers
