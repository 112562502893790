import permissions from '../../permissions'

const FAQS_DOCUMENTATION_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.CREATE,
    tag: permissions.FAQS_DOCUMENTATIONS.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.EDIT,
    tag: permissions.FAQS_DOCUMENTATIONS.EDIT,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.DESTROY,
    tag: permissions.FAQS_DOCUMENTATIONS.DESTROY,
  },
]

export default FAQS_DOCUMENTATION_LIST
