import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Tabs,
  Tab,
} from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'

import { AddInviteAdvisor, ListInviteAdvisor } from '..'
import { LoadingFeedback } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import constants from 'constants/index'
import * as service from 'service'

const DialogInviteAdvisor = ({
  refresh,
  open,
  setOpen,
  invite,
  company,
  isDpoInvite,
  inviteTypeText,
  isEdit,
  setAdvisorData,
}) => {
  const labelTab = invite?.id ? 'Alterar' : 'Adicionar'
  const tabDefault = invite?.id
    ? constants.inviteAdvisor.TAB_DISPLAY
    : constants.inviteAdvisor.TAB_NEW

  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [currentTab, setCurrentTab] = useState(tabDefault)
  const [buttonClick, setButtonClick] = useState(false)

  const snackbar = useSnackbar()

  const handleCancel = () => {
    setOpen(false)
  }

  const tabs = () => {
    if (isEdit) {
      return [
        {
          value: constants.inviteAdvisor.TAB_NEW,
          label: `${labelTab} ${inviteTypeText}`,
        },
        {
          value: constants.inviteAdvisor.TAB_DISPLAY,
          label: 'Visualizar dados',
        },
      ]
    } else {
      return [
        {
          value: constants.inviteAdvisor.TAB_NEW,
          label: `${labelTab} ${inviteTypeText}`,
        },
      ]
    }
  }

  const handleTabsChange = (event, value) => {
    setCurrentTab(value)
  }

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      if (!isEdit) {
        setAdvisorData(data)
      } else {
        if (isDpoInvite) {
          await service.dponet.outsourcedDpo.create({
            data,
            companyId: company.id,
          })
        } else {
          await service.dponet.observerInvite.create({
            data,
            companyId: company.id,
          })
        }
        refresh()
      }
      setOpen(false)
      snackbar.open({
        message: `${inviteTypeText} vinculado à sua empresa!`,
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
    setLoading(false)
  }

  const handleClickSubmitButton = () => {
    setButtonClick(true)
  }

  const handleDestroy = async () => {
    setLoading(true)
    try {
      if (isDpoInvite) {
        await service.dponet.outsourcedDpo.destroy({
          outsourcedId: invite.id,
        })
      } else {
        await service.dponet.observerInvite.destroy({
          observerInviteId: invite.id,
        })
      }

      setOpen(false)
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
    setLoading(false)
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-change"
      maxWidth="md"
      fullWidth
    >
      <LoadingFeedback open={loading} />
      <DialogTitle>
        <Box>
          <Typography variant="h4">{inviteTypeText}</Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Tabs
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
          textColor="primary"
          indicatorColor="primary"
        >
          {tabs().map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              onClick={(e) => handleTabsChange(e, tab.value)}
            />
          ))}
        </Tabs>

        {currentTab === constants.inviteAdvisor.TAB_NEW && (
          <AddInviteAdvisor
            disabled={disabled}
            setDisabled={setDisabled}
            onSubmit={onSubmit}
            invite={invite}
            buttonClick={buttonClick}
            setButtonClick={setButtonClick}
          />
        )}

        {currentTab === constants.inviteAdvisor.TAB_DISPLAY && (
          <ListInviteAdvisor
            setDisabled={setDisabled}
            invite={invite}
            loading={loading}
            inviteTypeText={inviteTypeText}
          />
        )}
      </DialogContent>
      <Box display="flex" justifyContent="flex-end" my={2} mr={2}>
        <Button
          variant="outlined"
          type="button"
          onClick={handleCancel}
          disableElevation
        >
          CANCELAR
        </Button>

        {!disabled && (
          <Box pl={3}>
            <Button
              startIcon={<CheckIcon />}
              variant="contained"
              color="primary"
              disableElevation
              onClick={handleClickSubmitButton}
              disabled={loading}
            >
              Confirmar
            </Button>
          </Box>
        )}

        {disabled && invite && (
          <Box pl={3}>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              disabled={loading}
              onClick={handleDestroy}
            >
              CANCELAR VÍNCULO
            </Button>
          </Box>
        )}
      </Box>
    </Dialog>
  )
}

DialogInviteAdvisor.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  invite: PropTypes.object,
  isDpoInvite: PropTypes.bool,
  inviteTypeText: PropTypes.string,
  refresh: PropTypes.func,
  company: PropTypes.object,
  isEdit: PropTypes.bool,
  setAdvisorData: PropTypes.func,
}

DialogInviteAdvisor.defaultProps = {
  open: false,
  setOpen: () => {},
  invite: {},
  isDpoInvite: false,
  inviteTypeText: '',
  refresh: () => {},
  company: {},
  isEdit: false,
  setAdvisorData: () => {},
}

export default DialogInviteAdvisor
