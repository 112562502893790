import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ ...params }) => {
  return await dponetAPI.get('/documentation_links', { params })
}

const show = async ({ documentationLinkId = '' }) => {
  return await dponetAPI.get(`/documentation_links/${documentationLinkId}`)
}

const create = async ({ ...params }) => {
  return await dponetAPI.post('/documentation_links', params)
}

const update = async ({ documentationLinkId = '', ...params }) => {
  return await dponetAPI.put(
    `/documentation_links/${documentationLinkId}`,
    params,
  )
}

const destroy = async ({ documentationLinkId }) => {
  return await dponetAPI.delete(`/documentation_links/${documentationLinkId}`)
}

const documentationLinks = {
  get,
  create,
  update,
  show,
  destroy,
}

export default documentationLinks
