import * as yup from 'yup'

const schema = () =>
  yup.object().shape({
    billetExpirationDate: yup.date().required().typeError('Confirme uma data'),
    isNfe: yup.boolean(),
    contractIdentifier: yup.number().when('isNfe', {
      is: true,
      then: yup.number().required('Digite o número da nota fiscal'),
    }),
  })

export default schema
