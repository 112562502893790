import { useState } from 'react'
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Tab,
  TablePagination,
  Tabs,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  LoadingFeedback,
  Page,
  Permitted,
} from 'components'

import { Plus as PlusIcon } from 'react-feather'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'
import CouponsTable from './components/CouponsTable'
import constants from 'constants/index'
import * as service from 'service'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

import helpers from 'helpers'

const CouponsMain = () => {
  const history = useHistory()
  const filter = useFilter()

  const [tab, setTab] = useState(constants.plan.ALL_TAB)

  const theme = useTheme()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = useFetch(
    service.dponet.coupons.get,
    {
      ...{ ...filter.filters },
      ...(tab !== constants.coupon.ALL_TAB && {
        status: tab === constants.coupon.ACTIVE_TAB,
      }),
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  const handleChangeTab = (_, value) => {
    setTab(value)
    if (value !== constants.coupon.ALL_TAB) {
      return filter.setFilters({
        ...filter.filters,
        active: value === constants.coupon.ACTIVE_TAB,
      })
    }

    const filters = filter.filters
    delete filters.active
    filter.setFilters({ ...filters })
  }

  return (
    <>
      <Page title="Cupons">
        <LoadingFeedback open={isLoading} />
        <Container maxWidth={false}>
          <ContentHeader
            title="Listagem"
            menu="Cupons"
            subtitle="Exibição dos Cupons"
          >
            <Box ml={1}>
              <FilterButton setDrawerOpen={filter.setDrawerOpen} />
            </Box>
          </ContentHeader>
          <Box
            mt={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
              >
                {constants.coupon.TABS.map((tab) => (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                    onClick={(event) => handleChangeTab(event, tab.value)}
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
          <Card>
            <Box width="100%">
              <Grid container>
                <Grid item xs={6}>
                  <Box
                    width="100%"
                    p={2}
                    height="100%"
                    display="flex"
                    alignItems="center"
                  >
                    <Typography variant="h5">Cupons</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box p={2} display="flex" justifyContent="flex-end">
                    <Permitted tag={constants.permissions.COUPONS.CREATE}>
                      <Button
                        onClick={() => history.push(routes.coupons.new)}
                        variant="outlined"
                        color="primary"
                        startIcon={<PlusIcon />}
                      >
                        Criar
                      </Button>
                    </Permitted>
                  </Box>
                </Grid>
              </Grid>
              <Divider />
            </Box>
            <CouponsTable
              refresh={refresh}
              coupons={response?.data?.discountCoupons ?? []}
            />
            <Box px={2} display="flex" justifyContent="flex-end">
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            </Box>
          </Card>
        </Container>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <input textfieldinput="true" label="Nome do cupom" name="name" />
          <input textfieldinput="true" label="Código" name="code" />
          <select
            textfieldinput="true"
            label="Tipo do desconto"
            name="discountType"
          >
            <option value="" />
            <option value={constants.discountCoupon.VALUE_TYPE}>
              {constants.discountCoupon.VALUE_LABEL}
            </option>
            <option value={constants.discountCoupon.PERCENTAGE_TYPE}>
              {constants.discountCoupon.PERCENTAGE_LABEL}
            </option>
            <option value={constants.discountCoupon.FIXED_PRICE_TYPE}>
              {constants.discountCoupon.FIXED_PRICE_LABEL}
            </option>
          </select>
          <input
            textfieldinput="true"
            label="Porcentagem de mensalidade maior que"
            name="percentageGteq"
            type="number"
            willreturnvalue="true"
            onChange={(e) => helpers.formatters.percentage(e.target.value)}
          />
          <input
            textfieldinput="true"
            label="Porcentagem de mensalidade menor que"
            name="percentageLteq"
            type="number"
            willreturnvalue="true"
            onChange={(e) => helpers.formatters.percentage(e.target.value)}
          />
          <input
            datepickerinput="true"
            label="Data de criação inicial"
            name="createdAtGteq"
            data-target="createdAtLteq"
            data-min
          />
          <input
            datepickerinput="true"
            label="Data de criação final"
            name="createdAtLteq"
            data-target="createdAtGteq"
            data-max
          />
        </Filters>
      </Page>
    </>
  )
}

export default CouponsMain
