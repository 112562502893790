const convertStringToPhone = (phone) => {
  if (phone === null || phone === undefined) return ''

  phone = phone.replace(/\D/g, '')

  let l = phone.length

  if (l <= 2) {
    return phone.replace(/^(\d{1,2}).*/, '($1')
  }

  const isPhoneMobile = phone[2] === '9'

  if (isPhoneMobile) {
    if (l <= 3 && l > 2) {
      return phone.replace(/^(\d{1,2})(\d{1}).*/, '($1) $2')
    }
    if (l <= 7 && l > 3) {
      return phone.replace(/^(\d{1,2})(\d{1})(\d{1,4}).*/, '($1) $2 $3')
    }
    if (l > 7 && l <= 11) {
      return phone.replace(
        /^(\d{1,2})(\d{1})(\d{1,4})(\d{1,4}).*/,
        '($1) $2 $3-$4',
      )
    }
    return phone.replace(
      /^(\d{1,2})(\d{1})(\d{1,4})(\d{1,4}).*/,
      '($1) $2 $3-$4',
    )
  } else {
    if (l <= 3 && l > 2) {
      return phone.replace(/^(\d{1,2})(\d{1,4}).*/, '($1) $2')
    }
    if (l <= 6 && l > 3) {
      return phone.replace(/^(\d{1,2})(\d{1,4}).*/, '($1) $2')
    }
    if (l > 6 && l <= 10) {
      return phone.replace(/^(\d{1,2})(\d{1,4})(\d{1,4}).*/, '($1) $2-$3')
    }
    return phone.replace(/^(\d{1,2})(\d{1,4})(\d{1,4}).*/, '($1) $2-$3')
  }
}

export default convertStringToPhone
