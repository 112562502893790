import axios from 'axios'

import api from 'service/api'
import { getBaseURL } from '../../env'
import * as service from 'service'

const dponetAPI = api.create('dponet')

const baseURL = getBaseURL('dponet')

const get = async ({ preRegistrationToken = '', ...params }) => {
  return await dponetAPI.get(`/pre_registrations/${preRegistrationToken}`, {
    params,
  })
}

const destroy = async ({ preRegistrationToken }) => {
  return await dponetAPI.delete(`/pre_registrations/${preRegistrationToken}`)
}

const logs = async ({ preRegistrationToken }) => {
  return await dponetAPI.get(`/pre_registrations/${preRegistrationToken}/logs`)
}

const histories = async ({ paymentOrderId }) => {
  return await dponetAPI.get(`/history_notification/${paymentOrderId}`)
}

const confirmationPaid = async ({
  files,
  id,
  description = '',
  confirmationDatePaid,
  paymentMethod,
  gateway,
}) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  const querySearch = {
    description,
    confirmation_date_paid: confirmationDatePaid,
    document: files,
    payment_method: paymentMethod,
    gateway,
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/payment_confirmation/${id}`,
    data: querySearch,
    ...config,
  })
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('pre_registration', data)
}

const update = async ({ preRegistrationToken = '', ...params }) => {
  return await dponetAPI.put(`pre_registration/${preRegistrationToken}`, params)
}

const exportConciliationPayments = async ({ ...data }) => {
  return await dponetAPI.post('pre_registrations/export_conciliation', {
    preRegistrations: data,
  })
}

const generateCompany = async (token) => {
  return await dponetAPI.post(`pre_registrations/${token}/company`)
}

const createUserOmie = async (token) => {
  return await dponetAPI.post(`pre_registrations/${token}/create_user_omie`)
}

const convertTrialToReal = async (token) => {
  return await dponetAPI.post(
    `pre_registrations/${token}/convert_trial_to_real`,
  )
}

const preRegistrations = {
  confirmationPaid,
  create,
  destroy,
  exportConciliationPayments,
  get,
  histories,
  logs,
  update,
  generateCompany,
  createUserOmie,
  convertTrialToReal,
}

export default preRegistrations
