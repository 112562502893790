import { colors } from '@material-ui/core'
import { getBaseURL } from 'service/env'

import palette from 'theme/palette'

import * as service from 'service'

import constants from 'constants/index'

import components from '../../components'
import { ratio } from '../../helpers'
import { WIDTH as WIDTHPdf, PADDING, GAP, rectangleSize } from '../../constants'

import privacyPortalLogo from 'images/logo-privacidade-voce-blue.png'
import helpers from 'helpers'

const generalInformations = async (
  pdf,
  date,
  company,
  questionnaireFormatted,
) => {
  const WIDTH = WIDTHPdf(pdf)
  const defaultPadding = 8
  var lineY = 220

  const avatarLetters = (company?.name)
    .split(' ')
    .map((word) => word[0])
    .join('')

  pdf.addPage()
  components.lineWithDate(pdf, date)
  components.subtitle(pdf, 'Visão geral', GAP * 1.5)

  pdf.setDrawColor(colors.grey['A200'])
  pdf.circle(WIDTH / 2, lineY, 20)

  pdf.setFontSize(26)
  pdf.text(
    avatarLetters?.toUpperCase() ?? '-',
    WIDTH / 2,
    lineY + 7,
    null,
    null,
    'center',
  )

  lineY += defaultPadding * 2 + 20

  pdf.setFontSize(16)
  pdf.text(company?.name ?? '-', WIDTH / 2, lineY, null, null, 'center')

  components.rectangle(
    pdf,
    PADDING,
    GAP * 5,
    WIDTH - PADDING * 2,
    palette.BlueDownDark,
    ratio,
    ['Progresso de resolução de questionário (%)'],
    `${company?.companyQuestionnairesProgress?.toFixed(0)}%`,
    undefined,
    80,
    true,
    palette.white,
    palette.white,
    true,
  )

  components.rectangle(
    pdf,
    PADDING,
    GAP * 7,
    rectangleSize.halfWidth + 10,
    palette.BlueDownDark,
    ratio,
    ['CNPJ/CPF'],
    company?.document,
    undefined,
    80,
    true,
    palette.white,
    palette.white,
    true,
  )

  components.rectangle(
    pdf,
    WIDTH / 2 + ratio(70) - 10,
    GAP * 7,
    rectangleSize.halfWidth + 10,
    palette.BlueDownDark,
    ratio,
    ['Conformidade (%)'],
    `${company?.companyCompliance?.toFixed(0)}%`,
    undefined,
    80,
    true,
    palette.white,
    palette.white,
    true,
  )

  if (!helpers.companies.isSupplier(company)) {
    const companyQrCode = await service.dponet.reports.generateQrcode({
      report: constants.reports.KIND.COMPLIANCE,
      companyId: company?.id,
    })
    const qrCode = `${getBaseURL('dponet')}/${companyQrCode?.data}`

    pdf.addImage(qrCode, 'JPEG', PADDING, GAP * 9.2, 60, 60)
    pdf.addImage(privacyPortalLogo, 'JPEG', PADDING + 255, GAP * 9.5, 90, 28)

    components.numberPage(pdf, palette.black)

    pdf.addPage()

    components.lineWithDate(pdf, date)

    questionnaireFormatted.forEach((questionnaireFormatted, index) => {
      const dataFormatted = [
        [
          ['Progresso de resolução de questionário (%)'],
          [questionnaireFormatted?.progress],
        ],
        [
          ['Conformidade de resolução de questionário (%)'],
          [questionnaireFormatted?.compliance],
        ],
      ]

      components.table(
        pdf,
        [[questionnaireFormatted?.name, 'Total (%)']],
        dataFormatted,
        GAP + ratio(400 * index),
      )
    })

    components.lineWithDate(pdf, date)

    components.numberPage(pdf, palette.black)
  }
}

export default generalInformations
