import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import helpers from 'helpers'
import exportCompliance from 'helpers/reports/compliance/exportCompliance'

const ExportQuestionnairePdf = ({ company, ...rest }) => {
  const snackbar = useSnackbar()

  const generateReport = async () => {
    try {
      const response = await service.dponet.questionnaires.get({
        full_relations: true,
        companyId: company?.id,
      })

      const fetchedQuestionnaires = response?.data?.questionnaires || []

      const questionnaires =
        helpers.questionnaires.formatQuestionnairesToReport(
          fetchedQuestionnaires,
        )

      await exportCompliance(company, questionnaires)
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.message || 'Erro ao gerar o Relatório de RMCs',
        variant: 'error',
      })
    }
  }

  return (
    <Button fullWidth size="small" onClick={generateReport} {...rest}>
      Relatório de conformidade
    </Button>
  )
}

ExportQuestionnairePdf.propTypes = {
  questionnaires: PropTypes.array,
  company: PropTypes.object,
}

export default ExportQuestionnairePdf
