import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import PropTypes from 'prop-types'
import { Button, Box } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import {
  Edit as EditIcon,
  Eye as EyeIcon,
  Trash as TrashIcon,
  XCircle as InactiveIcon,
  CheckCircle as CheckIcon,
} from 'react-feather'

import { MenuButton } from 'components'
import {
  InactivateDataProcessDialog,
  ReactivateDataProcessDialog,
  DeleteDataProcessDialog,
} from 'views/DataProcesses/components'
import { LabelIconStart } from 'components'

import { useDataProcessesCache } from 'hooks/caches'
import usePermission from 'hooks/usePermissions'

import constants from 'constants/index'
import { routes } from 'Routes'

const DataProcessCardMenuItems = ({
  dataProcess,
  status,
  isLoading,
  setLoading,
}) => {
  const history = useHistory()
  const dataProcessesCache = useDataProcessesCache()

  const {
    APPROVED_STATUS,
    DISAPPROVED_STATUS,
    INACTIVE_STATUS,
    PENDING_STATUS,
    REVIEWING_STATUS,
  } = constants.dataProcess

  const [openInactivateDialog, setOpenInactivateDialog] = useState(false)
  const [openReactivateDialog, setOpenReactivateDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const { permitted } = usePermission()

  const handleGoEdit = () => {
    history.push(
      reverse(routes.dataProcess.edit, { dataProcessId: dataProcess.id }),
    )
  }

  const handleGoView = () =>
    history.push(
      reverse(routes.dataProcess.view, { dataProcessId: dataProcess.id }),
    )

  const items = [
    {
      icon: <EyeIcon size={20} />,
      label:
        dataProcess?.statusId === REVIEWING_STATUS
          ? 'REVISAR PROCESSO'
          : 'VISUALIZAR',
      action: handleGoView,
      visible: permitted(constants.permissions.DATA_PROCESSES.LIST),
    },
    {
      icon: <EditIcon size={20} />,
      label: 'EDITAR',
      action: handleGoEdit,
      visible:
        permitted(constants.permissions.DATA_PROCESSES.CREATE) &&
        [DISAPPROVED_STATUS, PENDING_STATUS].includes(status) &&
        dataProcess?.company?.kind === constants.companies.KINDS.TEMPLATE,
    },
    {
      icon: <InactiveIcon size={20} />,
      label: 'INATIVAR',
      action: () => setOpenInactivateDialog(true),
      visible:
        permitted(constants.permissions.DATA_PROCESSES.INACTIVE_AND_REACTIVE) &&
        status === APPROVED_STATUS,
    },
    {
      icon: <CheckIcon size={20} />,
      label: 'REATIVAR',
      action: () => setOpenReactivateDialog(true),
      visible:
        permitted(constants.permissions.DATA_PROCESSES.INACTIVE_AND_REACTIVE) &&
        status === INACTIVE_STATUS,
    },
    {
      icon: <TrashIcon size={20} />,
      label: 'EXCLUIR',
      action: () => setOpenDeleteDialog(true),
      visible: permitted(constants.permissions.DATA_PROCESSES.DESTROY),
    },
  ]

  const visibleItems = items.filter((item) => item.visible)

  const eventOnAction = () => {
    if (openDeleteDialog) {
      return dataProcessesCache.useUpdateCache()
    }

    dataProcessesCache.useUpdateCache(dataProcess?.id)
  }

  return (
    <>
      {visibleItems?.length ? (
        <MenuButton>
          {visibleItems.map((item) => (
            <Button
              key={item.label}
              color="secondary"
              fullWidth
              onClick={item.action}
              disabled={isLoading}
            >
              {isLoading ? (
                <Skeleton variant="rect" width="10vw" height={20} />
              ) : (
                <LabelIconStart icon={item.icon} label={item.label} />
              )}
            </Button>
          ))}
        </MenuButton>
      ) : (
        <Box py={2}></Box>
      )}
      {!isLoading && (
        <>
          <InactivateDataProcessDialog
            dataProcessId={dataProcess.id}
            open={openInactivateDialog}
            setOpen={setOpenInactivateDialog}
            action={eventOnAction}
            setLoading={setLoading}
          />
          <ReactivateDataProcessDialog
            dataProcessId={dataProcess.id}
            open={openReactivateDialog}
            setOpen={setOpenReactivateDialog}
            action={eventOnAction}
            setLoading={setLoading}
          />
          <DeleteDataProcessDialog
            dataProcessId={dataProcess.id}
            open={openDeleteDialog}
            setOpen={setOpenDeleteDialog}
            action={eventOnAction}
            setLoading={setLoading}
          />
        </>
      )}
    </>
  )
}

DataProcessCardMenuItems.propTypes = {
  dataProcess: PropTypes.object.isRequired,
  status: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
}

export default DataProcessCardMenuItems
