import { Card, CardHeader, Divider, Box } from '@material-ui/core'

import constants from 'constants/index'

import PropsTypes from 'prop-types'

import {
  NecessaryContent,
  FragilityContent,
  NecessaryAndFragilityContent,
  IncidentContent,
  QuestionContent,
} from './components'

const ActionsPlans = ({ rmc }) => {
  const actionPlansContent = () => {
    switch (rmc.finality) {
      case constants.nonComplianceReports.NECESSARY_FINALITY:
        return <NecessaryContent rmc={rmc} />
      case constants.nonComplianceReports.FRAGILITY_FINALITY:
        return <FragilityContent rmc={rmc} />
      case constants.nonComplianceReports.FRAGILITY_AND_NECESSARY_FINALITY:
        return <NecessaryAndFragilityContent rmc={rmc} />
      default:
        return <></>
    }
  }
  const actionPlansByEntityType = () => {
    switch (rmc.entityType) {
      case constants.nonComplianceReports.ENTITY_DATA_PROCESS:
        return actionPlansContent()
      case constants.nonComplianceReports.ENTITY_QUESTION:
        return <QuestionContent rmc={rmc} />
      case constants.nonComplianceReports.ENTITY_INCIDENT:
        return <IncidentContent rmc={rmc} />
      default:
        return <></>
    }
  }

  const isDataProcessEntity =
    constants.nonComplianceReports.ENTITY_DATA_PROCESS === rmc.entityType

  return (
    <Card>
      <CardHeader title="Planos de ação" />
      <Divider />
      {rmc && (
        <Box m={isDataProcessEntity ? 2 : 0}>{actionPlansByEntityType()}</Box>
      )}
    </Card>
  )
}

ActionsPlans.propTypes = {
  rmc: PropsTypes.object,
}

export default ActionsPlans
