import {
  Box,
  Card,
  Divider,
  Grid,
  TablePagination,
  Typography,
  useMediaQuery,
  Button,
} from '@material-ui/core'
import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  LoadingFeedback,
  Page,
  Permitted,
} from 'components'
import { DocumentationLinksTable } from './components'
import { useTheme } from '@material-ui/styles'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'
import { useHistory } from 'react-router-dom'

import constants from 'constants/index'
import * as service from 'service'
import { routes } from 'Routes'

import { Plus as PlusIcon } from 'react-feather'

const DocumentationLinksMain = () => {
  const filter = useFilter()
  const history = useHistory()
  const theme = useTheme()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = useFetch(
    service.dponet.documentationLinks.get,
    {
      ...{ ...filter.filters },
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  return (
    <>
      <Page title="Links de Ajuda">
        <LoadingFeedback open={isLoading} />
        <Container
          maxWidth={false}
          spacingXl={30}
          spacingLg={25}
          spacingMd={10}
          spacingSm={10}
        >
          <ContentHeader
            title="Listagem"
            menu="Links de Ajuda"
            subtitle="Exibição dos Links de Ajuda"
          >
            <Box ml={1}>
              <FilterButton setDrawerOpen={filter.setDrawerOpen} />
            </Box>
          </ContentHeader>
          <Card>
            <Box width="100%">
              <Grid container>
                <Grid item xs={6}>
                  <Box
                    width="100%"
                    p={2}
                    height="100%"
                    display="flex"
                    alignItems="center"
                  >
                    <Typography variant="h5">Links de Ajuda</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box p={2} display="flex" justifyContent="flex-end">
                    <Permitted
                      tag={constants.permissions.DOCUMENTATION_LINKS.CREATE}
                    >
                      <Button
                        onClick={() =>
                          history.push(routes.documentationLinks.new)
                        }
                        variant="outlined"
                        color="primary"
                        startIcon={<PlusIcon />}
                      >
                        Criar
                      </Button>
                    </Permitted>
                  </Box>
                </Grid>
              </Grid>
              <Divider />
            </Box>
            <DocumentationLinksTable
              refresh={refresh}
              documentationLinks={response?.data?.documentationLinks || []}
            />
            <Box px={2} display="flex" justifyContent="flex-end">
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount || 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            </Box>
          </Card>
        </Container>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <input
            textfieldinput="true"
            label="Página de origem"
            name="page_uri"
          />
          <input textfieldinput="true" label="Redirecionar" name="url" />
          <select textfieldinput="true" label="Status" name="status">
            <option value="" />
            <option value="true">Ativo</option>
            <option value="false">Inativo</option>
          </select>
        </Filters>
      </Page>
    </>
  )
}

export default DocumentationLinksMain
