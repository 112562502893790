import PropTypes from 'prop-types'

import { Permitted } from 'components'

import { Link, Typography, makeStyles } from '@material-ui/core'

import { Alert } from '@material-ui/lab'

// import { useHistory } from 'react-router-dom'

// import { routes } from 'Routes'

// import { reverse } from 'named-urls'

import constants from 'constants/index'

import styles from './styles'

const useStyles = makeStyles(styles)

const FinalizedAlert = ({
  nonComplianceReportId,
  nonComplianceReportFinality,
  finalized,
}) => {
  // const history = useHistory()

  const classes = useStyles()

  const toNonComplianceReport = () => {
    //to implement
    // history.push(
    //   reverse(routes.nonComplianceReport.edit, {
    //     nonComplianceReportId: nonComplianceReportId,
    //   }),
    // )
  }

  return (
    <Permitted tag={constants.permissions.NON_COMPLIANCE_REPORT.EDIT}>
      <Alert variant="filled" severity={finalized ? 'info' : 'warning'}>
        <Typography>
          {!finalized &&
            nonComplianceReportFinality !==
              constants.nonComplianceReports.FRAGILITY_FINALITY &&
            'Os itens indicados como desnecessários/desproporcionais estão aguardando reanálise. '}
          <Link
            href="#"
            className={finalized ? classes.linkInfo : classes.link}
            onClick={toNonComplianceReport}
          >
            Clique aqui
          </Link>
          {nonComplianceReportFinality ===
            constants.nonComplianceReports.FRAGILITY_FINALITY &&
            ' para visualizar o RMC de risco.'}
          {nonComplianceReportFinality ===
            constants.nonComplianceReports.NECESSARY_FINALITY &&
            ' para visualizar o RMC de necessidade e proporcionalidade.'}
          {nonComplianceReportFinality ===
            constants.nonComplianceReports.FRAGILITY_AND_NECESSARY_FINALITY &&
            ' para visualizar o RMC de fragilidade e necessidade e proporcionalidade.'}
        </Typography>
      </Alert>
    </Permitted>
  )
}

FinalizedAlert.propTypes = {
  nonComplianceReportId: PropTypes.number.isRequired,
  nonComplianceReportFinality: PropTypes.string.isRequired,
  finalized: PropTypes.bool,
}

FinalizedAlert.defaultProps = {
  finalized: false,
}

export default FinalizedAlert
