import { useState, useEffect } from 'react'

import { Grid, TextField, Checkbox, Typography, Box } from '@material-ui/core'
import { Card } from 'components'

import { Controller } from 'react-hook-form'

import { CompanyTypeAndSeachInput } from 'components'
import PropTypes from 'prop-types'

const GeneralInformationForm = ({
  errors,
  control,
  clientProfile,
  setValue,
  profile,
  isEdit,
}) => {
  const company = profile?.company
  const defaultProfile = profile?.default
  const profileStatus = profile?.status

  const [createDefaultProfile, setCreateDefaultProfile] = useState(false)

  useEffect(() => {
    if (!company && isEdit) {
      setCreateDefaultProfile(true)
    }
    // eslint-disable-next-line
  }, [])

  const handleCheck = (event) => {
    setCreateDefaultProfile(event.target.checked)
  }

  return (
    <Card title="Informações gerais">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {isEdit &&
          (profile?.originProfileId || !profileStatus || profile?.default) ? (
            <TextField
              label="Nome do Perfil"
              type="text"
              color="primary"
              variant="outlined"
              fullWidth
              defaultValue={profile?.name}
              disabled
            />
          ) : (
            <Controller
              as={
                <TextField
                  label="Nome do Perfil"
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                  fullWidth
                />
              }
              control={control}
              name="name"
              mode="onBlur"
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {isEdit && !profileStatus ? (
            <TextField
              label="Descrição"
              type="text"
              color="primary"
              variant="outlined"
              fullWidth
              defaultValue={profile?.description}
              disabled
            />
          ) : (
            <Controller
              as={
                <TextField
                  label="Descrição"
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.description}
                  helperText={errors?.description?.message}
                  fullWidth
                />
              }
              control={control}
              name="description"
              mode="onBlur"
            />
          )}
        </Grid>
        {isEdit && clientProfile && company ? (
          <Grid item xs={12}>
            <TextField
              label="Empresa"
              color="primary"
              variant="outlined"
              fullWidth
              defaultValue={company?.name}
              disabled
            />
          </Grid>
        ) : (
          <>
            {!isEdit && clientProfile && (
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Grid item xs={1}>
                    <Checkbox
                      checked={createDefaultProfile}
                      onChange={handleCheck}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant="body2">
                      Deseja criar um perfil sugerido?
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
            )}
            {!createDefaultProfile && clientProfile && (
              <Grid item xs={12}>
                <CompanyTypeAndSeachInput control={control} errors={errors} />
              </Grid>
            )}
          </>
        )}
        {defaultProfile && isEdit && (
          <Grid item xs={12}>
            <Box my={1} ml={1}>
              <Typography variant="body2">Perfil sugerido</Typography>
            </Box>
          </Grid>
        )}
      </Grid>
    </Card>
  )
}

GeneralInformationForm.propTypes = {
  errors: PropTypes.object,
  control: PropTypes.object,
  clientProfile: PropTypes.bool,
  setValue: PropTypes.func,
  companies: PropTypes.arrayOf(Object),
  profile: PropTypes.object,
  isEdit: PropTypes.bool,
}

export default GeneralInformationForm
