import PropTypes from 'prop-types'
import { TableRow, TableCell } from 'components/Table'
import { ChangeStatus } from '../../components'
import { MenuButton, Permitted } from 'components'
import { Chip, Button } from '@material-ui/core'
import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from './styles'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { routes } from 'Routes'

const DepartmentTableRow = ({ department, refresh }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <TableRow key={department?.id}>
        <TableCell>{department?.id}</TableCell>
        <TableCell>{department?.name}</TableCell>
        <TableCell>{department?.company?.name}</TableCell>
        <TableCell disableTooltip>
          <Chip
            className={clsx(classes.root, {
              [classes.active]: department.active,
              [classes.inactive]: !department.active,
            })}
            align="center"
            size="small"
            label={department.active ? 'Ativo' : 'Inativo'}
          />
        </TableCell>
        <TableCell align="center">
          {helpers.formatters.date(department?.createdAt)}
        </TableCell>
        <TableCell disableTooltip align="right">
          <Permitted
            someTags={[
              constants.permissions.DEPARTMENT.CREATE,
              constants.permissions.DEPARTMENT.ACTIVATE_OR_INACTIVATE,
            ]}
          >
            <MenuButton>
              <Permitted tag={constants.permissions.DEPARTMENT.CREATE}>
                <Button
                  fullWidth
                  size="small"
                  onClick={() =>
                    history.push(
                      reverse(routes.departments.edit, {
                        departmentId: department?.id,
                      }),
                    )
                  }
                >
                  Editar
                </Button>
              </Permitted>
              <Permitted
                tag={constants.permissions.DEPARTMENT.ACTIVATE_OR_INACTIVATE}
              >
                <ChangeStatus department={department} refresh={refresh} />
              </Permitted>
            </MenuButton>
          </Permitted>
        </TableCell>
      </TableRow>
    </>
  )
}

DepartmentTableRow.propTypes = {
  department: PropTypes.object,
  refresh: PropTypes.func,
}

DepartmentTableRow.defaultProps = {
  refresh: () => {},
}

export default DepartmentTableRow
