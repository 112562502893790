import PropTypes from 'prop-types'

import { Box, Grid, Typography } from '@material-ui/core'
import { Card } from 'components'

import formatters from 'helpers/formatters'

const InfoUserByCoupon = ({ user, createdAt }) => {
  return (
    <Card title="Informações do responsável pelo cupom" paddingCard={5}>
      <Box mb={1}>
        <Grid spacing={2} container>
          <Grid item md={6}>
            <Typography variant="h6" color="textPrimary">
              ID
            </Typography>
            <Typography color="textPrimary" variant="body2">
              {user?.id}
            </Typography>
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography variant="h6" color="textPrimary">
              Data e hora de criação do cupom
            </Typography>

            <Typography color="textPrimary" variant="body2">
              {formatters.date(createdAt) +
                ' - ' +
                formatters.hours(createdAt, false)}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6" color="textPrimary">
              Nome do responsável
            </Typography>
            <Typography color="textPrimary" variant="body2">
              {user?.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" color="textPrimary">
              E-mail do responsável
            </Typography>
            <Typography color="textPrimary" variant="body2">
              {user?.email}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

InfoUserByCoupon.propTypes = {
  user: PropTypes.object,
  createdAt: PropTypes.string,
}

export default InfoUserByCoupon
