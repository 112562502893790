import PropTypes from 'prop-types'
import { Box, Grid } from '@material-ui/core'

import {
  DataProcessCard,
  DataProcessTable,
} from 'views/DataProcesses/components/'

const Main = ({ dataProcesses, isLoading, listingType, setLoading }) => {
  const typeListCards = 'cards'
  const typeListTable = 'table'

  return (
    <Box display="flex" width="100%" mt={-2}>
      <Grid container spacing={3}>
        {listingType === typeListCards && (
          <DataProcessCard
            md={4}
            sm={6}
            xs={12}
            dataProcesses={dataProcesses}
            isLoading={isLoading}
            setLoading={setLoading}
          />
        )}
        {listingType === typeListTable && (
          <DataProcessTable
            dataProcesses={dataProcesses}
            isLoading={isLoading}
          />
        )}
      </Grid>
    </Box>
  )
}

Main.propTypes = {
  dataProcesses: PropTypes.array,
  listingType: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func,
}

Main.defaultProps = {
  setLoading: () => {},
}

export default Main
