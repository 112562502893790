import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import { isEmpty } from 'lodash'
import { Box, Grid } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import { Page, Container, ContentHeader, LoadingFeedback } from 'components'
import { View, DataProcessMenuItems } from './components'

import { useDataProcess } from 'hooks'
import { useDataProcessesCache } from 'hooks/caches'
import { RmcProvider } from 'providers'

import useStyles from './styles'
import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'
const { StatusHeader, Tabs, CardBar } = View

const DataProcessesView = ({ match }) => {
  const classes = useStyles()
  const location = useLocation()
  const dataProcessCache = useDataProcessesCache()
  const {
    data,
    setCompanyId,
    setData,
    setNonComplianceReports,
    setLegalFrameworks,
    reloadEntireDataProcess,
    setLogs,
    setEditLegalFramework,
    clearLoadedDataProcess,
  } = useDataProcess()

  const [actionLoading, setActionLoading] = useState(false)

  const search = qs.parse(location.search)
  const { dataProcessId } = match.params

  const [dataProcessResponse, legalFrameworkResponse, logsResponse] =
    dataProcessCache.useOptions(dataProcessId)

  const dataProcess = dataProcessResponse.data?.dataProcess
  const legalFrameworks = legalFrameworkResponse.data?.legalFrameworks
  const logs = logsResponse.data?.dponetAudits

  const loading =
    dataProcessResponse.isLoading ||
    legalFrameworkResponse.isLoading ||
    logsResponse.isLoading

  const refechLoading = dataProcessResponse.isFetching

  useEffect(() => {
    if (!dataProcessResponse.isFetching && dataProcessResponse.isFetched) {
      setCompanyId(dataProcess.company.id)
      setData(dataProcess)

      if (dataProcess.nonComplianceReport) {
        service.dponet.nonComplianceReports
          .get({
            entityType: constants.nonComplianceReports.ENTITY_DATA_PROCESS,
            entityId: dataProcess.id,
          })
          .then((responseNonComplianceReports) => {
            setNonComplianceReports(
              responseNonComplianceReports.data.nonComplianceReports,
            )
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProcessResponse.isFetching])

  useEffect(() => {
    if (
      !legalFrameworkResponse.isFetching &&
      legalFrameworkResponse.isFetched
    ) {
      setLegalFrameworks(legalFrameworks)
      setEditLegalFramework(!isEmpty(legalFrameworks))
    }
    // eslint-disable-next-line
  }, [legalFrameworkResponse.isFetching])

  useEffect(() => {
    if (!logsResponse.isFetching && logsResponse.isFetched) {
      setLogs(logs)
    }
    // eslint-disable-next-line
  }, [logsResponse.isFetching])

  useEffect(() => {
    return () => {
      clearLoadedDataProcess()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Page className={classes.root} title="Visualizar processo">
      {loading || isEmpty(data) ? (
        <LoadingFeedback open={loading} />
      ) : (
        <Box>
          <LoadingFeedback open={actionLoading || refechLoading} />
          <Box className={classes.headerWhite}>
            <Container
              maxWidth={false}
              spacingXl={20}
              spacingLg={15}
              spacingMd={10}
              spacingSm={5}
              spacingXs={5}
              className={classes.container}
            >
              <ContentHeader
                menu="Processos"
                title="Visualizar"
                subtitle="Processamento de dados"
              >
                <DataProcessMenuItems
                  dataProcess={dataProcess}
                  origin={search.origin}
                  setLoading={setActionLoading}
                  onEvent={reloadEntireDataProcess}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                />
              </ContentHeader>
              {helpers.dataProcesses.statusesCheck.disapproved(dataProcess) &&
                dataProcess.reproveJustification && (
                  <Box mb={4}>
                    <Grid item xs={12}>
                      <Alert variant="filled" severity="warning">
                        <AlertTitle>Último motivo de reprova</AlertTitle>
                        {dataProcess.reproveJustification}
                      </Alert>
                    </Grid>
                  </Box>
                )}
              <StatusHeader
                name={dataProcess?.name}
                id={dataProcess?.id}
                companyName={dataProcess?.company?.name}
                statusId={dataProcess?.statusId}
              />
              <Box mt={4} pb={3}>
                <CardBar
                  departmentName={dataProcess?.department?.name}
                  statusId={dataProcess?.statusId}
                  fragility={dataProcess?.fragility}
                  suggestionFragilityId={dataProcess?.suggestionFragilityId}
                />
              </Box>
            </Container>
          </Box>
          <Box>
            <RmcProvider>
              <Tabs loading={loading} setLoading={setActionLoading} />
            </RmcProvider>
          </Box>
        </Box>
      )}
    </Page>
  )
}
export default DataProcessesView
