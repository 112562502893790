import PropTypes from 'prop-types'
import { CircularProgress, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import useStyles from '../styles'

const AutocompleteSelect = ({
  optionvalues,
  name,
  inputProps,
  onChangeTypeSearch,
  onChange,
  label,
  loading,
  register,
  values,
  setValues,
  noOptionsText,
  compareByName = false,
}) => {
  const classes = useStyles()

  const getSelectedOption = (fieldName, options) => {
    return (
      options?.find((option) =>
        compareByName
          ? option.name === values[fieldName]
          : parseInt(option.id) === parseInt(values[fieldName]),
      ) || null
    )
  }

  const handleChange = (_, newInputValue) => {
    if (onChange) onChange(newInputValue?.id)
    setValues({
      ...values,
      [name]: newInputValue?.id,
    })
    return
  }

  return (
    <Autocomplete
      options={optionvalues}
      className={classes.filterSelectField}
      value={getSelectedOption(name, optionvalues)}
      ChipProps={{
        size: 'small',
      }}
      onChange={handleChange}
      inputprops={{
        ref: register,
        ...inputProps,
      }}
      getOptionLabel={(option) => option.name}
      autoSelect
      loading={loading || false}
      noOptionsText={noOptionsText}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={onChangeTypeSearch}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

AutocompleteSelect.propTypes = {
  noOptionsText: PropTypes.string,
}

AutocompleteSelect.defaultProps = {
  noOptionsText: 'Sem opções',
  optionvalues: [],
}

export default AutocompleteSelect
