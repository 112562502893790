import { lightGreen, lightBlue, red } from '@material-ui/core/colors'

const styles = (theme) => ({
  destroyTableCell: {
    backgroundColor: red[50],
  },
  justifyHeight: {
    height: '100%',
  },
  createTableCell: {
    backgroundColor: lightGreen[50],
  },
  updateTableCell: {
    backgroundColor: lightBlue[50],
  },
  noCellPadding: {
    padding: 0,
  },
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  collapseBackground: {
    backgroundColor: theme.palette.backgroundDefault,
  },
  rowWraper: {
    maxWidth: '30vw',
    whiteSpace: 'pre-line',
  },
})

export default styles
