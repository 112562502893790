import { makeStyles } from '@material-ui/core'
import palette from 'theme/palette'

const styles = makeStyles((theme) => ({
  borderBottom: {
    borderBottom: `1px solid ${palette.secondary.superLight}`,
  },
}))

export default styles
