import * as yup from 'yup'
import constants from 'constants/index'

export const schemaGeneralInformation = (volumetryType) => {
  const volumetryRequire =
    volumetryType === constants.dataProcess.STORY_MODE_DEFINED_TYPE

  return yup.object().shape({
    departmentId: yup.string().required(),
    code: yup.string(),
    name: yup.string().required(),
    finality: yup.string().required(),
    ...(volumetryRequire && {
      deadlineJustification: yup.string().required(),
      value: yup.number().typeError('Precisa ser um número').required(),
      volumetry: yup.string().required(),
    }),
    dataProcessDataSources: yup.array().nullable(),
    sourceDescription: yup
      .number()
      .nullable()
      .transform((newValue) => {
        if (isNaN(parseInt(newValue))) {
          return null
        }

        return newValue
      }),
    sourceDescriptionAnother: yup.string().nullable(),
  })
}

export default schemaGeneralInformation
