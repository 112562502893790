import constants from 'constants/index'

const legalFrameworkLabelByDataType = (dataType) => {
  switch (dataType) {
    case constants.legalFramework.TYPE_PERSONAL_AND_FINANCIAL:
      return constants.legalFramework.TYPE_PERSONAL_AND_FINANCIAL_LABEL

    case constants.legalFramework.TYPE_MINOR:
      return constants.legalFramework.TYPE_MINOR_LABEL

    case constants.legalFramework.TYPE_SENSITIVE:
      return constants.legalFramework.TYPE_SENSITIVE_LABEL

    default:
      return ''
  }
}

export default legalFrameworkLabelByDataType
