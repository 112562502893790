import { useEffect } from 'react'

import PropTypes from 'prop-types'

import { TextField, Box, makeStyles } from '@material-ui/core'

import { Autocomplete } from '@material-ui/lab'

import { useSelectCountry } from 'hooks'

import styles from './styles'

import { Label } from 'components'

import { TableCountries } from './components'

const useStyles = makeStyles(styles)

const SelectCountry = ({
  withTable,
  label,
  handleRemoveCountry,
  manyFields,
  fieldName,
  disabled,
}) => {
  const {
    countries,
    loading,
    loadCountries,
    selectedCountries,
    setSelectedCountries,
    addCountry,
    manySelectedCountries,
    addCountryToManyFields,
  } = useSelectCountry()

  const classes = useStyles()

  useEffect(() => {
    setSelectedCountries(selectedCountries || [])

    if (countries.length <= 0) {
      loadCountries()
    }
    // eslint-disable-next-line
  }, [])

  if (manyFields) {
    return (
      <>
        {!loading && (
          <Box>
            {!disabled && (
              <Label description={label} item>
                <Autocomplete
                  options={countries || []}
                  getOptionLabel={(countryOption) => countryOption.name || ''}
                  getOptionSelected={(countryOption, value) =>
                    !value || countryOption.id === value.id || value.id === 0
                  }
                  onChange={(event, newInputValue) => {
                    addCountryToManyFields(newInputValue, fieldName)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.internationalTransferSelect}
                      variant="outlined"
                    />
                  )}
                />
              </Label>
            )}
            {withTable && (
              <Box mt={2}>
                <TableCountries
                  manyFields
                  disabled={disabled}
                  fieldName={fieldName}
                  countries={
                    manySelectedCountries
                      ? manySelectedCountries[fieldName]
                      : []
                  }
                  handleRemoveCountry={handleRemoveCountry}
                />
              </Box>
            )}
          </Box>
        )}
      </>
    )
  }

  return (
    <>
      {!loading && (
        <Box>
          {!disabled && (
            <Label description={label} item>
              <Autocomplete
                options={countries || []}
                getOptionLabel={(countryOption) => countryOption.name || ''}
                getOptionSelected={(countryOption, value) =>
                  !value || countryOption.id === value.id || value.id === 0
                }
                onChange={(event, newInputValue) => {
                  addCountry(newInputValue)
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled={disabled}
                    className={classes.internationalTransferSelect}
                    variant="outlined"
                  />
                )}
              />
            </Label>
          )}
          {withTable && (
            <Box mt={2}>
              <TableCountries
                disabled={disabled}
                countries={selectedCountries}
                handleRemoveCountry={handleRemoveCountry}
              />
            </Box>
          )}
        </Box>
      )}
    </>
  )
}

SelectCountry.propTypes = {
  withTable: PropTypes.bool,
  label: PropTypes.string,
  handleRemoveCountry: PropTypes.func,
  manyFields: PropTypes.bool,
  fieldName: PropTypes.string,
  disabled: PropTypes.bool,
}

SelectCountry.defaultProps = {
  withTable: false,
  label: '',
  handleRemoveCountry: () => {},
  manyFields: false,
  disabled: false,
}

export default SelectCountry
