import { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core'
import { Card } from 'components'

import { useForm, Controller } from 'react-hook-form'

import { LoadingBox, BackButton } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import useStyles from './styles'

import * as service from 'service'

import helpers from 'helpers'
import schema from './schema'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

const EconomicActivityForm = ({ isEdit, activity }) => {
  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()

  const checkedEdit = isEdit ? activity?.active : true
  const [checked, setChecked] = useState(checkedEdit)
  const labelChecked = checked ? 'Ativo' : 'Inativo'

  const { control, handleSubmit, errors, register } = useForm({
    validationSchema: schema(),
    defaultValues: {
      name: activity?.name || '',
      active: activity?.active || checkedEdit,
      classCnae: activity?.classCnae || '',
      division: activity?.division || '',
      group: activity?.group || '',
      session: activity?.session || '',
    },
  })

  const onSubmit = async (economicActivity) => {
    setLoading(true)
    try {
      if (isEdit) {
        await service.dponet.economicActivities.edit({
          activityId: activity?.id,
          economicActivity,
        })
      } else {
        await service.dponet.economicActivities.create(economicActivity)
      }

      snackbar.open({
        message: `Atividade econômica ${
          isEdit ? 'atualizada' : 'criada'
        } com sucesso!`,
        variant: 'success',
      })
      setLoading(false)
      history.push(routes.economicActivities.all)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) ||
          `Erro ao ${isEdit ? 'atualizar' : 'criar'} a atividade econômica!`,
        variant: 'error',
      })
      setLoading(false)
    }
  }

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card
            title={`${isEdit ? 'Edição da' : 'Nova'} atividade`}
            paddingCard={5}
          >
            <Grid container className={classes.gapGrid}>
              <Grid item xs={12}>
                <Typography variant="body1">Status</Typography>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    className={classes.labelSwitch}
                    control={
                      <Switch
                        checked={checked}
                        onChange={(event) => {
                          setChecked(event.target.checked)
                        }}
                      />
                    }
                    inputRef={register}
                    name="active"
                  />

                  <Typography variant="body1">{labelChecked}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      label="Nome da atividade"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.name}
                      helperText={errors?.name?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="name"
                  mode="onBlur"
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  as={
                    <TextField
                      label="Identificador CNAE"
                      type="text"
                      color="primary"
                      variant="outlined"
                      error={!!errors.classCnae}
                      helperText={errors?.classCnae?.message}
                      fullWidth
                    />
                  }
                  control={control}
                  name="classCnae"
                  mode="onBlur"
                />
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Controller
                    as={
                      <TextField
                        label="Sessão"
                        type="text"
                        color="primary"
                        variant="outlined"
                        error={!!errors.session}
                        helperText={errors?.session?.message}
                        fullWidth
                      />
                    }
                    control={control}
                    name="session"
                    mode="onBlur"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    as={
                      <TextField
                        label="Divisão"
                        type="text"
                        color="primary"
                        variant="outlined"
                        error={!!errors.division}
                        helperText={errors?.division?.message}
                        fullWidth
                      />
                    }
                    control={control}
                    name="division"
                    mode="onBlur"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    as={
                      <TextField
                        label="Grupo"
                        type="text"
                        color="primary"
                        variant="outlined"
                        error={!!errors.group}
                        helperText={errors?.group?.message}
                        fullWidth
                      />
                    }
                    control={control}
                    name="group"
                    mode="onBlur"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Card>
          <Box display="flex" justifyContent="flex-start">
            <Box pr={2.5}>
              <BackButton
                onClick={() => history.push(routes.economicActivities.all)}
              >
                Voltar
              </BackButton>
            </Box>
            <Box>
              <Button color="primary" variant="contained" type="submit">
                Salvar
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </>
  )
}

EconomicActivityForm.propTypes = {
  activity: PropTypes.object,
  isEdit: PropTypes.bool,
}

EconomicActivityForm.defaultProps = {
  activity: null,
  isEdit: false,
}

export default EconomicActivityForm
