import { useState, useEffect, useMemo } from 'react'

import {
  Card,
  CardHeader,
  Divider,
  Box,
  Grid,
  TextField,
} from '@material-ui/core'

import { Controller, useForm } from 'react-hook-form'

import { Label, TransferList } from 'components'

import { useDataProcess, useTransferList } from 'hooks'

import useStyles from '../../styles'

import schema from './schema'

import helpers from 'helpers'

import constants from 'constants/index'

const LegalFramework = () => {
  const {
    right: rightLegalFrameworkList,
    left: leftLegalFrameworkList,
    reload,
  } = useTransferList()

  const {
    submitting,
    submitUpdate,
    setSubmitUpdate,
    legalFrameworks,
    data,
    setData,
  } = useDataProcess()

  const setLeftInitialItens = () => {
    const leftLegalFrameWork = legalFrameworks.filter(
      (legalFramework) =>
        !data?.legalFrameworks
          ?.map((legalFrameworkPayload) => legalFrameworkPayload.id)
          .includes(legalFramework.id),
    )
    return helpers.dataProcesses.orderLegalFrameworks(leftLegalFrameWork)
    // eslint-disable-next-line
  }

  const setRightInitialItens = () => {
    const rightLegalFrameWork = legalFrameworks.filter((legalFramework) =>
      data?.legalFrameworks
        ?.map((legalFrameworkPayload) => legalFrameworkPayload.id)
        .includes(legalFramework.id),
    )
    return helpers.dataProcesses.orderLegalFrameworks(rightLegalFrameWork)
    // eslint-disable-next-line
  }

  const [leftItens, setLeftItens] = useState(setLeftInitialItens)
  const [rightItens, setRightItens] = useState(setRightInitialItens)

  const { control, errors, triggerValidation, setValue } = useForm({
    reValidateMode: 'onBlur',
    validationSchema: schema,
    defaultValues: {
      recomendations: data?.recomendations ?? '',
      legalBase: data?.legalBase ?? '',
      lia: data?.lia ?? '',
    },
  })

  const verifyLegitimateInterestPresence = useMemo(() => {
    try {
      const legitimateInterestPresent =
        rightLegalFrameworkList[
          constants.legalFramework.TYPE_PERSONAL_AND_FINANCIAL_VALUE
        ].items.filter((legalFramework) => {
          return (
            legalFramework.name ===
            constants.dataProcess.LEGITIMATE_INTEREST_NAME
          )
        }).length > 0
      return legitimateInterestPresent
    } catch {
      return false
    }
  }, [rightLegalFrameworkList])

  const classes = useStyles()

  useEffect(() => {
    setLeftItens(
      leftLegalFrameworkList.length > 0
        ? helpers.dataProcesses.orderLegalFrameworks(leftLegalFrameworkList)
        : setLeftInitialItens,
    )
    setRightItens(
      rightLegalFrameworkList.length > 0
        ? helpers.dataProcesses.orderLegalFrameworks(rightLegalFrameworkList)
        : setRightInitialItens,
    )
    // eslint-disable-next-line
  }, [reload, rightLegalFrameworkList, leftLegalFrameworkList])

  const handleChangeValue = ([event]) => {
    data[event.target.name] = event.target.value
    setData(data)
    return event.target.value
  }

  const handleChangeLia = (value) => {
    return setValue('lia', value)
  }

  useEffect(() => {
    if (submitting >= 1) {
      triggerValidation()
    }

    triggerValidation().then((valid) => {
      setSubmitUpdate(valid)
    })

    // eslint-disable-next-line
  }, [submitting, submitUpdate])

  useEffect(() => {
    if (verifyLegitimateInterestPresence) {
      return handleChangeLia(constants.dataProcess.LIA_LEGITIMATE_INTEREST_TEXT)
    }
    if (data?.lia && verifyLegitimateInterestPresence)
      return handleChangeLia(data?.lia)
    if (!verifyLegitimateInterestPresence) {
      return handleChangeLia('')
    }
    // eslint-disable-next-line
  }, [data, rightLegalFrameworkList, leftLegalFrameworkList])

  return (
    <Card>
      <CardHeader title="Detalhes do enquadramento Legal" />
      <Divider />
      <Box className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TransferList
              formatFunction={helpers.dataProcesses.orderLegalFrameworks}
              leftItens={leftItens}
              rightItens={rightItens}
              withLabel
            />
          </Grid>
          <Grid item xs={12}>
            <Label title="Base Legal">
              <Controller
                as={
                  <TextField
                    color="primary"
                    variant="outlined"
                    error={!!errors.legalBase}
                    helperText={errors?.legalBase?.message}
                    fullWidth
                    multiline
                    minRows={2}
                    maxRows={8}
                  />
                }
                onBlur={handleChangeValue}
                control={control}
                name="legalBase"
                mode="onBlur"
              />
            </Label>
          </Grid>
          <Grid item xs={12} md={6}>
            <Label title="Recomendações">
              <Controller
                as={
                  <TextField
                    color="primary"
                    variant="outlined"
                    error={!!errors.recomendations}
                    helperText={errors?.recomendations?.message}
                    fullWidth
                    multiline
                    minRows={2}
                    maxRows={8}
                  />
                }
                onBlur={handleChangeValue}
                control={control}
                name="recomendations"
                mode="onBlur"
              />
            </Label>
          </Grid>
          <Grid item xs={12} md={6}>
            <Label title="Legítimo interesse (LIA)">
              <Controller
                as={
                  <TextField
                    color="primary"
                    variant="outlined"
                    error={!!errors.lia}
                    helperText={errors?.lia?.message}
                    fullWidth
                    multiline
                    minRows={2}
                    maxRows={8}
                  />
                }
                onBlur={handleChangeValue}
                control={control}
                name="lia"
                mode="onBlur"
              />
            </Label>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

export default LegalFramework
