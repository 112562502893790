import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

import { Grid, TextField } from '@material-ui/core'
import { Card } from 'components'

import helpers from 'helpers'

const AddressForm = ({ control, errors, editionPermitted, ...props }) => {
  return (
    <Card title="Preencha o endereço da empresa">
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.street}
                helperText={errors?.street?.message}
                label="Rua/Logradouro"
                disabled={!editionPermitted}
              />
            }
            control={control}
            name="street"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.number}
                helperText={errors?.number?.message}
                label="Número"
                disabled={!editionPermitted}
                inputProps={{ maxLength: 10 }}
              />
            }
            control={control}
            name="number"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.neighborhood}
                helperText={errors?.neighborhood?.message}
                label="Bairro"
                disabled={!editionPermitted}
              />
            }
            control={control}
            name="neighborhood"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.complement}
                helperText={errors?.complement?.message}
                label="Complemento"
                disabled={!editionPermitted}
              />
            }
            control={control}
            name="complement"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.postalCode}
                helperText={errors?.postalCode?.message}
                label="Código Postal(CEP)"
                disabled={!editionPermitted}
              />
            }
            onChange={([text]) => helpers.formatters.cep(text.target.value)}
            control={control}
            name="postalCode"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                inputProps={{
                  maxLength: 2,
                }}
                error={!!errors.state}
                helperText={errors?.state?.message}
                label="Estado, ex: SP"
                disabled={!editionPermitted}
              />
            }
            control={control}
            name="state"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.country}
                helperText={errors?.country?.message}
                label="País"
                disabled={!editionPermitted}
              />
            }
            control={control}
            name="country"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={
              <TextField
                fullWidth
                error={!!errors.city}
                helperText={errors?.city?.message}
                label="Cidade"
                disabled={!editionPermitted}
              />
            }
            control={control}
            name="city"
            mode="onBlur"
          />
        </Grid>
      </Grid>
    </Card>
  )
}

AddressForm.prototype = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  editionPermitted: PropTypes.bool,
}

export default AddressForm
