/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import { Filters } from 'components'

import helpers from 'helpers'
import * as service from 'service'

const PlanFilter = ({ filter }) => {
  const [currencyValues, setCurrencyValues] = useState({
    priceGteq: helpers.formatters.currencyInput(
      filter?.filters?.priceGteq || '',
    ),
    priceLteq: helpers.formatters.currencyInput(
      filter?.filters?.priceLteq || '',
    ),
    implementationPrice: helpers.formatters.currencyInput(
      filter?.filters?.implementationPrice || '',
    ),
  })
  const [companies, setCompanies] = useState([])
  const [companyInputValue, setCompanyInputValue] = useState('')
  const [isLoadingCompaniesInput, setIsLoadingCompaniesInput] = useState(false)
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false)
  const [segments, setSegments] = useState([])
  const [segmentInputValue, setSegmentInputValue] = useState('')
  const [isLoadingSegmentsInput, setIsLoadingSegmentsInput] = useState(false)
  const [isLoadingSegments, setIsLoadingSegments] = useState(false)

  const { loadCompanies, instructionsText } = helpers.companies.typeAndSearch
  const { nameWithDocumentByArrayObject } = helpers.companies.nameWithDocument
  const { filters, drawerOpen } = filter

  const handleTypeCompaniesInput = (e) => {
    setCompanyInputValue(e.target.value)
  }

  const handleTypeSegmentsInput = (e) => {
    setSegmentInputValue(e.target.value)
  }

  const handleChangeCurrency = (e) => {
    const convertedValue = helpers.formatters.currencyInput(e.target.value)
    setCurrencyValues({ ...currencyValues, [e.target.name]: convertedValue })
    return convertedValue
  }

  const mountCompanyParams = () => {
    const defaultParams = {
      companyKind: 'T',
      minimal: true,
      perPage: 1000 * 1000,
    }

    if (!!companyInputValue) {
      return {
        name: companyInputValue,
        ...defaultParams,
      }
    }

    return {
      id: filters?.templateCompanyId,
      ...defaultParams,
    }
  }

  const mountSegmentsParams = () => {
    const defaultParams = {
      active: true,
      perPage: 1000 * 1000,
    }

    if (!!segmentInputValue) {
      return {
        name: segmentInputValue,
        ...defaultParams,
      }
    }

    return {
      id: filters?.segmentId,
      ...defaultParams,
    }
  }

  const companiesOptionsValues = nameWithDocumentByArrayObject(
    isLoadingCompaniesInput ? [] : companies,
  )

  const segmentsOptionsValues = isLoadingSegmentsInput ? [] : segments

  const loadSegments = async (setIsLoading) => {
    setIsLoading(true)

    const response = await service.dponet.segments.get(mountSegmentsParams())
    const responseSegments = response.data?.segments || []

    setSegments(responseSegments)

    setIsLoading(false)

    return responseSegments
  }

  useEffect(() => {
    const getCompanies = setTimeout(() => {
      const hasCompanyValueToFetch =
        companyInputValue.length < 3 && isEmpty(filters?.templateCompanyId)

      if (hasCompanyValueToFetch || !drawerOpen) return

      loadCompanies(
        mountCompanyParams(),
        setIsLoadingCompaniesInput,
        setCompanies,
      )
    }, 1000)

    return () => clearTimeout(getCompanies)
  }, [companyInputValue])

  useEffect(() => {
    if (filters?.templateCompanyId) {
      loadCompanies(mountCompanyParams(), setIsLoadingCompanies, setCompanies)
    }
  }, [filters?.templateCompanyId])

  useEffect(() => {
    const getSegments = setTimeout(() => {
      const hasSegmentsToFetch =
        segmentInputValue.length < 3 && isEmpty(filters?.segmentId)

      if (hasSegmentsToFetch || !drawerOpen) return

      loadSegments(setIsLoadingSegmentsInput)
    }, 1000)

    return () => clearTimeout(getSegments)
  }, [segmentInputValue])

  useEffect(() => {
    if (filters?.segmentId) loadSegments(setIsLoadingSegments)
  }, [filters?.segmentId])

  useEffect(() => {
    if (!drawerOpen && companyInputValue) setCompanyInputValue('')
  }, [drawerOpen])

  return (
    <Filters
      filter={filter}
      isLoading={isLoadingCompanies || isLoadingSegments}
    >
      <input textfieldinput="true" label="Identificador" name="id" />
      <select
        autocompleteselectinput="true"
        label="Segmento"
        name="segmentId"
        loading={isLoadingSegmentsInput}
        onChangeTypeSearch={handleTypeSegmentsInput}
        optionvalues={segmentsOptionsValues}
        noOptionsText={instructionsText(segmentInputValue)}
      />
      <input textfieldinput="true" label="Nome do plano" name="name" />
      <input
        textfieldinput="true"
        label="Mensalidade maior que"
        name="priceGteq"
        willreturnvalue="true"
        value={currencyValues.priceGteq}
        onChange={handleChangeCurrency}
      />
      <input
        textfieldinput="true"
        label="Mensalidade menor que"
        name="priceLteq"
        willreturnvalue="true"
        value={currencyValues.priceLteq}
        onChange={handleChangeCurrency}
      />
      <input
        textfieldinput="true"
        label="Implementaçao igual a"
        name="implementationPrice"
        willreturnvalue="true"
        value={currencyValues.implementationPrice}
        onChange={handleChangeCurrency}
      />
      <select
        autocompleteselectinput="true"
        label="Empresa template"
        name="templateCompanyId"
        loading={isLoadingCompaniesInput}
        onChangeTypeSearch={handleTypeCompaniesInput}
        optionvalues={companiesOptionsValues}
        noOptionsText={instructionsText(companyInputValue)}
      />
    </Filters>
  )
}

export default PlanFilter
