import { makeStyles, colors } from '@material-ui/core'

export default makeStyles(() => ({
  labelDisabled: {
    color: colors.grey[400],
  },
  checkbox: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  checkboxDisabled: {
    color: colors.grey[400],
  },
}))
