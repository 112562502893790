import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box } from '@material-ui/core'

import GroupsTableRow from '../GroupsTableRow'
import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'

const GroupsTable = ({ groups, refresh }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhum grupo encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="10%">ID</TableCell>
              <TableCell width="35%" align="left">
                Nome
              </TableCell>
              <TableCell width="20%" align="left">
                Status
              </TableCell>
              <TableCell width="25%" align="center">
                Inativo em
              </TableCell>
              <TableCell width="10%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.map((group) => (
              <GroupsTableRow refresh={refresh} group={group} key={group.id} />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

GroupsTable.propTypes = {
  groups: PropTypes.arrayOf(Object),
  refresh: PropTypes.func,
}

GroupsTable.defaultProps = {
  refresh: () => {},
}

export default GroupsTable
