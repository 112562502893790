import { colors } from '@material-ui/core'

const styles = (theme) => ({
  link: {
    color: colors.blue[500],
    fontWeight: 700,
  },
  mitigationMeasuresChip: {
    backgroundColor: colors.green[500],
    color: theme.palette.white,
    fontWeight: 700,
  },
  riskTakenChip: {
    backgroundColor: colors.red[500],
    color: theme.palette.white,
    fontWeight: 700,
  },
})

export default styles
