import PropTypes from 'prop-types'

import { ConfirmationDialog } from 'components'

const DialogRemoveDataTreatment = ({
  dataProcessId,
  dataTreatmentId,
  open,
  setOpen,
  handleRemove,
}) => {
  return (
    <ConfirmationDialog
      open={open}
      setOpen={setOpen}
      message="Tem certeza que deseja remover este compartilhamento de dados?"
      cancelButtonText="Cancelar"
      buttonText="Continuar"
      actionAcceptButton={() => handleRemove(dataProcessId, dataTreatmentId)}
    />
  )
}

DialogRemoveDataTreatment.propTypes = {
  dataProcessId: PropTypes.number,
  dataTreatmentId: PropTypes.number,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
}

DialogRemoveDataTreatment.defaultProps = {
  setOpen: () => {},
  handleRemove: () => {},
}

export default DialogRemoveDataTreatment
