import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Button } from '@material-ui/core'

import { MenuButton } from 'components'
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'
import { QuestionStatusChip } from './components'

import { usePermissions } from 'hooks'
import constants from 'constants/index'

const QuestionsTable = ({
  isEdit,
  questions,
  handleOpenQuestionDialog,
  handleOpenInactivateQuestionDialog,
}) => {
  const permissions = usePermissions()

  const hasPermissionToEditQuestionnaire = permissions.permitted(
    constants.permissions.QUESTIONNAIRE_BY_COMPANY.EDIT,
  )

  const showInactiveOption = (status) => {
    return (
      hasPermissionToEditQuestionnaire &&
      status !== constants.question.INACTIVE_STATUS
    )
  }

  return (
    <PerfectScrollbar options={{ suppressScrollY: true }}>
      <Table emptyMessage="Nenhum questionário encontrado" noWrap>
        <TableHead>
          <TableRow>
            <TableCell width="5%">ID</TableCell>
            <TableCell width="30%">Tema</TableCell>
            <TableCell width="45%">Perguntas</TableCell>
            <TableCell width="15%">Status</TableCell>
            <TableCell width="5%" align="right">
              Ações
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {questions.map((question) => (
            <TableRow key={question.id}>
              <TableCell>{question.id}</TableCell>
              <TableCell noOverFlow>{question.theme}</TableCell>
              <TableCell noOverFlow>{question.question}</TableCell>
              <TableCell disableTooltip>
                <QuestionStatusChip status={question.status} />
              </TableCell>
              <TableCell disableTooltip align="right">
                <MenuButton>
                  {isEdit ? (
                    <>
                      {hasPermissionToEditQuestionnaire && (
                        <Button
                          color="secondary"
                          fullWidth
                          size="small"
                          onClick={() =>
                            handleOpenQuestionDialog(
                              constants.mode.EDIT,
                              question,
                            )
                          }
                        >
                          Editar
                        </Button>
                      )}
                      {showInactiveOption(question.status) && (
                        <Button
                          color="secondary"
                          fullWidth
                          size="small"
                          onClick={() =>
                            handleOpenInactivateQuestionDialog(question)
                          }
                        >
                          Inativar
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button
                      color="secondary"
                      fullWidth
                      size="small"
                      onClick={() =>
                        handleOpenQuestionDialog(constants.mode.SHOW, question)
                      }
                    >
                      Visualizar
                    </Button>
                  )}
                </MenuButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </PerfectScrollbar>
  )
}

QuestionsTable.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  questions: PropTypes.array,
  handleOpenQuestionDialog: PropTypes.func.isRequired,
  handleOpenInactivateQuestionDialog: PropTypes.func.isRequired,
}

QuestionsTable.defaultProps = {
  questions: [],
}

export default QuestionsTable
