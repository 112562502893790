import * as yup from 'yup'

const schema = () =>
  yup.object().shape({
    preRegistration: yup.object().shape({
      id: yup.number().required(),
      name: yup.string().required(),
    }),
  })

export default schema
