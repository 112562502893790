import PropTypes from 'prop-types'

import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Grid,
} from '@material-ui/core'

import { Label } from 'components'

import { StorageTimeVolumetry, LawsForm } from './components'

import constants from 'constants/index'

const VolumetryForm = ({
  setValue,
  register,
  errors,
  control,
  volumetryType,
  setVolumetryType,
  laws,
  setLaws,
  data,
  enable,
  storageMode,
}) => {
  const handleChange = (e) => {
    setVolumetryType(e.target.value)
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <FormControl>
            <Label
              title="Tempo de armazenamento na organização"
              description="Por quanto tempo os dados ficam armazenados na organização?"
              item
              xs={12}
            >
              <RadioGroup
                onChange={handleChange}
                defaultValue={storageMode || data?.storageMode}
              >
                <FormControlLabel
                  key={constants.dataProcess.STORAGE_DEFINED_TYPE}
                  value={constants.dataProcess.STORY_MODE_DEFINED_TYPE}
                  control={<Radio />}
                  label="Definido"
                />
                <FormControlLabel
                  key={constants.dataProcess.STORAGE_UNDEFINED_TYPE}
                  value={constants.dataProcess.STORY_MODE_UNDEFINED_TYPE}
                  control={<Radio />}
                  label="Indefinido"
                />
                <FormControlLabel
                  disabled={!enable}
                  key={constants.dataProcess.STORAGE_PERMANENT_TYPE}
                  value={constants.dataProcess.STORY_MODE_PERMANENT_TYPE}
                  control={<Radio />}
                  label={`Permanente ${
                    !enable ? `(Selecione uma empresa)` : ''
                  }`}
                />
              </RadioGroup>
            </Label>
          </FormControl>
        </Grid>
      </Grid>
      {volumetryType === constants.dataProcess.STORY_MODE_DEFINED_TYPE && (
        <StorageTimeVolumetry
          setValue={setValue}
          register={register}
          errors={errors}
          control={control}
        />
      )}
      {volumetryType === constants.dataProcess.STORY_MODE_PERMANENT_TYPE && (
        <LawsForm laws={laws} setLaws={setLaws} volumetryType={volumetryType} />
      )}
    </>
  )
}

VolumetryForm.propTypes = {
  setValue: PropTypes.func,
  register: PropTypes.func,
  errors: PropTypes.object,
  control: PropTypes.object,
  volumetryType: PropTypes.string,
  setVolumetryType: PropTypes.func,
  laws: PropTypes.arrayOf(PropTypes.object),
  setLaws: PropTypes.func,
  data: PropTypes.object,
  enable: PropTypes.bool,
  storageMode: PropTypes.string,
}

VolumetryForm.defaultProps = {
  isShowLabel: true,
  enable: false,
}

export default VolumetryForm
