import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useForm, Controller } from 'react-hook-form'
import { Box, Grid, TextField } from '@material-ui/core'

import schema from './schema'
import helpers from 'helpers'

const AddOutsourcedDpo = ({ onSubmit, disabled, setDisabled, outsourced }) => {
  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      firstName: outsourced?.firstName ?? '',
      lastName: outsourced?.lastName ?? '',
      email: outsourced?.email ?? '',
      phone: helpers.formatters.phone(outsourced?.phone ?? ''),
    },
  })

  useEffect(() => {
    if (disabled) setDisabled(false)
    //eslint-disable-next-line
  }, [disabled])

  return (
    <Box my={2}>
      <form onSubmit={handleSubmit(onSubmit)} id="outsourcedDpoForm">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  label="Primeiro nome"
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.firstName}
                  helperText={errors?.firstName?.message}
                  fullWidth
                />
              }
              control={control}
              name="firstName"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  label="Sobrenome"
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.lastName}
                  helperText={errors?.lastName?.message}
                  fullWidth
                />
              }
              control={control}
              name="lastName"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  label="Email"
                  type="email"
                  color="primary"
                  variant="outlined"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  fullWidth
                />
              }
              control={control}
              name="email"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  label="Telefone"
                  type="text"
                  color="primary"
                  variant="outlined"
                  error={!!errors.phone}
                  helperText={errors?.phone?.message}
                  fullWidth
                />
              }
              control={control}
              onChange={([e]) => {
                return helpers.formatters.phone(e.target.value)
              }}
              name="phone"
              mode="onChange"
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

AddOutsourcedDpo.propTypes = {
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  setDisabled: PropTypes.func,
  outsourced: PropTypes.object,
}

AddOutsourcedDpo.defaultProps = {
  onSubmit: () => {},
  setDisabled: () => {},
}

export default AddOutsourcedDpo
