import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress } from '@material-ui/core'

import useStyles from './styles'

const LoadingButton = ({ loading, text, startIcon, alert, ...rest }) => {
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })

  const classes = useStyles({ dimensions, loading, alert })
  const targetRef = useRef()

  useEffect(() => {
    if (targetRef.current.offsetWidth >= dimensions.width) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight,
      })
    }
    //eslint-disable-next-line
  }, [loading])

  return (
    <Button
      color="primary"
      type="submit"
      variant="contained"
      ref={targetRef}
      className={classes.button}
      startIcon={!loading && startIcon}
      disabled={loading}
      {...rest}
    >
      {loading ? <CircularProgress color="inherit" size={25} /> : `${text}`}
    </Button>
  )
}

LoadingButton.propTypes = {
  loading: PropTypes.bool,
  text: PropTypes.string,
  startIcon: PropTypes.element,
  alert: PropTypes.bool,
}

LoadingButton.defaultProps = {
  startIcon: <></>,
  alert: false,
}

export default LoadingButton
