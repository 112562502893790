import { BasePermissionsForm } from '../index'

import { Box, Typography } from '@material-ui/core'

import constants from 'constants/index'
import PropTypes from 'prop-types'

const SalesForm = ({ register, permissions, handleChange }) => {
  const {
    preRegistrationList,
    paymentContractList,
    planList,
    couponList,
    paymentOrdersList,
    sellerList,
    churnList,
  } = constants.permissionsLists.collaboratorProfileLists

  return (
    <>
      <Box mt={2} mb={3}>
        <Typography variant="h5">Vendas</Typography>
      </Box>
      <BasePermissionsForm
        title="Clientes e sublicenciamentos"
        permissionsList={preRegistrationList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
      />
      <BasePermissionsForm
        title="Contratos"
        permissionsList={paymentContractList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
      />
      <BasePermissionsForm
        title="Ordens de pagamento"
        permissionsList={paymentOrdersList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
      />
      <BasePermissionsForm
        title="Vendedores & Parceiros"
        permissionsList={sellerList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
      />
      <BasePermissionsForm
        title="Planos"
        permissionsList={planList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
      />
      <BasePermissionsForm
        title="Cupons"
        permissionsList={couponList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
      />
      <BasePermissionsForm
        title="Churns"
        permissionsList={churnList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
      />
    </>
  )
}

SalesForm.propTypes = {
  register: PropTypes.func,
  permissions: PropTypes.object,
  handleChange: PropTypes.func,
}

export default SalesForm
