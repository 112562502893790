import PropTypes from 'prop-types'

import { Box, Typography, Grid, makeStyles } from '@material-ui/core'

import TableDataTreatments from '../../TableDataTreatments'

import styles from './styles'
const useStyles = makeStyles(styles)

const DataTreatment = ({ isLoading, dataTreatments }) => {
  const classes = useStyles()

  return (
    <>
      <Box mt={3} mb={2}>
        <Typography variant="h5">
          Com quem os dados são compartilhados?
        </Typography>
      </Box>
      <Grid container className={classes.dataCollectedContainer}>
        <Grid className={classes.dataCollectedTable} item xs={12}>
          {!isLoading && (
            <TableDataTreatments
              dataTreatments={dataTreatments}
              classes={classes}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}

DataTreatment.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  dataTreatments: PropTypes.array.isRequired,
}

export default DataTreatment
