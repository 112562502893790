import { Page, Container, ContentHeader } from 'components'
import { ProfilesForm } from 'components/Profiles'

const UserProfilesNew = () => {
  return (
    <Page title="Novo perfil">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Novo"
          menu="Perfis de usuário"
          subtitle="Novo perfil de usuário"
        />
        <ProfilesForm clientProfile />
      </Container>
    </Page>
  )
}

export default UserProfilesNew
