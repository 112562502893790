import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { compact } from 'lodash'

import { Box, Card, Grid, Typography, Divider } from '@material-ui/core'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { PaymentContractRow } from '..'

const PaymentContractTable = ({ preRegistration }) => {
  const paymentContracts = compact([
    ...preRegistration?.paymentContracts,
    preRegistration?.parentContract,
  ])

  return (
    <Box mb={4}>
      <Card>
        <Box width="100%">
          <Grid container>
            <Grid item xs={6}>
              <Box
                width="100%"
                p={2}
                height="100%"
                display="flex"
                alignItems="center"
              >
                <Typography variant="h5">Contratos</Typography>
              </Box>
            </Grid>
          </Grid>
          <Divider />
        </Box>
        <Box width="100%">
          <PerfectScrollbar
            options={{ wheelPropagation: true, useBothWheelAxes: true }}
          >
            <Table emptyMessage="Nenhum contrato encontrado" noWrap>
              <TableHead>
                <TableRow>
                  <TableCell width="10%">ID</TableCell>
                  <TableCell width="15%" align="left">
                    Numero do contrato
                  </TableCell>
                  <TableCell width="15%" align="left">
                    Responsável
                  </TableCell>
                  <TableCell width="15%" align="left">
                    Status
                  </TableCell>
                  <TableCell width="15%" align="left">
                    Mensalidade
                  </TableCell>
                  <TableCell width="15%" align="left">
                    Próximo faturamento
                  </TableCell>
                  <TableCell width="15%" align="left">
                    Próximo vencimento
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentContracts?.map((paymentContract) => (
                  <PaymentContractRow
                    paymentContract={paymentContract}
                    key={paymentContract?.id}
                    parentId={preRegistration?.parentContract?.id}
                  />
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </Box>
      </Card>
    </Box>
  )
}

PaymentContractTable.propTypes = {
  preRegistration: PropTypes.arrayOf(Object),
}

export default PaymentContractTable
