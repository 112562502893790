const styles = (theme) => ({
  icon: {
    backgroundColor: theme.palette.primary.main,
    height: '40px',
    width: '40px',
    borderRadius: '40px',
  },
  textBox: {
    whiteSpace: 'noWrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  descriptionTypography: {
    fontWeight: 600,
  },
})

export default styles
