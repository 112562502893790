import PropTypes from 'prop-types'

import { Box, Typography, Grid, makeStyles, Chip } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import clsx from 'clsx'
import helpers from 'helpers'
import styles from './styles'
import constants from 'constants/index'
import { LoadingBox, Collapse } from 'components'
import { isEmpty } from 'lodash'
import { DataProcessSourcesTable } from '../../../components'

const useStyles = makeStyles(styles)

const GeneralInformation = ({ data, isLoading, origin }) => {
  const classes = useStyles()

  const defaultStatus = () => {
    if (!origin) return data.statusId

    if (origin === constants.dataProcess.SUGGESTED_DATA_PROCESS_ORIGIN) {
      return constants.dataProcess.SUGGESTED
    }

    if (origin === constants.dataProcess.DISCARDED_DATA_PROCESS_ORIGIN) {
      return constants.dataProcess.DISCARDED
    }
  }

  return (
    <>
      {isLoading ? (
        <LoadingBox />
      ) : (
        <>
          {data.statusId === constants.dataProcess.DISAPPROVED_STATUS &&
            data.reproveJustification && (
              <Box mb={4}>
                <Grid item xs={12}>
                  <Alert severity="warning">
                    <AlertTitle>Último motivo de reprova</AlertTitle>
                    {data.reproveJustification}
                  </Alert>
                </Grid>
              </Box>
            )}
          <Typography variant="h5">Informações Gerais</Typography>
          <Grid container className={classes.root}>
            <Grid item md={4} sm={6} xs={12}>
              <Box mt={3}>
                <Typography variant="subtitle2" color="textSecondary">
                  Identificador
                </Typography>
                <Typography color="textPrimary" variant="body2">
                  {data.id}
                </Typography>
              </Box>
              <Box mt={3}>
                <Typography variant="subtitle2" color="textSecondary">
                  Departamento
                </Typography>
                <Typography color="textPrimary" variant="body2">
                  {data.department?.name}
                </Typography>
              </Box>
              <Box mt={3}>
                <Typography variant="subtitle2" color="textSecondary">
                  Versão
                </Typography>
                <Typography color="textPrimary" variant="body2">
                  {data.version}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={4} sm={6} xs={12}>
              <Box mt={3}>
                <Typography variant="subtitle2" color="textSecondary">
                  Nome do Registro
                </Typography>
                <Typography color="textPrimary" variant="body2">
                  {data.name}
                </Typography>
              </Box>
              <Box mt={3}>
                <Typography variant="subtitle2" color="textSecondary">
                  Código
                </Typography>
                <Typography color="textPrimary" variant="body2">
                  {data.code}
                </Typography>
              </Box>
              <Box mt={3}>
                <Typography variant="subtitle2" color="textSecondary">
                  Tempo de armazenamento da organização
                </Typography>
                <Typography color="textPrimary" variant="body2">
                  {!data.value && !data.volumetry
                    ? data?.eliminationDeadline
                    : `${data?.value} ${data?.volumetry}`}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Grid item xl={2} xs={6}>
                <Box mt={3}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Status
                  </Typography>
                  <Chip
                    className={clsx(
                      helpers.dataProcesses.textStatusColor(
                        defaultStatus(),
                        classes,
                      ),
                      helpers.dataProcesses.statusColor(
                        defaultStatus(),
                        classes,
                      ),
                    )}
                    align="center"
                    size="small"
                    label={helpers.dataProcesses.status(defaultStatus())}
                  />
                </Box>
              </Grid>
              <Grid item xl={2} xs={6}>
                <Box mt={2}>
                  <Typography variant="subtitle2" color="textSecondary">
                    Risco
                  </Typography>
                  <Chip
                    className={clsx(
                      helpers.dataProcesses.fragilityColor(
                        data?.adoptedFragilityId,
                        classes,
                      ),
                      helpers.dataProcesses.textFragilityColor(
                        data?.adoptedFragilityId,
                        classes,
                      ),
                    )}
                    align="center"
                    size="small"
                    label={helpers.functions.upperText(data?.fragility)}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs />
            <Grid item xl={8} xs={12}>
              <Box mt={2}>
                <Typography variant="subtitle2" color="textSecondary">
                  Por que o dado é tratado?
                </Typography>
                <Typography align="justify" color="textPrimary" variant="body2">
                  {data.finality}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs />
            <Grid item xl={8} xs={12}>
              <Box mt={2}>
                <Typography variant="subtitle2" color="textSecondary">
                  Justificativa do tempo de Armazenamento
                </Typography>
                <Typography align="justify" color="textPrimary" variant="body2">
                  {data.deadlineJustification}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs />
            <Grid item xs={12}>
              <Box mt={2}>
                {data?.dataProcessDataSources?.length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">
                      De onde vêm os dados (origem)
                    </Typography>
                    {data?.dataProcessDataSources?.map((dataSource) => (
                      <Box key={dataSource?.id}>
                        <Collapse
                          mx={0}
                          title={
                            <Typography>
                              {dataSource.sourceDescription}
                            </Typography>
                          }
                          visibilityChildren={
                            !isEmpty(dataSource.dataProcessSources)
                          }
                        >
                          {!isEmpty(dataSource.dataProcessSources) && (
                            <>
                              <Typography variant="body2" color="textSecondary">
                                {helpers.dataProcesses.labelDataProcessSourcesTable(
                                  dataSource?.dataProcessSources.map(
                                    (dataType) => dataType.type,
                                  ),
                                )}
                              </Typography>
                              <DataProcessSourcesTable
                                dataProcessSources={
                                  dataSource?.dataProcessSources
                                }
                                type={dataSource.dataProcessSources[0].type}
                              />
                            </>
                          )}
                        </Collapse>
                      </Box>
                    ))}
                  </Grid>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}

GeneralInformation.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  origin: PropTypes.string,
}

GeneralInformation.defaultProps = {
  origin: undefined,
}

export default GeneralInformation
