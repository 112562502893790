import { isEmpty } from 'lodash'
import { Fragment, useState } from 'react'
import {
  Grid,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { Delete as DeleteIcon, Add as AddIcon } from '@material-ui/icons'
import { Skeleton } from '@material-ui/lab'

import { Controller, useForm } from 'react-hook-form'
import { useSnackbar, useFetch } from 'hooks'

import { Card } from 'components'

import helpers from 'helpers'
import useStyles from './styles'

import * as service from 'service'

const BillingEmails = ({ disabled, paymentContractId }) => {
  const snackbar = useSnackbar()
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const { control, handleSubmit, errors } = useForm({
    defaultValues: {
      email: '',
    },
  })

  const { response, isLoading, refresh } = useFetch(
    service.dponet.billingEmails.index,
    {
      entityIdEq: paymentContractId,
      entityTypeEq: 'PaymentContract',
    },
    [],
  )

  const billingEmails = (!isLoading && response?.data?.billingEmails) || []

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      await service.dponet.billingEmails.create({
        paymentContractId,
        email: data.email.toLowerCase(),
      })

      snackbar.open({
        message: 'E-mail de pagamento e cobrança adicionado com sucesso!',
        variant: 'success',
      })
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Falha ao adicionar o e-mail de cobrança',
        ),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleRemove = async (billingEmailId) => {
    setLoading(true)
    try {
      await service.dponet.billingEmails.destroy(billingEmailId)

      snackbar.open({
        message: 'E-mail de pagamento e cobrança excluído com sucesso!',
        variant: 'success',
      })

      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(
          error?.response?.data?.error ||
            'Falha ao excluir o e-mail de cobrança',
        ),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Card title="E-mails de pagamento e cobrança">
      <form id="id-add-email-payment">
        <Grid container spacing={2}>
          {!disabled && (
            <>
              <Grid item xs={10}>
                <Controller
                  control={control}
                  name="email"
                  mode="onChange"
                  as={
                    <TextField
                      type="email"
                      label="Emails de cobrança"
                      fullWidth
                      variant="outlined"
                      error={!!errors?.email}
                      helperText={errors?.email?.message}
                      disabled={loading}
                    />
                  }
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  form="id-add-email-payment"
                  fullWidth
                  className={classes.btn}
                  startIcon={
                    loading ? (
                      <CircularProgress color="white" size={20} />
                    ) : (
                      <AddIcon />
                    )
                  }
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Adicionar
                </Button>
              </Grid>
            </>
          )}
          {isLoading ? (
            <>
              <Grid item xs={10}>
                <Skeleton variant="rect" height={55} />
              </Grid>
              <Grid item xs={2}>
                <Skeleton variant="rect" height={55} />
              </Grid>
            </>
          ) : (
            <>
              {isEmpty(billingEmails) && (
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Nenhum e-mail adicionado
                  </Typography>
                </Grid>
              )}

              {billingEmails?.map((emailObject) => (
                <Fragment key={emailObject?.id}>
                  <Grid item xs={disabled ? 12 : 10}>
                    <TextField
                      type="email"
                      defaultValue={emailObject?.email}
                      label="Emails de cobrança"
                      fullWidth
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  {!disabled && (
                    <Grid item xs={2}>
                      <Button
                        fullWidth
                        className={classes.btn}
                        startIcon={
                          loading ? (
                            <CircularProgress color="white" size={20} />
                          ) : (
                            <DeleteIcon />
                          )
                        }
                        onClick={() => handleRemove(emailObject?.id)}
                        variant="contained"
                        color="secondary"
                        type="button"
                        disabled={loading}
                      >
                        Remover
                      </Button>
                    </Grid>
                  )}
                </Fragment>
              ))}
            </>
          )}
        </Grid>
      </form>
    </Card>
  )
}

export default BillingEmails
