import PropTypes from 'prop-types'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box } from '@material-ui/core'

import SalesRow from '../SalesRow'
import PerfectScrollbar from 'react-perfect-scrollbar'

const SalesTable = ({ sales }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: true, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhum encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="30%" align="left">
                Nome
              </TableCell>
              <TableCell width="25%" align="left">
                E-mail
              </TableCell>
              <TableCell width="25%" align="left">
                Tipo
              </TableCell>
              <TableCell width="10%" align="left">
                Ativo
              </TableCell>
              <TableCell width="5%" align="left">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sales.map((sale) => (
              <SalesRow sale={sale} key={sale?.id} />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

SalesTable.propTypes = {
  subgroups: PropTypes.arrayOf(Object),
  refresh: PropTypes.func,
}

export default SalesTable
