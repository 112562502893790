import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  inputContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px ${theme.palette.primary.main} dashed`,
    borderRadius: '5px',
    color: theme.palette.primary.main,
    flexDirection: 'column',
  },
}))

export default styles
