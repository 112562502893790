import constants from 'constants/index'
import nonComplianceReportsConstants from 'constants/nonComplianceReports'

const formatQuestionnairesToReport = (questionnaires) => {
  const objectToReturn = []
  for (let questionnaire of questionnaires) {
    const questions = questionnaire?.questions

    const addoptedQuestions = questions.filter(
      (question) =>
        question.answer === constants?.question?.AFFIRMATIVE_ANSWER ||
        (question.answer === constants?.question?.NEGATIVE_ANSWER &&
          !nonComplianceReportsConstants?.NOT_MEASURES_TAKEN_STATUS.includes(
            question?.nonComplianceReport?.status,
          )),
    )

    const nonAddoptedQuestions = questions.filter(
      (question) =>
        question.answer === constants?.question?.NEGATIVE_ANSWER &&
        nonComplianceReportsConstants?.NOT_MEASURES_TAKEN_STATUS.includes(
          question?.nonComplianceReport?.status,
        ),
    )

    const oppenedQuestions = filterQuestionsByAnswer(questions, null)

    objectToReturn.push({
      name: questionnaire?.name,
      progress: questionnaire?.answerPercentage,
      compliance: questionnaire?.compliancePercentage,
      addoptedQuestions,
      nonAddoptedQuestions,
      oppenedQuestions,
    })
  }

  return objectToReturn
}

const filterQuestionsByAnswer = (questions, answer) =>
  questions.filter((question) => question.answer === answer)

export default formatQuestionnairesToReport
