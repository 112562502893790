import { useState, useEffect } from 'react'

import RmcContext from 'contexts/RmcContext'

import * as service from 'service'

import constants from 'constants/index'

const RmcProvider = ({ children }) => {
  const [data, setData] = useState({})
  const [oldScreen, setOldScreen] = useState()
  const [loading, setLoading] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const [isEditable, setIsEditable] = useState(true)
  const [riskStep, setRiskStep] = useState(false)
  const [unnecessaryDataValues, setUnnecessaryDataValues] = useState()
  const [securityMeasures, setSecurityMeasures] = useState([])
  const [initialSecurityMeasures, setInitialSecurityMeasures] = useState([])

  const loadData = async (nonComplianceReportId) => {
    setLoading(true)
    setIsEditable(true)

    let response = await service.dponet.nonComplianceReports.get({
      nonComplianceReportId: nonComplianceReportId,
    })
    const rmc = response?.data?.nonComplianceReport

    setIsEditable(
      !constants.nonComplianceReports.NOT_EDITABLE_STATUSES.includes(
        rmc?.status,
      ),
    )
    if (
      rmc.finality ===
        constants.nonComplianceReports.FRAGILITY_AND_NECESSARY_FINALITY &&
      rmc.unnecessaryDatas[0]?.status !==
        constants.unnecessaryData.STATUS.UNNECESSARY
    ) {
      setRiskStep(true)
    }

    setData(rmc)
    setInitialSecurityMeasures(
      rmc?.securityMeasures?.filter(
        (securityMeasure) =>
          securityMeasure?.measureType ===
          constants.nonComplianceReports.QUESTION_FINALITY,
      ),
    )

    const isOldScreen =
      rmc?.entityType !== constants.nonComplianceReports.ENTITY_DATA_PROCESS ||
      rmc?.status === constants.nonComplianceReports.RISK_ACCEPTED_STATUS ||
      rmc?.non_compliance_report_actions?.length > 0

    setOldScreen(isOldScreen)
    if (!unnecessaryDataValues) {
      setUnnecessaryDataValues(
        mountUnnecessaryDataValues(rmc?.unnecessaryDatas),
      )
    }
    setLoading(false)
  }

  useEffect(() => {
    if (data.id) {
      loadData(data.id)
    }
    // eslint-disable-next-line
  }, [refresh])

  const isContinuedFinalized =
    data?.chosenAction ===
      constants.nonComplianceReports.CONTINUED_CHOSEN_ACTION &&
    data?.status === constants.nonComplianceReports.FINALIZED_STATUS

  const reload = () => {
    setSecurityMeasures([])
    setRefresh(refresh + 1)
  }

  const mountUnnecessaryDataValues = (unnecessaryData = []) => {
    const data = {}
    for (let item of unnecessaryData) {
      data[item.id] = null
    }
    return data
  }

  const isEditableByFinality = !(!isEditable || riskStep)

  return (
    <RmcContext.Provider
      value={{
        data,
        setData,
        loading,
        setLoading,
        loadData,
        reload,
        isEditable,
        setIsEditable,
        riskStep,
        setRiskStep,
        isEditableByFinality,
        isContinuedFinalized,
        oldScreen,
        unnecessaryDataValues,
        setUnnecessaryDataValues,
        securityMeasures,
        setSecurityMeasures,
        initialSecurityMeasures,
        setInitialSecurityMeasures,
      }}
    >
      {children}
    </RmcContext.Provider>
  )
}

export default RmcProvider
