import permissions from '../../permissions'

const EXPORT_INFO_LIST = [
  {
    name: 'Permitir/Negar acesso ao menu',
    tag: permissions.EXPORT_INFO.EXPORT_RIPD,
  },
]

export default EXPORT_INFO_LIST
