import { Page, Container, ContentHeader } from 'components'
import { SegmentsForm } from './components'

const SegmentsNew = () => {
  return (
    <Page title="Novo segmento">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Novo"
          menu="Segmento"
          subtitle="Criação de segmento"
        />
        <SegmentsForm />
      </Container>
    </Page>
  )
}
export default SegmentsNew
