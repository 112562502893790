import atLeastOneNecessary from './atLeastOneNecessary'
import constants from 'constants/index'

const verifyUnnecessaryScopes = (data, handleOpenDialog) => {
  const dataCollectedAtLeastOneNecessary = atLeastOneNecessary(
    data,
    'collectedField',
  )
  const lifeCycleAtLeastOneNecessary = atLeastOneNecessary(
    data,
    'dataLifeCycle',
  )

  if (!dataCollectedAtLeastOneNecessary && !lifeCycleAtLeastOneNecessary)
    return handleOpenDialog(
      constants.necessityAndProportionalities.BOTH_WARNING_MESSAGE,
    )
  if (!dataCollectedAtLeastOneNecessary)
    return handleOpenDialog(
      constants.necessityAndProportionalities.DATA_COLLECTED_WARNING_MESSAGE,
    )
  if (!lifeCycleAtLeastOneNecessary)
    return handleOpenDialog(
      constants.necessityAndProportionalities.LIFE_CYCLE_WARNING_MESSAGE,
    )
}

export default verifyUnnecessaryScopes
