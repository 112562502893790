import api from 'service/api'

const dponetAPI = api.create('dponet')

const list = async ({ ...params }) => {
  return await dponetAPI.get(`/question_controls/list_custom_kinds`, { params })
}

const questionControls = {
  list,
}

export default questionControls
