import PropTypes from 'prop-types'

import { BaseDialog } from 'components'

import { Typography } from '@material-ui/core'

import { useSnackbar } from 'hooks'

import * as service from 'service'

import constants from 'constants/index'

import helpers from 'helpers'

const DialogApproveRevision = ({
  data,
  open,
  setOpen,
  reloadOptions,
  setLoading,
  onEvent,
}) => {
  const snackbar = useSnackbar()

  const handleApproveRevision = async () => {
    setLoading(true)
    try {
      await service.dponet.dataProcesses.changeStatus({
        dataProcessId: data.id,
        statusId: constants.dataProcess.APPROVED_STATUS,
      })
      snackbar.open({
        message: 'Processamento de dados aprovado com sucesso!',
        variant: 'success',
      })
      setLoading(false)
      reloadOptions()
      onEvent()
      setOpen(false)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
      setLoading(false)
      setOpen(false)
    }
  }

  return (
    <BaseDialog
      actionButton={handleApproveRevision}
      open={open}
      setOpen={setOpen}
      title="Aprovar processamento de dados"
      closeButtonText="cancelar"
      actionButtonText="Aprovar"
    >
      <Typography>
        {data?.company?.kind === constants.companies.KINDS.TEMPLATE
          ? `Este processo será incluído em uma empresa template que possui empresas reais criadas a partir dela. A inclusão deste processo será informada a todas as empresas reais e será exibida na aba "Processos sugeridos" para as empresas reais. Você tem certeza de que deseja incluir esse novo processo na empresa template?`
          : 'Deseja realmente aprovar este processamento de dados?'}
      </Typography>
    </BaseDialog>
  )
}

DialogApproveRevision.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  reloadOptions: PropTypes.func,
  onEvent: PropTypes.func,
  setLoading: PropTypes.func,
}

DialogApproveRevision.defaultProps = {
  setOpen: () => {},
  setLoading: () => {},
  reloadOptions: () => {},
  onEvent: () => {},
}

export default DialogApproveRevision
