import { isUndefined } from 'lodash'

import * as service from 'service'

const listCache = (params, options = {}) => {
  const keys = isUndefined(params)
    ? ['departments', 'list']
    : ['departments', 'list', params]

  const queryFn = () =>
    service.dponet.departments.get(params).then((response) => response.data)

  return { queryKey: keys, queryFn: queryFn, exact: false, ...options }
}

const viewCache = (id, options = {}) => {
  const keys = ['departments', 'view', parseInt(id)]
  const queryFn = () =>
    service.dponet.departments
      .get({ departmentId: id })
      .then((response) => response.data)

  return { queryKey: keys, queryFn: queryFn, exact: true, ...options }
}

const availableUsersCache = (id, companyId, options = {}) => {
  const keys = ['departments', 'availableUsers', parseInt(id)]
  const queryFn = () =>
    service.dponet.departments
      .availableUsers({ params: { departmentId: id, companyId } })
      .then((response) => response.data)

  return { queryKey: keys, queryFn: queryFn, exact: true, ...options }
}

const updateCache = (id) => {
  if (isUndefined(id)) return [listCache()]

  return [listCache(), viewCache(id)]
}

const departments = {
  listCache,
  viewCache,
  updateCache,
  availableUsersCache,
}

export default departments
