import constants from 'constants/index'

const status = (status) => {
  switch (status) {
    case constants.auditProcess.WAITING_ACTION_STATUS:
      return constants.auditProcess.WAITING_ACTION_LABEL
    case constants.auditProcess.CONFIRMED_STATUS:
      return constants.auditProcess.CONFIRMED_LABEL
    case constants.auditProcess.UPDATED_STATUS:
      return constants.auditProcess.UPDATED_LABEL
    case constants.auditProcess.AUTOMATICALLY_APPROVED_STATUS:
      return constants.auditProcess.AUTOMATICALLY_APPROVED_LABEL
    case constants.preRegistration.STATUSES.INITIATED_STATUS:
      return constants.preRegistration.STATUSES_LABELS.INITIATED_LABEL
    case constants.preRegistration.STATUSES.PAYMENT_FAILED_STATUS:
      return constants.preRegistration.STATUSES_LABELS.PAYMENT_FAILED_LABEL
    case constants.preRegistration.STATUSES.AWAITING_PAYMENT_STATUS:
      return constants.preRegistration.STATUSES_LABELS.AWAITING_PAYMENT_LABEL
    case constants.preRegistration.STATUSES.DEMO_STATUS:
      return constants.preRegistration.STATUSES_LABELS.DEMO_LABEL
    case constants.preRegistration.STATUSES.TRIAL_LIMITED_STATUS:
      return constants.preRegistration.STATUSES_LABELS.TRIAL_LIMITED_LABEL
    case constants.preRegistration.STATUSES.TRIAL_COMPLETE_STATUS:
      return constants.preRegistration.STATUSES_LABELS.TRIAL_COMPLETE_LABEL
    case constants.preRegistration.STATUSES.TRIAL_CANCELED_STATUS:
      return constants.preRegistration.STATUSES_LABELS.TRIAL_CANCELED_LABEL
    case constants.preRegistration.STATUSES.OVERDUE_STATUS:
      return constants.preRegistration.STATUSES_LABELS.OVERDUE_LABEL
    case constants.preRegistration.STATUSES.AWAITING_RESPONSE_STATUS:
      return constants.preRegistration.STATUSES_LABELS.AWAITING_RESPONSE_LABEL
    case constants.paymentOrder.STATUSES.CANCELED_STATUS:
      return constants.paymentOrder.STATUSES_LABELS.CANCELED_LABEL
    case constants.paymentOrder.STATUSES.FAILURE_STATUS:
      return constants.paymentOrder.STATUSES_LABELS.FAILURE_LABEL
    case constants.paymentOrder.STATUSES.NOT_PAID_STATUS:
      return constants.paymentOrder.STATUSES_LABELS.NOT_PAID_LABEL
    case constants.paymentOrder.STATUSES.OVERDUE_STATUS:
      return constants.paymentOrder.STATUSES_LABELS.OVERDUE_LABEL
    case constants.paymentOrder.STATUSES.PAID_STATUS:
      return constants.paymentOrder.STATUSES_LABELS.PAID_LABEL
    case constants.paymentOrder.STATUSES.WAITING_CONFIRM_STATUS:
      return constants.paymentOrder.STATUSES_LABELS.WAITING_CONFIRM_LABEL
    case constants.paymentOrder.STATUSES.WAITING_PAYMENT_STATUS:
      return constants.paymentOrder.STATUSES_LABELS.WAITING_PAYMENT_LABEL
    case constants.paymentOrder.STATUSES.INTEGRATION_STATUS:
      return constants.paymentOrder.STATUSES_LABELS.INTEGRATION_LABEL
    case constants.paymentOrder.STATUSES.WAITING_GATEWAY_STATUS:
      return constants.paymentOrder.STATUSES_LABELS.WAITING_GATEWAY_LABEL
    case constants.paymentOrder.STATUSES.WAITING_NFE_STATUS:
      return constants.paymentOrder.STATUSES_LABELS.WAITING_NFE_LABEL
    default:
      return status
  }
}

export default status
