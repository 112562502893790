const fragilityLabel = (fragilityId) => {
  switch (fragilityId) {
    case 1:
      return 'BAIXO'
    case 2:
      return 'MÉDIO'
    case 3:
      return 'ALTO'
    case 4:
      return 'SEVERO'
    default:
      return 'NÃO CALCULADO'
  }
}

export default fragilityLabel
