import { makeStyles } from '@material-ui/core'

const styles = (overFlowSize) => {
  return makeStyles((theme) => ({
    overFlow: {
      display: 'block',
      maxWidth: overFlowSize,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '14px',
      color: theme.palette.secondary.dark,
    },

    noOverFlow: {
      display: 'block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '14px',
      color: theme.palette.secondary.dark,
    },

    pointer: {
      cursor: 'pointer',
    },
  }))
}

export default styles
