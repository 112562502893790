import {
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Divider,
} from '@material-ui/core'

import PropTypes from 'prop-types'

import useStyles from '../../styles'

import { isEmpty } from 'lodash'

const CustomList = ({
  title,
  items,
  handleToggleAll,
  numberOfChecked,
  handleToggle,
  checked,
}) => {
  const classes = useStyles()

  return (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={(numberOfChecked(items) === items?.length) !== 0}
            indeterminate={
              numberOfChecked(items) !== items?.length &&
              numberOfChecked(items) !== 0
            }
            disabled={isEmpty(items)}
            inputProps={{
              'aria-label': 'Todos os itens selecionados.',
              color: 'primary',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${
          items?.length || '0'
        } selecionados`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items?.map((value) => {
          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  color="primary"
                  disableRipple
                  inputProps={{ 'aria-labelledby': value.id }}
                />
              </ListItemIcon>
              <ListItemText id={value.id} primary={value.name} />
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </Card>
  )
}

CustomList.propTypes = {
  handleToggleAll: PropTypes.func,
  numberOfChecked: PropTypes.func,
  handleToggle: PropTypes.func,
  checked: PropTypes.array,
}

CustomList.defaultProps = {
  handleToggleAll: () => {},
  numberOfChecked: () => {},
  handleToggle: () => {},
}

export default CustomList
