import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  paper: {
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  cardIaMessage: {
    backgroundColor: theme.colors.dataProcess.buttons.primary,
    color: theme.palette.common.white,
  },
  buttonAprove: {
    backgroundColor: theme.colors.dataProcess.buttons.primary,
    textShadow: '1px 4px 4px rgba(0, 0, 0, 0.25)',
    maxHeight: 30,
  },
}))

export default styles
