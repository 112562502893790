import { Card, CardHeader, Divider } from '@material-ui/core'

import PropTypes from 'prop-types'

import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import { getBaseURL } from 'service/env'

import { IconButton } from 'components'

import { VisibilityOutlined as VisibilityOutlinedIcon } from '@material-ui/icons'

import useDataProcess from 'hooks/useDataProcess'
import helpers from 'helpers'

const DocumentRow = ({ document }) => {
  return (
    <TableRow>
      <TableCell>{helpers.functions.documentName(document.url)}</TableCell>
      <TableCell disableTooltip align="right">
        <IconButton
          size="small"
          Icon={VisibilityOutlinedIcon}
          text="Visualizar"
          onClick={() =>
            window.open(`${getBaseURL('dponet')}${document.url}`, '_blank')
          }
        />
      </TableCell>
    </TableRow>
  )
}

DocumentRow.propTypes = {
  document: PropTypes.object.isRequired,
}

const DocumentedInformation = () => {
  const { data } = useDataProcess()
  const documents = data.documents

  return (
    <Card>
      <CardHeader title="Documentos anexados" />
      <Divider />
      <PerfectScrollbar>
        <Table emptyMessage="Nenhum documento encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="75%">Documentos</TableCell>
              <TableCell width="25%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document, index) => (
              <DocumentRow document={document} key={index} />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Card>
  )
}

export default DocumentedInformation
