import chipStatusColor from './chipStatusColor'
import iconByStatus from './iconByStatus'
import label from './label'
import mountTabsWithQuestionnaires from './mountTabsWithQuestionnaires'
import separateCompaniesByKinds from './separateCompaniesByKinds'

const companySuppliers = {
  chipStatusColor,
  iconByStatus,
  label,
  mountTabsWithQuestionnaires,
  separateCompaniesByKinds,
}

export default companySuppliers
