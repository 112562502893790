import { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'

import { FormCard, RemoveButton } from 'components'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { RemoveSecondaryEconomicActivityConfirmationDialog } from './components'

import { useCompany } from 'hooks'

const TableRowSecondaryEconomicActivity = ({
  economicActivity,
  companyId,
  editionPermitted,
  setRemovableEconomicActivityId,
  setOpen,
  ...rest
}) => {
  const handleOpenRemoveDialog = () => {
    setRemovableEconomicActivityId(economicActivity.id)
    setOpen(true)
  }

  return (
    <>
      <TableRow {...rest}>
        <TableCell>{economicActivity?.id}</TableCell>
        <TableCell overFlowSize="400px">{economicActivity?.name}</TableCell>
        <TableCell>{economicActivity?.session}</TableCell>
        <TableCell>{economicActivity?.division}</TableCell>
        <TableCell>{economicActivity?.group}</TableCell>
        <TableCell>{economicActivity?.classCnae}</TableCell>
        {editionPermitted && (
          <TableCell disableTooltip>
            <RemoveButton
              onClick={() => {
                handleOpenRemoveDialog()
              }}
            />
          </TableCell>
        )}
      </TableRow>
    </>
  )
}

const SecondaryEconomicActivityTable = ({
  companyId,
  setSelectSecondaryEconomicActivityDialog,
  editionPermitted,
}) => {
  const {
    secondaryEconomicActivities,
    isLoading,
    loadSecondaryEconomicActivities,
  } = useCompany()

  const [
    removeSecondaryEconomicActivityConfirmationDialog,
    setRemoveSecondaryEconomicActivityConfirmationDialog,
  ] = useState(false)
  const [removableEconomicActivityId, setRemovableEconomicActivityId] =
    useState()

  useEffect(() => {
    const loadSecondaryActivities = async () => {
      await loadSecondaryEconomicActivities(companyId)
    }
    loadSecondaryActivities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <FormCard
        padding={0}
        title="Selecione a atividade econômica secundária da empresa"
        addButton={editionPermitted}
        buttonText="Adicionar"
        onClick={() => setSelectSecondaryEconomicActivityDialog(true)}
      >
        {!isLoading && (
          <Box width="100%">
            <PerfectScrollbar
              options={{ wheelPropagation: false, useBothWheelAxes: true }}
            >
              <Table
                emptyMessage="Nenhuma atividade econômica secundária cadastrada."
                noWrap
              >
                <TableHead>
                  <TableRow>
                    <TableCell width="10%">ID</TableCell>
                    <TableCell width={editionPermitted ? '40%' : '50%'}>
                      Nome
                    </TableCell>
                    <TableCell width="10%">Sessão</TableCell>
                    <TableCell width="10%">Divisão</TableCell>
                    <TableCell width="10%">Grupo</TableCell>
                    <TableCell width="10%">Identificador CNAE</TableCell>
                    {editionPermitted && (
                      <TableCell width="10%" align="center">
                        Ações
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {secondaryEconomicActivities?.map((economicActivity) => (
                    <TableRowSecondaryEconomicActivity
                      economicActivity={economicActivity}
                      companyId={companyId}
                      key={economicActivity?.id}
                      hover
                      editionPermitted={editionPermitted}
                      setOpen={
                        setRemoveSecondaryEconomicActivityConfirmationDialog
                      }
                      setRemovableEconomicActivityId={
                        setRemovableEconomicActivityId
                      }
                    />
                  ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </Box>
        )}
      </FormCard>
      {editionPermitted && (
        <RemoveSecondaryEconomicActivityConfirmationDialog
          open={removeSecondaryEconomicActivityConfirmationDialog}
          setOpen={setRemoveSecondaryEconomicActivityConfirmationDialog}
          economicActivityId={removableEconomicActivityId}
          companyId={companyId}
        />
      )}
    </>
  )
}
TableRowSecondaryEconomicActivity.propTypes = {
  economicActivity: PropTypes.object,
  companyId: PropTypes.number,
}

SecondaryEconomicActivityTable.propTypes = {
  companyId: PropTypes.number,
  control: PropTypes.object,
  errors: PropTypes.object,
  setSelectSecondaryEconomicActivityDialog: PropTypes.func,
}

SecondaryEconomicActivityTable.defaultProps = {
  setSelectSecondaryEconomicActivityDialog: () => {},
}

export default SecondaryEconomicActivityTable
