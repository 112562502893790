import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTheme } from '@material-ui/styles'

import {
  Box,
  Card,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  Button,
  TablePagination,
  Divider,
} from '@material-ui/core'
import { Plus as PlusIcon } from 'react-feather'

import {
  Container,
  ContentHeader,
  FilterButton,
  LoadingFeedback,
  Page,
  Permitted,
} from 'components'
import { LastUpdateFilter, LastUpdatesTable } from './components'

import { useFilter, usePagination } from 'hooks'
import useFetch from 'hooks/useFetch'

import constants from 'constants/index'
import { routes } from 'Routes'
import * as service from 'service'

const LastUpdatesMain = () => {
  const theme = useTheme()
  const history = useHistory()
  const filter = useFilter()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const [tab, setTab] = useState(constants.lastUpdates.ALL_TAB)

  const handleChangeTab = (_, value) => {
    setTab(value)
    if (value !== constants.lastUpdates.DRAFT_TAB) {
      return filter.setFilters({
        ...filter.filters,
      })
    }

    const filters = filter.filters
    filter.setFilters({ ...filters })
  }

  const paramsFilter = {
    ...filter.filters,
    draft: tab === constants.lastUpdates.DRAFT_TAB,
    active: tab !== constants.lastUpdates.INACTIVE_TAB,
  }

  const paramsFilterAll = {
    ...filter.filters,
  }

  const paramsFilterInactive = {
    ...filter.filters,
    active: false,
  }

  const params = () => {
    if (
      tab !== constants.lastUpdates.ALL_TAB &&
      tab !== constants.lastUpdates.INACTIVE_TAB
    ) {
      return paramsFilter
    } else if (tab === constants.lastUpdates.INACTIVE_TAB) {
      return paramsFilterInactive
    }
    return paramsFilterAll
  }

  const { refresh, response, isLoading } = useFetch(
    service.dponet.lastUpdates.get,
    {
      ...params(),
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  return (
    <Page title="Últimas Atualizações">
      <LoadingFeedback open={isLoading} />
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={15}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          menu="Atualizações"
          title="Últimas Atualizações"
          subtitle="Últimas Atualizações"
        >
          <Box ml={1}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
        </ContentHeader>
        <Box
          mt={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              {constants.lastUpdates.TABS.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  onClick={(event) => handleChangeTab(event, tab.value)}
                />
              ))}
            </Tabs>
          </Box>
        </Box>
        <Card>
          <Box width="100%" display="flex" alignItems="center">
            <Grid container>
              <Grid item xs={6}>
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  ml={2}
                >
                  <Typography variant="h5">Atualizações</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box p={2} display="flex" justifyContent="flex-end">
                  <Permitted tag={constants.permissions.LAST_UPDATES.CREATE}>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<PlusIcon />}
                      onClick={() => history.push(routes.lastUpdates.new)}
                    >
                      Criar
                    </Button>
                  </Permitted>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <LastUpdatesTable
            lastUpdates={response?.data?.lastUpdates ?? []}
            refresh={refresh}
          />
          <Box px={2} display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount ?? 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Card>
      </Container>
      <LastUpdateFilter filter={filter} />
    </Page>
  )
}

export default LastUpdatesMain
