import { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

import { Grid, Button } from '@material-ui/core'

import useStyles from './styles'

import { useTransferList } from 'hooks/'

import { CustomList, CustomListWithLabel, IconTransform } from './components'

const TransferList = ({ leftItens, rightItens, withLabel, formatFunction }) => {
  const classes = useStyles()

  const {
    left,
    setLeft,
    right,
    setRight,
    union,
    intersection,
    not,
    reloadToFormat,
    reload,
  } = useTransferList()

  const [checked, setChecked] = useState([])

  useEffect(() => {
    setLeft(formatFunction(left))
    setRight(formatFunction(right))

    // eslint-disable-next-line
  }, [reload])

  useEffect(() => {
    setLeft(leftItens)
    setRight(rightItens)
    // eslint-disable-next-line
  }, [])

  let interactiveRight = []
  let interactiveLeft = []

  if (withLabel) {
    try {
      for (let rightKeys of Object.keys(right)) {
        interactiveRight.push(right[rightKeys])
      }
    } catch {
      interactiveRight = right
    }

    try {
      for (let leftKeys of Object.keys(left)) {
        interactiveLeft.push(left[leftKeys])
      }
    } catch {
      interactiveLeft = left
    }
  }

  const leftChecked = intersection(checked, withLabel ? interactiveLeft : left)
  const rightChecked = intersection(
    checked,
    withLabel ? interactiveRight : right,
  )

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const numberOfChecked = (items) => intersection(checked, items)?.length

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items?.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const arrayToAddToOtherSide = (array) => {
    let generalItems = []

    Object.keys(array).map((key) => {
      generalItems.push(array[key].items)
      return array[key].items
    })

    return union(checked, [...generalItems.flat()].flat())
  }

  const arrayToRemoveOfSameSide = (array) => {
    const keys = Object.keys(array)

    let generalItems = []

    keys.map((key) => {
      generalItems.push(array[key].items)
      return array[key].items
    })

    return not(generalItems.flat(), checked)
  }

  const arrayToRemoveCheckOnSideChange = (array) => {
    const keys = Object.keys(array)

    let generalItems = []

    keys.map((key) => {
      generalItems.push(array[key].items)
      return array[key].items
    })
    return not(checked, generalItems.flat())
  }

  const handleCheckedRightWithLabel = () => {
    setRight(arrayToAddToOtherSide(right))
    setLeft(arrayToRemoveOfSameSide(left))
    setChecked(arrayToRemoveCheckOnSideChange(left))

    reloadToFormat()
  }

  const handleCheckedLeftWithLabel = () => {
    setLeft(arrayToAddToOtherSide(left))
    setRight(arrayToRemoveOfSameSide(right))
    setChecked(arrayToRemoveCheckOnSideChange(right))

    reloadToFormat()
  }

  const numberOfCheckedWithLabel = (object) => {
    const generalItems = [...Object.keys(object).map((key) => object[key])]
    let checkedItemsToFormat = [
      ...Object.keys(checked).map((key) => checked[key]),
    ]

    if (generalItems.length > 0) {
      let checkedItems = []

      for (let i in Object.keys(checkedItemsToFormat)) {
        let a = checkedItemsToFormat[i]

        checkedItems.push(a)
      }

      return intersection([...checkedItems], generalItems)?.length
    }
    return rightChecked.length
  }

  const handleToggleWithLabel = (value) => {
    const currentIndex = checked.indexOf(value)
    var newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleToggleAllWithLabel = (items) => {
    if (numberOfCheckedWithLabel(items) === items?.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const disableToTransferToRight = () => {
    if (withLabel) {
      let generalLeft = []

      for (let array in interactiveLeft) {
        if (interactiveLeft[array] && interactiveLeft[array].items) {
          interactiveLeft[array].items.map((item) => {
            if (
              generalLeft.filter((itemLeft) => itemLeft === item).length === 0
            ) {
              generalLeft.push(item)
            }
            return generalLeft
          })
        } else {
          generalLeft.push(...interactiveLeft)
        }
      }
      return intersection(checked, generalLeft).length === 0
    }
    return leftChecked.length === 0
  }

  const disableToTransferToLeft = () => {
    if (withLabel) {
      let generalRight = []
      for (let array in interactiveRight) {
        interactiveRight[array].items &&
          interactiveRight[array].items.map((item) => {
            if (
              generalRight.filter((itemRight) => itemRight === item).length ===
              0
            ) {
              generalRight.push(item)
            }
            return generalRight
          })
      }

      return intersection(checked, generalRight).length === 0
    }
    return rightChecked.length === 0
  }

  const onClickToRight = () => {
    if (withLabel) {
      handleCheckedRightWithLabel()
    } else {
      handleCheckedRight()
    }
    reloadToFormat()
  }

  const onClickToLeft = () => {
    if (withLabel) {
      handleCheckedLeftWithLabel()
    } else {
      handleCheckedLeft()
    }
    reloadToFormat()
  }

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={classes.root}
      spacing={2}
    >
      <Grid item md={5} xs={12}>
        {withLabel ? (
          <>
            <CustomListWithLabel
              handleToggle={handleToggleWithLabel}
              handleToggleAll={handleToggleAllWithLabel}
              numberOfChecked={numberOfCheckedWithLabel}
              title="Selecione"
              items={left}
              checked={checked}
              formatFunction={formatFunction}
            />
          </>
        ) : (
          <CustomList
            handleToggle={handleToggle}
            handleToggleAll={handleToggleAll}
            numberOfChecked={numberOfChecked}
            title="Selecione"
            items={left}
            checked={checked}
          />
        )}
      </Grid>
      <Grid item md={2} xs={12}>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={onClickToRight}
            disabled={disableToTransferToRight()}
          >
            <IconTransform left />
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={onClickToLeft}
            disabled={disableToTransferToLeft()}
          >
            <IconTransform left={false} />
          </Button>
        </Grid>
      </Grid>
      <Grid item md={5} xs={12}>
        {withLabel ? (
          <CustomListWithLabel
            handleToggle={handleToggleWithLabel}
            handleToggleAll={handleToggleAllWithLabel}
            numberOfChecked={numberOfCheckedWithLabel}
            title="Selecione"
            items={right}
            checked={checked}
            formatFunction={formatFunction}
          />
        ) : (
          <CustomList
            handleToggle={handleToggle}
            handleToggleAll={handleToggleAll}
            numberOfChecked={numberOfChecked}
            title="Selecione"
            items={right}
            checked={checked}
          />
        )}
      </Grid>
    </Grid>
  )
}

TransferList.propTypes = {
  leftItens: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  rightItens: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  withLabel: PropTypes.bool,
  formatFunction: PropTypes.func,
}

TransferList.defaultProps = {
  leftItens: [],
  rightItens: [],
  withLabel: false,
  formatFunction: (array) => {
    return array
  },
}

export default TransferList
