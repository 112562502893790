import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { Check as CheckIcon, Moon as MoonIcon } from 'react-feather'

import {
  Page,
  Container,
  ContentHeader,
  StatusCard,
  LoadingFeedback,
} from 'components'
import { FormUsers, ChangeStatusButton } from 'components/Users'

import { useFetch } from 'hooks'

import * as service from 'service'
import palette from 'theme/palette'

const CollaboratorsEdit = ({ match }) => {
  const {
    params: { userId },
  } = match

  const { response, isLoading, refresh } = useFetch(
    service.dponet.user.get,
    { userId },
    [],
  )

  const activeUser = response?.data?.user?.active

  return (
    <Page title="Edição de Colaborador">
      <LoadingFeedback open={isLoading} />
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Edição"
          menu="Colaboradores"
          subtitle="Edição de colaborador"
        >
          {!isLoading && (
            <ChangeStatusButton
              user={response?.data?.user}
              isCollaborator
              refresh={refresh}
            />
          )}
        </ContentHeader>
        {!isLoading && (
          <>
            <Box mb={4}>
              <StatusCard
                backgroundIconColor={
                  activeUser ? palette.greenPantone : palette.secondary.light
                }
                icon={
                  activeUser ? (
                    <CheckIcon size={18} color="white" />
                  ) : (
                    <MoonIcon size={18} color="white" />
                  )
                }
                label="STATUS"
                value={activeUser ? 'Ativo' : 'Inativo'}
              />
            </Box>
            <FormUsers
              isEdit
              user={response?.data?.user}
              refreshUser={refresh}
            />
          </>
        )}
      </Container>
    </Page>
  )
}

CollaboratorsEdit.propTypes = {
  match: PropTypes.object,
}

export default CollaboratorsEdit
