import PropTypes from 'prop-types'
import { TableRow, TableCell } from 'components/Table'
import { MenuButton, Permitted } from 'components'
import { Chip, Button } from '@material-ui/core'
import helpers from 'helpers'
import constants from 'constants/index'
import useStyles from './styles'
import clsx from 'clsx'

import { reverse } from 'named-urls'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

const EconomicActivityRow = ({
  activity,
  setSelectedActivity,
  setOpenActivity,
  setIsDestroy,
}) => {
  const classes = useStyles()
  const history = useHistory()

  const handleChange = (activity, isDestroy) => {
    setSelectedActivity(activity)
    setOpenActivity(true)
    setIsDestroy(isDestroy)
  }

  return (
    <>
      <TableRow key={activity?.id}>
        <TableCell>{activity?.id}</TableCell>
        <TableCell>{activity?.name}</TableCell>
        <TableCell align="center">{activity?.session}</TableCell>
        <TableCell align="center">{activity?.division}</TableCell>
        <TableCell>{activity?.group}</TableCell>
        <TableCell>{activity?.classCnae}</TableCell>
        <TableCell disableTooltip>
          <Chip
            className={clsx(classes.root, {
              [classes.active]: activity?.active,
              [classes.inactive]: !activity?.active,
            })}
            align="center"
            size="small"
            label={activity.active ? 'Ativo' : 'Inativo'}
          />
        </TableCell>
        <TableCell align="center">
          {helpers.formatters.date(activity?.inactivateAt) || '-'}
        </TableCell>
        <TableCell disableTooltip align="right">
          <Permitted
            someTags={[
              constants.permissions.ECONOMIC_ACTIVITIES.DESTROY,
              constants.permissions.ECONOMIC_ACTIVITIES.ACTIVATE_OR_INACTIVATE,
              constants.permissions.ECONOMIC_ACTIVITIES.EDIT,
            ]}
          >
            <MenuButton>
              <Permitted tag={constants.permissions.ECONOMIC_ACTIVITIES.EDIT}>
                <Button
                  fullWidth
                  size="small"
                  onClick={() =>
                    history.push(
                      reverse(routes.economicActivities.edit, {
                        activityId: activity?.id,
                      }),
                    )
                  }
                >
                  Editar
                </Button>
              </Permitted>
              <Permitted
                tag={constants.permissions.ECONOMIC_ACTIVITIES.DESTROY}
              >
                <Button
                  fullWidth
                  size="small"
                  onClick={() => handleChange(activity, true)}
                >
                  Excluir
                </Button>
              </Permitted>
              <Permitted
                tag={
                  constants.permissions.ECONOMIC_ACTIVITIES
                    .ACTIVATE_OR_INACTIVATE
                }
              >
                <Button
                  fullWidth
                  size="small"
                  onClick={() => handleChange(activity, false)}
                >
                  {activity?.active ? 'Inativar' : 'Ativar'}
                </Button>
              </Permitted>
            </MenuButton>
          </Permitted>
        </TableCell>
      </TableRow>
    </>
  )
}

EconomicActivityRow.propTypes = {
  activity: PropTypes.object,
  setGetActivity: PropTypes.func,
  setOpenActivity: PropTypes.func,
  setIsDestroy: PropTypes.func,
}

EconomicActivityRow.depaultProps = {
  activity: {},
  setGetActivity: () => {},
  setOpenActivity: () => {},
  setIsDestroy: () => {},
}

export default EconomicActivityRow
