import { useContext } from 'react'

import RmcsListContext from 'contexts/RmcsListContext'

const useRmcsList = () => {
  const context = useContext(RmcsListContext)

  if (context === undefined) {
    throw new Error(
      'useRmcsList must be used within a RmcsListContext.Provider',
    )
  }

  return {
    loadingProvider: context.loadingProvider,
    departments: context.departments,
    users: context.users,
    loadUsers: context.loadUsers,
    loadDepartments: context.loadDepartments,
  }
}

export default useRmcsList
