const ALL_TAB = 0
const ACTIVE_TAB = 1
const INACTIVE_TAB = 2

const ACTIVE = true
const INACTIVE = false

const NEWLY_CREATED = 'newly_created'
const OLDER_CREATED = 'older_created'

const INACTIVATE_DIALOG =
  'Ao inativar este segmento e preço novos clientes não poderão realizar contratações do mesmo, os clientes que já o contrataram não serão afetados, tem certeza que desejar inativar este segmento e preço?'
const ACTIVATE_DIALOG =
  'Ao ativar este segmento e preço novos clientes poderão realizar contratações do mesmo, tem certeza que desejar ativar este segmento e preço?'
const DESTROY_DIALOG =
  'Tem certeza que deseja excluir este segmento e preço? Ao confirmar esta ação nenhum cliente poderá utiliza-lo, está ação é irreversível.'

const TABS = [
  {
    value: ALL_TAB,
    label: 'Todos',
  },
  {
    value: ACTIVE_TAB,
    label: 'Ativos',
  },
  {
    value: INACTIVE_TAB,
    label: 'Inativos',
  },
]

const SPLITS = [
  {
    id: 1,
    name: '1 vez',
  },
  {
    id: 2,
    name: '2 vezes',
  },
  {
    id: 3,
    name: '3 vezes',
  },
  {
    id: 4,
    name: '4 vezes',
  },
  {
    id: 5,
    name: '5 vezes',
  },
  {
    id: 6,
    name: '6 vezes',
  },
  {
    id: 7,
    name: '7 vezes',
  },
  {
    id: 8,
    name: '8 vezes',
  },
  {
    id: 9,
    name: '9 vezes',
  },
  {
    id: 10,
    name: '10 vezes',
  },
  {
    id: 11,
    name: '11 vezes',
  },
  {
    id: 12,
    name: '12 vezes',
  },
]

const plan = {
  TABS,
  ALL_TAB,
  ACTIVE_TAB,
  INACTIVE_TAB,
  INACTIVE,
  ACTIVE,
  NEWLY_CREATED,
  OLDER_CREATED,
  INACTIVATE_DIALOG,
  ACTIVATE_DIALOG,
  DESTROY_DIALOG,
  SPLITS,
}

export default plan
