import PropTypes from 'prop-types'

import useSnackbar from 'hooks/useSnackbar'
import * as service from 'service'

import constants from 'constants/index'

import helpers from 'helpers'

import { ConfirmationDialog } from 'components'

const ReactivateDataProcessDialog = ({
  dataProcessId,
  open,
  setOpen,
  action,
  setLoading,
}) => {
  const snackbar = useSnackbar()

  const handleReactivate = async () => {
    try {
      setLoading(true)
      await service.dponet.dataProcesses.changeStatus({
        dataProcessId: dataProcessId,
        statusId: constants.dataProcess.APPROVED_STATUS,
      })
      snackbar.open({
        message: 'Processamento de dados reativado com sucesso!',
        variant: 'success',
      })
      setOpen(false)
      action()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
      setOpen(false)
    }
    setLoading(false)
  }

  return (
    <ConfirmationDialog
      open={open}
      setOpen={setOpen}
      message="Você tem certeza de que deseja reativar este processo? Ao reativar um processo inativo, você está indicando que a atividade
          de tratamento de dados pessoais voltou a existir na rotina da sua
          empresa."
      cancelButtonText="Cancelar"
      buttonText="Confirmar reativação"
      actionAcceptButton={handleReactivate}
    />
  )
}

ReactivateDataProcessDialog.propTypes = {
  dataProcessId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
  setLoading: PropTypes.func,
}

ReactivateDataProcessDialog.defaultProps = {
  action: () => {},
  setOpen: () => {},
  setLoading: () => {},
}

export default ReactivateDataProcessDialog
