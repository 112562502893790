import constants from 'constants/index'

const chipStatusColor = (status, classes) => {
  switch (status) {
    case constants.privacyPolicy.DRAFT_STATUS_VALUE:
      return classes.draft
    case constants.privacyPolicy.ACTIVE_STATUS_VALUE:
      return classes.active
    default:
      return classes.inactive
  }
}

export default chipStatusColor
