import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ faqsDocumentationId = '', ...params }) => {
  return await dponetAPI.get(`/faqs_documentations/${faqsDocumentationId}`, {
    params,
  })
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('/faqs_documentations', data)
}

const put = async ({ faqsDocumentationId = '', ...data }) => {
  return await dponetAPI.put(
    `/faqs_documentations/${faqsDocumentationId}`,
    data,
  )
}

const destroy = async ({ faqsDocumentationId = '' }) => {
  return await dponetAPI.delete(`/faqs_documentations/${faqsDocumentationId}`)
}

const faqsDocumentations = {
  get,
  create,
  put,
  destroy,
}

export default faqsDocumentations
