const covertDataSourcesToOptions = (dataSources) => {
  return dataSources.map((dataSource) => ({
    id: dataSource.id,
    sourceDescription: dataSource.sourceDescription,
    dataProcessSources: dataSource.dataProcessSources.map((source) => ({
      entityId: source.entityId,
      entityName: source.name || source.entityName,
    })),
  }))
}

export default covertDataSourcesToOptions
