import { useState, createRef, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import useSnackbar from 'hooks/useSnackbar'

import { useHistory, useLocation } from 'react-router-dom'
import { routes } from 'Routes'
import { LoadingFeedback } from 'components'
import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'
import { reverse } from 'named-urls'
import theme from 'theme'

import {
  Chip,
  Card,
  Box,
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
  Button,
  makeStyles,
  Tab,
  Tabs,
  useMediaQuery,
} from '@material-ui/core'
import PropTypes from 'prop-types'

import styles from './styles'
import { Eye as EyeIcon } from 'react-feather'

const useStyles = makeStyles(styles)

const QuestionsReply = ({ questionnaire, refresh }) => {
  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()
  const location = useLocation()
  const { handleSubmit, control, setValue } = useForm()
  const [loading, setLoading] = useState(false)
  const [currentTab, setCurrentTab] = useState('all')
  const detachedId = location?.hash?.substring(1)
  const questionnaireId = questionnaire.id

  const refs = questionnaire.questions.reduce((acc, value) => {
    acc[value.id] = createRef()
    return acc
  }, {})

  useEffect(() => {
    if (!detachedId) {
      return
    }

    if (!refs[detachedId].current) {
      return history.replace()
    }

    refs[detachedId].current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })

    // eslint-disable-next-line
  }, [location])

  const filterActiveQuestion = (question) => question.applicableChoice

  const filterAnsweredQuestion = (question) =>
    question.answer !== 'Não se aplica'

  const [displayQuestions, setDisplayQuestions] = useState(
    questionnaire.activeQuestions.filter(filterActiveQuestion),
  )
  const filterInactiveQuestion = (question) =>
    question.status === constants.question.INACTIVE_STATUS

  const submitAnswers = async (questionnaire) => {
    try {
      setLoading(true)
      await service.dponet.questionnaires.reply({
        questionnaireId,
        questionnaire,
      })
      setLoading(false)
      refresh()
      snackbar.open({
        message: 'Questionário respondido com sucesso',
        variant: 'success',
      })
    } catch (error) {
      setLoading(false)
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
  }

  const onSubmit = async (data) => {
    var questionnaireAnswers = { answers: [] }
    Object.keys(data).map((key) => {
      return questionnaireAnswers.answers.push({
        id: key,
        answer: data[key],
      })
    })

    await submitAnswers(questionnaireAnswers)
  }

  const getCardStyle = (question) => {
    if (detachedId && parseInt(detachedId) === question.id) {
      return classes.detachedBox
    }

    return ''
  }

  const handleChangeTabs = (_, value) => {
    setCurrentTab(value || constants.question.ALL_VALUE)
    switch (value) {
      case constants.question.PENDING_VALUE:
        return setDisplayQuestions(questionnaire.pendingQuestions)

      case constants.question.ANSWERED_VALUE:
        return setDisplayQuestions(
          questionnaire.answeredQuestions.filter(filterAnsweredQuestion),
        )

      case constants.question.INACTIVE_VALUE:
        return setDisplayQuestions(
          questionnaire.inactiveQuestions.filter(filterInactiveQuestion),
        )

      default:
        return setDisplayQuestions(
          questionnaire.activeQuestions.filter(filterActiveQuestion),
        )
    }
  }

  const getBadgeValue = (tagValue) => {
    switch (tagValue) {
      case constants.question.PENDING_VALUE:
        return questionnaire.pendingQuestions.length
      case constants.question.ANSWERED_VALUE:
        return questionnaire.answeredQuestions.filter(filterAnsweredQuestion)
          .length
      case constants.question.INACTIVE_VALUE:
        return questionnaire.inactiveQuestions.filter(filterInactiveQuestion)
          .length
      default:
        return questionnaire.activeQuestions.filter(filterActiveQuestion).length
    }
  }

  // const redirectToRmc = (nonComplianceReport) => {
  //   history.push(
  //     reverse(routes.nonComplianceReport.show, {
  //       nonComplianceReportId: nonComplianceReport.id,
  //     }),
  //   )
  // }

  const isActive = useMediaQuery(theme.breakpoints.up('md'))
  const flagResponsive = () => {
    return isActive ? 'flex-end' : 'flex-start'
  }

  return (
    <>
      <LoadingFeedback open={loading} />
      <Tabs
        scrollButtons="auto"
        onChange={handleChangeTabs}
        textColor="primary"
        indicatorColor="primary"
        value={currentTab}
        variant="standard"
      >
        {constants.question.TABS.map((tab) => (
          <Tab
            key={tab.value}
            value={tab.value}
            label={
              <Box>
                {tab.label} ({getBadgeValue(tab.value)})
              </Box>
            }
          />
        ))}
      </Tabs>
      <Card>
        <Box pb={3}>
          {!!displayQuestions && displayQuestions.length !== 0 ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              {displayQuestions.map((question, index) => (
                <Box key={question.id}>
                  <Box
                    mx={3}
                    my={2}
                    id={question.id}
                    ref={refs[question.id]}
                    className={getCardStyle(question)}
                  >
                    <Grid container>
                      <Grid item md={9} sm={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant="h5">
                              {index + 1}. {question.theme}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Box width="100%" mt={1} ml={2}>
                              <Typography variant="body2">
                                {question.question}
                              </Typography>
                            </Box>
                          </Grid>
                          {question.status !==
                            constants.question.INACTIVE_STATUS && (
                            <Grid item sm={6} xs={12}>
                              <Box width="100%" mt={1} ml={2}>
                                <Controller
                                  control={control}
                                  name={question.id.toString()}
                                  defaultValue={question.answer}
                                  as={
                                    <RadioGroup row>
                                      <FormControlLabel
                                        value="Sim"
                                        control={<Radio color="primary" />}
                                        label="Sim"
                                      />
                                      <FormControlLabel
                                        value="Não"
                                        control={<Radio color="primary" />}
                                        label="Não"
                                      />
                                      <FormControlLabel
                                        value="Não se aplica"
                                        control={<Radio color="primary" />}
                                        label="Não se aplica"
                                      />
                                    </RadioGroup>
                                  }
                                  onChange={([event]) => {
                                    setValue(
                                      event.target.name,
                                      event.target.value,
                                    )
                                    return event
                                  }}
                                />
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item md={3} sm={12}>
                        <Box
                          width="100%"
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                          alignItems={flagResponsive()}
                          height="100%"
                          ml={2}
                          pr={2}
                        >
                          <Chip
                            size="small"
                            className={helpers.questions.statusColor(
                              question.status,
                              classes,
                            )}
                            label={helpers.questions.statusLabel(
                              question.status,
                            )}
                          />
                          {question?.questionControl && (
                            <Box mt={2}>
                              <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<EyeIcon size={14} />}
                                disableElevation
                                type="button"
                                disabled
                                onClick={
                                  () => {}
                                  // redirectToRmc(question.questionControl?.id)
                                }
                              >
                                Visualizar Controle
                                {/* Ainda não existe a tela*/}
                              </Button>
                            </Box>
                          )}
                          {question.user && question.answer && (
                            <Box mt={2}>
                              <Typography variant="body2">
                                Respondido por: {question.user.name}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                      {question.status ===
                        constants.question.INACTIVE_STATUS && (
                        <Grid xs={12}>
                          <Box
                            width="100%"
                            mt={1}
                            p={1}
                            className={classes.inactivateReasonBox}
                          >
                            <Box ml={1}>
                              <Typography variant="h6">
                                Motivo da inativação
                              </Typography>
                            </Box>
                            <Box mt={1} ml={1}>
                              <Typography variant="body2">
                                {question?.reason}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                  <Divider />
                </Box>
              ))}
              <Box ml={2} mt={2} display="flex" justifyContent="flex-start">
                <Box pr={1}>
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      history.push(routes.questionnaires.all)
                    }}
                  >
                    Voltar
                  </Button>
                </Box>
                <Box>
                  <Button color="primary" type="submit" variant="contained">
                    Salvar
                  </Button>
                </Box>
              </Box>
            </form>
          ) : (
            <Box mx={4} mt={3}>
              <Typography variant="body1">
                Não existe nenhuma questão com este status.
              </Typography>
            </Box>
          )}
        </Box>
      </Card>
    </>
  )
}

QuestionsReply.propTypes = {
  questionnaire: PropTypes.object,
  refresh: PropTypes.func,
}

QuestionsReply.defaultProps = {
  refresh: () => {},
}

export default QuestionsReply
