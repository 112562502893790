import status from './status'
import statusColor from './statusColor'
import textStatusColor from './textStatusColor'
import fragilityColor from './fragilityColor'
import textFragilityColor from './textFragilityColor'
import possibleMinor from './possibleMinor'
import fragilityLabel from './fragilityLabel'
import formatLegalFrameworksFilterOptions from './formatLegalFrameworksFilterOptions'
import mountTitle from './mountTitle'
import selectVolumetryOptions from './selectVolumetryOptions'
import getTextsSteps from './getTextsSteps'
import statusIcon from './statusIcon'
import statusLabel from './statusLabel'
import statusesCheck from './statusesCheck'
import formatPayloadEdit from './formatPayloadEdit'
import legalFrameworkLabelByDataType from './legalFrameworkLabelByDataType'
import legalFrameworkValueByDataType from './legalFrameworkValueByDataType'
import orderLegalFrameworks from './orderLegalFrameworks'
import labelDataProcessSourcesTable from './labelDataProcessSourcesTable'
import iconDataProcessSources from './iconDataProcessSources'
import convertSourceDescription from './convertSourceDescription'
import convertSourceKind from './convertSourceKind'
import labelSource from './labelSource'
import mountDataProcessSources from './mountDataProcessSources'
import selectSourceOptions from './selectSourceOptions'
import covertDataSourcesToOptions from './covertDataSourcesToOptions'
import convertSourceDescriptionToText from './convertSourceDescriptionToText'
import convertSourceDescriptionToSource from './convertSourceDescriptionToSource'
import getSourceFilterLabel from './getSourceFilterLabel'
import noDataMessage from './noDataMessage'
import storageModeType from './storageModeType'
import numberGrid from './numberGrid'
import retentionFinality from './retentionFinality'
import discardMode from './discardMode'
import storageTimeValidation from './storageTimeValidation'

const dataProcess = {
  status,
  statusColor,
  textStatusColor,
  formatPayloadEdit,
  fragilityColor,
  fragilityLabel,
  textFragilityColor,
  possibleMinor,
  formatLegalFrameworksFilterOptions,
  mountTitle,
  selectVolumetryOptions,
  getTextsSteps,
  statusIcon,
  statusLabel,
  statusesCheck,
  legalFrameworkLabelByDataType,
  legalFrameworkValueByDataType,
  orderLegalFrameworks,
  labelDataProcessSourcesTable,
  iconDataProcessSources,
  convertSourceDescription,
  labelSource,
  convertSourceKind,
  mountDataProcessSources,
  selectSourceOptions,
  covertDataSourcesToOptions,
  convertSourceDescriptionToText,
  convertSourceDescriptionToSource,
  getSourceFilterLabel,
  noDataMessage,
  storageModeType,
  numberGrid,
  retentionFinality,
  discardMode,
  storageTimeValidation,
}

export default dataProcess
