import permissions from '../../permissions'

const RMC_RESUME_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.SHOW,
    tag: permissions.NON_COMPLIANCE_REPORT.RESUME,
  },
]

export default RMC_RESUME_LIST
