import Main from './SuppliersMain'
import New from './SuppliersNew'
import Show from './SuppliersShow'

const Subgroups = {
  Main,
  New,
  Show,
}

export default Subgroups
