import permissions from '../../permissions'

const PRE_REGISTRATION_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.CREATE,
    tag: permissions.PRE_REGISTRATIONS.CREATE,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.PRE_REGISTRATIONS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.DESTROY,
    tag: permissions.PRE_REGISTRATIONS.DESTROY,
  },
]

export default PRE_REGISTRATION_LIST
