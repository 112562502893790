import PropTypes from 'prop-types'
import { Divider, Box } from '@material-ui/core'

import {
  DataCollected,
  DataTreatment,
  GeneralInformation,
  DataLifeCycle,
  PaperHeader,
  DataTreatmentAgent,
} from '../Show'
import { LoadingBox } from 'components'
import { Description, ShowNecessityProportionality } from '../'

import { useDataProcessesCache } from 'hooks/caches'
import RmcProvider from 'providers/RmcProvider'
import DataCollectedModalProvider from 'providers/DataCollectedModalProvider'
import DocumentsProvider from 'providers/DocumentsProvider'

import useStyles from '../../styles'

const PaperBody = ({ isLoading, dataProcess, origin }) => {
  return (
    <>
      <PaperHeader data={dataProcess} isLoading={isLoading} />
      <GeneralInformation
        data={dataProcess}
        isLoading={isLoading}
        origin={origin}
      />
      <Divider />
      <Description dataProcess={dataProcess} />
      <Divider />
      <DocumentsProvider documents={dataProcess.documents}>
        <DataCollectedModalProvider dataCollecteds={dataProcess.dataCollecteds}>
          <DataCollected
            dataCollecteds={dataProcess.dataCollecteds}
            isLoading={isLoading}
            dataProcessId={dataProcess.id}
          />
        </DataCollectedModalProvider>
      </DocumentsProvider>
      <Divider />
      <DataLifeCycle data={dataProcess} isLoading={isLoading} />
      <Divider />
      <DataTreatment
        isLoading={isLoading}
        dataTreatments={dataProcess.dataTreatments}
      />
      <DataTreatmentAgent
        dataTreatmentAgents={dataProcess?.dataTreatmentAgents}
        isLoading={isLoading}
      />
      <RmcProvider>
        <ShowNecessityProportionality data={dataProcess} />
      </RmcProvider>
      <Divider />
    </>
  )
}

const PaperShowDataProcess = ({ dataProcessId }) => {
  const classes = useStyles()
  const dataProcessesCache = useDataProcessesCache()

  const dataProcessResponse = dataProcessesCache.useView(dataProcessId)
  const dataProcess = dataProcessResponse.data?.dataProcess

  return (
    <>
      {dataProcessResponse.isFetching ? (
        <LoadingBox />
      ) : (
        <Box className={classes.boxHeight}>
          <PaperBody
            isLoading={dataProcessResponse.isFetching}
            dataProcess={dataProcess}
          />
        </Box>
      )}
    </>
  )
}

PaperBody.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  dataProcess: PropTypes.object.isRequired,
  origin: PropTypes.string,
}

PaperShowDataProcess.propTypes = {
  dataProcessId: PropTypes.number,
}

export default PaperShowDataProcess
