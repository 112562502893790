import { useState } from 'react'

import { Button } from '@material-ui/core'
import { ConfirmationDialog, Permitted } from 'components'

import constants from 'constants/index'
import helpers from 'helpers'

import PropTypes from 'prop-types'
import * as service from 'service'

import useSnackbar from 'hooks/useSnackbar'

const RemoveDocumentationLink = ({ documentationLinkId, refresh }) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const snackbar = useSnackbar()
  const deleteMessage = 'Deseja realmente excluir o link de ajuda?'

  const handleDelete = async () => {
    try {
      await service.dponet.documentationLinks.destroy({ documentationLinkId })

      refresh()

      snackbar.open({
        message: 'Link de ajuda excluido com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        variant: 'error',
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Erro ao excluir o link de ajuda!',
      })
    }
    setOpenDeleteDialog(false)
  }

  return (
    <>
      <Permitted tag={constants.permissions.DOCUMENTATION_LINKS.DESTROY}>
        <Button
          fullWidth
          size="small"
          onClick={() => setOpenDeleteDialog(true)}
        >
          Excluir
        </Button>
      </Permitted>
      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        message={deleteMessage}
        cancelButtonText="Cancelar"
        buttonText="Excluir"
        actionAcceptButton={handleDelete}
      />
    </>
  )
}

RemoveDocumentationLink.propTypes = {
  documentationLinkId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  refresh: PropTypes.func,
}

RemoveDocumentationLink.defaultProps = {
  refresh: () => {},
}

export default RemoveDocumentationLink
