import chapterCover from './chapterCover'
import firstCover from './firstCover'
import lastCover from './lastCover'

const components = {
  chapterCover,
  firstCover,
  lastCover,
}

export default components
