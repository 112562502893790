import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box } from '@material-ui/core'

import SegmentsTableRow from '../SegmentsTableRow'
import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'

const SegmentsTable = ({ segments, refresh }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhum segmento encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="30%" align="center">
                Nome
              </TableCell>
              <TableCell width="20%" align="left">
                Subgrupo
              </TableCell>
              <TableCell width="20%" align="center">
                Status
              </TableCell>
              <TableCell width="20%" align="center">
                Inativo em
              </TableCell>
              <TableCell width="5%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {segments.map((segment) => (
              <SegmentsTableRow
                refresh={refresh}
                segment={segment}
                key={segment.id}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

SegmentsTable.propTypes = {
  segments: PropTypes.arrayOf(Object),
  refresh: PropTypes.func,
}

SegmentsTable.defaultProps = {
  refresh: () => {},
}

export default SegmentsTable
