import PropTypes from 'prop-types'

import { useState, useEffect } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { useFormContext, Controller } from 'react-hook-form'

import {
  Box,
  Typography,
  MenuItem,
  TextField,
  Checkbox,
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'

import helpers from 'helpers'

import { useDataProcess } from 'hooks'

const TableNecPropDataTreatments = ({ dataTreatments, editType }) => {
  const { control, errors, setValue } = useFormContext()

  const [checked, setChecked] = useState(false)

  const { setNecDataTreatments } = useDataProcess()

  const handleChangeDataTreatment = (value, dataTreatment) => {
    const dataTreatmentIndex = dataTreatments.indexOf(dataTreatment)

    dataTreatments[dataTreatmentIndex].necessary =
      helpers.formatters.convertBinaryToBool(value)

    setNecDataTreatments(dataTreatments)
  }

  useEffect(() => {
    dataTreatments.map((dataTreatment) => {
      if (checked) {
        setValue(`dataTreatment-${dataTreatment.id}`, 1)
        return dataTreatment
      } else {
        setValue(
          `dataTreatment-${dataTreatment.id}`,
          helpers.formatters.convertBoolToBinary(dataTreatment.necessary),
        )
        return dataTreatment
      }
    })
    //eslint-disable-next-line
  }, [checked])

  return (
    <>
      {!editType && (
        <>
          <Box width="100%" mb={2}>
            <Typography variant="h6">Compartilhamento de Dados</Typography>
          </Box>
          <Box
            width="100%"
            mb={2}
            justifyContent="space-between"
            display={editType ? 'inherit' : 'flex'}
            alignItems="flex-end"
          >
            <Box>
              <Typography variant="body1">
                Os compartilhamentos abaixo foram indicados como tratados neste
                registro.{' '}
              </Typography>
              <Typography variant="body1">
                Analise quais compartilhamentos são necessários para a
                finalidade do tratamento.
              </Typography>
              <Typography variant="body1">
                {' '}
                Para os compartilhamentos necessários responda “Sim”.
              </Typography>
              <Typography variant="body1">
                Para os compartilhamentos que podem deixar de ser realizados sem
                prejudicar a finalidade do tratamento, responda com “Não”.
              </Typography>
            </Box>

            <Box padding="checkbox">
              <Box
                justifyContent={editType ? 'flex-end' : 'space-between'}
                display="flex"
                alignItems="center"
              >
                <Box fontWeight="fontWeightBold">
                  <Typography>
                    Deseja selecionar <u>todos</u> com SIM?
                  </Typography>
                </Box>
                <Checkbox
                  type="checkbox"
                  onChange={() => {
                    setChecked(!checked)
                  }}
                />
              </Box>
            </Box>
          </Box>
        </>
      )}
      <PerfectScrollbar>
        <Box width="100%">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell width="20%">Com quem</TableCell>
                <TableCell width="20%">Por que</TableCell>
                <TableCell width="20%">Como</TableCell>
                <TableCell width="15%">Tipo</TableCell>
                <TableCell width="25%">
                  Considera esse compartilhamento necessário?
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {dataTreatments?.map((dataTreatment) => (
                <TableRow key={dataTreatment.id}>
                  <TableCell>{dataTreatment?.shareProcess?.name}</TableCell>
                  <TableCell>{dataTreatment.description}</TableCell>
                  <TableCell>{dataTreatment.controllMechanismView}</TableCell>
                  <TableCell>{dataTreatment?.shareType?.name}</TableCell>
                  <TableCell disableTooltip>
                    <Box my={1}>
                      <Controller
                        as={
                          <TextField
                            label="Necessário?"
                            select
                            color="primary"
                            variant="outlined"
                            error={
                              !!errors[`dataTreatment-${dataTreatment?.id}`]
                            }
                            helperText={
                              errors &&
                              errors[`dataTreatment-${dataTreatment?.id}`] &&
                              'Preencha este campo'
                            }
                            fullWidth
                          >
                            <MenuItem key="sim" value={1}>
                              Sim
                            </MenuItem>
                            <MenuItem key="não" value={0}>
                              Não
                            </MenuItem>
                          </TextField>
                        }
                        name={`dataTreatment-${dataTreatment?.id}`}
                        defaultValue={helpers.formatters.convertBoolToBinary(
                          dataTreatment.necessary,
                        )}
                        rules={{ required: true }}
                        control={control}
                        onChange={([event]) => {
                          handleChangeDataTreatment(
                            event.target.value,
                            dataTreatment,
                          )
                          return event.target.value
                        }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </>
  )
}

TableNecPropDataTreatments.propTypes = {
  dataTreatments: PropTypes.arrayOf(Object).isRequired,
  editType: PropTypes.bool,
}

TableNecPropDataTreatments.defaultValue = {
  editType: false,
}

export default TableNecPropDataTreatments
