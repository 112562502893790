import { Page, Container, ContentHeader } from 'components'
import { FormUsers } from 'components/Users'

const CollaboratorsNew = () => {
  return (
    <Page title="Novo Colaborador">
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Criação"
          menu="Colaboradores"
          subtitle="Criação de colaborador"
        ></ContentHeader>
        <FormUsers />
      </Container>
    </Page>
  )
}
export default CollaboratorsNew
