import propTypes from 'prop-types'

import {
  Box,
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'

import useRmc from 'hooks/useRmc'

import { Database as DatabaseIcon } from 'react-feather'

import helpers from 'helpers'

import constants from 'constants/index'

const ListItemCustom = ({ label, classes, dataType, unnecessaryData }) => {
  const { isEditableByFinality } = useRmc()

  return (
    <ListItem divider>
      <Grid container>
        <Grid item xs={12} md={isEditableByFinality ? 8 : 12}>
          <Box display="flex">
            <ListItemAvatar>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                className={classes.avatarContainer}
              >
                <DatabaseIcon className={classes.iconStyle} />
              </Box>
            </ListItemAvatar>
            <Box>
              <ListItemText primary={label} />
              <ListItemText
                primary={
                  isEditableByFinality
                    ? '-'
                    : helpers.nonComplianceReports.eliminateNotEliminateSubtitleMessages(
                        dataType,
                        unnecessaryData?.status,
                      )
                }
                primaryTypographyProps={{
                  color: 'secondary',
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ListItem>
  )
}

ListItemCustom.propTypes = {
  unnecessaryData: propTypes.object.isRequired,
  label: propTypes.string.isRequired,
  classes: propTypes.object,
  dataType: propTypes.oneOf([
    constants.unnecessaryData.COLLECTED_FIELD_TYPE,
    constants.unnecessaryData.DATA_TREATMENT_TYPE,
    constants.unnecessaryData.LIFE_CYCLES_TYPE,
  ]),
  setButtonIsDisabled: propTypes.func,
}

ListItemCustom.defaultProps = {
  setButtonIsDisabled: () => {},
}

export default ListItemCustom
