import permissions from '../../permissions'

const LEGAL_FRAMEWORK_LIST = [
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.LIST,
    tag: permissions.LEGAL_FRAMEWORKS.LIST,
  },
  {
    name: permissions.PERMISSIONS_DEFAULT_LABEL.EDIT,
    tag: permissions.LEGAL_FRAMEWORKS.EDIT,
  },
]

export default LEGAL_FRAMEWORK_LIST
