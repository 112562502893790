import { makeStyles } from '@material-ui/core'

const styles = makeStyles(() => ({
  selectProfileBox: {
    background: '#FFF',
  },
  link: {
    cursor: 'pointer',
  },
}))

export default styles
