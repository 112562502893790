import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  statusBox: {
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  waitingReview: {
    backgroundColor: theme.colors.dataProcess.backgroundStatuses.waitingReview,
  },
  reviewing: {
    backgroundColor: theme.colors.dataProcess.backgroundStatuses.reviewing,
  },
}))

export default styles
