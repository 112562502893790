import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { Box } from '@material-ui/core'

import SubgroupTableRow from '../SubgroupTableRow'
import PerfectScrollbar from 'react-perfect-scrollbar'

import PropTypes from 'prop-types'

const SubgroupTable = ({ subgroups, refresh }) => {
  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhum subgrupo encontrado" noWrap>
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="30%" align="left">
                Nome
              </TableCell>
              <TableCell width="30%" align="left">
                Grupo
              </TableCell>
              <TableCell width="10%" align="left">
                Status
              </TableCell>
              <TableCell width="20%" align="center">
                Inativo em
              </TableCell>
              <TableCell width="5%" align="left">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {subgroups.map((subgroup) => (
              <SubgroupTableRow
                refresh={refresh}
                subgroup={subgroup}
                key={subgroup.id}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

SubgroupTable.propTypes = {
  subgroups: PropTypes.arrayOf(Object),
  refresh: PropTypes.func,
}

SubgroupTable.defaultProps = {
  refresh: () => {},
}

export default SubgroupTable
