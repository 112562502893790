import PropTypes from 'prop-types'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  Button,
  Card,
} from '@material-ui/core'

import PerfectScrollbar from 'react-perfect-scrollbar'
import { TableCollectedFields } from '..'

import useStyles from './styles'

const DialogCollectedFields = ({ open, setOpen, collectedFields }) => {
  const classes = useStyles()

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box>
          <Typography variant="h6">Visualizar dados tratados</Typography>
        </Box>
      </DialogTitle>
      <Box mx={1}>
        <DialogContent dividers>
          <Card>
            <PerfectScrollbar>
              <TableCollectedFields collectedFields={collectedFields} />
            </PerfectScrollbar>
          </Card>
        </DialogContent>
      </Box>
      <DialogActions p={2}>
        <Box pr={3} width="100%" display="flex" justifyContent="flex-end">
          <Button
            type="button"
            variant="contained"
            className={classes.closeButton}
            onClick={() => setOpen(false)}
          >
            FECHAR
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

DialogCollectedFields.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  collectedFields: PropTypes.array.isRequired,
}

DialogCollectedFields.defaultProps = {
  setOpen: () => {},
}

export default DialogCollectedFields
