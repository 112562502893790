import constants from 'constants/index'

const statusLabel = (status) => {
  switch (status) {
    case constants.questionnaire.ANSWERED_STATUS:
      return constants.questionnaire.ANSWERED_LABEL
    case constants.questionnaire.AWAITING_RESPONSE_STATUS:
      return constants.questionnaire.AWAITING_RESPONSE_LABEL
    case constants.questionnaire.PARTIALLY_ANSWERED_STATUS:
      return constants.questionnaire.PARTIALLY_ANSWERED_LABEL
    default:
      return ''
  }
}

export default statusLabel
