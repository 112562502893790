import companyList from './companyList'
import companySupplierList from './companySupplierList'
import dashboardList from './dashboardList'
import dataProcessList from './dataProcessList'
import departmentList from './departmentList'
import documentList from './documentList'
import documentTemplateList from './documentTemplateList'
import incidentList from './incidentList'
import nonComplianceReportList from './nonComplianceReportList'
import privacyPolicyList from './privacyPolicyList'
import profileList from './profileList'
import questionnaireList from './questionnaireList'
import ripdList from './ripdList'
import userList from './userList'
import lastUpdatesList from './lastUpdateList'
import diagnosticsList from './diagnosticsList'

const clientProfileLists = {
  companyList,
  companySupplierList,
  dashboardList,
  dataProcessList,
  departmentList,
  documentList,
  documentTemplateList,
  incidentList,
  nonComplianceReportList,
  privacyPolicyList,
  profileList,
  questionnaireList,
  ripdList,
  userList,
  lastUpdatesList,
  diagnosticsList,
}

export default clientProfileLists
