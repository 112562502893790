import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Box, Button, TextField, Grid } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'

import {
  Card,
  LoadingBox,
  Select as SelectComponent,
  BackButton,
} from 'components'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import helpers from 'helpers'
import schema from './schema'

import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

const SubgroupsForm = ({ subgroup, isEdit }) => {
  const [loading, setLoading] = useState(false)
  const [groupData, setGroupData] = useState(false)

  const history = useHistory()
  const snackbar = useSnackbar()

  const { control, handleSubmit, errors, reset } = useForm({
    validationSchema: schema(),
    defaultValues: {
      name: subgroup?.name || '',
      groupId: subgroup?.group.id || '',
      active: subgroup ? subgroup?.active?.toString() : '',
    },
  })

  useEffect(() => {
    const getGroups = async () => {
      const response = await service.dponet.groups.get({
        perPage: 100000,
      })

      setGroupData(response?.data?.groups)
    }
    //eslint-disable-next-line
    getGroups()
  }, [])

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      if (isEdit) {
        await service.dponet.subgroups.put({
          subgroupId: subgroup?.id,
          subgroup: data,
        })
      } else {
        await service.dponet.subgroups.post({ subgroup: data })
      }

      snackbar.open({
        message: `Subgrupo ${isEdit ? 'atualizado' : 'criado'} com sucesso!`,
        variant: 'success',
      })
      history.push(routes.subgroups.all)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || `Falha ao ${isEdit ? 'atualizar' : 'criar'} subgrupo!`,
        variant: 'error',
      })
      setLoading(false)
      reset(subgroup)
    }
  }

  return (
    <>
      {loading ? (
        <LoadingBox />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card title="Preencha os dados do subgrupo">
            <Box py={1}>
              <Grid container style={{ gap: 20 }}>
                <Grid item xs={12}>
                  <Controller
                    as={
                      <TextField
                        label="Nome do subgrupo"
                        type="text"
                        color="primary"
                        variant="outlined"
                        error={!!errors.name}
                        helperText={errors?.name?.message}
                        fullWidth
                      />
                    }
                    control={control}
                    name="name"
                    mode="onBlur"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    as={
                      <SelectComponent
                        items={groupData || []}
                        label="Grupo"
                        error={!!errors.groupId}
                        fullWidth
                        helperText={errors?.groupId && 'Preencha este campo'}
                      />
                    }
                    control={control}
                    name="groupId"
                    mode="onBlur"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    as={
                      <SelectComponent
                        items={[
                          { id: 'true', name: 'Ativo' },
                          { id: 'false', name: 'Inativo' },
                        ]}
                        label="Status"
                        error={!!errors.active}
                        fullWidth
                        helperText={errors?.active && 'Preencha este campo'}
                      />
                    }
                    control={control}
                    name="active"
                    mode="onBlur"
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Box mt={3} display="flex" justifyContent="flex-start">
            <Box pr={1}>
              <BackButton onClick={() => history.push(routes.subgroups.all)}>
                Voltar
              </BackButton>
            </Box>
            <Box>
              <Button color="primary" variant="contained" type="submit">
                Salvar
              </Button>
            </Box>
          </Box>
        </form>
      )}
    </>
  )
}

SubgroupsForm.propTypes = {
  subgroup: PropTypes.object,
}

export default SubgroupsForm
