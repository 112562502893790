import Main from './UserProfilesMain'
import New from './UserProfilesNew'
import Edit from './UserProfilesEdit'

const UserProfiles = {
  Main,
  New,
  Edit,
}

export default UserProfiles
