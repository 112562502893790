import * as yup from 'yup'

const profileSchema = yup
  .number()
  .required('Selecione um perfil')
  .typeError('Selecione um perfil')

const schemaUser = (userClient, isEdit, profileResponsible) =>
  yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    ...(userClient &&
      !isEdit && {
        companyId: yup.object().required('Selecione uma empresa').nullable(),
        profileId: profileSchema,
      },
    profileResponsible && {
      phone: yup.string().required(),
    }),
    ...(!userClient && {
      profileId: profileSchema,
      phone: yup.string().nullable(),
    }),
  })

export default schemaUser
