import { Button, Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { ContentHeader, Permitted, FilterButton } from 'components'

import constants from 'constants/index'
import { routes } from 'Routes'

const ContentHeaderMain = ({ filter }) => {
  const history = useHistory()
  const handleGoBack = () => history.push(routes.questionnaires.copy)

  return (
    <ContentHeader
      menu="Questionários"
      title="Todos os questionários"
      subtitle="Todos os questionários"
      filter={filter}
    >
      <Permitted tag={constants.permissions.QUESTIONNAIRE_BY_COMPANY.COPY}>
        <Box mx={1}>
          <Button variant="contained" color="primary" onClick={handleGoBack}>
            COPIAR
          </Button>
        </Box>
      </Permitted>
      <FilterButton setDrawerOpen={filter.setDrawerOpen} />
    </ContentHeader>
  )
}

export default ContentHeaderMain
