import constants from 'constants/index'

const statusLabel = (status) => {
  switch (status) {
    case constants.question.ANSWERED_STATUS:
      return constants.question.ANSWERED_LABEL
    case constants.question.PENDING_STATUS:
      return constants.question.PENDING_LABEL
    case constants.question.INACTIVE_STATUS:
      return constants.question.INACTIVE_LABEL
    default:
      return ''
  }
}

export default statusLabel
