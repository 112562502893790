import * as service from 'service'

const mountUsersParams = (userInputValue, filters) => {
  const defaultParams = {
    minimal: true,
    perPage: 1000 * 1000,
  }

  if (!!userInputValue) {
    return {
      name: userInputValue,
      ...defaultParams,
    }
  }

  return {
    id: filters?.userId,
    ...defaultParams,
  }
}

const loadUsers = async (params, setIsLoading, setUsers) => {
  setIsLoading(true)

  const response = await service.dponet.user.get(params)
  const responseUsers = response.data?.users || []

  setUsers(responseUsers)

  setIsLoading(false)

  return responseUsers
}

const instructionsText = (usersInputValue) => {
  return usersInputValue.length < 3
    ? 'Digite no mínimo 3 letras pra buscar'
    : 'Sem opções'
}

const typeAndSearch = {
  loadUsers,
  instructionsText,
  mountUsersParams,
}

export default typeAndSearch
