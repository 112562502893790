import palette from 'theme/palette'

import { logowhite } from '../assets/logoWhite64'
import { logoBlue } from '../assets/logoBlue64'

import {
  WIDTH as WIDTHPdf,
  HEIGHT as HEIGHTPdf,
  fontSize,
  PADDING,
} from '../constants'

import { ratio } from '../helpers'

const firstCover = (pdf, companyName, document, date, reportName) => {
  const WIDTH = WIDTHPdf(pdf)
  const HEIGHT = HEIGHTPdf(pdf)

  pdf.setFillColor(palette.BlueDownDark)
  pdf.rect(0, 0, WIDTH, HEIGHT, 'F')

  pdf.addImage(logowhite, 'png', PADDING, PADDING - 10, ratio(500), ratio(158))
  pdf.setFontSize(fontSize.documentTitle)
  pdf.setLineHeightFactor(1.2)
  pdf.setFont('Raleway-Bold')
  var splited_title = pdf.splitTextToSize(reportName, ratio(1700))
  pdf.setTextColor(palette.white)
  pdf.text(splited_title, PADDING, ratio(600), null, null, 'left')

  pdf.setFontSize(fontSize.subtitle)
  pdf.setFont('Raleway-Regular')
  var splited_name = pdf.splitTextToSize(companyName, WIDTH - PADDING * 3)

  pdf.text(splited_name, PADDING, ratio(1940))
  pdf.text(document, PADDING, ratio(2000 + 50 * splited_name.length))
  pdf.rect(PADDING, HEIGHT - ratio(374), WIDTH - PADDING * 2, ratio(8), 'F')
  pdf.text(date, WIDTH / 2, HEIGHT - PADDING, null, null, 'center')

  pdf.addPage()

  pdf.setFillColor(palette.white)
  pdf.rect(0, 0, WIDTH, HEIGHT, 'F')

  pdf.addImage(
    logoBlue,
    'png',
    WIDTH / 2 - PADDING * 1.25,
    PADDING - 10,
    ratio(500),
    ratio(158),
  )
  pdf.setFontSize(fontSize.documentTitle)
  pdf.setLineHeightFactor(1.2)
  pdf.setFont('Raleway-Bold')
  splited_title = pdf.splitTextToSize(reportName, ratio(1700))
  pdf.setTextColor(palette.BlueDownDark)
  pdf.text(splited_title, WIDTH / 2, ratio(600), null, null, 'center')

  pdf.setFontSize(fontSize.subtitle)
  pdf.setFont('Raleway-Regular')

  splited_name = pdf.splitTextToSize(companyName, ratio(1024))

  pdf.text(splited_name, WIDTH / 2, ratio(1940), null, null, 'center')
  pdf.text(
    document,
    WIDTH / 2,
    ratio(2000 + 50 * splited_name.length),
    null,
    null,
    'center',
  )
  pdf.text(date, WIDTH / 2, HEIGHT - PADDING, null, null, 'center')
}

export default firstCover
