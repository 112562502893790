import constants from 'constants/index'

const serverAllocationType = (type) => {
  switch (type) {
    case constants.sharedSoftware.NATIONAL_SERVER_ALLOCATION_TYPE:
      return constants.sharedSoftware.NATIONAL_SERVER_ALLOCATION_LABEL
    case constants.sharedSoftware.INTERNATIONAL_SERVER_ALLOCATION_TYPE:
      return constants.sharedSoftware.INTERNATIONAL_SERVER_ALLOCATION_LABEL
    case constants.sharedSoftware.BOTH_SERVER_ALLOCATION_TYPE:
      return constants.sharedSoftware.BOTH_SERVER_ALLOCATION_LABEL
    default:
      return 'Não informado'
  }
}

export default serverAllocationType
