const STATUSES = {
  ALL_SORTS: 0,
  INACTIVE: 1,
  EVALUATION: 2,
  ONBOARDING: 3,
  ACTIVE: 4,
  SUSPENDED: 5,
  MAINTENANCE: 6,
  INDICATED: 7,
  TRIAL_LIMITED: 8,
  TRIAL_CANCELED: 9,
  TEST: 10,
  TRIAL_COMPLETE: 11,
}

const STATUSES_LABELS = {
  1: 'INATIVA',
  2: 'EM AVALIAÇÃO',
  3: 'ONBOARDING',
  4: 'ATIVA',
  5: 'SUSPENSA',
  6: 'EM MANUTENÇÃO',
  7: 'INDICADA',
  8: 'GRATUITO LIMITADO',
  9: 'GRATUIDADE CANCELADA',
  10: 'TESTE',
  11: 'GRATUITO COMPLETO',
}

const STATUSES_OBJECT = [
  {
    id: 1,
    name: 'Inativa',
  },
  {
    id: 2,
    name: 'Em avaliação',
  },
  {
    id: 3,
    name: 'Onboarding',
  },
  {
    id: 4,
    name: 'Ativa',
  },
  {
    id: 5,
    name: 'Suspensa',
  },
  {
    id: 6,
    name: 'Em manutenção',
  },
  {
    id: 7,
    name: 'Indicada',
  },
  {
    id: 8,
    name: 'Gratuidade',
  },
  {
    id: 9,
    name: 'Gratuidade cancelada',
  },
  {
    id: 10,
    name: 'Teste',
  },
]

const DEMO_PARTNER_DPONET_TRIAL_KIND = 'DPONET_TRIAL'
const DEMO_PARTNER_ANPPD_KIND = 'ANPPD'

const KIND_COMPANY = 'C'
const KIND_PERSONAL = 'P'
const KIND_TEMPLATE = 'T'
const KIND_SUPPLIER = 'S'
const KIND_REAL = 'Real'
const KIND_TEST = 'Teste'
const KIND_TRIAL = 'Trial'

const TYPE_REAL = 'REAL'
const TYPE_TRIAL = 'TRIAL'
const TYPE_TEST = 'TEST'
const TYPE_TEMPLATE = 'TEMPLATE'

const TYPES_COMPANIES = {
  TEST_C: KIND_COMPANY,
  TEST_P: KIND_PERSONAL,
  TEMPLATE: KIND_TEMPLATE,
  SUPPLIER: KIND_SUPPLIER,
}
const TYPES_COMPANIES_LABEL = {
  TEST_C: 'Empresa Teste Jurídica',
  TEST_P: 'Empresa Teste Física',
  TEMPLATE: 'Empresa Template',
  SUPPLIER: 'Empresa Fornecedora',
}

const SELECT_TYPE_COMPANIES_CREATE = [
  { id: TYPES_COMPANIES.TEST_C, name: TYPES_COMPANIES_LABEL.TEST_C },
  { id: TYPES_COMPANIES.TEST_P, name: TYPES_COMPANIES_LABEL.TEST_P },
  { id: TYPES_COMPANIES.TEMPLATE, name: TYPES_COMPANIES_LABEL.TEMPLATE },
]

const KINDS = {
  COMPANY: KIND_COMPANY,
  PERSONAL: KIND_PERSONAL,
  REAL: KIND_REAL,
  TEST: KIND_TEST,
  TRIAL: KIND_TRIAL,
  TEMPLATE: KIND_TEMPLATE,
  SUPPLIER: KIND_SUPPLIER,
}

const KINDS_REALS = [
  { id: KIND_COMPANY, name: 'Empresa Jurídica' },
  { id: KIND_PERSONAL, name: 'Empresa Física' },
]

const KINDS_LABELS = {
  T: 'Template',
  S: 'Fornecedora',
  R: 'Real',
  TEST: 'Teste',
  TRIAL: 'Trial',
}

const ORIGINS_LABELS = {
  checkout: 'Checkout',
  backoffice: 'Backoffice',
  supplier: 'Fornecedor',
  easy: 'DPO Easy',
  easy_promotion: 'DPO Easy Upgrade',
}

const TYPES_PRE_REGISTRATION = {
  REAL: TYPE_REAL,
  TRIAL: TYPE_TRIAL,
}

const DEMO_PARTNERS = {
  MEMBER: 'M',
  PARTNER: 'P',
  MEMBER_AND_PARTNER: 'MP',
}

const DEMO_PARTNERS_LABELS = {
  M: 'Membro',
  P: 'Parceira',
  MP: 'Membro/Parceira',
}

const COMPANY_MULTIPLE_FILTER_OPTIONS = [
  { id: KINDS.REAL, name: KINDS_LABELS.R },
  { id: KINDS.TEST, name: KINDS_LABELS.TEST },
  { id: KINDS.TRIAL, name: KINDS_LABELS.TRIAL },
  { id: KINDS.TEMPLATE, name: KINDS_LABELS.T },
  { id: KINDS.SUPPLIER, name: KINDS_LABELS.S },
]

const DEMO_PARTNER_MULTIPLE_FILTER_OPTIONS = [
  { id: DEMO_PARTNERS.MEMBER, name: DEMO_PARTNERS_LABELS.M },
  { id: DEMO_PARTNERS.PARTNER, name: DEMO_PARTNERS_LABELS.P },
  { id: DEMO_PARTNERS.MEMBER_AND_PARTNER, name: DEMO_PARTNERS_LABELS.MP },
]

const CHANGE_STATUS_TRIALS = [
  {
    id: STATUSES.ACTIVE,
    name: STATUSES_LABELS[STATUSES.ACTIVE],
  },
  {
    id: STATUSES.TRIAL_LIMITED,
    name: STATUSES_LABELS[STATUSES.TRIAL_LIMITED],
  },
  {
    id: STATUSES.TRIAL_COMPLETE,
    name: STATUSES_LABELS[STATUSES.TRIAL_COMPLETE],
  },
  {
    id: STATUSES.TRIAL_CANCELED,
    name: STATUSES_LABELS[STATUSES.TRIAL_CANCELED],
  },
]

const TABS = [
  {
    value: STATUSES.ALL_SORTS,
    label: 'Todas',
  },
  {
    value: STATUSES.ACTIVE,
    label: 'Ativas',
  },
  {
    value: STATUSES.ONBOARDING,
    label: 'Onboarding',
  },
  {
    value: STATUSES.EVALUATION,
    label: 'Em avaliação',
  },
  {
    value: STATUSES.MAINTENANCE,
    label: 'Em manutenção',
  },
  {
    value: STATUSES.SUSPENDED,
    label: 'Suspensas',
  },
  {
    value: STATUSES.INACTIVE,
    label: 'Inativas',
  },
  {
    value: STATUSES.INDICATED,
    label: 'Indicadas',
  },
  {
    value: STATUSES.TRIAL_LIMITED,
    label: 'Gratuito limitado',
  },
  {
    value: STATUSES.TRIAL_COMPLETE,
    label: 'Gratuito completo',
  },
  {
    value: STATUSES.TRIAL_CANCELED,
    label: 'Gratuidade cancelada',
  },
]

const TABS_EASY = [
  {
    value: STATUSES.ALL_SORTS,
    label: 'Todas',
  },
  {
    value: STATUSES.ACTIVE,
    label: 'Ativas',
  },
  {
    value: STATUSES.INACTIVE,
    label: 'Em implementação',
  },
]

const companies = {
  CHANGE_STATUS_TRIALS,
  COMPANY_MULTIPLE_FILTER_OPTIONS,
  DEMO_PARTNER_ANPPD_KIND,
  DEMO_PARTNER_DPONET_TRIAL_KIND,
  DEMO_PARTNER_MULTIPLE_FILTER_OPTIONS,
  KIND_COMPANY,
  KIND_PERSONAL,
  KIND_SUPPLIER,
  KIND_TEMPLATE,
  KINDS_LABELS,
  KINDS_REALS,
  KINDS,
  ORIGINS_LABELS,
  SELECT_TYPE_COMPANIES_CREATE,
  STATUSES_LABELS,
  STATUSES_OBJECT,
  STATUSES,
  TABS,
  TABS_EASY,
  TYPE_REAL,
  TYPE_TEMPLATE,
  TYPE_TEST,
  TYPE_TRIAL,
  TYPES_PRE_REGISTRATION,
}

export default companies
