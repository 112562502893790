import auditProcess from './auditProcess'
import churn from './churn'
import companies from './companies'
import companySize from './companySize'
import companySuppliers from './companySuppliers'
import coupon from './coupon'
import creditCards from './creditCards'
import dataCollected from './dataCollected'
import dataProcess from './dataProcess'
import dataTreatmentAgent from './dataTreatmentAgent'
import department from './department'
import discountCoupon from './discountCoupon'
import documentationLinks from './documentationLinks'
import economicActivy from './economicActivy'
import faqsDocumentations from './faqsDocumentations'
import group from './group'
import incidents from './incidents'
import integrationPartner from './integrationPartner'
import inviteAdvisor from './inviteAdvisor'
import lastUpdates from './lastUpdates'
import legalFramework from './legalFramework'
import mode from './mode'
import necessityAndProportionalities from './necessityAndProportionalities'
import nonComplianceReports from './nonComplianceReports'
import orderFilter from './orderFilter'
import paymentContract from './paymentContract'
import paymentOrder from './paymentOrder'
import permissions from './permissions'
import permissionsLists from './permissionsLists'
import plan from './plan'
import preRegistration from './preRegistration'
import privacyPolicy from './privacyPolicy'
import profile from './profile'
import queryKeys from './queryKeys'
import question from './question'
import questionnaire from './questionnaire'
import reports from './reports'
import segment from './segment'
import seller from './seller'
import sharedSoftware from './sharedSoftware'
import subgroup from './subgroup'
import tabOutsourced from './tabOutsourced'
import technicalFaqs from './technicalFaqs'
import theme from './theme'
import unnecessaryData from './unnecessaryData'
import user from './user'
import userCompanies from './userCompanies'
import jodit from './jodit'

const constants = {
  auditProcess,
  churn,
  companies,
  companySize,
  companySuppliers,
  coupon,
  creditCards,
  dataCollected,
  dataProcess,
  dataTreatmentAgent,
  department,
  discountCoupon,
  documentationLinks,
  economicActivy,
  faqsDocumentations,
  group,
  incidents,
  integrationPartner,
  inviteAdvisor,
  lastUpdates,
  legalFramework,
  mode,
  necessityAndProportionalities,
  nonComplianceReports,
  orderFilter,
  paymentContract,
  paymentOrder,
  permissions,
  permissionsLists,
  plan,
  preRegistration,
  privacyPolicy,
  profile,
  queryKeys,
  question,
  questionnaire,
  reports,
  segment,
  seller,
  sharedSoftware,
  subgroup,
  tabOutsourced,
  technicalFaqs,
  theme,
  unnecessaryData,
  user,
  userCompanies,
  jodit,
}

export default constants
