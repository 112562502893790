import { size } from 'lodash'

import helpers from 'helpers'

const cnpjAndCpfMask = (document) => {
  document = helpers.formatters.onlyNumbers(document)
  if (size(document) > 11) return helpers.formatters.cnpj(document)
  return helpers.formatters.cpf(document)
}

export default cnpjAndCpfMask
