import * as yup from 'yup'

import helpers from 'helpers'

const schemaDocumentation = yup.object().shape({
  title: yup.string().required(),
  onlyCollaborators: yup.string().required(),
  uri: yup.string().required(),
  subtitle: yup.string().required(),
  faqsDocumentationsCategoryId: yup.string().required(),
  description: yup
    .string()
    .required()
    .test(
      'hasContent',
      'Preencha esse campo',
      helpers.validates.jodit.hasContent,
    ),
})

export default schemaDocumentation
