import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  context: {
    backgroundColor: theme.palette.spanishBlue,
  },
  text: {
    color: theme.palette.white,
  },
  icon: {
    color: theme.palette.white,
    '&:hover': {
      cursor: 'pointer',
      opacity: 0.8,
    },
  },
}))

export default useStyles
