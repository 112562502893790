import PropTypes from 'prop-types'
import { Grid, TextField } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'
import useSnackbar from 'hooks/useSnackbar'
import schema from './schema'

const QuestionForm = ({ mode, question, refresh, handleClose }) => {
  const { questionnaireId } = question
  const isShow = mode === constants.mode.SHOW

  const snackbar = useSnackbar()
  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema(),
    defaultValues: {
      theme: question?.theme,
      question: question?.question,
      affirmative: question?.affirmative,
      recomendations: question?.recomendations,
      reason: question?.reason,
      securityPolicy: question?.securityPolicy,
    },
  })

  const onSubmit = async (questionParams) => {
    try {
      if (mode === constants.mode.EDIT) {
        await service.dponet.questions.update({
          questionnaireId,
          questionId: question.id,
          question: questionParams,
        })

        snackbar.open({
          message: 'Questão criada com sucesso',
          variant: 'success',
        })
      } else if (mode === constants.mode.CREATE) {
        await service.dponet.questions.create({
          questionnaireId,
          question: questionParams,
        })

        snackbar.open({
          message: 'Questão atualizada com sucesso',
          variant: 'success',
        })
      }

      handleClose()
      refresh()
    } catch (error) {
      const defaultMessage =
        mode === constants.mode.EDIT
          ? 'Erro ao editar questão!'
          : 'Erro ao criar questão!'

      const errorMessage =
        helpers.formatters.errorMessage(error?.response?.data?.error, false) ||
        defaultMessage

      snackbar.open({ message: errorMessage, variant: 'error' })
    }
  }

  return (
    <form id="question-form" onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                label="Tema"
                type="text"
                color="primary"
                variant="outlined"
                disabled={isShow}
                error={!!errors.theme}
                helperText={errors?.theme?.message}
                fullWidth
                multiline
                maxRows={1}
              />
            }
            control={control}
            name="theme"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                label="Pergunta"
                type="text"
                color="primary"
                variant="outlined"
                disabled={isShow}
                error={!!errors.question}
                helperText={errors?.question?.message}
                fullWidth
                multiline
                minRows={4}
                maxRows={4}
              />
            }
            control={control}
            name="question"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                label="Medidas de controle adotadas"
                type="text"
                color="primary"
                variant="outlined"
                disabled={isShow}
                error={!!errors.affirmative}
                helperText={errors?.affirmative?.message}
                fullWidth
                multiline
                minRows={4}
                maxRows={4}
              />
            }
            control={control}
            name="affirmative"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                label="Recomendações"
                type="text"
                color="primary"
                variant="outlined"
                disabled={isShow}
                fullWidth
                multiline
                minRows={4}
                maxRows={4}
              />
            }
            control={control}
            name="recomendations"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                label="Motivo da inclusão"
                type="text"
                color="primary"
                variant="outlined"
                disabled={isShow}
                error={!!errors.reason}
                helperText={errors?.reason?.message}
                fullWidth
                multiline
                minRows={4}
                maxRows={4}
              />
            }
            control={control}
            name="reason"
            mode="onBlur"
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                label="Política de segurança"
                type="text"
                color="primary"
                variant="outlined"
                disabled={isShow}
                fullWidth
                multiline
                maxRows={1}
              />
            }
            control={control}
            name="securityPolicy"
            mode="onBlur"
          />
        </Grid>
      </Grid>
    </form>
  )
}

QuestionForm.propTypes = {
  mode: PropTypes.string.isRequired,
  question: PropTypes.object.isRequired,
  refresh: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default QuestionForm
