import { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Card,
  Container,
  ContentHeader,
  LoadingFeedback,
  Page,
} from 'components'
import { SubcategoryForm } from './components'

import { useFetch } from 'hooks'

import * as service from 'service'

const SubcategoryEdit = ({ match }) => {
  const [loading, setLoading] = useState(false)

  const { subCategoryId } = match?.params

  const { response, isLoading, refresh, error } = useFetch(
    service.dponet.technicalFaqs.getSubcategory,
    {
      subCategoryId,
    },
    [subCategoryId],
  )

  const faqSubcategory = response?.data?.knowledgeBaseSubcategory

  return (
    <Page title="Editar Subcategoria - FAQ">
      <LoadingFeedback open={loading || isLoading} />
      <Container spacingXl={40} spacingLg={25} spacingMd={10} spacingSm={10}>
        <ContentHeader
          menu="Apoio Técnico"
          title="Editar"
          subtitle="Subcategoria"
        />
        <Card title="Editar subcategoria">
          {((!isLoading && faqSubcategory) || error) && (
            <SubcategoryForm
              setLoading={setLoading}
              refresh={refresh}
              formData={faqSubcategory}
              isEdit
            />
          )}
        </Card>
      </Container>
    </Page>
  )
}

SubcategoryEdit.propTypes = {
  match: PropTypes.object,
}

export default SubcategoryEdit
