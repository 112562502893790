import { useTheme } from '@material-ui/styles'
import { Skeleton } from '@material-ui/lab'
import {
  Box,
  Grid,
  TablePagination,
  useMediaQuery,
  Card,
} from '@material-ui/core'

import {
  Page,
  Container,
  ContentHeader,
  FilterButton,
  LoadingFeedback,
} from 'components'
import { IncidentsTable, IncidentFilter } from './components'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import * as service from 'service'

const IncidentsMain = () => {
  const filter = useFilter()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.incidents.index,
    {
      ...{ ...filter.filters },
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  return (
    <Page title="Todos os Incidentes">
      <LoadingFeedback open={isLoading} />
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader menu="Incidentes" title="Listagem" subtitle="Incidentes">
          <Box pl={1}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
        </ContentHeader>
        <Card>
          <Grid container>
            {!isLoading && (
              <IncidentsTable
                incidents={response?.data?.incidents}
                isLoading={isLoading}
                onEvent={refresh}
              />
            )}
          </Grid>

          <Box px={2} display="flex" justifyContent="flex-end">
            {!isLoading && response ? (
              <TablePagination
                component="div"
                count={response.data.meta.totalCount}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowperpageoptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            ) : (
              <Box py="11px">
                <Skeleton variant="rect" width={200} height={30} />
              </Box>
            )}
          </Box>
        </Card>

        <IncidentFilter filter={filter} />
      </Container>
    </Page>
  )
}

export default IncidentsMain
