import { useState } from 'react'
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Tab,
  TablePagination,
  Tabs,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import {
  Container,
  ContentHeader,
  FilterButton,
  LoadingFeedback,
  Page,
  Permitted,
} from 'components'
import { SubgroupFilter } from './components'

import { Plus as PlusIcon } from 'react-feather'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'
import { SubgroupTable } from './components'
import constants from 'constants/index'
import * as service from 'service'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

const SubgroupsMain = () => {
  const filter = useFilter()
  const history = useHistory()

  const [tab, setTab] = useState(constants.subgroup.ALL_TAB)

  const theme = useTheme()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = useFetch(
    service.dponet.subgroups.get,
    {
      ...{ ...filter.filters },
      ...(tab !== constants.subgroup.ALL_TAB && {
        status: tab === constants.subgroup.ACTIVE_TAB,
      }),
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  const handleChangeTab = (_, value) => {
    setTab(value)
    if (value !== constants.subgroup.ALL_TAB) {
      return filter.setFilters({
        ...filter.filters,
        active: value === constants.subgroup.ACTIVE_TAB,
      })
    }

    const filters = filter.filters
    delete filters.active
    filter.setFilters({ ...filters })
  }

  return (
    <Page title="Subgrupos">
      <LoadingFeedback open={isLoading} />
      <Container
        maxWidth={false}
        spacingXl={30}
        spacingLg={25}
        spacingMd={10}
        spacingSm={10}
      >
        <ContentHeader
          title="Listagem"
          menu="Subgrupos"
          subtitle="Exibição dos subgrupos"
        >
          <Box ml={1}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
        </ContentHeader>
        <Box
          mt={4}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>
            <Tabs
              value={tab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              {constants.subgroup.TABS.map((tab) => (
                <Tab
                  key={tab.value}
                  label={tab.label}
                  value={tab.value}
                  onClick={(event) => handleChangeTab(event, tab.value)}
                />
              ))}
            </Tabs>
          </Box>
        </Box>
        <Card>
          <Box width="100%">
            <Grid container>
              <Grid item xs={6}>
                <Box
                  width="100%"
                  p={2}
                  height="100%"
                  display="flex"
                  alignItems="center"
                >
                  <Typography variant="h5">Subgrupos</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box p={2} display="flex" justifyContent="flex-end">
                  <Permitted tag={constants.permissions.SUBGROUPS.CREATE}>
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<PlusIcon />}
                      onClick={() => history.push(routes.subgroups.new)}
                    >
                      Criar
                    </Button>
                  </Permitted>
                </Box>
              </Grid>
            </Grid>
            <Divider />
          </Box>
          <SubgroupTable
            refresh={refresh}
            subgroups={response?.data?.subgroups ?? []}
          />
          <Box px={2} display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Card>
      </Container>
      <SubgroupFilter filter={filter} />
    </Page>
  )
}

export default SubgroupsMain
