import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    justifyContent: 'center',
    gridGap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tab: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '10px',
    padding: theme.spacing(2),
  },
  tabActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [theme.breakpoints.down('xs')]: {
    card: {
      flexDirection: 'column',
    },
  },
}))

export default styles
