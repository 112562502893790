import constants from 'constants/index'

const softwareAllocationType = (type) => {
  switch (type) {
    case constants.sharedSoftware.COMPANY_ALLOCATION_TYPE:
      return constants.sharedSoftware.COMPANY_ALLOCATION_LABEL
    case constants.sharedSoftware.THIRDY_ALLOCATION_TYPE:
      return constants.sharedSoftware.THIRDY_ALLOCATION_LABEL
    default:
      return ''
  }
}

export default softwareAllocationType
