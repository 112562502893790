const getResponsibleNames = (nonComplianceReport) => {
  if (!nonComplianceReport.nonComplianceReportActions) return '-'

  let responsibleNames = ''
  /** TODO
   * trocar todas as keys responsable para responsible no web backoffice e api
   **/
  nonComplianceReport.nonComplianceReportActions.map(
    (nonComplianceReportAction) => {
      return (responsibleNames =
        responsibleNames +
        (nonComplianceReportAction?.responsableUser?.name || '-') +
        '/ ')
    },
  )

  return responsibleNames.slice(0, -2)
}

export default getResponsibleNames
