import constants from 'constants/index'

const mountTitle = (title) => {
  if (title === constants.dataProcess.TAB_VIEW_VALUE.GENERAL) {
    return '- Geral'
  }

  return ''
}

export default mountTitle
