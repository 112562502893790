import constants from 'constants/incidents'

const chipStatusColor = (status, classes) => {
  switch (status) {
    case constants.STATUSES.ACTIVE:
      return classes.chipActive
    case constants.STATUSES.INACTIVE:
      return classes.chipInactive
    default:
      return null
  }
}

export default chipStatusColor
