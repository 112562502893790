const DEFAULT_TRUE = true
const DEFAULT_FALSE = false

const PROFILE_TYPE_CLIENT = 'Client'
const PROFILE_TYPE_COLLABORATOR = 'Collaborator'

const PROFILE_ID_RESPONSIBLE = 10000

const profile = {
  DEFAULT_TRUE,
  DEFAULT_FALSE,
  PROFILE_TYPE_CLIENT,
  PROFILE_TYPE_COLLABORATOR,
  PROFILE_ID_RESPONSIBLE,
}

export default profile
