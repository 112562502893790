import { useState } from 'react'
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Tab,
  TablePagination,
  Tabs,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { useTheme } from '@material-ui/styles'

import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  LoadingFeedback,
  Page,
  Permitted,
} from 'components'
import { EconomicActivitiesTable } from './components'

import { Plus as PlusIcon } from 'react-feather'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePagination from 'hooks/usePagination'

import constants from 'constants/index'

import * as service from 'service'
import { useHistory } from 'react-router-dom'
import { routes } from 'Routes'

const EconomicActivitiesMain = () => {
  const history = useHistory()
  const filter = useFilter()
  const [tab, setTab] = useState(constants.economicActivy.ALL_TAB)

  const theme = useTheme()
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading, refresh } = useFetch(
    service.dponet.economicActivities.get,
    {
      ...{ ...filter.filters },
      ...(tab !== constants.economicActivy.ALL_TAB && {
        status: tab === constants.economicActivy.ACTIVE_TAB,
      }),
      perPage,
      page,
    },
    [page, perPage, filter.filters],
  )

  const handleChangeTab = (_, value) => {
    setTab(value)
    if (value !== constants.economicActivy.ALL_TAB) {
      return filter.setFilters({
        ...filter.filters,
        active: value === constants.economicActivy.ACTIVE_TAB,
      })
    }

    const filters = filter.filters
    delete filters.active
    filter.setFilters({ ...filters })
  }

  return (
    <>
      <Page title="Atividades econômicas">
        <LoadingFeedback open={isLoading} />
        <Container
          maxWidth={false}
          spacingXl={30}
          spacingLg={15}
          spacingMd={10}
          spacingSm={10}
        >
          <ContentHeader
            title="Listagem"
            menu="Atividades econômicas"
            subtitle="Atividades econômicas"
          >
            <Box ml={1}>
              <FilterButton setDrawerOpen={filter.setDrawerOpen} />
            </Box>
          </ContentHeader>
          <Box
            mt={4}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Tabs
                value={tab}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
              >
                {constants.economicActivy.TABS.map((tab) => (
                  <Tab
                    key={tab.value}
                    label={tab.label}
                    value={tab.value}
                    onClick={(event) => handleChangeTab(event, tab.value)}
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
          <Card>
            <Box width="100%" display="flex" alignItems="center">
              <Grid container>
                <Grid item xs={6}>
                  <Box
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    ml={2}
                  >
                    <Typography variant="h5">Atividades econômicas</Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box p={2} display="flex" justifyContent="flex-end">
                    <Permitted
                      tag={constants.permissions.ECONOMIC_ACTIVITIES.CREATE}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<PlusIcon />}
                        onClick={() =>
                          history.push(routes.economicActivities.new)
                        }
                      >
                        Criar
                      </Button>
                    </Permitted>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            {!isLoading && (
              <EconomicActivitiesTable
                activities={response?.data?.economicActivities || []}
                refresh={refresh}
              />
            )}
            <Box px={2} display="flex" justifyContent="flex-end">
              <TablePagination
                component="div"
                count={response?.data?.meta?.totalCount ?? 0}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            </Box>
          </Card>
        </Container>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <input textfieldinput="true" label="Nome da atividade" name="name" />
          <input textfieldinput="true" label="Sessão" name="session" />
          <input textfieldinput="true" label="Divisão" name="division" />
          <input textfieldinput="true" label="Grupo" name="group" />
          <input
            textfieldinput="true"
            label="Identificador CNAE"
            name="class_cnae"
          />
        </Filters>
      </Page>
    </>
  )
}

export default EconomicActivitiesMain
