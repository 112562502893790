/* eslint-disable react-hooks/exhaustive-deps */
import { Filters } from 'components'

const DocumentationFilter = ({ filter, categories }) => {
  return (
    <Filters filter={filter}>
      <input textfieldinput="true" label="Identificador" name="id" />
      <input textfieldinput="true" label="Título" name="title" />
      <input textfieldinput="true" label="Publicado por" name="publishedBy" />

      <select
        multipleselectinput="true"
        label="Categorias"
        name="categories"
        optionvalues={categories}
      />
      <input
        datepickerinput="true"
        label="Última atualização antes de"
        name="updatedAtLt"
      />
      <input
        datepickerinput="true"
        label="Última atualização depois de"
        name="updatedAtGt"
      />
    </Filters>
  )
}

export default DocumentationFilter
