import constants from 'constants/index'

const companyKindLabel = (status, kind) => {
  const { STATUSES, KIND_COMPANY, KIND_PERSONAL, KIND_TEMPLATE, KINDS_LABELS } =
    constants.companies

  const isTrial = [
    STATUSES.TRIAL_LIMITED,
    STATUSES.TRIAL_COMPLETE,
    STATUSES.TRIAL_CANCELED,
  ].includes(status)
  const isTest = status === STATUSES.TEST ? 'Teste' : ''
  const isCompany = kind === KIND_COMPANY
  const isPersonal = kind === KIND_PERSONAL
  const isTemplate = kind === KIND_TEMPLATE

  if (isTrial) return `Empresa ${KINDS_LABELS.TRIAL}`
  if (isTemplate) return `Empresa ${KINDS_LABELS.T}`
  if (isCompany) return `Empresa ${isTest} Jurídica`
  if (isPersonal) return `Empresa ${isTest} Física`

  return `Empresa ${KINDS_LABELS.S}`
}

export default companyKindLabel
