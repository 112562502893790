import { useState } from 'react'

import PropTypes from 'prop-types'

import useSnackbar from 'hooks/useSnackbar'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'
import { LoadingButton } from 'components'

import * as service from 'service'

import useStyles from './styles'

const DocumentationActiveDialog = ({
  documentation,
  open,
  setOpen,
  refresh,
}) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()

  const InactiveOrActive = async () => {
    setLoading(true)
    try {
      if (documentation?.active) {
        await service.dponet.faqsDocumentations.put({
          faqsDocumentationId: documentation.id,
          active: false,
        })
      } else {
        await service.dponet.faqsDocumentations.put({
          faqsDocumentationId: documentation.id,
          active: true,
        })
      }
      refresh()
      snackbar.open({
        message: `Documentação ${
          documentation?.active ? 'inativada' : 'ativada'
        }com sucesso`,
        variant: 'success',
      })
    } catch {
      snackbar.open({
        message: `Falha ao inativar Documentação`,
        variant: 'error',
      })
    }
    setOpen(false)
    setLoading(false)
    refresh()
  }

  const noticeText = () => {
    if (documentation?.active) {
      return (
        <Box pb={1}>
          <Typography className={classes.textColor}>
            Tem certeza que deseja inativar?
          </Typography>
          <Typography className={classes.text}>
            Se inativada, ela não será mais vísivel para os clientes
          </Typography>
        </Box>
      )
    }

    return (
      <Box pb={1} className={classes.text}>
        <Typography className={classes.textColor}>
          Tem certeza que deseja reativar?
        </Typography>
        <Typography className={classes.text}>
          Se reativada, ela será vísivel para os clientes
        </Typography>
      </Box>
    )
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="change-last-update-status"
      fullWidth
    >
      <DialogTitle>
        <Typography className={classes.textTitle}>
          {`TEM CERTEZA QUE DESEJA ${
            documentation?.active ? 'INATIVAR' : 'REATIVAR'
          } ESSA DOCUMENTAÇÃO?`}
        </Typography>
      </DialogTitle>
      <Divider />
      <Box>
        <DialogContent>{noticeText()}</DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <LoadingButton
          variant="contained"
          documentation
          type="submit"
          color="primary"
          text={documentation?.active ? 'INATIVAR' : 'ATIVAR'}
          loading={loading}
          onClick={InactiveOrActive}
        />
      </DialogActions>
    </Dialog>
  )
}

DocumentationActiveDialog.propTypes = {
  documentation: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
}
DocumentationActiveDialog.defaultProps = {
  documentation: {},
  setOpen: () => {},
  refresh: () => {},
}

export default DocumentationActiveDialog
