import { ratio } from '../helpers'

export const fontSize = {
  paragraph: ratio(48),
  subtitle: ratio(80),
  title: ratio(230),
  documentTitle: ratio(260),
  pageNumber: ratio(60),
  chapterNumber: ratio(450),
  chapterTitle: ratio(120),
  progressLabel: ratio(40),
}

export const PADDING = ratio(200)

export const WIDTH = (pdf) => pdf.internal.pageSize.width
export const HEIGHT = (pdf) => pdf.internal.pageSize.height

export const ROWGAP = ratio(80)
export const GAP = ratio(240)

export const totalPages = (pdf) => pdf.internal.getNumberOfPages() - 3

export const rectangleSize = {
  halfWidth: ratio(650),
}

export const labelWidth = ratio(160)
export const labelHeight = ratio(80)

export const marginTable = ratio(34)
