import Tabs from './Tabs'
import GeneralInformation from './GeneralInformation'
import DataCollecteds from './DataCollecteds'
import LifeCycles from './LifeCycles'
import DataTreatments from './DataTreatments'
import DocumentedInformation from './DocumentedInformation'
import NecessityProportionality from './NecessityProportionality'
import DataTreatmentAgents from './DataTreatmentAgents'

const view = {
  Tabs,
  GeneralInformation,
  DataCollecteds,
  LifeCycles,
  DataTreatments,
  DocumentedInformation,
  NecessityProportionality,
  DataTreatmentAgents,
}

export default view
