import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette.text.backgroundInputColor,
    padding: theme.spacing(2),
  },

  chip: {
    margin: '-5px',
  },
}))

export default styles
