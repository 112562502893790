import palette from 'theme/palette'

import { fontSize, PADDING } from '../constants'

const subtitle = (pdf, sub, positionY, textColor = palette.BlueDownDark) => {
  pdf.setFont('Raleway-Bold')
  pdf.setFontSize(fontSize.subtitle)
  pdf.setTextColor(textColor)
  pdf.text(sub, PADDING, positionY, null, null, 'left')
}

export default subtitle
