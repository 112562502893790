import { useState, useEffect } from 'react'

import CompanyContext from 'contexts/CompanyContext'

import * as service from 'service'

const CompanyProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [groups, setGroups] = useState(null)
  const [companySizes, setCompanySizes] = useState(null)
  const [subgroups, setSubgroups] = useState(null)
  const [segments, setSegments] = useState(null)
  const [userCompanies, setUserCompanies] = useState(null)
  const [secondaryEconomicActivities, setSecondaryEconomicActivities] =
    useState(null)

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true)

      const [responseGroups, responseCompanySizes] = await Promise.all([
        service.dponet.groups.get({ perPage: 100000, active: true }),
        service.dponet.companySizes.get({ perPage: 100000, active: true }),
      ])

      setGroups(responseGroups?.data?.groups)
      setCompanySizes(responseCompanySizes?.data?.companySizes)

      setLoaded(true)
      setIsLoading(false)
    }
    loadData()
    // eslint-disable-next-line
  }, [])

  const loadSubgroups = async (groupId) => {
    if (!groupId) {
      setSubgroups(null)
      setSegments(null)
      return
    }

    let response = await service.dponet.subgroups.get({
      perPage: 100000,
      active: true,
      groupId: groupId,
    })

    setSubgroups(response?.data?.subgroups)
  }

  const loadSegments = async (subgroupId) => {
    if (!subgroupId) return setSegments(null)

    let response = await service.dponet.segments.get({
      perPage: 100000,
      active: true,
      subgroupId: subgroupId,
    })

    setSegments(response?.data?.segments)
  }

  const loadUserCompanies = async (companyId) => {
    if (!companyId) return setUserCompanies(null)
    setIsLoading(true)

    let response = await service.dponet.userCompanies.get({
      companyId,
      responsable: false,
      perPage: 1000000,
    })
    setUserCompanies(
      response?.data?.userCompanies.map((userCompany) => {
        return {
          id: userCompany?.id,
          name: userCompany?.user?.email,
          phone: userCompany?.user?.phone,
        }
      }),
    )

    setTimeout(() => {
      setIsLoading(false)
    }, 200)
  }

  const loadSecondaryEconomicActivities = async (companyId) => {
    if (!companyId) return setSecondaryEconomicActivities(null)

    setIsLoading(true)

    let response = await service.dponet.companies.secondaryEconomicActivities({
      companyId,
      perPage: 1000000,
    })

    setSecondaryEconomicActivities(response?.data?.economicActivities)

    setTimeout(() => {
      setIsLoading(false)
    }, 200)
  }

  return (
    <CompanyContext.Provider
      value={{
        isLoading,
        groups,
        companySizes,
        subgroups,
        segments,
        userCompanies,
        secondaryEconomicActivities,
        setIsLoading,
        loadSubgroups,
        loadSegments,
        loadUserCompanies,
        loadSecondaryEconomicActivities,
        loaded,
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}

export default CompanyProvider
