import { useHistory } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Typography,
  Grid,
  makeStyles,
  Divider,
  Tooltip,
} from '@material-ui/core'
import {
  Calendar as CalendarIcon,
  AlertOctagon as AlertRncIcon,
  File as FileIcon,
  AlertTriangle as AlertFragilityIcon,
  Briefcase as BriefcaseIcon,
  Box as BoxIcon,
} from 'react-feather'

import { NoData } from 'components'
import { DataProcessCardMenuItems, CardColumn, CardRow } from './components'

import usePermission from 'hooks/usePermissions'

import styles from './styles'
import helpers from 'helpers'
import { routes } from 'Routes'
import { reverse } from 'named-urls'
import theme from 'theme'
import constants from 'constants/index'
const useStyles = makeStyles(styles)

const DataProcessCard = ({
  dataProcesses,
  isLoading,
  showDetails,
  defaultStatus,
  setLoading,
  ...rest
}) => {
  const history = useHistory()
  const classes = useStyles()
  const { permitted } = usePermission()

  const handleGoView = (dataProcessId) => {
    history.push(
      reverse(routes.dataProcess.view, { dataProcessId: dataProcessId }),
    )
  }

  const permitRedirectToShow = () => {
    return showDetails && permitted(constants.permissions.DATA_PROCESSES.LIST)
  }

  return (
    <>
      {!isLoading &&
        dataProcesses?.map((dataProcess) => (
          <Grid item {...rest} key={dataProcess?.id}>
            <Card {...rest} className={classes.card}>
              <CardHeader
                className={classes.cardHeader}
                classes={{
                  content: classes.cardHeaderContent,
                }}
                action={
                  showDetails && (
                    <Box mt={1}>
                      <DataProcessCardMenuItems
                        dataProcess={dataProcess}
                        status={dataProcess.statusId}
                        origin={origin}
                        setLoading={setLoading}
                      />
                    </Box>
                  )
                }
                title={
                  <Tooltip title={dataProcess.name} placement="bottom-start">
                    <Box display="flex">
                      <Box>
                        <Typography noWrap variant="h5">
                          {`#${dataProcess.id} - ${dataProcess.name}`}
                        </Typography>
                      </Box>
                      <Box
                        mr={1}
                        className={clsx({
                          [classes.blueDot]: dataProcess.inAudit,
                        })}
                      />
                    </Box>
                  </Tooltip>
                }
              />
              <Divider />
              <CardContent
                className={
                  permitRedirectToShow()
                    ? classes.cardLinkContent
                    : classes.cardContent
                }
                onClick={() =>
                  permitRedirectToShow() && handleGoView(dataProcess.id)
                }
              >
                <CardRow>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-evenly"
                    py={2}
                    className={helpers.dataProcesses.statusColor(
                      defaultStatus || dataProcess.statusId,
                      classes,
                    )}
                  >
                    <Typography className={classes.textWhite}>
                      {helpers.dataProcesses.status(
                        defaultStatus || dataProcess.statusId,
                      )}
                    </Typography>
                  </Box>
                </CardRow>
                <CardRow minHeight="5em" className={classes.statusSection}>
                  <CardColumn
                    icon={
                      <BriefcaseIcon color={theme.palette.secondary.main} />
                    }
                    title="Empresa"
                    subtitle={dataProcess?.company?.name || '-'}
                  />
                  <CardColumn
                    icon={<BoxIcon color={theme.palette.secondary.main} />}
                    title="Departamento"
                    subtitle={dataProcess?.department?.name || '-'}
                  />
                </CardRow>
                <Divider />
                <CardRow>
                  <CardColumn
                    icon={
                      <AlertFragilityIcon
                        color={theme.palette.secondary.main}
                      />
                    }
                    className={helpers.dataProcesses.fragilityColor(
                      dataProcess?.adoptedFragilityId,
                      classes,
                    )}
                    title="Risco"
                    subtitle={helpers.dataProcesses.fragilityLabel(
                      dataProcess?.adoptedFragilityId,
                    )}
                  />
                  {dataProcess?.rncCreated && (
                    <CardColumn
                      icon={
                        <AlertRncIcon color={theme.palette.secondary.main} />
                      }
                      title="RMC"
                      subtitle="Criado"
                    />
                  )}
                </CardRow>
                <Divider />
                <CardRow>
                  <CardColumn
                    icon={<CalendarIcon color={theme.palette.secondary.main} />}
                    title="Criado em"
                    subtitle={helpers.formatters.date(dataProcess?.createdAt)}
                  />
                  <CardColumn
                    icon={<CalendarIcon color={theme.palette.secondary.main} />}
                    title="Última atualização"
                    subtitle={`feita ${helpers.formatters.dateFromNow(
                      dataProcess?.updatedAt,
                    )}`}
                  />
                </CardRow>
                <Divider />
                <CardRow>
                  <CardColumn
                    icon={<FileIcon color={theme.palette.secondary.main} />}
                    title="Por que o dado é tratado?"
                    subtitle={dataProcess?.finality || '-'}
                    noWrap
                    width="100%"
                  />
                </CardRow>
              </CardContent>
            </Card>
          </Grid>
        ))}
      {dataProcesses?.length === 0 && (
        <Grid item sm={12}>
          <NoData message="Nenhum processamento de dados foi encontrado." />
        </Grid>
      )}
    </>
  )
}

DataProcessCard.propTypes = {
  dataProcesses: PropTypes.array,
  isLoading: PropTypes.bool,
  origin: PropTypes.string,
  showDetails: PropTypes.bool,
  defaultStatus: PropTypes.number,
  setLoading: PropTypes.func,
}

DataProcessCard.defaultProps = {
  showDetails: true,
  setLoading: () => {},
}

export default DataProcessCard
