import { Box, Typography } from '@material-ui/core'
import PropTypes from 'react-jss/lib/propTypes'

const FormattedPhraseList = ({ list, variant }) => {
  return (
    <Box display="flex">
      {list?.map((item, index) => (
        <Typography variant={variant} key={index}>
          {index === list.length - 1 ? item?.name + '.' : item?.name + ',\xa0'}
        </Typography>
      ))}
    </Box>
  )
}

FormattedPhraseList.prototype = {
  list: PropTypes.array,
  variant: PropTypes.string,
}

FormattedPhraseList.defaultProps = {
  variant: 'body2',
}

export default FormattedPhraseList
