import PropTypes from 'prop-types'

import { Box, Typography } from '@material-ui/core'

import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from 'components/Table'

const TableNecPropDataTreatments = ({ dataTreatments }) => {
  if (dataTreatments.length === 0) {
    return null
  }

  return (
    <Box my={2}>
      <Box width="100%" my={2}>
        <Typography variant="h6">
          Dados compartilhados não necessários
        </Typography>
      </Box>
      <Box width="100%">
        <PerfectScrollbar>
          <Table
            size="small"
            emptyMessage="Nenhum dado compartilhado encontrado"
            noWrap
          >
            <TableHead>
              <TableRow>
                <TableCell width="30%">Com quem</TableCell>
                <TableCell width="30%">Por que</TableCell>
                <TableCell width="25%">Como</TableCell>
                <TableCell width="15%">Tipo</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {dataTreatments.map((dataTreatment) => {
                return (
                  <TableRow key={dataTreatment.id}>
                    <TableCell>{dataTreatment?.shareProcess?.name}</TableCell>
                    <TableCell>{dataTreatment.description}</TableCell>
                    <TableCell>{dataTreatment.controllMechanismView}</TableCell>
                    <TableCell>{dataTreatment?.shareType?.name}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Box>
    </Box>
  )
}

TableNecPropDataTreatments.propTypes = {
  dataTreatments: PropTypes.arrayOf(Object).isRequired,
}

TableNecPropDataTreatments.defaultProps = {
  dataTreatments: [],
}

export default TableNecPropDataTreatments
