import PropTypes from 'prop-types'

import { useState } from 'react'

import { Button } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import useDocuments from 'hooks/useDocuments'

import helpers from 'helpers'

import * as service from 'service'

import { getBaseURL } from 'service/env'

import { IconButton } from 'components'

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'

import MenuButton from 'components/MenuButton'

import useSnackbar from 'hooks/useSnackbar'

const TableDocuments = ({ classes, dataProcessId, edit }) => {
  const [loading, setLoading] = useState(false)

  const { setData, data: documents, loading: loadingDocuments } = useDocuments()

  const snackbar = useSnackbar()

  const onClickDeleteDocument = async (document, index) => {
    setLoading(true)
    const documentName = helpers.functions.documentName(document.url)
    try {
      await service.dponet.documents.deleteDocument({
        dataProcessesId: dataProcessId,
        documentName: documentName,
      })
      delete documents[index]
      setData(documents)
      setLoading(false)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
      setLoading(false)
    }
  }

  return (
    <Table size="small" emptyMessage="Nenhum documento encontrado" noWrap>
      <TableHead>
        <TableRow>
          <TableCell width="80%">Documentos</TableCell>
          <TableCell width="20%" align="right" className={classes.actionHeader}>
            Ações
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!loadingDocuments &&
          documents?.map((document, index) => (
            <TableRow key={index}>
              <TableCell>
                {helpers.functions.documentName(document.url)}
              </TableCell>
              <TableCell align="right" disableTooltip>
                {edit ? (
                  <>
                    <MenuButton>
                      <Button
                        color="secondary"
                        fullWidth
                        onClick={() =>
                          window.open(
                            `${getBaseURL('dponet')}${document.url}`,
                            '_blank',
                          )
                        }
                      >
                        Visualizar
                      </Button>
                      <Button
                        color="secondary"
                        fullWidth
                        onClick={() => onClickDeleteDocument(document, index)}
                        disabled={loading}
                      >
                        {loading ? 'Aguarde...' : 'Remover'}
                      </Button>
                    </MenuButton>
                  </>
                ) : (
                  <IconButton
                    size="small"
                    Icon={VisibilityOutlinedIcon}
                    text="Visualizar"
                    onClick={() =>
                      window.open(
                        `${getBaseURL('dponet')}${document.url}`,
                        '_blank',
                      )
                    }
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}

TableDocuments.propTypes = {
  classes: PropTypes.object.isRequired,
  edit: PropTypes.bool.isRequired,
  dataProcessId: PropTypes.number.isRequired,
}

TableDocuments.defaultProps = {
  edit: false,
}

export default TableDocuments
