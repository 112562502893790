import { useEffect } from 'react'

import helpers from 'helpers'

import { Card, CardHeader, Box, Typography, Divider } from '@material-ui/core'

import { NoData, Permitted, RmcActionContent } from 'components'

import useDataProcess from 'hooks/useDataProcess'

import FinalizedOrContinuedAlert from '../../Show/FinalizedOrContinuedAlert'
import useRmc from 'hooks/useRmc'

import useStyles from './styles'

import {
  TableNecPropDataCollectedShow,
  TableNecPropDataTreatmentShow,
  TableNecPropDataLifeCycleShow,
} from './components'

import constants from 'constants/index'

const GeneralInformation = () => {
  const { data } = useDataProcess()

  const { loadData, data: rmc, loading } = useRmc()

  const classes = useStyles()

  useEffect(() => {
    if (data.nonComplianceReport) {
      loadData(data.nonComplianceReport.id)
    }
    //eslint-disable-next-line
  }, [])

  const dataLifeCycles = data.lifeCycles?.filter(
    (dataLifeCycle) => dataLifeCycle.necessary === false,
  )

  const dataTreatments = data.dataTreatments?.filter(
    (dataTreatment) => dataTreatment.necessary === false,
  )

  const collectedFields = helpers.dataCollected.mountUnnecessaryFields(
    data.dataCollecteds,
  )

  const showStep =
    dataLifeCycles.length > 0 ||
    dataTreatments.length > 0 ||
    collectedFields.length > 0

  const showResult =
    data.nonComplianceReport &&
    data.version === data.nonComplianceReport.dataProcessVersion &&
    !helpers.nonComplianceReports.finalityCheck.fragility(
      data.nonComplianceReport,
    )
  return (
    <>
      {showStep || showResult ? (
        <>
          {showStep && (
            <>
              <Card>
                <CardHeader title="Análise da Necessidade e Proporcionalidade" />
                <Divider />
                <Box className={classes.root}>
                  <Typography variant="body1">
                    Os seguintes itens foram indicados como
                    desnecessários/desproporcionais:
                  </Typography>
                </Box>
              </Card>
              {collectedFields?.length > 0 && (
                <Box mt={2}>
                  <Card>
                    <CardHeader title="Dados tratados não necessários" />
                    <Divider />
                    <TableNecPropDataCollectedShow
                      collectedFields={collectedFields}
                    />
                  </Card>
                </Box>
              )}
              {dataTreatments?.length > 0 && (
                <Box mt={2}>
                  <Card>
                    <CardHeader title="Dados compartilhados não necessários" />
                    <Divider />
                    <TableNecPropDataTreatmentShow
                      dataTreatments={dataTreatments}
                    />
                  </Card>
                </Box>
              )}
              {dataLifeCycles?.length > 0 && (
                <Box mt={2}>
                  <Card>
                    <CardHeader title="Proteção não necessária" />
                    <Divider />
                    <TableNecPropDataLifeCycleShow
                      dataLifeCycles={dataLifeCycles}
                    />
                  </Card>
                </Box>
              )}
            </>
          )}
          {showResult && !loading && rmc?.unnecessaryDatas && (
            <Permitted tag={constants.permissions.NON_COMPLIANCE_REPORT.EDIT}>
              <Box mt={showStep && 2}>
                <RmcActionContent
                  titleCard="Resultado da reavaliação de necessidade e
                proporcionalidade"
                />
              </Box>
            </Permitted>
          )}
          {showResult && !loading && (
            <Box mt={2}>
              <FinalizedOrContinuedAlert nonComplianceReport={rmc} />
            </Box>
          )}
        </>
      ) : (
        <Box display="flex" justifyContent="center">
          <Box width="50%">
            <NoData
              message={helpers.dataProcesses.noDataMessage(
                data?.dataCollecteds,
              )}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

export default GeneralInformation
